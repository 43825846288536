import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK= theme.palette.primary.dark;
 
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1080 634">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
		<g viewBox="0 0 980.000000 980.000000" >
		
  
<path fill="#011560" opacity="1.000000" stroke="none" 
	d="
M40.999996,635.000000 
	C27.677015,635.000000 14.354032,635.000000 1.000000,635.000000 
	C1.000000,460.645782 1.000000,286.291534 1.346704,111.499725 
	C2.802988,111.369576 3.912568,111.677025 5.240373,112.084618 
	C5.731485,112.373894 5.923367,112.624947 6.055635,113.286415 
	C7.070864,114.413071 8.064700,115.191223 9.346952,116.113983 
	C11.099436,117.821350 12.563503,119.384109 14.050005,121.295387 
	C15.071926,122.416878 16.071413,123.189850 17.358829,124.107712 
	C19.108824,125.813988 20.570892,127.375366 22.053886,129.285492 
	C23.069126,130.411713 24.063442,131.189194 25.346344,132.111618 
	C27.099880,133.820679 28.564831,135.384796 30.051517,137.297806 
	C31.076685,138.416519 32.080120,139.186325 33.359055,140.098694 
	C34.679646,140.859970 35.724739,141.478699 36.946045,142.378220 
	C37.791649,144.030319 38.461040,145.401611 39.112938,147.118652 
	C40.074242,147.946320 41.053040,148.428223 42.352875,148.946045 
	C43.101318,149.034195 43.528717,149.086426 44.052834,149.337021 
	C44.328640,149.783508 44.564816,149.951324 44.883621,150.358643 
	C44.948471,151.104279 44.987785,151.530045 45.019688,152.330780 
	C46.087357,153.758469 47.162437,154.811188 48.469254,155.984680 
	C49.140888,156.384537 49.580795,156.663651 50.010338,157.208374 
	C49.998787,157.651154 49.997597,157.828339 49.997391,158.473236 
	C49.998859,160.771622 50.123177,162.612244 49.976967,164.431122 
	C49.511261,170.224762 52.316311,177.118774 44.625389,180.587540 
	C44.168243,180.793716 44.006741,182.097717 44.037632,182.877777 
	C44.562042,196.120605 45.183193,209.359711 45.678478,222.603531 
	C45.840111,226.925522 45.703880,231.258652 45.703880,236.289627 
	C43.159214,235.261368 41.855736,234.734650 39.080551,233.613235 
	C39.562443,236.266052 39.373634,239.024658 40.249355,239.418930 
	C45.201950,241.648834 43.963314,245.813736 43.984241,249.590622 
	C44.033123,258.410950 43.999844,267.231750 43.999844,276.052338 
	C43.999844,282.043671 43.925079,288.036224 44.021461,294.026031 
	C44.112728,299.697662 41.543915,305.535614 45.003777,311.380707 
	C43.639088,313.462433 42.246140,315.146271 40.934517,316.891235 
	C38.638538,319.945801 36.392467,323.037903 34.075485,326.053986 
	C34.025475,325.993713 34.114025,326.120239 33.742191,326.115692 
	C30.804729,326.787659 28.624554,327.799652 28.943966,331.291016 
	C28.647900,331.771576 28.394157,331.952942 27.738842,332.033112 
	C26.614403,332.566528 25.830254,333.115448 24.648304,333.748840 
	C23.167885,333.887482 22.085262,333.941589 20.793934,333.751495 
	C16.908712,333.752106 16.898270,336.353149 17.041740,339.460999 
	C17.090206,341.818878 17.123928,343.759308 17.139172,346.093689 
	C20.069546,350.627045 22.734346,351.123627 26.371372,348.078979 
	C28.375477,346.091034 31.261610,344.277374 31.441381,342.226501 
	C32.055595,335.219482 37.096493,332.196167 41.945618,329.117798 
	C42.477573,331.632904 44.072819,334.761566 43.218994,335.729218 
	C39.648281,339.775940 41.205505,344.338104 41.202065,348.637756 
	C41.200924,350.065216 43.118969,352.393524 44.540611,352.738159 
	C47.625813,353.486053 48.295635,355.253235 47.970852,357.803925 
	C47.102772,364.621277 44.995846,371.494904 45.446201,378.207642 
	C45.921703,385.295135 47.522430,392.176331 46.530041,399.485413 
	C45.907715,404.068939 47.054115,409.031403 48.105824,413.665222 
	C49.202694,418.498016 46.407257,425.159576 53.976685,427.417023 
	C54.744034,427.645874 54.949421,429.686279 55.458340,430.867584 
	C57.186638,434.879242 57.835690,438.729645 54.846771,442.603607 
	C54.047470,443.639618 53.869675,445.507172 54.066830,446.899475 
	C54.956161,453.179993 56.308163,459.400787 57.038029,465.695923 
	C57.466831,469.394318 57.105713,473.193817 56.979275,476.943756 
	C56.895451,479.429779 57.288792,481.315613 60.419941,480.999115 
	C64.410103,480.595764 65.093819,482.889984 65.032967,486.253479 
	C64.895859,493.832428 64.834732,501.422211 65.134247,508.992065 
	C65.198715,510.621490 66.872940,513.524048 67.881691,513.557678 
	C73.528221,513.745789 73.165520,517.387878 73.014145,521.178406 
	C72.934425,523.174744 72.749794,525.215820 73.052475,527.168457 
	C73.713562,531.433228 72.469391,536.806641 79.202797,537.840698 
	C80.226860,537.997925 80.901794,540.428650 81.831955,541.962402 
	C83.154907,537.770142 83.216469,532.117615 89.950256,534.137939 
	C81.292572,542.742676 82.022293,552.990784 91.709526,559.751770 
	C94.824150,561.925537 98.409576,563.411926 101.672829,565.389038 
	C105.492249,567.703064 109.510315,569.843079 112.865227,572.729248 
	C116.694237,576.023254 116.288994,581.751160 112.650642,585.114929 
	C108.855377,588.623779 100.195831,589.321106 95.364601,586.467102 
	C95.496231,585.674500 95.649178,584.860107 95.764557,584.040466 
	C96.274902,580.415283 94.945084,577.317444 91.386642,576.786316 
	C89.035744,576.435486 85.745621,577.383911 84.023315,578.984802 
	C81.437920,581.388062 81.947464,584.957214 84.337868,587.545959 
	C85.976883,589.320923 88.133415,590.763916 90.304230,591.875732 
	C97.978821,595.806519 105.967094,595.208618 113.839066,593.390991 
	C112.227516,594.816467 110.876694,596.657166 109.293732,596.885071 
	C103.710083,597.688721 98.041611,597.888062 92.420044,598.461121 
	C91.667046,598.537842 90.998466,599.442749 90.106583,600.099792 
	C96.608543,603.484070 97.453850,608.955750 97.000130,615.396729 
	C96.809967,618.849854 97.339417,621.442261 100.992615,622.397217 
	C100.651031,624.427185 100.316292,626.083496 99.613113,627.801758 
	C98.501801,627.823242 97.758942,627.782715 96.989845,627.368042 
	C94.727844,623.296692 91.829575,624.921814 88.568016,626.001465 
	C85.753304,626.600586 83.369652,627.200684 80.545563,627.851440 
	C77.741272,627.870178 75.377411,627.838440 72.894958,627.437256 
	C67.432968,626.760010 62.089554,626.452209 56.473709,626.105713 
	C55.791241,625.731018 55.381199,625.394958 54.917717,624.685303 
	C50.661980,620.783142 47.270477,621.013062 44.998543,624.991455 
	C45.001678,625.002319 44.980957,624.994019 44.656548,624.995361 
	C43.894489,625.667297 43.456837,626.337891 43.012573,627.339111 
	C42.942310,628.105774 42.878666,628.541748 42.508415,629.045044 
	C41.801205,631.074951 41.400600,633.037476 40.999996,635.000000 
z"/>
<path fill="#01369A" opacity="1.000000" stroke="none" 
	d="
M429.000000,0.999996 
	C510.020905,1.000000 591.041809,1.000000 672.531372,1.420276 
	C673.125488,9.233256 674.790588,11.017396 682.042419,10.953159 
	C685.072876,10.926317 687.477600,11.221135 688.912537,14.480202 
	C689.427246,15.649248 691.333069,16.273886 692.688965,16.984770 
	C693.954407,17.648251 695.332703,18.096548 697.960205,19.170416 
	C694.632996,19.891718 692.950256,20.256525 690.898499,20.701334 
	C691.710083,22.725040 692.359985,24.345610 692.540649,25.989704 
	C677.113281,26.014910 662.155212,25.987549 647.197510,26.057234 
	C645.447144,26.065388 643.699341,26.608747 641.486938,26.913610 
	C633.685608,26.929171 626.347656,26.935112 618.800232,26.720568 
	C617.380859,27.323250 616.171021,28.146418 614.702271,29.098053 
	C613.952942,29.805698 613.462524,30.384876 612.657104,31.014271 
	C611.881775,31.700815 611.421326,32.337143 610.626770,33.047619 
	C605.521240,37.772953 600.749756,42.424141 595.669434,47.101711 
	C594.932495,47.740604 594.504517,48.353115 593.927795,49.232536 
	C593.164062,50.016186 592.549072,50.532928 591.574585,51.086784 
	C588.650330,52.628384 586.068542,54.105438 583.541992,55.671520 
	C583.221008,55.870461 583.188660,56.534977 582.889954,57.119984 
	C582.757324,57.256180 582.377380,57.242397 581.967529,57.154533 
	C580.042786,57.406883 578.527832,57.747093 576.690918,58.282196 
	C562.693420,63.416706 548.853882,67.954254 535.402588,73.443237 
	C527.024170,76.862167 519.232727,81.719406 511.122040,85.581947 
	C509.963104,83.784645 509.001129,82.197784 507.734985,80.909988 
	C495.519043,68.484459 483.334320,56.026398 470.976715,43.742531 
	C458.152374,30.994715 445.122375,18.453871 432.256012,5.747933 
	C430.915283,4.423930 430.073364,2.594769 429.000000,0.999996 
z"/>
<path fill="#011B6C" opacity="1.000000" stroke="none" 
	d="
M1008.000000,635.000000 
	C984.979126,635.000000 961.958191,635.000000 938.542480,634.638672 
	C940.085938,632.797485 942.729126,631.694214 943.774780,629.737305 
	C944.941528,627.553711 944.782349,624.603638 944.969849,621.970764 
	C945.165527,619.223938 945.837769,617.341919 949.046692,616.674561 
	C950.477051,616.377075 952.825256,613.957886 952.581787,613.205383 
	C950.975708,608.241516 954.190979,606.546448 958.006775,605.050171 
	C956.499390,603.445740 955.353821,602.226501 954.255676,601.057617 
	C956.539124,599.920837 958.418335,598.985291 960.297546,598.049744 
	C960.137512,597.527771 959.977539,597.005798 959.817505,596.483826 
	C956.908447,596.976074 953.803223,596.987671 951.137329,598.075684 
	C947.342102,599.624451 948.272644,603.060364 948.843811,606.242859 
	C948.964539,606.915710 947.906189,607.800232 947.391113,608.587158 
	C946.651733,608.061829 945.640076,607.695129 945.224304,606.981323 
	C944.006592,604.890564 943.026184,602.661560 941.936157,600.453064 
	C942.886841,599.389648 943.876770,597.689392 945.351929,596.757263 
	C948.308838,594.889099 950.424927,593.116089 950.122070,588.978088 
	C949.686584,583.027283 951.442505,581.141907 957.430786,581.165466 
	C960.433655,581.177307 963.430298,582.773254 967.375732,583.930176 
	C967.894470,576.149475 968.332031,567.846741 969.073303,559.571228 
	C969.284363,557.214722 969.728882,553.551758 971.182739,552.870544 
	C976.619507,550.323120 975.789856,545.349365 977.096436,541.093506 
	C977.567993,539.557556 979.010315,537.402344 980.278137,537.198364 
	C984.222717,536.563721 984.965393,534.317810 985.048401,530.901611 
	C985.217468,523.945068 985.524353,516.983521 986.110596,510.052460 
	C986.231812,508.620148 987.543091,506.532074 988.781921,506.094635 
	C992.285706,504.857483 993.484314,502.669647 992.992126,499.245300 
	C992.386353,495.030609 991.945251,490.773560 991.002869,486.632690 
	C990.404236,484.002441 989.022949,481.550323 988.462036,479.011505 
	C994.565247,479.003082 1000.202271,479.109680 1005.830994,478.939880 
	C1007.743103,478.882172 1010.071533,478.681671 1011.455139,477.590057 
	C1016.101685,473.924011 1020.566467,469.989746 1024.813232,465.862183 
	C1034.962769,455.997589 1044.948242,445.964142 1055.434326,435.999512 
	C1063.363525,434.069580 1061.858276,441.922577 1065.941406,444.394470 
	C1057.973267,445.971375 1057.122070,447.140228 1056.976562,454.507294 
	C1056.727173,467.132599 1056.447998,479.760529 1055.849976,492.371643 
	C1055.775513,493.941132 1053.831543,495.562408 1052.491089,496.895660 
	C1050.838013,498.540039 1047.782959,499.634521 1047.341797,501.453674 
	C1046.502441,504.914764 1046.919434,508.734100 1047.186646,512.377441 
	C1047.266846,513.471375 1048.359985,514.812744 1049.363525,515.463196 
	C1054.604614,518.860413 1054.566040,523.536377 1049.132446,526.963562 
	C1045.719727,529.116089 1044.894653,533.868164 1047.350464,537.524780 
	C1047.997437,538.488159 1048.811157,539.339600 1049.395874,540.055847 
	C1045.983398,541.536438 1043.096802,543.480774 1039.973877,543.997925 
	C1033.711792,545.034973 1032.196289,548.637817 1032.981445,554.215698 
	C1033.416260,557.304077 1032.782227,560.265076 1029.213745,560.678406 
	C1025.239502,561.138916 1025.102051,563.431274 1024.923584,566.564087 
	C1024.521118,573.625427 1023.675781,580.660706 1023.086853,587.712891 
	C1022.963318,589.191956 1023.147888,590.699463 1023.224182,592.192139 
	C1023.469299,596.988586 1024.216797,601.828369 1023.787048,606.562195 
	C1023.559509,609.068298 1021.860779,612.136108 1019.848877,613.659912 
	C1012.649414,619.112671 1007.092651,625.175110 1008.000000,635.000000 
M996.210632,591.475281 
	C998.335510,595.290955 1001.542786,595.853394 1004.931763,593.694153 
	C1007.878235,591.816895 1008.484436,588.417664 1006.247925,585.968567 
	C1004.811890,584.395996 1001.623901,583.413879 999.459778,583.741760 
	C995.803528,584.295593 995.477966,587.578247 996.210632,591.475281 
M974.814331,591.601074 
	C978.246033,595.504883 983.171570,595.972351 984.848755,592.183838 
	C985.692139,590.278992 985.625793,586.911987 984.437805,585.464355 
	C983.243103,584.008606 979.960388,583.274475 977.923096,583.723450 
	C974.452332,584.488342 973.837524,587.602295 974.814331,591.601074 
M963.852600,586.543518 
	C962.507385,583.326477 959.784058,582.533875 956.940918,583.563416 
	C955.379822,584.128723 953.442078,586.052490 953.292542,587.527954 
	C953.092346,589.502747 953.884033,592.388916 955.319092,593.489380 
	C956.740234,594.579163 960.273865,594.850830 961.464111,593.829895 
	C963.061035,592.459961 963.296692,589.503174 963.852600,586.543518 
z"/>
<path fill="#04338D" opacity="1.000000" stroke="none" 
	d="
M632.000000,635.000000 
	C602.979126,635.000000 573.958191,635.000000 544.468628,634.544800 
	C544.000000,633.424683 543.911377,632.745789 544.014160,632.097168 
	C544.751953,627.444275 543.087219,625.801453 538.132874,625.863647 
	C528.206665,625.988342 518.272095,625.275391 508.338043,625.026550 
	C504.781586,624.937500 503.613586,623.786682 504.654663,620.070740 
	C505.137024,618.348999 504.043579,616.185730 503.664551,614.222656 
	C501.348633,614.802795 498.761627,614.929688 496.800293,616.102661 
	C495.152100,617.088440 494.551575,620.191956 491.767334,618.137024 
	C489.243774,616.274536 488.170227,614.003540 489.688141,610.794556 
	C490.268311,609.567993 489.942871,607.913086 489.960236,607.622620 
	C484.156647,608.221741 479.271667,608.725952 474.386658,609.230225 
	C474.232727,608.674683 474.078796,608.119141 473.924835,607.563660 
	C474.814880,606.292969 475.704926,605.022339 477.461792,602.514221 
	C471.557434,601.254700 466.278351,600.128540 460.534424,599.002014 
	C454.093658,599.001587 448.117798,599.001587 442.141907,599.001587 
	C442.141357,598.599792 442.140808,598.197937 442.140259,597.796143 
	C446.646881,597.796143 451.153534,597.796143 455.660156,597.796143 
	C455.637329,597.688782 455.614471,597.581482 455.591644,597.474121 
	C450.062347,596.989258 444.533081,596.504395 439.471954,596.013306 
	C445.573303,596.006897 451.213104,596.153198 456.835052,595.901306 
	C458.201721,595.840088 459.509735,594.469055 460.839691,593.308228 
	C460.890472,591.609924 460.946259,590.303650 461.344696,588.796509 
	C463.791626,588.344788 465.895905,588.093994 468.032104,588.200317 
	C468.343353,589.148743 468.512573,589.832214 468.918732,590.317444 
	C474.539764,597.033264 482.654999,598.582214 490.100311,597.063538 
	C495.375366,595.987488 502.240204,594.430847 505.414368,587.859497 
	C508.303864,587.512817 510.764862,587.171143 513.506897,587.010681 
	C521.699524,596.279724 534.936646,597.611572 544.576416,590.015808 
	C545.667786,589.155884 545.561157,586.775635 546.010071,585.100342 
	C544.351318,584.811523 542.566101,583.984924 541.058716,584.339539 
	C537.273438,585.230225 533.613770,586.655029 529.988220,587.532959 
	C534.513489,582.815674 538.822510,578.286072 543.437134,574.092773 
	C547.051819,570.808105 551.044983,567.939880 554.916077,565.337708 
	C554.977722,570.659668 554.809509,575.540527 555.073425,580.397888 
	C555.250244,583.650879 555.750122,586.959839 556.702515,590.060303 
	C557.071777,591.262634 559.085022,591.960022 560.683594,593.004639 
	C565.273926,595.966064 569.479370,594.434875 573.791809,593.158264 
	C577.079285,592.185120 577.972168,590.348328 576.879150,586.955933 
	C576.955566,586.424805 576.979248,586.211182 577.308105,586.035034 
	C581.210510,588.085815 581.883362,585.842590 581.792358,583.024231 
	C581.643372,578.412231 581.196777,573.810303 581.021790,569.198486 
	C580.892944,565.800354 580.997192,562.393311 580.997253,558.743896 
	C580.996948,558.497559 580.995117,558.004883 581.365845,557.998413 
	C584.540833,556.725830 587.345032,555.459717 590.016174,554.498962 
	C583.416016,559.685791 582.735718,566.690430 582.831726,573.987610 
	C582.939209,582.152588 585.215271,589.442383 593.162537,593.007202 
	C601.415649,596.709229 609.529541,594.884094 616.823242,589.947144 
	C617.888428,589.226074 618.615112,586.056030 617.961853,585.220459 
	C617.072266,584.082642 614.596252,583.495056 613.000854,583.782715 
	C611.211914,584.105286 609.718079,585.815613 607.969177,586.659851 
	C604.211914,588.473572 600.335876,589.089111 596.705688,586.415222 
	C592.961609,583.657349 591.534729,579.848511 592.008179,574.809631 
	C599.216309,574.809631 606.184021,574.739197 613.149231,574.838806 
	C616.713928,574.889771 618.913879,573.171448 619.671936,569.914001 
	C621.699463,561.201599 613.875549,550.891357 604.944580,551.189941 
	C599.978394,551.356018 595.066772,553.153564 590.126648,553.840454 
	C593.238586,551.101318 596.354492,548.737488 599.470337,546.373596 
	C599.509338,546.639771 599.548279,546.906006 599.587280,547.172180 
	C602.417542,546.511963 605.247864,545.851746 608.631592,545.062378 
	C608.560303,545.828674 608.418579,547.352112 608.212097,549.571716 
	C611.216431,549.766602 614.269592,549.783813 617.248962,550.261475 
	C618.219482,550.417053 619.601501,551.672791 619.756592,552.599304 
	C620.358582,556.193115 620.542175,559.856995 620.881531,563.494751 
	C621.502625,563.721130 622.123657,563.947449 622.744690,564.173767 
	C624.790527,561.674438 626.832825,559.172180 628.885864,556.678833 
	C629.202515,556.294250 629.617798,555.985352 629.903931,555.582153 
	C634.294556,549.393555 641.416687,549.546875 647.753784,548.184753 
	C650.641846,547.563965 651.554871,546.293152 652.026794,543.704285 
	C652.804810,539.436829 650.748840,537.892090 646.945496,536.970520 
	C645.431946,536.603821 643.224182,534.596985 643.247681,533.373657 
	C643.290405,531.149231 644.267761,527.489624 645.727051,526.993408 
	C652.592468,524.658813 659.746338,523.397705 666.000000,526.707764 
	C666.000000,538.375305 666.000000,549.187622 666.000000,560.466797 
	C665.680298,566.334656 665.479248,571.745972 665.010498,577.134033 
	C664.450439,583.570862 664.860168,584.182678 671.325684,585.096252 
	C671.915405,585.179565 672.444946,585.688538 673.049927,586.386230 
	C675.488342,595.000000 675.488342,595.000000 684.109558,595.000000 
	C685.738892,595.000000 687.368225,595.000000 688.991882,595.375488 
	C687.399963,597.863281 685.815063,599.976562 684.226929,602.087463 
	C683.485901,603.072449 682.327759,603.955444 682.067932,605.052673 
	C681.127319,609.024536 678.344849,609.382568 675.153625,608.808411 
	C673.209290,608.458496 671.378357,607.487549 669.435059,607.121277 
	C666.009521,606.475586 664.427490,607.644836 665.262878,611.467529 
	C666.365784,616.514160 665.355957,617.241699 660.315674,618.111694 
	C656.080444,618.842773 650.351196,617.311890 649.007385,624.132202 
	C648.837097,624.996521 646.304565,625.719299 644.788940,625.919006 
	C642.499390,626.220581 640.090271,625.715149 637.832092,626.116394 
	C636.484680,626.355835 634.847961,627.402405 634.194641,628.565430 
	C633.103333,630.508179 632.695557,632.834900 632.000000,635.000000 
M655.252869,591.888306 
	C658.193604,594.468445 666.330261,595.588196 669.678040,593.517395 
	C670.645447,592.919067 671.290466,591.102417 671.263733,589.861938 
	C671.248352,589.146240 669.682312,588.097778 668.657959,587.852783 
	C666.970093,587.449036 665.155396,587.575317 663.132935,587.463806 
	C663.132935,584.921753 663.133179,582.939209 663.132874,580.956665 
	C663.130432,564.645569 663.191101,548.333801 663.027222,532.024292 
	C663.010986,530.408447 661.618164,527.422302 660.873352,527.425171 
	C656.428040,527.442139 651.946228,527.948669 647.575500,528.798950 
	C646.717896,528.965759 645.908569,531.338684 645.834961,532.734131 
	C645.799194,533.411804 647.434265,534.631104 648.454163,534.812927 
	C650.353149,535.151367 652.355469,534.909973 654.641663,534.909973 
	C654.641663,540.612549 654.641663,545.830688 654.641663,551.253967 
	C653.079163,551.121521 651.776306,550.975281 650.469360,550.905823 
	C634.067627,550.034424 625.085571,559.365784 626.111450,574.019653 
	C627.315369,591.218201 638.159912,599.826355 655.252869,591.888306 
M607.086121,577.038574 
	C605.097046,577.040222 602.744446,576.387451 601.223694,577.232239 
	C599.772522,578.038391 599.188171,580.404846 598.222778,582.085449 
	C599.951660,583.020203 601.710876,584.683228 603.397888,584.613037 
	C604.388672,584.571777 605.264648,581.773560 606.396240,579.872986 
	C607.726013,580.822388 608.982788,581.719666 609.120300,581.817810 
	C612.994141,581.455811 615.818054,581.325562 618.590332,580.876465 
	C619.974792,580.652222 622.271118,580.048828 622.429016,579.267883 
	C623.253113,575.193237 623.522888,571.006470 623.985474,566.858704 
	C623.532043,566.808105 623.078613,566.757568 622.625183,566.706970 
	C620.952148,576.720825 615.790710,581.617798 607.086121,577.038574 
M494.170227,609.021851 
	C498.266083,614.460205 501.130859,614.050293 505.334686,606.613037 
	C502.782013,605.205627 500.319580,603.318604 497.570038,602.692566 
	C496.854706,602.529602 495.243225,606.302185 494.170227,609.021851 
M583.645386,590.878784 
	C582.928467,590.847595 582.211548,590.816406 581.494629,590.785217 
	C582.007202,591.459839 582.519775,592.134399 583.032349,592.809021 
	C583.415527,592.275391 583.798767,591.741760 583.645386,590.878784 
M595.454773,577.714478 
	C595.716675,577.989319 595.976257,578.266846 596.253967,578.524475 
	C596.261108,578.531128 596.468262,578.322205 596.582153,578.213867 
	C596.319946,577.939026 596.057739,577.664124 595.454773,577.714478 
z"/>
<path fill="#011863" opacity="1.000000" stroke="none" 
	d="
M1008.468628,635.000000 
	C1007.092651,625.175110 1012.649414,619.112671 1019.848877,613.659912 
	C1021.860779,612.136108 1023.559509,609.068298 1023.787048,606.562195 
	C1024.216797,601.828369 1023.469299,596.988586 1023.224182,592.192139 
	C1023.147888,590.699463 1022.963318,589.191956 1023.086853,587.712891 
	C1023.675781,580.660706 1024.521118,573.625427 1024.923584,566.564087 
	C1025.102051,563.431274 1025.239502,561.138916 1029.213745,560.678406 
	C1032.782227,560.265076 1033.416260,557.304077 1032.981445,554.215698 
	C1032.196289,548.637817 1033.711792,545.034973 1039.973877,543.997925 
	C1043.096802,543.480774 1045.983398,541.536438 1049.395874,540.055847 
	C1048.811157,539.339600 1047.997437,538.488159 1047.350464,537.524780 
	C1044.894653,533.868164 1045.719727,529.116089 1049.132446,526.963562 
	C1054.566040,523.536377 1054.604614,518.860413 1049.363525,515.463196 
	C1048.359985,514.812744 1047.266846,513.471375 1047.186646,512.377441 
	C1046.919434,508.734100 1046.502441,504.914764 1047.341797,501.453674 
	C1047.782959,499.634521 1050.838013,498.540039 1052.491089,496.895660 
	C1053.831543,495.562408 1055.775513,493.941132 1055.849976,492.371643 
	C1056.447998,479.760529 1056.727173,467.132599 1056.976562,454.507294 
	C1057.122070,447.140228 1057.973267,445.971375 1065.941406,444.394470 
	C1061.858276,441.922577 1063.363525,434.069580 1055.582031,435.701569 
	C1059.430664,431.201172 1063.850586,427.235046 1067.595947,422.710632 
	C1069.908325,419.917297 1075.507324,419.531677 1073.712402,412.917908 
	C1074.868042,414.848358 1075.372437,415.690765 1076.235718,417.132812 
	C1076.921509,413.926910 1077.444946,411.479523 1077.977783,409.498688 
	C1077.991333,484.976837 1077.995728,559.988403 1078.000000,635.000000 
	C1054.979126,635.000000 1031.958252,635.000000 1008.468628,635.000000 
z"/>
<path fill="#012173" opacity="1.000000" stroke="none" 
	d="
M987.992676,479.018311 
	C989.022949,481.550323 990.404236,484.002441 991.002869,486.632690 
	C991.945251,490.773560 992.386353,495.030609 992.992126,499.245300 
	C993.484314,502.669647 992.285706,504.857483 988.781921,506.094635 
	C987.543091,506.532074 986.231812,508.620148 986.110596,510.052460 
	C985.524353,516.983521 985.217468,523.945068 985.048401,530.901611 
	C984.965393,534.317810 984.222717,536.563721 980.278137,537.198364 
	C979.010315,537.402344 977.567993,539.557556 977.096436,541.093506 
	C975.789856,545.349365 976.619507,550.323120 971.182739,552.870544 
	C969.728882,553.551758 969.284363,557.214722 969.073303,559.571228 
	C968.332031,567.846741 967.894470,576.149475 967.375732,583.930176 
	C963.430298,582.773254 960.433655,581.177307 957.430786,581.165466 
	C951.442505,581.141907 949.686584,583.027283 950.122070,588.978088 
	C950.424927,593.116089 948.308838,594.889099 945.351929,596.757263 
	C943.876770,597.689392 942.886841,599.389648 941.936157,600.453064 
	C943.026184,602.661560 944.006592,604.890564 945.224304,606.981323 
	C945.640076,607.695129 946.651733,608.061829 947.391113,608.587158 
	C947.906189,607.800232 948.964539,606.915710 948.843811,606.242859 
	C948.272644,603.060364 947.342102,599.624451 951.137329,598.075684 
	C953.803223,596.987671 956.908447,596.976074 959.817505,596.483826 
	C959.977539,597.005798 960.137512,597.527771 960.297546,598.049744 
	C958.418335,598.985291 956.539124,599.920837 954.255676,601.057617 
	C955.353821,602.226501 956.499390,603.445740 958.006775,605.050171 
	C954.190979,606.546448 950.975708,608.241516 952.581787,613.205383 
	C952.825256,613.957886 950.477051,616.377075 949.046692,616.674561 
	C945.837769,617.341919 945.165527,619.223938 944.969849,621.970764 
	C944.782349,624.603638 944.941528,627.553711 943.774780,629.737305 
	C942.729126,631.694214 940.085938,632.797485 938.073792,634.638672 
	C916.645752,635.000000 895.291565,635.000000 873.468628,634.580200 
	C872.162720,628.100647 875.450195,625.053894 881.450073,624.267212 
	C880.605225,623.052734 880.367493,622.638123 880.060791,622.283020 
	C878.904907,620.944458 877.726501,619.625366 876.556580,618.298950 
	C878.205078,618.097900 879.875732,617.620117 881.497620,617.750427 
	C885.483582,618.070618 886.075439,616.198059 885.120361,612.648804 
	C886.146301,609.209412 887.024719,606.084595 887.894653,602.664551 
	C888.168091,601.877075 888.449890,601.384827 889.133179,600.905762 
	C891.954956,597.000427 896.424683,593.039795 896.345398,589.172424 
	C896.204590,582.297485 899.791382,577.957886 903.346802,572.992371 
	C907.188049,571.082458 910.278076,569.010437 910.108765,564.290833 
	C910.070496,563.221375 911.360107,562.104370 912.033813,561.422974 
	C912.621155,567.709778 913.403259,573.248230 920.350952,575.501587 
	C925.313293,577.111145 929.999023,579.573547 934.882996,581.687744 
	C932.936279,584.849365 928.485352,586.301086 927.595581,584.268188 
	C924.665283,577.572876 918.813477,578.538330 912.916199,577.610718 
	C912.916199,581.365845 912.916199,584.677368 913.023987,588.157227 
	C913.131775,588.325500 913.115479,588.724854 913.076782,589.110840 
	C913.152832,590.141541 913.043091,590.989319 913.415527,591.400818 
	C921.137085,599.932434 937.425659,600.484802 945.252686,590.394348 
	C946.001953,589.428406 951.673340,585.422852 945.330688,582.572632 
	C945.178223,581.122559 945.111145,580.061707 945.355713,578.952026 
	C945.680847,577.990417 946.051819,576.851868 945.643616,576.205261 
	C944.177795,573.883240 942.448730,571.727417 940.638611,569.262939 
	C941.764099,568.427490 942.561035,567.895203 943.291565,567.283508 
	C946.875244,564.282532 947.164001,553.222900 943.420105,551.539124 
	C939.743042,549.885315 935.700928,548.501709 931.729309,548.206665 
	C928.212830,547.945435 924.572998,549.343994 920.924194,549.656921 
	C919.762451,548.962341 918.613464,548.731140 917.576355,548.256042 
	C915.354431,547.238342 913.190247,546.094604 911.442932,545.001709 
	C921.404053,544.992065 921.943848,544.562866 921.949280,535.377502 
	C921.951111,532.392212 922.505981,530.463928 925.752441,529.365295 
	C927.171814,528.884949 928.546387,526.542297 928.859863,524.845459 
	C929.841309,519.534119 930.691895,514.140076 930.865295,508.754578 
	C931.036926,503.422852 930.199585,498.053528 936.712097,495.609039 
	C937.367554,495.363007 937.502258,493.887665 938.017517,493.070343 
	C938.673584,492.029480 939.580872,491.139832 940.183044,490.073792 
	C941.198364,488.276459 942.754272,486.384796 942.736267,484.546082 
	C942.724548,483.350311 940.325806,482.177979 939.092773,480.606750 
	C945.944092,479.845093 952.705078,479.102234 959.452393,479.210480 
	C965.307983,479.304443 971.148132,480.362122 977.428711,480.995911 
	C980.242737,480.336029 982.623169,479.674133 985.377319,479.010681 
	C986.498230,479.012238 987.245483,479.015259 987.992676,479.018311 
z"/>
<path fill="#011460" opacity="1.000000" stroke="none" 
	d="
M1.000000,99.000000 
	C1.000000,66.430176 1.000000,33.860359 1.000000,1.000000 
	C17.353081,1.000000 33.707882,1.000000 50.531296,1.467907 
	C50.666565,9.387406 49.576336,16.904818 50.216965,24.271711 
	C50.833172,31.357773 47.561409,37.418358 46.546574,44.057198 
	C44.024349,44.300121 41.553005,43.952583 39.902946,44.955833 
	C36.498688,47.025650 34.785816,50.661579 36.467785,54.402897 
	C38.015488,57.845551 39.168869,62.738438 45.093399,61.190311 
	C46.049984,60.940346 47.577862,61.288448 48.284683,61.952728 
	C50.980370,64.486191 53.448135,67.262184 56.124290,70.250366 
	C57.164986,71.702431 58.082432,72.851700 58.999817,74.388931 
	C58.833008,76.084167 58.666264,77.391449 58.499519,78.698730 
	C57.916393,78.990540 57.333271,79.282356 56.750149,79.574165 
	C55.715282,78.061119 54.657196,76.563339 53.650284,75.031914 
	C51.398479,71.607132 48.402027,70.833221 46.165215,74.407455 
	C42.575428,80.143600 36.832146,81.331940 31.133821,82.951721 
	C30.660204,83.086342 30.225361,83.360657 29.776327,83.577972 
	C26.277908,85.271072 25.659981,92.581268 28.829512,94.767807 
	C33.948597,98.299271 35.048859,109.646393 30.530764,113.898544 
	C28.131126,116.156921 25.392248,118.054848 22.509071,119.967087 
	C15.800602,113.559624 9.389566,107.300552 2.947796,100.717117 
	C2.278041,99.928497 1.639020,99.464249 1.000000,99.000000 
M24.355036,9.487710 
	C23.479019,11.324066 22.603003,13.160421 21.726986,14.996779 
	C23.783649,15.600371 25.857904,16.652246 27.887758,16.572590 
	C28.632021,16.543383 29.778917,13.859020 29.853714,12.342043 
	C30.015366,9.063629 27.586958,8.881906 24.355036,9.487710 
z"/>
<path fill="#01257E" opacity="1.000000" stroke="none" 
	d="
M224.000000,1.000000 
	C234.687561,1.000000 245.375122,1.000000 256.536163,1.365088 
	C258.038635,3.349554 258.802277,5.245131 260.146454,6.536392 
	C264.143982,10.376537 269.348236,13.340902 272.273560,17.826258 
	C276.499725,24.306234 281.140106,29.796812 287.997894,33.441639 
	C288.752136,33.842514 288.695679,35.768776 289.017883,37.353764 
	C293.347198,42.891285 297.673859,48.062607 302.554382,53.895901 
	C299.668243,54.194950 296.408478,54.532715 293.148712,54.870480 
	C293.097931,55.294998 293.047119,55.719513 292.996338,56.144028 
	C296.274445,57.007439 299.552582,57.870850 303.141479,58.816105 
	C302.284668,60.459526 301.633911,61.707706 300.521912,62.970310 
	C296.042603,62.983192 292.024536,62.981651 287.954773,62.612854 
	C284.725525,58.882553 283.151917,59.049706 280.525055,62.988327 
	C258.116882,63.006664 236.176819,63.035847 214.236938,62.988617 
	C212.484100,62.984844 210.732407,62.453468 209.021255,61.786133 
	C209.313217,60.289448 209.810989,59.165062 209.771378,58.059937 
	C209.616302,53.733864 208.385818,49.222363 209.249496,45.138103 
	C210.439819,39.509083 207.965759,38.936886 203.645966,38.973438 
	C193.541870,39.058949 183.435181,39.081398 173.333298,38.909153 
	C171.880524,38.884384 170.448547,37.640213 169.318512,36.942299 
	C170.046143,36.918999 170.462204,36.913761 171.358475,36.941116 
	C188.859100,36.647732 205.887772,36.528595 222.894028,35.848595 
	C227.083191,35.681091 230.515442,36.403702 234.130051,38.301281 
	C235.829773,39.193592 238.260132,38.694118 240.359406,38.825336 
	C240.343002,35.922726 240.600357,32.965305 240.040970,30.171408 
	C239.947617,29.705183 236.205093,29.526121 234.323761,29.964769 
	C231.465530,30.631186 228.758896,32.855267 225.990463,32.850658 
	C209.212372,32.822723 192.435532,32.314690 175.656723,32.074406 
	C173.770798,32.047394 171.875229,32.693844 169.535706,33.028214 
	C166.396561,33.011913 163.705841,32.995388 161.011688,32.649460 
	C161.005508,31.880648 161.002747,31.441242 161.243805,30.703993 
	C163.544632,29.938257 165.609146,29.040178 167.657410,29.075867 
	C177.266479,29.243296 186.873199,29.943220 196.476212,29.868229 
	C200.478424,29.836975 205.702454,29.619770 208.169632,27.222048 
	C211.669922,23.820312 215.583267,22.387526 219.730804,21.146040 
	C222.904099,20.196173 224.063309,18.549473 224.017532,15.470528 
	C223.945847,10.647997 223.999451,5.823604 224.000000,1.000000 
M287.554047,55.898956 
	C288.193481,55.410667 288.832916,54.922382 289.472351,54.434090 
	C288.655945,54.054768 287.839569,53.675449 287.023163,53.296127 
	C286.976318,54.095997 286.929504,54.895863 287.554047,55.898956 
z"/>
<path fill="#04277D" opacity="1.000000" stroke="none" 
	d="
M884.972778,612.963379 
	C886.075439,616.198059 885.483582,618.070618 881.497620,617.750427 
	C879.875732,617.620117 878.205078,618.097900 876.556580,618.298950 
	C877.726501,619.625366 878.904907,620.944458 880.060791,622.283020 
	C880.367493,622.638123 880.605225,623.052734 881.450073,624.267212 
	C875.450195,625.053894 872.162720,628.100647 873.000000,634.580200 
	C844.645752,635.000000 816.291565,635.000000 787.474976,634.622681 
	C787.634888,629.448486 790.498962,626.664307 794.927429,625.088379 
	C796.242493,624.620361 798.013245,623.763916 798.405090,622.667358 
	C799.868835,618.570862 800.680664,614.242920 802.112061,610.132446 
	C802.525208,608.945923 804.080750,607.973877 805.331665,607.327759 
	C806.148743,606.905884 807.360413,607.248169 808.494629,607.248169 
	C808.311584,604.539673 808.158264,602.271057 808.395142,600.000977 
	C811.529724,599.022644 814.274109,598.045715 817.678650,596.833740 
	C815.634949,594.894531 814.490601,594.116943 813.809692,593.044922 
	C813.412537,592.419678 813.756897,591.323364 813.767578,590.439026 
	C814.683228,590.515808 815.677856,590.387512 816.500732,590.704834 
	C820.059021,592.077209 823.566956,593.579956 827.093201,595.035278 
	C827.360779,594.655823 827.628357,594.276428 827.895935,593.896973 
	C823.272705,590.431519 823.229675,585.662354 822.797668,580.581909 
	C822.250366,574.146118 827.482727,572.488403 830.752869,568.984985 
	C829.501587,567.824463 828.309143,566.927002 827.373169,565.813904 
	C823.449951,561.148499 824.441833,555.097656 829.065308,554.612915 
	C826.192444,559.942322 826.277344,563.840637 830.093201,565.044617 
	C832.397461,565.771790 835.871521,564.159424 838.255554,562.685425 
	C839.437073,561.954834 839.344055,559.163025 839.816406,557.347168 
	C848.806641,556.869263 851.209534,559.486633 850.429993,569.319092 
	C846.688782,569.319092 842.881531,569.140747 839.097168,569.358826 
	C832.651306,569.730347 827.286682,573.658264 826.152954,578.532043 
	C824.514832,585.574158 826.475098,589.887207 833.139221,594.208740 
	C836.383606,595.242676 839.572510,596.264648 842.810181,596.454285 
	C846.495117,596.670166 850.223206,596.136292 853.933167,595.940613 
	C858.820312,595.682983 863.708008,595.437256 868.672424,595.182495 
	C868.878052,590.690063 871.351318,586.105835 865.353149,585.195984 
	C861.751099,584.649658 861.012390,582.757751 860.998596,579.826538 
	C860.975952,575.005676 860.946167,570.184204 860.993591,565.363770 
	C861.092834,555.283875 857.970032,551.185669 848.705750,548.726013 
	C845.663269,548.193787 842.903076,547.948181 840.142822,547.702637 
	C841.192688,547.173340 842.288269,546.894165 843.327332,547.034241 
	C847.052124,547.536499 849.544189,546.611084 849.401062,542.268555 
	C849.090637,532.846069 853.325439,527.941650 863.066956,528.433228 
	C863.702393,528.465332 864.355286,528.152466 865.340454,528.149536 
	C870.876465,528.855103 872.752014,531.848938 872.020630,536.834045 
	C871.877808,537.807617 871.984558,538.820435 872.002991,539.814636 
	C872.089722,544.492615 874.221924,546.110962 878.877747,545.616638 
	C881.941711,545.291382 885.080383,545.395935 888.164734,545.609192 
	C891.622986,545.848389 895.067261,546.823486 898.500122,546.742310 
	C901.677917,546.667053 904.832642,545.619446 907.997986,545.415283 
	C907.994873,547.213013 907.991333,548.595459 907.549927,549.987549 
	C904.975220,549.796875 902.763062,549.146912 900.717346,549.489929 
	C896.463257,550.203125 891.500305,547.599731 887.712769,551.879517 
	C886.278870,551.170715 885.136963,550.612244 883.604614,550.031128 
	C877.981140,550.009399 872.746216,550.096802 867.516052,549.975830 
	C864.758179,549.912048 862.569763,550.296082 862.080322,553.556274 
	C861.629944,556.556091 862.535461,558.551025 865.542603,559.800537 
	C867.193237,560.486450 869.087646,561.814758 869.781494,563.347290 
	C871.988770,568.222351 873.575500,573.374146 875.626038,578.326294 
	C876.570068,580.606018 878.035461,582.669861 879.210510,584.842407 
	C879.156616,584.853271 879.204041,584.754028 879.189209,585.177979 
	C879.617554,590.234863 880.060608,594.867676 880.577637,600.273987 
	C870.537109,595.396606 867.161133,595.936646 863.639282,601.997925 
	C860.877075,603.126404 858.495178,604.244690 856.113281,605.363037 
	C856.313965,605.901550 856.514587,606.440125 856.715210,606.978699 
	C859.160583,606.978699 861.605896,606.978699 864.242798,607.177490 
	C865.533936,613.604126 868.739868,615.783569 874.773560,614.592529 
	C878.148987,613.926208 881.571472,613.498535 884.972778,612.963379 
M830.193726,597.586304 
	C830.197998,597.206665 830.202271,596.826965 830.206604,596.447327 
	C830.049011,596.445557 829.754517,596.433228 829.753723,596.443604 
	C829.724304,596.821472 829.722290,597.201477 830.193726,597.586304 
z"/>
<path fill="#023290" opacity="1.000000" stroke="none" 
	d="
M693.009949,25.966173 
	C692.359985,24.345610 691.710083,22.725040 690.898499,20.701334 
	C692.950256,20.256525 694.632996,19.891718 697.960205,19.170416 
	C695.332703,18.096548 693.954407,17.648251 692.688965,16.984770 
	C691.333069,16.273886 689.427246,15.649248 688.912537,14.480202 
	C687.477600,11.221135 685.072876,10.926317 682.042419,10.953159 
	C674.790588,11.017396 673.125488,9.233256 673.000000,1.420276 
	C694.354248,1.000000 715.708435,1.000000 737.531372,1.422273 
	C737.598206,5.375142 738.206299,8.014909 742.570435,8.497529 
	C743.688965,8.621230 745.381042,10.323207 745.503235,11.442688 
	C745.893555,15.018648 748.085632,15.881798 751.065735,16.588236 
	C757.312500,18.068993 757.953491,19.828821 754.993958,25.554853 
	C753.402039,28.634867 753.139648,30.920883 757.523071,32.303795 
	C759.409180,32.898811 760.914246,35.757473 761.863403,37.909302 
	C762.539795,39.442562 762.051819,41.514488 761.988831,43.342781 
	C761.898682,45.963844 763.316895,46.754646 765.464478,48.278992 
	C769.878418,51.412052 773.202087,56.082035 776.961853,60.131088 
	C777.047485,60.223263 776.955688,60.461029 776.993530,60.619709 
	C777.638489,63.326145 778.290955,66.030792 779.262146,70.067902 
	C778.534546,70.708878 776.789917,72.366280 774.911621,73.854958 
	C773.789551,74.744278 771.549622,75.194351 771.367432,76.141121 
	C770.166809,82.380615 766.551147,86.315781 760.433350,88.456985 
	C757.029358,86.341911 753.973450,84.299194 750.778320,81.960770 
	C750.041260,81.463516 749.443420,81.261978 748.614502,81.075317 
	C748.049438,80.970215 747.879395,80.732849 747.906433,79.970734 
	C746.954590,78.042320 745.969482,76.521271 744.783081,74.747864 
	C742.697693,72.702293 740.813599,70.909088 739.010620,68.762054 
	C740.218811,67.551743 741.345886,66.695244 742.581970,65.755898 
	C740.173218,64.971031 737.653748,64.885864 736.294983,63.586720 
	C728.102966,55.754360 720.174744,47.646069 712.169373,39.619011 
	C711.824646,39.273376 711.486877,38.856655 711.319458,38.408245 
	C709.353088,33.141891 704.346436,32.009178 699.756653,29.908539 
	C699.271240,29.607599 699.085205,29.351761 698.986694,28.703255 
	C698.312866,27.939392 697.635071,27.507463 696.616089,27.029142 
	C695.186646,26.643888 694.098267,26.305029 693.009949,25.966173 
z"/>
<path fill="#012D89" opacity="1.000000" stroke="none" 
	d="
M632.468628,635.000000 
	C632.695557,632.834900 633.103333,630.508179 634.194641,628.565430 
	C634.847961,627.402405 636.484680,626.355835 637.832092,626.116394 
	C640.090271,625.715149 642.499390,626.220581 644.788940,625.919006 
	C646.304565,625.719299 648.837097,624.996521 649.007385,624.132202 
	C650.351196,617.311890 656.080444,618.842773 660.315674,618.111694 
	C665.355957,617.241699 666.365784,616.514160 665.262878,611.467529 
	C664.427490,607.644836 666.009521,606.475586 669.435059,607.121277 
	C671.378357,607.487549 673.209290,608.458496 675.153625,608.808411 
	C678.344849,609.382568 681.127319,609.024536 682.067932,605.052673 
	C682.327759,603.955444 683.485901,603.072449 684.226929,602.087463 
	C685.815063,599.976562 687.399963,597.863281 689.232910,595.203979 
	C689.653076,594.104675 689.826538,593.552307 690.111938,592.783325 
	C690.423401,592.296143 690.681885,592.105530 691.219482,592.079956 
	C691.959290,592.110840 692.479126,592.056641 693.292664,592.144409 
	C695.064697,593.521790 696.543030,594.757202 697.627441,595.994995 
	C695.943115,596.158142 694.652649,596.318909 692.669983,596.565979 
	C696.057434,600.553528 698.553162,599.601013 701.406555,596.982056 
	C706.202148,596.654968 710.601196,596.326904 715.173462,596.221191 
	C715.860474,596.484680 716.374268,596.525757 716.888000,596.566895 
	C716.890564,596.417603 716.893066,596.268311 716.895569,596.119080 
	C716.263306,596.079773 715.631042,596.040466 715.060303,595.664062 
	C717.116577,595.569397 719.152649,595.636536 721.096497,596.095032 
	C724.054443,596.792664 727.208069,599.067993 729.344849,594.333984 
	C730.079712,594.189514 730.516846,594.187134 731.083008,594.556946 
	C733.426147,595.274109 735.629089,595.750793 737.856384,595.937439 
	C742.913757,596.361145 747.983154,596.642151 752.575073,596.989014 
	C746.869629,596.996826 741.636841,596.996826 736.403992,596.996826 
	C739.592224,600.410828 752.009705,600.773010 754.718079,598.868469 
	C754.929321,598.719910 754.922607,598.261414 755.242798,597.946167 
	C755.468323,597.945435 755.918701,597.970398 756.275269,597.926270 
	C757.076355,596.891357 757.520874,595.900513 758.010254,594.548218 
	C758.056702,592.114563 758.058289,590.042358 758.268494,587.862793 
	C758.731873,587.556091 758.911865,587.303467 759.262817,586.998718 
	C759.508545,586.999878 760.000000,587.000000 760.412109,587.010376 
	C763.882812,587.348022 766.941406,587.675232 770.052368,588.388428 
	C770.746155,591.499695 771.387573,594.224854 771.972290,597.283081 
	C770.610413,598.744080 769.305237,599.872070 767.701111,600.851074 
	C766.035278,599.515686 764.668396,598.329346 763.364441,597.197571 
	C757.251160,600.487366 757.300720,600.461060 760.167358,605.869690 
	C760.653381,606.786804 760.969971,608.338745 760.512512,609.046143 
	C760.115540,609.660034 758.439636,609.823975 757.453491,609.610779 
	C754.145325,608.895508 753.294006,610.880798 753.052246,613.422546 
	C752.863708,615.404663 752.943726,617.420166 753.011597,619.417175 
	C753.137085,623.108704 751.542297,624.883789 747.716370,625.040161 
	C738.474670,625.417847 738.477295,625.508179 736.158020,634.014648 
	C736.071045,634.333740 736.051147,634.671143 736.000000,635.000000 
	C701.645752,635.000000 667.291565,635.000000 632.468628,635.000000 
M707.794373,605.904785 
	C706.372253,605.693176 704.950073,605.481567 703.527954,605.269958 
	C703.721741,606.296143 703.915527,607.322327 704.109314,608.348450 
	C705.380676,607.784607 706.652039,607.220703 707.794373,605.904785 
M691.334717,605.079468 
	C690.350525,605.285828 689.366272,605.492188 688.382080,605.698547 
	C688.356689,606.121643 688.331238,606.544800 688.305847,606.967896 
	C689.549805,607.207947 690.793762,607.447937 692.037720,607.687988 
	C692.034729,606.883423 692.031738,606.078918 691.334717,605.079468 
M730.344849,599.525574 
	C730.147888,599.473877 729.950867,599.422180 729.753906,599.370544 
	C729.880371,599.496521 730.006836,599.622498 730.344849,599.525574 
z"/>
<path fill="#012E8A" opacity="1.000000" stroke="none" 
	d="
M760.781494,88.529327 
	C766.551147,86.315781 770.166809,82.380615 771.367432,76.141121 
	C771.549622,75.194351 773.789551,74.744278 774.911621,73.854958 
	C776.789917,72.366280 778.534546,70.708878 779.262146,70.067902 
	C778.290955,66.030792 777.638489,63.326145 776.993530,60.619709 
	C776.955688,60.461029 777.047485,60.223263 776.961853,60.131088 
	C773.202087,56.082035 769.878418,51.412052 765.464478,48.278992 
	C763.316895,46.754646 761.898682,45.963844 761.988831,43.342781 
	C762.051819,41.514488 762.539795,39.442562 761.863403,37.909302 
	C760.914246,35.757473 759.409180,32.898811 757.523071,32.303795 
	C753.139648,30.920883 753.402039,28.634867 754.993958,25.554853 
	C757.953491,19.828821 757.312500,18.068993 751.065735,16.588236 
	C748.085632,15.881798 745.893555,15.018648 745.503235,11.442688 
	C745.381042,10.323207 743.688965,8.621230 742.570435,8.497529 
	C738.206299,8.014909 737.598206,5.375142 738.000000,1.422273 
	C761.020874,1.000000 784.041809,1.000000 807.531372,1.448094 
	C808.352112,6.006104 808.388672,10.181620 809.222351,14.191460 
	C809.526611,15.654704 811.612793,17.611315 813.083252,17.799091 
	C816.828735,18.277393 817.739929,20.562458 817.966125,23.572041 
	C818.616943,32.232037 817.576721,40.711639 814.601074,49.003510 
	C813.135925,49.003902 812.070374,49.004642 810.686707,49.047958 
	C809.910461,49.722584 809.452393,50.354633 808.581482,50.989300 
	C806.399353,51.256027 804.079407,50.904282 802.965088,51.900730 
	C799.734070,54.790031 796.948486,58.186710 794.074036,61.459755 
	C793.228882,62.422089 792.859924,63.836563 791.947510,64.700401 
	C787.349243,69.054024 782.196594,72.910240 778.099548,77.680588 
	C773.897949,82.572701 769.676025,87.186348 763.593262,90.155930 
	C762.447693,89.594803 761.614563,89.062065 760.781494,88.529327 
z"/>
<path fill="#01257D" opacity="1.000000" stroke="none" 
	d="
M849.000000,1.000000 
	C874.687561,1.000000 900.375122,1.000000 926.531372,1.462659 
	C926.525574,7.412726 929.139343,13.805893 922.435303,17.482616 
	C922.223206,17.598967 922.058594,18.129559 922.133240,18.392988 
	C923.533020,23.330843 923.940186,28.978727 926.723694,32.962753 
	C929.033081,36.268028 930.459717,38.995071 929.833252,42.765011 
	C929.373291,45.532803 928.618469,48.251595 927.541748,50.993649 
	C924.061096,50.993725 921.033875,50.992371 917.925049,50.628395 
	C915.654480,48.575642 913.563171,47.675388 911.525269,50.991104 
	C892.702332,50.989845 874.347717,50.987221 856.085876,50.604881 
	C857.010437,49.820675 857.814392,49.142506 858.678223,49.055134 
	C865.383850,48.376862 866.323486,46.534531 863.980347,40.251034 
	C863.048767,37.752663 863.213257,34.770737 863.236572,32.011974 
	C863.260193,29.221039 863.792175,26.438322 863.982422,23.643898 
	C864.230103,20.006620 862.661926,18.028467 858.804138,17.968023 
	C854.081177,17.894020 850.506714,16.279594 850.064880,10.818694 
	C849.799500,7.538550 849.360779,4.272426 849.000000,1.000000 
z"/>
<path fill="#002173" opacity="1.000000" stroke="none" 
	d="
M927.995117,50.992233 
	C928.618469,48.251595 929.373291,45.532803 929.833252,42.765011 
	C930.459717,38.995071 929.033081,36.268028 926.723694,32.962753 
	C923.940186,28.978727 923.533020,23.330843 922.133240,18.392988 
	C922.058594,18.129559 922.223206,17.598967 922.435303,17.482616 
	C929.139343,13.805893 926.525574,7.412726 927.000000,1.462659 
	C943.354248,1.000000 959.708435,1.000000 976.531372,1.467026 
	C977.000793,5.741493 976.665833,9.593021 977.157349,13.336002 
	C977.372803,14.976539 979.029785,17.347296 980.462891,17.716982 
	C983.445312,18.486319 983.967651,20.060741 984.036621,22.675669 
	C984.268738,31.478754 984.330688,31.477121 991.673523,33.949883 
	C992.578918,37.877209 992.165649,40.799332 987.105774,41.203873 
	C986.320801,41.266632 985.712341,43.536537 984.570801,44.847271 
	C981.105225,44.904522 978.091064,44.900272 974.948059,44.518852 
	C974.295654,43.793278 973.775208,43.146805 973.247925,43.141144 
	C965.311462,43.055923 957.373230,42.998772 949.438171,43.116261 
	C948.590942,43.128803 947.760620,44.276756 946.575562,44.955597 
	C943.813293,47.006180 941.397827,48.999146 938.637451,50.933563 
	C936.605957,50.644661 934.916748,50.215878 933.233643,50.238724 
	C931.484436,50.262474 929.741028,50.722820 927.995117,50.992233 
z"/>
<path fill="#011965" opacity="1.000000" stroke="none" 
	d="
M81.000000,1.000000 
	C84.362099,1.000000 87.724205,1.000000 91.581818,1.347658 
	C94.394493,3.457760 96.711647,5.220204 99.314255,7.125977 
	C100.712357,8.217126 101.824997,9.164948 102.951569,10.484882 
	C104.310440,12.904371 105.655373,14.951747 107.000145,17.415155 
	C108.239838,21.382492 109.479698,24.933794 110.719551,28.485098 
	C109.853188,29.144215 108.986824,29.803331 108.120453,30.462450 
	C107.347275,28.852112 106.938736,26.826183 105.703545,25.742401 
	C104.328766,24.536158 102.201393,24.187653 100.401642,23.465755 
	C100.307610,25.396992 99.437393,27.835890 100.293900,29.145420 
	C101.727509,31.337299 104.262924,32.808533 107.179527,35.306797 
	C104.228264,36.170658 101.378105,37.004929 98.158897,37.947220 
	C102.716057,43.312950 97.569252,45.268463 94.711456,48.155987 
	C93.382973,49.498283 91.575508,50.905769 91.198418,52.560234 
	C89.204483,61.308472 93.860291,67.974068 102.985054,70.380020 
	C100.412239,74.847244 98.466270,79.584152 95.095947,82.852066 
	C91.826820,86.021858 93.194649,87.572945 95.866112,88.680710 
	C99.540169,90.204216 103.555000,90.905930 106.646606,91.748032 
	C106.188919,94.386597 104.799889,97.262466 105.563126,99.352318 
	C106.428780,101.722610 109.221802,103.389023 111.176888,105.361458 
	C108.941711,105.647903 106.853630,104.895866 104.734055,104.246681 
	C103.484383,103.863937 102.184769,103.644234 100.618484,103.247902 
	C99.891960,102.747177 99.454575,102.349754 99.014816,101.563347 
	C98.753914,97.962891 98.298012,94.912399 93.681328,94.854156 
	C91.620514,93.770874 89.861984,92.842697 87.912552,91.793411 
	C87.479462,91.457199 87.365044,91.187813 87.621857,90.707542 
	C90.302574,87.130760 86.156418,86.727295 85.248421,84.840012 
	C84.500175,85.217400 83.751930,85.594788 82.686081,85.937515 
	C81.569931,84.949089 80.771385,83.995308 80.094681,82.763588 
	C80.324615,81.895554 80.432701,81.305458 80.540794,80.715363 
	C79.686073,80.815353 78.831352,80.915344 77.645874,80.950378 
	C71.236076,74.582359 65.157028,68.279305 59.305897,61.971985 
	C59.533806,61.967728 59.988407,61.933281 60.323643,61.951088 
	C61.106377,61.978344 61.553875,61.987801 62.382599,61.986366 
	C63.509014,60.650162 64.254204,59.324852 64.958168,57.636356 
	C63.786144,52.694618 65.022018,51.372650 69.899498,49.812347 
	C71.819298,49.198208 73.965302,47.018543 74.504303,45.123917 
	C74.892693,43.758705 72.986732,41.524174 71.718536,39.997761 
	C71.105469,39.259861 69.224953,39.587574 68.589935,38.852558 
	C67.458069,37.542454 66.128174,35.774464 66.165863,34.229294 
	C66.223389,31.870604 67.026085,27.700937 68.116158,27.494930 
	C74.933670,26.206522 75.191483,21.595123 75.064720,16.292229 
	C75.024803,14.622301 75.405693,11.664334 76.177757,11.455652 
	C82.221733,9.822003 80.945343,5.165174 81.000000,1.000000 
z"/>
<path fill="#011B6D" opacity="1.000000" stroke="none" 
	d="
M985.022217,44.785774 
	C985.712341,43.536537 986.320801,41.266632 987.105774,41.203873 
	C992.165649,40.799332 992.578918,37.877209 991.673523,33.949883 
	C984.330688,31.477121 984.268738,31.478754 984.036621,22.675669 
	C983.967651,20.060741 983.445312,18.486319 980.462891,17.716982 
	C979.029785,17.347296 977.372803,14.976539 977.157349,13.336002 
	C976.665833,9.593021 977.000793,5.741493 977.000000,1.467026 
	C995.354248,1.000000 1013.708496,1.000000 1032.531372,1.464977 
	C1033.000122,4.762407 1033.085938,7.598103 1032.979248,10.426525 
	C1032.833008,14.301594 1033.636963,17.210340 1038.143921,18.223413 
	C1039.331055,18.490236 1040.442017,20.749117 1040.870850,22.292433 
	C1041.344116,23.995810 1041.145874,25.943296 1040.967529,27.757656 
	C1040.645142,31.039278 1041.974121,32.246830 1045.248779,32.084438 
	C1046.742554,32.010357 1048.281494,32.848015 1050.232056,33.392853 
	C1046.189087,37.787113 1042.636108,40.678638 1037.090576,40.233284 
	C1035.837891,40.132687 1034.375854,42.641945 1032.570801,43.968483 
	C1029.425171,44.303017 1026.718872,44.618893 1023.543335,44.941574 
	C1010.390015,44.894176 997.706116,44.839973 985.022217,44.785774 
M1032.212769,33.123959 
	C1031.562744,33.702106 1030.912720,34.280254 1029.212280,35.792671 
	C1033.436646,36.331043 1036.443970,36.714298 1040.212158,37.194530 
	C1038.990723,31.767691 1035.543945,33.356380 1032.212769,33.123959 
z"/>
<path fill="#042271" opacity="1.000000" stroke="none" 
	d="
M226.000000,635.000000 
	C205.645767,635.000000 185.291550,635.000000 164.585999,634.724915 
	C164.460373,633.896240 164.686066,633.342712 165.129623,632.545898 
	C176.242950,621.659607 187.200943,611.078918 197.959045,600.298706 
	C199.391464,598.863342 199.809601,596.415710 200.975800,594.279785 
	C202.242966,593.121094 203.231781,592.119385 204.578690,591.096191 
	C208.436920,587.947083 211.937057,584.819519 215.655136,581.994141 
	C216.753128,584.550415 217.628372,586.806274 218.514160,589.057983 
	C220.807388,594.887268 219.507996,600.346191 214.468323,605.249634 
	C213.907684,604.002502 213.699249,602.376465 212.814636,601.814270 
	C210.998566,600.660156 208.692078,599.231934 206.830383,599.514893 
	C205.242294,599.756287 203.331070,602.008911 202.732864,603.769409 
	C201.466263,607.497070 204.268890,611.071289 208.589661,612.072632 
	C215.171875,613.598022 221.060822,610.458923 223.727982,603.905579 
	C228.104553,593.152100 232.427734,582.376831 237.021133,571.772217 
	C237.572693,572.179138 237.876541,572.424744 238.142319,573.039368 
	C240.228394,577.177795 238.715851,582.885925 236.828995,583.437561 
	C231.657806,584.949463 232.597031,588.863159 232.039673,592.943909 
	C233.922318,591.648438 235.804962,590.352966 236.995361,589.533813 
	C239.684189,591.283936 241.930740,592.676941 244.096390,594.186096 
	C245.121826,594.900635 246.577652,595.687988 246.835449,596.698242 
	C248.191696,602.013550 249.306763,607.371521 253.599991,610.996582 
	C250.175446,612.459717 246.665359,613.366760 244.215424,615.494507 
	C238.951233,620.066345 234.180191,625.215820 229.326843,630.244263 
	C227.999863,631.619080 227.097321,633.403503 226.000000,635.000000 
M231.030518,599.438904 
	C230.786270,599.866821 230.417053,600.268982 230.322617,600.727783 
	C230.093353,601.841431 229.798187,603.012085 229.968719,604.090393 
	C230.013977,604.376526 232.315430,604.753052 232.442612,604.504822 
	C233.142624,603.138489 233.665741,601.609741 233.824295,600.092224 
	C233.860474,599.745911 232.219269,599.224304 231.030518,599.438904 
z"/>
<path fill="#002982" opacity="1.000000" stroke="none" 
	d="
M848.531372,1.000000 
	C849.360779,4.272426 849.799500,7.538550 850.064880,10.818694 
	C850.506714,16.279594 854.081177,17.894020 858.804138,17.968023 
	C862.661926,18.028467 864.230103,20.006620 863.982422,23.643898 
	C863.792175,26.438322 863.260193,29.221039 863.236572,32.011974 
	C863.213257,34.770737 863.048767,37.752663 863.980347,40.251034 
	C866.323486,46.534531 865.383850,48.376862 858.678223,49.055134 
	C857.814392,49.142506 857.010437,49.820675 855.642578,50.608444 
	C852.738708,50.990719 850.370911,50.989708 847.917480,50.617561 
	C845.334595,48.483494 842.947510,47.730122 840.748901,50.993988 
	C840.499268,50.995899 840.000000,50.998932 839.531372,50.997726 
	C832.981018,50.996586 826.892700,51.155659 820.821289,50.905209 
	C818.858337,50.824234 816.939636,49.668148 815.000732,49.003853 
	C817.576721,40.711639 818.616943,32.232037 817.966125,23.572041 
	C817.739929,20.562458 816.828735,18.277393 813.083252,17.799091 
	C811.612793,17.611315 809.526611,15.654704 809.222351,14.191460 
	C808.388672,10.181620 808.352112,6.006104 808.000000,1.448094 
	C821.354248,1.000000 834.708435,1.000000 848.531372,1.000000 
z"/>
<path fill="#012C87" opacity="1.000000" stroke="none" 
	d="
M389.000000,635.000000 
	C365.979095,635.000000 342.958221,635.000000 319.428284,634.645386 
	C314.660492,629.397644 314.462616,623.318237 314.322327,617.236572 
	C315.416748,617.134644 316.152679,617.122925 316.983978,617.433228 
	C318.007019,618.553894 318.934692,619.352539 319.654816,619.972473 
	C318.726990,622.447876 317.277710,624.565552 317.295105,626.671021 
	C317.322327,629.965820 323.666260,633.653931 328.084442,632.816895 
	C331.778473,632.116943 334.143372,629.507202 333.934265,625.408020 
	C333.792480,622.628662 333.382355,619.863037 333.554993,617.085083 
	C342.342773,617.086792 350.667786,617.094604 359.206970,617.319275 
	C360.589050,616.673950 361.756958,615.811768 363.086243,614.659241 
	C364.484619,613.225220 365.721680,612.081604 367.282745,610.913940 
	C368.054504,610.248962 368.502258,609.607971 368.971985,608.979248 
	C368.993958,608.991638 368.966034,608.949524 369.307495,608.925354 
	C370.423279,607.926575 371.197662,606.952026 372.111206,605.723633 
	C372.825775,604.969482 373.401154,604.469177 374.318176,603.941467 
	C375.412415,602.926208 376.165039,601.938354 376.995728,600.623840 
	C378.130035,599.528992 379.186249,598.760864 380.253967,598.138733 
	C380.265472,598.284790 380.603241,598.385620 380.603241,598.385620 
	C380.603241,598.385620 380.752533,598.015076 380.806030,597.699463 
	C380.895447,597.173462 380.931427,596.963135 381.298004,596.768188 
	C382.739471,595.994080 383.850403,595.204590 385.121857,594.763672 
	C393.708313,596.136658 402.194855,598.052185 410.674133,593.540405 
	C416.783600,593.795715 422.454742,593.925964 428.079285,594.406982 
	C426.802826,595.817566 425.495758,596.800720 424.355011,597.949097 
	C413.326965,609.050903 402.309326,620.163208 391.348358,631.331116 
	C390.352905,632.345337 389.772888,633.767334 389.000000,635.000000 
z"/>
<path fill="#012E8A" opacity="1.000000" stroke="none" 
	d="
M439.003815,596.019531 
	C444.533081,596.504395 450.062347,596.989258 455.591644,597.474121 
	C455.614471,597.581482 455.637329,597.688782 455.660156,597.796143 
	C451.153534,597.796143 446.646881,597.796143 442.140259,597.796143 
	C442.140808,598.197937 442.141357,598.599792 442.141907,599.001587 
	C448.117798,599.001587 454.093658,599.001587 460.483307,599.323853 
	C457.707825,600.683350 455.120789,602.272217 456.294464,606.218994 
	C457.301758,609.606384 461.504578,610.006897 469.136383,606.803894 
	C468.272156,609.508423 467.997864,611.316711 467.159637,612.808105 
	C464.826660,616.958740 465.524109,623.099182 468.936554,623.622009 
	C473.178345,624.271912 477.821991,625.370422 481.200531,620.800354 
	C483.357849,617.882263 485.423096,618.299133 487.141479,621.692322 
	C487.957886,623.304443 489.720947,625.679626 490.967010,625.626892 
	C498.254608,625.318359 498.486633,629.776611 498.000000,635.000000 
	C465.312439,635.000000 432.624878,635.000000 399.497559,634.654175 
	C400.263733,632.811218 401.546967,631.368835 402.656830,629.803589 
	C404.195374,627.633728 405.603607,625.371582 407.186432,622.913330 
	C407.586609,622.474304 407.867981,622.270874 408.500122,622.047729 
	C410.102692,621.311523 411.570404,620.816711 412.570618,619.842102 
	C418.383789,614.177917 424.125244,608.438110 429.790833,602.626282 
	C430.760132,601.631958 431.246826,600.167297 432.025452,598.610352 
	C433.025330,597.514221 433.956177,596.727722 435.272827,595.963928 
	C436.424835,595.989502 437.191071,595.992310 438.218964,595.998779 
	C438.655060,596.008118 438.829437,596.013855 439.003815,596.019531 
z"/>
<path fill="#072F84" opacity="1.000000" stroke="none" 
	d="
M226.375000,635.000000 
	C227.097321,633.403503 227.999863,631.619080 229.326843,630.244263 
	C234.180191,625.215820 238.951233,620.066345 244.215424,615.494507 
	C246.665359,613.366760 250.175446,612.459717 254.069229,610.999146 
	C275.061584,610.998474 295.189728,611.021484 315.317688,610.970520 
	C318.098938,610.963440 321.159119,609.747620 323.602722,610.559143 
	C328.945221,612.333313 329.646729,608.505188 330.588837,605.393616 
	C331.602112,602.046997 332.013489,598.375061 327.094330,597.017456 
	C329.069122,597.017456 331.043945,597.017456 333.150604,597.322632 
	C334.501373,598.763245 335.720245,599.898682 336.963928,601.343750 
	C337.011414,602.066589 337.034119,602.479980 337.094910,603.264954 
	C338.641907,604.416260 340.469391,606.141418 341.588623,605.764343 
	C343.487701,605.124512 345.205505,603.349548 346.431274,601.643433 
	C346.840668,601.073547 345.511993,599.254944 344.769958,597.884155 
	C344.304474,597.551697 344.123566,597.289429 344.000000,596.576904 
	C342.649719,592.246887 339.401764,594.639709 336.956726,593.961670 
	C336.864410,593.844604 336.701233,593.595154 336.928711,593.349365 
	C347.128754,587.172424 350.686859,578.407898 348.030548,566.316956 
	C345.820618,556.257874 340.509766,551.858948 329.344788,550.403625 
	C332.651245,548.403320 335.745911,546.663879 339.235046,544.945435 
	C343.487823,544.726685 344.153900,542.152100 344.248962,538.783081 
	C345.652344,537.387695 346.812958,536.191467 348.352600,535.009094 
	C352.673309,536.101318 350.948242,538.649170 349.693970,540.301880 
	C347.133972,543.675110 344.120758,546.704346 340.681183,550.562988 
	C342.909271,551.887817 344.908844,553.076721 347.252777,554.470398 
	C348.769012,545.960205 349.629913,545.022034 354.108398,545.740845 
	C353.542328,547.112488 352.936432,548.580627 352.538300,549.545288 
	C359.596802,548.093323 365.894836,546.797852 372.187592,545.503418 
	C372.869110,547.951904 373.252838,549.330627 373.705811,550.958191 
	C376.652405,550.206604 379.797302,550.173584 381.961273,548.700073 
	C387.253754,545.096313 391.300415,547.835999 395.538544,550.406860 
	C396.762512,551.149292 397.839661,552.133789 398.988220,553.381348 
	C399.661530,554.836914 400.330750,555.918457 401.316650,557.048584 
	C401.093994,559.403381 400.554718,561.709595 400.012512,564.432312 
	C400.008850,566.241699 400.008118,567.634460 399.767090,569.206543 
	C399.088470,570.271790 398.650177,571.157776 398.147949,571.584595 
	C398.064606,569.296326 397.975983,567.464783 398.037109,565.638245 
	C398.414886,554.351624 390.704346,549.098694 380.090363,552.243652 
	C376.972229,553.167542 373.903259,554.257202 370.816040,555.269470 
	C369.427734,551.139771 365.044678,549.684387 359.150360,551.297058 
	C355.752258,552.226685 352.096130,553.090576 354.607910,558.320862 
	C357.178772,558.548828 359.897064,558.789856 362.436432,559.015015 
	C362.677765,559.761902 362.863098,560.069824 362.864471,560.378601 
	C362.896790,567.694946 362.790619,575.014343 362.978455,582.326355 
	C363.064880,585.690552 362.353699,587.604614 358.473511,587.855957 
	C357.238281,587.935974 356.141510,590.154114 354.981384,591.393860 
	C356.295898,592.236145 357.574890,593.734131 358.931091,593.807678 
	C364.898682,594.131165 370.888550,594.043457 376.886292,594.317505 
	C376.902191,594.530151 376.951355,594.953796 376.747070,595.223328 
	C372.468536,599.048706 367.938599,602.210022 364.433044,606.258301 
	C359.857727,611.541931 354.579163,613.165833 347.768707,613.070801 
	C326.509247,612.774170 305.242340,613.013733 283.511536,613.021240 
	C273.277985,612.992004 263.507935,612.852417 253.747025,613.089783 
	C251.674118,613.140259 249.165482,614.060730 247.667328,615.452148 
	C243.082993,619.709900 238.876984,624.376953 234.556625,628.916077 
	C232.663483,630.905090 230.849792,632.969727 229.000000,635.000000 
	C228.250000,635.000000 227.500000,635.000000 226.375000,635.000000 
z"/>
<path fill="#022B82" opacity="1.000000" stroke="none" 
	d="
M736.468628,635.000000 
	C736.051147,634.671143 736.071045,634.333740 736.158020,634.014648 
	C738.477295,625.508179 738.474670,625.417847 747.716370,625.040161 
	C751.542297,624.883789 753.137085,623.108704 753.011597,619.417175 
	C752.943726,617.420166 752.863708,615.404663 753.052246,613.422546 
	C753.294006,610.880798 754.145325,608.895508 757.453491,609.610779 
	C758.439636,609.823975 760.115540,609.660034 760.512512,609.046143 
	C760.969971,608.338745 760.653381,606.786804 760.167358,605.869690 
	C757.300720,600.461060 757.251160,600.487366 763.364441,597.197571 
	C764.668396,598.329346 766.035278,599.515686 767.662231,601.208862 
	C766.611572,604.105774 765.300903,606.496033 763.575378,609.642822 
	C765.365662,609.642822 766.838684,610.090332 767.387024,609.567688 
	C770.199951,606.886597 772.841919,606.927063 776.396118,608.417603 
	C779.504333,609.721130 786.065186,604.200928 784.605469,601.974304 
	C782.112122,598.170715 784.294861,595.931030 785.914307,593.262573 
	C789.630798,593.515259 793.429871,594.856995 796.287842,591.179077 
	C797.391479,591.902771 798.239624,592.436646 799.236816,593.199951 
	C799.646423,593.897522 799.906799,594.365540 800.183044,595.208252 
	C801.793274,596.051208 803.387573,596.519409 805.213135,597.162231 
	C806.297913,598.225281 807.151367,599.113892 808.004883,600.002441 
	C808.158264,602.271057 808.311584,604.539673 808.494629,607.248169 
	C807.360413,607.248169 806.148743,606.905884 805.331665,607.327759 
	C804.080750,607.973877 802.525208,608.945923 802.112061,610.132446 
	C800.680664,614.242920 799.868835,618.570862 798.405090,622.667358 
	C798.013245,623.763916 796.242493,624.620361 794.927429,625.088379 
	C790.498962,626.664307 787.634888,629.448486 787.006287,634.622681 
	C770.312439,635.000000 753.624878,635.000000 736.468628,635.000000 
M800.193420,600.586304 
	C800.197815,600.206787 800.202209,599.827209 800.206604,599.447632 
	C800.049133,599.445923 799.754822,599.433533 799.754028,599.444092 
	C799.724548,599.821777 799.722473,600.201599 800.193420,600.586304 
z"/>
<path fill="#002173" opacity="1.000000" stroke="none" 
	d="
M223.531342,1.000000 
	C223.999451,5.823604 223.945847,10.647997 224.017532,15.470528 
	C224.063309,18.549473 222.904099,20.196173 219.730804,21.146040 
	C215.583267,22.387526 211.669922,23.820312 208.169632,27.222048 
	C205.702454,29.619770 200.478424,29.836975 196.476212,29.868229 
	C186.873199,29.943220 177.266479,29.243296 167.657410,29.075867 
	C165.609146,29.040178 163.544632,29.938257 160.843155,30.703457 
	C159.129974,30.991104 158.061218,30.981443 157.005066,30.591208 
	C158.342743,28.197344 160.611618,26.278194 160.823532,24.153856 
	C161.589661,16.473209 161.664307,8.723585 162.000000,1.000000 
	C182.354233,1.000000 202.708450,1.000000 223.531342,1.000000 
z"/>
<path fill="#011965" opacity="1.000000" stroke="none" 
	d="
M1033.010132,43.949814 
	C1034.375854,42.641945 1035.837891,40.132687 1037.090576,40.233284 
	C1042.636108,40.678638 1046.189087,37.787113 1050.232056,33.392853 
	C1048.281494,32.848015 1046.742554,32.010357 1045.248779,32.084438 
	C1041.974121,32.246830 1040.645142,31.039278 1040.967529,27.757656 
	C1041.145874,25.943296 1041.344116,23.995810 1040.870850,22.292433 
	C1040.442017,20.749117 1039.331055,18.490236 1038.143921,18.223413 
	C1033.636963,17.210340 1032.833008,14.301594 1032.979248,10.426525 
	C1033.085938,7.598103 1033.000122,4.762407 1033.000000,1.464977 
	C1047.687622,1.000000 1062.375122,1.000000 1077.531372,1.468657 
	C1077.993652,15.627919 1077.987305,29.318523 1077.604248,43.005402 
	C1076.471436,43.013527 1075.715698,43.025383 1074.493652,43.040413 
	C1060.355103,43.345661 1046.682617,43.647736 1033.010132,43.949814 
z"/>
<path fill="#002982" opacity="1.000000" stroke="none" 
	d="
M264.000000,0.999998 
	C279.020905,1.000000 294.041779,1.000000 309.532318,1.399674 
	C310.937103,8.383277 313.041321,10.334585 321.000000,11.999075 
	C321.000000,11.999962 321.002075,12.000444 321.058594,12.342031 
	C322.074829,13.122955 323.034515,13.562291 324.024719,14.318636 
	C324.374847,15.417953 324.694427,16.200260 325.017090,17.376854 
	C325.929657,20.962097 326.839111,24.153055 327.760925,27.387268 
	C325.605774,26.748543 323.816437,26.218241 322.027130,25.687941 
	C322.476959,27.673237 322.926788,29.658531 323.385376,31.682520 
	C316.295624,35.585270 316.295624,35.585270 322.599487,41.464169 
	C320.006714,42.529732 317.003357,43.764034 313.563507,44.999313 
	C307.977356,46.094788 305.596313,42.825626 302.334961,39.457966 
	C292.312225,29.108545 281.482635,19.544769 271.111603,9.525570 
	C268.465363,6.969104 266.356018,3.856879 264.000000,0.999998 
z"/>
<path fill="#001B6E" opacity="1.000000" stroke="none" 
	d="
M161.531342,1.000000 
	C161.664307,8.723585 161.589661,16.473209 160.823532,24.153856 
	C160.611618,26.278194 158.342743,28.197344 156.537537,30.599171 
	C149.660858,31.049295 143.264297,31.110880 136.557922,31.164185 
	C135.834900,31.155127 135.421707,31.154346 134.802856,30.857571 
	C132.759323,30.018198 130.921432,29.474819 129.014465,28.596203 
	C121.692558,20.770948 114.402557,13.316250 107.220978,5.758520 
	C105.919472,4.388848 105.062302,2.596967 104.000000,1.000000 
	C123.020897,1.000000 142.041794,1.000000 161.531342,1.000000 
z"/>
<path fill="#012D89" opacity="1.000000" stroke="none" 
	d="
M321.000000,0.999997 
	C336.687561,1.000000 352.375122,1.000000 368.800720,1.327078 
	C375.016296,1.918415 380.493866,2.182675 385.995941,2.795161 
	C387.146698,4.094131 388.224457,5.111260 389.411560,5.978634 
	C390.863617,7.039580 392.407379,7.974996 393.911469,8.964689 
	C392.519867,10.982518 391.311096,13.169068 389.665375,14.952379 
	C388.736847,15.958513 386.247253,16.004454 385.900421,16.989708 
	C383.465790,23.905443 377.177795,21.915789 372.402863,22.845484 
	C368.560181,23.593670 364.451843,23.113832 360.468628,22.973536 
	C353.642761,22.733116 346.822571,22.332289 339.622742,21.987000 
	C338.652191,21.741390 337.871704,21.673120 337.495392,21.247484 
	C334.634888,18.012186 331.787323,14.760653 329.065765,11.408602 
	C326.299866,8.001862 323.682465,4.474523 321.000000,0.999997 
z"/>
<path fill="#01247B" opacity="1.000000" stroke="none" 
	d="
M313.963806,617.326782 
	C314.462616,623.318237 314.660492,629.397644 318.959625,634.645386 
	C292.312439,635.000000 265.624878,635.000000 238.463226,634.627930 
	C237.951019,633.759155 237.912888,633.262451 237.939636,632.446289 
	C239.349548,630.757019 240.694534,629.387207 242.404388,628.008301 
	C246.193268,624.705566 249.617279,621.411926 253.317795,617.807861 
	C263.731354,617.348633 273.868408,617.199768 284.467529,617.077759 
	C288.935089,617.183777 292.940582,617.262939 296.984283,617.710571 
	C299.707794,621.125671 302.367889,621.224487 305.410278,617.988037 
	C308.535156,617.752380 311.249481,617.539551 313.963806,617.326782 
z"/>
<path fill="#011662" opacity="1.000000" stroke="none" 
	d="
M80.545891,1.000000 
	C80.945343,5.165174 82.221733,9.822003 76.177757,11.455652 
	C75.405693,11.664334 75.024803,14.622301 75.064720,16.292229 
	C75.191483,21.595123 74.933670,26.206522 68.116158,27.494930 
	C67.026085,27.700937 66.223389,31.870604 66.165863,34.229294 
	C66.128174,35.774464 67.458069,37.542454 68.589935,38.852558 
	C69.224953,39.587574 71.105469,39.259861 71.718536,39.997761 
	C72.986732,41.524174 74.892693,43.758705 74.504303,45.123917 
	C73.965302,47.018543 71.819298,49.198208 69.899498,49.812347 
	C65.022018,51.372650 63.786144,52.694618 64.576965,57.648254 
	C63.491787,59.347977 62.746582,60.672615 62.001373,61.997253 
	C61.553875,61.987801 61.106377,61.978344 60.327187,61.557785 
	C61.515854,56.476292 58.992538,52.803173 56.951439,48.634388 
	C55.461201,45.194199 53.349663,45.431236 50.653267,47.043625 
	C49.948517,46.988392 49.545845,46.956879 49.026802,46.644444 
	C48.260033,45.984554 47.609634,45.605587 46.964783,44.939568 
	C46.969978,44.461102 46.969627,44.269695 46.969280,44.078285 
	C47.561409,37.418358 50.833172,31.357773 50.216965,24.271711 
	C49.576336,16.904818 50.666565,9.387406 50.999954,1.467907 
	C55.356899,1.000000 59.713802,1.000000 64.535385,1.430984 
	C65.154510,6.431645 67.090408,8.545248 69.501785,6.613643 
	C70.719040,5.638576 70.544968,2.926610 71.000000,1.000000 
	C74.030594,1.000000 77.061188,1.000000 80.545891,1.000000 
z"/>
<path fill="#012F8C" opacity="1.000000" stroke="none" 
	d="
M498.468658,635.000000 
	C498.486633,629.776611 498.254608,625.318359 490.967010,625.626892 
	C489.720947,625.679626 487.957886,623.304443 487.141479,621.692322 
	C485.423096,618.299133 483.357849,617.882263 481.200531,620.800354 
	C477.821991,625.370422 473.178345,624.271912 468.936554,623.622009 
	C465.524109,623.099182 464.826660,616.958740 467.159637,612.808105 
	C467.997864,611.316711 468.272156,609.508423 469.136383,606.803894 
	C461.504578,610.006897 457.301758,609.606384 456.294464,606.218994 
	C455.120789,602.272217 457.707825,600.683350 460.948181,599.324219 
	C466.278351,600.128540 471.557434,601.254700 477.461792,602.514221 
	C475.704926,605.022339 474.814880,606.292969 473.924835,607.563660 
	C474.078796,608.119141 474.232727,608.674683 474.386658,609.230225 
	C479.271667,608.725952 484.156647,608.221741 489.960236,607.622620 
	C489.942871,607.913086 490.268311,609.567993 489.688141,610.794556 
	C488.170227,614.003540 489.243774,616.274536 491.767334,618.137024 
	C494.551575,620.191956 495.152100,617.088440 496.800293,616.102661 
	C498.761627,614.929688 501.348633,614.802795 503.664551,614.222656 
	C504.043579,616.185730 505.137024,618.348999 504.654663,620.070740 
	C503.613586,623.786682 504.781586,624.937500 508.338043,625.026550 
	C518.272095,625.275391 528.206665,625.988342 538.132874,625.863647 
	C543.087219,625.801453 544.751953,627.444275 544.014160,632.097168 
	C543.911377,632.745789 544.000000,633.424683 544.000000,634.544800 
	C528.979126,635.000000 513.958191,635.000000 498.468658,635.000000 
z"/>
<path fill="#0A4DBA" opacity="1.000000" stroke="none" 
	d="
M200.697449,594.436890 
	C199.809601,596.415710 199.391464,598.863342 197.959045,600.298706 
	C187.200943,611.078918 176.242950,621.659607 164.671005,632.588928 
	C161.503586,632.908630 159.012634,632.971008 156.521683,632.971252 
	C124.796791,632.973938 93.071777,632.934814 61.347324,633.050720 
	C59.562531,633.057251 57.782333,634.321228 56.000000,635.000000 
	C55.250000,635.000000 54.500000,635.000000 53.435158,634.646545 
	C53.318745,633.843811 53.690163,633.392517 53.684887,632.945618 
	C53.664841,631.249390 54.169975,628.809998 53.275749,628.032898 
	C51.915356,626.850830 49.323231,626.058289 47.691891,626.550598 
	C46.451340,626.924927 45.573841,629.475159 45.142017,631.208557 
	C44.862270,632.331482 45.676159,633.726746 46.000000,635.000000 
	C45.250000,635.000000 44.500000,635.000000 43.395378,634.570190 
	C42.965511,632.419556 42.890266,630.698608 42.815025,628.977722 
	C42.878666,628.541748 42.942310,628.105774 43.279415,627.222656 
	C44.028900,626.181702 44.504929,625.587891 44.980957,624.994019 
	C44.980957,624.994019 45.001678,625.002319 45.390633,624.948547 
	C48.843445,624.949524 51.907299,625.004211 54.971161,625.058960 
	C55.381199,625.394958 55.791241,625.731018 56.640503,626.476624 
	C62.390995,627.193054 67.702271,627.499878 73.013550,627.806641 
	C75.377411,627.838440 77.741272,627.870178 80.986679,627.925598 
	C84.251266,627.906677 86.634293,627.864136 89.458542,627.870911 
	C92.271866,627.860901 94.643974,627.801514 97.016075,627.742188 
	C97.758942,627.782715 98.501801,627.823242 100.072823,627.887878 
	C104.934227,627.871826 108.967468,627.831604 113.464333,627.864868 
	C128.240128,627.954895 142.554138,628.091431 156.862686,627.872009 
	C159.296646,627.834717 162.325790,626.922668 164.027557,625.306580 
	C174.556503,615.307556 184.784470,604.991577 195.490936,594.635620 
	C197.478775,594.472534 199.088120,594.454712 200.697449,594.436890 
z"/>
<path fill="#022D9A" opacity="1.000000" stroke="none" 
	d="
M263.562500,0.999998 
	C266.356018,3.856879 268.465363,6.969104 271.111603,9.525570 
	C281.482635,19.544769 292.312225,29.108545 302.334961,39.457966 
	C305.596313,42.825626 307.977356,46.094788 314.003357,45.001266 
	C322.454651,45.212921 330.029419,45.589653 337.604553,45.597198 
	C350.506714,45.610050 363.409210,45.402405 376.311310,45.263741 
	C381.871216,45.203991 387.430542,45.085194 392.988770,45.354225 
	C392.325104,47.809399 391.662842,49.903561 390.769409,52.259895 
	C388.024353,52.013908 385.510468,51.505753 382.912872,50.625626 
	C380.334198,48.486416 377.949066,47.727287 375.534363,50.998676 
	C368.376251,50.999317 361.686890,50.998867 354.927124,50.637924 
	C349.049835,46.220856 343.153503,49.397160 337.294006,50.099598 
	C337.161041,50.115540 337.094879,50.688526 336.529083,51.003487 
	C328.706665,51.003220 321.352722,51.001152 313.914612,50.626968 
	C311.334900,48.486290 308.949158,47.726269 306.658997,50.943291 
	C304.342773,49.228920 302.404327,47.526562 300.389923,45.919411 
	C296.621643,42.912975 292.809265,39.961758 289.015259,36.987564 
	C288.695679,35.768776 288.752136,33.842514 287.997894,33.441639 
	C281.140106,29.796812 276.499725,24.306234 272.273560,17.826258 
	C269.348236,13.340902 264.143982,10.376537 260.146454,6.536392 
	C258.802277,5.245131 258.038635,3.349554 257.004822,1.365088 
	C259.041656,1.000000 261.083344,1.000000 263.562500,0.999998 
z"/>
<path fill="#01319F" opacity="1.000000" stroke="none" 
	d="
M320.555847,0.999997 
	C323.682465,4.474523 326.299866,8.001862 329.065765,11.408602 
	C331.787323,14.760653 334.634888,18.012186 337.495392,21.247484 
	C337.871704,21.673120 338.652191,21.741390 339.860962,22.260870 
	C344.419250,23.363453 348.351593,24.820244 352.306793,24.885321 
	C368.532410,25.152279 384.764984,24.995354 401.461578,24.996099 
	C405.353699,24.997919 408.793640,25.178520 412.197479,24.906847 
	C413.507172,24.802319 414.734680,23.668392 416.257446,22.893400 
	C417.010193,22.521973 417.505096,22.260641 418.438721,21.999535 
	C422.232452,22.403332 426.812866,20.409983 427.796356,25.715448 
	C428.177032,27.769051 427.549835,31.655880 426.484680,32.010120 
	C423.728180,32.926849 419.834290,33.455746 417.540924,32.140488 
	C411.643951,28.758566 405.941284,29.749050 399.537842,30.996746 
	C394.381104,30.995522 389.690765,30.992899 384.901978,30.598671 
	C379.390625,30.012869 373.977997,29.805582 368.563446,29.682409 
	C368.385742,29.678366 368.188629,30.530025 367.531891,30.986380 
	C362.567719,30.992718 358.028687,30.595079 353.585419,31.078205 
	C343.010773,32.228008 335.601166,27.477295 329.541412,19.424408 
	C328.604767,18.179670 326.551117,17.775476 325.014008,16.982567 
	C324.694427,16.200260 324.374847,15.417953 323.882965,14.030887 
	C322.807800,12.950897 321.904938,12.475667 321.002075,12.000441 
	C321.002075,12.000444 321.000000,11.999962 320.991272,11.635235 
	C320.502502,10.521045 320.084137,9.722301 319.531525,9.030842 
	C317.377441,6.335448 315.180817,3.674041 313.000000,1.000001 
	C315.370575,1.000000 317.741119,1.000000 320.555847,0.999997 
z"/>
<path fill="#0E8ED8" opacity="1.000000" stroke="none" 
	d="
M428.555847,0.999996 
	C430.073364,2.594769 430.915283,4.423930 432.256012,5.747933 
	C445.122375,18.453871 458.152374,30.994715 470.976715,43.742531 
	C483.334320,56.026398 495.519043,68.484459 507.734985,80.909988 
	C509.001129,82.197784 509.963104,83.784645 510.996582,85.878677 
	C509.833832,87.054192 508.740662,87.583778 507.286835,87.996574 
	C505.330048,86.439484 503.661194,85.071365 502.148712,83.548027 
	C479.768494,61.007183 457.409027,38.445751 434.987000,15.580156 
	C434.277008,14.857150 433.624664,14.443180 432.986145,14.014681 
	C433.000000,14.000153 432.970398,14.027052 432.956696,13.689727 
	C432.002289,12.543552 431.061615,11.734703 430.082581,10.695108 
	C430.044281,10.464363 429.993774,9.998342 429.841217,9.755332 
	C429.160400,9.323520 428.632111,9.134717 427.843079,8.931118 
	C427.408264,8.914957 427.234161,8.913590 427.017761,8.565181 
	C424.954498,6.190952 422.933502,4.163764 420.939087,1.852798 
	C420.977142,1.379346 420.988556,1.189673 421.000000,1.000000 
	C423.370575,1.000000 425.741119,1.000000 428.555847,0.999996 
z"/>
<path fill="#E7F9FF" opacity="1.000000" stroke="none" 
	d="
M1080.000000,0.999999 
	C1080.333374,1.166665 1080.943848,1.315913 1080.955444,1.502795 
	C1081.038940,2.830885 1081.000000,4.166667 1081.000000,5.500000 
	C1081.000000,213.833328 1081.002075,422.166656 1080.958618,630.500000 
	C1080.958374,632.000061 1080.333252,633.500000 1079.828735,634.724365 
	C1079.691284,633.283752 1079.754883,632.118835 1079.755005,630.953918 
	C1079.766968,555.300964 1079.784912,479.647980 1079.765015,403.995026 
	C1079.762939,396.009674 1079.534058,388.024353 1079.546631,379.665527 
	C1079.670044,378.231262 1079.657471,377.170471 1079.776733,375.713867 
	C1079.822021,374.499268 1079.735352,373.680542 1079.698975,372.483551 
	C1079.724609,371.088562 1079.699951,370.071869 1079.808472,368.599335 
	C1079.845581,360.390167 1079.749390,352.636780 1079.692139,344.423889 
	C1079.662476,333.633636 1079.593872,323.302979 1079.635254,312.517456 
	C1079.740845,287.816071 1079.765625,263.569458 1079.721680,239.323013 
	C1079.691895,222.889862 1079.565552,206.456894 1079.606079,189.655411 
	C1079.652832,188.518890 1079.575806,187.750778 1079.621094,186.604752 
	C1079.678467,185.169235 1079.613281,184.111618 1079.641846,182.728302 
	C1079.723267,181.954788 1079.710815,181.507019 1079.801270,180.757751 
	C1079.826538,180.258636 1079.748779,180.061005 1079.707031,179.404877 
	C1079.652222,168.910828 1079.561279,158.875275 1079.591797,148.541931 
	C1079.645020,147.161789 1079.576538,146.079422 1079.645630,144.586823 
	C1079.715576,142.457855 1079.647705,140.739136 1079.645264,138.549637 
	C1079.720459,128.128052 1079.764404,118.177116 1079.732910,108.226425 
	C1079.671631,88.830551 1079.568115,69.434807 1079.604980,49.618942 
	C1079.657104,47.460758 1079.586304,45.722637 1079.616455,43.527161 
	C1079.811646,29.046537 1079.905884,15.023269 1080.000000,0.999999 
z"/>
<path fill="#000B41" opacity="1.000000" stroke="none" 
	d="
M1079.410400,380.039032 
	C1079.534058,388.024353 1079.762939,396.009674 1079.765015,403.995026 
	C1079.784912,479.647980 1079.766968,555.300964 1079.755005,630.953918 
	C1079.754883,632.118835 1079.691284,633.283752 1079.328735,634.724365 
	C1079.000000,635.000000 1078.500000,635.000000 1078.250000,635.000000 
	C1077.995728,559.988403 1077.991333,484.976837 1077.986450,409.026306 
	C1077.975830,401.378448 1077.965820,394.669586 1077.966797,387.522888 
	C1078.010010,384.745361 1078.042114,382.405579 1078.297485,380.065430 
	C1078.520752,380.065063 1078.966675,380.091003 1078.966675,380.091003 
	C1078.966675,380.091003 1079.410400,380.039032 1079.410400,380.039032 
z"/>
<path fill="#0439BA" opacity="1.000000" stroke="none" 
	d="
M135.008499,31.153564 
	C135.421707,31.154346 135.834900,31.155127 136.704315,31.531696 
	C138.681580,32.266502 140.197601,32.908169 141.724548,32.935352 
	C148.152969,33.049786 154.584641,32.980755 161.015121,32.978863 
	C163.705841,32.995388 166.396561,33.011913 169.676270,33.338486 
	C170.469604,34.735199 170.673935,35.821861 170.878265,36.908524 
	C170.462204,36.913761 170.046143,36.918999 168.874237,36.936241 
	C165.749466,36.941475 163.380524,36.934696 160.701508,36.652634 
	C153.926971,36.590588 147.462494,36.803825 140.569855,37.013065 
	C138.428528,37.010635 136.715347,37.012199 134.745880,36.739239 
	C130.472229,36.487057 127.554169,34.505196 124.987740,31.584667 
	C123.980019,30.437920 122.404861,29.789820 121.020561,28.578714 
	C118.318230,25.817396 115.683464,23.391998 113.007492,20.640493 
	C111.970749,19.228354 110.975220,18.142319 109.752594,17.022732 
	C109.525490,16.989180 109.067780,16.952883 108.912262,16.659328 
	C106.817039,14.281440 104.877335,12.197104 102.937637,10.112768 
	C101.824997,9.164948 100.712357,8.217126 99.174042,6.828444 
	C97.498917,4.591722 96.249458,2.795860 95.000000,0.999999 
	C97.700012,1.000000 100.400032,1.000000 103.550018,1.000000 
	C105.062302,2.596967 105.919472,4.388848 107.220978,5.758520 
	C114.402557,13.316250 121.692558,20.770948 129.134399,28.915031 
	C131.218445,30.097252 133.113480,30.625406 135.008499,31.153564 
z"/>
<path fill="#0946BC" opacity="1.000000" stroke="none" 
	d="
M2.978532,101.041489 
	C9.389566,107.300552 15.800602,113.559624 22.591047,120.298370 
	C24.237881,122.268303 25.442177,123.818237 26.784334,125.237816 
	C31.158546,129.864395 35.583099,134.443390 39.980232,139.417938 
	C38.904732,140.562607 37.837280,141.330002 36.769829,142.097412 
	C35.724739,141.478699 34.679646,140.859970 33.310265,139.764908 
	C32.000580,138.508682 31.015181,137.728790 30.029783,136.948914 
	C28.564831,135.384796 27.099880,133.820679 25.299198,131.776215 
	C23.986633,130.509476 23.009798,129.723114 22.032963,128.936752 
	C20.570892,127.375366 19.108824,125.813988 17.307434,123.772491 
	C15.987930,122.510551 15.007750,121.728714 14.027569,120.946869 
	C12.563503,119.384109 11.099436,117.821350 9.301377,115.777664 
	C7.989671,114.510468 7.011956,113.724190 6.034242,112.937912 
	C5.923367,112.624947 5.731485,112.373894 5.205407,111.743042 
	C3.634811,110.200882 2.317406,109.100441 1.000001,108.000000 
	C1.000000,105.958336 1.000000,103.916664 1.329706,101.451195 
	C2.099119,101.032089 2.538826,101.036789 2.978532,101.041489 
z"/>
<path fill="#1796D0" opacity="1.000000" stroke="none" 
	d="
M434.887054,595.941223 
	C433.956177,596.727722 433.025330,597.514221 431.685791,598.662842 
	C423.567871,606.705811 415.858643,614.386658 408.149353,622.067505 
	C407.867981,622.270874 407.586609,622.474304 406.868652,623.007568 
	C402.954712,627.224915 399.477356,631.112427 396.000000,635.000000 
	C393.958344,635.000000 391.916656,635.000000 389.437500,635.000000 
	C389.772888,633.767334 390.352905,632.345337 391.348358,631.331116 
	C402.309326,620.163208 413.326965,609.050903 424.355011,597.949097 
	C425.495758,596.800720 426.802826,595.817566 428.478577,594.304443 
	C430.857117,593.709839 432.789856,593.568542 434.790283,593.845825 
	C434.867676,594.823364 434.877380,595.382263 434.887054,595.941223 
z"/>
<path fill="#086BCC" opacity="1.000000" stroke="none" 
	d="
M284.005493,617.050842 
	C273.868408,617.199768 263.731354,617.348633 252.962280,617.811340 
	C248.900024,621.422546 245.469772,624.719971 242.039520,628.017395 
	C240.694534,629.387207 239.349548,630.757019 237.629303,632.543274 
	C236.836029,633.639771 236.417999,634.319885 235.999985,635.000000 
	C233.958328,635.000000 231.916672,635.000000 229.437500,635.000000 
	C230.849792,632.969727 232.663483,630.905090 234.556625,628.916077 
	C238.876984,624.376953 243.082993,619.709900 247.667328,615.452148 
	C249.165482,614.060730 251.674118,613.140259 253.747025,613.089783 
	C263.507935,612.852417 273.277985,612.992004 283.516907,613.419861 
	C283.994476,614.912048 283.999969,615.981445 284.005493,617.050842 
z"/>
<path fill="#02257D" opacity="1.000000" stroke="none" 
	d="
M56.468658,635.000000 
	C57.782333,634.321228 59.562531,633.057251 61.347324,633.050720 
	C93.071777,632.934814 124.796791,632.973938 156.521683,632.971252 
	C159.012634,632.971008 161.503586,632.908630 164.453156,632.832153 
	C164.686066,633.342712 164.460373,633.896240 164.117340,634.724915 
	C128.312439,635.000000 92.624878,635.000000 56.468658,635.000000 
z"/>
<path fill="#000B41" opacity="1.000000" stroke="none" 
	d="
M1079.666748,1.000000 
	C1079.905884,15.023269 1079.811646,29.046537 1079.365723,43.509216 
	C1078.679077,43.961021 1078.344482,43.973419 1078.005371,43.741562 
	C1078.001099,43.497307 1077.981079,43.009129 1077.981079,43.009129 
	C1077.987305,29.318523 1077.993652,15.627919 1078.000000,1.468657 
	C1078.444458,1.000000 1078.888916,1.000000 1079.666748,1.000000 
z"/>
<path fill="#0B76EC" opacity="1.000000" stroke="none" 
	d="
M46.437500,635.000000 
	C45.676159,633.726746 44.862270,632.331482 45.142017,631.208557 
	C45.573841,629.475159 46.451340,626.924927 47.691891,626.550598 
	C49.323231,626.058289 51.915356,626.850830 53.275749,628.032898 
	C54.169975,628.809998 53.664841,631.249390 53.684887,632.945618 
	C53.690163,633.392517 53.318745,633.843811 53.060158,634.646545 
	C50.958332,635.000000 48.916668,635.000000 46.437500,635.000000 
z"/>
<path fill="#1173BF" opacity="1.000000" stroke="none" 
	d="
M420.531342,1.000000 
	C420.988556,1.189673 420.977142,1.379346 420.480072,1.852504 
	C408.653442,2.239637 397.312408,2.343285 385.971405,2.446934 
	C380.493866,2.182675 375.016296,1.918415 369.269379,1.327078 
	C386.020905,1.000000 403.041779,1.000000 420.531342,1.000000 
z"/>
<path fill="#012E8A" opacity="1.000000" stroke="none" 
	d="
M312.625000,1.000001 
	C315.180817,3.674041 317.377441,6.335448 319.531525,9.030842 
	C320.084137,9.722301 320.502502,10.521045 320.991272,11.634348 
	C313.041321,10.334585 310.937103,8.383277 310.000977,1.399674 
	C310.750000,1.000000 311.500000,1.000000 312.625000,1.000001 
z"/>
<path fill="#011460" opacity="1.000000" stroke="none" 
	d="
M70.571533,1.000000 
	C70.544968,2.926610 70.719040,5.638576 69.501785,6.613643 
	C67.090408,8.545248 65.154510,6.431645 65.000031,1.430984 
	C66.714355,1.000000 68.428719,1.000000 70.571533,1.000000 
z"/>
<path fill="#03429E" opacity="1.000000" stroke="none" 
	d="
M396.375000,635.000000 
	C399.477356,631.112427 402.954712,627.224915 406.749847,623.243164 
	C405.603607,625.371582 404.195374,627.633728 402.656830,629.803589 
	C401.546967,631.368835 400.263733,632.811218 399.028900,634.654175 
	C398.250000,635.000000 397.500000,635.000000 396.375000,635.000000 
z"/>
<path fill="#012276" opacity="1.000000" stroke="none" 
	d="
M94.625000,0.999999 
	C96.249458,2.795860 97.498917,4.591722 98.888588,6.685116 
	C96.711647,5.220204 94.394493,3.457760 92.038666,1.347658 
	C92.750000,1.000000 93.500000,1.000000 94.625000,0.999999 
z"/>
<path fill="#031E6F" opacity="1.000000" stroke="none" 
	d="
M1.000001,108.375000 
	C2.317406,109.100441 3.634811,110.200882 4.987182,111.642899 
	C3.912568,111.677025 2.802988,111.369576 1.346704,111.031067 
	C1.000000,110.250000 1.000000,109.500000 1.000001,108.375000 
z"/>
<path fill="#051F6B" opacity="1.000000" stroke="none" 
	d="
M42.508415,629.045044 
	C42.890266,630.698608 42.965511,632.419556 43.020378,634.570190 
	C42.555553,635.000000 42.111111,635.000000 41.333328,635.000000 
	C41.400600,633.037476 41.801205,631.074951 42.508415,629.045044 
z"/>
<path fill="#012174" opacity="1.000000" stroke="none" 
	d="
M2.947797,100.717117 
	C2.538826,101.036789 2.099119,101.032089 1.329706,101.013695 
	C1.000000,100.555557 1.000000,100.111107 1.000000,99.333328 
	C1.639020,99.464249 2.278041,99.928497 2.947797,100.717117 
z"/>
<path fill="#012C87" opacity="1.000000" stroke="none" 
	d="
M236.333313,635.000000 
	C236.417999,634.319885 236.836029,633.639771 237.564392,632.862671 
	C237.912888,633.262451 237.951019,633.759155 237.994568,634.627930 
	C237.555557,635.000000 237.111099,635.000000 236.333313,635.000000 
z"/>
<path fill="#011964" opacity="1.000000" stroke="none" 
	d="
M90.203316,533.898499 
	C83.216469,532.117615 83.154907,537.770142 81.831955,541.962402 
	C80.901794,540.428650 80.226860,537.997925 79.202797,537.840698 
	C72.469391,536.806641 73.713562,531.433228 73.052475,527.168457 
	C72.749794,525.215820 72.934425,523.174744 73.014145,521.178406 
	C73.165520,517.387878 73.528221,513.745789 67.881691,513.557678 
	C66.872940,513.524048 65.198715,510.621490 65.134247,508.992065 
	C64.834732,501.422211 64.895859,493.832428 65.032967,486.253479 
	C65.093819,482.889984 64.410103,480.595764 60.419941,480.999115 
	C57.288792,481.315613 56.895451,479.429779 56.979275,476.943756 
	C57.105713,473.193817 57.466831,469.394318 57.038029,465.695923 
	C56.308163,459.400787 54.956161,453.179993 54.066830,446.899475 
	C53.869675,445.507172 54.047470,443.639618 54.846771,442.603607 
	C57.835690,438.729645 57.186638,434.879242 55.458340,430.867584 
	C54.949421,429.686279 54.744034,427.645874 53.976685,427.417023 
	C46.407257,425.159576 49.202694,418.498016 48.105824,413.665222 
	C47.054115,409.031403 45.907715,404.068939 46.530041,399.485413 
	C47.522430,392.176331 45.921703,385.295135 45.446201,378.207642 
	C44.995846,371.494904 47.102772,364.621277 47.970852,357.803925 
	C48.295635,355.253235 47.625813,353.486053 44.540611,352.738159 
	C43.118969,352.393524 41.200924,350.065216 41.202065,348.637756 
	C41.205505,344.338104 39.648281,339.775940 43.218994,335.729218 
	C44.072819,334.761566 42.477573,331.632904 41.980400,328.824310 
	C42.596333,327.415405 43.240582,326.680389 44.274635,325.958771 
	C45.755394,324.604492 46.846348,323.236816 48.061150,321.619446 
	C48.740337,320.889038 49.295685,320.408325 50.239281,319.944458 
	C51.402180,318.939972 52.176826,317.918640 53.103889,316.600708 
	C55.464729,314.182465 57.673157,312.060791 60.246025,309.995667 
	C64.431297,311.737457 66.973816,313.484406 62.141571,317.270569 
	C61.305206,317.925873 61.905506,320.706146 62.225204,322.434052 
	C62.411140,323.438995 63.808018,324.265503 63.888985,325.236572 
	C64.312874,330.320770 65.280121,335.041779 69.721687,338.354980 
	C70.405457,338.865051 70.845543,340.050507 70.927536,340.965790 
	C72.001205,352.951691 73.101143,364.937164 73.960556,376.939545 
	C74.516479,384.703400 74.716736,392.493622 75.020836,400.274200 
	C75.147614,403.517914 74.890121,406.794708 75.257103,410.005035 
	C75.531319,412.404022 76.389809,414.771606 77.242744,417.058197 
	C77.974426,419.019806 79.833656,420.830231 79.874718,422.735962 
	C80.125626,434.383118 80.158417,446.041656 79.917656,457.689270 
	C79.829010,461.978302 80.950119,464.584900 85.351852,465.802246 
	C86.803703,466.203796 88.977890,468.507507 88.785843,469.575226 
	C87.840942,474.828644 89.398506,478.933563 91.346115,483.887329 
	C93.354164,488.994781 93.710487,495.045715 95.582878,500.722778 
	C98.010826,508.084229 97.809006,516.286255 99.157890,524.053711 
	C99.415840,525.539124 101.420280,527.471375 102.952065,527.850159 
	C108.379990,529.192078 113.950729,529.958801 119.470314,530.926697 
	C123.397682,531.615417 124.330200,533.914978 122.785736,537.194580 
	C120.879532,535.644287 119.373947,534.029541 117.524002,533.100525 
	C109.509079,529.075623 101.269508,529.702881 92.969666,532.011353 
	C92.519760,531.352966 92.108047,531.044983 91.696335,530.737000 
	C91.851723,531.395874 92.007111,532.054810 91.999672,532.883301 
	C91.836845,533.052917 91.366676,533.044800 91.083679,532.786011 
	C90.542641,532.565063 90.284607,532.602905 90.026558,532.640747 
	C90.210564,532.960510 90.394577,533.280212 90.522568,533.722168 
	C90.466545,533.844299 90.203316,533.898499 90.203316,533.898499 
z"/>
<path fill="#001A66" opacity="1.000000" stroke="none" 
	d="
M45.012131,311.010315 
	C41.543915,305.535614 44.112728,299.697662 44.021461,294.026031 
	C43.925079,288.036224 43.999844,282.043671 43.999844,276.052338 
	C43.999844,267.231750 44.033123,258.410950 43.984241,249.590622 
	C43.963314,245.813736 45.201950,241.648834 40.249355,239.418930 
	C39.373634,239.024658 39.562443,236.266052 39.080551,233.613235 
	C41.855736,234.734650 43.159214,235.261368 45.703880,236.289627 
	C45.703880,231.258652 45.840111,226.925522 45.678478,222.603531 
	C45.183193,209.359711 44.562042,196.120605 44.037632,182.877777 
	C44.006741,182.097717 44.168243,180.793716 44.625389,180.587540 
	C52.316311,177.118774 49.511261,170.224762 49.976967,164.431122 
	C50.123177,162.612244 49.998859,160.771622 50.347527,158.509460 
	C52.143570,158.019272 53.590458,157.960571 55.472565,157.899384 
	C57.940041,157.921310 59.972298,157.945724 62.054203,158.304535 
	C63.067944,158.418060 64.032036,158.197189 65.462181,157.956726 
	C70.618828,157.961288 75.309410,157.985443 79.991425,158.398712 
	C78.989815,161.906525 77.153740,165.039154 77.205101,168.140518 
	C77.278618,172.579987 75.755829,175.331238 72.110893,177.492844 
	C70.644005,178.362762 68.460754,179.979416 68.524200,181.152298 
	C68.887856,187.875000 70.023254,194.553345 70.476082,201.275101 
	C70.651260,203.875351 69.994453,206.630615 69.236603,209.172104 
	C68.037010,213.194931 65.317154,217.074844 65.237106,221.055389 
	C65.078941,228.919647 66.772179,236.810349 66.789276,244.692093 
	C66.805275,252.069290 65.340050,259.438599 65.085907,266.830597 
	C64.831902,274.218384 70.103783,277.556824 76.709518,274.700836 
	C77.239563,274.471649 77.882782,274.504211 78.921478,274.347534 
	C78.556808,276.192871 78.279320,277.597046 77.789337,279.275940 
	C73.180481,281.459167 69.725868,284.052734 68.246361,289.078735 
	C67.683601,290.990448 65.521057,292.997437 63.597610,293.833130 
	C58.434761,296.076233 56.224823,300.152618 55.042625,305.548889 
	C53.097408,307.308563 51.214371,308.737823 48.953102,310.138062 
	C47.387287,310.409454 46.199707,310.709900 45.012131,311.010315 
z"/>
<path fill="#E8EEF3" opacity="1.000000" stroke="none" 
	d="
M93.007858,532.361877 
	C101.269508,529.702881 109.509079,529.075623 117.524002,533.100525 
	C119.373947,534.029541 120.879532,535.644287 122.752731,537.268311 
	C122.961876,537.593201 123.067619,537.716492 123.047531,538.099243 
	C123.373703,539.987183 123.719971,541.492371 123.940277,543.307861 
	C123.168411,544.995850 122.522507,546.373535 121.513123,547.779114 
	C120.433723,547.912109 119.717804,548.017212 118.603249,548.070190 
	C117.134369,548.018799 116.064117,548.019653 114.724091,547.846619 
	C114.028397,547.637146 113.602493,547.601501 113.089920,547.337769 
	C112.746765,546.897522 112.455269,546.759094 112.119568,546.495728 
	C111.785690,546.096985 111.460991,545.896912 111.146629,545.246338 
	C110.971603,544.559387 110.786255,544.322998 110.578171,544.073608 
	C110.555435,544.060547 110.579292,544.010315 110.473404,543.597290 
	C110.675095,541.314758 110.982689,539.445251 111.353386,537.192261 
	C106.206795,536.595093 101.391777,535.977844 96.631851,539.113403 
	C92.102692,545.195801 91.996452,547.574280 96.119026,550.915710 
	C97.832039,552.304138 99.919884,553.230042 102.044205,554.632935 
	C104.728233,555.967957 107.205315,557.036316 109.735809,558.055664 
	C109.789223,558.006653 109.868195,558.128296 109.952972,558.446899 
	C113.360130,561.027405 116.682503,563.289307 120.102280,565.696716 
	C120.199677,565.842163 120.532341,565.951172 120.713722,566.222168 
	C124.747536,572.420166 126.314743,578.613953 122.355400,585.057861 
	C120.840752,587.523010 118.665031,589.582031 116.845467,591.838989 
	C116.900360,591.851868 116.879372,591.741089 116.547951,591.776794 
	C115.732895,592.179626 115.249260,592.546753 114.562180,592.899536 
	C114.358727,592.885254 113.993324,593.066528 113.993324,593.066528 
	C105.967094,595.208618 97.978821,595.806519 90.304230,591.875732 
	C88.133415,590.763916 85.976883,589.320923 84.337868,587.545959 
	C81.947464,584.957214 81.437920,581.388062 84.023315,578.984802 
	C85.745621,577.383911 89.035744,576.435486 91.386642,576.786316 
	C94.945084,577.317444 96.274902,580.415283 95.764557,584.040466 
	C95.649178,584.860107 95.496231,585.674500 95.364601,586.467102 
	C100.195831,589.321106 108.855377,588.623779 112.650642,585.114929 
	C116.288994,581.751160 116.694237,576.023254 112.865227,572.729248 
	C109.510315,569.843079 105.492249,567.703064 101.672829,565.389038 
	C98.409576,563.411926 94.824150,561.925537 91.709526,559.751770 
	C82.022293,552.990784 81.292572,542.742676 89.950256,534.137939 
	C90.203316,533.898499 90.466545,533.844299 90.623657,533.683838 
	C91.024849,533.423706 91.220154,533.264160 91.366676,533.044800 
	C91.366676,533.044800 91.836845,533.052917 92.162979,532.900757 
	C92.749977,532.738159 92.922890,532.609253 93.007858,532.361877 
z"/>
<path fill="#011964" opacity="1.000000" stroke="none" 
	d="
M113.839066,593.390991 
	C113.993324,593.066528 114.358727,592.885254 114.896507,592.894226 
	C115.915985,592.515869 116.397690,592.128479 116.879379,591.741089 
	C116.879372,591.741089 116.900360,591.851868 117.264221,591.891357 
	C118.751747,591.953918 119.875420,591.976990 121.043091,592.371948 
	C123.300308,598.756592 125.842278,604.675049 127.604797,610.817139 
	C128.681274,614.568542 128.834579,618.666077 128.802277,622.603271 
	C128.792969,623.736511 126.832924,625.583313 125.530197,625.824890 
	C122.793739,626.332581 119.881378,625.803589 117.078888,626.080933 
	C115.676804,626.219543 114.357468,627.194641 113.000717,627.791443 
	C108.967468,627.831604 104.934227,627.871826 100.441269,627.825928 
	C100.316292,626.083496 100.651031,624.427185 101.001648,622.110779 
	C101.022850,621.259827 101.028175,621.069031 101.356949,620.893188 
	C102.112152,620.909729 102.543884,620.911255 103.189301,621.187012 
	C104.856667,620.387512 107.089188,619.567200 107.544273,618.168518 
	C107.996445,616.778809 107.146194,614.336060 106.020515,613.177917 
	C102.346428,609.398010 99.715073,612.822144 97.001221,614.997559 
	C97.453850,608.955750 96.608543,603.484070 90.106583,600.099792 
	C90.998466,599.442749 91.667046,598.537842 92.420044,598.461121 
	C98.041611,597.888062 103.710083,597.688721 109.293732,596.885071 
	C110.876694,596.657166 112.227516,594.816467 113.839066,593.390991 
z"/>
<path fill="#0A4DBA" opacity="1.000000" stroke="none" 
	d="
M49.331337,310.167114 
	C51.214371,308.737823 53.097408,307.308563 55.394806,305.498444 
	C56.132915,305.049805 56.572227,305.094666 56.763897,304.898071 
	C68.492302,292.868835 80.130241,280.749908 91.984604,268.846497 
	C93.802376,267.021210 96.609421,266.181122 99.023224,265.250854 
	C96.514626,269.783508 93.941750,273.950684 91.048553,278.235382 
	C88.799377,280.390900 86.901001,282.458801 84.936340,284.461639 
	C76.595467,292.964661 68.234932,301.448364 59.881584,309.939117 
	C57.673157,312.060791 55.464729,314.182465 52.824020,316.743958 
	C51.544834,318.098358 50.697933,319.013000 49.851028,319.927612 
	C49.295685,320.408325 48.740337,320.889038 47.777855,321.774933 
	C46.208759,323.435181 45.046799,324.690277 43.884834,325.945343 
	C43.240582,326.680389 42.596333,327.415405 41.917297,328.443909 
	C37.096493,332.196167 32.055595,335.219482 31.441381,342.226501 
	C31.261610,344.277374 28.375477,346.091034 25.962709,348.021454 
	C22.516310,347.252930 19.836979,346.476349 17.157650,345.699768 
	C17.123928,343.759308 17.090206,341.818878 17.272491,339.286377 
	C18.013983,338.164703 18.539467,337.635071 19.064650,337.516541 
	C19.040358,338.591675 18.747332,339.423645 19.038855,339.890778 
	C20.314877,341.935486 21.395700,344.561798 23.294310,345.569092 
	C24.280020,346.092010 28.100063,343.942383 28.381727,342.544952 
	C28.905041,339.948639 31.667517,336.281494 27.154163,333.977295 
	C27.510729,333.135712 27.794931,332.592133 28.079132,332.048584 
	C28.394157,331.952942 28.647900,331.771576 29.243790,331.142059 
	C31.136148,329.226501 32.625084,327.673370 34.114021,326.120239 
	C34.114025,326.120239 34.025475,325.993713 34.409851,326.005280 
	C39.639927,320.733612 44.485634,315.450348 49.331337,310.167114 
z"/>
<path fill="#0540BE" opacity="1.000000" stroke="none" 
	d="
M80.000000,158.009583 
	C75.309410,157.985443 70.618828,157.961288 65.088699,157.931519 
	C63.500961,157.940628 62.752758,157.955383 62.004551,157.970139 
	C59.972298,157.945724 57.940041,157.921310 55.196220,157.654343 
	C52.996670,157.255463 51.508682,157.099121 50.020699,156.942780 
	C49.580795,156.663651 49.140888,156.384537 48.388451,155.667831 
	C47.059643,154.138763 46.043369,153.047302 45.027100,151.955826 
	C44.987785,151.530045 44.948471,151.104279 45.247433,150.377686 
	C46.706745,150.686798 47.774158,151.493515 48.972416,151.820053 
	C49.535114,151.973373 50.349796,151.201981 51.416649,150.891205 
	C52.520515,150.968155 53.258015,151.003571 54.358780,151.075882 
	C55.451485,151.144531 56.180916,151.176285 57.151894,151.435654 
	C58.263039,151.764832 59.132641,151.866394 60.470016,151.994476 
	C67.622528,151.992599 74.307274,151.964188 81.459747,151.975647 
	C93.527405,152.025879 105.127411,152.013092 116.727219,152.056671 
	C122.204636,152.077240 127.669258,157.226715 128.068604,162.897675 
	C127.674744,163.447708 127.297485,163.747375 126.542892,164.048813 
	C124.941727,163.657303 123.152489,163.646530 122.585732,162.808762 
	C118.882416,157.334549 113.387733,157.814285 107.908211,157.917679 
	C102.606850,158.017685 97.303032,157.986572 91.540146,157.986938 
	C87.386665,157.977066 83.693336,157.993332 80.000000,158.009583 
z"/>
<path fill="#011773" opacity="1.000000" stroke="none" 
	d="
M97.000130,615.396729 
	C99.715073,612.822144 102.346428,609.398010 106.020515,613.177917 
	C107.146194,614.336060 107.996445,616.778809 107.544273,618.168518 
	C107.089188,619.567200 104.856667,620.387512 103.363373,620.882812 
	C103.726555,619.782837 104.470940,619.257935 104.465782,618.740479 
	C104.452248,617.383789 104.342583,615.916931 103.765793,614.742249 
	C103.584587,614.373169 101.253242,614.398865 100.865150,614.936340 
	C100.157272,615.916748 99.980888,617.409607 99.947769,618.698059 
	C99.929337,619.414978 100.647377,620.150818 101.033501,620.878174 
	C101.028175,621.069031 101.022850,621.259827 101.008499,621.737183 
	C97.339417,621.442261 96.809967,618.849854 97.000130,615.396729 
z"/>
<path fill="#031E6F" opacity="1.000000" stroke="none" 
	d="
M48.953102,310.138062 
	C44.485634,315.450348 39.639927,320.733612 34.459858,326.065552 
	C36.392467,323.037903 38.638538,319.945801 40.934517,316.891235 
	C42.246140,315.146271 43.639088,313.462433 45.003777,311.380676 
	C46.199707,310.709900 47.387287,310.409454 48.953102,310.138062 
z"/>
<path fill="#0540BE" opacity="1.000000" stroke="none" 
	d="
M36.946045,142.378220 
	C37.837280,141.330002 38.904732,140.562607 40.269310,139.519623 
	C41.324772,139.522568 42.083099,139.801086 42.909683,140.402725 
	C43.963722,142.157700 44.949497,143.589523 45.676746,145.226166 
	C44.930859,146.666855 44.443489,147.902756 43.956120,149.138657 
	C43.528717,149.086426 43.101318,149.034195 42.213875,148.664200 
	C40.879364,147.821930 40.004902,147.297409 39.130436,146.772903 
	C38.461040,145.401611 37.791649,144.030319 36.946045,142.378220 
z"/>
<path fill="#051F6B" opacity="1.000000" stroke="none" 
	d="
M72.894958,627.437256 
	C67.702271,627.499878 62.390995,627.193054 56.912930,626.515320 
	C62.089554,626.452209 67.432968,626.760010 72.894958,627.437256 
z"/>
<path fill="#0946BC" opacity="1.000000" stroke="none" 
	d="
M44.052834,149.337021 
	C44.443489,147.902756 44.930859,146.666855 45.944725,145.219528 
	C46.649166,144.996857 46.827110,144.985596 47.202850,145.021973 
	C47.400642,145.069580 47.807301,145.083115 47.890812,145.379974 
	C48.581791,146.149338 49.189259,146.621841 49.845871,147.425659 
	C50.280102,148.787872 50.665195,149.818771 51.050285,150.849655 
	C50.349796,151.201981 49.535114,151.973373 48.972416,151.820053 
	C47.774158,151.493515 46.706745,150.686798 45.221893,150.057846 
	C44.564816,149.951324 44.328640,149.783508 44.052834,149.337021 
z"/>
<path fill="#011964" opacity="1.000000" stroke="none" 
	d="
M96.989845,627.367981 
	C94.643974,627.801514 92.271866,627.860901 89.454979,627.569824 
	C89.006493,626.813721 89.002785,626.408081 88.999084,626.002441 
	C91.829575,624.921814 94.727844,623.296692 96.989845,627.367981 
z"/>
<path fill="#051F6B" opacity="1.000000" stroke="none" 
	d="
M54.917717,624.685303 
	C51.907299,625.004211 48.843445,624.949524 45.387497,624.937744 
	C47.270477,621.013062 50.661980,620.783142 54.917717,624.685303 
z"/>
<path fill="#011D6B" opacity="1.000000" stroke="none" 
	d="
M17.139172,346.093689 
	C19.836979,346.476349 22.516310,347.252930 25.604301,348.087036 
	C22.734346,351.123627 20.069546,350.627045 17.139172,346.093689 
z"/>
<path fill="#051F6B" opacity="1.000000" stroke="none" 
	d="
M88.568008,626.001465 
	C89.002785,626.408081 89.006493,626.813721 89.013763,627.520508 
	C86.634293,627.864136 84.251266,627.906677 81.427116,627.875000 
	C83.369652,627.200684 85.753304,626.600586 88.568008,626.001465 
z"/>
<path fill="#031E6F" opacity="1.000000" stroke="none" 
	d="
M33.742188,326.115692 
	C32.625084,327.673370 31.136148,329.226501 29.347391,330.928589 
	C28.624554,327.799652 30.804729,326.787659 33.742188,326.115692 
z"/>
<path fill="#031E6F" opacity="1.000000" stroke="none" 
	d="
M19.064949,337.105469 
	C18.539467,337.635071 18.013983,338.164703 17.257746,338.868958 
	C16.898270,336.353149 16.908712,333.752106 20.673771,334.034363 
	C20.456829,335.310852 20.151344,336.060333 19.647991,336.876221 
	C19.450125,336.942688 19.064949,337.105469 19.064949,337.105469 
z"/>
<path fill="#0A4DBA" opacity="1.000000" stroke="none" 
	d="
M19.845856,336.809784 
	C20.151344,336.060333 20.456829,335.310852 20.882479,334.278564 
	C22.085262,333.941589 23.167885,333.887482 24.644291,333.938873 
	C25.038074,334.044373 24.995811,334.416656 24.916792,334.721588 
	C23.173800,335.620941 21.509827,336.215363 19.845856,336.809784 
z"/>
<path fill="#031E6F" opacity="1.000000" stroke="none" 
	d="
M45.019688,152.330780 
	C46.043369,153.047302 47.059643,154.138763 48.156715,155.547089 
	C47.162437,154.811188 46.087357,153.758469 45.019688,152.330780 
z"/>
<path fill="#031E6F" opacity="1.000000" stroke="none" 
	d="
M50.010334,157.208359 
	C51.508682,157.099121 52.996670,157.255463 54.761002,157.656830 
	C53.590458,157.960571 52.143570,158.019272 50.346546,158.041748 
	C49.997597,157.828339 49.998787,157.651154 50.010334,157.208359 
z"/>
<path fill="#031E6F" opacity="1.000000" stroke="none" 
	d="
M14.050004,121.295387 
	C15.007750,121.728714 15.987930,122.510551 17.019506,123.627609 
	C16.071413,123.189850 15.071926,122.416878 14.050004,121.295387 
z"/>
<path fill="#031E6F" opacity="1.000000" stroke="none" 
	d="
M22.053886,129.285492 
	C23.009798,129.723114 23.986633,130.509476 25.010612,131.631256 
	C24.063442,131.189194 23.069126,130.411713 22.053886,129.285492 
z"/>
<path fill="#031E6F" opacity="1.000000" stroke="none" 
	d="
M6.055634,113.286415 
	C7.011956,113.724190 7.989671,114.510468 9.012962,115.633057 
	C8.064700,115.191223 7.070864,114.413071 6.055634,113.286415 
z"/>
<path fill="#031E6F" opacity="1.000000" stroke="none" 
	d="
M30.051517,137.297806 
	C31.015181,137.728790 32.000580,138.508682 33.034767,139.622345 
	C32.080120,139.186325 31.076685,138.416519 30.051517,137.297806 
z"/>
<path fill="#031E6F" opacity="1.000000" stroke="none" 
	d="
M39.112938,147.118652 
	C40.004902,147.297409 40.879364,147.821930 41.892830,148.628281 
	C41.053040,148.428223 40.074242,147.946320 39.112938,147.118652 
z"/>
<path fill="#051F6B" opacity="1.000000" stroke="none" 
	d="
M44.656548,624.995361 
	C44.504929,625.587891 44.028900,626.181702 43.286034,626.891968 
	C43.456837,626.337891 43.894489,625.667297 44.656548,624.995361 
z"/>
<path fill="#031E6F" opacity="1.000000" stroke="none" 
	d="
M27.738842,332.033112 
	C27.794931,332.592133 27.510729,333.135712 26.802868,333.938904 
	C25.918077,334.271240 25.456945,334.343964 24.995811,334.416687 
	C24.995811,334.416656 25.038074,334.044373 25.042089,333.854370 
	C25.830254,333.115448 26.614403,332.566528 27.738842,332.033112 
z"/>
<path fill="#F9FBFC" opacity="1.000000" stroke="none" 
	d="
M507.647522,88.113358 
	C508.740662,87.583778 509.833832,87.054192 511.052429,86.227875 
	C519.232727,81.719406 527.024170,76.862167 535.402588,73.443237 
	C548.853882,67.954254 562.693420,63.416706 577.089722,58.340622 
	C579.332825,57.883568 580.855103,57.562981 582.377380,57.242397 
	C582.377380,57.242397 582.757324,57.256180 583.296509,57.159698 
	C586.857605,56.328842 589.879578,55.594463 593.330933,54.912483 
	C594.852295,54.661182 595.944275,54.357487 597.277832,54.168510 
	C598.007812,54.131226 598.496155,53.979218 599.381897,53.903507 
	C601.862610,53.655613 603.945923,53.331417 606.396973,53.113777 
	C607.709656,52.944374 608.654602,52.668423 609.832153,52.474148 
	C610.415588,52.585426 610.717529,52.477848 611.293091,52.371532 
	C613.925232,52.354431 616.233521,52.086258 618.544373,52.062778 
	C628.679810,51.959789 638.815918,51.927608 649.395874,51.946751 
	C653.637390,52.375496 657.501892,52.392384 661.220032,53.138783 
	C674.977417,55.900539 688.844116,58.323692 702.362732,62.002285 
	C712.790039,64.839699 722.757629,69.366608 733.151611,73.412109 
	C736.884583,75.194290 740.395569,76.706680 744.133484,78.458084 
	C745.531311,79.257431 746.702271,79.817764 747.873230,80.378098 
	C747.879395,80.732849 748.049438,80.970215 748.753296,81.369980 
	C749.721436,81.852005 750.319519,82.054245 750.917603,82.256485 
	C753.973450,84.299194 757.029358,86.341911 760.433350,88.456978 
	C761.614563,89.062065 762.447693,89.594803 763.675171,90.481644 
	C766.265991,92.281303 768.462463,93.726860 770.804993,95.488968 
	C774.406006,98.372444 777.860901,100.939362 781.405029,103.812340 
	C782.768433,105.145798 784.042664,106.173203 785.444946,107.537041 
	C790.588379,112.223328 795.284180,116.785980 795.578308,124.040894 
	C795.890198,131.734421 790.929321,136.377274 785.369324,140.843628 
	C782.667175,141.537262 780.333496,142.257248 777.670410,142.866699 
	C776.892090,142.837311 776.443054,142.918457 775.674194,142.931320 
	C775.102295,143.197983 774.850159,143.532913 774.277222,143.738678 
	C772.648499,143.399323 771.340698,143.189148 769.764648,142.799194 
	C768.748535,142.347794 768.000488,142.076172 767.157715,141.532974 
	C766.718994,141.111130 766.375122,140.960861 766.049805,140.877533 
	C766.068298,140.944489 766.195068,140.887680 766.103882,140.580444 
	C765.344543,139.840439 764.676270,139.407654 763.915039,138.653717 
	C752.529419,128.055191 740.476013,118.783760 726.844177,111.830147 
	C718.349121,107.496765 710.132385,102.202156 699.988403,101.359116 
	C696.965576,99.908806 694.108276,98.772995 690.947937,97.419380 
	C678.093445,94.889435 665.625366,91.794456 652.971191,90.447098 
	C633.588867,88.383385 614.158203,88.960526 594.699280,93.402054 
	C593.299194,93.683243 592.229919,94.050270 590.875000,94.299423 
	C590.052246,94.411629 589.514954,94.641708 588.561768,94.819717 
	C573.057068,97.578720 558.774963,102.820190 545.240356,109.898193 
	C538.138977,113.611885 531.593994,118.389618 524.834106,122.720322 
	C524.870422,122.753799 524.927979,122.673569 524.598572,122.665863 
	C523.820374,123.092949 523.371582,123.527740 522.607056,123.978134 
	C521.086975,125.054291 519.882568,126.114853 518.451538,127.213882 
	C518.224976,127.252342 518.048035,127.676598 517.709595,127.696335 
	C517.138733,128.084244 516.906311,128.452408 516.746765,128.811935 
	C516.819641,128.803284 516.740540,128.679657 516.399292,128.723083 
	C509.701721,133.315140 503.345459,137.863800 496.600403,142.395645 
	C491.941254,141.857605 487.670868,141.336395 483.179138,140.540070 
	C473.484802,134.908585 471.373383,128.083313 475.139496,115.107643 
	C475.096497,115.084435 475.108490,115.181435 475.371887,115.116867 
	C475.709808,114.725632 475.784302,114.398964 476.097351,113.873245 
	C476.408600,113.480278 476.481354,113.286354 476.828491,113.067200 
	C477.549164,112.497879 477.995392,111.953781 478.744720,111.271011 
	C488.581055,103.459351 498.114288,95.786354 507.647522,88.113358 
M631.507935,54.991150 
	C636.891846,54.991150 642.275818,54.991150 647.659729,54.991150 
	C647.648193,54.756046 647.636658,54.520939 647.625122,54.285835 
	C637.809753,54.285835 627.994385,54.285835 618.179016,54.285835 
	C618.176697,54.521004 618.174316,54.756172 618.171997,54.991341 
	C622.286316,54.991341 626.400574,54.991341 631.507935,54.991150 
M656.050415,54.542191 
	C655.046204,54.372379 654.041992,54.202564 653.037781,54.032749 
	C653.015930,54.320797 652.994080,54.608841 652.972229,54.896889 
	C653.853455,54.896889 654.734619,54.896889 656.050415,54.542191 
z"/>
<path fill="#075FC9" opacity="1.000000" stroke="none" 
	d="
M743.906616,78.219055 
	C740.395569,76.706680 736.884583,75.194290 733.381592,73.189224 
	C731.810852,71.107231 730.239990,69.509956 728.651978,67.929878 
	C717.766724,57.099033 706.796143,46.351955 696.049866,35.384975 
	C693.478149,32.760414 690.837646,31.878061 687.257874,31.892654 
	C665.481140,31.981432 643.703491,31.859194 622.059937,31.433008 
	C623.898010,30.710167 625.600952,30.067125 627.306885,30.058878 
	C644.082031,29.977770 660.857605,30.014124 677.633057,29.977339 
	C679.221619,29.973856 680.809570,29.699472 682.397766,29.550858 
	C682.354797,29.034681 682.311829,28.518503 682.268799,28.002325 
	C680.673767,28.002325 679.078674,28.002916 677.483582,28.002230 
	C667.351868,27.997868 657.219604,28.043278 647.088867,27.940077 
	C645.372681,27.922594 643.663025,27.265553 641.950317,26.903992 
	C643.699341,26.608747 645.447144,26.065388 647.197510,26.057234 
	C662.155212,25.987549 677.113281,26.014910 692.540649,25.989700 
	C694.098267,26.305029 695.186646,26.643888 696.669617,27.339991 
	C697.703735,28.143217 698.343262,28.589201 698.982727,29.035187 
	C699.085205,29.351761 699.271240,29.607599 699.892456,30.218086 
	C705.749451,36.484623 711.093445,42.498711 716.800476,48.145885 
	C724.022400,55.291973 731.539612,62.139553 738.929504,69.115875 
	C740.813599,70.909088 742.697693,72.702293 744.645142,75.060493 
	C744.441223,76.490005 744.173889,77.354530 743.906616,78.219055 
z"/>
<path fill="#0E76D3" opacity="1.000000" stroke="none" 
	d="
M641.486938,26.913612 
	C643.663025,27.265553 645.372681,27.922594 647.088867,27.940077 
	C657.219604,28.043278 667.351868,27.997868 677.483582,28.002230 
	C679.078674,28.002916 680.673767,28.002325 682.268799,28.002325 
	C682.311829,28.518503 682.354797,29.034681 682.397766,29.550858 
	C680.809570,29.699472 679.221619,29.973856 677.633057,29.977339 
	C660.857605,30.014124 644.082031,29.977770 627.306885,30.058878 
	C625.600952,30.067125 623.898010,30.710167 621.688049,31.490662 
	C619.527832,32.576607 617.450012,32.804253 616.289856,33.956116 
	C610.363770,39.839970 604.674561,45.962379 598.633545,52.030392 
	C597.921753,52.724216 597.479004,53.389008 597.036194,54.053795 
	C595.944275,54.357487 594.852295,54.661182 593.190247,54.611061 
	C592.391418,53.188053 592.162720,52.118858 591.934082,51.049667 
	C592.549072,50.532928 593.164062,50.016186 594.230286,49.197136 
	C595.113770,48.288330 595.546021,47.681828 595.978333,47.075329 
	C600.749756,42.424141 605.521240,37.772953 610.952637,33.014141 
	C612.065674,32.259026 612.518860,31.611536 612.972046,30.964050 
	C613.462524,30.384876 613.952942,29.805698 615.040527,29.079439 
	C616.761658,28.268587 617.885742,27.604816 619.009766,26.941048 
	C626.347656,26.935112 633.685608,26.929171 641.486938,26.913612 
z"/>
<path fill="#2159B0" opacity="1.000000" stroke="none" 
	d="
M591.574585,51.086777 
	C592.162720,52.118858 592.391418,53.188053 592.760864,54.558662 
	C589.879578,55.594463 586.857605,56.328842 583.429077,57.023502 
	C583.188660,56.534977 583.221008,55.870461 583.541992,55.671520 
	C586.068542,54.105438 588.650330,52.628384 591.574585,51.086777 
z"/>
<path fill="#075FC9" opacity="1.000000" stroke="none" 
	d="
M618.800232,26.720562 
	C617.885742,27.604816 616.761658,28.268587 615.299438,28.950974 
	C616.171021,28.146418 617.380859,27.323250 618.800232,26.720562 
z"/>
<path fill="#2159B0" opacity="1.000000" stroke="none" 
	d="
M581.967529,57.154533 
	C580.855103,57.562981 579.332825,57.883568 577.411743,58.145729 
	C578.527832,57.747093 580.042786,57.406883 581.967529,57.154533 
z"/>
<path fill="#075FC9" opacity="1.000000" stroke="none" 
	d="
M612.657104,31.014267 
	C612.518860,31.611536 612.065674,32.259026 611.286621,32.939991 
	C611.421326,32.337143 611.881775,31.700815 612.657104,31.014267 
z"/>
<path fill="#2159B0" opacity="1.000000" stroke="none" 
	d="
M595.669434,47.101711 
	C595.546021,47.681828 595.113770,48.288330 594.379028,48.930229 
	C594.504517,48.353115 594.932495,47.740604 595.669434,47.101711 
z"/>
<path fill="#010C60" opacity="1.000000" stroke="none" 
	d="
M1077.955811,387.960693 
	C1077.965820,394.669586 1077.975830,401.378448 1077.977173,408.559753 
	C1077.444946,411.479523 1076.921509,413.926910 1076.235718,417.132812 
	C1075.372437,415.690765 1074.868042,414.848358 1073.712402,412.917908 
	C1075.507324,419.531677 1069.908325,419.917297 1067.595947,422.710632 
	C1063.850586,427.235046 1059.430664,431.201172 1055.147705,435.700928 
	C1044.948242,445.964142 1034.962769,455.997589 1024.813232,465.862183 
	C1020.566467,469.989746 1016.101685,473.924011 1011.455139,477.590057 
	C1010.071533,478.681671 1007.743103,478.882172 1005.830994,478.939880 
	C1000.202271,479.109680 994.565247,479.003082 988.462036,479.011505 
	C987.245483,479.015259 986.498230,479.012238 984.932861,479.004883 
	C981.743958,478.997955 979.373230,478.995331 976.701111,478.705139 
	C971.343201,478.089722 966.286560,477.761841 961.229980,477.433990 
	C961.253479,476.776611 961.276978,476.119263 961.300537,475.461884 
	C962.807495,475.307953 964.313110,475.039551 965.821777,475.021271 
	C970.598816,474.963348 975.383728,475.138916 980.150146,474.907043 
	C981.462769,474.843140 982.721436,473.670959 984.253662,473.004791 
	C984.502380,473.002258 985.000000,472.997559 985.468628,473.000092 
	C990.428406,473.002960 994.970703,472.575592 999.398743,473.104370 
	C1005.971313,473.889252 1010.325073,470.981354 1014.514648,466.379486 
	C1020.069702,460.277771 1026.227661,454.728394 1032.052246,448.867737 
	C1038.113892,442.768524 1044.043457,436.538147 1050.109619,430.443420 
	C1057.327026,423.191895 1064.618042,416.013245 1071.898438,408.824554 
	C1074.994385,405.767578 1076.354858,401.727142 1074.510254,398.150085 
	C1073.553711,396.295227 1069.598633,395.255615 1066.943115,395.142303 
	C1058.309082,394.773804 1049.649658,395.002441 1041.000000,394.572998 
	C1041.000000,392.432709 1041.000000,390.720642 1041.453369,389.005981 
	C1053.273193,386.907471 1065.297241,391.430389 1076.515015,385.888153 
	C1076.852417,386.373444 1077.404175,387.167053 1077.955811,387.960693 
z"/>
<path fill="#E3ECF3" opacity="1.000000" stroke="none" 
	d="
M996.114746,591.128479 
	C995.477966,587.578247 995.803528,584.295593 999.459778,583.741760 
	C1001.623901,583.413879 1004.811890,584.395996 1006.247925,585.968567 
	C1008.484436,588.417664 1007.878235,591.816895 1004.931763,593.694153 
	C1001.542786,595.853394 998.335510,595.290955 996.114746,591.128479 
z"/>
<path fill="#E3ECF3" opacity="1.000000" stroke="none" 
	d="
M974.686646,591.239136 
	C973.837524,587.602295 974.452332,584.488342 977.923096,583.723450 
	C979.960388,583.274475 983.243103,584.008606 984.437805,585.464355 
	C985.625793,586.911987 985.692139,590.278992 984.848755,592.183838 
	C983.171570,595.972351 978.246033,595.504883 974.686646,591.239136 
z"/>
<path fill="#E3ECF3" opacity="1.000000" stroke="none" 
	d="
M963.984680,586.885620 
	C963.296692,589.503174 963.061035,592.459961 961.464111,593.829895 
	C960.273865,594.850830 956.740234,594.579163 955.319092,593.489380 
	C953.884033,592.388916 953.092346,589.502747 953.292542,587.527954 
	C953.442078,586.052490 955.379822,584.128723 956.940918,583.563416 
	C959.784058,582.533875 962.507385,583.326477 963.984680,586.885620 
z"/>
<path fill="#013598" opacity="1.000000" stroke="none" 
	d="
M590.149292,554.193604 
	C587.345032,555.459717 584.540833,556.725830 581.365845,557.564148 
	C580.995117,555.035583 580.995117,552.934814 580.995117,550.828552 
	C582.792358,550.640930 584.146851,550.499512 585.501404,550.358093 
	C585.494934,549.931824 585.488525,549.505493 585.482056,549.079224 
	C580.596252,548.698242 575.710449,548.317200 570.900452,547.596191 
	C573.981506,544.522705 576.986755,541.789124 580.304321,539.327881 
	C588.030640,539.715393 595.453308,540.086670 602.854980,539.836426 
	C606.488647,539.713623 610.071960,538.423035 611.102661,533.696533 
	C612.197449,532.317444 613.128357,531.203857 614.417358,530.065735 
	C616.167847,528.683350 617.560242,527.325500 619.113037,525.705322 
	C620.169678,524.610962 621.065918,523.778870 622.309326,522.927246 
	C624.062744,521.791870 625.585205,520.793701 626.856018,519.540405 
	C633.585388,512.903748 640.198303,506.148224 646.975708,499.561707 
	C648.728210,497.858582 650.823425,495.565247 652.941406,495.354523 
	C659.810425,494.671112 660.086304,490.114899 660.038940,485.047241 
	C660.014587,485.047974 660.068420,485.062927 660.403564,485.011719 
	C661.813843,483.632507 662.888977,482.304474 663.980835,480.987762 
	C663.997620,480.999084 663.976746,480.964447 664.322998,480.943512 
	C667.451965,478.282440 670.234741,475.642273 673.438599,472.989868 
	C678.042480,472.649231 682.253113,472.506042 686.392090,471.885284 
	C687.720520,471.686096 688.859802,470.225647 690.541382,469.277649 
	C695.672302,469.199188 700.347534,469.185272 705.315735,469.424438 
	C708.061707,469.808899 710.514771,469.940308 713.075317,470.445953 
	C716.600281,472.323273 719.999451,473.586365 723.484619,470.057648 
	C727.294495,469.723541 730.653992,469.401245 734.388733,469.072632 
	C735.514954,469.057373 736.265930,469.048462 737.435364,469.065002 
	C739.242004,469.203735 740.630188,469.317017 741.926025,469.734192 
	C736.141785,479.253082 726.458801,475.934967 718.673950,476.228821 
	C713.566772,476.421570 712.675964,477.907410 712.426941,481.540680 
	C712.331604,482.931549 713.649170,485.623596 714.343323,485.630371 
	C716.167114,485.648224 718.871033,485.049103 719.671692,483.743591 
	C722.195068,479.629059 726.444519,477.431335 730.924988,479.293030 
	C732.196350,479.821289 733.600342,481.571655 733.704468,482.861572 
	C733.782471,483.828949 732.028137,485.208374 730.811462,485.945038 
	C729.478271,486.752319 727.825806,487.032166 725.885498,487.685638 
	C727.406250,491.488373 729.284363,494.761230 729.925842,498.260742 
	C730.398438,500.838745 730.216675,505.107849 728.648438,506.188660 
	C724.277893,509.200806 721.145081,515.305908 714.313782,513.503784 
	C713.728455,513.349365 713.299377,512.380371 712.754395,512.337280 
	C711.170776,512.212036 709.376282,511.914795 708.001953,512.490540 
	C703.082336,514.551392 705.138916,519.077148 705.116821,522.579163 
	C705.086853,527.313904 703.278076,529.406616 698.554932,529.018921 
	C697.065735,528.896729 695.545227,529.126343 694.061096,528.976501 
	C690.027405,528.569275 688.931030,530.506897 688.932068,534.286560 
	C688.934814,544.313782 688.803467,544.339294 678.872437,544.992554 
	C674.949280,545.250671 672.622437,546.771423 672.946655,551.179382 
	C673.097412,553.229187 672.673645,555.321228 672.510376,557.394165 
	C672.126465,557.359192 671.742554,557.324158 671.358643,557.289185 
	C671.093262,554.066650 670.827942,550.844177 670.562561,547.621643 
	C670.040710,547.656738 669.518799,547.691833 668.996948,547.726929 
	C668.996948,550.136475 669.377197,552.628845 668.875671,554.929077 
	C668.480347,556.742188 666.998962,558.318481 666.000000,560.000000 
	C666.000000,549.187622 666.000000,538.375305 666.000000,526.707764 
	C659.746338,523.397705 652.592468,524.658813 645.727051,526.993408 
	C644.267761,527.489624 643.290405,531.149231 643.247681,533.373657 
	C643.224182,534.596985 645.431946,536.603821 646.945496,536.970520 
	C650.748840,537.892090 652.804810,539.436829 652.026794,543.704285 
	C651.554871,546.293152 650.641846,547.563965 647.753784,548.184753 
	C641.416687,549.546875 634.294556,549.393555 629.903931,555.582153 
	C629.617798,555.985352 629.202515,556.294250 628.885864,556.678833 
	C626.832825,559.172180 624.790527,561.674438 622.744690,564.173767 
	C622.123657,563.947449 621.502625,563.721130 620.881531,563.494751 
	C620.542175,559.856995 620.358582,556.193115 619.756592,552.599304 
	C619.601501,551.672791 618.219482,550.417053 617.248962,550.261475 
	C614.269592,549.783813 611.216431,549.766602 608.212097,549.571716 
	C608.418579,547.352112 608.560303,545.828674 608.631592,545.062378 
	C605.247864,545.851746 602.417542,546.511963 599.587280,547.172180 
	C599.548279,546.906006 599.509338,546.639771 599.470337,546.373596 
	C596.354492,548.737488 593.238586,551.101318 590.055176,553.774475 
	C589.987671,554.083801 590.149292,554.193604 590.149292,554.193604 
M712.658325,491.716553 
	C707.133545,495.430847 706.839172,497.475922 708.814941,504.487701 
	C710.127136,509.144867 713.357544,510.028748 717.040833,509.711334 
	C723.530884,509.152100 726.852722,506.096039 726.986450,501.488251 
	C727.212585,493.698914 722.618774,490.281281 712.658325,491.716553 
z"/>
<path fill="#013290" opacity="1.000000" stroke="none" 
	d="
M666.000000,560.466797 
	C666.998962,558.318481 668.480347,556.742188 668.875671,554.929077 
	C669.377197,552.628845 668.996948,550.136475 668.996948,547.726929 
	C669.518799,547.691833 670.040710,547.656738 670.562561,547.621643 
	C670.827942,550.844177 671.093262,554.066650 671.358643,557.289185 
	C671.742554,557.324158 672.126465,557.359192 672.510376,557.394165 
	C672.673645,555.321228 673.097412,553.229187 672.946655,551.179382 
	C672.622437,546.771423 674.949280,545.250671 678.872437,544.992554 
	C688.803467,544.339294 688.934814,544.313782 688.932068,534.286560 
	C688.931030,530.506897 690.027405,528.569275 694.061096,528.976501 
	C695.545227,529.126343 697.065735,528.896729 698.554932,529.018921 
	C703.278076,529.406616 705.086853,527.313904 705.116821,522.579163 
	C705.138916,519.077148 703.082336,514.551392 708.001953,512.490540 
	C709.376282,511.914795 711.170776,512.212036 712.754395,512.337280 
	C713.299377,512.380371 713.728455,513.349365 714.313782,513.503784 
	C721.145081,515.305908 724.277893,509.200806 728.648438,506.188660 
	C730.216675,505.107849 730.398438,500.838745 729.925842,498.260742 
	C729.284363,494.761230 727.406250,491.488373 725.885498,487.685638 
	C727.825806,487.032166 729.478271,486.752319 730.811462,485.945038 
	C732.028137,485.208374 733.782471,483.828949 733.704468,482.861572 
	C733.600342,481.571655 732.196350,479.821289 730.924988,479.293030 
	C726.444519,477.431335 722.195068,479.629059 719.671692,483.743591 
	C718.871033,485.049103 716.167114,485.648224 714.343323,485.630371 
	C713.649170,485.623596 712.331604,482.931549 712.426941,481.540680 
	C712.675964,477.907410 713.566772,476.421570 718.673950,476.228821 
	C726.458801,475.934967 736.141785,479.253082 742.385742,469.627991 
	C752.940247,469.197754 762.942627,469.177551 772.948547,469.424744 
	C772.959595,469.871368 772.967163,470.050568 772.784302,470.512878 
	C768.036743,473.187958 771.570007,477.262573 770.289551,480.174622 
	C770.730408,480.498169 770.850525,480.634705 771.000366,480.688965 
	C773.218628,481.491821 776.817261,480.000549 776.958618,484.250488 
	C777.079712,487.891724 775.154541,489.086212 771.691162,489.243103 
	C770.749634,489.285706 768.947327,491.673798 769.164734,492.606750 
	C771.049622,500.693695 764.917114,505.834015 762.244263,512.190857 
	C760.530823,516.265930 757.666748,519.910583 756.329529,524.072998 
	C755.346069,527.134644 753.935547,528.563782 750.980286,528.555786 
	C749.322083,527.133728 747.799255,525.496582 746.038025,525.178345 
	C742.787170,524.590881 739.219788,524.068420 736.114624,524.843079 
	C733.101074,525.594971 730.073853,527.518799 729.983276,531.672974 
	C729.910950,534.992737 730.587646,536.846008 734.533569,537.227234 
	C736.162476,537.384644 738.773499,540.210571 738.819336,541.880981 
	C739.175598,554.863708 738.953308,567.861084 739.045593,580.852966 
	C739.079529,585.631836 735.814087,584.897827 732.841614,585.058472 
	C729.797424,585.222900 727.711792,584.866577 727.899231,580.899292 
	C728.174011,575.082275 728.066040,569.240601 727.942078,563.413208 
	C727.811035,557.254089 725.544617,554.021790 720.987671,553.005615 
	C720.999695,552.998657 720.989685,553.023560 720.935303,552.662354 
	C719.364258,551.428894 717.916321,549.985901 716.317932,549.793579 
	C711.917419,549.264282 707.453796,549.259033 702.933960,548.674622 
	C700.617432,547.643250 698.111389,546.033081 696.217224,546.572205 
	C694.000977,547.203125 692.132324,549.555725 690.539246,551.509155 
	C690.161255,551.972656 691.482178,553.821655 692.022522,555.459167 
	C692.017212,557.589233 692.011047,559.293396 691.656799,561.061890 
	C690.982422,562.855774 690.656189,564.585266 690.266235,566.652405 
	C685.548645,568.588074 680.191711,570.427917 680.797485,577.562439 
	C680.855347,578.244507 679.704590,579.031738 679.109985,579.766113 
	C678.036926,581.091248 677.032837,582.482666 675.860229,583.713318 
	C675.026245,584.588501 673.963257,585.245422 673.002441,585.999817 
	C672.444946,585.688538 671.915405,585.179565 671.325684,585.096252 
	C664.860168,584.182678 664.450439,583.570862 665.010498,577.134033 
	C665.479248,571.745972 665.680298,566.334656 666.000000,560.466797 
M672.410095,579.164856 
	C672.087830,579.852417 671.765625,580.539978 671.443359,581.227478 
	C671.856018,581.302551 672.268677,581.377625 672.681335,581.452637 
	C672.803101,580.722839 672.924805,579.992981 672.410095,579.164856 
z"/>
<path fill="#EAF3F7" opacity="1.000000" stroke="none" 
	d="
M654.862793,591.798096 
	C638.159912,599.826355 627.315369,591.218201 626.111450,574.019653 
	C625.085571,559.365784 634.067627,550.034424 650.469360,550.905823 
	C651.776306,550.975281 653.079163,551.121521 654.641663,551.253967 
	C654.641663,545.830688 654.641663,540.612549 654.641663,534.909973 
	C652.355469,534.909973 650.353149,535.151367 648.454163,534.812927 
	C647.434265,534.631104 645.799194,533.411804 645.834961,532.734131 
	C645.908569,531.338684 646.717896,528.965759 647.575500,528.798950 
	C651.946228,527.948669 656.428040,527.442139 660.873352,527.425171 
	C661.618164,527.422302 663.010986,530.408447 663.027222,532.024292 
	C663.191101,548.333801 663.130432,564.645569 663.132874,580.956665 
	C663.133179,582.939209 663.132935,584.921753 663.132935,587.463806 
	C665.155396,587.575317 666.970093,587.449036 668.657959,587.852783 
	C669.682312,588.097778 671.248352,589.146240 671.263733,589.861938 
	C671.290466,591.102417 670.645447,592.919067 669.678040,593.517395 
	C666.330261,595.588196 658.193604,594.468445 654.862793,591.798096 
M654.959106,566.669800 
	C654.211121,564.000977 654.314758,560.156677 652.556213,558.882629 
	C647.178345,554.986267 639.368591,557.624756 636.810547,563.830811 
	C633.998413,570.653503 634.509155,577.566772 638.153015,583.982483 
	C640.351318,587.852966 644.182495,588.548096 648.163574,587.242798 
	C651.983826,585.990295 655.700989,584.563293 655.034912,579.092346 
	C654.576111,575.324524 654.954712,571.454651 654.959106,566.669800 
z"/>
<path fill="#EAF3F7" opacity="1.000000" stroke="none" 
	d="
M434.722595,593.427246 
	C432.789856,593.568542 430.857117,593.709839 428.525116,593.953674 
	C422.454742,593.925964 416.783600,593.795715 410.689178,593.184143 
	C410.845490,590.974243 411.425110,589.245728 412.315216,587.593445 
	C413.449493,587.526733 414.273285,587.383728 415.434998,587.323792 
	C416.444061,586.922607 417.115295,586.438354 417.899780,585.500610 
	C418.014862,577.795959 418.081696,570.543640 417.962860,563.294312 
	C417.939392,561.861816 417.201691,560.440918 416.386536,558.885437 
	C414.002808,558.828796 412.025818,558.901489 409.700012,558.791321 
	C408.066711,553.897766 409.700989,551.520813 414.748566,551.580994 
	C416.175415,551.598083 417.609253,551.028931 419.431458,550.808228 
	C420.883850,550.825500 421.944672,550.763306 423.326324,550.827393 
	C424.105621,550.992554 424.564117,551.031372 425.073547,551.425293 
	C425.485931,553.126221 425.847382,554.472046 426.199890,555.784668 
	C429.746918,554.437134 432.769379,553.020508 435.940033,552.133423 
	C446.447418,549.193481 452.794891,553.934387 453.191986,564.893066 
	C453.402710,570.709534 453.498291,576.530273 453.488892,582.668945 
	C453.331940,584.517822 453.331940,586.046875 453.331940,587.269226 
	C456.356110,587.950623 458.679077,588.473999 461.002075,588.997375 
	C460.946259,590.303650 460.890472,591.609924 460.471497,593.343140 
	C453.225830,593.785889 446.343323,593.801636 439.350037,593.568176 
	C439.120056,593.181824 439.000824,593.044617 438.946289,592.522461 
	C440.029388,590.650391 441.108948,589.200745 442.048950,587.665588 
	C442.985779,586.135681 443.782623,584.520020 444.748901,582.489136 
	C444.706146,576.396423 444.804108,570.734985 444.318207,565.124146 
	C443.987488,561.305176 441.884308,558.612854 437.482666,558.745728 
	C432.946930,558.882629 426.682922,562.693359 426.583771,565.860413 
	C426.361816,572.949585 426.514313,580.050476 426.514313,587.236633 
	C429.564270,587.669128 432.148651,588.035583 434.717926,588.796875 
	C434.783295,590.511414 434.863739,591.831116 434.852966,593.191162 
	C434.761749,593.231567 434.722595,593.427185 434.722595,593.427246 
z"/>
<path fill="#0F3C91" opacity="1.000000" stroke="none" 
	d="
M570.824646,547.936157 
	C575.710449,548.317200 580.596252,548.698242 585.482056,549.079224 
	C585.488525,549.505493 585.494934,549.931824 585.501404,550.358093 
	C584.146851,550.499512 582.792358,550.640930 580.995117,550.828552 
	C580.995117,552.934814 580.995117,555.035583 580.995117,557.570618 
	C580.995117,558.004883 580.996948,558.497559 580.624634,558.830811 
	C579.562378,561.278503 578.872437,563.392944 577.807495,566.656677 
	C576.563843,560.931580 573.570740,559.195374 569.301392,559.702881 
	C572.035950,558.956360 574.502380,558.485046 577.161865,557.648499 
	C577.633545,555.646973 577.912048,554.010681 578.298584,551.740417 
	C573.759705,551.740417 570.030945,551.740417 566.307556,551.740417 
	C566.087891,547.178833 565.893250,543.135925 565.700745,539.137146 
	C561.157898,538.483948 560.186768,540.871033 559.644104,544.249084 
	C559.146057,547.349243 559.305786,551.510376 554.632141,551.574585 
	C549.861084,551.640137 549.038147,554.005188 549.947754,558.305359 
	C548.548096,559.420654 547.192139,560.177917 545.741333,560.573547 
	C544.197937,557.815002 543.328613,554.422852 541.204590,553.186829 
	C529.552002,546.405823 513.190308,553.964966 511.075623,567.248535 
	C510.267059,572.327515 511.430542,577.720459 511.250946,582.934082 
	C507.436615,582.995972 504.066711,583.094116 500.368317,583.168335 
	C499.158325,583.178528 498.276794,583.212769 496.905518,583.147339 
	C490.272003,583.319336 484.128204,583.591064 477.659363,583.708008 
	C476.516998,580.831787 475.699677,578.110352 474.697144,574.772339 
	C482.524567,574.772339 489.347992,575.098328 496.109802,574.595581 
	C498.478851,574.419434 502.004517,572.719238 502.746796,570.829224 
	C505.355469,564.186951 500.199677,554.189514 493.289856,552.152466 
	C489.730743,551.103149 485.779083,551.385498 482.004761,550.790894 
	C482.005402,550.332214 482.006073,550.148743 482.414062,549.910889 
	C487.906464,549.915649 492.994598,550.116028 498.076050,550.006287 
	C510.690216,549.733765 523.301331,549.319641 536.325195,548.977417 
	C539.925232,550.005615 543.113647,551.018738 545.997681,551.935120 
	C546.839233,551.371338 547.788452,550.262817 548.892395,550.077942 
	C553.506287,549.305176 556.865051,547.871399 557.372253,542.225830 
	C557.547302,540.277832 560.308044,537.867615 562.425171,537.034546 
	C563.820496,536.485413 566.175842,538.375793 568.113037,539.507812 
	C568.213501,540.280579 568.302246,540.719421 568.150024,541.458008 
	C566.320190,545.025513 565.972290,547.707825 570.824646,547.936157 
z"/>
<path fill="#E4EEF5" opacity="1.000000" stroke="none" 
	d="
M590.059082,554.149780 
	C595.066772,553.153564 599.978394,551.356018 604.944580,551.189941 
	C613.875549,550.891357 621.699463,561.201599 619.671936,569.914001 
	C618.913879,573.171448 616.713928,574.889771 613.149231,574.838806 
	C606.184021,574.739197 599.216309,574.809631 592.008179,574.809631 
	C591.534729,579.848511 592.961609,583.657349 596.705688,586.415222 
	C600.335876,589.089111 604.211914,588.473572 607.969177,586.659851 
	C609.718079,585.815613 611.211914,584.105286 613.000854,583.782715 
	C614.596252,583.495056 617.072266,584.082642 617.961853,585.220459 
	C618.615112,586.056030 617.888428,589.226074 616.823242,589.947144 
	C609.529541,594.884094 601.415649,596.709229 593.162537,593.007202 
	C585.215271,589.442383 582.939209,582.152588 582.831726,573.987610 
	C582.735718,566.690430 583.416016,559.685791 590.016174,554.498962 
	C590.149292,554.193604 589.987671,554.083801 590.059082,554.149780 
M609.040283,560.129639 
	C604.041321,555.618408 598.913635,556.259338 593.409119,562.457703 
	C592.795898,564.373474 592.182678,566.289246 591.475342,568.499023 
	C598.490295,568.499023 604.589539,568.499023 611.676575,568.499023 
	C610.735779,565.620483 609.954224,563.229248 609.040283,560.129639 
z"/>
<path fill="#E4EEF5" opacity="1.000000" stroke="none" 
	d="
M511.695312,582.970337 
	C511.430542,577.720459 510.267059,572.327515 511.075623,567.248535 
	C513.190308,553.964966 529.552002,546.405823 541.204590,553.186829 
	C543.328613,554.422852 544.197937,557.815002 545.779114,560.601074 
	C545.911804,560.990234 545.975952,560.921509 545.686768,560.951050 
	C545.275757,561.340942 545.153931,561.701355 544.940918,562.143311 
	C544.849670,562.224915 544.971436,562.437195 544.668152,562.677795 
	C538.434692,566.866821 535.330383,565.301208 533.389954,557.383301 
	C527.225464,556.873596 523.440308,558.732178 521.043091,563.445740 
	C517.808044,569.806702 518.445374,578.775085 522.522339,583.816650 
	C525.020752,585.402527 527.462219,586.634094 529.903748,587.865662 
	C533.613770,586.655029 537.273438,585.230225 541.058716,584.339539 
	C542.566101,583.984924 544.351318,584.811523 546.010071,585.100342 
	C545.561157,586.775635 545.667786,589.155884 544.576416,590.015808 
	C534.936646,597.611572 521.699524,596.279724 513.513550,586.702393 
	C512.724548,585.131958 512.209900,584.051147 511.695312,582.970337 
z"/>
<path fill="#EAF3F7" opacity="1.000000" stroke="none" 
	d="
M549.991455,557.947388 
	C549.038147,554.005188 549.861084,551.640137 554.632141,551.574585 
	C559.305786,551.510376 559.146057,547.349243 559.644104,544.249084 
	C560.186768,540.871033 561.157898,538.483948 565.700745,539.137146 
	C565.893250,543.135925 566.087891,547.178833 566.307556,551.740417 
	C570.030945,551.740417 573.759705,551.740417 578.298584,551.740417 
	C577.912048,554.010681 577.633545,555.646973 576.703430,557.627625 
	C572.785095,557.972046 569.518250,557.972046 565.918335,557.972046 
	C565.918335,565.301392 565.925049,571.930115 565.916748,578.558899 
	C565.905579,587.441345 565.900330,587.435547 574.840088,587.090637 
	C575.498840,587.065247 576.164185,587.208496 576.826416,587.273315 
	C577.972168,590.348328 577.079285,592.185120 573.791809,593.158264 
	C569.479370,594.434875 565.273926,595.966064 560.506165,592.656616 
	C559.186279,588.896545 557.874817,585.632141 557.673767,582.300720 
	C557.247620,575.239441 557.400330,568.143127 557.483826,560.698242 
	C556.296692,555.695374 552.513611,558.844055 549.991455,557.947388 
z"/>
<path fill="#01308F" opacity="1.000000" stroke="none" 
	d="
M673.049927,586.386230 
	C673.963257,585.245422 675.026245,584.588501 675.860229,583.713318 
	C677.032837,582.482666 678.036926,581.091248 679.109985,579.766113 
	C679.704590,579.031738 680.855347,578.244507 680.797485,577.562439 
	C680.191711,570.427917 685.548645,568.588074 690.266235,566.652405 
	C690.656189,564.585266 690.982422,562.855774 691.671204,561.430054 
	C692.691223,564.965332 694.405151,566.985107 698.196167,567.105774 
	C695.964600,569.880432 693.493408,572.446289 690.675537,575.045410 
	C689.363708,576.065063 688.398682,577.051636 687.433655,578.038208 
	C688.282837,578.357910 689.132019,578.677612 690.091675,579.253906 
	C690.454712,580.006958 690.707275,580.503479 690.570923,581.000977 
	C683.688293,580.864258 687.249695,585.298584 686.766479,588.193970 
	C687.688660,589.925232 688.844360,591.462646 690.000000,593.000000 
	C689.826538,593.552307 689.653076,594.104675 689.238586,594.828491 
	C687.368225,595.000000 685.738892,595.000000 684.109558,595.000000 
	C675.488342,595.000000 675.488342,595.000000 673.049927,586.386230 
z"/>
<path fill="#013598" opacity="1.000000" stroke="none" 
	d="
M569.033264,559.978210 
	C573.570740,559.195374 576.563843,560.931580 577.807495,566.656677 
	C578.872437,563.392944 579.562378,561.278503 580.624939,559.077148 
	C580.997192,562.393311 580.892944,565.800354 581.021790,569.198486 
	C581.196777,573.810303 581.643372,578.412231 581.792358,583.024231 
	C581.883362,585.842590 581.210510,588.085815 577.090698,585.846069 
	C574.651123,585.592834 572.668945,585.850098 570.842041,585.430786 
	C569.773193,585.185486 568.249390,583.944580 568.137329,583.007202 
	C567.553345,578.123352 567.065674,573.188843 567.138672,568.280823 
	C567.180054,565.504517 568.363953,562.745178 569.033264,559.978210 
z"/>
<path fill="#1A7FC9" opacity="1.000000" stroke="none" 
	d="
M549.947754,558.305359 
	C552.513611,558.844055 556.296692,555.695374 557.263611,560.713745 
	C556.584900,561.003357 556.293579,560.999817 555.714478,561.283386 
	C555.256409,562.644531 555.085083,563.803833 554.918152,564.935486 
	C554.922607,564.907837 554.869995,564.886597 554.869995,564.886597 
	C551.044983,567.939880 547.051819,570.808105 543.437134,574.092773 
	C538.822510,578.286072 534.513489,582.815674 529.988220,587.532959 
	C527.462219,586.634094 525.020752,585.402527 522.904175,583.669189 
	C532.555725,579.573120 537.562622,570.861511 544.832886,564.829529 
	C545.252136,564.481750 544.942078,563.254944 544.971436,562.437195 
	C544.971436,562.437195 544.849670,562.224915 545.166016,562.015015 
	C545.646851,561.510498 545.811401,561.216003 545.975952,560.921509 
	C545.975952,560.921509 545.911804,560.990234 545.874023,560.962769 
	C547.192139,560.177917 548.548096,559.420654 549.947754,558.305359 
z"/>
<path fill="#013598" opacity="1.000000" stroke="none" 
	d="
M607.518555,577.096558 
	C615.790710,581.617798 620.952148,576.720825 622.625183,566.706970 
	C623.078613,566.757568 623.532043,566.808105 623.985474,566.858704 
	C623.522888,571.006470 623.253113,575.193237 622.429016,579.267883 
	C622.271118,580.048828 619.974792,580.652222 618.590332,580.876465 
	C615.818054,581.325562 612.994141,581.455811 609.120300,581.817810 
	C608.982788,581.719666 607.726013,580.822388 606.396240,579.872986 
	C605.264648,581.773560 604.388672,584.571777 603.397888,584.613037 
	C601.710876,584.683228 599.951660,583.020203 598.222778,582.085449 
	C599.188171,580.404846 599.772522,578.038391 601.223694,577.232239 
	C602.744446,576.387451 605.097046,577.040222 607.518555,577.096558 
z"/>
<path fill="#0A3185" opacity="1.000000" stroke="none" 
	d="
M504.985901,587.864502 
	C502.240204,594.430847 495.375366,595.987488 490.100311,597.063538 
	C482.654999,598.582214 474.539764,597.033264 468.918732,590.317444 
	C468.512573,589.832214 468.343353,589.148743 468.391174,588.201294 
	C469.195374,587.832642 469.672394,587.820129 470.408508,588.028442 
	C474.643005,590.403809 478.402405,593.597656 482.642456,594.479065 
	C489.711334,595.948547 496.231689,593.028992 502.398010,588.853394 
	C503.457275,588.446777 504.221588,588.155640 504.985901,587.864502 
z"/>
<path fill="#318AC2" opacity="1.000000" stroke="none" 
	d="
M470.149475,587.807617 
	C469.672394,587.820129 469.195374,587.832642 468.359253,587.844116 
	C465.895905,588.093994 463.791626,588.344788 461.344696,588.796509 
	C458.679077,588.473999 456.356110,587.950623 453.331940,587.269226 
	C453.331940,586.046875 453.331940,584.517822 453.976349,582.618530 
	C458.929993,582.458374 463.239227,582.668457 467.762329,583.106934 
	C468.700623,584.826111 469.425049,586.316895 470.149475,587.807617 
z"/>
<path fill="#0442AB" opacity="1.000000" stroke="none" 
	d="
M494.106781,608.644531 
	C495.243225,606.302185 496.854706,602.529602 497.570038,602.692566 
	C500.319580,603.318604 502.782013,605.205627 505.334686,606.613037 
	C501.130859,614.050293 498.266083,614.460205 494.106781,608.644531 
z"/>
<path fill="#0C307F" opacity="1.000000" stroke="none" 
	d="
M556.001221,561.081543 
	C556.293579,560.999817 556.584900,561.003357 557.095398,561.076660 
	C557.400330,568.143127 557.247620,575.239441 557.673767,582.300720 
	C557.874817,585.632141 559.186279,588.896545 560.171997,592.539307 
	C559.085022,591.960022 557.071777,591.262634 556.702515,590.060303 
	C555.750122,586.959839 555.250244,583.650879 555.073425,580.397888 
	C554.809509,575.540527 554.977722,570.659668 554.916077,565.337708 
	C554.869995,564.886597 554.922607,564.907837 555.202271,564.723633 
	C555.655029,563.386780 555.828125,562.234192 556.001221,561.081543 
z"/>
<path fill="#1A336F" opacity="1.000000" stroke="none" 
	d="
M569.301392,559.702881 
	C568.363953,562.745178 567.180054,565.504517 567.138672,568.280823 
	C567.065674,573.188843 567.553345,578.123352 568.137329,583.007202 
	C568.249390,583.944580 569.773193,585.185486 570.842041,585.430786 
	C572.668945,585.850098 574.651123,585.592834 576.785522,585.808594 
	C576.979248,586.211182 576.955566,586.424805 576.879150,586.955933 
	C576.164185,587.208496 575.498840,587.065247 574.840088,587.090637 
	C565.900330,587.435547 565.905579,587.441345 565.916748,578.558899 
	C565.925049,571.930115 565.918335,565.301392 565.918335,557.972046 
	C569.518250,557.972046 572.785095,557.972046 576.510376,557.992920 
	C574.502380,558.485046 572.035950,558.956360 569.301392,559.702881 
z"/>
<path fill="#318AC2" opacity="1.000000" stroke="none" 
	d="
M505.414398,587.859497 
	C504.221588,588.155640 503.457275,588.446777 502.407196,588.470459 
	C501.646545,586.532715 501.171661,584.862488 500.696777,583.192322 
	C504.066711,583.094116 507.436615,582.995972 511.250916,582.934082 
	C512.209900,584.051147 512.724548,585.131958 513.232483,586.521118 
	C510.764862,587.171143 508.303864,587.512817 505.414398,587.859497 
z"/>
<path fill="#092672" opacity="1.000000" stroke="none" 
	d="
M439.460815,593.817383 
	C446.343323,593.801636 453.225830,593.785889 460.476532,593.735229 
	C459.509735,594.469055 458.201721,595.840088 456.835052,595.901306 
	C451.213104,596.153198 445.573303,596.006897 439.471954,596.013306 
	C438.829437,596.013855 438.655060,596.008118 438.275238,595.649536 
	C438.533478,594.803589 438.997162,594.310486 439.460815,593.817383 
z"/>
<path fill="#013598" opacity="1.000000" stroke="none" 
	d="
M583.913696,591.043457 
	C583.798767,591.741760 583.415527,592.275391 583.032349,592.809021 
	C582.519775,592.134399 582.007202,591.459839 581.494629,590.785217 
	C582.211548,590.816406 582.928467,590.847595 583.913696,591.043457 
z"/>
<path fill="#013598" opacity="1.000000" stroke="none" 
	d="
M595.625122,577.551880 
	C596.057739,577.664124 596.319946,577.939026 596.582153,578.213867 
	C596.468262,578.322205 596.261108,578.531128 596.253967,578.524475 
	C595.976257,578.266846 595.716675,577.989319 595.625122,577.551880 
z"/>
<path fill="#01257D" opacity="1.000000" stroke="none" 
	d="
M907.997559,545.000000 
	C904.832642,545.619446 901.677917,546.667053 898.500122,546.742310 
	C895.067261,546.823486 891.622986,545.848389 888.164734,545.609192 
	C885.080383,545.395935 881.941711,545.291382 878.877747,545.616638 
	C874.221924,546.110962 872.089722,544.492615 872.002991,539.814636 
	C871.984558,538.820435 871.877808,537.807617 872.020630,536.834045 
	C872.752014,531.848938 870.876465,528.855103 865.340454,527.680969 
	C865.333313,520.041565 865.666626,513.020142 866.419800,505.999207 
	C869.059570,505.244110 872.552002,505.041840 873.181152,503.594727 
	C873.990601,501.732941 872.363159,498.890778 872.048096,496.437134 
	C871.770020,494.270844 871.059692,491.665375 871.916687,489.935822 
	C875.172546,483.365021 874.592651,482.169708 866.585510,481.944702 
	C861.905945,481.592072 857.623413,481.439484 853.385071,480.923462 
	C851.865967,480.738495 850.453735,479.676392 849.462158,479.009705 
	C860.289368,479.001587 870.647156,478.998352 881.473267,478.995361 
	C892.293091,479.001923 902.644531,479.008240 913.096008,479.405701 
	C921.133240,480.195007 929.070435,480.593231 937.339172,480.992340 
	C938.112610,480.993866 938.554565,480.994476 938.996521,480.995117 
	C940.325806,482.177979 942.724548,483.350311 942.736267,484.546082 
	C942.754272,486.384796 941.198364,488.276459 940.183044,490.073792 
	C939.580872,491.139832 938.673584,492.029480 938.017517,493.070343 
	C937.502258,493.887665 937.367554,495.363007 936.712097,495.609039 
	C930.199585,498.053528 931.036926,503.422852 930.865295,508.754578 
	C930.691895,514.140076 929.841309,519.534119 928.859863,524.845459 
	C928.546387,526.542297 927.171814,528.884949 925.752441,529.365295 
	C922.505981,530.463928 921.951111,532.392212 921.949280,535.377502 
	C921.943848,544.562866 921.404053,544.992065 911.067322,545.000793 
	C909.500000,545.000427 908.748779,545.000244 907.997559,545.000000 
z"/>
<path fill="#E8F0F4" opacity="1.000000" stroke="none" 
	d="
M888.731750,600.892578 
	C888.449890,601.384827 888.168091,601.877075 887.636597,602.886658 
	C883.486267,611.355530 876.157471,614.499451 869.680969,611.052612 
	C866.641541,609.434875 865.158630,606.855896 866.169373,603.675049 
	C866.704773,601.990173 868.873535,599.426819 869.943665,599.592529 
	C872.370239,599.968262 874.620667,601.711426 876.833069,603.076477 
	C877.379089,603.413452 877.505310,604.430908 877.886292,605.271851 
	C884.799988,597.484863 884.620239,590.384583 879.204041,584.754028 
	C879.204041,584.754028 879.156616,584.853271 879.185425,584.443726 
	C876.398560,575.976562 873.651489,567.893372 870.671082,559.897095 
	C870.337891,559.003235 868.631226,558.670471 867.649231,557.956726 
	C866.423096,557.065613 865.287903,556.049377 864.113403,555.087158 
	C865.424316,554.029053 866.671997,552.164490 868.057312,552.056030 
	C873.345886,551.641724 878.678772,551.792908 884.295288,551.983887 
	C884.837708,553.491882 885.080017,554.748962 885.095154,556.315552 
	C883.317505,557.423218 881.767029,558.221313 879.677185,559.297058 
	C882.328613,567.079285 885.004944,574.934753 888.195007,584.297913 
	C891.767151,574.757202 894.783875,566.700012 897.976685,558.172485 
	C895.894897,558.172485 894.457581,558.172485 892.726807,557.965698 
	C892.050232,557.156494 891.667175,556.554138 891.428101,555.540894 
	C892.376953,554.109375 893.117798,552.274353 893.997559,552.205078 
	C898.456482,551.853943 902.956482,551.837402 907.429565,552.016541 
	C908.285950,552.050842 909.796814,553.531372 909.752258,554.268738 
	C909.678101,555.494385 908.781067,556.839905 907.875977,557.800781 
	C906.843628,558.896667 904.748779,559.359497 904.274170,560.556702 
	C898.964294,573.950867 893.878174,587.433655 888.731750,600.892578 
z"/>
<path fill="#E8EEF3" opacity="1.000000" stroke="none" 
	d="
M913.115479,588.724854 
	C913.115479,588.724854 913.131775,588.325500 913.071472,587.759277 
	C912.825134,583.457825 914.449951,581.020325 918.258240,580.640625 
	C923.420898,580.125916 924.439392,584.026062 925.198914,588.043762 
	C926.564148,588.106262 927.707825,588.239807 928.844604,588.195740 
	C932.612183,588.049500 936.672852,587.788879 937.380981,583.115540 
	C938.053345,578.678040 933.989746,578.023926 931.008850,576.777466 
	C926.886902,575.054016 922.504089,573.818420 918.639465,571.649475 
	C913.606689,568.825012 912.313538,561.326843 915.538452,556.792358 
	C920.455566,549.878540 937.173279,548.562622 942.595825,555.078674 
	C944.022949,556.793518 944.273743,560.389954 943.563599,562.650879 
	C943.119812,564.063721 939.429443,565.772827 937.983398,565.234375 
	C936.008118,564.498901 934.702454,561.808289 933.285889,559.829712 
	C932.887817,559.273682 933.107422,558.275513 933.020386,557.188965 
	C931.362732,557.188965 929.619568,556.678711 928.488831,557.293945 
	C926.054565,558.618469 923.935364,560.522095 921.687195,562.188660 
	C923.439331,564.094727 924.992493,566.258728 927.012878,567.816956 
	C928.382568,568.873230 930.404114,569.092102 932.141907,569.661682 
	C937.441040,571.398376 942.430115,573.557190 945.044067,579.000854 
	C945.111145,580.061707 945.178223,581.122559 945.229370,582.955994 
	C943.222046,586.765686 941.891479,590.798157 939.109802,592.644836 
	C932.020386,597.351257 919.059998,594.960083 913.115479,588.724854 
z"/>
<path fill="#01116C" opacity="1.000000" stroke="none" 
	d="
M939.092773,480.606720 
	C938.554565,480.994476 938.112610,480.993866 937.239380,480.600281 
	C935.315674,479.805359 933.829407,479.085449 932.329773,479.056458 
	C925.887268,478.931732 919.440979,479.010132 912.996033,479.014557 
	C902.644531,479.008240 892.293091,479.001923 881.481506,478.590881 
	C882.382935,477.124817 883.615417,475.469452 885.135803,475.139008 
	C887.519104,474.620972 890.160583,475.327423 892.566223,474.861755 
	C893.845398,474.614166 894.858582,472.992340 896.299683,471.712769 
	C902.072815,471.303864 907.537964,471.173035 913.042847,471.356232 
	C913.722778,472.105560 914.362976,472.540863 915.148926,473.200256 
	C915.862061,473.274963 916.429565,473.125519 917.395996,472.980438 
	C918.857910,472.981354 919.920959,472.977844 921.453308,472.983032 
	C927.889465,472.991730 933.856262,472.991730 941.109131,472.991730 
	C937.330933,468.024139 934.619324,464.458862 931.943909,460.604340 
	C932.303589,459.851776 932.627014,459.388458 933.287048,458.946533 
	C934.072754,458.966705 934.521790,458.965515 935.329773,459.011353 
	C937.308044,460.138000 939.453125,460.863953 940.457520,462.357178 
	C945.315002,469.578949 951.524658,473.580597 960.596680,473.087158 
	C968.377808,472.663910 976.200562,473.004517 984.004883,473.007324 
	C982.721436,473.670959 981.462769,474.843140 980.150146,474.907043 
	C975.383728,475.138916 970.598816,474.963348 965.821777,475.021271 
	C964.313110,475.039551 962.807495,475.307953 961.300537,475.461884 
	C961.276978,476.119263 961.253479,476.776611 961.229980,477.433990 
	C966.286560,477.761841 971.343201,478.089722 976.699829,479.038818 
	C976.998291,480.104645 976.996704,480.549286 976.995117,480.993896 
	C971.148132,480.362122 965.307983,479.304443 959.452393,479.210480 
	C952.705078,479.102234 945.944092,479.845093 939.092773,480.606720 
z"/>
<path fill="#1A316D" opacity="1.000000" stroke="none" 
	d="
M945.355713,578.952026 
	C942.430115,573.557190 937.441040,571.398376 932.141907,569.661682 
	C930.404114,569.092102 928.382568,568.873230 927.012878,567.816956 
	C924.992493,566.258728 923.439331,564.094727 921.687195,562.188660 
	C923.935364,560.522095 926.054565,558.618469 928.488831,557.293945 
	C929.619568,556.678711 931.362732,557.188965 933.020386,557.188965 
	C933.107422,558.275513 932.887817,559.273682 933.285889,559.829712 
	C934.702454,561.808289 936.008118,564.498901 937.983398,565.234375 
	C939.429443,565.772827 943.119812,564.063721 943.563599,562.650879 
	C944.273743,560.389954 944.022949,556.793518 942.595825,555.078674 
	C937.173279,548.562622 920.455566,549.878540 915.538452,556.792358 
	C912.313538,561.326843 913.606689,568.825012 918.639465,571.649475 
	C922.504089,573.818420 926.886902,575.054016 931.008850,576.777466 
	C933.989746,578.023926 938.053345,578.678040 937.380981,583.115540 
	C936.672852,587.788879 932.612183,588.049500 928.844604,588.195740 
	C927.707825,588.239807 926.564148,588.106262 925.198914,588.043762 
	C924.439392,584.026062 923.420898,580.125916 918.258240,580.640625 
	C914.449951,581.020325 912.825134,583.457825 912.963684,587.591003 
	C912.916199,584.677368 912.916199,581.365845 912.916199,577.610718 
	C918.813477,578.538330 924.665283,577.572876 927.595581,584.268188 
	C928.485352,586.301086 932.936279,584.849365 934.882996,581.687744 
	C929.999023,579.573547 925.313293,577.111145 920.350952,575.501587 
	C913.403259,573.248230 912.621155,567.709778 912.297363,561.158447 
	C913.575623,553.859985 915.735901,551.174255 920.987671,550.009766 
	C924.572998,549.343994 928.212830,547.945435 931.729309,548.206665 
	C935.700928,548.501709 939.743042,549.885315 943.420105,551.539124 
	C947.164001,553.222900 946.875244,564.282532 943.291565,567.283508 
	C942.561035,567.895203 941.764099,568.427490 940.638611,569.262939 
	C942.448730,571.727417 944.177795,573.883240 945.643616,576.205261 
	C946.051819,576.851868 945.680847,577.990417 945.355713,578.952026 
z"/>
<path fill="#1A336F" opacity="1.000000" stroke="none" 
	d="
M889.133179,600.905762 
	C893.878174,587.433655 898.964294,573.950867 904.274170,560.556702 
	C904.748779,559.359497 906.843628,558.896667 907.875977,557.800781 
	C908.781067,556.839905 909.678101,555.494385 909.752258,554.268738 
	C909.796814,553.531372 908.285950,552.050842 907.429565,552.016541 
	C902.956482,551.837402 898.456482,551.853943 893.997559,552.205078 
	C893.117798,552.274353 892.376953,554.109375 891.163330,555.449219 
	C889.838013,554.522095 888.921387,553.276001 888.004822,552.029846 
	C891.500305,547.599731 896.463257,550.203125 900.717346,549.489929 
	C902.763062,549.146912 904.975220,549.796875 907.940308,550.010315 
	C912.492310,551.768616 913.445496,557.264282 909.713501,559.261230 
	C903.773193,562.439636 903.972229,567.835327 902.975586,573.000000 
	C899.791382,577.957886 896.204590,582.297485 896.345398,589.172424 
	C896.424683,593.039795 891.954956,597.000427 889.133179,600.905762 
z"/>
<path fill="#1A336F" opacity="1.000000" stroke="none" 
	d="
M879.189209,585.177979 
	C884.620239,590.384583 884.799988,597.484863 877.886292,605.271851 
	C877.505310,604.430908 877.379089,603.413452 876.833069,603.076477 
	C874.620667,601.711426 872.370239,599.968262 869.943665,599.592529 
	C868.873535,599.426819 866.704773,601.990173 866.169373,603.675049 
	C865.158630,606.855896 866.641541,609.434875 869.680969,611.052612 
	C876.157471,614.499451 883.486267,611.355530 887.645020,603.181885 
	C887.024719,606.084595 886.146301,609.209412 885.120361,612.648804 
	C881.571472,613.498535 878.148987,613.926208 874.773560,614.592529 
	C868.739868,615.783569 865.533936,613.604126 864.231567,606.762695 
	C864.025757,604.762024 864.022644,603.374939 864.019531,601.987793 
	C867.161133,595.936646 870.537109,595.396606 880.577637,600.273987 
	C880.060608,594.867676 879.617554,590.234863 879.189209,585.177979 
z"/>
<path fill="#01247B" opacity="1.000000" stroke="none" 
	d="
M903.346802,572.992371 
	C903.972229,567.835327 903.773193,562.439636 909.713501,559.261230 
	C913.445496,557.264282 912.492310,551.768616 908.378174,550.000671 
	C907.991333,548.595459 907.994873,547.213013 907.997986,545.415283 
	C908.748779,545.000244 909.500000,545.000427 910.626831,545.001587 
	C913.190247,546.094604 915.354431,547.238342 917.576355,548.256042 
	C918.613464,548.731140 919.762451,548.962341 920.924194,549.656921 
	C915.735901,551.174255 913.575623,553.859985 912.302063,560.744629 
	C911.360107,562.104370 910.070496,563.221375 910.108765,564.290833 
	C910.278076,569.010437 907.188049,571.082458 903.346802,572.992371 
z"/>
<path fill="#1A316D" opacity="1.000000" stroke="none" 
	d="
M913.076782,589.110840 
	C919.059998,594.960083 932.020386,597.351257 939.109802,592.644836 
	C941.891479,590.798157 943.222046,586.765686 945.314697,583.345215 
	C951.673340,585.422852 946.001953,589.428406 945.252686,590.394348 
	C937.425659,600.484802 921.137085,599.932434 913.415527,591.400818 
	C913.043091,590.989319 913.152832,590.141541 913.076782,589.110840 
z"/>
<path fill="#011B6C" opacity="1.000000" stroke="none" 
	d="
M977.428711,480.995911 
	C976.996704,480.549286 976.998291,480.104645 977.001221,479.326355 
	C979.373230,478.995331 981.743958,478.997955 984.559204,479.006409 
	C982.623169,479.674133 980.242737,480.336029 977.428711,480.995911 
z"/>
<path fill="#011662" opacity="1.000000" stroke="none" 
	d="
M47.807301,145.083115 
	C47.807301,145.083115 47.400642,145.069580 47.198891,144.657288 
	C45.611897,142.856522 44.226658,141.468063 42.841423,140.079590 
	C42.083099,139.801086 41.324772,139.522568 40.277367,139.142365 
	C35.583099,134.443390 31.158546,129.864395 26.784334,125.237816 
	C25.442177,123.818237 24.237881,122.268303 22.888481,120.446777 
	C25.392248,118.054848 28.131126,116.156921 30.530764,113.898544 
	C35.048859,109.646393 33.948597,98.299271 28.829512,94.767807 
	C25.659981,92.581268 26.277908,85.271072 29.776327,83.577972 
	C30.225361,83.360657 30.660204,83.086342 31.133821,82.951721 
	C36.832146,81.331940 42.575428,80.143600 46.165215,74.407455 
	C48.402027,70.833221 51.398479,71.607132 53.650284,75.031914 
	C54.657196,76.563339 55.715282,78.061119 56.750149,79.574165 
	C57.333271,79.282356 57.916393,78.990540 58.499519,78.698730 
	C58.666264,77.391449 58.833008,76.084167 59.384094,74.388733 
	C60.536907,73.991905 61.305378,73.983231 62.345772,74.092026 
	C63.407398,74.810242 64.197105,75.410980 64.968994,76.256851 
	C64.951187,76.501968 64.914871,76.992348 64.569351,77.000084 
	C63.482914,77.671280 62.741993,78.334740 61.712677,79.146584 
	C61.369953,80.351585 61.315620,81.408188 61.261284,82.464798 
	C62.174137,81.643379 63.086994,80.821953 64.300644,80.150513 
	C65.604912,81.710846 67.766167,83.921700 67.430969,84.406509 
	C64.410378,88.775307 64.750275,92.530647 69.903976,96.227425 
	C64.348129,97.353264 63.930901,100.495392 63.915520,104.328697 
	C63.888340,111.100815 63.612373,117.899391 62.901165,124.629898 
	C62.537926,128.067368 60.124561,131.391525 60.163952,134.748993 
	C60.287148,145.249634 56.394852,145.551758 47.807301,145.083115 
M51.353176,78.922157 
	C50.071301,78.656128 48.789425,78.390106 47.542835,78.131401 
	C48.307430,84.051277 48.977196,89.236938 49.583435,93.930733 
	C51.613419,91.802429 53.937168,89.905922 55.495110,87.511200 
	C56.307182,86.262962 56.305176,83.904472 55.684925,82.475388 
	C55.114029,81.160011 53.222816,80.417679 51.353176,78.922157 
z"/>
<path fill="#02378D" opacity="1.000000" stroke="none" 
	d="
M46.546574,44.057198 
	C46.969627,44.269695 46.969978,44.461102 46.687172,45.160454 
	C44.575947,46.412762 42.747875,47.157127 40.925652,47.912308 
	C40.931503,47.923122 40.912739,47.925434 40.564045,47.996109 
	C37.292553,51.003773 36.803997,53.736439 40.904232,56.058167 
	C40.920048,56.031078 40.931255,56.092789 41.024971,56.321587 
	C41.318310,56.836082 41.589062,57.012833 42.244759,57.299561 
	C44.375931,57.653740 46.193295,57.788998 48.147797,58.233997 
	C51.853596,62.366184 55.422260,66.188629 58.617119,70.010559 
	C57.495892,69.989220 56.748466,69.968399 56.001045,69.947571 
	C53.448135,67.262184 50.980370,64.486191 48.284683,61.952728 
	C47.577862,61.288448 46.049984,60.940346 45.093399,61.190311 
	C39.168869,62.738438 38.015488,57.845551 36.467785,54.402897 
	C34.785816,50.661579 36.498688,47.025650 39.902946,44.955833 
	C41.553005,43.952583 44.024349,44.300121 46.546574,44.057198 
z"/>
<path fill="#073CA0" opacity="1.000000" stroke="none" 
	d="
M24.716860,9.343799 
	C27.586958,8.881906 30.015366,9.063629 29.853714,12.342043 
	C29.778917,13.859020 28.632021,16.543383 27.887758,16.572590 
	C25.857904,16.652246 23.783649,15.600371 21.726986,14.996778 
	C22.603003,13.160421 23.479019,11.324066 24.716860,9.343799 
z"/>
<path fill="#001A66" opacity="1.000000" stroke="none" 
	d="
M56.124290,70.250366 
	C56.748466,69.968399 57.495892,69.989220 58.901001,70.171349 
	C60.397076,71.546623 61.235462,72.760597 62.073853,73.974564 
	C61.305378,73.983231 60.536907,73.991905 59.384155,74.000778 
	C58.082432,72.851700 57.164986,71.702431 56.124290,70.250366 
z"/>
<path fill="#012174" opacity="1.000000" stroke="none" 
	d="
M161.011581,36.927917 
	C163.380524,36.934696 165.749466,36.941475 168.562683,36.954304 
	C170.448547,37.640213 171.880524,38.884384 173.333298,38.909153 
	C183.435181,39.081398 193.541870,39.058949 203.645966,38.973438 
	C207.965759,38.936886 210.439819,39.509083 209.249496,45.138103 
	C208.385818,49.222363 209.616302,53.733864 209.771378,58.059937 
	C209.810989,59.165062 209.313217,60.289448 208.709320,61.809036 
	C207.923737,62.830769 207.491150,63.448448 206.638000,64.090851 
	C189.139908,64.240074 172.062378,64.364578 155.067261,64.158638 
	C156.691254,62.807709 158.232819,61.787228 161.112595,59.880894 
	C156.508347,58.617928 152.929535,57.636246 149.907333,56.807240 
	C151.768112,53.530396 153.346344,50.751114 155.183365,47.516109 
	C153.492981,47.272240 152.479401,47.315662 151.631958,46.961533 
	C150.178818,46.354286 148.829514,45.498596 147.436600,44.747215 
	C148.836060,44.165291 150.198456,43.230377 151.643097,43.078648 
	C154.244568,42.805420 156.895309,43.001438 159.525452,43.001438 
	C159.542831,42.210281 159.560196,41.419128 159.577560,40.627972 
	C156.512619,40.418846 153.419037,40.396168 150.393295,39.931587 
	C148.539230,39.646908 146.789200,38.684677 145.459625,38.016964 
	C150.955048,37.647449 155.983322,37.287682 161.011581,36.927917 
z"/>
<path fill="#012F8B" opacity="1.000000" stroke="none" 
	d="
M289.017883,37.353764 
	C292.809265,39.961758 296.621643,42.912975 300.389923,45.919411 
	C302.404327,47.526562 304.342773,49.228920 307.096161,50.943478 
	C309.917450,50.998795 311.958130,50.998940 313.998779,50.999084 
	C321.352722,51.001152 328.706665,51.003220 336.831543,51.288712 
	C342.735291,51.709579 347.868103,51.847027 353.298157,52.277885 
	C362.360199,52.714764 371.125580,52.835018 379.889496,53.022514 
	C381.518921,53.057373 383.727875,52.751537 384.666351,53.650261 
	C388.497742,57.319572 392.190399,55.402580 396.214081,54.121555 
	C396.699463,54.426750 396.889862,54.682056 397.059631,55.347916 
	C398.629730,56.222828 400.107971,57.003338 401.661407,57.205456 
	C403.677002,57.467705 405.752502,57.269497 407.802216,57.269497 
	C407.873688,57.967422 407.945160,58.665344 408.016632,59.363270 
	C405.952515,59.907139 403.850739,60.341442 401.837494,61.033325 
	C400.490967,61.496056 399.270233,62.324875 397.594604,62.993126 
	C396.132996,62.994049 395.069977,62.991711 393.944885,62.643288 
	C391.245209,59.148739 388.928192,59.161194 386.530151,62.997700 
	C357.702087,62.985390 329.342651,62.970638 300.983185,62.955887 
	C301.633911,61.707706 302.284668,60.459526 303.141479,58.816105 
	C299.552582,57.870850 296.274445,57.007439 292.996338,56.144028 
	C293.047119,55.719513 293.097931,55.294998 293.148712,54.870480 
	C296.408478,54.532715 299.668243,54.194950 302.554382,53.895901 
	C297.673859,48.062607 293.347198,42.891285 289.017883,37.353764 
z"/>
<path fill="#0350AC" opacity="1.000000" stroke="none" 
	d="
M300.521912,62.970314 
	C329.342651,62.970638 357.702087,62.985390 386.967957,63.004745 
	C389.918579,63.002697 391.962799,62.996037 394.006989,62.989372 
	C395.069977,62.991711 396.132996,62.994049 398.063934,63.001503 
	C408.140900,63.005863 417.351379,62.923054 426.557495,63.086021 
	C428.072052,63.112827 429.567505,64.218773 430.598633,64.877716 
	C357.546875,64.946281 284.968292,64.969620 212.389709,64.941681 
	C210.612534,64.941002 208.835602,64.370735 207.058548,64.066124 
	C207.491150,63.448448 207.923737,62.830769 208.668243,62.190186 
	C210.732407,62.453468 212.484100,62.984844 214.236938,62.988617 
	C236.176819,63.035847 258.116882,63.006664 280.963318,63.004982 
	C283.915314,63.001965 285.960876,62.991039 288.006470,62.980110 
	C292.024536,62.981651 296.042603,62.983192 300.521912,62.970314 
z"/>
<path fill="#022590" opacity="1.000000" stroke="none" 
	d="
M171.358475,36.941116 
	C170.673935,35.821861 170.469604,34.735199 170.124695,33.338264 
	C171.875229,32.693844 173.770798,32.047394 175.656723,32.074406 
	C192.435532,32.314690 209.212372,32.822723 225.990463,32.850658 
	C228.758896,32.855267 231.465530,30.631186 234.323761,29.964769 
	C236.205093,29.526121 239.947617,29.705183 240.040970,30.171408 
	C240.600357,32.965305 240.343002,35.922726 240.359406,38.825336 
	C238.260132,38.694118 235.829773,39.193592 234.130051,38.301281 
	C230.515442,36.403702 227.083191,35.681091 222.894028,35.848595 
	C205.887772,36.528595 188.859100,36.647732 171.358475,36.941116 
z"/>
<path fill="#022590" opacity="1.000000" stroke="none" 
	d="
M161.011688,32.649460 
	C154.584641,32.980755 148.152969,33.049786 141.724548,32.935352 
	C140.197601,32.908169 138.681580,32.266502 137.014130,31.539972 
	C143.264297,31.110880 149.660858,31.049295 156.524963,30.979748 
	C158.061218,30.981443 159.129974,30.991104 160.599365,31.001301 
	C161.002747,31.441242 161.005508,31.880648 161.011688,32.649460 
z"/>
<path fill="#012F8B" opacity="1.000000" stroke="none" 
	d="
M287.954773,62.612858 
	C285.960876,62.991039 283.915314,63.001965 281.431458,62.996235 
	C283.151917,59.049706 284.725525,58.882553 287.954773,62.612858 
z"/>
<path fill="#012F8B" opacity="1.000000" stroke="none" 
	d="
M287.218384,55.797344 
	C286.929504,54.895863 286.976318,54.095997 287.023163,53.296127 
	C287.839569,53.675449 288.655945,54.054768 289.472351,54.434090 
	C288.832916,54.922382 288.193481,55.410667 287.218384,55.797344 
z"/>
<path fill="#032A82" opacity="1.000000" stroke="none" 
	d="
M848.992676,479.014648 
	C850.453735,479.676392 851.865967,480.738495 853.385071,480.923462 
	C857.623413,481.439484 861.905945,481.592072 866.585693,482.378082 
	C868.980896,490.678345 867.691284,498.347260 866.000000,505.998779 
	C865.666626,513.020142 865.333313,520.041565 865.000000,527.531494 
	C864.355286,528.152466 863.702393,528.465332 863.066956,528.433228 
	C853.325439,527.941650 849.090637,532.846069 849.401062,542.268555 
	C849.544189,546.611084 847.052124,547.536499 843.327332,547.034241 
	C842.288269,546.894165 841.192688,547.173340 840.142822,547.702637 
	C842.903076,547.948181 845.663269,548.193787 848.258057,548.724854 
	C845.403992,549.010986 842.715454,549.011597 839.598999,549.007812 
	C837.425293,549.181458 835.602478,549.103455 833.952271,549.599487 
	C831.658752,550.288940 828.443970,550.358032 829.176331,554.265869 
	C829.152222,554.261719 829.200806,554.271790 829.200806,554.271790 
	C824.441833,555.097656 823.449951,561.148499 827.373169,565.813904 
	C828.309143,566.927002 829.501587,567.824463 830.752869,568.984985 
	C827.482727,572.488403 822.250366,574.146118 822.797668,580.581909 
	C823.229675,585.662354 823.272705,590.431519 827.895935,593.896973 
	C827.628357,594.276428 827.360779,594.655823 827.093201,595.035278 
	C823.566956,593.579956 820.059021,592.077209 816.500732,590.704834 
	C815.677856,590.387512 814.683228,590.515808 813.767578,590.439026 
	C813.756897,591.323364 813.412537,592.419678 813.809692,593.044922 
	C814.490601,594.116943 815.634949,594.894531 817.678650,596.833740 
	C814.274109,598.045715 811.529724,599.022644 808.395142,600.000977 
	C807.151367,599.113892 806.297913,598.225281 805.453247,596.894409 
	C806.616699,595.427917 808.437744,594.606079 808.820129,593.347351 
	C811.727844,583.775269 814.246216,574.084717 817.171936,564.518555 
	C817.675842,562.870850 819.350037,560.677917 820.809265,560.390686 
	C825.678345,559.432068 823.767090,555.897705 823.903381,553.311157 
	C824.083008,549.901672 821.400024,549.983948 818.802917,549.641479 
	C815.429932,548.922913 812.255615,548.535339 809.081238,548.147827 
	C809.051941,548.761963 809.022644,549.376099 808.592529,549.993774 
	C806.136475,550.336670 804.081238,550.676086 801.769958,551.010254 
	C801.343506,551.003357 801.172974,551.001709 800.685547,550.836792 
	C799.665405,549.143860 798.962097,547.614075 798.258789,546.084351 
	C799.838440,545.403076 801.690552,543.816650 802.950867,544.195984 
	C808.628845,545.905029 809.124023,542.724426 809.010315,538.543030 
	C808.951660,536.386902 808.801514,534.201721 809.076965,532.079529 
	C809.250916,530.739502 809.923523,528.536804 810.734436,528.357483 
	C815.985657,527.195862 814.525085,523.182007 815.143799,519.744202 
	C815.646790,516.948975 816.773743,513.556396 818.818909,511.951721 
	C820.789307,510.405701 822.491577,508.904541 824.266235,507.100708 
	C827.366699,503.949463 827.789673,499.789764 829.890808,496.592010 
	C832.647461,492.396729 831.821899,488.334900 831.785583,484.189789 
	C831.776184,483.122467 829.680786,481.396271 828.335083,481.159760 
	C825.596985,480.678558 822.694641,481.212189 819.904663,480.912018 
	C818.548096,480.766052 817.292175,479.684326 816.460327,479.017548 
	C827.617554,479.010803 838.305115,479.012726 848.992676,479.014648 
z"/>
<path fill="#E8F0F4" opacity="1.000000" stroke="none" 
	d="
M833.105286,593.829163 
	C826.475098,589.887207 824.514832,585.574158 826.152954,578.532043 
	C827.286682,573.658264 832.651306,569.730347 839.097168,569.358826 
	C842.881531,569.140747 846.688782,569.319092 850.429993,569.319092 
	C851.209534,559.486633 848.806641,556.869263 839.816406,557.347168 
	C839.344055,559.163025 839.437073,561.954834 838.255554,562.685425 
	C835.871521,564.159424 832.397461,565.771790 830.093201,565.044617 
	C826.277344,563.840637 826.192444,559.942322 829.065308,554.612915 
	C829.200806,554.271790 829.152222,554.261719 829.553650,554.266235 
	C833.310547,553.163147 836.665955,552.055603 840.464722,551.010132 
	C855.121826,549.999023 860.021667,555.681030 859.442444,569.379578 
	C859.192200,575.297058 859.400452,581.233826 859.400452,587.397766 
	C861.245972,587.520264 862.608215,587.368164 863.795654,587.753967 
	C865.127136,588.186646 866.300598,589.105896 867.542786,589.813416 
	C866.481995,591.213440 865.532471,593.698914 864.340698,593.821106 
	C859.772156,594.289429 854.734009,596.147888 851.121399,591.226746 
	C845.336426,594.964233 839.280640,594.868408 833.105286,593.829163 
M836.840698,586.912354 
	C837.613464,587.070862 838.386292,587.229431 839.881409,587.608032 
	C840.875366,587.541321 841.869385,587.474548 843.502014,587.582397 
	C844.672058,587.070557 845.842041,586.558777 847.728821,585.737976 
	C848.801270,582.655029 849.873718,579.572021 851.314636,575.429626 
	C845.408630,576.116211 841.216064,576.603638 836.452148,577.005127 
	C836.277405,577.314819 836.102661,577.624512 835.471863,578.435974 
	C835.187988,579.907654 834.904114,581.379272 834.402893,583.452698 
	C834.826050,584.113220 835.249268,584.773743 835.870850,585.820374 
	C836.043274,586.064087 836.267883,586.241699 836.840698,586.912354 
z"/>
<path fill="#1A336F" opacity="1.000000" stroke="none" 
	d="
M833.139221,594.208740 
	C839.280640,594.868408 845.336426,594.964233 851.121399,591.226746 
	C854.734009,596.147888 859.772156,594.289429 864.340698,593.821106 
	C865.532471,593.698914 866.481995,591.213440 867.542786,589.813416 
	C866.300598,589.105896 865.127136,588.186646 863.795654,587.753967 
	C862.608215,587.368164 861.245972,587.520264 859.400452,587.397766 
	C859.400452,581.233826 859.192200,575.297058 859.442444,569.379578 
	C860.021667,555.681030 855.121826,549.999023 840.462891,550.687439 
	C840.020752,549.872498 840.023804,549.442383 840.026855,549.012207 
	C842.715454,549.011597 845.403992,549.010986 848.540283,549.011475 
	C857.970032,551.185669 861.092834,555.283875 860.993591,565.363770 
	C860.946167,570.184204 860.975952,575.005676 860.998596,579.826538 
	C861.012390,582.757751 861.751099,584.649658 865.353149,585.195984 
	C871.351318,586.105835 868.878052,590.690063 868.672424,595.182495 
	C863.708008,595.437256 858.820312,595.682983 853.933167,595.940613 
	C850.223206,596.136292 846.495117,596.670166 842.810181,596.454285 
	C839.572510,596.264648 836.383606,595.242676 833.139221,594.208740 
z"/>
<path fill="#0C307F" opacity="1.000000" stroke="none" 
	d="
M887.712769,551.879517 
	C888.921387,553.276001 889.838013,554.522095 891.019348,555.859985 
	C891.667175,556.554138 892.050232,557.156494 892.772888,558.263306 
	C893.424988,559.964111 894.248962,561.304382 893.960022,562.331665 
	C892.801880,566.449829 891.351562,570.490295 889.880310,574.512329 
	C889.567139,575.368408 888.713623,576.026794 887.998657,576.913086 
	C885.967285,571.634338 884.048462,566.979614 882.485596,562.208313 
	C882.266541,561.539429 883.635864,559.661926 884.539307,559.439880 
	C887.522339,558.706848 888.180481,557.877319 885.322327,556.005981 
	C885.080017,554.748962 884.837708,553.491882 884.299316,551.703674 
	C884.000488,550.799561 883.997803,550.426636 883.995117,550.053711 
	C885.136963,550.612244 886.278870,551.170715 887.712769,551.879517 
z"/>
<path fill="#1A336F" opacity="1.000000" stroke="none" 
	d="
M883.604614,550.031128 
	C883.997803,550.426636 884.000488,550.799561 883.999146,551.452759 
	C878.678772,551.792908 873.345886,551.641724 868.057312,552.056030 
	C866.671997,552.164490 865.424316,554.029053 864.113403,555.087158 
	C865.287903,556.049377 866.423096,557.065613 867.649231,557.956726 
	C868.631226,558.670471 870.337891,559.003235 870.671082,559.897095 
	C873.651489,567.893372 876.398560,575.976562 879.239380,584.432861 
	C878.035461,582.669861 876.570068,580.606018 875.626038,578.326294 
	C873.575500,573.374146 871.988770,568.222351 869.781494,563.347290 
	C869.087646,561.814758 867.193237,560.486450 865.542603,559.800537 
	C862.535461,558.551025 861.629944,556.556091 862.080322,553.556274 
	C862.569763,550.296082 864.758179,549.912048 867.516052,549.975830 
	C872.746216,550.096802 877.981140,550.009399 883.604614,550.031128 
z"/>
<path fill="#022274" opacity="1.000000" stroke="none" 
	d="
M863.639282,601.997925 
	C864.022644,603.374939 864.025757,604.762024 864.040039,606.563965 
	C861.605896,606.978699 859.160583,606.978699 856.715210,606.978699 
	C856.514587,606.440125 856.313965,605.901550 856.113281,605.363037 
	C858.495178,604.244690 860.877075,603.126404 863.639282,601.997925 
z"/>
<path fill="#032A82" opacity="1.000000" stroke="none" 
	d="
M829.955078,597.583740 
	C829.722290,597.201477 829.724304,596.821472 829.753723,596.443604 
	C829.754517,596.433228 830.049011,596.445557 830.206604,596.447327 
	C830.202271,596.826965 830.197998,597.206665 829.955078,597.583740 
z"/>
<path fill="#01369A" opacity="1.000000" stroke="none" 
	d="
M739.010620,68.762054 
	C731.539612,62.139553 724.022400,55.291973 716.800476,48.145885 
	C711.093445,42.498711 705.749451,36.484623 700.108398,30.323921 
	C704.346436,32.009178 709.353088,33.141891 711.319458,38.408245 
	C711.486877,38.856655 711.824646,39.273376 712.169373,39.619011 
	C720.174744,47.646069 728.102966,55.754360 736.294983,63.586720 
	C737.653748,64.885864 740.173218,64.971031 742.581970,65.755898 
	C741.345886,66.695244 740.218811,67.551743 739.010620,68.762054 
z"/>
<path fill="#01369A" opacity="1.000000" stroke="none" 
	d="
M744.133423,78.458076 
	C744.173889,77.354530 744.441223,76.490005 744.846436,75.312851 
	C745.969482,76.521271 746.954590,78.042320 747.906433,79.970734 
	C746.702271,79.817764 745.531311,79.257431 744.133423,78.458076 
z"/>
<path fill="#01369A" opacity="1.000000" stroke="none" 
	d="
M698.986694,28.703251 
	C698.343262,28.589201 697.703735,28.143217 697.010742,27.386383 
	C697.635071,27.507463 698.312866,27.939392 698.986694,28.703251 
z"/>
<path fill="#01369A" opacity="1.000000" stroke="none" 
	d="
M750.778320,81.960770 
	C750.319519,82.054245 749.721436,81.852005 748.984497,81.355103 
	C749.443420,81.261978 750.041260,81.463516 750.778320,81.960770 
z"/>
<path fill="#EAF2F7" opacity="1.000000" stroke="none" 
	d="
M730.953918,594.184753 
	C730.516846,594.187134 730.079712,594.189514 728.899658,594.267212 
	C724.644348,593.805298 721.132141,593.268005 718.188293,592.817627 
	C712.526489,593.247559 707.064148,594.611694 701.918457,593.846436 
	C692.513672,592.447815 688.732483,580.951843 695.511841,574.328125 
	C698.299683,571.604187 702.964172,570.424194 706.994690,569.467529 
	C710.250244,568.694702 713.835449,569.310852 717.207520,569.310852 
	C717.882812,559.123535 715.076843,556.279907 705.913818,557.531616 
	C706.445679,561.602295 704.879150,564.527222 700.630493,565.695435 
	C700.494141,565.608643 700.237915,565.805603 699.880249,565.669434 
	C699.017700,565.506165 698.512756,565.479004 697.643372,565.269409 
	C692.713440,560.720337 693.737244,555.344482 699.887573,552.939209 
	C706.857666,550.213379 713.967712,550.058777 720.989685,553.023560 
	C720.989685,553.023560 720.999695,552.998657 721.125977,553.323486 
	C722.811584,557.344177 725.359558,560.946289 725.720825,564.755737 
	C726.432373,572.258301 725.930420,579.875977 725.930420,587.763000 
	C729.560120,587.763000 732.375122,587.618225 735.169434,587.796936 
	C739.347595,588.064209 741.044678,586.615234 740.932434,582.102600 
	C740.638855,570.297119 740.835510,558.479492 740.836487,546.666687 
	C740.836853,542.888184 740.836548,539.109680 740.836548,534.831299 
	C738.266235,534.831299 736.271973,535.031799 734.351257,534.743042 
	C733.449585,534.607483 732.174683,533.649414 731.988647,532.849976 
	C731.724060,531.713318 731.894470,530.022522 732.607361,529.198669 
	C734.523743,526.984009 746.294983,527.015442 749.090942,529.452332 
	C749.045776,548.999573 749.045776,568.079529 749.045776,587.682312 
	C751.312500,587.541016 753.234314,587.421143 755.425903,587.488770 
	C756.114746,587.803833 756.533752,587.931580 756.976440,588.481384 
	C757.573303,592.512573 756.263733,594.003540 752.397278,593.919067 
	C745.254822,593.762939 738.102295,594.070374 730.953918,594.184753 
M717.717224,579.448547 
	C716.951294,578.121521 716.286987,575.741394 715.401123,575.655884 
	C712.132874,575.340393 708.644531,575.263611 705.533813,576.141846 
	C703.734192,576.649902 701.706055,578.960571 701.246155,580.822021 
	C700.837280,582.476685 701.899658,585.268311 703.268982,586.447266 
	C707.787170,590.337219 716.921753,586.259155 717.717224,579.448547 
z"/>
<path fill="#163A84" opacity="1.000000" stroke="none" 
	d="
M700.766785,565.782288 
	C704.879150,564.527222 706.445679,561.602295 705.913818,557.531616 
	C715.076843,556.279907 717.882812,559.123535 717.207520,569.310852 
	C713.835449,569.310852 710.250244,568.694702 706.994690,569.467529 
	C702.964172,570.424194 698.299683,571.604187 695.511841,574.328125 
	C688.732483,580.951843 692.513672,592.447815 701.918457,593.846436 
	C707.064148,594.611694 712.526489,593.247559 718.188293,592.817627 
	C721.132141,593.268005 724.644348,593.805298 728.601807,594.409302 
	C727.208069,599.067993 724.054443,596.792664 721.096497,596.095032 
	C719.152649,595.636536 717.116577,595.569397 715.060913,595.663513 
	C715.000000,596.000000 715.000244,595.998901 715.000244,595.998901 
	C710.601196,596.326904 706.202148,596.654968 701.194092,596.728577 
	C699.730530,596.313660 698.875916,596.153137 698.021301,595.992676 
	C696.543030,594.757202 695.064697,593.521790 693.262573,591.824158 
	C692.292480,590.574707 691.646240,589.787354 690.978271,588.703369 
	C690.641785,587.934509 690.327026,587.462341 690.053345,586.612183 
	C690.382935,584.489441 690.671326,582.744690 690.959778,581.000000 
	C690.707275,580.503479 690.454712,580.006958 690.239136,578.937256 
	C690.524902,577.246704 690.773560,576.129456 691.022217,575.012207 
	C693.493408,572.446289 695.964600,569.880432 698.209717,566.866516 
	C698.010803,566.096680 698.018860,565.774475 698.007874,565.451843 
	C698.512756,565.479004 699.017700,565.506165 699.942871,565.704834 
	C700.363159,565.876404 700.766785,565.782288 700.766785,565.782288 
z"/>
<path fill="#032A82" opacity="1.000000" stroke="none" 
	d="
M796.032349,590.989258 
	C793.429871,594.856995 789.630798,593.515259 785.914307,593.262573 
	C784.294861,595.931030 782.112122,598.170715 784.605469,601.974304 
	C786.065186,604.200928 779.504333,609.721130 776.396118,608.417603 
	C772.841919,606.927063 770.199951,606.886597 767.387024,609.567688 
	C766.838684,610.090332 765.365662,609.642822 763.575378,609.642822 
	C765.300903,606.496033 766.611572,604.105774 767.961121,601.357788 
	C769.305237,599.872070 770.610413,598.744080 772.408569,597.294128 
	C775.046631,596.883484 777.291992,597.164429 779.312073,596.615662 
	C782.046265,595.872803 784.370239,594.385376 781.926758,590.685730 
	C782.181763,589.899475 782.453369,589.403564 783.100891,588.881226 
	C783.995239,588.123718 784.532898,587.405457 785.028992,586.659485 
	C787.430908,583.047791 789.821655,579.428711 792.148682,575.901184 
	C792.080994,575.990173 792.184265,575.791870 792.130127,576.143188 
	C792.376587,577.324585 792.677185,578.154602 793.080200,579.130310 
	C793.182617,579.276001 793.138977,579.629395 793.125977,579.981323 
	C793.506348,580.846619 793.899719,581.359985 794.230469,581.882690 
	C794.167786,581.891968 794.201599,581.769897 794.146362,582.137573 
	C794.492859,583.292053 794.894592,584.078796 795.235474,584.876099 
	C795.174622,584.886658 795.208069,584.767822 795.173950,585.098145 
	C795.497864,585.924316 795.855957,586.420166 796.161377,586.918823 
	C796.108765,586.921570 796.138489,586.820435 796.095337,587.236267 
	C796.045532,588.764465 796.038940,589.876892 796.032349,590.989258 
M788.918701,585.284973 
	C788.972168,585.773254 789.025574,586.261536 789.079041,586.749817 
	C789.513916,586.637207 789.948853,586.524536 790.383789,586.411926 
	C790.089661,585.994385 789.795593,585.576843 788.918701,585.284973 
z"/>
<path fill="#012E8B" opacity="1.000000" stroke="none" 
	d="
M760.000000,587.000000 
	C760.000000,587.000000 759.508545,586.999878 759.066589,586.762573 
	C757.641296,586.644531 756.657959,586.763794 755.461670,586.600220 
	C751.998596,586.268677 750.695435,584.835266 750.919434,581.403503 
	C751.231567,576.621765 751.000732,571.804504 751.269897,567.189941 
	C752.325806,567.429810 753.784607,567.614319 753.804138,567.515137 
	C754.294128,565.029053 754.632446,562.513123 754.998779,560.001221 
	C755.000000,560.000000 755.002441,559.997559 755.403381,560.051880 
	C758.267151,560.361084 760.729980,560.615906 763.140930,560.901672 
	C763.089050,560.932617 763.137756,560.822083 763.093872,561.281250 
	C763.699646,566.496033 764.349304,571.251709 764.647949,576.042480 
	C763.104736,575.555420 761.491638,575.346375 760.816345,574.439392 
	C759.734253,572.985901 759.273438,571.069946 758.550110,569.349487 
	C758.137878,569.401306 757.725586,569.453186 757.313354,569.505005 
	C756.606812,573.262207 755.900269,577.019409 755.193787,580.776611 
	C755.738220,580.631592 756.282715,580.486572 756.827148,580.341553 
	C757.884766,582.561035 758.942383,584.780518 760.000000,587.000000 
z"/>
<path fill="#022B82" opacity="1.000000" stroke="none" 
	d="
M760.412109,587.010376 
	C758.942383,584.780518 757.884766,582.561035 756.827148,580.341553 
	C756.282715,580.486572 755.738220,580.631592 755.193787,580.776611 
	C755.900269,577.019409 756.606812,573.262207 757.313354,569.505005 
	C757.725586,569.453186 758.137878,569.401306 758.550110,569.349487 
	C759.273438,571.069946 759.734253,572.985901 760.816345,574.439392 
	C761.491638,575.346375 763.104736,575.555420 764.769775,576.325378 
	C765.161743,577.714539 765.080872,578.856079 764.634155,579.999634 
	C763.345581,580.224548 762.422913,580.447388 761.500183,580.670288 
	C761.516418,580.967651 761.532715,581.265015 761.548950,581.562378 
	C762.700134,581.708252 763.851257,581.854126 765.345093,582.034424 
	C766.781738,582.379028 767.875732,582.689209 768.979065,583.371704 
	C769.325562,585.163513 769.662781,586.583008 770.000000,588.002441 
	C766.941406,587.675232 763.882812,587.348022 760.412109,587.010376 
z"/>
<path fill="#234380" opacity="1.000000" stroke="none" 
	d="
M731.083008,594.556946 
	C738.102295,594.070374 745.254822,593.762939 752.397278,593.919067 
	C756.263733,594.003540 757.573303,592.512573 757.251587,588.439636 
	C757.688660,587.973877 757.874329,587.971985 758.059937,587.970154 
	C758.058289,590.042358 758.056702,592.114563 757.727295,594.708862 
	C756.905823,596.144165 756.412292,597.057251 755.918701,597.970398 
	C755.918701,597.970398 755.468323,597.945435 754.988892,597.840393 
	C754.022217,597.483948 753.534912,597.232544 753.047668,596.981140 
	C747.983154,596.642151 742.913757,596.361145 737.856384,595.937439 
	C735.629089,595.750793 733.426147,595.274109 731.083008,594.556946 
z"/>
<path fill="#142A6D" opacity="1.000000" stroke="none" 
	d="
M770.052368,588.388428 
	C769.662781,586.583008 769.325562,585.163513 768.936768,582.973694 
	C768.645142,580.349182 768.405151,578.494995 768.457336,576.885620 
	C769.930054,581.254639 770.920105,585.447571 772.373779,589.473083 
	C773.067993,591.395630 774.273193,593.863464 775.899292,594.558716 
	C778.757568,595.780884 780.703918,593.536377 781.943420,590.976013 
	C784.370239,594.385376 782.046265,595.872803 779.312073,596.615662 
	C777.291992,597.164429 775.046631,596.883484 772.465271,596.961182 
	C771.387573,594.224854 770.746155,591.499695 770.052368,588.388428 
z"/>
<path fill="#02257F" opacity="1.000000" stroke="none" 
	d="
M752.575073,596.989014 
	C753.534912,597.232544 754.022217,597.483948 754.763306,597.841187 
	C754.922607,598.261414 754.929321,598.719910 754.718079,598.868469 
	C752.009705,600.773010 739.592224,600.410828 736.403992,596.996826 
	C741.636841,596.996826 746.869629,596.996826 752.575073,596.989014 
z"/>
<path fill="#04338D" opacity="1.000000" stroke="none" 
	d="
M707.858887,606.280762 
	C706.652039,607.220703 705.380676,607.784607 704.109314,608.348511 
	C703.915527,607.322327 703.721741,606.296143 703.527954,605.269958 
	C704.950073,605.481567 706.372253,605.693176 707.858887,606.280762 
z"/>
<path fill="#04338D" opacity="1.000000" stroke="none" 
	d="
M697.627441,595.994995 
	C698.875916,596.153137 699.730530,596.313660 700.797607,596.727661 
	C698.553162,599.601013 696.057434,600.553528 692.669983,596.565979 
	C694.652649,596.318909 695.943115,596.158142 697.627441,595.994995 
z"/>
<path fill="#013290" opacity="1.000000" stroke="none" 
	d="
M690.012207,586.990234 
	C690.327026,587.462341 690.641785,587.934509 690.749023,588.872620 
	C690.694092,590.223999 690.846741,591.109497 690.999390,591.994934 
	C690.681885,592.105530 690.423401,592.296143 690.111938,592.783325 
	C688.844360,591.462646 687.688660,589.925232 687.086304,588.166626 
	C688.430481,587.627014 689.221375,587.308655 690.012207,586.990234 
z"/>
<path fill="#04338D" opacity="1.000000" stroke="none" 
	d="
M691.681763,605.176880 
	C692.031738,606.078918 692.034729,606.883423 692.037720,607.687988 
	C690.793762,607.447937 689.549805,607.207947 688.305847,606.967896 
	C688.331238,606.544800 688.356689,606.121643 688.382080,605.698547 
	C689.366272,605.492188 690.350525,605.285828 691.681763,605.176880 
z"/>
<path fill="#04338D" opacity="1.000000" stroke="none" 
	d="
M691.219482,592.079956 
	C690.846741,591.109497 690.694092,590.223999 690.770752,589.169312 
	C691.646240,589.787354 692.292480,590.574707 692.968811,591.682251 
	C692.479126,592.056641 691.959290,592.110840 691.219482,592.079956 
z"/>
<path fill="#02257F" opacity="1.000000" stroke="none" 
	d="
M758.268555,587.862793 
	C757.874329,587.971985 757.688660,587.973877 757.227905,588.017578 
	C756.533752,587.931580 756.114746,587.803833 755.500366,587.402588 
	C755.305115,587.129089 755.674622,586.883057 755.674622,586.883057 
	C756.657959,586.763794 757.641296,586.644531 758.820862,586.761353 
	C758.911865,587.303467 758.731873,587.556091 758.268555,587.862793 
z"/>
<path fill="#02257F" opacity="1.000000" stroke="none" 
	d="
M756.275269,597.926270 
	C756.412292,597.057251 756.905823,596.144165 757.682373,595.070312 
	C757.520874,595.900513 757.076355,596.891357 756.275269,597.926270 
z"/>
<path fill="#163A84" opacity="1.000000" stroke="none" 
	d="
M714.999390,596.000610 
	C715.631042,596.040466 716.263306,596.079773 716.895569,596.119080 
	C716.893066,596.268311 716.890564,596.417603 716.888000,596.566895 
	C716.374268,596.525757 715.860474,596.484680 715.173462,596.221191 
	C715.000244,595.998901 715.000000,596.000000 714.999390,596.000610 
z"/>
<path fill="#02257F" opacity="1.000000" stroke="none" 
	d="
M730.239075,599.637024 
	C730.006836,599.622498 729.880371,599.496521 729.753906,599.370544 
	C729.950867,599.422180 730.147888,599.473877 730.239075,599.637024 
z"/>
<path fill="#011C7F" opacity="1.000000" stroke="none" 
	d="
M770.658875,95.172424 
	C768.462463,93.726860 766.265991,92.281303 763.987671,90.510040 
	C769.676025,87.186348 773.897949,82.572701 778.099548,77.680588 
	C782.196594,72.910240 787.349243,69.054024 791.947510,64.700401 
	C792.859924,63.836563 793.228882,62.422089 794.074036,61.459755 
	C796.948486,58.186710 799.734070,54.790031 802.965088,51.900730 
	C804.079407,50.904282 806.399353,51.256027 808.900269,50.948437 
	C810.089600,50.271767 810.547241,49.638580 811.004883,49.005386 
	C812.070374,49.004642 813.135925,49.003902 814.601074,49.003513 
	C816.939636,49.668148 818.858337,50.824234 820.821289,50.905209 
	C826.892700,51.155659 832.981018,50.996586 839.541260,51.403034 
	C840.296082,55.657253 843.212219,54.978813 845.612732,54.982956 
	C863.774048,55.014301 881.935791,54.951065 900.096069,55.090031 
	C901.735474,55.102577 903.365601,56.331413 904.531372,56.996826 
	C894.040955,56.999451 884.019287,57.000641 873.529053,57.001419 
	C859.373718,57.000973 845.686829,57.000946 831.531372,57.000450 
	C823.929077,57.000225 816.787109,56.810844 809.667175,57.122879 
	C807.679749,57.209976 805.369690,58.358585 803.874207,59.741222 
	C798.342285,64.855774 792.951172,70.141022 787.767517,75.608963 
	C781.807922,81.895401 773.741516,86.367767 770.658875,95.172424 
z"/>
<path fill="#002982" opacity="1.000000" stroke="none" 
	d="
M810.686707,49.047966 
	C810.547241,49.638580 810.089600,50.271767 809.313110,50.945820 
	C809.452393,50.354633 809.910461,49.722584 810.686707,49.047966 
z"/>
<path fill="#011773" opacity="1.000000" stroke="none" 
	d="
M905.000000,56.995388 
	C903.365601,56.331413 901.735474,55.102577 900.096069,55.090031 
	C881.935791,54.951065 863.774048,55.014301 845.612732,54.982956 
	C843.212219,54.978813 840.296082,55.657253 840.009888,51.404236 
	C840.000000,50.998932 840.499268,50.995899 841.186646,50.997292 
	C843.917053,50.995354 845.960083,50.992027 848.003113,50.988701 
	C850.370911,50.989708 852.738708,50.990719 855.549805,50.988159 
	C874.347717,50.987221 892.702332,50.989845 911.954712,50.994423 
	C914.570496,50.994587 916.288513,50.992805 918.006592,50.991020 
	C921.033875,50.992371 924.061096,50.993725 927.541748,50.993660 
	C929.741028,50.722820 931.484436,50.262474 933.233643,50.238724 
	C934.916748,50.215878 936.605957,50.644661 938.501526,51.236931 
	C936.496765,53.412983 934.283020,55.227116 931.593140,57.025799 
	C922.411377,57.005356 913.705688,57.000366 905.000000,56.995388 
z"/>
<path fill="#002173" opacity="1.000000" stroke="none" 
	d="
M917.925049,50.628395 
	C916.288513,50.992805 914.570496,50.994587 912.422974,50.993057 
	C913.563171,47.675388 915.654480,48.575642 917.925049,50.628395 
z"/>
<path fill="#0430AF" opacity="1.000000" stroke="none" 
	d="
M932.069275,57.041256 
	C934.283020,55.227116 936.496765,53.412983 938.846436,51.295483 
	C941.397827,48.999146 943.813293,47.006180 947.051453,44.990463 
	C956.941711,44.943817 966.009277,44.919922 975.076843,44.896027 
	C978.091064,44.900272 981.105225,44.904522 984.570801,44.847275 
	C997.706116,44.839973 1010.390015,44.894176 1023.465820,45.312004 
	C1021.895325,46.767269 1019.932922,47.858906 1017.641235,48.961128 
	C1016.871887,48.964062 1016.431946,48.956409 1015.687500,48.667801 
	C1003.808105,48.286259 992.233398,48.172165 980.658325,48.126789 
	C980.104675,48.124622 979.548645,48.729485 978.526611,49.049728 
	C972.731628,49.055798 967.403687,49.063801 961.604248,49.041725 
	C957.705017,49.008118 954.183594,48.526329 950.882996,49.172516 
	C948.753296,49.589462 946.947510,51.660328 944.757874,53.217091 
	C944.324158,54.292267 944.129211,55.140335 943.696899,55.978813 
	C943.459534,55.969223 942.984680,55.985310 942.668945,56.032051 
	C941.895508,56.711315 941.437683,57.343842 940.824951,58.274014 
	C936.792908,61.733898 932.915894,64.896141 929.016968,67.709229 
	C928.335815,66.789101 927.303284,65.612335 927.080566,65.749619 
	C925.984741,66.424973 925.122314,67.469711 924.143860,68.346542 
	C919.431274,72.569893 914.707825,76.781136 910.026306,80.653183 
	C916.092834,74.181007 922.149963,68.079987 928.129333,61.903656 
	C929.574646,60.410667 930.762817,58.668724 932.069275,57.041256 
z"/>
<path fill="#032495" opacity="1.000000" stroke="none" 
	d="
M974.948059,44.518852 
	C966.009277,44.919922 956.941711,44.943817 947.398315,44.932850 
	C947.760620,44.276756 948.590942,43.128803 949.438171,43.116261 
	C957.373230,42.998772 965.311462,43.055923 973.247925,43.141144 
	C973.775208,43.146805 974.295654,43.793278 974.948059,44.518852 
z"/>
<path fill="#011E6F" opacity="1.000000" stroke="none" 
	d="
M100.907616,103.351196 
	C102.184769,103.644234 103.484383,103.863937 104.734055,104.246681 
	C106.853630,104.895866 108.941711,105.647903 111.176888,105.361458 
	C109.221802,103.389023 106.428780,101.722610 105.563126,99.352318 
	C104.799889,97.262466 106.188919,94.386597 106.646606,91.748032 
	C103.555000,90.905930 99.540169,90.204216 95.866112,88.680710 
	C93.194649,87.572945 91.826820,86.021858 95.095947,82.852066 
	C98.466270,79.584152 100.412239,74.847244 102.990555,70.376541 
	C102.999718,70.000015 103.007103,69.995384 103.271118,70.109818 
	C104.058487,70.459480 104.581841,70.694710 105.405533,71.099655 
	C108.056702,72.361000 110.079445,76.087814 113.394768,72.111404 
	C114.838341,71.042366 115.939354,70.045929 117.368645,69.082611 
	C118.136047,69.091522 118.575165,69.067329 119.315979,69.326111 
	C121.422592,69.739510 123.227379,69.980934 125.032486,69.983398 
	C146.593872,70.012886 168.155411,69.980446 189.716614,70.051987 
	C191.819122,70.058968 193.919708,70.649788 195.952850,71.326889 
	C192.580292,74.664711 192.918900,78.621635 192.540970,82.606178 
	C189.401978,82.617775 186.706131,82.528473 183.878326,82.145309 
	C181.826355,82.045906 179.906326,82.240356 177.735916,82.560081 
	C177.317291,82.766220 177.149048,82.847092 176.540970,82.883240 
	C173.741714,82.872124 171.382309,82.905731 168.699631,82.883911 
	C167.947037,82.747894 167.517715,82.667305 166.953720,82.277176 
	C164.848145,82.159714 162.877258,82.351807 160.454041,82.627136 
	C155.031830,82.662949 150.061935,82.615517 144.974640,82.280975 
	C143.562302,82.021095 142.267319,82.048340 140.579315,82.076515 
	C139.140732,82.063126 138.095169,82.048798 136.664108,81.962891 
	C133.851089,81.929268 131.423584,81.967232 128.640472,81.954483 
	C125.138794,79.015907 121.759041,76.818230 116.811501,78.131989 
	C115.641464,79.432640 114.845634,80.747368 114.028687,82.483353 
	C114.063950,84.864334 114.120331,86.824059 114.151917,89.152107 
	C115.168610,90.273628 116.210083,91.026825 117.663719,91.813782 
	C121.545502,92.452271 124.655243,91.931633 127.421448,89.001923 
	C130.991486,89.828857 134.132782,90.649925 138.253448,91.726982 
	C138.456482,91.308006 139.245071,89.680695 140.363068,88.027290 
	C141.129929,88.045113 141.567368,88.089035 142.314423,88.410843 
	C149.774323,88.829430 156.924622,88.970131 164.053741,89.538376 
	C163.971863,98.426712 166.174622,100.942612 175.346451,101.904892 
	C180.046402,102.397995 182.488663,104.706909 182.824738,109.379883 
	C181.674011,109.365013 180.832230,109.193565 179.907379,108.637535 
	C173.952301,107.547653 168.088089,106.744545 162.197296,106.275009 
	C161.544968,106.223007 160.740845,108.075439 159.568817,109.067268 
	C142.148743,109.303459 125.166969,109.517021 108.064270,109.427795 
	C106.590073,108.477448 105.236794,107.829903 103.713470,106.924988 
	C102.664833,105.562149 101.786224,104.456673 100.907616,103.351196 
z"/>
<path fill="#011C6D" opacity="1.000000" stroke="none" 
	d="
M144.992447,38.026714 
	C146.789200,38.684677 148.539230,39.646908 150.393295,39.931587 
	C153.419037,40.396168 156.512619,40.418846 159.577560,40.627972 
	C159.560196,41.419128 159.542831,42.210281 159.525452,43.001438 
	C156.895309,43.001438 154.244568,42.805420 151.643097,43.078648 
	C150.198456,43.230377 148.836060,44.165291 147.436600,44.747215 
	C148.829514,45.498596 150.178818,46.354286 151.631958,46.961533 
	C152.479401,47.315662 153.492981,47.272240 155.183365,47.516109 
	C153.346344,50.751114 151.768112,53.530396 149.907333,56.807240 
	C152.929535,57.636246 156.508347,58.617928 161.112595,59.880894 
	C158.232819,61.787228 156.691254,62.807709 154.603470,64.215942 
	C142.427277,64.230217 130.797272,63.856728 118.894226,63.234718 
	C117.444794,62.305710 116.268425,61.625217 114.915024,60.771004 
	C114.474510,60.395172 114.227623,60.174503 113.994591,59.535149 
	C113.593613,54.396458 112.016495,50.148277 108.028442,47.344360 
	C106.624023,46.356941 104.162834,45.377678 102.882530,45.951874 
	C94.728188,49.608963 93.652435,58.462978 100.138138,64.606773 
	C101.538963,65.933739 102.073524,68.175171 103.007103,69.995384 
	C103.007103,69.995384 102.999718,70.000015 102.994217,70.003494 
	C93.860291,67.974068 89.204483,61.308472 91.198418,52.560234 
	C91.575508,50.905769 93.382973,49.498283 94.711456,48.155987 
	C97.569252,45.268463 102.716057,43.312950 98.158897,37.947220 
	C101.378105,37.004929 104.228264,36.170658 107.179527,35.306797 
	C104.262924,32.808533 101.727509,31.337299 100.293900,29.145420 
	C99.437393,27.835890 100.307610,25.396992 100.401642,23.465755 
	C102.201393,24.187653 104.328766,24.536158 105.703545,25.742401 
	C106.938736,26.826183 107.347275,28.852112 108.120453,30.462450 
	C108.986824,29.803331 109.853188,29.144215 110.719551,28.485098 
	C109.479698,24.933794 108.239838,21.382492 107.344482,17.413315 
	C108.148582,16.981253 108.608185,16.967068 109.067780,16.952883 
	C109.067780,16.952883 109.525490,16.989180 109.761734,17.381516 
	C111.014893,18.838099 112.031799,19.902346 113.048706,20.966597 
	C115.683464,23.391998 118.318230,25.817396 121.024216,28.932919 
	C126.156631,37.667915 128.987961,39.172619 135.002167,37.013763 
	C136.715347,37.012199 138.428528,37.010635 140.806335,37.278885 
	C142.644806,37.708038 143.818619,37.867374 144.992447,38.026714 
z"/>
<path fill="#0D65C3" opacity="1.000000" stroke="none" 
	d="
M100.618484,103.247894 
	C101.786224,104.456673 102.664833,105.562149 103.789993,107.266739 
	C105.419434,108.487434 106.802315,109.109016 108.185188,109.730591 
	C125.166969,109.517021 142.148743,109.303459 160.009521,109.094193 
	C167.255844,109.073029 173.623138,109.047569 179.990448,109.022110 
	C180.832230,109.193565 181.674011,109.365013 183.200363,109.554207 
	C185.246765,109.645660 186.608582,109.719360 188.179138,110.097519 
	C189.924576,110.241920 191.461273,110.081863 193.307373,109.896423 
	C194.766953,110.094261 195.917130,110.317474 197.158981,110.849060 
	C197.811066,110.942566 198.371506,110.727692 199.144135,110.332169 
	C199.900574,110.083885 200.444839,110.016258 200.993164,110.348053 
	C201.010635,111.809769 201.024017,112.872063 200.703339,114.002808 
	C199.916412,114.097824 199.463547,114.124374 198.755585,113.835770 
	C195.666931,113.723106 192.833359,113.925598 189.678162,114.106339 
	C188.926758,114.085617 188.496964,114.086655 187.674286,114.044540 
	C186.492981,114.039101 185.704544,114.076813 184.455017,114.103683 
	C179.021881,114.096909 174.049820,114.100975 168.626099,114.055481 
	C165.758255,114.037048 163.342056,114.068169 160.462875,114.088852 
	C154.002090,114.361389 148.004288,114.644356 141.540924,114.903336 
	C129.069321,114.875481 117.063301,114.871635 104.880371,114.630173 
	C104.133286,114.315689 103.563118,114.238808 102.709549,113.976540 
	C96.918396,108.212502 91.410637,102.633858 85.891876,96.730713 
	C85.248253,95.944145 84.615631,95.482063 83.849014,94.763107 
	C83.134758,94.012474 82.554489,93.518715 81.852661,92.718002 
	C79.817398,90.610352 77.903709,88.809647 75.842331,86.739517 
	C74.864403,85.912811 74.034172,85.355522 73.125046,84.488144 
	C72.022263,83.127480 70.998367,82.076927 69.833893,80.773590 
	C69.119156,80.021614 68.544991,79.522430 67.945015,78.683311 
	C66.941742,77.566162 65.964272,76.788948 64.986809,76.011726 
	C64.197105,75.410980 63.407398,74.810242 62.345772,74.092026 
	C61.235462,72.760597 60.397076,71.546623 59.274807,70.171867 
	C55.422260,66.188629 51.853596,62.366184 48.308983,57.872616 
	C48.527782,54.487770 48.722534,51.774052 48.985188,49.004623 
	C49.053093,48.948917 49.187965,48.836369 49.478596,49.069725 
	C51.947746,52.278168 50.083332,57.494801 55.243046,59.192150 
	C56.622814,60.314034 57.850399,61.145142 59.077988,61.976246 
	C65.157028,68.279305 71.236076,74.582359 77.690598,81.266159 
	C78.701668,82.111778 79.337250,82.576653 79.972839,83.041527 
	C80.771385,83.995308 81.569931,84.949089 82.799683,86.253311 
	C84.613388,88.023888 85.995880,89.444023 87.378380,90.864151 
	C87.365044,91.187813 87.479462,91.457199 88.011055,92.112572 
	C90.556412,94.668793 92.812355,96.784752 95.245102,99.171814 
	C96.620338,100.279396 97.818756,101.115860 99.017181,101.952332 
	C99.454575,102.349754 99.891960,102.747177 100.618484,103.247894 
z"/>
<path fill="#011560" opacity="1.000000" stroke="none" 
	d="
M59.305897,61.971985 
	C57.850399,61.145142 56.622814,60.314034 55.229919,58.807610 
	C54.355450,55.429436 53.646290,52.726582 53.264420,49.882637 
	C54.727688,49.494110 55.863667,49.246674 56.999641,48.999237 
	C58.992538,52.803173 61.515854,56.476292 59.991951,61.539978 
	C59.988407,61.933281 59.533806,61.967728 59.305897,61.971985 
z"/>
<path fill="#011E6F" opacity="1.000000" stroke="none" 
	d="
M87.621857,90.707542 
	C85.995880,89.444023 84.613388,88.023888 83.117287,86.287964 
	C83.751930,85.594788 84.500175,85.217400 85.248421,84.840012 
	C86.156418,86.727295 90.302574,87.130760 87.621857,90.707542 
z"/>
<path fill="#011E6F" opacity="1.000000" stroke="none" 
	d="
M99.014816,101.563347 
	C97.818756,101.115860 96.620338,100.279396 95.230377,98.832741 
	C94.687096,97.151451 94.335358,96.080353 93.983620,95.009254 
	C98.298012,94.912399 98.753914,97.962891 99.014816,101.563347 
z"/>
<path fill="#02378D" opacity="1.000000" stroke="none" 
	d="
M93.681328,94.854156 
	C94.335358,96.080353 94.687096,97.151451 95.053558,98.561630 
	C92.812355,96.784752 90.556412,94.668793 88.201965,92.233681 
	C89.861984,92.842697 91.620514,93.770874 93.681328,94.854156 
z"/>
<path fill="#02257D" opacity="1.000000" stroke="none" 
	d="
M108.912262,16.659328 
	C108.608185,16.967068 108.148582,16.981253 107.344650,16.997280 
	C105.655373,14.951747 104.310440,12.904371 102.951569,10.484882 
	C104.877335,12.197104 106.817039,14.281440 108.912262,16.659328 
z"/>
<path fill="#011560" opacity="1.000000" stroke="none" 
	d="
M62.382599,61.986366 
	C62.746582,60.672615 63.491787,59.347977 64.618195,58.011436 
	C64.254204,59.324852 63.509014,60.650162 62.382599,61.986366 
z"/>
<path fill="#011E6F" opacity="1.000000" stroke="none" 
	d="
M80.094681,82.763588 
	C79.337250,82.576653 78.701668,82.111778 78.021355,81.331116 
	C78.831352,80.915344 79.686073,80.815353 80.540794,80.715363 
	C80.432701,81.305458 80.324615,81.895554 80.094681,82.763588 
z"/>
<path fill="#032495" opacity="1.000000" stroke="none" 
	d="
M1017.970581,48.950539 
	C1019.932922,47.858906 1021.895325,46.767269 1023.935181,45.305199 
	C1026.718872,44.618893 1029.425171,44.303017 1032.570801,43.968475 
	C1046.682617,43.647736 1060.355103,43.345661 1074.557129,43.374046 
	C1076.030518,44.163315 1076.974121,44.622120 1077.931641,45.493942 
	C1078.247681,49.292881 1075.907471,49.014927 1073.697754,49.018791 
	C1061.130981,49.040749 1048.564331,49.087448 1035.530762,49.090111 
	C1029.366089,49.019836 1023.668335,48.985188 1017.970581,48.950539 
z"/>
<path fill="#011965" opacity="1.000000" stroke="none" 
	d="
M1032.614868,33.075340 
	C1035.543945,33.356380 1038.990723,31.767691 1040.212158,37.194530 
	C1036.443970,36.714298 1033.436646,36.331043 1029.212280,35.792671 
	C1030.912720,34.280254 1031.562744,33.702106 1032.614868,33.075340 
z"/>
<path fill="#05277B" opacity="1.000000" stroke="none" 
	d="
M333.018738,597.017456 
	C331.043945,597.017456 329.069122,597.017456 327.094330,597.017456 
	C332.013489,598.375061 331.602112,602.046997 330.588837,605.393616 
	C329.646729,608.505188 328.945221,612.333313 323.602722,610.559143 
	C321.159119,609.747620 318.098938,610.963440 315.317688,610.970520 
	C295.189728,611.021484 275.061584,610.998474 254.464264,610.996460 
	C249.306763,607.371521 248.191696,602.013550 246.835449,596.698242 
	C246.577652,595.687988 245.121826,594.900635 244.096390,594.186096 
	C241.930740,592.676941 239.684189,591.283936 236.995361,589.533813 
	C235.804962,590.352966 233.922318,591.648438 232.039673,592.943909 
	C232.597031,588.863159 231.657806,584.949463 236.828995,583.437561 
	C238.715851,582.885925 240.228394,577.177795 238.536926,573.065674 
	C243.048660,572.472473 247.127518,572.218811 251.206818,571.972290 
	C256.751343,571.637146 262.296204,571.307373 267.949677,571.397156 
	C268.514313,575.100464 268.586548,578.492371 269.493530,581.643982 
	C273.124512,594.260742 286.114166,598.605774 299.526001,591.674133 
	C301.833710,590.481384 306.151276,589.941040 304.158508,585.597656 
	C302.050659,581.003479 299.298737,584.411011 296.817657,585.483337 
	C296.361115,585.680603 295.917755,585.908142 295.467316,586.119568 
	C285.186890,590.946960 277.536926,586.288147 277.077301,574.888672 
	C277.024139,573.570679 277.212402,572.242920 277.679382,571.019592 
	C279.514404,571.407898 280.957794,571.938660 282.403473,571.944946 
	C291.363251,571.984070 300.323547,571.905396 309.409149,572.254700 
	C309.596375,573.637329 309.637207,574.632935 309.723083,575.624573 
	C310.991730,590.272339 320.477570,597.174316 334.740479,593.846069 
	C335.378845,593.697083 336.046967,593.675842 336.701233,593.595154 
	C336.701233,593.595154 336.864410,593.844604 336.883057,594.293152 
	C335.607391,595.500305 334.313080,596.258850 333.018738,597.017456 
z"/>
<path fill="#E8EFF4" opacity="1.000000" stroke="none" 
	d="
M236.773422,571.610779 
	C232.427734,582.376831 228.104553,593.152100 223.727982,603.905579 
	C221.060822,610.458923 215.171875,613.598022 208.589661,612.072632 
	C204.268890,611.071289 201.466263,607.497070 202.732864,603.769409 
	C203.331070,602.008911 205.242294,599.756287 206.830383,599.514893 
	C208.692078,599.231934 210.998566,600.660156 212.814636,601.814270 
	C213.699249,602.376465 213.907684,604.002502 214.468323,605.249634 
	C219.507996,600.346191 220.807388,594.887268 218.514160,589.057983 
	C217.628372,586.806274 216.753128,584.550415 215.634033,581.646423 
	C214.720871,579.531494 214.046753,578.066528 213.352661,576.234253 
	C211.973190,572.295776 210.552567,568.746460 209.270523,565.147705 
	C207.837021,561.123657 207.008743,556.571533 200.732544,557.751343 
	C200.908493,555.490723 201.030411,553.924377 201.190430,551.868347 
	C207.403656,551.868347 213.197845,551.775146 218.983017,551.961670 
	C219.928574,551.992188 221.592651,553.255493 221.593933,553.951538 
	C221.596268,555.233154 220.790100,557.495056 220.006821,557.638916 
	C215.241608,558.514282 216.791519,561.238525 217.829971,563.901611 
	C218.609543,565.900818 219.433395,567.882751 220.162445,570.170349 
	C220.519699,571.355042 220.952026,572.241821 221.412964,573.533569 
	C222.343582,576.897217 223.245605,579.855774 224.147629,582.814331 
	C224.570724,582.870056 224.993835,582.925720 225.416931,582.981384 
	C226.850342,578.683105 228.283768,574.384888 229.907578,569.873901 
	C230.365616,568.765503 230.633270,567.869751 231.117920,566.675903 
	C232.301392,563.789062 233.267868,561.200256 234.345749,558.313110 
	C232.753510,558.164246 231.545135,558.399109 230.854767,557.907166 
	C229.753220,557.122131 228.424133,555.922974 228.283966,554.766541 
	C228.181610,553.921814 229.794128,552.080750 230.714340,552.031494 
	C235.795059,551.759460 240.897934,551.901428 247.233765,551.901428 
	C246.167191,554.516663 245.737778,557.410400 244.786148,557.593750 
	C239.907562,558.533691 239.873856,562.293518 238.910583,565.977905 
	C238.018997,567.975891 237.396210,569.793335 236.773422,571.610779 
z"/>
<path fill="#E8EFF4" opacity="1.000000" stroke="none" 
	d="
M200.975800,594.279785 
	C199.088120,594.454712 197.478775,594.472534 195.113953,594.505798 
	C191.991409,593.756714 189.624359,592.992126 186.639389,592.028015 
	C177.113068,596.362671 169.931839,595.522156 163.374710,588.480225 
	C159.944916,578.179810 164.807938,570.507935 175.247391,569.430359 
	C178.361084,569.108948 181.527512,569.239136 184.668930,569.265015 
	C187.107224,569.285095 188.170837,568.487793 187.058350,565.631714 
	C186.791672,563.822021 186.438873,562.390442 186.006195,560.602051 
	C182.847000,558.872925 179.767639,557.500610 176.688293,556.128296 
	C176.784882,556.801697 176.881470,557.475037 176.978058,558.148376 
	C175.299210,560.497803 174.071991,563.816589 171.793167,564.878174 
	C170.087311,565.672913 167.026520,563.559326 164.581497,562.345764 
	C165.608871,559.056458 166.623764,556.162170 167.757080,553.283386 
	C167.875504,553.298950 167.989807,553.089172 168.339294,553.050903 
	C168.943924,552.644470 169.199066,552.276245 169.625397,551.919189 
	C170.138321,551.910645 170.480072,551.890991 171.157684,551.953247 
	C173.336334,551.594360 175.179108,551.153503 177.440002,550.782104 
	C184.636841,550.607666 191.154312,550.965027 194.786118,558.574219 
	C195.428421,568.413513 195.983444,577.845276 196.566818,587.758850 
	C198.966843,587.758850 201.404388,587.758850 203.920242,588.174438 
	C204.072586,589.432617 204.146591,590.275208 204.220596,591.117737 
	C203.231781,592.119385 202.242966,593.121094 200.975800,594.279785 
M187.497253,581.799744 
	C187.497253,579.865601 187.497253,577.931396 187.497253,575.914917 
	C183.180145,575.914917 179.256256,575.299805 175.688812,576.161011 
	C173.754669,576.627991 171.269974,579.541992 171.155579,581.477539 
	C171.043198,583.378845 173.190353,586.325562 175.100677,587.244324 
	C179.058807,589.148193 184.651657,586.625732 187.497253,581.799744 
z"/>
<path fill="#0A4DBA" opacity="1.000000" stroke="none" 
	d="
M237.021133,571.772217 
	C237.396210,569.793335 238.018997,567.975891 239.362320,566.059814 
	C240.386307,565.983643 240.689774,566.006104 241.462708,566.033813 
	C250.819000,566.370544 259.705811,566.702026 268.668396,567.367554 
	C268.443115,568.792786 268.142029,569.884033 267.840942,570.975281 
	C262.296204,571.307373 256.751343,571.637146 251.206818,571.972290 
	C247.127518,572.218811 243.048660,572.472473 238.574997,572.696594 
	C237.876541,572.424744 237.572693,572.179138 237.021133,571.772217 
z"/>
<path fill="#1546AD" opacity="1.000000" stroke="none" 
	d="
M204.578690,591.096191 
	C204.146591,590.275208 204.072586,589.432617 203.954163,587.848877 
	C204.577927,586.378906 205.246094,585.650085 205.943863,585.275391 
	C206.467697,586.284180 206.961899,586.938904 207.456085,587.593689 
	C209.238327,585.753540 211.057022,583.944214 212.707214,581.992432 
	C212.800568,581.882019 211.510773,580.602112 210.952179,579.526184 
	C211.817291,578.324402 212.594955,577.463013 213.372635,576.601624 
	C214.046753,578.066528 214.720871,579.531494 215.416092,581.344116 
	C211.937057,584.819519 208.436920,587.947083 204.578690,591.096191 
z"/>
<path fill="#012276" opacity="1.000000" stroke="none" 
	d="
M231.190308,599.111145 
	C232.219269,599.224304 233.860474,599.745911 233.824295,600.092224 
	C233.665741,601.609741 233.142624,603.138489 232.442612,604.504822 
	C232.315430,604.753052 230.013977,604.376526 229.968719,604.090393 
	C229.798187,603.012085 230.093353,601.841431 230.322617,600.727783 
	C230.417053,600.268982 230.786270,599.866821 231.190308,599.111145 
z"/>
<path fill="#01257D" opacity="1.000000" stroke="none" 
	d="
M847.917480,50.617561 
	C845.960083,50.992027 843.917053,50.995354 841.436279,50.995380 
	C842.947510,47.730122 845.334595,48.483494 847.917480,50.617561 
z"/>
<path fill="#0858C9" opacity="1.000000" stroke="none" 
	d="
M284.467560,617.077759 
	C283.999969,615.981445 283.994476,614.912048 283.983643,613.444031 
	C305.242340,613.013733 326.509247,612.774170 347.768707,613.070801 
	C354.579163,613.165833 359.857727,611.541931 364.433044,606.258301 
	C367.938599,602.210022 372.468536,599.048706 377.117798,595.236816 
	C378.784332,595.571350 379.875885,596.162048 380.967438,596.752747 
	C380.931427,596.963135 380.895447,597.173462 380.679962,597.693115 
	C380.500427,598.002319 380.242432,597.992676 380.242432,597.992676 
	C379.186249,598.760864 378.130035,599.528992 376.667542,600.678345 
	C375.499695,602.029236 374.738098,602.999084 373.976562,603.968933 
	C373.401154,604.469177 372.825775,604.969482 371.776855,605.764038 
	C370.524292,607.022095 369.745178,607.985840 368.966064,608.949524 
	C368.966034,608.949524 368.993958,608.991638 368.661804,609.030701 
	C367.872681,609.692505 367.415710,610.315186 366.958740,610.937927 
	C365.721680,612.081604 364.484619,613.225220 362.742371,614.673096 
	C361.155701,615.685669 360.074249,616.394043 358.992798,617.102417 
	C350.667786,617.094604 342.342773,617.086792 333.078979,617.050903 
	C327.056305,617.052246 321.972473,617.081726 316.888611,617.111206 
	C316.152679,617.122925 315.416748,617.134644 314.322327,617.236572 
	C311.249481,617.539551 308.535156,617.752380 305.126526,617.707642 
	C301.936829,617.414062 299.441437,617.378052 296.946045,617.342102 
	C292.940582,617.262939 288.935089,617.183777 284.467560,617.077759 
z"/>
<path fill="#0241B4" opacity="1.000000" stroke="none" 
	d="
M316.983978,617.433228 
	C321.972473,617.081726 327.056305,617.052246 332.616211,617.057007 
	C333.382355,619.863037 333.792480,622.628662 333.934265,625.408020 
	C334.143372,629.507202 331.778473,632.116943 328.084442,632.816895 
	C323.666260,633.653931 317.322327,629.965820 317.295105,626.671021 
	C317.277710,624.565552 318.726990,622.447876 319.654816,619.972473 
	C318.934692,619.352539 318.007019,618.553894 316.983978,617.433228 
M322.139374,626.292175 
	C323.659454,627.122620 325.125305,628.340576 326.730225,628.565308 
	C327.264435,628.640137 328.726837,626.153625 328.693573,624.865723 
	C328.669098,623.916504 326.497498,622.053894 325.991272,622.268616 
	C324.504028,622.899536 323.370239,624.363586 322.139374,626.292175 
z"/>
<path fill="#1A336F" opacity="1.000000" stroke="none" 
	d="
M412.004761,587.517151 
	C411.425110,589.245728 410.845490,590.974243 410.250824,593.059082 
	C402.194855,598.052185 393.708313,596.136658 385.177551,594.662598 
	C385.072693,594.212891 385.104401,593.986328 385.560486,593.942993 
	C391.515778,593.900269 397.026794,594.089966 402.504639,593.748169 
	C403.651825,593.676575 404.676758,591.646179 405.758057,590.518433 
	C404.718567,589.590637 403.817291,588.323425 402.601624,587.828430 
	C401.331268,587.311096 399.758453,587.536560 398.248566,587.432556 
	C398.248566,582.395813 398.248566,577.755005 398.456482,573.390259 
	C398.784729,574.826721 398.905121,575.987061 399.025269,577.542542 
	C400.940491,580.290344 397.836884,585.075439 402.542206,585.879211 
	C405.608185,586.402954 408.829590,586.016785 411.987122,586.284668 
	C412.013306,586.859680 412.017273,587.188293 412.004761,587.517151 
z"/>
<path fill="#2159B0" opacity="1.000000" stroke="none" 
	d="
M381.298004,596.768188 
	C379.875885,596.162048 378.784332,595.571350 377.322052,594.967285 
	C376.951355,594.953796 376.902191,594.530151 377.263428,594.153381 
	C378.101929,591.841187 378.579193,589.905701 379.479492,587.975952 
	C381.315521,588.039429 382.728546,588.097229 384.120117,588.544189 
	C384.433929,590.617676 384.769165,592.302002 385.104431,593.986328 
	C385.104401,593.986328 385.072693,594.212891 385.017029,594.313965 
	C383.850403,595.204590 382.739471,595.994080 381.298004,596.768188 
z"/>
<path fill="#0241B4" opacity="1.000000" stroke="none" 
	d="
M359.206970,617.319275 
	C360.074249,616.394043 361.155701,615.685669 362.581024,614.963440 
	C361.756958,615.811768 360.589050,616.673950 359.206970,617.319275 
z"/>
<path fill="#0241B4" opacity="1.000000" stroke="none" 
	d="
M374.318176,603.941467 
	C374.738098,602.999084 375.499695,602.029236 376.589447,601.005005 
	C376.165039,601.938354 375.412415,602.926208 374.318176,603.941467 
z"/>
<path fill="#0241B4" opacity="1.000000" stroke="none" 
	d="
M369.307495,608.925354 
	C369.745178,607.985840 370.524292,607.022095 371.637695,606.017944 
	C371.197662,606.952026 370.423279,607.926575 369.307495,608.925354 
z"/>
<path fill="#0241B4" opacity="1.000000" stroke="none" 
	d="
M367.282745,610.913940 
	C367.415710,610.315186 367.872681,609.692505 368.639832,609.018311 
	C368.502258,609.607971 368.054504,610.248962 367.282745,610.913940 
z"/>
<path fill="#2159B0" opacity="1.000000" stroke="none" 
	d="
M380.253967,598.138733 
	C380.242432,597.992676 380.500427,598.002319 380.626465,598.008667 
	C380.752533,598.015076 380.603241,598.385620 380.603241,598.385620 
	C380.603241,598.385620 380.265472,598.284790 380.253967,598.138733 
z"/>
<path fill="#03429E" opacity="1.000000" stroke="none" 
	d="
M408.500153,622.047729 
	C415.858643,614.386658 423.567871,606.705811 431.616821,598.972473 
	C431.246826,600.167297 430.760132,601.631958 429.790833,602.626282 
	C424.125244,608.438110 418.383789,614.177917 412.570618,619.842102 
	C411.570404,620.816711 410.102692,621.311523 408.500153,622.047729 
z"/>
<path fill="#03429E" opacity="1.000000" stroke="none" 
	d="
M439.350037,593.568237 
	C438.997162,594.310486 438.533478,594.803589 438.013550,595.645874 
	C437.191071,595.992310 436.424835,595.989502 435.272858,595.963928 
	C434.877380,595.382263 434.867676,594.823364 434.790283,593.845825 
	C434.722595,593.427185 434.761749,593.231567 435.154968,593.148926 
	C436.659302,593.013306 437.770416,592.960327 438.881561,592.907349 
	C439.000824,593.044617 439.120056,593.181824 439.350037,593.568237 
z"/>
<path fill="#012E8A" opacity="1.000000" stroke="none" 
	d="
M419.039917,550.728638 
	C417.609253,551.028931 416.175415,551.598083 414.748566,551.580994 
	C409.700989,551.520813 408.066711,553.897766 409.672729,559.120117 
	C409.334351,560.098694 408.674408,560.565674 407.818695,560.786499 
	C405.087097,561.698792 402.551239,562.857239 400.015442,564.015747 
	C400.554718,561.709595 401.093994,559.403381 401.360046,556.689575 
	C401.726929,554.843384 402.367004,553.404846 403.007080,551.966248 
	C402.698181,551.596008 402.389282,551.225769 402.080383,550.855530 
	C401.048309,551.572815 400.016205,552.290039 398.984131,553.007324 
	C397.839661,552.133789 396.762512,551.149292 395.538544,550.406860 
	C391.300415,547.835999 387.253754,545.096313 381.961273,548.700073 
	C379.797302,550.173584 376.652405,550.206604 373.705811,550.958191 
	C373.252838,549.330627 372.869110,547.951904 372.187592,545.503418 
	C365.894836,546.797852 359.596802,548.093323 352.538300,549.545288 
	C352.936432,548.580627 353.542328,547.112488 354.108398,545.740845 
	C349.629913,545.022034 348.769012,545.960205 347.252777,554.470398 
	C344.908844,553.076721 342.909271,551.887817 340.681183,550.562988 
	C344.120758,546.704346 347.133972,543.675110 349.693970,540.301880 
	C350.948242,538.649170 352.673309,536.101318 348.499390,534.707642 
	C353.526642,528.936951 358.786163,523.481689 364.407227,518.015869 
	C366.158783,516.641602 367.548798,515.277893 368.954895,513.613953 
	C368.998932,512.913879 369.026947,512.514038 369.367065,512.075073 
	C370.098755,512.009277 370.518311,511.982635 371.295776,511.966278 
	C372.435242,511.321381 373.216766,510.666168 374.332520,510.007629 
	C375.112396,510.000824 375.558014,509.997375 376.469391,509.996155 
	C381.619629,509.996063 386.304108,509.993744 391.205444,510.103455 
	C391.694000,510.412933 391.887726,510.669342 391.728363,511.266602 
	C389.316223,512.330933 385.583221,512.777039 385.334442,513.957458 
	C384.510864,517.865112 383.524506,522.816895 385.230499,525.924011 
	C387.727356,530.471375 392.091583,534.370483 396.474457,537.346130 
	C398.664001,538.832703 400.154083,539.954712 401.032104,542.392822 
	C402.413300,546.228149 407.771851,548.875671 410.549622,547.368042 
	C415.280823,544.800232 416.301636,545.204285 419.039917,550.728638 
z"/>
<path fill="#EAF2F7" opacity="1.000000" stroke="none" 
	d="
M385.560486,593.942993 
	C384.769165,592.302002 384.433929,590.617676 384.096069,588.540833 
	C384.093475,588.148315 384.132599,588.161499 384.523071,588.098267 
	C388.734924,587.807556 390.553589,585.961182 389.987671,581.988770 
	C389.757385,580.371948 389.824951,578.712708 389.821594,576.642456 
	C389.656036,572.406250 389.570251,568.584595 389.154846,564.799072 
	C388.743958,561.054810 386.753387,558.644531 382.670929,558.642456 
	C377.948090,558.640015 371.449951,562.568970 371.347992,565.871094 
	C371.128693,572.972107 371.280640,580.084595 371.280640,587.890869 
	C374.149292,587.890869 376.582550,587.890869 379.004822,587.921509 
	C378.993835,587.952209 379.056427,587.970276 379.056458,587.970276 
	C378.579193,589.905701 378.101929,591.841187 377.247528,593.940735 
	C370.888550,594.043457 364.898682,594.131165 358.931091,593.807678 
	C357.574890,593.734131 356.295898,592.236145 354.981384,591.393860 
	C356.141510,590.154114 357.238281,587.935974 358.473511,587.855957 
	C362.353699,587.604614 363.064880,585.690552 362.978455,582.326355 
	C362.790619,575.014343 362.896790,567.694946 362.864471,560.378601 
	C362.863098,560.069824 362.677765,559.761902 362.436432,559.015015 
	C359.897064,558.789856 357.178772,558.548828 354.607910,558.320862 
	C352.096130,553.090576 355.752258,552.226685 359.150360,551.297058 
	C365.044678,549.684387 369.427734,551.139771 370.816040,555.269470 
	C373.903259,554.257202 376.972229,553.167542 380.090363,552.243652 
	C390.704346,549.098694 398.414886,554.351624 398.037109,565.638245 
	C397.975983,567.464783 398.064606,569.296326 398.077332,571.854370 
	C398.129944,572.760132 398.189240,572.937134 398.248566,573.114136 
	C398.248566,577.755005 398.248566,582.395813 398.248566,587.432556 
	C399.758453,587.536560 401.331268,587.311096 402.601624,587.828430 
	C403.817291,588.323425 404.718567,589.590637 405.758057,590.518433 
	C404.676758,591.646179 403.651825,593.676575 402.504639,593.748169 
	C397.026794,594.089966 391.515778,593.900269 385.560486,593.942993 
z"/>
<path fill="#E8F0F4" opacity="1.000000" stroke="none" 
	d="
M336.928711,593.349365 
	C336.046967,593.675842 335.378845,593.697083 334.740479,593.846069 
	C320.477570,597.174316 310.991730,590.272339 309.723083,575.624573 
	C309.637207,574.632935 309.596375,573.637329 309.545105,571.905823 
	C309.700562,569.156677 309.845551,567.145386 310.216278,564.846191 
	C312.663361,557.716919 317.830994,553.962585 324.508453,551.463928 
	C325.224152,551.285767 325.611877,551.090271 326.391083,550.962891 
	C327.566010,550.908813 328.349487,550.786682 329.132965,550.664490 
	C340.509766,551.858948 345.820618,556.257874 348.030548,566.316956 
	C350.686859,578.407898 347.128754,587.172424 336.928711,593.349365 
M339.853607,575.065491 
	C339.266510,570.994507 339.092926,566.805664 337.976471,562.885437 
	C336.850708,558.932434 333.133240,556.861755 329.429688,557.411072 
	C326.339569,557.869446 322.498047,559.675598 320.900391,562.129211 
	C318.981628,565.076111 318.501587,569.331177 318.436127,573.038147 
	C318.288544,581.395142 322.063873,586.851257 327.716858,587.613037 
	C334.036804,588.464661 337.469299,585.151978 339.853607,575.065491 
z"/>
<path fill="#084EC4" opacity="1.000000" stroke="none" 
	d="
M329.344788,550.403625 
	C328.349487,550.786682 327.566010,550.908813 326.384583,550.600220 
	C327.829041,547.769775 329.433685,545.132507 331.553772,543.010315 
	C342.619965,531.933044 353.817108,520.986572 365.350891,509.997070 
	C366.840363,510.704437 367.947662,511.409332 369.054962,512.114197 
	C369.026947,512.514038 368.998932,512.913879 368.628174,513.673096 
	C366.872192,515.363770 365.458923,516.695068 364.045654,518.026367 
	C358.786163,523.481689 353.526642,528.936951 348.120361,534.693726 
	C346.812958,536.191467 345.652344,537.387695 343.944458,538.923035 
	C341.878326,541.149597 340.359467,543.036987 338.840607,544.924438 
	C335.745911,546.663879 332.651245,548.403320 329.344788,550.403625 
z"/>
<path fill="#084EC4" opacity="1.000000" stroke="none" 
	d="
M398.456482,573.390259 
	C398.189240,572.937134 398.129944,572.760132 398.141296,572.313477 
	C398.650177,571.157776 399.088470,570.271790 400.069305,569.066040 
	C403.079376,566.174988 405.546906,563.603821 408.014465,561.032593 
	C408.674408,560.565674 409.334351,560.098694 410.021606,559.302979 
	C412.025818,558.901489 414.002808,558.828796 416.423584,559.278320 
	C416.826111,561.110535 416.784851,562.420654 416.424744,563.843262 
	C412.047913,567.504456 408.058105,571.136169 403.893555,574.555115 
	C402.500427,575.698853 400.660950,576.298889 399.025482,577.147461 
	C398.905121,575.987061 398.784729,574.826721 398.456482,573.390259 
z"/>
<path fill="#0251BD" opacity="1.000000" stroke="none" 
	d="
M337.056854,602.893311 
	C337.034119,602.479980 337.011414,602.066589 336.991547,600.963745 
	C337.790833,595.884888 340.329102,595.203430 344.019348,596.974304 
	C344.123566,597.289429 344.304474,597.551697 344.762329,598.251221 
	C344.236481,603.760559 341.030518,604.057861 337.056854,602.893311 
z"/>
<path fill="#022D9A" opacity="1.000000" stroke="none" 
	d="
M344.000000,596.576904 
	C340.329102,595.203430 337.790833,595.884888 336.966766,600.654236 
	C335.720245,599.898682 334.501373,598.763245 333.150604,597.322632 
	C334.313080,596.258850 335.607391,595.500305 336.975372,594.410156 
	C339.401764,594.639709 342.649719,592.246887 344.000000,596.576904 
z"/>
<path fill="#022D9A" opacity="1.000000" stroke="none" 
	d="
M337.094910,603.264954 
	C341.030518,604.057861 344.236481,603.760559 344.970245,598.374268 
	C345.511993,599.254944 346.840668,601.073547 346.431274,601.643433 
	C345.205505,603.349548 343.487701,605.124512 341.588623,605.764343 
	C340.469391,606.141418 338.641907,604.416260 337.094910,603.264954 
z"/>
<path fill="#013295" opacity="1.000000" stroke="none" 
	d="
M407.818665,560.786499 
	C405.546906,563.603821 403.079376,566.174988 400.309631,568.886719 
	C400.008118,567.634460 400.008850,566.241699 400.012512,564.432251 
	C402.551239,562.857239 405.087097,561.698792 407.818665,560.786499 
z"/>
<path fill="#013290" opacity="1.000000" stroke="none" 
	d="
M398.988220,553.381348 
	C400.016205,552.290039 401.048309,551.572815 402.080383,550.855530 
	C402.389282,551.225769 402.698181,551.596008 403.007080,551.966248 
	C402.367004,553.404846 401.726929,554.843384 401.043396,556.640991 
	C400.330750,555.918457 399.661530,554.836914 398.988220,553.381348 
z"/>
<path fill="#012E8A" opacity="1.000000" stroke="none" 
	d="
M339.235046,544.945435 
	C340.359467,543.036987 341.878326,541.149597 343.701660,539.122192 
	C344.153900,542.152100 343.487823,544.726685 339.235046,544.945435 
z"/>
<path fill="#E3ECF3" opacity="1.000000" stroke="none" 
	d="
M781.926758,590.685730 
	C780.703918,593.536377 778.757568,595.780884 775.899292,594.558716 
	C774.273193,593.863464 773.067993,591.395630 772.373779,589.473083 
	C770.920105,585.447571 769.930054,581.254639 768.487305,576.507935 
	C766.529358,570.864319 764.833557,565.843201 763.137756,560.822083 
	C763.137756,560.822083 763.089050,560.932617 763.106201,560.507568 
	C760.888428,559.338257 758.653503,558.593994 756.343872,557.537476 
	C756.143250,556.816162 756.017334,556.407104 755.989136,555.601135 
	C757.068420,554.155640 758.009583,552.248169 759.038208,552.199768 
	C765.126343,551.913513 771.234802,552.058655 777.867249,552.058655 
	C777.867249,553.901978 777.867249,555.327393 777.664185,556.868286 
	C777.219604,557.378052 776.978088,557.772217 776.298340,558.155273 
	C774.898865,559.293640 773.291687,560.358887 773.128174,561.612488 
	C772.884766,563.477539 773.551758,565.502930 774.025574,567.411926 
	C775.245483,572.327271 776.572021,577.216187 778.127625,583.152039 
	C779.103088,580.674927 779.627930,579.504028 780.030640,578.292419 
	C782.056030,572.198608 784.049561,566.094116 786.136902,559.835571 
	C786.219543,559.677734 786.120544,559.335449 786.435608,559.171265 
	C787.253357,557.738037 787.756042,556.469116 788.203003,555.191162 
	C788.147156,555.182251 788.189575,555.287048 788.576416,555.286743 
	C791.835022,554.788513 793.554199,555.745300 793.971313,558.856445 
	C794.095337,559.781738 795.023071,560.599182 795.544189,561.839478 
	C797.945679,569.144348 800.385620,576.075806 802.825623,583.007263 
	C803.243164,582.961975 803.660767,582.916748 804.078369,582.871460 
	C806.016785,574.809387 807.955261,566.747253 810.071960,557.943604 
	C807.564819,557.943604 805.782349,557.943604 803.898499,557.508057 
	C802.966370,553.226257 804.306458,551.490479 808.430542,551.816040 
	C811.723511,552.075989 815.072632,551.659912 818.357971,551.963684 
	C819.611694,552.079529 821.636414,553.262573 821.737854,554.140930 
	C821.876221,555.338806 820.635193,556.802124 819.760864,557.985596 
	C818.516113,559.670349 816.427307,560.992126 815.815063,562.838867 
	C812.955872,571.463135 810.211182,580.146240 807.978821,588.948669 
	C806.838379,593.445557 804.829407,595.591248 800.167175,594.833618 
	C799.906799,594.365540 799.646423,593.897522 799.223206,592.842896 
	C798.086426,590.444336 797.112488,588.632385 796.138489,586.820435 
	C796.138489,586.820435 796.108765,586.921570 796.145630,586.587646 
	C795.857727,585.758423 795.532898,585.263123 795.208069,584.767822 
	C795.208069,584.767822 795.174622,584.886658 795.191833,584.522461 
	C794.873230,583.362122 794.537415,582.565979 794.201599,581.769897 
	C794.201599,581.769897 794.167786,581.891968 794.214172,581.529785 
	C793.886719,580.654846 793.512817,580.142090 793.138977,579.629395 
	C793.138977,579.629395 793.182617,579.276001 793.127563,578.777527 
	C792.776428,577.450012 792.480347,576.620911 792.184265,575.791870 
	C792.184265,575.791870 792.080994,575.990173 792.010742,575.546265 
	C791.365417,573.966919 790.790466,572.831482 789.624023,570.528015 
	C787.035828,577.423218 784.880371,583.165405 782.724976,588.907593 
	C782.453369,589.403564 782.181763,589.899475 781.926758,590.685730 
z"/>
<path fill="#142A6D" opacity="1.000000" stroke="none" 
	d="
M800.183044,595.208252 
	C804.829407,595.591248 806.838379,593.445557 807.978821,588.948669 
	C810.211182,580.146240 812.955872,571.463135 815.815063,562.838867 
	C816.427307,560.992126 818.516113,559.670349 819.760864,557.985596 
	C820.635193,556.802124 821.876221,555.338806 821.737854,554.140930 
	C821.636414,553.262573 819.611694,552.079529 818.357971,551.963684 
	C815.072632,551.659912 811.723511,552.075989 808.430542,551.816040 
	C804.306458,551.490479 802.966370,553.226257 803.647949,557.525879 
	C803.330872,557.977661 803.163025,557.976196 802.886841,557.720947 
	C802.509399,556.986267 802.240356,556.505310 801.981567,555.606079 
	C802.003235,553.797058 802.014587,552.406311 802.025940,551.015503 
	C804.081238,550.676086 806.136475,550.336670 809.047485,549.997375 
	C812.936035,549.989197 815.968750,549.980896 819.001526,549.972595 
	C821.400024,549.983948 824.083008,549.901672 823.903381,553.311157 
	C823.767090,555.897705 825.678345,559.432068 820.809265,560.390686 
	C819.350037,560.677917 817.675842,562.870850 817.171936,564.518555 
	C814.246216,574.084717 811.727844,583.775269 808.820129,593.347351 
	C808.437744,594.606079 806.616699,595.427917 805.221985,596.719849 
	C803.387573,596.519409 801.793274,596.051208 800.183044,595.208252 
z"/>
<path fill="#142A6D" opacity="1.000000" stroke="none" 
	d="
M796.095337,587.236267 
	C797.112488,588.632385 798.086426,590.444336 799.074097,592.613403 
	C798.239624,592.436646 797.391479,591.902771 796.287842,591.179077 
	C796.038940,589.876892 796.045532,588.764465 796.095337,587.236267 
z"/>
<path fill="#032A82" opacity="1.000000" stroke="none" 
	d="
M799.955078,600.583740 
	C799.722473,600.201599 799.724548,599.821777 799.754028,599.444092 
	C799.754822,599.433533 800.049133,599.445923 800.206543,599.447632 
	C800.202209,599.827209 800.197815,600.206787 799.955078,600.583740 
z"/>
<path fill="#002173" opacity="1.000000" stroke="none" 
	d="
M1077.917847,45.080917 
	C1076.974121,44.622120 1076.030518,44.163315 1075.023315,43.370872 
	C1075.715698,43.025383 1076.471436,43.013527 1077.604248,43.005402 
	C1077.981079,43.009129 1078.001099,43.497307 1077.988281,44.015903 
	C1077.956055,44.716637 1077.936890,44.898773 1077.917847,45.080917 
z"/>
<path fill="#012E8A" opacity="1.000000" stroke="none" 
	d="
M385.000397,30.990276 
	C389.690765,30.992899 394.381104,30.995522 399.536987,31.372501 
	C400.004150,32.498161 400.005737,33.249462 399.693298,34.141376 
	C394.686523,36.323586 392.947693,40.127167 392.990112,44.993221 
	C387.430542,45.085194 381.871216,45.203991 376.311310,45.263741 
	C363.409210,45.402405 350.506714,45.610050 337.604553,45.597198 
	C330.029419,45.589653 322.454651,45.212921 314.439850,45.000294 
	C317.003357,43.764034 320.006714,42.529732 322.599487,41.464169 
	C316.295624,35.585270 316.295624,35.585270 323.385376,31.682520 
	C322.926788,29.658531 322.476959,27.673237 322.027130,25.687941 
	C323.816437,26.218241 325.605774,26.748543 327.760925,27.387268 
	C326.839111,24.153055 325.929657,20.962097 325.017090,17.376854 
	C326.551117,17.775476 328.604767,18.179670 329.541412,19.424408 
	C335.601166,27.477295 343.010773,32.228008 353.585419,31.078205 
	C358.028687,30.595079 362.567719,30.992718 367.999329,30.992039 
	C374.290436,30.992838 379.645416,30.991558 385.000397,30.990276 
z"/>
<path fill="#012E8A" opacity="1.000000" stroke="none" 
	d="
M321.058594,12.342029 
	C321.904938,12.475667 322.807800,12.950897 323.852417,13.713877 
	C323.034515,13.562291 322.074829,13.122955 321.058594,12.342029 
z"/>
<path fill="#022590" opacity="1.000000" stroke="none" 
	d="
M134.802856,30.857571 
	C133.113480,30.625406 131.218445,30.097252 129.203491,29.250267 
	C130.921432,29.474819 132.759323,30.018198 134.802856,30.857571 
z"/>
<path fill="#01308F" opacity="1.000000" stroke="none" 
	d="
M385.995941,2.795160 
	C397.312408,2.343285 408.653442,2.239637 420.453461,2.136283 
	C422.933502,4.163764 424.954498,6.190952 427.090454,8.808750 
	C427.467407,9.601957 427.729370,9.804554 428.109680,10.218954 
	C428.430603,10.694244 428.686584,10.886825 429.099335,11.230122 
	C429.400391,11.728041 429.662109,11.914341 430.141510,12.289877 
	C431.186859,13.055091 432.078644,13.541071 432.970398,14.027051 
	C432.970398,14.027052 433.000000,14.000153 433.000122,14.314879 
	C433.329529,15.108651 433.658783,15.587697 433.950348,16.411476 
	C433.663544,18.443491 433.414398,20.130774 433.059631,22.533461 
	C431.303070,21.575590 429.828125,21.005157 428.632538,20.075453 
	C425.062744,17.299519 420.930420,17.989531 418.000000,21.999310 
	C417.505096,22.260641 417.010193,22.521973 415.791443,22.892307 
	C411.643097,23.001453 408.204163,22.821178 404.801331,23.092962 
	C403.490265,23.197678 402.261353,24.330183 400.994995,24.994411 
	C384.764984,24.995354 368.532410,25.152279 352.306793,24.885321 
	C348.351593,24.820244 344.419250,23.363453 340.238220,22.272686 
	C346.822571,22.332289 353.642761,22.733116 360.468628,22.973536 
	C364.451843,23.113832 368.560181,23.593670 372.402863,22.845484 
	C377.177795,21.915789 383.465790,23.905443 385.900421,16.989708 
	C386.247253,16.004454 388.736847,15.958513 389.665375,14.952379 
	C391.311096,13.169068 392.519867,10.982518 393.911469,8.964689 
	C392.407379,7.974996 390.863617,7.039580 389.411560,5.978634 
	C388.224457,5.111260 387.146698,4.094131 385.995941,2.795160 
z"/>
<path fill="#012C87" opacity="1.000000" stroke="none" 
	d="
M242.404388,628.008301 
	C245.469772,624.719971 248.900024,621.422546 252.685760,618.121704 
	C249.617279,621.411926 246.193268,624.705566 242.404388,628.008301 
z"/>
<path fill="#012C87" opacity="1.000000" stroke="none" 
	d="
M296.984283,617.710571 
	C299.441437,617.378052 301.936829,617.414062 304.715942,617.730469 
	C302.367889,621.224487 299.707794,621.125671 296.984283,617.710571 
z"/>
<path fill="#02378D" opacity="1.000000" stroke="none" 
	d="
M52.937130,50.023727 
	C53.646290,52.726582 54.355450,55.429436 55.077736,58.516830 
	C50.083332,57.494801 51.947746,52.278168 49.435112,48.753872 
	C49.115059,47.778229 49.129116,47.351795 49.143173,46.925365 
	C49.545845,46.956879 49.948517,46.988392 50.691723,47.354424 
	C51.667217,48.467205 52.302174,49.245464 52.937130,50.023727 
z"/>
<path fill="#011965" opacity="1.000000" stroke="none" 
	d="
M53.264420,49.882637 
	C52.302174,49.245464 51.667217,48.467205 50.993801,47.378143 
	C53.349663,45.431236 55.461201,45.194199 56.951439,48.634388 
	C55.863667,49.246674 54.727688,49.494110 53.264420,49.882637 
z"/>
<path fill="#0D65C3" opacity="1.000000" stroke="none" 
	d="
M49.026802,46.644444 
	C49.129116,47.351795 49.115059,47.778229 49.144485,48.520515 
	C49.187965,48.836369 49.053093,48.948917 48.637501,48.816574 
	C45.787872,48.423313 43.353840,48.162403 40.919804,47.901497 
	C42.747875,47.157127 44.575947,46.412762 46.681625,45.447510 
	C47.609634,45.605587 48.260033,45.984554 49.026802,46.644444 
z"/>
<path fill="#051F6B" opacity="1.000000" stroke="none" 
	d="
M163.483185,588.876160 
	C169.931839,595.522156 177.113068,596.362671 186.639389,592.028015 
	C189.624359,592.992126 191.991409,593.756714 194.735443,594.651123 
	C184.784470,604.991577 174.556503,615.307556 164.027557,625.306580 
	C162.325790,626.922668 159.296646,627.834717 156.862686,627.872009 
	C142.554138,628.091431 128.240128,627.954895 113.464325,627.864868 
	C114.357468,627.194641 115.676804,626.219543 117.078888,626.080933 
	C119.881378,625.803589 122.793739,626.332581 125.530197,625.824890 
	C126.832924,625.583313 128.792969,623.736511 128.802277,622.603271 
	C128.834579,618.666077 128.681274,614.568542 127.604797,610.817139 
	C125.842278,604.675049 123.300308,598.756592 121.090538,592.052002 
	C122.065964,590.243347 123.037941,589.126526 124.412590,588.006348 
	C126.615150,587.845093 128.415024,587.687317 130.219543,587.528015 
	C130.814514,588.534302 131.404831,589.542175 131.607162,589.887634 
	C133.417892,588.120422 135.007675,586.568909 136.349655,585.259216 
	C136.349655,577.487488 136.349655,569.587769 136.349655,561.687988 
	C135.849716,561.704651 135.349762,561.721375 134.849823,561.738037 
	C134.849823,568.683289 134.849823,575.628479 134.849823,583.244446 
	C132.693039,579.761292 131.189957,577.333923 129.216858,574.147400 
	C128.131851,577.016785 127.570229,578.502014 127.006927,579.559570 
	C126.347549,575.760376 125.689850,572.388855 125.374496,568.949341 
	C126.513168,567.533875 127.309502,566.186523 128.105835,564.839172 
	C127.673645,564.539673 127.241455,564.240173 126.809265,563.940674 
	C125.872543,564.627930 124.935822,565.315186 123.649017,565.850830 
	C122.376732,565.783203 121.454536,565.867188 120.532341,565.951172 
	C120.532341,565.951172 120.199677,565.842163 120.360306,565.345642 
	C120.312080,559.426147 115.087341,556.129822 109.868195,558.128296 
	C109.868195,558.128296 109.789223,558.006653 109.815292,557.655090 
	C109.231117,556.203186 108.620865,555.102783 108.368179,553.936890 
	C111.078346,552.382996 113.726227,551.102966 113.176590,547.565918 
	C113.602493,547.601501 114.028397,547.637146 114.731461,548.220947 
	C116.557411,550.242554 118.106209,551.715942 119.655014,553.189331 
	C119.975296,552.823425 120.295578,552.457520 120.615860,552.091614 
	C120.077866,550.768494 119.539879,549.445435 119.001892,548.122314 
	C119.717804,548.017212 120.433723,547.912109 121.693817,548.048462 
	C123.493683,547.193970 124.749382,546.097961 126.257385,545.215576 
	C126.571297,546.793213 126.632904,548.157288 126.701584,549.678223 
	C128.584030,548.016602 130.291397,546.509521 132.011475,545.385132 
	C133.152496,549.097961 134.933258,548.634644 136.237381,546.133911 
	C137.013779,544.645142 136.871262,542.635620 136.983322,540.850281 
	C137.231567,536.895569 142.129349,534.543640 145.756363,536.068481 
	C150.089752,537.890259 148.185654,542.161682 149.852661,545.193481 
	C148.598160,546.063416 147.470184,546.624390 146.261963,546.728149 
	C146.181702,543.863098 146.181702,541.455139 146.181702,538.812805 
	C141.311584,538.826538 139.554535,540.855225 139.377991,544.828979 
	C139.238266,547.973755 139.206467,551.674072 134.655685,551.580200 
	C130.163498,551.487610 129.533051,553.941345 129.839401,557.776672 
	C132.526276,557.943054 135.079483,558.101135 137.904663,558.276062 
	C137.904663,566.758850 137.867584,574.736328 137.913757,582.713196 
	C137.975723,593.416443 143.378220,597.198120 153.312286,593.271301 
	C154.952835,592.622864 156.071213,590.653442 157.430893,589.294434 
	C155.674316,588.686340 153.845886,587.416870 152.175156,587.598511 
	C147.774368,588.076721 145.997101,586.502197 146.120148,582.112549 
	C146.274216,576.617371 146.119110,571.114014 146.133667,565.614319 
	C146.153076,558.289307 146.168503,558.052856 153.663055,558.067200 
	C158.375275,558.076172 158.921021,555.965515 157.967026,552.111511 
	C155.770309,551.956665 153.794067,551.817383 151.928421,551.403809 
	C152.622269,550.710144 153.205505,550.290833 154.215027,549.905029 
	C156.378830,550.173767 159.203323,549.847534 159.665176,550.741699 
	C161.635498,554.556335 164.843033,552.988708 167.638641,553.267822 
	C166.623764,556.162170 165.608871,559.056458 164.263977,562.526611 
	C163.713440,566.526428 163.681396,569.975952 163.223892,573.368042 
	C162.671875,577.460876 161.564590,581.484924 161.140976,585.584595 
	C161.037033,586.590393 162.656204,587.774170 163.483185,588.876160 
z"/>
<path fill="#0332AA" opacity="1.000000" stroke="none" 
	d="
M392.988770,45.354225 
	C392.947693,40.127167 394.686523,36.323586 400.102234,34.152397 
	C402.926086,34.350002 405.017731,34.764107 407.129547,34.987370 
	C412.495850,35.554707 415.330994,40.237644 413.479156,45.370022 
	C413.047424,46.566517 413.118469,47.950470 412.997314,49.251812 
	C412.651550,52.964458 410.606934,54.903564 406.901947,54.981060 
	C403.603027,55.050064 400.301575,54.996059 397.001221,54.996342 
	C396.889862,54.682056 396.699463,54.426750 396.000122,53.856827 
	C394.047028,52.988064 392.523804,52.492897 391.000580,51.997726 
	C391.662842,49.903561 392.325104,47.809399 392.988770,45.354225 
M409.160675,49.367241 
	C409.581146,46.329517 411.038147,42.888302 410.066589,40.392441 
	C409.391144,38.657158 405.190857,37.059406 402.893158,37.430527 
	C396.921936,38.394985 397.861511,44.217571 396.355743,48.195229 
	C402.776062,53.668087 402.776062,53.668087 409.160675,49.367241 
z"/>
<path fill="#013295" opacity="1.000000" stroke="none" 
	d="
M390.769409,52.259895 
	C392.523804,52.492897 394.047028,52.988064 395.784210,53.747955 
	C392.190399,55.402580 388.497742,57.319572 384.666351,53.650261 
	C383.727875,52.751537 381.518921,53.057373 379.889496,53.022514 
	C371.125580,52.835018 362.360199,52.714764 353.470276,52.039574 
	C353.895966,51.338036 354.446747,51.168224 354.997528,50.998413 
	C361.686890,50.998867 368.376251,50.999317 375.971375,50.998238 
	C378.916931,50.997002 380.956726,50.997303 382.996552,50.997597 
	C385.510468,51.505753 388.024353,52.013908 390.769409,52.259895 
z"/>
<path fill="#0332AA" opacity="1.000000" stroke="none" 
	d="
M354.927124,50.637920 
	C354.446747,51.168224 353.895966,51.338036 353.173065,51.746162 
	C347.868103,51.847027 342.735291,51.709579 337.299988,51.286903 
	C337.094879,50.688526 337.161041,50.115540 337.294006,50.099598 
	C343.153503,49.397160 349.049835,46.220856 354.927124,50.637920 
z"/>
<path fill="#0332AA" opacity="1.000000" stroke="none" 
	d="
M382.912872,50.625629 
	C380.956726,50.997303 378.916931,50.997002 376.440094,50.997147 
	C377.949066,47.727287 380.334198,48.486416 382.912872,50.625629 
z"/>
<path fill="#0332AA" opacity="1.000000" stroke="none" 
	d="
M313.914612,50.626968 
	C311.958130,50.998940 309.917450,50.998795 307.439636,50.998459 
	C308.949158,47.726269 311.334900,48.486290 313.914612,50.626968 
z"/>
<path fill="#01379A" opacity="1.000000" stroke="none" 
	d="
M397.059631,55.347916 
	C400.301575,54.996059 403.603027,55.050064 406.901947,54.981060 
	C410.606934,54.903564 412.651550,52.964458 412.997314,49.251812 
	C413.118469,47.950470 413.047424,46.566517 413.479156,45.370022 
	C415.330994,40.237644 412.495850,35.554707 407.129547,34.987370 
	C405.017731,34.764107 402.926086,34.350002 400.416321,34.011780 
	C400.005737,33.249462 400.004150,32.498161 400.003357,31.371105 
	C405.941284,29.749050 411.643951,28.758566 417.540924,32.140488 
	C419.834290,33.455746 423.728180,32.926849 426.484680,32.010120 
	C427.549835,31.655880 428.177032,27.769051 427.796356,25.715448 
	C426.812866,20.409983 422.232452,22.403332 418.438721,21.999537 
	C420.930420,17.989531 425.062744,17.299519 428.632538,20.075453 
	C429.828125,21.005157 431.303070,21.575590 433.059631,22.533461 
	C433.414398,20.130774 433.663544,18.443491 434.214722,16.367891 
	C434.692719,15.949450 434.868683,15.919327 435.044617,15.889198 
	C457.409027,38.445751 479.768494,61.007183 502.148712,83.548027 
	C503.661194,85.071365 505.330048,86.439484 507.286835,87.996582 
	C498.114288,95.786354 488.581055,103.459351 478.456360,111.023895 
	C476.391815,108.960861 475.134521,106.787567 473.411102,105.086540 
	C460.688477,92.529221 447.887726,80.050774 435.061096,67.599510 
	C433.914398,66.486420 432.410034,65.741768 431.071808,64.825989 
	C429.567505,64.218773 428.072052,63.112827 426.557495,63.086021 
	C417.351379,62.923054 408.140900,63.005863 398.462555,62.998245 
	C399.270233,62.324875 400.490967,61.496056 401.837494,61.033325 
	C403.850739,60.341442 405.952515,59.907139 408.016632,59.363270 
	C407.945160,58.665344 407.873688,57.967422 407.802216,57.269497 
	C405.752502,57.269497 403.677002,57.467705 401.661407,57.205456 
	C400.107971,57.003338 398.629730,56.222828 397.059631,55.347916 
z"/>
<path fill="#01379A" opacity="1.000000" stroke="none" 
	d="
M384.901978,30.598671 
	C379.645416,30.991558 374.290436,30.992838 368.468018,30.988461 
	C368.188629,30.530025 368.385742,29.678366 368.563446,29.682409 
	C373.977997,29.805582 379.390625,30.012869 384.901978,30.598671 
z"/>
<path fill="#01379A" opacity="1.000000" stroke="none" 
	d="
M401.461578,24.996098 
	C402.261353,24.330183 403.490265,23.197678 404.801331,23.092962 
	C408.204163,22.821178 411.643097,23.001453 415.533630,23.002403 
	C414.734680,23.668392 413.507172,24.802319 412.197479,24.906847 
	C408.793640,25.178520 405.353699,24.997919 401.461578,24.996098 
z"/>
<path fill="#1173BF" opacity="1.000000" stroke="none" 
	d="
M432.956696,13.689726 
	C432.078644,13.541071 431.186859,13.055091 430.166351,12.017599 
	C430.065369,11.286012 430.093140,11.105935 430.120911,10.925857 
	C431.061615,11.734703 432.002289,12.543552 432.956696,13.689726 
z"/>
<path fill="#1173BF" opacity="1.000000" stroke="none" 
	d="
M434.987000,15.580162 
	C434.868683,15.919327 434.692719,15.949450 434.252411,16.023159 
	C433.658783,15.587697 433.329529,15.108651 432.986267,14.329409 
	C433.624664,14.443180 434.277008,14.857150 434.987000,15.580162 
z"/>
<path fill="#1173BF" opacity="1.000000" stroke="none" 
	d="
M428.995911,11.008500 
	C428.686584,10.886825 428.430603,10.694244 428.131165,9.952906 
	C428.057495,9.298673 428.080688,9.122291 428.103851,8.945911 
	C428.632111,9.134717 429.160400,9.323520 429.624634,9.869041 
	C429.284943,10.423584 429.096710,10.684497 428.995911,11.008500 
z"/>
<path fill="#0350AC" opacity="1.000000" stroke="none" 
	d="
M429.099335,11.230122 
	C429.096710,10.684497 429.284943,10.423584 429.777191,10.112051 
	C429.993774,9.998342 430.044281,10.464363 430.082581,10.695111 
	C430.093140,11.105935 430.065369,11.286012 430.012756,11.738365 
	C429.662109,11.914341 429.400391,11.728041 429.099335,11.230122 
z"/>
<path fill="#0350AC" opacity="1.000000" stroke="none" 
	d="
M427.843079,8.931116 
	C428.080688,9.122291 428.057495,9.298673 428.012848,9.741102 
	C427.729370,9.804554 427.467407,9.601957 427.132721,9.155792 
	C427.234161,8.913590 427.408264,8.914957 427.843079,8.931116 
z"/>
<path fill="#011B6D" opacity="1.000000" stroke="none" 
	d="
M1079.675293,369.055145 
	C1079.699951,370.071869 1079.724609,371.088562 1079.525391,372.384979 
	C1079.301392,372.664673 1078.903198,372.637665 1078.738037,372.334015 
	C1076.979370,371.027039 1075.394531,369.167603 1073.791016,369.151337 
	C1055.515503,368.966003 1037.237183,369.061920 1018.588013,369.070984 
	C1017.473022,369.056000 1016.729492,369.052673 1015.990723,368.789978 
	C1015.994202,368.357727 1015.992859,368.184814 1015.939331,367.599823 
	C1015.166382,360.742188 1014.445801,354.296600 1013.658325,347.252930 
	C1012.007629,347.436066 1009.499146,347.714325 1007.031921,347.643860 
	C1008.393250,345.649872 1009.516968,343.786530 1011.064697,342.394440 
	C1022.031738,332.530060 1030.677490,320.059662 1044.398438,312.997131 
	C1052.045776,320.123657 1059.278564,327.270294 1066.760986,334.144989 
	C1068.362061,335.616028 1070.869019,336.100861 1072.964355,337.421997 
	C1073.310059,339.208221 1073.650146,340.612396 1074.046021,342.349945 
	C1075.397949,343.454315 1076.694092,344.225342 1078.202393,344.989929 
	C1078.414673,344.983521 1078.838745,344.962616 1078.894287,345.431702 
	C1079.191528,353.618927 1079.433350,361.337036 1079.675293,369.055145 
z"/>
<path fill="#011B6C" opacity="1.000000" stroke="none" 
	d="
M1079.498779,186.982666 
	C1079.575806,187.750778 1079.652832,188.518890 1079.283203,189.806183 
	C1078.542358,192.533356 1078.248169,194.741364 1077.708984,196.751373 
	C1077.098633,195.852753 1076.733154,195.152115 1076.724976,195.136383 
	C1073.830444,195.818054 1071.304443,196.333084 1068.826660,197.021896 
	C1066.853882,197.570343 1064.940063,198.330872 1062.697632,198.847061 
	C1060.189941,196.332092 1057.997192,193.955444 1055.776733,191.604996 
	C1050.856934,186.396973 1045.923218,181.202087 1040.995850,175.614517 
	C1040.023926,169.869186 1032.657593,165.695038 1027.227051,167.448120 
	C1025.811768,167.905014 1024.464111,168.677216 1023.020569,168.921005 
	C1019.172302,169.570877 1010.333923,162.365311 1010.034729,158.327927 
	C1009.606323,152.545822 1009.148132,146.755356 1009.043518,140.962280 
	C1008.879333,131.868866 1008.999817,131.866684 1017.285278,130.982666 
	C1018.035645,130.684799 1018.500671,130.404877 1019.296265,130.178146 
	C1021.380493,131.828735 1023.134216,133.426132 1024.908936,135.352768 
	C1024.952759,136.121536 1024.975464,136.561081 1024.698608,137.043671 
	C1024.137207,137.541611 1023.875427,137.996490 1023.613708,138.451385 
	C1024.421753,138.280930 1025.229858,138.110474 1026.376221,137.990814 
	C1028.760986,139.716614 1030.807251,141.391647 1032.888184,143.386230 
	C1032.949951,144.134140 1032.977417,144.562485 1033.036621,145.346130 
	C1035.738647,148.460388 1038.408691,151.219360 1041.234497,154.264984 
	C1043.602173,156.676010 1045.814331,158.800400 1048.146240,161.248383 
	C1051.183228,164.041016 1054.100098,166.510056 1057.008545,168.989563 
	C1057.000000,169.000000 1057.021118,168.982910 1057.049316,169.335464 
	C1059.044189,171.128082 1061.011108,172.568146 1063.167725,174.249802 
	C1063.933838,175.289322 1064.509888,176.087250 1065.063232,177.263184 
	C1065.949829,181.295929 1067.936401,183.774506 1072.185059,184.262589 
	C1074.453979,186.218170 1076.181763,188.899261 1079.498779,186.982666 
z"/>
<path fill="#001A66" opacity="1.000000" stroke="none" 
	d="
M1077.931641,45.493938 
	C1077.936890,44.898773 1077.956055,44.716637 1077.992554,44.260159 
	C1078.344482,43.973419 1078.679077,43.961021 1079.264648,43.966568 
	C1079.586304,45.722637 1079.657104,47.460758 1079.269165,49.781662 
	C1078.538696,53.408031 1078.209839,56.447842 1078.008423,59.496063 
	C1077.687012,64.361031 1077.450317,69.231598 1076.938477,73.818100 
	C1073.471802,70.572792 1069.671997,69.661453 1065.345581,69.948616 
	C1061.582764,70.198387 1057.789551,69.989792 1054.000977,69.608742 
	C1051.380493,66.323372 1048.765259,63.416145 1046.157959,60.501869 
	C1042.767822,56.712776 1039.384033,52.918072 1035.997559,49.125748 
	C1048.564331,49.087448 1061.130981,49.040749 1073.697754,49.018791 
	C1075.907471,49.014927 1078.247681,49.292881 1077.931641,45.493938 
z"/>
<path fill="#0433B7" opacity="1.000000" stroke="none" 
	d="
M1048.026367,160.924805 
	C1045.814331,158.800400 1043.602173,156.676010 1041.226318,153.891586 
	C1041.039429,152.484039 1041.016235,151.736526 1041.324951,151.022308 
	C1042.616455,151.196686 1043.576172,151.337738 1044.920044,151.535263 
	C1043.400635,148.986298 1042.201904,146.975479 1041.367798,144.987106 
	C1042.458008,145.071487 1043.183594,145.133423 1043.962402,145.524994 
	C1045.651855,147.580093 1047.288208,149.305542 1048.984863,151.347778 
	C1049.687378,152.133072 1050.329712,152.601562 1051.127808,153.369568 
	C1055.184204,157.449570 1059.084717,161.230072 1063.100830,165.276459 
	C1063.793335,166.036484 1064.370239,166.530655 1065.001099,167.342010 
	C1065.695923,168.121277 1066.336914,168.583359 1067.121216,169.333984 
	C1068.815430,171.095566 1070.366089,172.568604 1071.973267,174.377167 
	C1073.011108,175.500229 1073.992676,176.287796 1075.122925,177.349014 
	C1076.135620,178.131866 1076.999390,178.641068 1077.880371,179.369354 
	C1077.897583,179.588425 1077.973877,180.021240 1077.961548,180.342407 
	C1078.482178,181.460403 1079.015137,182.257217 1079.548218,183.054016 
	C1079.613281,184.111618 1079.678467,185.169235 1079.621094,186.604752 
	C1076.181763,188.899261 1074.453979,186.218170 1072.143066,183.924759 
	C1069.629395,181.178833 1067.357788,179.032013 1065.086060,176.885193 
	C1064.509888,176.087250 1063.933838,175.289322 1063.030273,173.947357 
	C1060.808960,171.929840 1058.915039,170.456360 1057.021118,168.982895 
	C1057.021118,168.982910 1057.000000,169.000000 1056.972412,168.645935 
	C1053.971924,165.836166 1050.999146,163.380478 1048.026367,160.924805 
z"/>
<path fill="#011965" opacity="1.000000" stroke="none" 
	d="
M1078.838745,344.962616 
	C1078.838745,344.962616 1078.414673,344.983521 1078.203491,344.659637 
	C1077.696289,343.563446 1077.400391,342.791107 1077.093506,341.620728 
	C1077.095947,340.161163 1077.109497,339.099670 1077.266846,337.926514 
	C1077.287231,337.538727 1077.163818,337.262634 1077.024170,336.985870 
	C1077.007690,336.985199 1077.025757,336.957764 1077.018921,336.582306 
	C1075.668213,334.811218 1074.324341,333.415588 1072.767578,331.760864 
	C1069.693604,328.676575 1066.832397,325.851410 1063.945312,322.669342 
	C1062.645142,318.384399 1060.196045,315.739380 1055.859619,314.820740 
	C1055.554688,314.343231 1055.307983,314.144196 1055.304199,313.719360 
	C1060.738525,313.285614 1065.876587,313.143799 1071.356323,313.060089 
	C1073.307983,315.076447 1074.917847,317.034698 1076.527832,318.992950 
	C1076.947510,318.822113 1077.367065,318.651276 1077.786743,318.480438 
	C1077.874512,316.638184 1077.962280,314.795959 1078.296265,312.954346 
	C1078.542480,312.954956 1079.034424,312.933472 1079.034424,312.933472 
	C1079.034424,312.933472 1079.525269,312.972290 1079.525146,312.972290 
	C1079.593872,323.302979 1079.662476,333.633636 1079.484863,344.405121 
	C1079.238525,344.845917 1078.838745,344.962616 1078.838745,344.962616 
z"/>
<path fill="#0A4DBA" opacity="1.000000" stroke="none" 
	d="
M1079.579834,139.020401 
	C1079.647705,140.739136 1079.715576,142.457855 1079.261475,144.605164 
	C1077.198486,144.951630 1075.657471,144.869522 1074.086304,144.515228 
	C1073.739258,143.813187 1073.422119,143.383331 1073.037842,142.635849 
	C1072.314453,141.865692 1071.658447,141.413147 1070.810791,140.921783 
	C1070.619385,140.882980 1070.228638,140.890884 1070.145508,140.566254 
	C1068.724976,138.793549 1067.387695,137.345490 1065.902100,135.610626 
	C1064.175659,133.871399 1062.597656,132.418945 1060.908691,130.697266 
	C1060.209229,129.947281 1059.620483,129.466476 1058.887695,128.680817 
	C1056.167236,126.552071 1053.590820,124.728188 1050.648193,122.878334 
	C1049.550049,122.827927 1048.818237,122.803482 1047.889771,122.566177 
	C1047.139404,122.216553 1046.585815,122.079788 1045.882080,121.658249 
	C1044.511475,119.903618 1043.290771,118.433769 1042.062256,116.551712 
	C1042.178955,110.202110 1044.384644,107.230164 1048.800049,107.050278 
	C1054.147827,106.832390 1056.352295,109.058403 1056.981201,115.225418 
	C1057.079712,116.192307 1057.084229,117.465385 1057.674438,118.040207 
	C1064.318970,124.511620 1071.069702,130.874115 1077.842896,137.541382 
	C1078.455933,138.218063 1079.017944,138.619232 1079.579834,139.020401 
M1054.885010,114.213638 
	C1054.116699,113.158539 1053.561157,111.587746 1052.532593,111.163071 
	C1050.764893,110.433258 1048.362061,109.658997 1046.864136,110.329483 
	C1045.524048,110.929359 1044.044922,113.741814 1044.425293,115.058289 
	C1044.966553,116.930649 1046.891479,118.846291 1048.717651,119.767632 
	C1050.255493,120.543526 1052.495850,119.927277 1054.699707,119.927277 
	C1054.810059,117.930115 1054.890381,116.479019 1054.885010,114.213638 
z"/>
<path fill="#000B41" opacity="1.000000" stroke="none" 
	d="
M1079.635254,312.517456 
	C1079.525269,312.972290 1079.034424,312.933472 1079.034424,312.933472 
	C1079.034424,312.933472 1078.542480,312.954956 1078.280640,312.545624 
	C1077.980591,310.768250 1077.942261,309.400208 1077.935303,307.561096 
	C1077.979370,290.394714 1077.992065,273.699371 1078.020386,256.588562 
	C1077.746338,253.788879 1077.456787,251.404724 1077.426270,248.695251 
	C1077.774292,233.263870 1077.863403,218.157776 1077.964844,202.617371 
	C1077.969604,200.438477 1077.961792,198.693924 1077.953857,196.949371 
	C1078.248169,194.741364 1078.542358,192.533356 1079.159424,190.174591 
	C1079.565552,206.456894 1079.691895,222.889862 1079.721680,239.323013 
	C1079.765625,263.569458 1079.740845,287.816071 1079.635254,312.517456 
z"/>
<path fill="#000B41" opacity="1.000000" stroke="none" 
	d="
M1079.645264,138.549637 
	C1079.017944,138.619232 1078.455933,138.218063 1077.916992,137.050018 
	C1077.949341,126.523827 1077.958618,116.764488 1077.978271,106.667809 
	C1077.983276,105.880554 1077.977783,105.430641 1077.980225,104.546707 
	C1077.977295,102.089272 1077.966309,100.065865 1077.967041,97.601631 
	C1077.978027,95.103867 1077.977539,93.046928 1077.985352,90.533737 
	C1077.703613,86.082932 1077.413818,82.088371 1077.119873,77.692917 
	C1077.136353,76.227951 1077.156982,75.163879 1077.177734,74.099800 
	C1077.450317,69.231598 1077.687012,64.361031 1078.008423,59.496063 
	C1078.209839,56.447842 1078.538696,53.408031 1079.146240,50.201729 
	C1079.568115,69.434807 1079.671631,88.830551 1079.732910,108.226425 
	C1079.764404,118.177116 1079.720459,128.128052 1079.645264,138.549637 
z"/>
<path fill="#000B41" opacity="1.000000" stroke="none" 
	d="
M1077.973877,180.021240 
	C1077.973877,180.021240 1077.897583,179.588425 1077.932129,178.976593 
	C1077.961548,177.579330 1077.956299,176.793900 1077.964844,175.539474 
	C1078.002808,166.395462 1078.027222,157.720459 1078.409546,149.016693 
	C1079.001953,148.938522 1079.236206,148.889114 1079.470215,148.839722 
	C1079.561279,158.875275 1079.652222,168.910828 1079.499756,179.373047 
	C1079.256348,179.799698 1078.859375,179.935181 1078.637573,179.952332 
	C1078.415771,179.969498 1077.973877,180.021240 1077.973877,180.021240 
z"/>
<path fill="#012073" opacity="1.000000" stroke="none" 
	d="
M1079.546509,379.665527 
	C1079.410400,380.039032 1078.966675,380.091003 1078.966675,380.091003 
	C1078.966675,380.091003 1078.520752,380.065063 1077.977783,379.915833 
	C1075.960571,378.498718 1074.486328,377.230835 1073.326172,375.831787 
	C1075.345581,375.817871 1077.050659,375.935059 1078.975708,376.104614 
	C1079.195557,376.157013 1079.644897,376.109711 1079.644897,376.109711 
	C1079.657471,377.170471 1079.670044,378.231262 1079.546509,379.665527 
z"/>
<path fill="#4D5E81" opacity="1.000000" stroke="none" 
	d="
M1078.894287,345.431702 
	C1078.838745,344.962616 1079.238525,344.845917 1079.445801,344.864685 
	C1079.749390,352.636780 1079.845581,360.390167 1079.808594,368.599335 
	C1079.433350,361.337036 1079.191528,353.618927 1078.894287,345.431702 
z"/>
<path fill="#011D6F" opacity="1.000000" stroke="none" 
	d="
M1079.591919,148.541931 
	C1079.236206,148.889114 1079.001953,148.938522 1078.093262,148.869492 
	C1076.318115,147.429840 1075.217163,146.108643 1074.116333,144.787430 
	C1075.657471,144.869522 1077.198486,144.951630 1079.123779,145.015396 
	C1079.576538,146.079422 1079.645020,147.161789 1079.591919,148.541931 
z"/>
<path fill="#011B6C" opacity="1.000000" stroke="none" 
	d="
M1077.961548,180.342407 
	C1077.973877,180.021240 1078.415771,179.969498 1078.739746,180.171234 
	C1079.165039,180.703812 1079.376465,180.932571 1079.698364,181.059235 
	C1079.710815,181.507019 1079.723267,181.954788 1079.641846,182.728302 
	C1079.015137,182.257217 1078.482178,181.460403 1077.961548,180.342407 
z"/>
<path fill="#4D5E81" opacity="1.000000" stroke="none" 
	d="
M1079.776733,375.713867 
	C1079.644897,376.109711 1079.195557,376.157013 1079.052002,375.685425 
	C1078.906616,374.355103 1078.904907,373.496368 1078.903198,372.637665 
	C1078.903198,372.637665 1079.301392,372.664673 1079.475098,372.763245 
	C1079.735352,373.680542 1079.822021,374.499268 1079.776733,375.713867 
z"/>
<path fill="#4D5E81" opacity="1.000000" stroke="none" 
	d="
M1079.801270,180.757751 
	C1079.376465,180.932571 1079.165039,180.703812 1078.961670,180.154068 
	C1078.859375,179.935181 1079.256348,179.799698 1079.463623,179.831543 
	C1079.748779,180.061005 1079.826538,180.258636 1079.801270,180.757751 
z"/>
<path fill="#011B6C" opacity="1.000000" stroke="none" 
	d="
M1073.012085,375.962921 
	C1074.486328,377.230835 1075.960571,378.498718 1077.754517,379.916199 
	C1078.042114,382.405579 1078.010010,384.745361 1077.966797,387.522888 
	C1077.404175,387.167053 1076.852417,386.373444 1076.515015,385.888153 
	C1065.297241,391.430389 1053.273193,386.907471 1041.008911,389.003174 
	C1032.280762,389.218719 1023.933655,389.258270 1018.823364,396.042572 
	C1015.164856,400.899689 1010.880920,403.230011 1005.377930,403.864716 
	C1000.546631,404.421875 998.740540,406.945129 998.982178,411.511841 
	C999.078674,413.335297 999.002441,415.167908 999.002441,416.998169 
	C999.000000,417.000000 998.995117,417.004883 998.626343,416.923767 
	C993.889771,410.995056 998.186584,405.347168 998.359436,399.509552 
	C1005.040649,401.806732 1010.399719,399.083618 1010.626770,394.561035 
	C1013.099182,392.735229 1016.078186,391.636047 1016.643738,389.795349 
	C1017.165649,388.096771 1015.098083,385.602539 1013.899658,382.831207 
	C1014.187683,382.503113 1015.332458,381.492889 1016.035461,380.235657 
	C1016.275574,379.806305 1015.406433,378.756683 1015.034119,377.745117 
	C1015.024170,377.499786 1015.011536,377.008759 1015.186157,376.772186 
	C1015.905090,376.340942 1016.449463,376.146301 1017.264404,376.239441 
	C1020.115540,376.498657 1023.179199,378.760498 1025.433105,374.962860 
	C1030.922485,374.952606 1035.942749,374.939667 1041.434692,374.954529 
	C1047.697876,374.987640 1053.492920,374.872040 1059.280029,375.036682 
	C1063.862549,375.167053 1068.435059,375.641357 1073.012085,375.962921 
z"/>
<path fill="#022590" opacity="1.000000" stroke="none" 
	d="
M145.459625,38.016964 
	C143.818619,37.867374 142.644806,37.708038 141.234497,37.282883 
	C147.462494,36.803825 153.926971,36.590588 160.701508,36.652634 
	C155.983322,37.287682 150.955048,37.647449 145.459625,38.016964 
z"/>
<path fill="#022590" opacity="1.000000" stroke="none" 
	d="
M134.745880,36.739239 
	C128.987961,39.172619 126.156631,37.667915 121.091782,29.268841 
	C122.404861,29.789820 123.980019,30.437920 124.987740,31.584667 
	C127.554169,34.505196 130.472229,36.487057 134.745880,36.739239 
z"/>
<path fill="#02257D" opacity="1.000000" stroke="none" 
	d="
M113.007492,20.640490 
	C112.031799,19.902346 111.014893,18.838099 109.988846,17.415068 
	C110.975220,18.142319 111.970749,19.228354 113.007492,20.640490 
z"/>
<path fill="#011D6B" opacity="1.000000" stroke="none" 
	d="
M60.246025,309.995667 
	C68.234932,301.448364 76.595467,292.964661 84.936340,284.461639 
	C86.901001,282.458801 88.799377,280.390900 90.947021,278.624023 
	C90.294991,282.417450 89.323479,285.918335 88.570999,289.465729 
	C85.491119,303.985016 86.523285,318.174194 95.231674,330.448059 
	C100.109276,337.322693 107.082962,342.710144 112.909225,349.058105 
	C109.138809,351.150482 106.307472,351.633606 105.237244,346.414917 
	C105.021812,345.364471 102.472992,344.792542 100.750229,344.000000 
	C100.500458,344.000000 100.000916,344.000000 99.944771,343.661285 
	C96.896172,339.589539 95.370605,334.451447 89.958374,332.636108 
	C88.768265,329.330536 87.687500,326.288147 83.428169,327.479187 
	C84.103500,334.298828 85.955399,336.635040 90.289955,336.299469 
	C90.578995,342.199219 90.578995,347.798248 90.578995,352.924103 
	C94.103943,354.211792 96.551521,355.105865 98.999443,356.374817 
	C99.000366,357.499390 99.000946,358.249084 98.642349,358.959167 
	C96.775269,358.369019 95.267365,357.818420 92.737404,356.894684 
	C93.289459,359.484863 93.672470,361.281921 93.929001,363.368225 
	C93.504005,364.437103 93.205505,365.216705 92.561066,366.075317 
	C90.293236,368.084351 90.143990,369.756622 93.090286,371.299988 
	C95.174301,373.053772 97.088295,374.510986 99.001434,376.380859 
	C99.994041,382.679230 95.189072,382.859253 91.144936,384.205994 
	C96.462051,384.885651 97.406433,388.061829 97.027733,392.240997 
	C96.863190,394.056793 96.898300,395.907104 97.023949,397.729248 
	C97.274551,401.363464 98.001373,404.045319 102.974289,403.153595 
	C108.185165,402.219238 109.853722,404.263672 110.049385,409.677246 
	C110.138008,412.128998 110.866684,414.550537 111.117615,417.004974 
	C111.721428,422.911072 115.133392,429.578522 106.651176,433.121246 
	C105.724319,433.508362 105.100883,435.652405 105.074951,436.995789 
	C104.927246,444.648865 104.776146,452.318146 105.177101,459.953033 
	C105.267815,461.680328 107.370926,464.510620 108.845657,464.718414 
	C112.523903,465.236603 113.075012,467.077881 113.031898,470.126678 
	C112.930740,477.281860 112.967178,484.439606 113.009789,491.595978 
	C113.048820,498.150696 114.776962,500.173462 121.172073,499.895874 
	C125.424706,499.711273 126.265793,501.475525 126.070107,505.157623 
	C125.929985,507.794220 125.967216,510.118774 122.800934,511.042542 
	C122.074783,511.254395 121.151543,512.374695 121.114098,513.117065 
	C120.756226,520.211426 120.273537,527.388184 125.999542,533.401978 
	C125.999680,534.866821 125.999672,535.932190 125.493423,537.037598 
	C124.347343,537.290588 123.707481,537.503540 123.067627,537.716492 
	C123.067619,537.716492 122.961876,537.593201 122.994888,537.519470 
	C124.330200,533.914978 123.397682,531.615417 119.470314,530.926697 
	C113.950729,529.958801 108.379990,529.192078 102.952065,527.850159 
	C101.420280,527.471375 99.415840,525.539124 99.157890,524.053711 
	C97.809006,516.286255 98.010826,508.084229 95.582878,500.722778 
	C93.710487,495.045715 93.354164,488.994781 91.346115,483.887329 
	C89.398506,478.933563 87.840942,474.828644 88.785843,469.575226 
	C88.977890,468.507507 86.803703,466.203796 85.351852,465.802246 
	C80.950119,464.584900 79.829010,461.978302 79.917656,457.689270 
	C80.158417,446.041656 80.125626,434.383118 79.874718,422.735962 
	C79.833656,420.830231 77.974426,419.019806 77.242744,417.058197 
	C76.389809,414.771606 75.531319,412.404022 75.257103,410.005035 
	C74.890121,406.794708 75.147614,403.517914 75.020836,400.274200 
	C74.716736,392.493622 74.516479,384.703400 73.960556,376.939545 
	C73.101143,364.937164 72.001205,352.951691 70.927536,340.965790 
	C70.845543,340.050507 70.405457,338.865051 69.721687,338.354980 
	C65.280121,335.041779 64.312874,330.320770 63.888985,325.236572 
	C63.808018,324.265503 62.411140,323.438995 62.225204,322.434052 
	C61.905506,320.706146 61.305206,317.925873 62.141571,317.270569 
	C66.973816,313.484406 64.431297,311.737457 60.246025,309.995667 
M87.014305,357.509430 
	C85.142754,357.506836 81.772980,357.075806 81.632187,357.568146 
	C80.275787,362.311462 83.831993,364.464111 87.272423,367.115784 
	C89.702301,363.863281 92.065750,361.082092 87.014305,357.509430 
z"/>
<path fill="#011D6B" opacity="1.000000" stroke="none" 
	d="
M44.274639,325.958771 
	C45.046799,324.690277 46.208759,323.435181 47.654011,322.024597 
	C46.846348,323.236816 45.755394,324.604492 44.274639,325.958771 
z"/>
<path fill="#011D6B" opacity="1.000000" stroke="none" 
	d="
M50.239285,319.944458 
	C50.697933,319.013000 51.544834,318.098358 52.671604,317.040527 
	C52.176826,317.918640 51.402180,318.939972 50.239285,319.944458 
z"/>
<path fill="#011560" opacity="1.000000" stroke="none" 
	d="
M92.969666,532.011353 
	C92.922890,532.609253 92.749977,532.738159 92.325806,532.731201 
	C92.007111,532.054810 91.851723,531.395874 91.696335,530.737000 
	C92.108047,531.044983 92.519760,531.352966 92.969666,532.011353 
z"/>
<path fill="#011560" opacity="1.000000" stroke="none" 
	d="
M91.083679,532.786011 
	C91.220154,533.264160 91.024849,533.423706 90.679672,533.561707 
	C90.394577,533.280212 90.210564,532.960510 90.026558,532.640747 
	C90.284607,532.602905 90.542641,532.565063 91.083679,532.786011 
z"/>
<path fill="#031E6F" opacity="1.000000" stroke="none" 
	d="
M92.000290,158.013046 
	C97.303032,157.986572 102.606850,158.017685 107.908211,157.917679 
	C113.387733,157.814285 118.882416,157.334549 122.585732,162.808762 
	C123.152489,163.646530 124.941727,163.657303 126.457642,164.372803 
	C125.933327,165.947998 125.110115,167.196579 124.301559,168.454575 
	C111.393044,188.538391 114.274399,211.464569 131.713470,226.481079 
	C134.523651,228.900848 137.733627,230.856323 141.160492,233.314575 
	C138.459656,234.517075 136.380936,235.484772 134.269836,236.375732 
	C119.766052,242.496735 108.913216,253.133209 98.958939,264.885376 
	C96.609421,266.181122 93.802376,267.021210 91.984604,268.846497 
	C80.130241,280.749908 68.492302,292.868835 56.763897,304.898071 
	C56.572227,305.094666 56.132915,305.049805 55.456985,305.168030 
	C56.224823,300.152618 58.434761,296.076233 63.597610,293.833130 
	C65.521057,292.997437 67.683601,290.990448 68.246361,289.078735 
	C69.725868,284.052734 73.180481,281.459167 78.144745,279.212189 
	C79.475197,276.917053 80.237747,274.960358 81.366333,272.925781 
	C82.156715,271.232330 82.581070,269.616791 83.358536,267.957825 
	C86.330002,264.491913 88.948357,261.069397 92.257729,256.743683 
	C90.361397,256.838196 88.957741,256.502045 88.049454,257.025452 
	C84.554634,259.039459 82.704430,257.859711 82.078178,254.234818 
	C81.572144,251.305771 81.275589,248.334213 81.017311,245.370102 
	C80.596893,240.545197 82.283119,235.421722 78.378639,231.098267 
	C77.589699,230.224640 78.264572,228.029068 78.264572,226.520981 
	C88.395523,225.016418 88.318863,214.445007 95.232590,209.961777 
	C93.791847,209.077347 93.010925,208.241898 92.127998,208.114746 
	C87.085312,207.388580 86.635406,204.061111 86.947021,199.894730 
	C87.262764,195.673309 88.703476,190.939758 83.375664,188.350739 
	C83.140892,188.236649 83.345604,187.218170 83.345604,186.575714 
	C85.940445,186.724655 88.239166,187.067902 90.498833,186.896439 
	C91.614784,186.811752 93.218597,185.992935 93.560349,185.105362 
	C93.840401,184.377960 92.579170,183.057144 91.702148,181.867065 
	C90.602173,180.822952 89.801239,179.911163 89.000473,178.584793 
	C90.328560,174.952026 91.139862,171.356949 93.133362,168.624344 
	C95.030762,166.023468 95.290413,164.050720 93.728966,161.486801 
	C93.058678,160.386169 92.570961,159.174347 92.000290,158.013046 
M92.234055,215.868774 
	C92.637985,217.543488 92.592827,219.556259 93.543610,220.819321 
	C95.279594,223.125519 97.741409,224.979477 100.670845,222.495483 
	C103.284088,220.279663 104.053802,217.132751 101.596420,214.655060 
	C98.821243,211.856903 95.430641,212.043686 92.234055,215.868774 
M100.469704,177.757065 
	C99.600014,175.700241 98.892670,173.541901 97.723747,171.672165 
	C97.489723,171.297836 95.236801,171.669861 94.366379,172.310944 
	C92.203186,173.904144 91.027657,175.912842 93.108833,178.579086 
	C95.597427,181.767303 97.797363,180.606003 100.469704,177.757065 
z"/>
<path fill="#011D6B" opacity="1.000000" stroke="none" 
	d="
M92.001167,181.999390 
	C92.579170,183.057144 93.840401,184.377960 93.560349,185.105362 
	C93.218597,185.992935 91.614784,186.811752 90.498833,186.896439 
	C88.239166,187.067902 85.940445,186.724655 83.345604,186.575714 
	C83.345604,187.218170 83.140892,188.236649 83.375664,188.350739 
	C88.703476,190.939758 87.262764,195.673309 86.947021,199.894730 
	C86.635406,204.061111 87.085312,207.388580 92.127998,208.114746 
	C93.010925,208.241898 93.791847,209.077347 95.232590,209.961777 
	C88.318863,214.445007 88.395523,225.016418 78.264572,226.520981 
	C78.264572,228.029068 77.589699,230.224640 78.378639,231.098267 
	C82.283119,235.421722 80.596893,240.545197 81.017311,245.370102 
	C81.275589,248.334213 81.572144,251.305771 82.078178,254.234818 
	C82.704430,257.859711 84.554634,259.039459 88.049454,257.025452 
	C88.957741,256.502045 90.361397,256.838196 92.257729,256.743683 
	C88.948357,261.069397 86.330002,264.491913 82.991570,268.033752 
	C81.847771,269.769958 81.424034,271.386810 81.000305,273.003662 
	C80.237747,274.960358 79.475197,276.917053 78.357239,278.937500 
	C78.279320,277.597046 78.556808,276.192871 78.921478,274.347534 
	C77.882782,274.504211 77.239563,274.471649 76.709518,274.700836 
	C70.103783,277.556824 64.831902,274.218384 65.085907,266.830597 
	C65.340050,259.438599 66.805275,252.069290 66.789276,244.692093 
	C66.772179,236.810349 65.078941,228.919647 65.237106,221.055389 
	C65.317154,217.074844 68.037010,213.194931 69.236603,209.172104 
	C69.994453,206.630615 70.651260,203.875351 70.476082,201.275101 
	C70.023254,194.553345 68.887856,187.875000 68.524200,181.152298 
	C68.460754,179.979416 70.644005,178.362762 72.110893,177.492844 
	C75.755829,175.331238 77.278618,172.579987 77.205101,168.140518 
	C77.153740,165.039154 78.989815,161.906525 79.991425,158.398712 
	C83.693336,157.993332 87.386665,157.977066 91.540146,157.986938 
	C92.570961,159.174347 93.058678,160.386169 93.728966,161.486801 
	C95.290413,164.050720 95.030762,166.023468 93.133362,168.624344 
	C91.139862,171.356949 90.328560,174.952026 88.646271,178.520737 
	C86.672882,178.398300 85.031036,177.558640 83.447624,177.657272 
	C82.915199,177.690445 81.772736,180.573135 82.241051,181.339569 
	C83.155037,182.835388 85.009346,184.685684 86.471527,184.683304 
	C88.316185,184.680313 90.158081,182.972000 92.001167,181.999390 
M75.047493,196.024292 
	C75.041290,199.323410 74.823051,202.643921 75.152451,205.909180 
	C75.257217,206.947739 76.818863,207.839355 77.713837,208.798203 
	C78.464760,207.859406 79.764244,206.974701 79.863159,205.971619 
	C80.153145,203.031067 80.229538,200.012848 79.890602,197.082779 
	C79.551270,194.149216 77.913841,193.027466 75.047493,196.024292 
M82.710533,259.356476 
	C81.590736,260.333374 80.470940,261.310303 79.351143,262.287201 
	C80.152214,263.156067 80.953278,264.024963 81.754341,264.893829 
	C82.200180,264.794647 82.646011,264.695435 83.091850,264.596222 
	C83.184456,262.904083 83.277061,261.211945 82.710533,259.356476 
M73.827881,244.606461 
	C73.266930,244.282761 72.705986,243.959061 72.145020,243.635361 
	C71.941223,244.203720 71.737419,244.772064 71.533623,245.340424 
	C72.242249,245.297531 72.950882,245.254654 73.827881,244.606461 
z"/>
<path fill="#031E6F" opacity="1.000000" stroke="none" 
	d="
M62.054203,158.304535 
	C62.752758,157.955383 63.500961,157.940628 64.622650,157.951096 
	C64.032036,158.197189 63.067944,158.418060 62.054203,158.304535 
z"/>
<path fill="#011964" opacity="1.000000" stroke="none" 
	d="
M113.089912,547.337769 
	C113.726227,551.102966 111.078346,552.382996 108.111961,553.932739 
	C107.327461,553.991211 107.156746,553.988281 106.893753,553.714844 
	C108.636734,548.713257 105.689438,547.258057 101.544182,545.896301 
	C101.327332,547.524292 101.162598,548.760925 100.599411,549.997559 
	C96.816528,550.217285 95.987564,547.982605 96.054832,545.316956 
	C96.107216,543.241028 96.663383,541.177856 96.995667,539.109009 
	C101.391777,535.977844 106.206795,536.595093 111.353386,537.192261 
	C110.982689,539.445251 110.675095,541.314758 110.236893,543.657593 
	C110.271156,544.116272 110.436028,544.101440 110.600906,544.086670 
	C110.786255,544.322998 110.971603,544.559387 110.760986,545.312988 
	C109.875610,546.391052 109.386215,546.951904 108.896820,547.512817 
	C109.974144,547.240051 111.051453,546.967224 112.128761,546.694458 
	C112.455269,546.759094 112.746765,546.897522 113.089912,547.337769 
z"/>
<path fill="#011560" opacity="1.000000" stroke="none" 
	d="
M124.009918,588.009766 
	C123.037941,589.126526 122.065964,590.243347 121.046539,591.680054 
	C119.875420,591.976990 118.751747,591.953918 117.209328,591.878540 
	C118.665031,589.582031 120.840752,587.523010 122.355400,585.057861 
	C126.314743,578.613953 124.747536,572.420166 120.713722,566.222229 
	C121.454536,565.867188 122.376732,565.783203 123.652328,566.190063 
	C124.347878,567.459778 124.690018,568.238586 125.032150,569.017395 
	C125.689850,572.388855 126.347549,575.760376 126.998077,579.935791 
	C125.997246,583.163025 125.003586,585.586426 124.009918,588.009766 
z"/>
<path fill="#051F6B" opacity="1.000000" stroke="none" 
	d="
M96.631851,539.113403 
	C96.663383,541.177856 96.107216,543.241028 96.054832,545.316956 
	C95.987564,547.982605 96.816528,550.217285 100.600471,549.998779 
	C101.000000,550.000000 101.002136,550.002441 101.002983,550.339600 
	C101.331505,551.455322 101.659180,552.233887 101.959290,553.352051 
	C101.900230,553.916504 101.868744,554.141357 101.837265,554.366272 
	C99.919884,553.230042 97.832039,552.304138 96.119026,550.915710 
	C91.996452,547.574280 92.102692,545.195801 96.631851,539.113403 
z"/>
<path fill="#011560" opacity="1.000000" stroke="none" 
	d="
M109.952972,558.446899 
	C115.087341,556.129822 120.312080,559.426147 120.262909,565.200195 
	C116.682503,563.289307 113.360130,561.027405 109.952972,558.446899 
z"/>
<path fill="#011964" opacity="1.000000" stroke="none" 
	d="
M118.603256,548.070190 
	C119.539879,549.445435 120.077866,550.768494 120.615860,552.091614 
	C120.295578,552.457520 119.975296,552.823425 119.655014,553.189331 
	C118.106209,551.715942 116.557411,550.242554 115.001236,548.394775 
	C116.064117,548.019653 117.134369,548.018799 118.603256,548.070190 
z"/>
<path fill="#011560" opacity="1.000000" stroke="none" 
	d="
M102.044205,554.632935 
	C101.868744,554.141357 101.900230,553.916504 102.335075,553.347168 
	C103.489235,553.002563 104.240036,553.002502 105.161385,553.242859 
	C105.883293,553.650635 106.434654,553.817993 106.986023,553.985352 
	C107.156746,553.988281 107.327461,553.991211 107.754395,553.998291 
	C108.620865,555.102783 109.231117,556.203186 109.761879,557.704102 
	C107.205315,557.036316 104.728233,555.967957 102.044205,554.632935 
z"/>
<path fill="#051F6B" opacity="1.000000" stroke="none" 
	d="
M123.047531,538.099243 
	C123.707481,537.503540 124.347343,537.290588 125.715202,537.214294 
	C126.293709,539.229126 126.144203,541.107239 125.673286,542.986572 
	C124.923332,542.991089 124.494789,542.994324 124.066246,542.997559 
	C123.719971,541.492371 123.373703,539.987183 123.047531,538.099243 
z"/>
<path fill="#011964" opacity="1.000000" stroke="none" 
	d="
M123.940277,543.307861 
	C124.494789,542.994324 124.923332,542.991089 125.676498,543.322876 
	C126.002434,544.105957 126.003754,544.554016 126.005081,545.002014 
	C124.749382,546.097961 123.493683,547.193970 122.057297,548.020630 
	C122.522507,546.373535 123.168411,544.995850 123.940277,543.307861 
z"/>
<path fill="#051F6B" opacity="1.000000" stroke="none" 
	d="
M112.119568,546.495728 
	C111.051453,546.967224 109.974144,547.240051 108.896820,547.512817 
	C109.386215,546.951904 109.875610,546.391052 110.750656,545.763489 
	C111.460991,545.896912 111.785690,546.096985 112.119568,546.495728 
z"/>
<path fill="#011560" opacity="1.000000" stroke="none" 
	d="
M116.547958,591.776794 
	C116.397690,592.128479 115.915985,592.515869 115.099960,592.908569 
	C115.249260,592.546753 115.732895,592.179626 116.547958,591.776794 
z"/>
<path fill="#051F6B" opacity="1.000000" stroke="none" 
	d="
M110.578171,544.073608 
	C110.436028,544.101440 110.271156,544.116272 110.342789,544.070679 
	C110.579292,544.010315 110.555435,544.060547 110.578171,544.073608 
z"/>
<path fill="#1546AD" opacity="1.000000" stroke="none" 
	d="
M101.356964,620.893188 
	C100.647377,620.150818 99.929337,619.414978 99.947769,618.698059 
	C99.980888,617.409607 100.157272,615.916748 100.865150,614.936340 
	C101.253242,614.398865 103.584587,614.373169 103.765793,614.742249 
	C104.342583,615.916931 104.452248,617.383789 104.465782,618.740479 
	C104.470940,619.257935 103.726555,619.782837 103.149696,620.608521 
	C102.543884,620.911255 102.112152,620.909729 101.356964,620.893188 
z"/>
<path fill="#F3F7F9" opacity="1.000000" stroke="none" 
	d="
M99.023224,265.250854 
	C108.913216,253.133209 119.766052,242.496735 134.269836,236.375732 
	C136.380936,235.484772 138.459656,234.517075 141.160492,233.314575 
	C137.733627,230.856323 134.523651,228.900848 131.713470,226.481079 
	C114.274399,211.464569 111.393044,188.538391 124.301559,168.454575 
	C125.110115,167.196579 125.933327,165.947998 126.834976,164.371048 
	C127.297485,163.747375 127.674744,163.447708 128.402115,162.750076 
	C132.640747,158.946533 136.352844,155.309952 140.451553,152.179550 
	C151.811371,143.503464 165.230820,140.211304 179.455109,138.057526 
	C198.363846,138.519318 214.906097,142.636429 224.224091,161.199982 
	C224.849945,163.381729 225.479599,165.211090 226.055481,167.391876 
	C226.248413,168.380936 226.495102,169.018600 226.582367,169.934525 
	C226.567474,170.819321 226.712006,171.425827 226.743469,172.382965 
	C227.007706,173.555099 227.385056,174.376617 227.749313,175.398956 
	C227.736221,175.599762 227.947845,175.942123 227.865585,176.268555 
	C227.544098,177.408707 227.304886,178.222458 226.987488,179.277374 
	C226.970779,179.676178 227.032272,179.833786 226.915527,180.280945 
	C226.739197,181.014557 226.741074,181.458603 226.530762,182.180206 
	C225.455154,184.191650 224.842987,186.118378 223.685318,187.626312 
	C218.381790,194.534515 210.528763,194.167725 202.284821,186.490784 
	C201.787659,185.292572 201.265976,184.369110 200.701294,182.950119 
	C197.940552,171.016556 200.388229,165.679199 210.334686,161.354324 
	C204.809952,150.945435 186.180389,147.243225 174.547699,149.838547 
	C160.774765,152.911377 148.230362,166.965790 146.098221,182.188873 
	C145.643234,183.885498 145.372284,185.327393 144.966049,187.185364 
	C144.646729,188.914398 144.394318,190.221924 144.289017,191.541199 
	C142.903931,208.898026 155.296356,226.119385 171.154572,227.632843 
	C180.995667,228.572037 190.988815,227.917953 201.183685,228.126511 
	C201.891388,229.151932 202.329102,230.029877 202.574677,231.256134 
	C201.864334,234.099289 201.346115,236.594162 200.839188,239.034637 
	C197.967499,239.227142 195.543289,239.389664 192.661758,239.411621 
	C188.743668,239.303467 185.277863,239.231750 181.823563,239.398209 
	C178.522171,239.557312 175.232742,239.964279 171.540466,240.184143 
	C167.782623,240.804413 164.422333,241.502853 160.759277,242.210541 
	C159.958817,242.504318 159.461151,242.788849 158.630905,243.037796 
	C157.163406,243.614777 156.028488,244.227325 154.528870,244.794708 
	C149.911148,247.605774 145.658127,250.462006 141.441467,253.392471 
	C141.477829,253.466705 141.337585,253.379150 141.042542,253.477631 
	C139.519974,254.721420 138.292435,255.866745 136.739075,257.110229 
	C124.569839,269.055145 118.025330,283.324707 116.605057,300.370148 
	C116.644699,304.789520 116.908363,308.878448 117.111084,313.377502 
	C118.305077,323.329742 121.050369,332.170624 128.833221,338.965729 
	C129.884827,339.943787 130.864014,340.631287 132.006866,341.620178 
	C135.777069,343.492798 139.249329,345.618927 143.015549,346.530273 
	C153.891937,349.162231 164.825409,348.085907 175.296051,344.683685 
	C181.520569,342.661133 187.219452,339.020966 193.103699,336.085297 
	C193.052734,336.062469 193.102509,336.162445 193.469360,336.161469 
	C198.941376,331.476868 204.046539,326.793274 209.098801,322.074158 
	C209.045898,322.038666 209.106583,322.150696 209.453613,322.061768 
	C210.194809,321.460541 210.625656,320.972168 210.977417,320.432281 
	C214.326111,315.292755 217.661407,310.144531 221.287445,305.135803 
	C221.991043,305.307526 222.407715,305.342194 223.107361,305.587433 
	C225.321762,306.859131 227.253204,307.920258 229.058395,309.341461 
	C228.791794,310.448517 228.651459,311.195526 228.286911,312.166809 
	C227.040253,314.601501 226.017807,316.811890 224.761261,319.269348 
	C217.751648,331.095245 207.995773,339.699799 196.655579,347.052307 
	C192.531830,348.840271 188.769165,350.659546 184.665100,352.581604 
	C183.524170,353.059570 182.724640,353.434662 181.540466,353.864990 
	C161.671539,359.996826 142.245926,361.432312 122.907059,353.280334 
	C122.063934,352.924927 120.986015,353.126526 119.780090,352.946930 
	C119.374641,352.766998 119.206581,352.708771 119.002792,352.330322 
	C117.704361,351.281464 116.441658,350.552795 115.000427,349.573761 
	C114.252007,349.138153 113.682106,348.952911 113.112198,348.767670 
	C107.082962,342.710144 100.109276,337.322693 95.231674,330.448059 
	C86.523285,318.174194 85.491119,303.985016 88.570999,289.465729 
	C89.323479,285.918335 90.294991,282.417450 91.267334,278.506531 
	C93.941750,273.950684 96.514626,269.783508 99.023224,265.250854 
z"/>
<path fill="#0B76EC" opacity="1.000000" stroke="none" 
	d="
M24.916794,334.721619 
	C25.456945,334.343964 25.918077,334.271240 26.730503,334.236938 
	C31.667517,336.281494 28.905041,339.948639 28.381727,342.544952 
	C28.100063,343.942383 24.280020,346.092010 23.294310,345.569092 
	C21.395700,344.561798 20.314877,341.935486 19.038855,339.890778 
	C18.747332,339.423645 19.040358,338.591675 19.064648,337.516541 
	C19.064949,337.105469 19.450125,336.942688 19.647987,336.876221 
	C21.509827,336.215363 23.173800,335.620941 24.916794,334.721619 
z"/>
<path fill="#011D6E" opacity="1.000000" stroke="none" 
	d="
M179.004211,137.973618 
	C165.230820,140.211304 151.811371,143.503464 140.451553,152.179550 
	C136.352844,155.309952 132.640747,158.946533 128.418701,162.499725 
	C127.669258,157.226715 122.204636,152.077240 116.727219,152.056671 
	C105.127411,152.013092 93.527405,152.025879 81.466354,151.615570 
	C82.754478,145.589478 87.527046,143.782639 92.421646,142.026230 
	C93.664917,141.580124 94.696655,140.544495 95.825592,139.779724 
	C94.953789,138.698166 94.297127,137.225204 93.171059,136.606277 
	C89.337769,134.499359 85.338951,132.693619 81.295395,130.720215 
	C81.172173,124.776688 82.263855,123.846680 87.894623,124.931152 
	C89.360764,125.213531 91.846092,125.446487 92.334450,124.700836 
	C95.208275,120.312866 97.566940,122.097389 100.350395,124.680161 
	C101.095177,125.371246 102.808403,125.605865 103.783592,125.248749 
	C104.402573,125.022087 105.054024,122.745743 104.691887,122.381287 
	C103.628967,121.311577 101.897987,120.933792 100.734032,119.930672 
	C98.112679,117.671524 93.382454,114.922050 93.630295,112.869606 
	C94.182083,108.300079 92.079521,106.663330 89.179573,104.589394 
	C86.941414,102.988739 84.854004,101.117760 82.996124,99.094643 
	C82.620438,98.685532 83.574997,97.054825 84.101318,96.217606 
	C84.825195,96.650337 85.364037,96.852776 85.902870,97.055214 
	C91.410637,102.633858 96.918396,108.212502 102.755386,114.254883 
	C103.742157,114.768333 104.399719,114.818054 105.057274,114.867783 
	C117.063301,114.871635 129.069321,114.875481 141.530701,115.274841 
	C139.787018,117.956764 137.587967,120.243172 135.388916,122.529587 
	C135.748505,122.609131 136.108078,122.688683 136.467651,122.768227 
	C136.080460,124.935890 135.693253,127.103554 135.350616,129.021759 
	C136.356339,135.890701 137.367477,142.796585 138.408554,149.907043 
	C141.867889,148.032471 144.783493,146.556442 147.592300,144.899414 
	C150.090988,143.425354 152.471359,141.750717 155.507172,139.771286 
	C157.321411,139.549423 160.208008,139.346939 163.032730,138.815628 
	C167.251038,138.022217 171.401276,136.837433 175.637009,136.187698 
	C176.644394,136.033173 177.875824,137.339325 179.004211,137.973618 
z"/>
<path fill="#001A66" opacity="1.000000" stroke="none" 
	d="
M83.916267,95.987312 
	C83.574997,97.054825 82.620438,98.685532 82.996124,99.094643 
	C84.854004,101.117760 86.941414,102.988739 89.179573,104.589394 
	C92.079521,106.663330 94.182083,108.300079 93.630295,112.869606 
	C93.382454,114.922050 98.112679,117.671524 100.734032,119.930672 
	C101.897987,120.933792 103.628967,121.311577 104.691887,122.381287 
	C105.054024,122.745743 104.402573,125.022087 103.783592,125.248749 
	C102.808403,125.605865 101.095177,125.371246 100.350395,124.680161 
	C97.566940,122.097389 95.208275,120.312866 92.334450,124.700836 
	C91.846092,125.446487 89.360764,125.213531 87.894623,124.931152 
	C82.263855,123.846680 81.172173,124.776688 81.295395,130.720215 
	C85.338951,132.693619 89.337769,134.499359 93.171059,136.606277 
	C94.297127,137.225204 94.953789,138.698166 95.825592,139.779724 
	C94.696655,140.544495 93.664917,141.580124 92.421646,142.026230 
	C87.527046,143.782639 82.754478,145.589478 80.998627,151.575668 
	C74.307274,151.964188 67.622528,151.992599 60.417305,151.667938 
	C58.901329,151.279251 57.905842,151.243652 56.910351,151.208038 
	C56.180916,151.176285 55.451485,151.144531 54.324474,150.724319 
	C52.550175,149.255356 51.173447,148.174850 49.796722,147.094345 
	C49.189259,146.621841 48.581791,146.149338 47.890812,145.379974 
	C56.394852,145.551758 60.287148,145.249634 60.163952,134.748993 
	C60.124561,131.391525 62.537926,128.067368 62.901165,124.629898 
	C63.612373,117.899391 63.888340,111.100815 63.915520,104.328697 
	C63.930901,100.495392 64.348129,97.353264 69.903976,96.227425 
	C64.750275,92.530647 64.410378,88.775307 67.430969,84.406509 
	C67.766167,83.921700 65.604912,81.710846 64.042831,80.041122 
	C62.989834,79.520576 62.495453,79.259384 62.001068,78.998199 
	C62.741993,78.334740 63.482914,77.671280 64.742615,77.275459 
	C66.164536,78.036476 67.067680,78.529854 67.970825,79.023239 
	C68.544991,79.522430 69.119156,80.021614 69.840622,81.139404 
	C71.059921,82.771423 72.131927,83.784828 73.203941,84.798241 
	C74.034172,85.355522 74.864403,85.912811 75.845551,87.107559 
	C77.989052,89.504990 79.981644,91.264969 81.974228,93.024956 
	C82.554489,93.518715 83.134758,94.012474 83.832092,95.004837 
	C83.949173,95.503433 83.916267,95.987312 83.916267,95.987312 
M84.796761,112.607109 
	C85.911163,113.123535 87.025558,113.639969 88.139954,114.156403 
	C88.485184,112.695541 89.609871,110.605301 89.032082,109.889717 
	C87.165680,107.578224 84.673141,105.772308 82.164116,103.542465 
	C79.597244,108.073563 83.128593,109.644684 84.796761,112.607109 
z"/>
<path fill="#011D6E" opacity="1.000000" stroke="none" 
	d="
M49.845867,147.425659 
	C51.173447,148.174850 52.550175,149.255356 53.961205,150.687424 
	C53.258015,151.003571 52.520515,150.968155 51.416649,150.891205 
	C50.665195,149.818771 50.280102,148.787872 49.845867,147.425659 
z"/>
<path fill="#011D6E" opacity="1.000000" stroke="none" 
	d="
M57.151894,151.435654 
	C57.905842,151.243652 58.901329,151.279251 59.949532,151.641418 
	C59.132641,151.866394 58.263039,151.764832 57.151894,151.435654 
z"/>
<path fill="#011D6E" opacity="1.000000" stroke="none" 
	d="
M42.909683,140.402725 
	C44.226658,141.468063 45.611897,142.856522 47.001099,144.609680 
	C46.827110,144.985596 46.649166,144.996857 46.203247,145.014740 
	C44.949497,143.589523 43.963722,142.157700 42.909683,140.402725 
z"/>
<path fill="#023CA5" opacity="1.000000" stroke="none" 
	d="
M518.048035,127.676590 
	C518.048035,127.676598 518.224976,127.252342 518.686584,127.624825 
	C520.890381,128.319199 522.630188,128.903580 524.375122,128.919495 
	C534.194153,129.008972 544.024841,128.689301 553.830017,129.067963 
	C559.296814,129.279068 563.261780,127.581703 566.981201,123.601837 
	C575.449585,114.540398 584.302856,105.838676 593.398071,96.993401 
	C596.193115,96.333450 598.589905,95.670868 601.395264,95.004868 
	C611.551331,92.487610 621.171143,91.435242 631.241760,94.232231 
	C634.694641,95.191200 639.091003,92.059151 643.005981,92.199577 
	C650.024292,92.451317 657.004517,93.766060 663.902405,94.624321 
	C661.268921,96.115173 657.644348,97.007965 657.328064,98.570419 
	C656.487305,102.723747 656.810913,107.192772 657.250305,111.476860 
	C657.383362,112.773773 659.360352,113.928200 660.580627,115.052467 
	C661.594604,115.986610 662.978516,116.625732 663.686157,117.725258 
	C663.917725,118.085121 662.656128,120.119881 661.872314,120.262711 
	C660.467834,120.518646 658.882263,120.022232 657.419983,119.652191 
	C655.937378,119.277008 654.509705,118.246643 653.062500,118.255363 
	C649.436890,118.277191 645.816650,118.771248 642.189148,118.992538 
	C631.117554,119.667984 619.912292,119.477364 609.010803,121.208939 
	C599.766663,122.677246 590.427124,123.735344 581.390076,127.564598 
	C571.449829,131.776550 560.965271,134.646515 551.566406,140.992874 
	C536.827942,143.459015 522.613525,141.353928 507.968262,139.900650 
	C509.694397,137.390152 511.945984,135.711929 513.796143,133.669998 
	C515.066345,132.268158 515.778748,130.360962 516.740540,128.679657 
	C516.740540,128.679657 516.819641,128.803284 517.053650,128.730316 
	C517.541077,128.330429 517.794556,128.003510 518.048035,127.676590 
z"/>
<path fill="#012E8A" opacity="1.000000" stroke="none" 
	d="
M770.804993,95.488976 
	C773.741516,86.367767 781.807922,81.895401 787.767517,75.608963 
	C792.951172,70.141022 798.342285,64.855774 803.874207,59.741222 
	C805.369690,58.358585 807.679749,57.209976 809.667175,57.122879 
	C816.787109,56.810844 823.929077,57.000225 831.580566,57.389107 
	C833.903442,59.958374 831.310791,64.747231 836.380371,64.992043 
	C840.033142,65.168427 840.882019,67.420433 841.072815,70.674461 
	C841.281372,74.230774 841.463684,77.987389 842.725952,81.242302 
	C845.457703,88.286308 848.871643,95.065758 851.538940,101.976654 
	C845.709961,102.316986 840.348694,102.634079 834.657654,102.945816 
	C833.888245,102.944809 833.448608,102.949158 832.750000,102.697441 
	C830.658630,102.613274 828.826233,102.785179 826.525513,102.951553 
	C821.577026,102.942123 817.095947,102.879944 812.616699,102.946022 
	C802.182617,103.099937 791.749390,103.315613 781.315857,103.506279 
	C777.860901,100.939362 774.406006,98.372444 770.804993,95.488976 
M774.481995,95.579575 
	C774.481995,95.579575 774.422913,95.478172 774.481995,95.579575 
z"/>
<path fill="#043898" opacity="1.000000" stroke="none" 
	d="
M598.902466,52.001358 
	C604.674561,45.962379 610.363770,39.839970 616.289856,33.956116 
	C617.450012,32.804253 619.527832,32.576607 621.554260,31.865040 
	C643.703491,31.859194 665.481140,31.981432 687.257874,31.892654 
	C690.837646,31.878061 693.478149,32.760414 696.049866,35.384975 
	C706.796143,46.351955 717.766724,57.099033 728.651978,67.929878 
	C730.239990,69.509956 731.810852,71.107231 733.159668,72.919441 
	C722.757629,69.366608 712.790039,64.839699 702.362732,62.002285 
	C688.844116,58.323692 674.977417,55.900539 661.220032,53.138783 
	C657.501892,52.392384 653.637390,52.375496 649.079224,51.690231 
	C639.716125,51.246510 631.113464,51.159225 622.512085,50.999886 
	C621.307983,50.977581 619.683044,51.026508 618.980530,50.319481 
	C614.925171,46.237648 614.999207,46.164104 610.035583,49.468246 
	C610.357971,50.421497 610.664246,51.327282 610.970581,52.233078 
	C610.717529,52.477848 610.415588,52.585426 609.448730,52.356361 
	C607.898254,52.440338 606.963806,52.723782 606.029297,53.007225 
	C603.945923,53.331417 601.862610,53.655613 599.401733,53.598507 
	C598.983704,52.811924 598.943115,52.406639 598.902466,52.001358 
z"/>
<path fill="#093C9C" opacity="1.000000" stroke="none" 
	d="
M551.987793,140.972763 
	C560.965271,134.646515 571.449829,131.776550 581.390076,127.564598 
	C590.427124,123.735344 599.766663,122.677246 609.010803,121.208939 
	C619.912292,119.477364 631.117554,119.667984 642.189148,118.992538 
	C645.816650,118.771248 649.436890,118.277191 653.062500,118.255363 
	C654.509705,118.246643 655.937378,119.277008 657.419983,119.652191 
	C658.882263,120.022232 660.467834,120.518646 661.872314,120.262711 
	C662.656128,120.119881 663.917725,118.085121 663.686157,117.725258 
	C662.978516,116.625732 661.594604,115.986610 660.580627,115.052467 
	C659.360352,113.928200 657.383362,112.773773 657.250305,111.476860 
	C656.810913,107.192772 656.487305,102.723747 657.328064,98.570419 
	C657.644348,97.007965 661.268921,96.115173 663.902405,94.624321 
	C657.004517,93.766060 650.024292,92.451317 643.005981,92.199577 
	C639.091003,92.059151 634.694641,95.191200 631.241760,94.232231 
	C621.171143,91.435242 611.551331,92.487610 601.006104,95.002548 
	C598.482178,94.498383 596.756104,93.993134 595.030029,93.487885 
	C614.158203,88.960526 633.588867,88.383385 652.971191,90.447098 
	C665.625366,91.794456 678.093445,94.889435 690.869507,97.821930 
	C691.625305,99.898018 691.766113,101.734421 692.752563,102.746452 
	C698.313965,108.451752 704.116516,113.921043 709.744995,119.562431 
	C711.028137,120.848473 711.948975,122.495979 712.682983,123.947258 
	C709.825684,121.838371 707.200012,119.887650 704.846924,117.650223 
	C701.332642,114.308762 698.783997,109.817421 692.564758,110.853470 
	C689.326172,111.392975 688.896484,112.468231 689.197693,115.340080 
	C689.797546,121.058632 693.608459,122.230614 698.376343,123.078323 
	C703.892822,124.059135 709.791748,124.517998 712.072388,131.539581 
	C712.798096,133.773651 715.955322,135.217957 717.796326,137.297516 
	C714.799133,138.177612 712.005554,138.783859 709.114868,139.423584 
	C709.017822,139.457062 708.844482,139.346939 708.926758,138.970642 
	C711.442871,135.950272 711.572388,133.052490 708.815430,130.866806 
	C706.082458,128.700195 703.004883,126.604866 699.733032,125.562119 
	C694.109314,123.769814 691.643066,126.418762 692.597412,132.080627 
	C675.605774,126.134300 658.535400,122.406296 640.852112,121.952530 
	C626.576294,121.586197 612.508484,122.653091 598.417053,125.749725 
	C582.238953,129.304916 567.033936,134.798325 552.540527,142.797943 
	C552.229980,142.052765 552.108887,141.512756 551.987793,140.972763 
z"/>
<path fill="#0E76D3" opacity="1.000000" stroke="none" 
	d="
M430.598633,64.877716 
	C432.410034,65.741768 433.914398,66.486420 435.061096,67.599510 
	C447.887726,80.050774 460.688477,92.529221 473.411102,105.086540 
	C475.134521,106.787567 476.391815,108.960861 478.153259,111.162575 
	C477.995392,111.953781 477.549164,112.497879 476.421326,113.065094 
	C474.079742,112.129005 472.143646,111.455322 470.806519,110.162254 
	C464.230133,103.802696 457.839386,97.251694 451.328247,90.823830 
	C447.944519,87.483353 444.447235,84.257927 440.803589,80.943649 
	C440.605957,80.906776 440.203827,80.906570 440.120453,80.588547 
	C439.029968,79.170532 438.022827,78.070534 436.869934,76.670258 
	C433.318573,73.487396 431.419189,68.893890 425.672668,68.873985 
	C402.735443,68.794548 379.799866,68.116554 356.863007,68.080925 
	C279.471619,67.960732 202.079941,68.013191 124.688408,68.054657 
	C122.796860,68.055672 120.905655,68.699249 119.014290,69.043129 
	C118.575165,69.067329 118.136047,69.091522 117.359428,68.733948 
	C117.338974,67.250175 117.656006,66.148178 118.189034,64.903770 
	C118.659111,64.335320 118.913200,63.909279 119.167282,63.483238 
	C130.797272,63.856728 142.427277,64.230217 154.521057,64.546387 
	C172.062378,64.364578 189.139908,64.240074 206.638000,64.090851 
	C208.835602,64.370735 210.612534,64.941002 212.389709,64.941681 
	C284.968292,64.969620 357.546875,64.946281 430.598633,64.877716 
z"/>
<path fill="#033A9F" opacity="1.000000" stroke="none" 
	d="
M441.001221,80.980522 
	C444.447235,84.257927 447.944519,87.483353 451.328247,90.823830 
	C457.839386,97.251694 464.230133,103.802696 470.806519,110.162254 
	C472.143646,111.455322 474.079742,112.129005 476.146912,113.090317 
	C476.481354,113.286354 476.408600,113.480278 475.908569,114.007652 
	C475.259949,114.578110 475.135681,114.858223 475.108490,115.181435 
	C475.108490,115.181435 475.096497,115.084435 474.761169,115.125992 
	C470.501740,119.335129 470.229950,124.352318 471.772675,129.382233 
	C472.924561,133.137894 474.948456,136.626083 476.650604,140.380188 
	C472.338104,140.924393 468.171448,141.450180 463.537720,141.980011 
	C461.250458,141.986496 459.430237,141.992920 457.610046,141.990646 
	C451.074829,141.982468 444.539612,141.971329 437.991150,141.588638 
	C435.577911,138.767929 433.128723,136.365158 430.807465,133.844620 
	C430.098755,133.075073 429.084167,131.604218 429.363831,131.116928 
	C429.906982,130.170547 431.196167,129.318268 432.305573,129.107422 
	C434.075348,128.771088 436.137024,129.470856 437.733643,128.851669 
	C439.656067,128.106094 442.386078,126.689232 442.721680,125.124451 
	C443.711426,120.509521 442.866272,116.074356 446.532440,111.482780 
	C450.615326,106.369270 450.125031,105.368309 454.862061,110.203751 
	C455.597656,110.954643 457.142181,111.928581 457.680969,111.636040 
	C458.668243,111.099968 459.719543,109.720497 459.768066,108.648521 
	C459.836060,107.146217 459.407562,105.292610 458.497925,104.133553 
	C455.112946,99.820282 447.984619,98.545822 448.148804,91.293709 
	C448.154510,91.040695 447.388275,90.642197 446.927765,90.526260 
	C441.858307,89.250046 440.712280,85.566902 441.001221,80.980522 
z"/>
<path fill="#04308B" opacity="1.000000" stroke="none" 
	d="
M785.737732,140.869995 
	C790.929321,136.377274 795.890198,131.734421 795.578308,124.040894 
	C795.284180,116.785980 790.588379,112.223328 785.914673,107.495056 
	C787.815186,107.126198 789.373962,107.135750 791.031860,107.483604 
	C793.063599,109.619316 794.774231,109.885803 796.508850,107.089203 
	C812.331055,107.135132 827.687927,107.201233 843.029358,107.608749 
	C838.973450,113.200783 834.965393,118.476860 830.883362,123.694977 
	C826.621155,129.143417 822.287659,134.536087 817.599670,139.801147 
	C808.808655,139.758942 800.403259,139.868713 791.598755,139.991913 
	C789.378967,140.293549 787.558350,140.581772 785.737732,140.869995 
z"/>
<path fill="#0842A7" opacity="1.000000" stroke="none" 
	d="
M524.797791,122.686836 
	C531.593994,118.389618 538.138977,113.611885 545.240356,109.898193 
	C558.774963,102.820190 573.057068,97.578720 588.372314,95.100502 
	C583.357422,102.380165 570.484802,100.559708 569.319885,112.203720 
	C569.306946,112.333229 569.100952,112.442680 568.986511,112.562920 
	C566.244019,115.444466 563.945435,119.119873 560.628662,120.973969 
	C557.601624,122.666122 553.466858,122.769951 549.830505,122.726028 
	C548.814087,122.713753 548.051392,119.496712 546.769165,118.127235 
	C545.719421,117.006149 543.964172,115.636604 542.708679,115.801376 
	C539.855774,116.175758 536.107544,115.995216 536.191040,120.806793 
	C536.203491,121.523636 533.994873,122.792480 532.729980,122.910873 
	C530.115784,123.155548 527.445190,122.797165 524.797791,122.686836 
z"/>
<path fill="#086CD7" opacity="1.000000" stroke="none" 
	d="
M524.834106,122.720314 
	C527.445190,122.797165 530.115784,123.155548 532.729980,122.910873 
	C533.994873,122.792480 536.203491,121.523636 536.191040,120.806793 
	C536.107544,115.995216 539.855774,116.175758 542.708679,115.801376 
	C543.964172,115.636604 545.719421,117.006149 546.769165,118.127235 
	C548.051392,119.496712 548.814087,122.713753 549.830505,122.726028 
	C553.466858,122.769951 557.601624,122.666122 560.628662,120.973969 
	C563.945435,119.119873 566.244019,115.444466 568.986511,112.562920 
	C569.100952,112.442680 569.306946,112.333229 569.319885,112.203720 
	C570.484802,100.559708 583.357422,102.380165 588.788330,95.152580 
	C589.514954,94.641708 590.052246,94.411629 591.104553,94.462524 
	C592.079651,95.492592 592.539673,96.241676 592.999695,96.990761 
	C584.302856,105.838676 575.449585,114.540398 566.981201,123.601837 
	C563.261780,127.581703 559.296814,129.279068 553.830017,129.067963 
	C544.024841,128.689301 534.194153,129.008972 524.375122,128.919495 
	C522.630188,128.903580 520.890381,128.319199 518.913208,127.586365 
	C519.882568,126.114853 521.086975,125.054291 522.928955,123.937340 
	C524.020325,123.478493 524.474121,123.076035 524.927979,122.673569 
	C524.927979,122.673569 524.870422,122.753799 524.834106,122.720314 
M573.575684,106.133041 
	C573.697937,107.637894 573.820190,109.142746 573.942505,110.647591 
	C575.142639,110.390335 576.766663,110.516693 577.435120,109.778183 
	C578.260315,108.866508 578.291016,107.235703 578.665039,105.915657 
	C577.158020,105.799736 575.651001,105.683823 573.575684,106.133041 
z"/>
<path fill="#072C99" opacity="1.000000" stroke="none" 
	d="
M551.566406,140.992874 
	C552.108887,141.512756 552.229980,142.052765 552.392883,143.089706 
	C544.630493,148.720795 536.826294,153.854965 529.025085,158.620911 
	C530.018982,156.842163 530.799622,155.200531 532.043884,154.068405 
	C534.783325,151.575836 537.756836,149.340485 541.576294,146.234619 
	C536.313660,146.949219 532.452209,147.896286 528.583374,147.927658 
	C509.129059,148.085449 489.672791,148.028381 470.217468,147.965042 
	C468.481354,147.959396 466.747131,147.367859 465.301971,146.749939 
	C474.364166,146.301514 483.136139,146.128571 491.908875,146.015808 
	C494.488190,145.982651 497.075348,146.127975 496.989166,142.412445 
	C503.345459,137.863800 509.701721,133.315140 516.399292,128.723083 
	C515.778748,130.360962 515.066345,132.268158 513.796143,133.669998 
	C511.945984,135.711929 509.694397,137.390152 507.968262,139.900650 
	C522.613525,141.353928 536.827942,143.459015 551.566406,140.992874 
z"/>
<path fill="#064DCF" opacity="1.000000" stroke="none" 
	d="
M713.036560,123.977127 
	C711.948975,122.495979 711.028137,120.848473 709.744995,119.562431 
	C704.116516,113.921043 698.313965,108.451752 692.752563,102.746452 
	C691.766113,101.734421 691.625305,99.898018 691.172485,98.039734 
	C694.108276,98.772995 696.965576,99.908806 700.038452,101.665970 
	C700.852051,102.836197 701.450073,103.385078 702.173462,104.249939 
	C705.884460,108.029327 709.470093,111.492744 713.197571,115.254860 
	C715.904907,118.025566 718.470398,120.497574 721.169067,123.275131 
	C723.183472,125.071259 725.064758,126.561844 727.085205,128.321442 
	C727.840088,129.387283 728.455750,130.184128 729.201172,131.293060 
	C730.600037,132.643433 732.217834,133.453232 733.075317,134.761398 
	C736.051636,139.302277 740.132568,140.511627 745.339966,140.103943 
	C750.536560,139.697098 755.787048,139.977631 761.383789,139.987610 
	C762.495605,140.021179 763.237610,140.023987 764.168823,140.283081 
	C764.970398,140.655472 765.582764,140.771576 766.195068,140.887680 
	C766.195068,140.887680 766.068298,140.944489 766.177917,141.126312 
	C766.609192,141.473587 766.930847,141.639053 767.252502,141.804535 
	C768.000488,142.076172 768.748535,142.347794 769.761475,143.147919 
	C770.028137,144.141296 770.029785,144.606186 769.559692,145.061356 
	C762.733948,145.084076 756.380005,145.116516 749.564209,145.106964 
	C745.068665,145.373886 741.034973,145.682800 736.751343,145.986969 
	C736.334717,145.983337 736.168091,145.984467 735.870728,145.706345 
	C734.823669,144.625443 733.907349,143.823792 732.847900,142.726196 
	C731.477783,141.607681 730.250793,140.785126 729.019165,139.721344 
	C729.014587,139.480148 728.990234,138.998260 728.951294,138.679367 
	C728.277649,137.902374 727.642944,137.444275 726.980103,136.692627 
	C726.627319,135.938309 726.302673,135.477554 725.837952,134.712601 
	C724.129395,133.918503 722.560913,133.428604 720.990234,132.946045 
	C720.987976,132.953369 720.972900,132.947662 720.764282,132.928238 
	C720.555664,132.908813 720.136658,132.919067 720.113525,132.604950 
	C720.087402,131.870163 720.084473,131.449478 720.290894,130.839874 
	C720.000183,130.102158 719.500061,129.553360 718.875732,128.741837 
	C718.160095,128.009598 717.568665,127.540085 716.839478,126.770706 
	C715.479980,125.639610 714.258240,124.808372 713.036560,123.977127 
z"/>
<path fill="#053FC9" opacity="1.000000" stroke="none" 
	d="
M785.369324,140.843628 
	C787.558350,140.581772 789.378967,140.293549 791.896606,140.285522 
	C799.396240,140.707336 806.198486,140.870621 813.001587,140.979752 
	C815.668701,141.022552 818.337830,140.944061 821.399170,140.922409 
	C822.837402,140.862900 823.882446,140.801819 825.375427,140.819794 
	C828.221985,140.872345 830.620544,140.845825 833.484131,140.859467 
	C839.632385,140.913696 845.315796,140.927765 851.290894,141.243866 
	C863.882446,141.795609 876.182312,142.045303 889.199646,142.309570 
	C888.024841,143.747681 887.511169,144.376495 886.529053,145.006058 
	C862.367249,145.080429 838.674011,145.154068 814.563965,145.167191 
	C812.466675,145.143173 810.786133,145.179657 808.669922,145.150635 
	C806.490723,145.104004 804.747070,145.122864 802.535767,145.111084 
	C794.705261,145.073181 787.342590,145.065948 779.830139,144.808060 
	C779.120178,144.030685 778.559998,143.503967 777.999756,142.977234 
	C780.333496,142.257248 782.667175,141.537262 785.369324,140.843628 
z"/>
<path fill="#001C8F" opacity="1.000000" stroke="none" 
	d="
M496.600403,142.395630 
	C497.075348,146.127975 494.488190,145.982651 491.908875,146.015808 
	C483.136139,146.128571 474.364166,146.301514 464.882843,146.741058 
	C460.116913,147.676361 456.060028,148.322067 451.789154,148.715652 
	C450.716980,148.306061 449.858826,148.148621 448.698853,147.704468 
	C442.583069,147.298187 436.769104,147.178619 430.593903,147.045441 
	C428.503937,146.323883 426.775208,145.615921 425.033569,144.680923 
	C425.020660,144.453888 424.996948,143.999710 425.224121,143.712585 
	C427.635925,142.946121 429.820557,142.466766 432.433472,141.989471 
	C434.575958,141.981476 436.290192,141.971405 438.004395,141.961319 
	C444.539612,141.971329 451.074829,141.982468 457.610046,141.990646 
	C459.430237,141.992920 461.250458,141.986496 463.832397,142.274323 
	C470.996857,143.421402 477.336731,143.602707 483.400452,140.815186 
	C487.670868,141.336395 491.941254,141.857605 496.600403,142.395630 
z"/>
<path fill="#0B3189" opacity="1.000000" stroke="none" 
	d="
M763.979553,140.026779 
	C763.237610,140.023987 762.495605,140.021179 761.335510,139.638992 
	C758.396851,137.050156 755.924561,134.782547 753.343506,132.646255 
	C750.236450,130.074646 747.202515,127.357430 743.826599,125.185631 
	C740.992920,123.362617 738.279968,123.915863 736.252319,127.077507 
	C734.645508,129.582825 732.680542,131.928741 729.071411,130.980988 
	C728.455750,130.184128 727.840088,129.387283 727.115784,128.057068 
	C727.022949,127.347404 727.038818,127.171104 727.385742,126.937943 
	C730.048401,124.663582 732.380005,122.446091 735.788269,119.204674 
	C726.431213,114.597832 717.715576,110.306824 708.785400,105.761047 
	C706.396545,104.982170 704.222290,104.458061 702.048035,103.933960 
	C701.450073,103.385078 700.852051,102.836197 700.203979,101.980469 
	C710.132385,102.202156 718.349121,107.496765 726.844177,111.830147 
	C740.476013,118.783760 752.529419,128.055191 763.906189,138.916656 
	C763.986816,139.676071 763.983215,139.851425 763.979553,140.026779 
z"/>
<path fill="#0455DA" opacity="1.000000" stroke="none" 
	d="
M790.932678,107.145294 
	C789.373962,107.135750 787.815186,107.126198 785.786682,107.158630 
	C784.042664,106.173203 782.768433,105.145798 781.405029,103.812332 
	C791.749390,103.315613 802.182617,103.099937 812.616699,102.946022 
	C817.095947,102.879944 821.577026,102.942123 826.771484,103.229790 
	C829.326721,103.326866 831.167847,103.140190 833.008911,102.953514 
	C833.448608,102.949158 833.888245,102.944809 834.952271,103.245377 
	C840.666748,103.782646 845.756897,104.014992 850.831970,104.246651 
	C850.150879,105.228287 849.558533,106.082153 848.543823,106.968369 
	C846.429199,107.089592 844.737000,107.178467 843.044739,107.267334 
	C827.687927,107.201233 812.331055,107.135132 796.084106,107.045654 
	C793.773560,107.063293 792.353088,107.104294 790.932678,107.145294 
z"/>
<path fill="#072C99" opacity="1.000000" stroke="none" 
	d="
M483.179138,140.540070 
	C477.336731,143.602707 470.996857,143.421402 464.299469,142.270294 
	C468.171448,141.450180 472.338104,140.924393 476.650604,140.380188 
	C474.948456,136.626083 472.924561,133.137894 471.772675,129.382233 
	C470.229950,124.352318 470.501740,119.335129 474.804138,115.149200 
	C471.373383,128.083313 473.484802,134.908585 483.179138,140.540070 
z"/>
<path fill="#2159B0" opacity="1.000000" stroke="none" 
	d="
M611.293152,52.371529 
	C610.664246,51.327282 610.357971,50.421497 610.035583,49.468246 
	C614.999207,46.164104 614.925171,46.237648 618.980530,50.319481 
	C619.683044,51.026508 621.307983,50.977581 622.512085,50.999886 
	C631.113464,51.159225 639.716125,51.246510 648.635132,51.613228 
	C638.815918,51.927608 628.679810,51.959789 618.544373,52.062778 
	C616.233521,52.086258 613.925232,52.354431 611.293152,52.371529 
z"/>
<path fill="#E8EEF3" opacity="1.000000" stroke="none" 
	d="
M631.011414,54.991245 
	C626.400574,54.991341 622.286316,54.991341 618.171997,54.991341 
	C618.174316,54.756172 618.176697,54.521004 618.179016,54.285835 
	C627.994385,54.285835 637.809753,54.285835 647.625122,54.285835 
	C647.636658,54.520939 647.648193,54.756046 647.659729,54.991150 
	C642.275818,54.991150 636.891846,54.991150 631.011414,54.991245 
z"/>
<path fill="#0332AA" opacity="1.000000" stroke="none" 
	d="
M777.670410,142.866714 
	C778.559998,143.503967 779.120178,144.030685 779.600220,145.084473 
	C777.346680,146.074371 775.173340,146.537186 772.813538,146.756317 
	C771.761841,146.032104 770.896667,145.551590 770.031494,145.071075 
	C770.029785,144.606186 770.028137,144.141296 770.029663,143.327698 
	C771.340698,143.189148 772.648499,143.399323 774.563171,143.693329 
	C775.444641,143.517960 775.719299,143.258759 775.994019,142.999588 
	C776.443054,142.918457 776.892090,142.837311 777.670410,142.866714 
z"/>
<path fill="#0842A7" opacity="1.000000" stroke="none" 
	d="
M593.398071,96.993401 
	C592.539673,96.241676 592.079651,95.492592 591.390137,94.580399 
	C592.229919,94.050270 593.299194,93.683243 594.699280,93.402054 
	C596.756104,93.993134 598.482178,94.498383 600.597412,95.005951 
	C598.589905,95.670868 596.193115,96.333450 593.398071,96.993401 
z"/>
<path fill="#2159B0" opacity="1.000000" stroke="none" 
	d="
M598.633545,52.030396 
	C598.943115,52.406639 598.983704,52.811924 599.004395,53.522209 
	C598.496155,53.979218 598.007812,54.131226 597.277832,54.168510 
	C597.479004,53.389008 597.921753,52.724216 598.633545,52.030396 
z"/>
<path fill="#E8EEF3" opacity="1.000000" stroke="none" 
	d="
M655.833130,54.719540 
	C654.734619,54.896889 653.853455,54.896889 652.972229,54.896889 
	C652.994080,54.608841 653.015930,54.320797 653.037781,54.032749 
	C654.041992,54.202564 655.046204,54.372379 655.833130,54.719540 
z"/>
<path fill="#043898" opacity="1.000000" stroke="none" 
	d="
M764.168823,140.283081 
	C763.983215,139.851425 763.986816,139.676071 763.999207,139.237793 
	C764.676270,139.407654 765.344543,139.840439 766.103882,140.580444 
	C765.582764,140.771576 764.970398,140.655472 764.168823,140.283081 
z"/>
<path fill="#2159B0" opacity="1.000000" stroke="none" 
	d="
M606.396973,53.113777 
	C606.963806,52.723782 607.898254,52.440338 609.216187,52.274681 
	C608.654602,52.668423 607.709656,52.944374 606.396973,53.113777 
z"/>
<path fill="#0842A7" opacity="1.000000" stroke="none" 
	d="
M524.598572,122.665863 
	C524.474121,123.076035 524.020325,123.478493 523.244629,123.921738 
	C523.371582,123.527740 523.820374,123.092949 524.598572,122.665863 
z"/>
<path fill="#053FC9" opacity="1.000000" stroke="none" 
	d="
M775.674194,142.931305 
	C775.719299,143.258759 775.444641,143.517960 774.884033,143.822510 
	C774.850159,143.532913 775.102295,143.197983 775.674194,142.931305 
z"/>
<path fill="#043898" opacity="1.000000" stroke="none" 
	d="
M767.157715,141.532959 
	C766.930847,141.639053 766.609192,141.473587 766.159424,141.059357 
	C766.375122,140.960861 766.718994,141.111130 767.157715,141.532959 
z"/>
<path fill="#072C99" opacity="1.000000" stroke="none" 
	d="
M517.709595,127.696327 
	C517.794556,128.003510 517.541077,128.330429 516.980774,128.738968 
	C516.906311,128.452408 517.138733,128.084244 517.709595,127.696327 
z"/>
<path fill="#072C99" opacity="1.000000" stroke="none" 
	d="
M475.371887,115.116867 
	C475.135681,114.858223 475.259949,114.578110 475.670044,114.206696 
	C475.784302,114.398964 475.709808,114.725632 475.371887,115.116867 
z"/>
<path fill="#012073" opacity="1.000000" stroke="none" 
	d="
M1015.044067,377.990448 
	C1015.406433,378.756683 1016.275574,379.806305 1016.035461,380.235657 
	C1015.332458,381.492889 1014.187683,382.503113 1013.899658,382.831207 
	C1015.098083,385.602539 1017.165649,388.096771 1016.643738,389.795349 
	C1016.078186,391.636047 1013.099182,392.735229 1010.626770,394.561035 
	C1010.399719,399.083618 1005.040649,401.806732 998.359436,399.509552 
	C998.186584,405.347168 993.889771,410.995056 998.634155,417.297302 
	C999.535522,421.919739 1002.019409,424.020996 1005.983337,425.131409 
	C1007.412903,425.531830 1008.437500,427.377869 1009.646912,428.564209 
	C1008.474548,429.420197 1007.419067,430.618866 1006.107361,431.066589 
	C1002.490234,432.301147 1000.742493,434.402649 1000.932983,438.429810 
	C1001.199280,444.059723 1000.712158,449.730774 1001.084717,455.348572 
	C1001.426270,460.499084 1000.764282,464.539795 994.748962,465.531738 
	C994.649170,465.548218 994.637207,466.097656 994.542175,466.637512 
	C996.187134,467.352264 997.869690,468.083344 999.552307,468.814453 
	C996.074280,470.305145 992.697632,470.498505 989.385986,471.068817 
	C987.858521,471.331818 986.458069,472.331757 985.000000,472.997559 
	C985.000000,472.997559 984.502380,473.002258 984.253662,473.004791 
	C976.200562,473.004517 968.377808,472.663910 960.596680,473.087158 
	C951.524658,473.580597 945.315002,469.578949 940.457520,462.357178 
	C939.453125,460.863953 937.308044,460.138000 935.339050,458.685974 
	C934.995239,457.878693 935.001099,457.443848 935.173523,456.715515 
	C938.212769,453.935181 941.085388,451.448364 944.314880,448.939026 
	C949.449951,443.614532 954.228149,438.312531 959.161377,432.721405 
	C960.878357,430.955261 962.440369,429.478241 964.341125,427.966461 
	C965.455322,426.957062 966.230896,425.982422 967.265625,424.877045 
	C968.021423,424.167908 968.518005,423.589539 969.357178,422.973022 
	C974.729431,418.416168 979.901733,414.044556 984.754761,409.343384 
	C991.625061,402.687988 998.259705,395.789398 1004.998535,389.379303 
	C1005.676819,390.771332 1006.351746,391.778839 1007.026672,392.786346 
	C1007.560608,392.250488 1008.094604,391.714600 1008.628540,391.178772 
	C1007.417358,390.451294 1006.206238,389.723816 1005.141907,388.697937 
	C1008.540466,384.929779 1011.792236,381.460114 1015.044067,377.990448 
z"/>
<path fill="#011B6C" opacity="1.000000" stroke="none" 
	d="
M985.468628,473.000092 
	C986.458069,472.331757 987.858521,471.331818 989.385986,471.068817 
	C992.697632,470.498505 996.074280,470.305145 999.552307,468.814453 
	C997.869690,468.083344 996.187134,467.352264 994.542175,466.637512 
	C994.637207,466.097656 994.649170,465.548218 994.748962,465.531738 
	C1000.764282,464.539795 1001.426270,460.499084 1001.084717,455.348572 
	C1000.712158,449.730774 1001.199280,444.059723 1000.932983,438.429810 
	C1000.742493,434.402649 1002.490234,432.301147 1006.107361,431.066589 
	C1007.419067,430.618866 1008.474548,429.420197 1009.646912,428.564209 
	C1008.437500,427.377869 1007.412903,425.531830 1005.983337,425.131409 
	C1002.019409,424.020996 999.535522,421.919739 999.002930,417.378448 
	C998.995117,417.004883 999.000000,417.000000 999.363403,417.064209 
	C1002.708557,420.741180 1006.825256,420.956543 1010.507996,419.431976 
	C1014.060364,417.961395 1016.259155,415.022278 1016.224915,410.396667 
	C1016.206177,407.859131 1017.922180,404.728119 1019.834167,402.872742 
	C1024.401611,398.440399 1029.248047,394.012970 1036.668335,395.888489 
	C1037.955322,396.213745 1039.548340,395.327759 1041.000000,395.001221 
	C1049.649658,395.002441 1058.309082,394.773804 1066.943115,395.142303 
	C1069.598633,395.255615 1073.553711,396.295227 1074.510254,398.150085 
	C1076.354858,401.727142 1074.994385,405.767578 1071.898438,408.824554 
	C1064.618042,416.013245 1057.327026,423.191895 1050.109619,430.443420 
	C1044.043457,436.538147 1038.113892,442.768524 1032.052246,448.867737 
	C1026.227661,454.728394 1020.069702,460.277771 1014.514648,466.379486 
	C1010.325073,470.981354 1005.971313,473.889252 999.398743,473.104370 
	C994.970703,472.575592 990.428406,473.002960 985.468628,473.000092 
z"/>
<path fill="#010B64" opacity="1.000000" stroke="none" 
	d="
M1041.000000,394.573029 
	C1039.548340,395.327759 1037.955322,396.213745 1036.668335,395.888489 
	C1029.248047,394.012970 1024.401611,398.440399 1019.834167,402.872742 
	C1017.922180,404.728119 1016.206177,407.859131 1016.224915,410.396667 
	C1016.259155,415.022278 1014.060364,417.961395 1010.507996,419.431976 
	C1006.825256,420.956543 1002.708557,420.741180 999.365845,417.062378 
	C999.002441,415.167908 999.078674,413.335297 998.982178,411.511841 
	C998.740540,406.945129 1000.546631,404.421875 1005.377930,403.864716 
	C1010.880920,403.230011 1015.164856,400.899689 1018.823364,396.042572 
	C1023.933655,389.258270 1032.280762,389.218719 1040.555420,389.005737 
	C1041.000000,390.720642 1041.000000,392.432709 1041.000000,394.573029 
z"/>
<path fill="#0E80D9" opacity="1.000000" stroke="none" 
	d="
M570.900452,547.596191 
	C565.972290,547.707825 566.320190,545.025513 568.472046,541.405701 
	C570.356079,539.706482 571.677246,538.359253 572.998047,537.004761 
	C572.997742,536.997559 573.012390,536.998901 573.333984,536.976929 
	C574.436279,536.293091 575.216919,535.631287 576.466187,534.951843 
	C584.918152,534.919495 592.901489,534.904785 601.275818,534.933960 
	C605.752258,535.037781 607.751953,532.494995 609.029114,529.042419 
	C609.015991,529.017761 609.066589,529.041504 609.377197,528.987915 
	C610.134399,528.304199 610.580933,527.674011 611.314514,526.867554 
	C613.424805,524.809448 615.248108,522.927490 617.387939,521.005676 
	C618.450317,520.279114 619.196106,519.592407 620.192322,518.714600 
	C621.485718,517.291809 622.528625,516.060120 623.877808,514.741455 
	C624.479797,514.117188 624.775513,513.579834 625.381104,512.988464 
	C626.136230,512.303833 626.581604,511.673248 627.201477,510.787292 
	C628.543274,509.660858 629.710632,508.789795 631.187744,507.818176 
	C632.015625,506.837830 632.533752,505.958069 633.034912,505.051880 
	C633.017944,505.025452 633.075623,505.050507 633.384521,504.995605 
	C634.143372,504.314545 634.593323,503.688385 635.325562,502.905334 
	C636.761108,501.513885 637.914368,500.279327 639.376099,498.995972 
	C640.143005,498.330017 640.601440,497.712891 641.377563,496.996979 
	C643.148560,495.279388 644.601868,493.660583 646.404907,492.031677 
	C647.452332,492.014984 648.150085,492.008392 649.224670,492.009766 
	C650.098572,492.092194 650.595520,492.166687 651.011841,492.559937 
	C644.395142,499.145172 637.728821,505.283173 631.375671,511.729828 
	C627.960388,515.195374 625.082581,519.190613 621.962219,522.946838 
	C621.065918,523.778870 620.169678,524.610962 618.781860,525.743652 
	C616.880005,527.393005 615.469604,528.741638 614.059204,530.090271 
	C613.128357,531.203857 612.197449,532.317444 610.750183,533.743286 
	C606.503174,538.166504 601.668823,538.152405 596.605164,538.112671 
	C591.070190,538.069214 585.529968,538.711975 579.992004,539.055542 
	C576.986755,541.789124 573.981506,544.522705 570.900452,547.596191 
z"/>
<path fill="#0540BE" opacity="1.000000" stroke="none" 
	d="
M713.024658,491.545135 
	C722.618774,490.281281 727.212585,493.698914 726.986450,501.488251 
	C726.852722,506.096039 723.530884,509.152100 717.040833,509.711334 
	C713.357544,510.028748 710.127136,509.144867 708.814941,504.487701 
	C706.839172,497.475922 707.133545,495.430847 713.024658,491.545135 
M720.486511,502.751892 
	C721.528992,500.095001 721.392944,497.568695 718.279358,496.821136 
	C716.890930,496.487762 714.408691,497.279877 713.727234,498.373749 
	C713.012207,499.521454 713.348389,502.747711 714.129700,503.108887 
	C715.778442,503.871094 718.017395,503.356628 720.486511,502.751892 
z"/>
<path fill="#0974E0" opacity="1.000000" stroke="none" 
	d="
M705.022766,469.171387 
	C700.347534,469.185272 695.672302,469.199188 690.076660,469.173950 
	C684.811340,469.138123 680.466370,469.141479 675.749390,469.108704 
	C671.577209,473.036530 667.776978,477.000458 663.976746,480.964417 
	C663.976746,480.964447 663.997620,480.999084 663.640503,481.027069 
	C662.211731,482.391022 661.140076,483.726990 660.068420,485.062927 
	C660.068420,485.062927 660.014587,485.047974 659.778931,485.187805 
	C658.987793,485.859375 658.432373,486.391113 657.885498,486.582336 
	C657.573303,485.443359 657.252441,484.644928 656.944458,483.498688 
	C656.983765,482.452057 657.010132,481.753235 657.363403,480.974426 
	C659.484741,478.943726 661.279236,476.993011 663.380249,474.993652 
	C664.145447,474.334747 664.604065,473.724487 665.066467,473.091797 
	C665.070312,473.069305 665.115540,473.064056 665.447205,473.010590 
	C667.196106,471.320831 668.613403,469.684509 670.249390,468.011902 
	C670.468079,467.975555 670.908386,467.922852 671.236755,467.908539 
	C672.046448,467.267487 672.527771,466.640747 673.428589,465.977173 
	C678.754578,465.646606 683.672058,465.466492 688.561279,464.993469 
	C690.135864,464.841156 691.635864,463.917297 693.532593,463.350952 
	C696.578857,463.955322 699.262329,464.555115 702.023254,465.484863 
	C703.074646,466.933655 704.048706,468.052521 705.022766,469.171387 
z"/>
<path fill="#0461C6" opacity="1.000000" stroke="none" 
	d="
M657.876953,486.922821 
	C658.432373,486.391113 658.987793,485.859375 659.803284,485.187073 
	C660.086304,490.114899 659.810425,494.671112 652.941406,495.354523 
	C650.823425,495.565247 648.728210,497.858582 646.975708,499.561707 
	C640.198303,506.148224 633.585388,512.903748 626.856018,519.540405 
	C625.585205,520.793701 624.062744,521.791870 622.309326,522.927246 
	C625.082581,519.190613 627.960388,515.195374 631.375671,511.729828 
	C637.728821,505.283173 644.395142,499.145172 651.395264,492.485229 
	C655.527588,492.304077 657.415161,490.443359 657.876953,486.922821 
z"/>
<path fill="#065DD2" opacity="1.000000" stroke="none" 
	d="
M772.945068,469.157349 
	C762.942627,469.177551 752.940247,469.197754 742.478149,469.324158 
	C740.630188,469.317017 739.242004,469.203735 737.427612,468.688110 
	C736.993835,467.534546 736.986328,466.783264 737.449585,466.012207 
	C749.612732,465.996918 761.305115,466.001404 772.998901,466.398315 
	C772.981873,467.579590 772.963501,468.368469 772.945068,469.157349 
z"/>
<path fill="#0461C6" opacity="1.000000" stroke="none" 
	d="
M580.304321,539.327881 
	C585.529968,538.711975 591.070190,538.069214 596.605164,538.112671 
	C601.668823,538.152405 606.503174,538.166504 610.586304,534.008728 
	C610.071960,538.423035 606.488647,539.713623 602.854980,539.836426 
	C595.453308,540.086670 588.030640,539.715393 580.304321,539.327881 
z"/>
<path fill="#0974E0" opacity="1.000000" stroke="none" 
	d="
M736.978821,466.032013 
	C736.986328,466.783264 736.993835,467.534546 737.009155,468.662659 
	C736.265930,469.048462 735.514954,469.057373 734.102356,468.765503 
	C728.965576,468.670929 724.490356,468.877167 719.636292,469.072998 
	C718.499512,469.067871 717.741516,469.073181 716.713989,468.783203 
	C713.671570,468.245789 710.898682,468.003662 708.020203,467.752289 
	C708.367981,466.895111 708.719849,466.027771 709.492065,465.126343 
	C711.610291,465.072327 713.308289,465.052368 715.237671,465.075348 
	C715.469116,465.118286 715.936890,465.171112 716.057495,465.528961 
	C719.117554,465.934387 722.057068,465.981964 725.457275,466.031921 
	C729.604919,466.033539 733.291870,466.032776 736.978821,466.032013 
z"/>
<path fill="#003CB2" opacity="1.000000" stroke="none" 
	d="
M676.121399,469.144867 
	C680.466370,469.141479 684.811340,469.138123 689.620972,469.238464 
	C688.859802,470.225647 687.720520,471.686096 686.392090,471.885284 
	C682.253113,472.506042 678.042480,472.649231 673.578552,472.698059 
	C674.238770,471.327271 675.180054,470.236053 676.121399,469.144867 
z"/>
<path fill="#0461C6" opacity="1.000000" stroke="none" 
	d="
M709.071777,465.160431 
	C708.719849,466.027771 708.367981,466.895111 708.020203,467.752289 
	C710.898682,468.003662 713.671570,468.245789 716.467896,469.034302 
	C715.316833,469.744324 714.142273,469.908020 712.967773,470.071716 
	C710.514771,469.940308 708.061707,469.808899 705.315735,469.424438 
	C704.048706,468.052521 703.074646,466.933655 702.354492,465.298462 
	C704.069519,464.534546 705.530762,464.286987 707.175964,464.281921 
	C707.930603,464.736450 708.501160,464.948456 709.071777,465.160431 
z"/>
<path fill="#0461C6" opacity="1.000000" stroke="none" 
	d="
M675.749390,469.108704 
	C675.180054,470.236053 674.238770,471.327271 673.157471,472.710327 
	C670.234741,475.642273 667.451965,478.282440 664.322998,480.943481 
	C667.776978,477.000458 671.577209,473.036530 675.749390,469.108704 
z"/>
<path fill="#0461C6" opacity="1.000000" stroke="none" 
	d="
M720.015137,469.083374 
	C724.490356,468.877167 728.965576,468.670929 733.727173,468.771851 
	C730.653992,469.401245 727.294495,469.723541 723.149658,470.029785 
	C721.581360,469.703644 720.798218,469.393494 720.015137,469.083374 
z"/>
<path fill="#003CB2" opacity="1.000000" stroke="none" 
	d="
M719.636292,469.072968 
	C720.798218,469.393494 721.581360,469.703644 722.699402,470.041626 
	C719.999451,473.586365 716.600281,472.323273 713.075317,470.445923 
	C714.142273,469.908020 715.316833,469.744324 716.737427,469.329529 
	C717.741516,469.073181 718.499512,469.067871 719.636292,469.072968 
z"/>
<path fill="#0461C6" opacity="1.000000" stroke="none" 
	d="
M614.417358,530.065735 
	C615.469604,528.741638 616.880005,527.393005 618.621460,526.005981 
	C617.560242,527.325500 616.167847,528.683350 614.417358,530.065735 
z"/>
<path fill="#0461C6" opacity="1.000000" stroke="none" 
	d="
M660.403564,485.011719 
	C661.140076,483.726990 662.211731,482.391022 663.623657,481.015747 
	C662.888977,482.304474 661.813843,483.632507 660.403564,485.011719 
z"/>
<path fill="#012E8B" opacity="1.000000" stroke="none" 
	d="
M801.971313,556.024353 
	C802.240356,556.505310 802.509399,556.986267 802.634460,557.725342 
	C802.322205,557.987366 802.153992,557.991272 801.560791,557.995117 
	C799.436951,557.988159 797.738037,557.981140 796.030212,557.567627 
	C795.016785,555.784485 794.185791,553.503479 792.962097,553.268738 
	C791.552246,552.998291 789.795593,554.535095 788.189575,555.287048 
	C788.189575,555.287048 788.147156,555.182251 787.860840,555.163208 
	C787.040039,555.755615 786.505615,556.367065 785.580200,556.982056 
	C784.115051,556.255310 782.555420,555.767029 782.049866,554.753235 
	C779.683777,550.008423 776.284912,547.947998 770.758301,547.789612 
	C766.286987,547.661438 762.065125,551.418640 757.461609,548.517761 
	C757.249390,548.384094 755.847717,550.137939 754.727966,550.816162 
	C753.938843,550.244385 753.094238,549.447449 752.953552,549.556763 
	C750.623779,551.366638 751.987915,556.871887 754.994263,558.337036 
	C754.998108,559.111938 755.000244,559.554749 755.002441,559.997559 
	C755.002441,559.997559 755.000000,560.000000 754.656250,560.005615 
	C753.208252,560.673279 752.104126,561.335449 750.996704,561.529175 
	C750.991760,550.362854 750.990112,539.665039 750.997437,528.968262 
	C751.006348,528.969360 751.008118,528.935608 751.008118,528.935608 
	C753.935547,528.563782 755.346069,527.134644 756.329529,524.072998 
	C757.666748,519.910583 760.530823,516.265930 762.244263,512.190857 
	C764.917114,505.834015 771.049622,500.693695 769.164734,492.606750 
	C768.947327,491.673798 770.749634,489.285706 771.691162,489.243103 
	C775.154541,489.086212 777.079712,487.891724 776.958618,484.250488 
	C776.817261,480.000549 773.218628,481.491821 771.000366,480.688965 
	C770.850525,480.634705 770.730408,480.498169 770.289551,480.174622 
	C771.570007,477.262573 768.036743,473.187958 773.121216,470.449219 
	C774.104126,470.096313 774.559814,470.090118 775.283875,470.345581 
	C777.701050,470.380188 779.849854,470.153137 782.376160,469.933289 
	C783.507141,469.986023 784.260559,470.031586 785.014465,470.466492 
	C786.342468,473.240509 787.670044,475.625122 789.056885,478.399658 
	C789.940369,479.501221 790.740112,480.792572 791.592773,480.828491 
	C796.414246,481.031433 801.250732,481.006348 806.075562,480.853058 
	C806.737793,480.832001 807.363708,479.663879 808.449951,479.022217 
	C811.259216,479.020477 813.624939,479.023346 815.990723,479.026215 
	C817.292175,479.684326 818.548096,480.766052 819.904663,480.912018 
	C822.694641,481.212189 825.596985,480.678558 828.335083,481.159760 
	C829.680786,481.396271 831.776184,483.122467 831.785583,484.189789 
	C831.821899,488.334900 832.647461,492.396729 829.890808,496.592010 
	C827.789673,499.789764 827.366699,503.949463 824.266235,507.100708 
	C822.491577,508.904541 820.789307,510.405701 818.818909,511.951721 
	C816.773743,513.556396 815.646790,516.948975 815.143799,519.744202 
	C814.525085,523.182007 815.985657,527.195862 810.734436,528.357483 
	C809.923523,528.536804 809.250916,530.739502 809.076965,532.079529 
	C808.801514,534.201721 808.951660,536.386902 809.010315,538.543030 
	C809.124023,542.724426 808.628845,545.905029 802.950867,544.195984 
	C801.690552,543.816650 799.838440,545.403076 798.258789,546.084351 
	C798.962097,547.614075 799.665405,549.143860 800.644897,551.191101 
	C801.271179,553.147217 801.621277,554.585754 801.971313,556.024353 
M800.982239,512.610535 
	C802.226257,510.872833 804.290466,509.200134 804.435059,507.375305 
	C804.536865,506.091064 801.712524,503.255005 800.751526,503.480865 
	C798.743530,503.952850 795.960632,505.733215 795.573303,507.445892 
	C794.948730,510.207886 796.493958,512.894470 800.982239,512.610535 
M817.565491,503.098999 
	C817.073853,502.179932 816.582214,501.260864 816.109741,500.377594 
	C813.768555,503.901245 811.553101,507.079895 809.544495,510.384308 
	C809.119812,511.082977 809.282532,512.286926 809.516968,513.166809 
	C809.613892,513.530579 810.621643,513.874817 811.199463,513.850891 
	C814.795715,513.701782 819.085144,507.174377 817.565491,503.098999 
z"/>
<path fill="#064DCF" opacity="1.000000" stroke="none" 
	d="
M775.015442,470.083923 
	C774.559814,470.090118 774.104126,470.096313 773.311584,470.166138 
	C772.967163,470.050568 772.959595,469.871368 772.948547,469.424744 
	C772.963501,468.368469 772.981873,467.579590 773.002441,466.130096 
	C773.003601,465.290710 773.002747,465.111938 773.408264,464.946899 
	C775.894836,464.688416 777.975037,464.416168 780.335632,464.277649 
	C781.409912,464.604340 782.203735,464.797333 783.465820,465.007935 
	C789.960388,464.996887 795.986633,464.968231 802.480347,464.958740 
	C821.619934,465.042847 840.291931,465.107758 858.973755,465.552185 
	C858.986511,466.954468 858.989563,467.977234 858.524475,469.001343 
	C840.677063,469.007996 823.297363,468.962341 805.919250,469.100555 
	C804.253296,469.113831 802.596375,470.272461 800.487671,470.919098 
	C797.022583,470.966797 794.005005,470.994781 791.010254,470.993927 
	C791.032959,470.965118 791.057617,470.896027 791.008850,470.614868 
	C790.302246,470.246185 789.644470,470.158691 788.589050,470.061707 
	C787.132324,470.060516 786.073120,470.068817 785.013977,470.077148 
	C784.260559,470.031586 783.507141,469.986023 782.125366,469.682983 
	C779.524597,468.869690 777.552246,468.313843 775.312500,467.682617 
	C775.211060,468.502686 775.113220,469.293304 775.015442,470.083923 
z"/>
<path fill="#234380" opacity="1.000000" stroke="none" 
	d="
M749.136169,528.985046 
	C746.294983,527.015442 734.523743,526.984009 732.607361,529.198669 
	C731.894470,530.022522 731.724060,531.713318 731.988647,532.849976 
	C732.174683,533.649414 733.449585,534.607483 734.351257,534.743042 
	C736.271973,535.031799 738.266235,534.831299 740.836548,534.831299 
	C740.836548,539.109680 740.836853,542.888184 740.836487,546.666687 
	C740.835510,558.479492 740.638855,570.297119 740.932434,582.102600 
	C741.044678,586.615234 739.347595,588.064209 735.169434,587.796936 
	C732.375122,587.618225 729.560120,587.763000 725.930420,587.763000 
	C725.930420,579.875977 726.432373,572.258301 725.720825,564.755737 
	C725.359558,560.946289 722.811584,557.344177 721.113953,553.330505 
	C725.544617,554.021790 727.811035,557.254089 727.942078,563.413208 
	C728.066040,569.240601 728.174011,575.082275 727.899231,580.899292 
	C727.711792,584.866577 729.797424,585.222900 732.841614,585.058472 
	C735.814087,584.897827 739.079529,585.631836 739.045593,580.852966 
	C738.953308,567.861084 739.175598,554.863708 738.819336,541.880981 
	C738.773499,540.210571 736.162476,537.384644 734.533569,537.227234 
	C730.587646,536.846008 729.910950,534.992737 729.983276,531.672974 
	C730.073853,527.518799 733.101074,525.594971 736.114624,524.843079 
	C739.219788,524.068420 742.787170,524.590881 746.038025,525.178345 
	C747.799255,525.496582 749.322083,527.133728 750.980286,528.555786 
	C751.008118,528.935608 751.006348,528.969360 750.688354,528.972656 
	C749.958984,528.978943 749.547546,528.981995 749.136169,528.985046 
z"/>
<path fill="#234380" opacity="1.000000" stroke="none" 
	d="
M697.643372,565.269409 
	C698.018860,565.774475 698.010803,566.096680 697.970093,566.657715 
	C694.405151,566.985107 692.691223,564.965332 692.019287,561.365723 
	C692.011047,559.293396 692.017212,557.589233 692.354187,555.396851 
	C694.805908,553.279358 696.926880,551.649963 699.259033,549.912109 
	C699.733093,549.610596 699.924438,549.362427 700.311157,549.174194 
	C701.390320,549.208313 702.202759,549.127441 703.015198,549.046509 
	C707.453796,549.259033 711.917419,549.264282 716.317932,549.793579 
	C717.916321,549.985901 719.364258,551.428894 720.935303,552.662354 
	C713.967712,550.058777 706.857666,550.213379 699.887573,552.939209 
	C693.737244,555.344482 692.713440,560.720337 697.643372,565.269409 
z"/>
<path fill="#013295" opacity="1.000000" stroke="none" 
	d="
M702.933960,548.674683 
	C702.202759,549.127441 701.390320,549.208313 700.117493,548.925049 
	C698.940186,548.593872 698.223328,548.626953 697.506409,548.660034 
	C698.020203,549.113586 698.533997,549.567078 699.047791,550.020630 
	C696.926880,551.649963 694.805908,553.279358 692.353333,554.970947 
	C691.482178,553.821655 690.161255,551.972656 690.539246,551.509155 
	C692.132324,549.555725 694.000977,547.203125 696.217224,546.572205 
	C698.111389,546.033081 700.617432,547.643250 702.933960,548.674683 
z"/>
<path fill="#01308F" opacity="1.000000" stroke="none" 
	d="
M672.728271,579.213989 
	C672.924805,579.992981 672.803101,580.722839 672.681335,581.452637 
	C672.268677,581.377625 671.856018,581.302551 671.443359,581.227478 
	C671.765625,580.539978 672.087830,579.852417 672.728271,579.213989 
z"/>
<path fill="#103B8C" opacity="1.000000" stroke="none" 
	d="
M654.959106,567.150085 
	C654.954712,571.454651 654.576111,575.324524 655.034912,579.092346 
	C655.700989,584.563293 651.983826,585.990295 648.163574,587.242798 
	C644.182495,588.548096 640.351318,587.852966 638.153015,583.982483 
	C634.509155,577.566772 633.998413,570.653503 636.810547,563.830811 
	C639.368591,557.624756 647.178345,554.986267 652.556213,558.882629 
	C654.314758,560.156677 654.211121,564.000977 654.959106,567.150085 
z"/>
<path fill="#013290" opacity="1.000000" stroke="none" 
	d="
M419.431458,550.808228 
	C416.301636,545.204285 415.280823,544.800232 410.549622,547.368042 
	C407.771851,548.875671 402.413300,546.228149 401.032104,542.392822 
	C400.154083,539.954712 398.664001,538.832703 396.474457,537.346130 
	C392.091583,534.370483 387.727356,530.471375 385.230499,525.924011 
	C383.524506,522.816895 384.510864,517.865112 385.334442,513.957458 
	C385.583221,512.777039 389.316223,512.330933 392.167969,511.269165 
	C396.544861,510.381470 400.223419,509.181763 403.865997,509.282562 
	C412.585876,509.523834 421.288818,510.376953 430.052521,511.323151 
	C433.343842,516.673645 438.181854,519.112793 443.804596,520.502502 
	C445.265778,520.863647 447.250244,521.847168 447.708344,523.039124 
	C449.426605,527.510010 450.612610,532.185364 452.228668,537.549377 
	C452.814911,537.888367 454.321777,538.759705 455.266998,539.306335 
	C452.553619,541.398438 450.270447,543.158875 447.522430,544.938965 
	C442.375671,544.931213 437.693817,544.903748 432.914307,544.518188 
	C430.311798,542.508789 427.913208,541.740540 425.705872,545.127625 
	C423.115479,546.419067 420.517731,547.431335 423.005524,550.701111 
	C421.944672,550.763306 420.883850,550.825500 419.431458,550.808228 
z"/>
<path fill="#064DCF" opacity="1.000000" stroke="none" 
	d="
M423.326324,550.827393 
	C420.517731,547.431335 423.115479,546.419067 426.144226,545.140625 
	C428.919922,544.963501 430.965912,544.919922 433.011932,544.876343 
	C437.693817,544.903748 442.375671,544.931213 447.991516,544.971069 
	C474.878967,544.990295 500.832520,544.973267 526.785889,545.034546 
	C529.932007,545.041931 532.300720,544.545166 534.435608,541.636963 
	C536.922852,538.248596 540.435242,535.606873 543.536560,532.676819 
	C547.329956,529.092957 551.158264,525.546082 555.265625,521.847534 
	C556.034546,520.802124 556.509460,519.892273 557.099121,518.769409 
	C557.414368,518.290588 557.670776,518.100464 558.307617,517.962891 
	C559.399475,517.267090 560.166931,516.594299 561.104858,515.626282 
	C562.531677,513.567322 563.788025,511.803558 565.425232,510.024841 
	C566.818665,509.926849 567.831299,509.843842 569.045776,510.052704 
	C569.890015,510.595581 570.532349,510.846588 571.107178,511.436646 
	C563.147217,520.119263 555.251953,528.460266 547.363464,536.807617 
	C543.540344,540.853088 539.729858,544.910400 535.913635,548.962402 
	C523.301331,549.319641 510.690216,549.733765 498.076050,550.006287 
	C492.994598,550.116028 487.906464,549.915649 481.944641,549.905884 
	C467.783203,549.682251 454.497772,549.196411 441.214081,549.239685 
	C435.815002,549.257263 430.419647,550.425537 425.022614,551.070251 
	C424.564117,551.031372 424.105621,550.992554 423.326324,550.827393 
z"/>
<path fill="#0A3890" opacity="1.000000" stroke="none" 
	d="
M425.073547,551.425293 
	C430.419647,550.425537 435.815002,549.257263 441.214081,549.239685 
	C454.497772,549.196411 467.783203,549.682251 481.537354,549.960327 
	C482.006073,550.148743 482.005402,550.332214 481.704102,550.979370 
	C470.492096,555.367310 465.887817,561.945801 466.237640,573.574585 
	C466.331268,576.686218 467.093262,579.777710 467.548462,582.878479 
	C463.239227,582.668457 458.929993,582.458374 454.133301,582.298706 
	C453.498291,576.530273 453.402710,570.709534 453.191986,564.893066 
	C452.794891,553.934387 446.447418,549.193481 435.940033,552.133423 
	C432.769379,553.020508 429.746918,554.437134 426.199890,555.784668 
	C425.847382,554.472046 425.485931,553.126221 425.073547,551.425293 
z"/>
<path fill="#103B8C" opacity="1.000000" stroke="none" 
	d="
M434.733032,588.402100 
	C432.148651,588.035583 429.564270,587.669128 426.514313,587.236633 
	C426.514313,580.050476 426.361816,572.949585 426.583771,565.860413 
	C426.682922,562.693359 432.946930,558.882629 437.482666,558.745728 
	C441.884308,558.612854 443.987488,561.305176 444.318207,565.124146 
	C444.804108,570.734985 444.706146,576.396423 444.363434,582.483215 
	C439.345001,582.284058 436.982727,585.249084 434.733032,588.402100 
z"/>
<path fill="#1796D0" opacity="1.000000" stroke="none" 
	d="
M434.717926,588.796875 
	C436.982727,585.249084 439.345001,582.284058 444.255402,582.936035 
	C443.782623,584.520020 442.985779,586.135681 442.048950,587.665588 
	C441.108948,589.200745 440.029388,590.650391 438.946289,592.522461 
	C437.770416,592.960327 436.659302,593.013306 435.246155,593.108521 
	C434.863739,591.831116 434.783295,590.511414 434.717926,588.796875 
z"/>
<path fill="#142A6D" opacity="1.000000" stroke="none" 
	d="
M416.743591,563.730774 
	C416.784851,562.420654 416.826111,561.110535 416.830353,559.407593 
	C417.201691,560.440918 417.939392,561.861816 417.962860,563.294312 
	C418.081696,570.543640 418.014862,577.795959 417.710876,585.373779 
	C417.179688,585.489014 417.019867,585.234253 416.942383,584.473999 
	C416.884888,577.251465 416.814240,570.491150 416.743591,563.730774 
z"/>
<path fill="#142A6D" opacity="1.000000" stroke="none" 
	d="
M412.315247,587.593445 
	C412.017273,587.188293 412.013306,586.859680 412.364380,586.265015 
	C413.490448,585.994263 414.245026,585.989685 415.019348,586.299377 
	C415.058441,586.822693 415.077759,587.031677 415.097107,587.240723 
	C414.273285,587.383728 413.449493,587.526733 412.315247,587.593445 
z"/>
<path fill="#1A336F" opacity="1.000000" stroke="none" 
	d="
M415.434998,587.323853 
	C415.077759,587.031677 415.058441,586.822693 415.188416,586.067139 
	C415.868195,585.325745 416.398682,585.130920 416.929199,584.936096 
	C417.019867,585.234253 417.179688,585.489014 417.597595,585.827148 
	C417.115295,586.438354 416.444061,586.922607 415.434998,587.323853 
z"/>
<path fill="#01379B" opacity="1.000000" stroke="none" 
	d="
M536.325195,548.977417 
	C539.729858,544.910400 543.540344,540.853088 547.363464,536.807617 
	C555.251953,528.460266 563.147217,520.119263 571.451172,511.410767 
	C573.210815,510.686005 574.559082,510.326202 576.279907,509.973083 
	C578.769653,508.985657 580.886719,507.991638 583.282532,507.216187 
	C586.078186,511.359680 590.033020,512.590515 591.718323,509.502014 
	C595.920776,501.801025 605.892090,498.553101 607.356262,488.872925 
	C607.372498,488.765503 607.918274,488.738159 609.610291,488.371429 
	C608.644714,491.957977 607.635742,494.908234 607.128784,497.942352 
	C606.894165,499.346863 607.481934,502.143616 607.963928,502.204834 
	C610.638123,502.544281 613.914307,503.098999 615.953125,501.869751 
	C617.497925,500.938293 617.484741,497.444977 618.195862,495.101105 
	C618.707458,493.414886 618.857849,490.627197 619.878235,490.250366 
	C625.930908,488.015015 631.219971,483.480652 638.151733,483.817017 
	C639.996094,483.906494 642.583923,483.425964 643.492981,484.424561 
	C644.718140,485.770630 644.648560,488.295258 645.134705,490.314087 
	C644.708618,490.490997 644.282593,490.667908 643.856567,490.844818 
	C641.993774,489.533295 640.131042,488.221771 638.336182,486.958038 
	C637.713135,487.610382 637.308594,487.833466 637.296631,488.075775 
	C637.014771,493.812958 636.542969,499.427002 630.594055,502.594940 
	C630.384827,502.706329 630.906006,504.189423 631.058838,505.394104 
	C630.979980,506.476257 630.928955,507.197510 630.877930,507.918762 
	C629.710632,508.789795 628.543274,509.660858 626.868164,510.773102 
	C625.917175,511.036255 625.473877,511.058197 624.980347,510.770721 
	C624.265564,510.402191 623.601135,510.343109 622.936646,510.283997 
	C623.148315,511.798798 623.359924,513.313599 623.571533,514.828430 
	C622.528625,516.060120 621.485718,517.291809 619.845276,518.736938 
	C618.522278,519.648804 617.796814,520.347168 617.071411,521.045532 
	C615.248108,522.927490 613.424805,524.809448 610.991272,526.894653 
	C609.942871,527.745789 609.504700,528.393616 609.066528,529.041504 
	C609.066589,529.041504 609.015991,529.017761 608.676941,529.063843 
	C605.853577,531.036682 603.369202,532.963379 600.884888,534.890015 
	C592.901489,534.904785 584.918152,534.919495 576.271118,534.725708 
	C574.742432,535.344482 573.877380,536.171692 573.012390,536.998901 
	C573.012390,536.998901 572.997742,536.997559 572.656677,537.031433 
	C570.910828,537.768188 569.506104,538.471008 568.101318,539.173889 
	C566.175842,538.375793 563.820496,536.485413 562.425171,537.034546 
	C560.308044,537.867615 557.547302,540.277832 557.372253,542.225830 
	C556.865051,547.871399 553.506287,549.305176 548.892395,550.077942 
	C547.788452,550.262817 546.839233,551.371338 545.997681,551.935120 
	C543.113647,551.018738 539.925232,550.005615 536.325195,548.977417 
z"/>
<path fill="#E6F1F6" opacity="1.000000" stroke="none" 
	d="
M467.762329,583.106934 
	C467.093262,579.777710 466.331268,576.686218 466.237640,573.574585 
	C465.887817,561.945801 470.492096,555.367310 481.704163,551.254639 
	C485.779083,551.385498 489.730743,551.103149 493.289856,552.152466 
	C500.199677,554.189514 505.355469,564.186951 502.746796,570.829224 
	C502.004517,572.719238 498.478851,574.419434 496.109802,574.595581 
	C489.347992,575.098328 482.524567,574.772339 474.697144,574.772339 
	C475.699677,578.110352 476.516998,580.831787 477.764984,584.062256 
	C483.665283,589.867737 490.121063,589.422424 497.395264,583.246948 
	C498.276794,583.212769 499.158325,583.178528 500.368317,583.168335 
	C501.171661,584.862488 501.646545,586.532715 502.112244,588.585815 
	C496.231689,593.028992 489.711334,595.948547 482.642456,594.479065 
	C478.402405,593.597656 474.643005,590.403809 470.408508,588.028442 
	C469.425049,586.316895 468.700623,584.826111 467.762329,583.106934 
M488.786011,557.431274 
	C480.193604,557.050659 475.671448,560.812378 475.746277,568.407593 
	C481.958374,568.407593 488.168335,568.407593 494.371460,568.407593 
	C494.400574,561.828979 493.799591,560.558167 488.786011,557.431274 
z"/>
<path fill="#318AC2" opacity="1.000000" stroke="none" 
	d="
M496.905518,583.147278 
	C490.121063,589.422424 483.665283,589.867737 478.090027,584.217102 
	C484.128204,583.591064 490.272003,583.319336 496.905518,583.147278 
z"/>
<path fill="#0461C6" opacity="1.000000" stroke="none" 
	d="
M568.113037,539.507812 
	C569.506104,538.471008 570.910828,537.768188 572.656982,537.038635 
	C571.677246,538.359253 570.356079,539.706482 568.712952,541.106079 
	C568.302246,540.719421 568.213501,540.280579 568.113037,539.507812 
z"/>
<path fill="#2B4B89" opacity="1.000000" stroke="none" 
	d="
M609.172729,560.837952 
	C609.954224,563.229248 610.735779,565.620483 611.676575,568.499023 
	C604.589539,568.499023 598.490295,568.499023 591.475342,568.499023 
	C592.182678,566.289246 592.795898,564.373474 593.786438,562.307556 
	C594.163757,562.157410 594.313232,562.304749 594.210205,562.702637 
	C593.638672,565.691406 594.340759,567.240112 597.354858,567.061584 
	C599.474976,566.936035 601.622192,566.892395 603.733948,567.077698 
	C608.252319,567.474243 609.721924,565.418945 608.883179,561.115234 
	C608.979431,561.022522 609.172729,560.837952 609.172729,560.837952 
z"/>
<path fill="#2B4B89" opacity="1.000000" stroke="none" 
	d="
M594.090271,562.082397 
	C598.913635,556.259338 604.041321,555.618408 609.106506,560.483765 
	C609.172729,560.837952 608.979431,561.022522 608.535034,561.024902 
	C606.121521,560.516479 604.069397,559.389465 602.203247,559.641296 
	C599.502625,560.005737 596.936646,561.368103 594.313232,562.304749 
	C594.313232,562.304749 594.163757,562.157410 594.090271,562.082397 
z"/>
<path fill="#0A3890" opacity="1.000000" stroke="none" 
	d="
M544.668213,562.677795 
	C544.942078,563.254944 545.252136,564.481750 544.832886,564.829529 
	C537.562622,570.861511 532.555725,579.573120 522.847351,583.314880 
	C518.445374,578.775085 517.808044,569.806702 521.043091,563.445740 
	C523.440308,558.732178 527.225464,556.873596 533.389954,557.383301 
	C535.330383,565.301208 538.434692,566.866821 544.668213,562.677795 
z"/>
<path fill="#0F3C91" opacity="1.000000" stroke="none" 
	d="
M545.686768,560.951050 
	C545.811401,561.216003 545.646851,561.510498 545.257202,561.933411 
	C545.153931,561.701355 545.275757,561.340942 545.686768,560.951050 
z"/>
<path fill="#023290" opacity="1.000000" stroke="none" 
	d="
M690.053345,586.612183 
	C689.221375,587.308655 688.430481,587.627014 687.319824,587.972656 
	C687.249695,585.298584 683.688293,580.864258 690.570923,581.000977 
	C690.671326,582.744690 690.382935,584.489441 690.053345,586.612183 
z"/>
<path fill="#013290" opacity="1.000000" stroke="none" 
	d="
M690.675537,575.045410 
	C690.773560,576.129456 690.524902,577.246704 690.128662,578.680664 
	C689.132019,578.677612 688.282837,578.357910 687.433655,578.038208 
	C688.398682,577.051636 689.363708,576.065063 690.675537,575.045410 
z"/>
<path fill="#04338D" opacity="1.000000" stroke="none" 
	d="
M555.714478,561.283386 
	C555.828125,562.234192 555.655029,563.386780 555.197815,564.751282 
	C555.085083,563.803833 555.256409,562.644531 555.714478,561.283386 
z"/>
<path fill="#01177C" opacity="1.000000" stroke="none" 
	d="
M785.014465,470.466492 
	C786.073120,470.068817 787.132324,470.060516 788.768921,470.299957 
	C789.916809,470.663818 790.487244,470.779907 791.057617,470.896027 
	C791.057617,470.896027 791.032959,470.965118 791.110107,471.384705 
	C792.354736,472.202057 793.520386,472.939514 794.690063,472.945984 
	C808.915710,473.025024 823.142090,473.021881 837.367859,472.950684 
	C838.583435,472.944611 839.795776,472.300110 841.436829,471.953064 
	C846.812866,471.641632 851.765137,471.020935 856.709839,471.076935 
	C869.805603,471.225250 882.898010,471.666260 895.991760,471.990784 
	C894.858582,472.992340 893.845398,474.614166 892.566223,474.861755 
	C890.160583,475.327423 887.519104,474.620972 885.135803,475.139008 
	C883.615417,475.469452 882.382935,477.124817 881.013184,478.590637 
	C870.647156,478.998352 860.289368,479.001587 849.462158,479.009705 
	C838.305115,479.012726 827.617554,479.010803 816.460327,479.017548 
	C813.624939,479.023346 811.259216,479.020477 807.981689,479.013672 
	C801.045837,478.676422 795.021729,478.343109 788.997559,478.009766 
	C787.670044,475.625122 786.342468,473.240509 785.014465,470.466492 
z"/>
<path fill="#04277D" opacity="1.000000" stroke="none" 
	d="
M866.419861,505.999207 
	C867.691284,498.347260 868.980896,490.678345 867.000122,482.434631 
	C874.592651,482.169708 875.172546,483.365021 871.916687,489.935822 
	C871.059692,491.665375 871.770020,494.270844 872.048096,496.437134 
	C872.363159,498.890778 873.990601,501.732941 873.181152,503.594727 
	C872.552002,505.041840 869.059570,505.244110 866.419861,505.999207 
z"/>
<path fill="#012173" opacity="1.000000" stroke="none" 
	d="
M913.096008,479.405701 
	C919.440979,479.010132 925.887268,478.931732 932.329773,479.056458 
	C933.829407,479.085449 935.315674,479.805359 936.907898,480.599426 
	C929.070435,480.593231 921.133240,480.195007 913.096008,479.405701 
z"/>
<path fill="#1A336F" opacity="1.000000" stroke="none" 
	d="
M885.095154,556.315552 
	C888.180481,557.877319 887.522339,558.706848 884.539307,559.439880 
	C883.635864,559.661926 882.266541,561.539429 882.485596,562.208313 
	C884.048462,566.979614 885.967285,571.634338 887.998657,576.913086 
	C888.713623,576.026794 889.567139,575.368408 889.880310,574.512329 
	C891.351562,570.490295 892.801880,566.449829 893.960022,562.331665 
	C894.248962,561.304382 893.424988,559.964111 893.066406,558.470093 
	C894.457581,558.172485 895.894897,558.172485 897.976685,558.172485 
	C894.783875,566.700012 891.767151,574.757202 888.195007,584.297913 
	C885.004944,574.934753 882.328613,567.079285 879.677185,559.297058 
	C881.767029,558.221313 883.317505,557.423218 885.095154,556.315552 
z"/>
<path fill="#0433B7" opacity="1.000000" stroke="none" 
	d="
M969.014587,423.011169 
	C968.518005,423.589539 968.021423,424.167908 966.925781,424.908875 
	C965.552002,426.048004 964.777222,427.024597 964.002441,428.001221 
	C962.440369,429.478241 960.878357,430.955261 958.815369,432.753113 
	C953.528992,438.369812 948.743469,443.665680 943.958008,448.961548 
	C941.085388,451.448364 938.212769,453.935181 934.856689,456.751099 
	C933.899048,457.695160 933.424683,458.310150 932.950378,458.925110 
	C932.627014,459.388458 932.303589,459.851776 931.636108,460.656128 
	C930.522888,461.322876 929.753784,461.648590 928.977478,461.611572 
	C928.261658,460.458771 927.553040,459.668701 926.914246,458.550354 
	C927.338257,457.160583 927.692505,456.099030 928.163940,454.792053 
	C928.555542,454.079559 928.830017,453.612488 929.445190,453.076904 
	C935.196899,447.675720 940.607971,442.343018 946.308594,436.868896 
	C948.058838,435.461395 949.519531,434.195312 951.300476,432.905212 
	C952.064941,432.249390 952.509216,431.617584 953.117310,430.705078 
	C954.510864,429.262360 955.740540,428.100281 957.275879,426.814026 
	C958.697754,425.452515 959.813965,424.215179 961.111511,422.684021 
	C963.521790,420.246735 965.750732,418.103241 968.284912,415.826538 
	C971.386597,412.791718 974.183167,409.890167 977.276367,406.845978 
	C979.692383,404.787872 981.811584,402.872406 984.278564,400.938416 
	C986.750916,400.279877 988.875488,399.639923 990.952271,399.351410 
	C987.516296,402.889679 983.944397,405.905914 980.785522,409.305908 
	C976.689697,413.714355 972.922974,418.428558 969.014587,423.011169 
z"/>
<path fill="#003CB2" opacity="1.000000" stroke="none" 
	d="
M896.299683,471.712769 
	C882.898010,471.666260 869.805603,471.225250 856.709839,471.076935 
	C851.765137,471.020935 846.812866,471.641632 841.129639,471.672760 
	C827.241943,471.227509 814.088623,471.063446 800.935303,470.899353 
	C802.596375,470.272461 804.253296,469.113831 805.919250,469.100555 
	C823.297363,468.962341 840.677063,469.007996 858.993958,469.004974 
	C877.644470,469.030334 895.357361,469.053436 913.055542,469.403809 
	C913.028381,470.168121 913.015808,470.605133 913.003174,471.042175 
	C907.537964,471.173035 902.072815,471.303864 896.299683,471.712769 
z"/>
<path fill="#01247B" opacity="1.000000" stroke="none" 
	d="
M928.984619,461.974335 
	C929.753784,461.648590 930.522888,461.322876 931.599854,460.945374 
	C934.619324,464.458862 937.330933,468.024139 941.109131,472.991730 
	C933.856262,472.991730 927.889465,472.991730 921.516418,472.645386 
	C923.697815,469.479889 926.285522,466.660736 928.895752,463.532349 
	C928.940430,462.806854 928.962524,462.390594 928.984619,461.974335 
z"/>
<path fill="#012E8B" opacity="1.000000" stroke="none" 
	d="
M928.873230,463.841553 
	C926.285522,466.660736 923.697815,469.479889 921.047058,472.636688 
	C919.920959,472.977844 918.857910,472.981354 917.246765,472.764893 
	C916.133484,472.688629 915.568359,472.832367 915.003235,472.976135 
	C914.362976,472.540863 913.722778,472.105560 913.042847,471.356232 
	C913.015808,470.605133 913.028381,470.168121 913.491821,469.391541 
	C918.186768,469.234528 922.366272,469.439972 925.405518,465.462097 
	C926.092163,464.563416 927.693176,464.363281 928.873230,463.841553 
z"/>
<path fill="#022590" opacity="1.000000" stroke="none" 
	d="
M933.287048,458.946533 
	C933.424683,458.310150 933.899048,457.695160 934.690186,457.044586 
	C935.001099,457.443848 934.995239,457.878693 934.980103,458.638916 
	C934.521790,458.965515 934.072754,458.966705 933.287048,458.946533 
z"/>
<path fill="#01247B" opacity="1.000000" stroke="none" 
	d="
M915.148926,473.200256 
	C915.568359,472.832367 916.133484,472.688629 916.847839,472.760498 
	C916.429565,473.125519 915.862061,473.274963 915.148926,473.200256 
z"/>
<path fill="#011460" opacity="1.000000" stroke="none" 
	d="
M51.633202,79.172531 
	C53.222816,80.417679 55.114029,81.160011 55.684925,82.475388 
	C56.305176,83.904472 56.307182,86.262962 55.495110,87.511200 
	C53.937168,89.905922 51.613419,91.802429 49.583435,93.930733 
	C48.977196,89.236938 48.307430,84.051277 47.542835,78.131401 
	C48.789425,78.390106 50.071301,78.656128 51.633202,79.172531 
z"/>
<path fill="#011460" opacity="1.000000" stroke="none" 
	d="
M61.712677,79.146591 
	C62.495453,79.259384 62.989834,79.520576 63.742031,79.891151 
	C63.086994,80.821953 62.174137,81.643379 61.261284,82.464798 
	C61.315620,81.408188 61.369953,80.351585 61.712677,79.146591 
z"/>
<path fill="#02378D" opacity="1.000000" stroke="none" 
	d="
M67.945015,78.683311 
	C67.067680,78.529854 66.164536,78.036476 65.088135,77.267723 
	C64.914871,76.992348 64.951187,76.501968 64.968994,76.256851 
	C65.964272,76.788948 66.941742,77.566162 67.945015,78.683311 
z"/>
<path fill="#0C8FEC" opacity="1.000000" stroke="none" 
	d="
M40.925652,47.912308 
	C43.353840,48.162403 45.787872,48.423313 48.569595,48.872276 
	C48.722534,51.774052 48.527782,54.487770 48.171844,57.562874 
	C46.193295,57.788998 44.375931,57.653740 42.158295,57.064262 
	C41.560085,56.313522 41.284496,56.141102 40.931255,56.092789 
	C40.931255,56.092789 40.920048,56.031078 40.907669,55.672722 
	C40.901104,52.851387 40.906921,50.388412 40.912739,47.925434 
	C40.912739,47.925434 40.931503,47.923122 40.925652,47.912308 
z"/>
<path fill="#0D65C3" opacity="1.000000" stroke="none" 
	d="
M40.564045,47.996109 
	C40.906921,50.388412 40.901104,52.851387 40.891853,55.699806 
	C36.803997,53.736439 37.292553,51.003773 40.564045,47.996109 
z"/>
<path fill="#0D65C3" opacity="1.000000" stroke="none" 
	d="
M41.024971,56.321587 
	C41.284496,56.141102 41.560085,56.313522 41.844482,56.845345 
	C41.589062,57.012833 41.318310,56.836082 41.024971,56.321587 
z"/>
<path fill="#01379A" opacity="1.000000" stroke="none" 
	d="
M393.944885,62.643284 
	C391.962799,62.996037 389.918579,63.002697 387.436554,63.002312 
	C388.928192,59.161194 391.245209,59.148739 393.944885,62.643284 
z"/>
<path fill="#04277D" opacity="1.000000" stroke="none" 
	d="
M839.598999,549.007812 
	C840.023804,549.442383 840.020752,549.872498 840.019531,550.625366 
	C836.665955,552.055603 833.310547,553.163147 829.577759,554.270386 
	C828.443970,550.358032 831.658752,550.288940 833.952271,549.599487 
	C835.602478,549.103455 837.425293,549.181458 839.598999,549.007812 
z"/>
<path fill="#04277D" opacity="1.000000" stroke="none" 
	d="
M818.802917,549.641479 
	C815.968750,549.980896 812.936035,549.989197 809.448303,549.993896 
	C809.022644,549.376099 809.051941,548.761963 809.081238,548.147827 
	C812.255615,548.535339 815.429932,548.922913 818.802917,549.641479 
z"/>
<path fill="#04277D" opacity="1.000000" stroke="none" 
	d="
M801.981567,555.606079 
	C801.621277,554.585754 801.271179,553.147217 800.961792,551.354309 
	C801.172974,551.001709 801.343506,551.003357 801.769958,551.010254 
	C802.014587,552.406311 802.003235,553.797058 801.981567,555.606079 
z"/>
<path fill="#042475" opacity="1.000000" stroke="none" 
	d="
M842.863342,587.407837 
	C841.869385,587.474548 840.875366,587.541321 839.366272,587.228149 
	C838.082336,586.683167 837.313599,586.518127 836.544800,586.353088 
	C836.267883,586.241699 836.043274,586.064087 835.838379,585.323120 
	C835.410706,584.167603 835.015503,583.509277 834.620300,582.850952 
	C834.904114,581.379272 835.187988,579.907654 835.938721,578.135742 
	C836.683105,577.646790 836.889038,577.398621 837.023499,577.091064 
	C841.216064,576.603638 845.408630,576.116211 851.314636,575.429626 
	C849.873718,579.572021 848.801270,582.655029 847.187317,585.693726 
	C845.385010,586.235657 844.124146,586.821716 842.863342,587.407837 
z"/>
<path fill="#4D5E81" opacity="1.000000" stroke="none" 
	d="
M843.182678,587.495117 
	C844.124146,586.821716 845.385010,586.235657 846.828979,585.848267 
	C845.842041,586.558777 844.672058,587.070557 843.182678,587.495117 
z"/>
<path fill="#4D5E81" opacity="1.000000" stroke="none" 
	d="
M834.511597,583.151855 
	C835.015503,583.509277 835.410706,584.167603 835.739136,585.130127 
	C835.249268,584.773743 834.826050,584.113220 834.511597,583.151855 
z"/>
<path fill="#4D5E81" opacity="1.000000" stroke="none" 
	d="
M836.692749,586.632690 
	C837.313599,586.518127 838.082336,586.683167 839.005127,587.118103 
	C838.386292,587.229431 837.613464,587.070862 836.692749,586.632690 
z"/>
<path fill="#4D5E81" opacity="1.000000" stroke="none" 
	d="
M836.737793,577.048096 
	C836.889038,577.398621 836.683105,577.646790 836.166748,577.884888 
	C836.102661,577.624512 836.277405,577.314819 836.737793,577.048096 
z"/>
<path fill="#163A84" opacity="1.000000" stroke="none" 
	d="
M717.715698,579.855896 
	C716.921753,586.259155 707.787170,590.337219 703.268982,586.447266 
	C701.899658,585.268311 700.837280,582.476685 701.246155,580.822021 
	C701.706055,578.960571 703.734192,576.649902 705.533813,576.141846 
	C708.644531,575.263611 712.132874,575.340393 715.401123,575.655884 
	C716.286987,575.741394 716.951294,578.121521 717.715698,579.855896 
z"/>
<path fill="#001A66" opacity="1.000000" stroke="none" 
	d="
M749.090942,529.452332 
	C749.547546,528.981995 749.958984,528.978943 750.679443,528.971558 
	C750.990112,539.665039 750.991760,550.362854 750.991211,561.946228 
	C750.993408,564.221985 750.997864,565.612244 751.002319,567.002441 
	C751.000732,571.804504 751.231567,576.621765 750.919434,581.403503 
	C750.695435,584.835266 751.998596,586.268677 755.461670,586.600220 
	C755.674622,586.883057 755.305115,587.129089 755.230591,587.215210 
	C753.234314,587.421143 751.312500,587.541016 749.045776,587.682312 
	C749.045776,568.079529 749.045776,548.999573 749.090942,529.452332 
z"/>
<path fill="#234380" opacity="1.000000" stroke="none" 
	d="
M700.630493,565.695435 
	C700.766785,565.782288 700.363159,565.876404 700.300537,565.841003 
	C700.237915,565.805603 700.494141,565.608643 700.630493,565.695435 
z"/>
<path fill="#142A6D" opacity="1.000000" stroke="none" 
	d="
M783.100830,588.881226 
	C784.880371,583.165405 787.035828,577.423218 789.624023,570.528015 
	C790.790466,572.831482 791.365417,573.966919 792.078369,575.457275 
	C789.821655,579.428711 787.430908,583.047791 785.028992,586.659485 
	C784.532898,587.405457 783.995239,588.123718 783.100830,588.881226 
z"/>
<path fill="#022B82" opacity="1.000000" stroke="none" 
	d="
M789.210083,585.222168 
	C789.795593,585.576843 790.089661,585.994385 790.383789,586.411926 
	C789.948853,586.524536 789.513916,586.637207 789.079041,586.749817 
	C789.025574,586.261536 788.972168,585.773254 789.210083,585.222168 
z"/>
<path fill="#142A6D" opacity="1.000000" stroke="none" 
	d="
M792.130127,576.143188 
	C792.480347,576.620911 792.776428,577.450012 793.025146,578.631836 
	C792.677185,578.154602 792.376587,577.324585 792.130127,576.143188 
z"/>
<path fill="#142A6D" opacity="1.000000" stroke="none" 
	d="
M794.146362,582.137573 
	C794.537415,582.565979 794.873230,583.362122 795.252686,584.511902 
	C794.894592,584.078796 794.492859,583.292053 794.146362,582.137573 
z"/>
<path fill="#142A6D" opacity="1.000000" stroke="none" 
	d="
M795.173950,585.098145 
	C795.532898,585.263123 795.857727,585.758423 796.198242,586.584839 
	C795.855957,586.420166 795.497864,585.924316 795.173950,585.098145 
z"/>
<path fill="#142A6D" opacity="1.000000" stroke="none" 
	d="
M793.125977,579.981323 
	C793.512817,580.142090 793.886719,580.654846 794.276855,581.520447 
	C793.899719,581.359985 793.506348,580.846619 793.125977,579.981323 
z"/>
<path fill="#032A82" opacity="1.000000" stroke="none" 
	d="
M763.093872,561.281250 
	C764.833557,565.843201 766.529358,570.864319 768.195190,576.263184 
	C768.405151,578.494995 768.645142,580.349182 768.927490,582.601318 
	C767.875732,582.689209 766.781738,582.379028 765.344238,581.700684 
	C765.000549,580.887512 765.000305,580.442566 765.000000,579.997559 
	C765.080872,578.856079 765.161743,577.714539 765.120728,576.290161 
	C764.349304,571.251709 763.699646,566.496033 763.093872,561.281250 
z"/>
<path fill="#022B82" opacity="1.000000" stroke="none" 
	d="
M751.269897,567.189941 
	C750.997864,565.612244 750.993408,564.221985 750.994507,562.414673 
	C752.104126,561.335449 753.208252,560.673279 754.655029,560.006836 
	C754.632446,562.513123 754.294128,565.029053 753.804138,567.515137 
	C753.784607,567.614319 752.325806,567.429810 751.269897,567.189941 
z"/>
<path fill="#032A82" opacity="1.000000" stroke="none" 
	d="
M755.403320,560.051880 
	C755.000244,559.554749 754.998108,559.111938 755.351318,558.302979 
	C755.944031,557.907837 756.181335,557.878784 756.418579,557.849731 
	C758.653503,558.593994 760.888428,559.338257 763.158081,560.476624 
	C760.729980,560.615906 758.267151,560.361084 755.403320,560.051880 
z"/>
<path fill="#012E8B" opacity="1.000000" stroke="none" 
	d="
M764.634155,579.999634 
	C765.000305,580.442566 765.000549,580.887512 765.001587,581.666260 
	C763.851257,581.854126 762.700134,581.708252 761.548950,581.562378 
	C761.532715,581.265015 761.516418,580.967651 761.500183,580.670288 
	C762.422913,580.447388 763.345581,580.224548 764.634155,579.999634 
z"/>
<path fill="#012982" opacity="1.000000" stroke="none" 
	d="
M852.006531,101.953430 
	C848.871643,95.065758 845.457703,88.286308 842.725952,81.242302 
	C841.463684,77.987389 841.281372,74.230774 841.072815,70.674461 
	C840.882019,67.420433 840.033142,65.168427 836.380371,64.992043 
	C831.310791,64.747231 833.903442,59.958374 832.049194,57.389568 
	C845.686829,57.000946 859.373718,57.000973 873.529907,57.422615 
	C873.920715,60.904774 873.618103,63.850113 878.087280,64.419754 
	C879.197327,64.561241 880.630005,67.111977 880.800171,68.676460 
	C881.174255,72.117035 880.877686,75.636162 880.774719,79.120850 
	C880.712341,81.232803 879.752258,83.718338 880.572266,85.339989 
	C881.340942,86.860390 883.921875,87.464523 886.723816,89.047089 
	C884.746582,89.841698 883.286743,90.370422 881.877930,91.011421 
	C880.557190,91.612335 879.290466,92.332001 877.625000,93.000023 
	C876.500000,93.000244 875.750000,92.999847 874.709473,92.714661 
	C871.794434,92.259499 869.043457,92.532265 866.571960,91.826332 
	C861.458557,90.365753 859.101135,93.275665 857.004150,97.253456 
	C857.003418,97.502449 857.000000,98.000412 856.661377,98.037460 
	C855.550171,99.047180 854.777527,100.019867 854.002014,101.249916 
	C853.998230,101.678864 853.997375,101.850449 853.664429,102.024323 
	C852.890381,102.002220 852.448486,101.977829 852.006531,101.953430 
z"/>
<path fill="#01257D" opacity="1.000000" stroke="none" 
	d="
M878.000000,92.999405 
	C879.290466,92.332001 880.557190,91.612335 881.877930,91.011421 
	C883.286743,90.370422 884.746582,89.841698 886.723816,89.047089 
	C883.921875,87.464523 881.340942,86.860390 880.572266,85.339989 
	C879.752258,83.718338 880.712341,81.232803 880.774719,79.120850 
	C880.877686,75.636162 881.174255,72.117035 880.800171,68.676460 
	C880.630005,67.111977 879.197327,64.561241 878.087280,64.419754 
	C873.618103,63.850113 873.920715,60.904774 873.998413,57.423027 
	C884.019287,57.000641 894.040955,56.999451 904.531372,56.996819 
	C913.705688,57.000366 922.411377,57.005356 931.593140,57.025806 
	C930.762817,58.668724 929.574646,60.410667 928.129333,61.903656 
	C922.149963,68.079987 916.092834,74.181007 909.762817,80.841583 
	C907.965027,82.903030 906.468750,84.432602 904.631836,86.005478 
	C902.852478,87.694862 901.413940,89.340958 899.853760,91.191391 
	C899.530762,91.650200 899.280518,91.840485 898.661987,92.013168 
	C897.895020,92.706543 897.447510,93.353325 896.776123,94.027893 
	C896.552246,94.055672 896.101624,94.076004 895.824097,94.149216 
	C895.038635,94.479042 894.530701,94.735649 893.942932,94.628983 
	C892.741943,94.067978 891.627625,93.765053 890.498413,93.691360 
	C886.334961,93.419632 882.166565,93.223793 878.000000,92.999405 
z"/>
<path fill="#012276" opacity="1.000000" stroke="none" 
	d="
M929.038879,68.058380 
	C932.915894,64.896141 936.792908,61.733898 941.147705,58.238243 
	C942.078552,57.264999 942.531616,56.625164 942.984680,55.985321 
	C942.984680,55.985310 943.459534,55.969223 944.119019,56.034355 
	C946.876282,56.398830 948.971680,56.935104 951.072327,56.956688 
	C960.379883,57.052322 969.689026,57.000183 979.084229,57.376724 
	C983.332642,58.766666 987.026611,60.358204 988.748413,64.780876 
	C988.971436,65.353661 990.293579,65.408730 990.819397,65.971802 
	C991.980042,67.214615 992.957581,68.628479 993.557373,69.984924 
	C988.743713,70.314621 984.382385,70.631950 979.980591,70.666061 
	C979.294006,70.255501 978.647827,70.128166 977.585999,69.999680 
	C975.784729,69.989891 974.399109,69.981255 972.954956,69.645752 
	C971.927795,69.539253 970.959229,69.759621 969.566711,69.983467 
	C966.282593,71.111000 962.838074,71.570793 960.668213,73.479645 
	C953.204895,80.045364 946.187378,87.117874 938.531616,93.997940 
	C931.704407,93.993217 925.345581,93.987808 919.047729,93.646370 
	C919.983826,92.225922 920.858826,91.141502 922.153687,89.536850 
	C917.987061,89.327217 914.509399,89.152245 911.025757,88.738289 
	C911.019836,88.499313 911.007690,88.021370 911.141235,87.740555 
	C912.127258,86.937691 912.979797,86.415627 914.208923,85.896523 
	C916.065186,84.274590 917.544800,82.649712 919.367432,80.980499 
	C922.130432,77.940948 924.550354,74.945709 927.098145,71.661774 
	C927.830383,70.268173 928.434631,69.163277 929.038879,68.058380 
z"/>
<path fill="#001168" opacity="1.000000" stroke="none" 
	d="
M978.997559,57.001514 
	C969.689026,57.000183 960.379883,57.052322 951.072327,56.956688 
	C948.971680,56.935104 946.876282,56.398830 944.356384,56.043945 
	C944.129211,55.140335 944.324158,54.292267 945.144958,53.175182 
	C949.741638,52.269829 953.734436,51.741280 957.672485,50.942982 
	C959.203735,50.632584 960.611633,49.713566 962.075745,49.071793 
	C967.403687,49.063801 972.731628,49.055798 978.834839,49.327759 
	C991.182739,49.709293 1002.755249,49.824337 1014.328064,49.870884 
	C1014.881409,49.873108 1015.437317,49.269978 1015.992004,48.948757 
	C1016.431946,48.956409 1016.871887,48.964062 1017.641235,48.961128 
	C1023.668335,48.985188 1029.366089,49.019836 1035.530762,49.090122 
	C1039.384033,52.918072 1042.767822,56.712776 1046.157959,60.501869 
	C1048.765259,63.416145 1051.380493,66.323372 1053.650024,69.767120 
	C1049.205688,69.868492 1045.103760,69.436462 1041.000244,68.618698 
	C1039.306396,65.773186 1037.528198,63.367115 1035.944336,60.839302 
	C1034.185791,58.032772 1031.881714,56.904606 1028.482666,56.934677 
	C1011.988770,57.080593 995.492798,57.001141 978.997559,57.001514 
z"/>
<path fill="#053FC9" opacity="1.000000" stroke="none" 
	d="
M929.016968,67.709236 
	C928.434631,69.163277 927.830383,70.268173 926.737793,71.679871 
	C923.830811,74.321640 921.412109,76.656616 918.660767,79.045723 
	C916.879150,80.725891 915.430115,82.351921 913.962402,84.298172 
	C913.906494,85.043449 913.869385,85.468506 913.832275,85.893570 
	C912.979797,86.415627 912.127258,86.937691 910.820190,87.863281 
	C908.861267,90.138786 907.356934,92.010765 905.582764,94.021774 
	C904.522461,94.760437 903.731934,95.360077 902.632690,96.093391 
	C900.907288,97.498787 899.490601,98.770508 897.795654,100.221252 
	C895.682983,102.270088 893.848511,104.139893 891.669189,106.004997 
	C890.545349,106.364059 889.766357,106.727829 888.519653,107.086990 
	C876.694580,107.135727 865.337219,107.189072 853.565674,107.186790 
	C851.756348,107.066124 850.361206,107.001076 848.966125,106.936020 
	C849.558533,106.082153 850.150879,105.228287 850.831970,104.246651 
	C845.756897,104.014992 840.666748,103.782646 835.281982,103.250732 
	C840.348694,102.634079 845.709961,102.316986 851.538940,101.976662 
	C852.448486,101.977829 852.890381,102.002220 853.902161,102.279205 
	C855.979248,102.370621 857.486450,102.209450 859.421631,102.046997 
	C861.560730,102.009331 863.271851,101.972954 865.452393,101.968689 
	C872.337524,102.006012 878.769836,102.275009 885.160522,101.876541 
	C887.467529,101.732697 889.674683,99.987442 892.177979,98.820213 
	C892.956970,98.097466 893.485291,97.527443 894.353027,96.920410 
	C895.162231,95.947609 895.631958,95.011818 896.101685,94.076012 
	C896.101624,94.076004 896.552246,94.055672 897.097290,93.984512 
	C898.088684,93.264427 898.535095,92.615509 898.981445,91.966583 
	C899.280518,91.840485 899.530762,91.650200 900.200562,91.162292 
	C902.103455,89.273300 903.537964,87.617722 904.972534,85.962158 
	C906.468750,84.432602 907.965027,82.903030 909.724731,81.185066 
	C914.707825,76.781136 919.431274,72.569893 924.143860,68.346542 
	C925.122314,67.469711 925.984741,66.424973 927.080566,65.749619 
	C927.303284,65.612335 928.335815,66.789101 929.016968,67.709236 
z"/>
<path fill="#032495" opacity="1.000000" stroke="none" 
	d="
M1015.687500,48.667793 
	C1015.437317,49.269978 1014.881409,49.873108 1014.328064,49.870884 
	C1002.755249,49.824337 991.182739,49.709293 979.301880,49.329693 
	C979.548645,48.729485 980.104675,48.124622 980.658325,48.126789 
	C992.233398,48.172165 1003.808105,48.286259 1015.687500,48.667793 
z"/>
<path fill="#032495" opacity="1.000000" stroke="none" 
	d="
M961.604248,49.041714 
	C960.611633,49.713566 959.203735,50.632584 957.672485,50.942982 
	C953.734436,51.741280 949.741638,52.269829 945.383667,52.948074 
	C946.947510,51.660328 948.753296,49.589462 950.882996,49.172516 
	C954.183594,48.526329 957.705017,49.008118 961.604248,49.041714 
z"/>
<path fill="#032495" opacity="1.000000" stroke="none" 
	d="
M942.668945,56.032059 
	C942.531616,56.625164 942.078552,57.264999 941.302734,57.940601 
	C941.437683,57.343842 941.895508,56.711315 942.668945,56.032059 
z"/>
<path fill="#01399A" opacity="1.000000" stroke="none" 
	d="
M437.991150,141.588638 
	C436.290192,141.971405 434.575958,141.981476 432.353516,141.625671 
	C429.585327,141.228607 427.324097,141.171814 425.071472,141.285461 
	C425.001160,141.289017 425.019775,143.055237 424.996948,143.999710 
	C424.996948,143.999710 425.020660,144.453888 424.998962,144.734909 
	C424.977264,145.015930 424.911102,145.123428 424.549500,145.067596 
	C419.768036,140.341949 415.348206,135.672104 410.899811,130.688675 
	C410.245789,129.939850 409.620361,129.504608 408.783447,129.020508 
	C408.571930,128.971634 408.139862,128.929398 408.058777,128.597839 
	C405.647308,125.875992 403.316895,123.485695 400.692200,121.001144 
	C399.629822,120.258652 398.861694,119.610405 398.037720,118.621155 
	C396.721222,116.768623 395.554260,115.165848 394.184845,113.760284 
	C384.819427,104.147675 375.411591,94.576416 366.347168,84.843475 
	C370.408325,82.795975 371.750397,85.931808 374.060364,87.763298 
	C376.487549,89.687683 379.762390,90.542915 382.666809,91.865356 
	C383.446594,89.581848 384.659821,87.343742 384.904846,85.004219 
	C385.275940,81.460869 386.903107,79.938568 390.345245,79.993729 
	C393.424561,80.043076 396.505219,80.004120 399.395905,80.004120 
	C401.133759,71.190071 400.981323,70.896996 392.894684,71.045387 
	C388.602905,71.124153 384.315308,71.982048 380.033142,71.926476 
	C369.750214,71.793015 359.471283,71.337936 349.191376,70.992859 
	C342.564087,70.770386 335.936768,70.285042 329.311615,70.320663 
	C317.210571,70.385735 305.111145,70.749870 292.571106,70.989487 
	C288.427063,70.670280 284.722961,70.350845 280.572693,70.019035 
	C277.747284,70.010429 275.368103,70.014206 272.520935,70.015854 
	C249.320572,70.009109 226.588058,69.973160 203.855881,70.042580 
	C201.243378,70.050560 198.632706,70.646347 196.021179,70.969604 
	C193.919708,70.649788 191.819122,70.058968 189.716614,70.051987 
	C168.155411,69.980446 146.593872,70.012886 125.032486,69.983398 
	C123.227379,69.980934 121.422592,69.739510 119.315979,69.326111 
	C120.905655,68.699249 122.796860,68.055672 124.688408,68.054657 
	C202.079941,68.013191 279.471619,67.960732 356.863007,68.080925 
	C379.799866,68.116554 402.735443,68.794548 425.672668,68.873985 
	C431.419189,68.893890 433.318573,73.487396 436.869019,77.043060 
	C438.077209,78.779617 439.140503,79.843094 440.203827,80.906570 
	C440.203827,80.906570 440.605957,80.906776 440.803589,80.943649 
	C440.712280,85.566902 441.858307,89.250046 446.927765,90.526260 
	C447.388275,90.642197 448.154510,91.040695 448.148804,91.293709 
	C447.984619,98.545822 455.112946,99.820282 458.497925,104.133553 
	C459.407562,105.292610 459.836060,107.146217 459.768066,108.648521 
	C459.719543,109.720497 458.668243,111.099968 457.680969,111.636040 
	C457.142181,111.928581 455.597656,110.954643 454.862061,110.203751 
	C450.125031,105.368309 450.615326,106.369270 446.532440,111.482780 
	C442.866272,116.074356 443.711426,120.509521 442.721680,125.124451 
	C442.386078,126.689232 439.656067,128.106094 437.733643,128.851669 
	C436.137024,129.470856 434.075348,128.771088 432.305573,129.107422 
	C431.196167,129.318268 429.906982,130.170547 429.363831,131.116928 
	C429.084167,131.604218 430.098755,133.075073 430.807465,133.844620 
	C433.128723,136.365158 435.577911,138.767929 437.991150,141.588638 
z"/>
<path fill="#01257E" opacity="1.000000" stroke="none" 
	d="
M195.952850,71.326889 
	C198.632706,70.646347 201.243378,70.050560 203.855881,70.042580 
	C226.588058,69.973160 249.320572,70.009109 272.613190,70.390106 
	C275.967651,72.432953 278.704529,73.487144 281.018860,70.031410 
	C284.722961,70.350845 288.427063,70.670280 292.508484,71.339890 
	C289.299225,74.279610 288.368958,77.865257 288.557190,82.072525 
	C282.980225,82.368507 277.852142,82.838432 272.722443,82.856544 
	C249.120819,82.939865 225.518738,82.911011 201.916870,82.882095 
	C198.939133,82.878448 195.961685,82.636711 192.984100,82.505280 
	C192.918900,78.621635 192.580292,74.664711 195.952850,71.326889 
z"/>
<path fill="#0E80D9" opacity="1.000000" stroke="none" 
	d="
M192.540970,82.606178 
	C195.961685,82.636711 198.939133,82.878448 201.916870,82.882095 
	C225.518738,82.911011 249.120819,82.939865 272.722443,82.856544 
	C277.852142,82.838432 282.980225,82.368507 288.989197,82.116196 
	C291.893372,82.138893 293.917358,82.152069 296.267609,82.408318 
	C300.066284,82.765472 303.538727,82.879539 307.011719,83.332291 
	C307.350555,84.447838 307.688843,85.224709 308.019501,86.297897 
	C308.007202,86.791786 308.002502,86.989342 307.540283,87.138451 
	C303.712097,87.144165 300.341400,87.198341 296.516083,87.192795 
	C292.387451,87.172211 288.713501,87.211357 284.577545,87.162277 
	C279.718384,87.159737 275.321167,87.245422 270.465210,87.266197 
	C242.081055,87.160698 214.155685,87.114182 186.230286,87.100830 
	C185.091049,87.100288 183.951660,87.412056 182.639053,87.508690 
	C182.465775,87.439072 182.097809,87.375046 181.699127,87.218903 
	C179.899384,86.916000 178.498352,86.769234 177.068207,86.260201 
	C177.019669,84.907944 177.000244,83.917946 176.980820,82.927956 
	C177.149048,82.847092 177.317291,82.766220 178.146240,82.722412 
	C180.541412,82.652702 182.275833,82.545937 184.010269,82.439171 
	C186.706131,82.528473 189.401978,82.617775 192.540970,82.606178 
z"/>
<path fill="#010558" opacity="1.000000" stroke="none" 
	d="
M182.097809,87.375046 
	C182.097809,87.375046 182.465775,87.439072 182.679138,87.906509 
	C182.300369,94.554626 183.369354,99.932487 189.034637,103.781441 
	C190.272873,104.622688 190.345978,107.178879 190.630707,108.956589 
	C189.527298,109.242973 188.748856,109.518021 187.970413,109.793068 
	C186.608582,109.719360 185.246765,109.645660 183.509308,109.397629 
	C182.488663,104.706909 180.046402,102.397995 175.346451,101.904892 
	C166.174622,100.942612 163.971863,98.426712 164.512863,89.506058 
	C167.771591,89.021530 170.569748,89.193901 173.323135,88.918587 
	C176.270020,88.623917 179.174698,87.907425 182.097809,87.375046 
z"/>
<path fill="#0E80D9" opacity="1.000000" stroke="none" 
	d="
M145.092026,82.568085 
	C150.061935,82.615517 155.031830,82.662949 160.881073,82.769363 
	C163.536407,82.747803 165.312408,82.667259 167.088394,82.586716 
	C167.517715,82.667305 167.947037,82.747894 168.690247,83.260338 
	C168.972168,84.676125 168.940201,85.660049 168.451538,86.717575 
	C159.326385,86.855186 150.657913,86.919212 141.716370,86.710449 
	C138.965134,86.623825 136.486954,86.809990 133.580688,87.006027 
	C131.441086,87.016739 129.729584,87.017570 127.725060,86.968842 
	C127.236664,86.886536 127.041275,86.853790 126.892059,86.471054 
	C126.977821,85.408134 127.017418,84.695206 127.346298,83.956230 
	C128.093430,83.620155 128.551285,83.310135 129.452026,82.989967 
	C132.261475,82.986763 134.628036,82.993698 137.210876,83.254410 
	C138.620590,83.341011 139.814011,83.173836 141.419373,82.989792 
	C142.918213,82.837982 144.005112,82.703033 145.092026,82.568085 
z"/>
<path fill="#084EC4" opacity="1.000000" stroke="none" 
	d="
M113.052216,72.184013 
	C110.079445,76.087814 108.056702,72.361000 105.368942,70.695564 
	C103.876900,66.108437 104.701469,61.358795 99.790581,58.764000 
	C98.740715,58.209267 98.645325,54.467041 99.282318,52.591267 
	C99.699944,51.361485 102.365707,49.995392 103.770027,50.227909 
	C105.530518,50.519398 107.537384,52.074570 108.506058,53.655350 
	C109.564079,55.381927 109.557030,57.761154 109.824051,60.159798 
	C108.463478,62.594700 106.574417,64.667809 106.396278,66.878761 
	C106.291931,68.173889 108.835281,69.782646 110.346031,71.063431 
	C111.055122,71.664581 112.138474,71.824265 113.052216,72.184013 
z"/>
<path fill="#021F84" opacity="1.000000" stroke="none" 
	d="
M110.014221,59.855907 
	C109.557030,57.761154 109.564079,55.381927 108.506058,53.655350 
	C107.537384,52.074570 105.530518,50.519398 103.770027,50.227909 
	C102.365707,49.995392 99.699944,51.361485 99.282318,52.591267 
	C98.645325,54.467041 98.740715,58.209267 99.790581,58.764000 
	C104.701469,61.358795 103.876900,66.108437 105.068596,70.525848 
	C104.581841,70.694710 104.058487,70.459480 103.271118,70.109818 
	C102.073524,68.175171 101.538963,65.933739 100.138138,64.606773 
	C93.652435,58.462978 94.728188,49.608963 102.882530,45.951874 
	C104.162834,45.377678 106.624023,46.356941 108.028442,47.344360 
	C112.016495,50.148277 113.593613,54.396458 113.599159,59.598274 
	C112.142387,59.992981 111.078300,59.924442 110.014221,59.855907 
z"/>
<path fill="#11A7F5" opacity="1.000000" stroke="none" 
	d="
M109.824051,60.159798 
	C111.078300,59.924442 112.142387,59.992981 113.601898,59.998398 
	C114.227623,60.174503 114.474510,60.395172 114.885056,61.106888 
	C116.012436,62.759727 116.992744,63.902954 117.973053,65.046181 
	C117.656006,66.148178 117.338974,67.250175 117.031151,68.700836 
	C115.939354,70.045929 114.838341,71.042366 113.394768,72.111404 
	C112.138474,71.824265 111.055122,71.664581 110.346031,71.063431 
	C108.835281,69.782646 106.291931,68.173889 106.396278,66.878761 
	C106.574417,64.667809 108.463478,62.594700 109.824051,60.159798 
z"/>
<path fill="#02378D" opacity="1.000000" stroke="none" 
	d="
M141.989456,86.983231 
	C150.657913,86.919212 159.326385,86.855186 168.900970,86.814392 
	C172.237183,86.765900 174.667236,86.694183 177.097305,86.622467 
	C178.498352,86.769234 179.899384,86.916000 181.699127,87.218903 
	C179.174698,87.907425 176.270020,88.623917 173.323135,88.918587 
	C170.569748,89.193901 167.771591,89.021530 164.534027,89.078522 
	C156.924622,88.970131 149.774323,88.829430 142.304016,88.122833 
	C141.985809,87.365700 141.987640,87.174461 141.989456,86.983231 
z"/>
<path fill="#01267C" opacity="1.000000" stroke="none" 
	d="
M179.907379,108.637535 
	C173.623138,109.047569 167.255844,109.073029 160.447830,109.071564 
	C160.740845,108.075439 161.544968,106.223007 162.197296,106.275009 
	C168.088089,106.744545 173.952301,107.547653 179.907379,108.637535 
z"/>
<path fill="#1173BF" opacity="1.000000" stroke="none" 
	d="
M126.845886,86.821045 
	C127.041275,86.853790 127.236664,86.886536 127.604919,87.221237 
	C127.516090,88.014145 127.254402,88.505096 126.992714,88.996056 
	C124.655243,91.931633 121.545502,92.452271 117.560272,91.507927 
	C116.088676,90.373466 115.132690,89.578621 114.176712,88.783783 
	C114.120331,86.824059 114.063950,84.864334 114.282646,82.353470 
	C115.070396,81.572914 115.583069,81.343498 116.080711,81.518806 
	C116.996597,84.376244 117.376740,87.412949 119.093681,89.032219 
	C119.936325,89.826935 123.260559,87.998726 125.457283,87.342735 
	C125.929932,87.201591 126.383514,86.996613 126.845886,86.821045 
z"/>
<path fill="#02378D" opacity="1.000000" stroke="none" 
	d="
M127.421448,89.001923 
	C127.254402,88.505096 127.516090,88.014145 127.897926,87.270798 
	C129.729584,87.017570 131.441086,87.016739 133.856232,87.270874 
	C136.384460,87.701691 138.209045,87.877541 140.033646,88.053391 
	C139.245071,89.680695 138.456482,91.308006 138.253448,91.726982 
	C134.132782,90.649925 130.991486,89.828857 127.421448,89.001923 
z"/>
<path fill="#086BCC" opacity="1.000000" stroke="none" 
	d="
M177.068207,86.260201 
	C174.667236,86.694183 172.237183,86.765900 169.357666,86.740799 
	C168.940201,85.660049 168.972168,84.676125 169.013519,83.315765 
	C171.382309,82.905731 173.741714,82.872124 176.540970,82.883240 
	C177.000244,83.917946 177.019669,84.907944 177.068207,86.260201 
z"/>
<path fill="#1173BF" opacity="1.000000" stroke="none" 
	d="
M129.009155,83.000122 
	C128.551285,83.310135 128.093430,83.620155 127.123199,83.694443 
	C123.861244,80.743210 120.843430,79.026527 116.891029,80.503784 
	C116.999031,79.490807 117.092369,78.818436 117.185707,78.146065 
	C121.759041,76.818230 125.138794,79.015907 128.639496,82.203247 
	C128.994095,82.502739 129.009155,83.000122 129.009155,83.000122 
z"/>
<path fill="#1173BF" opacity="1.000000" stroke="none" 
	d="
M140.363068,88.027298 
	C138.209045,87.877541 136.384460,87.701691 134.284332,87.261002 
	C136.486954,86.809990 138.965134,86.623825 141.716370,86.710449 
	C141.987640,87.174461 141.985809,87.365700 141.994400,87.844940 
	C141.567368,88.089035 141.129929,88.045113 140.363068,88.027298 
z"/>
<path fill="#02378D" opacity="1.000000" stroke="none" 
	d="
M129.452026,82.989975 
	C129.009155,83.000122 128.994095,82.502739 128.995087,82.253967 
	C131.423584,81.967232 133.851089,81.929268 136.650848,82.204300 
	C137.023071,82.517296 136.994598,83.000641 136.994598,83.000641 
	C134.628036,82.993698 132.261475,82.986763 129.452026,82.989975 
z"/>
<path fill="#01257E" opacity="1.000000" stroke="none" 
	d="
M183.878326,82.145309 
	C182.275833,82.545937 180.541412,82.652702 178.396637,82.597137 
	C179.906326,82.240356 181.826355,82.045906 183.878326,82.145309 
z"/>
<path fill="#02378D" opacity="1.000000" stroke="none" 
	d="
M166.953720,82.277168 
	C165.312408,82.667259 163.536407,82.747803 161.333389,82.686127 
	C162.877258,82.351807 164.848145,82.159714 166.953720,82.277168 
z"/>
<path fill="#02378D" opacity="1.000000" stroke="none" 
	d="
M116.811501,78.131989 
	C117.092369,78.818436 116.999031,79.490807 116.695419,80.569305 
	C116.485146,80.975433 116.095741,81.114075 116.095741,81.114075 
	C115.583069,81.343498 115.070396,81.572914 114.303757,81.932220 
	C114.845634,80.747368 115.641464,79.432640 116.811501,78.131989 
z"/>
<path fill="#03429E" opacity="1.000000" stroke="none" 
	d="
M108.064270,109.427795 
	C106.802315,109.109016 105.419434,108.487434 103.960037,107.524109 
	C105.236794,107.829903 106.590073,108.477448 108.064270,109.427795 
z"/>
<path fill="#1173BF" opacity="1.000000" stroke="none" 
	d="
M137.210876,83.254410 
	C136.994598,83.000641 137.023071,82.517296 137.036346,82.275887 
	C138.095169,82.048798 139.140732,82.063126 140.585785,82.309113 
	C140.985291,82.540771 141.007431,83.006653 141.007431,83.006653 
	C139.814011,83.173836 138.620590,83.341011 137.210876,83.254410 
z"/>
<path fill="#02378D" opacity="1.000000" stroke="none" 
	d="
M141.419373,82.989792 
	C141.007431,83.006653 140.985291,82.540771 140.978821,82.308182 
	C142.267319,82.048340 143.562302,82.021095 144.974640,82.280968 
	C144.005112,82.703033 142.918213,82.837982 141.419373,82.989792 
z"/>
<path fill="#02378D" opacity="1.000000" stroke="none" 
	d="
M114.151917,89.152107 
	C115.132690,89.578621 116.088676,90.373466 117.148102,91.474167 
	C116.210083,91.026825 115.168610,90.273628 114.151917,89.152107 
z"/>
<path fill="#0350AC" opacity="1.000000" stroke="none" 
	d="
M118.189041,64.903770 
	C116.992744,63.902954 116.012436,62.759727 115.062088,61.280617 
	C116.268425,61.625217 117.444794,62.305710 118.894226,63.234718 
	C118.913200,63.909279 118.659111,64.335320 118.189041,64.903770 
z"/>
<path fill="#01267C" opacity="1.000000" stroke="none" 
	d="
M190.955673,108.945267 
	C190.345978,107.178879 190.272873,104.622688 189.034637,103.781441 
	C183.369354,99.932487 182.300369,94.554626 182.852417,87.976135 
	C183.951660,87.412056 185.091049,87.100288 186.230286,87.100830 
	C214.155685,87.114182 242.081055,87.160698 270.601196,87.618927 
	C275.119537,88.379654 279.043091,88.722733 282.916748,89.396667 
	C281.948761,90.810059 281.030670,91.892593 279.918304,93.204178 
	C281.349487,94.333595 282.505249,95.245651 284.346405,96.698578 
	C280.392120,97.632446 277.303345,98.361908 273.906891,99.164032 
	C277.673431,104.893547 285.014038,98.750107 288.530060,105.682915 
	C285.900208,105.820724 284.037964,105.559708 282.459839,106.111420 
	C281.133209,106.575218 280.137360,107.985252 278.661438,109.004532 
	C277.882141,109.013618 277.436493,108.994545 276.732605,108.703995 
	C271.422516,107.623909 266.376068,106.779716 261.316742,106.021217 
	C258.652863,105.621834 257.810486,107.146599 257.481262,109.622948 
	C238.348557,109.797592 219.668823,109.873108 200.989090,109.948624 
	C200.444839,110.016258 199.900574,110.083885 198.835144,110.394653 
	C197.898422,110.605423 197.482864,110.573059 197.067322,110.540695 
	C195.917130,110.317474 194.766953,110.094261 193.120941,109.671051 
	C192.068634,109.295792 191.512146,109.120529 190.955673,108.945267 
z"/>
<path fill="#032275" opacity="1.000000" stroke="none" 
	d="
M179.455109,138.057526 
	C177.875824,137.339325 176.644394,136.033173 175.637009,136.187698 
	C171.401276,136.837433 167.251038,138.022217 163.032730,138.815628 
	C160.208008,139.346939 157.321411,139.549423 155.507172,139.771286 
	C152.471359,141.750717 150.090988,143.425354 147.592300,144.899414 
	C144.783493,146.556442 141.867889,148.032471 138.408554,149.907043 
	C137.367477,142.796585 136.356339,135.890701 135.350616,129.021759 
	C135.693253,127.103554 136.080460,124.935890 136.467651,122.768227 
	C136.108078,122.688683 135.748505,122.609131 135.388916,122.529587 
	C137.587967,120.243172 139.787018,117.956764 141.996277,115.298843 
	C148.004288,114.644356 154.002090,114.361389 160.572235,114.454590 
	C163.953140,116.383308 166.714600,117.421028 169.077774,114.105042 
	C174.049820,114.100975 179.021881,114.096909 184.530945,114.421677 
	C186.067703,114.529572 187.067444,114.308632 188.067169,114.087692 
	C188.496964,114.086655 188.926758,114.085617 189.897430,114.351105 
	C191.306168,114.773979 192.173996,114.930336 193.037094,115.448776 
	C196.869400,120.124474 200.637833,124.501656 204.560638,128.735825 
	C209.639771,134.218109 214.842041,139.586334 219.631866,145.012329 
	C216.076813,145.689728 217.222244,146.539780 218.626480,148.509186 
	C221.152298,152.051605 222.931244,156.126572 224.815948,160.189117 
	C224.482391,160.548660 224.355164,160.698135 224.227921,160.847610 
	C214.906097,142.636429 198.363846,138.519318 179.455109,138.057526 
M181.577515,136.514404 
	C181.577515,136.514404 181.478104,136.570633 181.577515,136.514404 
z"/>
<path fill="#0970D1" opacity="1.000000" stroke="none" 
	d="
M200.993164,110.348053 
	C219.668823,109.873108 238.348557,109.797592 257.942688,109.758591 
	C264.901672,109.521889 270.946259,109.248680 276.990845,108.975471 
	C277.436493,108.994545 277.882141,109.013618 279.128906,109.046188 
	C293.608093,109.234688 307.286163,109.409691 321.424683,109.699326 
	C326.881744,109.806015 331.878357,109.798073 337.338196,109.848434 
	C341.228638,109.877762 344.655853,109.848785 348.480652,109.847092 
	C351.702698,111.187057 354.683533,112.250069 357.302612,113.890648 
	C359.438110,115.228302 361.141022,117.256599 363.067566,119.299210 
	C363.728241,120.085968 364.357117,120.552177 365.151154,121.306503 
	C369.937836,126.274406 374.533813,130.979782 379.189606,135.625198 
	C382.393921,138.822372 385.681183,141.936401 388.983246,145.390167 
	C389.102325,146.097229 389.169495,146.501724 388.893005,146.959503 
	C388.005249,147.399536 387.461212,147.786285 386.598785,148.081757 
	C385.198669,147.297516 384.116943,146.604584 382.833038,145.869720 
	C382.630859,145.827805 382.217987,145.832672 382.174988,145.614105 
	C382.131989,145.395554 382.042511,144.957794 381.997681,144.640198 
	C381.304626,143.878906 380.656403,143.435226 379.880737,142.787842 
	C379.545349,142.331970 379.291565,142.140366 378.863586,141.701447 
	C376.840698,139.910828 374.946228,138.428131 373.025879,136.972595 
	C373.000000,136.999741 373.054565,136.948639 373.013428,136.625183 
	C371.980865,135.203369 370.989471,134.105042 369.848694,132.709381 
	C367.154724,130.253571 364.610138,128.095062 362.032776,125.968277 
	C362.000000,126.000000 362.063385,125.934456 362.017151,125.602005 
	C360.300568,123.534569 358.630188,121.799576 356.974365,120.037857 
	C356.988892,120.011131 356.935394,120.040161 356.820831,119.742714 
	C355.828888,118.939491 354.951477,118.433708 354.037048,117.963814 
	C354.000000,117.999695 354.070709,117.924637 354.012146,117.545074 
	C352.351837,116.109207 350.783264,114.206398 349.142670,114.142097 
	C341.760651,113.852768 334.358124,114.085190 326.501099,114.100983 
	C321.685181,114.142403 317.331207,114.212318 312.515869,114.200714 
	C290.037994,114.168434 268.021423,114.217659 245.540405,114.184059 
	C238.402390,114.102188 231.728821,114.103142 224.584045,114.047699 
	C216.375885,114.034485 208.638916,114.077682 200.917664,114.061073 
	C200.933395,114.001274 201.037399,113.934349 201.037399,113.934349 
	C201.024017,112.872063 201.010635,111.809769 200.993164,110.348053 
z"/>
<path fill="#01096A" opacity="1.000000" stroke="none" 
	d="
M219.991287,145.003662 
	C214.842041,139.586334 209.639771,134.218109 204.560638,128.735825 
	C200.637833,124.501656 196.869400,120.124474 193.433136,115.424370 
	C195.559509,114.742218 197.285095,114.446579 199.010666,114.150925 
	C199.463547,114.124374 199.916412,114.097824 200.703339,114.002808 
	C201.037399,113.934349 200.933395,114.001274 200.919708,114.299576 
	C200.906006,114.597878 200.948792,115.075233 201.016113,115.404137 
	C208.275238,123.206566 215.425690,130.720734 222.699570,138.113495 
	C223.908249,139.341965 225.568710,140.640640 227.169418,140.870895 
	C231.738358,141.528107 236.384079,141.651352 241.332016,142.136261 
	C247.173691,145.675720 252.606018,144.966476 258.462036,141.984222 
	C263.620026,141.985260 268.311584,141.983368 273.300049,142.275391 
	C286.498535,142.692078 299.400116,142.826553 312.302002,142.898331 
	C312.867981,142.901489 313.437134,142.330521 314.440430,142.023438 
	C316.908295,142.021729 318.940552,142.023590 321.064972,142.393555 
	C323.767822,144.170502 326.378510,145.579361 328.699188,147.253510 
	C325.959625,147.600052 323.510101,147.681290 320.952026,147.428528 
	C318.894501,147.064880 316.945526,147.035248 314.538910,147.015259 
	C310.723602,147.025986 307.365875,147.027054 303.712219,146.730835 
	C287.418762,146.294739 271.421326,146.127747 255.423599,146.098907 
	C254.608047,146.097443 253.790359,147.284225 252.652435,147.946533 
	C251.902084,148.002350 251.472961,148.029404 250.571518,148.031952 
	C242.401093,148.014053 234.702988,148.020645 226.662262,147.961426 
	C224.212128,146.933289 222.104599,145.970963 219.998535,145.004654 
	C219.999969,145.000671 219.991287,145.003662 219.991287,145.003662 
z"/>
<path fill="#03429E" opacity="1.000000" stroke="none" 
	d="
M198.755585,113.835770 
	C197.285095,114.446579 195.559509,114.742218 193.437897,115.062271 
	C192.173996,114.930336 191.306168,114.773979 190.219055,114.372856 
	C192.833359,113.925598 195.666931,113.723106 198.755585,113.835770 
z"/>
<path fill="#03429E" opacity="1.000000" stroke="none" 
	d="
M168.626099,114.055481 
	C166.714600,117.421028 163.953140,116.383308 161.035217,114.465027 
	C163.342056,114.068169 165.758255,114.037048 168.626099,114.055481 
z"/>
<path fill="#02378D" opacity="1.000000" stroke="none" 
	d="
M81.852661,92.718002 
	C79.981644,91.264969 77.989052,89.504990 75.993240,87.376968 
	C77.903709,88.809647 79.817398,90.610352 81.852661,92.718002 
z"/>
<path fill="#03429E" opacity="1.000000" stroke="none" 
	d="
M190.630707,108.956589 
	C191.512146,109.120529 192.068634,109.295792 192.811539,109.696426 
	C191.461273,110.081863 189.924576,110.241920 188.179138,110.097519 
	C188.748856,109.518021 189.527298,109.242973 190.630707,108.956589 
z"/>
<path fill="#02378D" opacity="1.000000" stroke="none" 
	d="
M73.125046,84.488144 
	C72.131927,83.784828 71.059921,82.771423 69.981186,81.392197 
	C70.998367,82.076927 72.022263,83.127480 73.125046,84.488144 
z"/>
<path fill="#03429E" opacity="1.000000" stroke="none" 
	d="
M187.674286,114.044540 
	C187.067444,114.308632 186.067703,114.529572 184.992050,114.432510 
	C185.704544,114.076813 186.492981,114.039101 187.674286,114.044540 
z"/>
<path fill="#02378D" opacity="1.000000" stroke="none" 
	d="
M84.101318,96.217606 
	C83.916267,95.987312 83.949173,95.503433 83.966095,95.261711 
	C84.615631,95.482063 85.248253,95.944145 85.891876,96.730713 
	C85.364037,96.852776 84.825195,96.650337 84.101318,96.217606 
z"/>
<path fill="#032275" opacity="1.000000" stroke="none" 
	d="
M104.880371,114.630173 
	C104.399719,114.818054 103.742157,114.768333 103.038773,114.440277 
	C103.563118,114.238808 104.133286,114.315689 104.880371,114.630173 
z"/>
<path fill="#0970D1" opacity="1.000000" stroke="none" 
	d="
M197.158981,110.849068 
	C197.482864,110.573059 197.898422,110.605423 198.622955,110.575302 
	C198.371506,110.727692 197.811066,110.942566 197.158981,110.849068 
z"/>
<path fill="#E4EEF5" opacity="1.000000" stroke="none" 
	d="
M267.949677,571.397156 
	C268.142029,569.884033 268.443115,568.792786 268.817566,567.002441 
	C271.888306,553.173035 285.367523,548.614380 297.275543,552.196106 
	C301.046387,553.330322 304.366882,555.656189 303.212494,559.954285 
	C302.665985,561.989197 299.663025,564.485413 297.530457,564.739868 
	C293.097717,565.268677 291.878510,561.688599 291.716431,557.841675 
	C291.711060,557.713928 291.507294,557.594482 291.423218,557.501282 
	C285.790131,556.836487 284.569916,557.245667 280.542389,561.154663 
	C279.229156,564.505066 278.258942,567.712402 277.288757,570.919678 
	C277.212402,572.242920 277.024139,573.570679 277.077301,574.888672 
	C277.536926,586.288147 285.186890,590.946960 295.467316,586.119568 
	C295.917755,585.908142 296.361115,585.680603 296.817657,585.483337 
	C299.298737,584.411011 302.050659,581.003479 304.158508,585.597656 
	C306.151276,589.941040 301.833710,590.481384 299.526001,591.674133 
	C286.114166,598.605774 273.124512,594.260742 269.493530,581.643982 
	C268.586548,578.492371 268.514313,575.100464 267.949677,571.397156 
z"/>
<path fill="#0A4DBA" opacity="1.000000" stroke="none" 
	d="
M277.679352,571.019592 
	C278.258942,567.712402 279.229156,564.505066 280.760498,561.421997 
	C288.138153,567.407349 295.803650,568.127014 304.148804,565.552734 
	C305.966156,564.992126 308.036652,565.252075 309.990540,565.134033 
	C309.845551,567.145386 309.700562,569.156677 309.419617,571.516907 
	C300.323547,571.905396 291.363251,571.984070 282.403473,571.944946 
	C280.957794,571.938660 279.514404,571.407898 277.679352,571.019592 
z"/>
<path fill="#092672" opacity="1.000000" stroke="none" 
	d="
M205.914246,584.921326 
	C205.246094,585.650085 204.577927,586.378906 203.875854,587.433228 
	C201.404388,587.758850 198.966843,587.758850 196.566818,587.758850 
	C195.983444,577.845276 195.428421,568.413513 195.087982,558.352600 
	C194.423370,552.674438 196.028152,548.943237 201.381790,546.996582 
	C206.443268,548.399170 204.650009,543.021973 207.334961,542.049683 
	C209.327667,543.598328 210.723099,543.961975 212.653320,542.087341 
	C213.771439,541.001343 216.188736,540.048828 217.445892,540.532410 
	C221.480896,542.084473 224.413986,546.226257 229.422638,545.622437 
	C232.172760,551.638855 237.122971,547.863220 240.533875,547.344421 
	C245.495102,546.589844 246.666000,550.442871 248.978516,552.521118 
	C251.508453,554.794678 249.310333,557.263855 247.313309,559.252930 
	C245.126434,561.431030 243.093323,563.763428 240.993210,566.028564 
	C240.689774,566.006104 240.386307,565.983643 239.631134,565.879211 
	C239.873856,562.293518 239.907562,558.533691 244.786148,557.593750 
	C245.737778,557.410400 246.167191,554.516663 247.233765,551.901428 
	C240.897934,551.901428 235.795059,551.759460 230.714340,552.031494 
	C229.794128,552.080750 228.181610,553.921814 228.283966,554.766541 
	C228.424133,555.922974 229.753220,557.122131 230.854767,557.907166 
	C231.545135,558.399109 232.753510,558.164246 234.345749,558.313110 
	C233.267868,561.200256 232.301392,563.789062 230.744293,566.628113 
	C225.799759,564.403564 223.166519,567.627747 220.237549,569.872375 
	C219.433395,567.882751 218.609543,565.900818 217.829971,563.901611 
	C216.791519,561.238525 215.241608,558.514282 220.006821,557.638916 
	C220.790100,557.495056 221.596268,555.233154 221.593933,553.951538 
	C221.592651,553.255493 219.928574,551.992188 218.983017,551.961670 
	C213.197845,551.775146 207.403656,551.868347 201.190430,551.868347 
	C201.030411,553.924377 200.908493,555.490723 200.732544,557.751343 
	C207.008743,556.571533 207.837021,561.123657 209.270523,565.147705 
	C210.552567,568.746460 211.973190,572.295776 213.352646,576.234253 
	C212.594955,577.463013 211.817291,578.324402 210.757172,579.666443 
	C210.219482,580.353271 210.030273,580.608459 209.600372,580.978088 
	C208.850311,581.677490 208.406982,582.311401 207.818359,583.197021 
	C207.086792,583.939636 206.500519,584.430481 205.914246,584.921326 
M215.634155,548.888977 
	C215.754349,548.781738 215.919525,548.693542 215.976044,548.559448 
	C216.005081,548.490601 215.842865,548.243530 215.762482,548.241211 
	C214.906372,548.217285 214.049332,548.226379 213.192551,548.226379 
	C213.191666,548.442505 213.190781,548.658569 213.189896,548.874695 
	C213.814697,548.908081 214.439514,548.941528 215.634155,548.888977 
z"/>
<path fill="#163A84" opacity="1.000000" stroke="none" 
	d="
M229.717178,570.086548 
	C228.283768,574.384888 226.850342,578.683105 225.416931,582.981384 
	C224.993835,582.925720 224.570724,582.870056 224.147629,582.814331 
	C223.245605,579.855774 222.343582,576.897217 221.844925,573.503174 
	C224.737930,572.073975 227.227554,571.080261 229.717178,570.086548 
z"/>
<path fill="#2159B0" opacity="1.000000" stroke="none" 
	d="
M229.907578,569.873901 
	C227.227554,571.080261 224.737930,572.073975 221.816345,573.098083 
	C220.952026,572.241821 220.519699,571.355042 220.162445,570.170349 
	C223.166519,567.627747 225.799759,564.403564 230.527313,566.926147 
	C230.633270,567.869751 230.365616,568.765503 229.907578,569.873901 
z"/>
<path fill="#022072" opacity="1.000000" stroke="none" 
	d="
M167.757065,553.283386 
	C164.843033,552.988708 161.635498,554.556335 159.665176,550.741699 
	C159.203323,549.847534 156.378830,550.173767 154.404572,549.624146 
	C159.985886,543.734131 165.870529,538.226013 171.601517,532.562378 
	C176.454529,527.766357 181.129700,522.790405 186.283310,517.887573 
	C189.334000,517.255371 191.957657,516.310425 194.642487,516.080750 
	C198.411621,515.758423 202.226257,515.967651 206.484894,515.961487 
	C211.309601,516.016479 215.670853,516.059448 219.976349,516.441956 
	C217.623474,519.196045 215.326355,521.610596 212.968018,523.645264 
	C212.273483,522.574585 211.673965,521.325073 211.001358,521.284485 
	C206.926849,521.038269 202.942734,520.952454 200.207535,525.096375 
	C197.646561,528.976318 195.545334,532.639954 198.964874,537.356567 
	C193.322266,544.767029 187.148682,550.728149 176.616089,549.052856 
	C174.420807,550.024231 172.621323,550.947815 170.821823,551.871338 
	C170.480072,551.890991 170.138321,551.910645 169.320282,552.038818 
	C168.559265,552.461243 168.274551,552.775208 167.989807,553.089172 
	C167.989807,553.089172 167.875504,553.298950 167.757065,553.283386 
z"/>
<path fill="#142A6D" opacity="1.000000" stroke="none" 
	d="
M187.452515,582.204712 
	C184.651657,586.625732 179.058807,589.148193 175.100677,587.244324 
	C173.190353,586.325562 171.043198,583.378845 171.155579,581.477539 
	C171.269974,579.541992 173.754669,576.627991 175.688812,576.161011 
	C179.256256,575.299805 183.180145,575.914917 187.497253,575.914917 
	C187.497253,577.931396 187.497253,579.865601 187.452515,582.204712 
z"/>
<path fill="#010C60" opacity="1.000000" stroke="none" 
	d="
M177.011871,549.005005 
	C187.148682,550.728149 193.322266,544.767029 199.362183,537.354797 
	C200.857559,537.004639 201.917389,537.002014 203.140930,537.306641 
	C207.580017,541.983582 201.890671,543.899170 200.994095,546.995117 
	C196.028152,548.943237 194.423370,552.674438 195.000687,557.945068 
	C191.154312,550.965027 184.636841,550.607666 177.444550,550.497498 
	C177.024597,549.763855 177.018234,549.384460 177.011871,549.005005 
z"/>
<path fill="#011560" opacity="1.000000" stroke="none" 
	d="
M163.374710,588.480225 
	C162.656204,587.774170 161.037033,586.590393 161.140976,585.584595 
	C161.564590,581.484924 162.671875,577.460876 163.223892,573.368042 
	C163.681396,569.975952 163.713440,566.526428 164.251495,562.921509 
	C167.026520,563.559326 170.087311,565.672913 171.793167,564.878174 
	C174.071991,563.816589 175.299210,560.497803 176.978058,558.148376 
	C176.881470,557.475037 176.784882,556.801697 176.688293,556.128296 
	C179.767639,557.500610 182.847000,558.872925 185.619171,560.572144 
	C182.772385,560.220764 180.232758,559.542542 177.422348,558.791931 
	C177.022980,561.966980 176.745911,564.169678 176.290039,567.793823 
	C180.237564,567.134521 183.604874,566.572144 186.972198,566.009766 
	C188.170837,568.487793 187.107224,569.285095 184.668930,569.265015 
	C181.527512,569.239136 178.361084,569.108948 175.247391,569.430359 
	C164.807938,570.507935 159.944916,578.179810 163.374710,588.480225 
z"/>
<path fill="#011460" opacity="1.000000" stroke="none" 
	d="
M187.058334,565.631653 
	C183.604874,566.572144 180.237564,567.134521 176.290039,567.793823 
	C176.745911,564.169678 177.022980,561.966980 177.422348,558.791931 
	C180.232758,559.542542 182.772385,560.220764 185.699036,560.928955 
	C186.438873,562.390442 186.791672,563.822021 187.058334,565.631653 
z"/>
<path fill="#051F6B" opacity="1.000000" stroke="none" 
	d="
M176.616089,549.052856 
	C177.018234,549.384460 177.024597,549.763855 177.026413,550.427979 
	C175.179108,551.153503 173.336334,551.594360 171.157684,551.953247 
	C172.621323,550.947815 174.420807,550.024231 176.616089,549.052856 
z"/>
<path fill="#051F6B" opacity="1.000000" stroke="none" 
	d="
M168.339294,553.050903 
	C168.274551,552.775208 168.559265,552.461243 169.149094,552.027588 
	C169.199066,552.276245 168.943924,552.644470 168.339294,553.050903 
z"/>
<path fill="#032780" opacity="1.000000" stroke="none" 
	d="
M202.977219,536.999390 
	C201.917389,537.002014 200.857559,537.004639 199.400421,537.009094 
	C195.545334,532.639954 197.646561,528.976318 200.207535,525.096375 
	C202.942734,520.952454 206.926849,521.038269 211.001358,521.284485 
	C211.673965,521.325073 212.273483,522.574585 213.004822,523.999512 
	C214.067184,526.166748 215.031479,527.599915 216.055664,529.373901 
	C218.835037,531.486572 221.554535,533.258423 224.274033,535.030212 
	C224.226486,535.554565 224.178940,536.078857 224.131393,536.603210 
	C223.971359,534.368530 223.811310,532.133911 223.651260,529.899231 
	C226.046753,528.026917 227.534698,525.494019 229.035309,522.968567 
	C229.602524,522.013916 230.281937,521.116760 230.763290,520.122375 
	C231.567322,518.461304 232.253220,516.743103 233.457794,515.029480 
	C241.615921,515.014587 249.305069,515.019165 257.037842,515.314697 
	C257.401611,515.916809 257.721710,516.227966 258.041809,516.539124 
	C258.363770,516.037292 258.685730,515.535522 259.407654,515.023560 
	C260.874359,515.014465 261.941071,515.015564 263.208344,515.254211 
	C264.584259,514.609497 265.759644,513.727295 266.935364,512.626587 
	C266.935638,512.408081 266.970886,511.972504 267.270020,511.867004 
	C268.686188,511.176544 269.803223,510.591614 271.347748,509.994141 
	C273.730042,509.664001 275.684143,509.076172 277.639771,509.071045 
	C295.103271,509.025299 312.567047,509.082336 330.011841,509.485870 
	C327.523804,511.125549 325.110779,513.308960 322.574127,513.463562 
	C316.942169,513.806702 316.011230,517.607056 315.075989,521.724121 
	C314.784698,523.006226 314.856506,524.368042 314.601288,525.661621 
	C313.509308,531.197205 316.350281,535.795410 321.777832,536.708008 
	C323.968536,537.076355 326.272919,536.768799 329.959625,536.768799 
	C328.522003,539.679993 327.530975,542.057739 326.218353,544.242188 
	C324.862427,546.498718 321.713013,548.139038 324.180511,551.446594 
	C317.830994,553.962585 312.663361,557.716919 310.216309,564.846191 
	C308.036652,565.252075 305.966156,564.992126 304.148804,565.552734 
	C295.803650,568.127014 288.138153,567.407349 281.103516,561.278931 
	C284.569916,557.245667 285.790131,556.836487 291.423218,557.501282 
	C291.507294,557.594482 291.711060,557.713928 291.716431,557.841675 
	C291.878510,561.688599 293.097717,565.268677 297.530457,564.739868 
	C299.663025,564.485413 302.665985,561.989197 303.212494,559.954285 
	C304.366882,555.656189 301.046387,553.330322 297.275543,552.196106 
	C285.367523,548.614380 271.888306,553.173035 268.741760,566.668457 
	C259.705811,566.702026 250.819000,566.370544 241.462692,566.033813 
	C243.093323,563.763428 245.126434,561.431030 247.313309,559.252930 
	C249.310333,557.263855 251.508453,554.794678 248.978516,552.521118 
	C246.666000,550.442871 245.495102,546.589844 240.533875,547.344421 
	C237.122971,547.863220 232.172760,551.638855 229.422638,545.622437 
	C224.413986,546.226257 221.480896,542.084473 217.445892,540.532410 
	C216.188736,540.048828 213.771439,541.001343 212.653320,542.087341 
	C210.723099,543.961975 209.327667,543.598328 207.332672,541.621399 
	C207.060913,539.716370 207.116287,538.291260 207.507019,536.904053 
	C209.465393,536.685181 212.412796,536.622681 212.483871,536.138123 
	C212.804733,533.951233 212.365585,531.638428 212.097763,529.383179 
	C211.739594,526.367004 209.482101,525.269531 207.165359,526.230896 
	C205.451721,526.941895 203.979874,529.000366 203.141464,530.803223 
	C202.519760,532.140076 203.088562,534.030640 203.088257,536.005554 
	C203.025375,536.557556 203.001297,536.778503 202.977219,536.999390 
M319.559998,549.939331 
	C319.869324,549.180115 320.178650,548.420898 320.487976,547.661682 
	C319.098358,547.605530 317.688965,547.404663 316.332367,547.590088 
	C315.972046,547.639404 315.773926,548.875122 315.501312,549.566101 
	C316.624115,549.757996 317.746887,549.949829 319.559998,549.939331 
M254.502533,560.693359 
	C255.034683,561.024597 255.566818,561.355774 256.098969,561.687012 
	C256.230133,561.419006 256.361328,561.151001 256.492523,560.882996 
	C255.971283,560.682190 255.450058,560.481384 254.502533,560.693359 
z"/>
<path fill="#1058CB" opacity="1.000000" stroke="none" 
	d="
M205.943863,585.275391 
	C206.500519,584.430481 207.086792,583.939636 208.147125,583.173096 
	C209.049835,582.235840 209.478470,581.574219 209.907104,580.912598 
	C210.030273,580.608459 210.219482,580.353271 210.669754,580.006775 
	C211.510773,580.602112 212.800568,581.882019 212.707214,581.992432 
	C211.057022,583.944214 209.238327,585.753540 207.456085,587.593689 
	C206.961899,586.938904 206.467697,586.284180 205.943863,585.275391 
z"/>
<path fill="#24B6F9" opacity="1.000000" stroke="none" 
	d="
M322.118713,625.896729 
	C323.370239,624.363586 324.504028,622.899536 325.991272,622.268616 
	C326.497498,622.053894 328.669098,623.916504 328.693573,624.865723 
	C328.726837,626.153625 327.264435,628.640137 326.730225,628.565308 
	C325.125305,628.340576 323.659454,627.122620 322.118713,625.896729 
z"/>
<path fill="#04308B" opacity="1.000000" stroke="none" 
	d="
M416.942383,584.473999 
	C416.398682,585.130920 415.868195,585.325745 415.168640,585.752808 
	C414.245026,585.989685 413.490448,585.994263 412.358643,586.018494 
	C408.829590,586.016785 405.608185,586.402954 402.542206,585.879211 
	C397.836884,585.075439 400.940491,580.290344 399.025269,577.542542 
	C400.660950,576.298889 402.500427,575.698853 403.893555,574.555115 
	C408.058105,571.136169 412.047913,567.504456 416.424744,563.843262 
	C416.814240,570.491150 416.884888,577.251465 416.942383,584.473999 
z"/>
<path fill="#072F84" opacity="1.000000" stroke="none" 
	d="
M384.117523,588.151672 
	C382.728546,588.097229 381.315521,588.039429 379.479462,587.975952 
	C379.056427,587.970276 378.993835,587.952209 378.979401,587.599792 
	C378.569244,586.742737 378.096802,585.756714 377.789307,585.805725 
	C373.288086,586.523254 372.938049,583.613586 372.919159,580.387634 
	C372.902557,577.560608 373.165710,574.705200 372.851807,571.913269 
	C372.418274,568.057373 373.750397,565.085205 376.697510,562.849182 
	C381.528198,559.184143 385.988525,558.722351 387.161346,565.519165 
	C387.826752,569.375305 388.429169,573.242310 389.053009,577.499268 
	C388.326752,579.337219 387.586853,580.770630 386.893188,582.226013 
	C385.954437,584.195679 385.051117,586.182190 384.132599,588.161499 
	C384.132599,588.161499 384.093475,588.148315 384.117523,588.151672 
z"/>
<path fill="#011999" opacity="1.000000" stroke="none" 
	d="
M568.843872,509.760834 
	C567.831299,509.843842 566.818665,509.926849 565.011047,509.954956 
	C561.444885,509.606812 558.668518,509.021790 555.903503,509.071075 
	C544.267273,509.278320 532.634338,509.675079 520.724243,509.723206 
	C518.296509,509.308807 516.144714,509.173645 513.528870,509.030304 
	C508.714020,509.031067 504.363251,509.040039 499.542847,509.034302 
	C483.441650,509.015900 467.809906,508.980957 452.178711,509.052704 
	C450.449341,509.060638 448.722748,509.677826 446.527863,510.008606 
	C440.706757,510.330841 435.352692,510.656982 429.998596,510.983154 
	C421.288818,510.376953 412.585876,509.523834 403.865997,509.282562 
	C400.223419,509.181763 396.544861,510.381470 392.443054,510.987305 
	C391.887726,510.669342 391.694000,510.412933 391.149994,509.713776 
	C388.739716,507.089691 378.812286,505.910004 376.611450,507.669495 
	C376.109955,508.070435 376.190826,509.199799 376.003662,509.993896 
	C375.558014,509.997375 375.112396,510.000824 374.078125,509.896240 
	C373.000214,509.529724 372.510925,509.271271 372.153015,508.723785 
	C373.216003,507.058075 374.147583,505.681427 375.484467,504.302490 
	C381.353882,504.473511 386.817963,504.646759 392.456055,504.835083 
	C392.630066,504.850159 392.964874,504.949615 393.039429,505.318542 
	C395.285706,507.505829 397.355682,508.136627 399.391632,505.101013 
	C400.038300,504.981720 400.411133,504.934509 401.261230,504.962708 
	C442.157074,505.039825 482.575745,505.098328 522.994019,504.988800 
	C529.929321,504.970001 536.862732,504.235229 544.193970,504.028534 
	C553.685181,504.485626 562.779297,504.745422 571.853271,505.337738 
	C570.836670,507.033783 569.840271,508.397308 568.843872,509.760834 
z"/>
<path fill="#053FC9" opacity="1.000000" stroke="none" 
	d="
M375.079163,504.304749 
	C374.147583,505.681427 373.216003,507.058075 371.891235,508.925079 
	C371.311310,510.262268 371.124573,511.109100 370.937866,511.955963 
	C370.518311,511.982635 370.098755,512.009277 369.367065,512.075073 
	C367.947662,511.409332 366.840363,510.704437 365.360901,509.755676 
	C364.988678,509.511810 365.007629,509.028931 365.312378,508.872559 
	C366.771149,506.812836 367.925201,504.909485 369.406311,503.014709 
	C370.489899,502.692841 371.246429,502.362396 372.375580,502.022095 
	C373.490204,501.945007 374.232147,501.877808 375.224487,501.851074 
	C375.641937,501.917236 375.809052,501.942841 375.861145,502.257996 
	C375.523804,503.133331 375.301483,503.719025 375.079163,504.304749 
z"/>
<path fill="#011480" opacity="1.000000" stroke="none" 
	d="
M376.469391,509.996155 
	C376.190826,509.199799 376.109955,508.070435 376.611450,507.669495 
	C378.812286,505.910004 388.739716,507.089691 390.933167,509.601776 
	C386.304108,509.993744 381.619629,509.996063 376.469391,509.996155 
z"/>
<path fill="#00359C" opacity="1.000000" stroke="none" 
	d="
M364.407227,518.015869 
	C365.458923,516.695068 366.872192,515.363770 368.612152,513.973328 
	C367.548798,515.277893 366.158783,516.641602 364.407227,518.015869 
z"/>
<path fill="#00359C" opacity="1.000000" stroke="none" 
	d="
M371.295776,511.966278 
	C371.124573,511.109100 371.311310,510.262268 371.759827,509.214111 
	C372.510925,509.271271 373.000214,509.529724 373.743866,509.899567 
	C373.216766,510.666168 372.435242,511.321381 371.295776,511.966278 
z"/>
<path fill="#2B4B89" opacity="1.000000" stroke="none" 
	d="
M389.059967,577.104431 
	C388.429169,573.242310 387.826752,569.375305 387.161346,565.519165 
	C385.988525,558.722351 381.528198,559.184143 376.697510,562.849182 
	C373.750397,565.085205 372.418274,568.057373 372.851807,571.913269 
	C373.165710,574.705200 372.902557,577.560608 372.919159,580.387634 
	C372.938049,583.613586 373.288086,586.523254 377.789307,585.805725 
	C378.096802,585.756714 378.569244,586.742737 378.990387,587.569092 
	C376.582550,587.890869 374.149292,587.890869 371.280640,587.890869 
	C371.280640,580.084595 371.128693,572.972107 371.347992,565.871094 
	C371.449951,562.568970 377.948090,558.640015 382.670929,558.642456 
	C386.753387,558.644531 388.743958,561.054810 389.154846,564.799072 
	C389.570251,568.584595 389.656036,572.406250 389.646667,576.636597 
	C389.407104,577.061096 389.059967,577.104431 389.059967,577.104431 
z"/>
<path fill="#2159B0" opacity="1.000000" stroke="none" 
	d="
M389.053009,577.499268 
	C389.059967,577.104431 389.407104,577.061096 389.582031,577.066895 
	C389.824951,578.712708 389.757385,580.371948 389.987671,581.988770 
	C390.553589,585.961182 388.734924,587.807556 384.523071,588.098267 
	C385.051117,586.182190 385.954437,584.195679 386.893188,582.226013 
	C387.586853,580.770630 388.326752,579.337219 389.053009,577.499268 
z"/>
<path fill="#012D8D" opacity="1.000000" stroke="none" 
	d="
M365.007629,509.028931 
	C365.007629,509.028931 364.988678,509.511810 364.978668,509.753235 
	C353.817108,520.986572 342.619965,531.933044 331.553772,543.010315 
	C329.433685,545.132507 327.829041,547.769775 325.993103,550.532104 
	C325.611877,551.090271 325.224152,551.285767 324.508453,551.463928 
	C321.713013,548.139038 324.862427,546.498718 326.218353,544.242188 
	C327.530975,542.057739 328.522003,539.679993 329.959625,536.768799 
	C326.272919,536.768799 323.968536,537.076355 321.777832,536.708008 
	C316.350281,535.795410 313.509308,531.197205 314.601288,525.661621 
	C314.856506,524.368042 314.784698,523.006226 315.075989,521.724121 
	C316.011230,517.607056 316.942169,513.806702 322.574127,513.463562 
	C325.110779,513.308960 327.523804,511.125549 330.476685,509.459351 
	C341.963654,509.020172 352.966827,508.984283 364.229187,508.971558 
	C364.661438,509.006195 364.834534,509.017578 365.007629,509.028931 
M326.934479,530.455627 
	C326.536346,528.496094 326.323456,526.459229 325.567078,524.649414 
	C325.433075,524.328796 322.178802,524.549561 321.858856,525.226379 
	C321.168030,526.687683 320.752930,529.113953 321.536865,530.187134 
	C322.227509,531.132568 324.701263,530.775269 326.934479,530.455627 
z"/>
<path fill="#0C307F" opacity="1.000000" stroke="none" 
	d="
M339.840881,575.495117 
	C337.469299,585.151978 334.036804,588.464661 327.716858,587.613037 
	C322.063873,586.851257 318.288544,581.395142 318.436127,573.038147 
	C318.501587,569.331177 318.981628,565.076111 320.900391,562.129211 
	C322.498047,559.675598 326.339569,557.869446 329.429688,557.411072 
	C333.133240,556.861755 336.850708,558.932434 337.976471,562.885437 
	C339.092926,566.805664 339.266510,570.994507 339.840881,575.495117 
z"/>
<path fill="#032A82" opacity="1.000000" stroke="none" 
	d="
M755.000000,551.012634 
	C755.847717,550.137939 757.249390,548.384094 757.461609,548.517761 
	C762.065125,551.418640 766.286987,547.661438 770.758301,547.789612 
	C776.284912,547.947998 779.683777,550.008423 782.049866,554.753235 
	C782.555420,555.767029 784.115051,556.255310 785.598145,557.375610 
	C786.045044,558.288879 786.082764,558.812195 786.120483,559.335449 
	C786.120544,559.335449 786.219543,559.677734 785.793823,559.893555 
	C784.576904,561.406189 783.785828,562.703125 782.847778,563.663086 
	C781.089600,561.135071 779.478455,558.943970 777.867249,556.752808 
	C777.867249,555.327393 777.867249,553.901978 777.867249,552.058655 
	C771.234802,552.058655 765.126343,551.913513 759.038208,552.199768 
	C758.009583,552.248169 757.068420,554.155640 755.659363,555.536072 
	C755.154602,554.249512 755.077332,552.631104 755.000000,551.012634 
z"/>
<path fill="#04277D" opacity="1.000000" stroke="none" 
	d="
M801.985779,557.995117 
	C802.153992,557.991272 802.322205,557.987366 802.742798,557.979126 
	C803.163025,557.976196 803.330872,557.977661 803.749390,557.961426 
	C805.782349,557.943604 807.564819,557.943604 810.071960,557.943604 
	C807.955261,566.747253 806.016785,574.809387 804.078369,582.871460 
	C803.660767,582.916748 803.243164,582.961975 802.825623,583.007263 
	C800.385620,576.075806 797.945679,569.144348 795.661682,561.710083 
	C795.817627,561.207275 796.054016,560.950256 796.405396,561.033447 
	C798.374207,564.720520 799.991638,568.324402 802.060364,572.933655 
	C804.033997,568.806030 805.729004,565.261169 807.269836,562.038696 
	C804.883179,560.212341 803.434509,559.103760 801.985779,557.995117 
z"/>
<path fill="#04277D" opacity="1.000000" stroke="none" 
	d="
M782.994690,564.000000 
	C783.785828,562.703125 784.576904,561.406189 785.711182,560.051331 
	C784.049561,566.094116 782.056030,572.198608 780.030640,578.292419 
	C779.627930,579.504028 779.103088,580.674927 778.127625,583.152039 
	C776.572021,577.216187 775.245483,572.327271 774.025574,567.411926 
	C773.551758,565.502930 772.884766,563.477539 773.128174,561.612488 
	C773.291687,560.358887 774.898865,559.293640 776.279907,558.588257 
	C776.476196,561.013489 776.252625,562.994690 776.043091,565.276184 
	C776.375000,566.052246 776.692810,566.527893 777.060120,567.395874 
	C777.545044,570.157471 777.980408,572.526672 778.389771,574.754211 
	C779.877991,571.278687 781.436340,567.639343 782.994690,564.000000 
z"/>
<path fill="#0B3189" opacity="1.000000" stroke="none" 
	d="
M782.847778,563.663086 
	C781.436340,567.639343 779.877991,571.278687 778.389771,574.754211 
	C777.980408,572.526672 777.545044,570.157471 777.279419,567.220337 
	C776.975830,566.093628 776.502441,565.534729 776.029053,564.975830 
	C776.252625,562.994690 776.476196,561.013489 776.718140,558.599365 
	C776.978088,557.772217 777.219604,557.378052 777.664185,556.868286 
	C779.478455,558.943970 781.089600,561.135071 782.847778,563.663086 
z"/>
<path fill="#04277D" opacity="1.000000" stroke="none" 
	d="
M788.576416,555.286743 
	C789.795593,554.535095 791.552246,552.998291 792.962097,553.268738 
	C794.185791,553.503479 795.016785,555.784485 796.017700,557.940430 
	C796.027405,559.463196 796.040710,560.206726 796.054016,560.950256 
	C796.054016,560.950256 795.817627,561.207275 795.700073,561.336670 
	C795.023071,560.599182 794.095337,559.781738 793.971313,558.856445 
	C793.554199,555.745300 791.835022,554.788513 788.576416,555.286743 
z"/>
<path fill="#022B82" opacity="1.000000" stroke="none" 
	d="
M754.728027,550.816162 
	C755.077332,552.631104 755.154602,554.249512 755.561646,555.933044 
	C756.017334,556.407104 756.143250,556.816162 756.343872,557.537476 
	C756.181335,557.878784 755.944031,557.907837 755.349731,557.970886 
	C751.987915,556.871887 750.623779,551.366638 752.953552,549.556763 
	C753.094238,549.447449 753.938843,550.244385 754.728027,550.816162 
z"/>
<path fill="#04277D" opacity="1.000000" stroke="none" 
	d="
M786.435547,559.171265 
	C786.082764,558.812195 786.045044,558.288879 785.989258,557.372070 
	C786.505615,556.367065 787.040039,555.755615 787.916626,555.172180 
	C787.756042,556.469116 787.253357,557.738037 786.435547,559.171265 
z"/>
<path fill="#001B6E" opacity="1.000000" stroke="none" 
	d="
M125.999390,533.002441 
	C120.273537,527.388184 120.756226,520.211426 121.114098,513.117065 
	C121.151543,512.374695 122.074783,511.254395 122.800934,511.042542 
	C125.967216,510.118774 125.929985,507.794220 126.070107,505.157623 
	C126.265793,501.475525 125.424706,499.711273 121.172073,499.895874 
	C114.776962,500.173462 113.048820,498.150696 113.009789,491.595978 
	C112.967178,484.439606 112.930740,477.281860 113.031898,470.126678 
	C113.075012,467.077881 112.523903,465.236603 108.845657,464.718414 
	C107.370926,464.510620 105.267815,461.680328 105.177101,459.953033 
	C104.776146,452.318146 104.927246,444.648865 105.074951,436.995789 
	C105.100883,435.652405 105.724319,433.508362 106.651176,433.121246 
	C115.133392,429.578522 111.721428,422.911072 111.117615,417.004974 
	C110.866684,414.550537 110.138008,412.128998 110.049385,409.677246 
	C109.853722,404.263672 108.185165,402.219238 102.974289,403.153595 
	C98.001373,404.045319 97.274551,401.363464 97.023949,397.729248 
	C96.898300,395.907104 96.863190,394.056793 97.027733,392.240997 
	C97.406433,388.061829 96.462051,384.885651 91.144936,384.205994 
	C95.189072,382.859253 99.994041,382.679230 99.400467,376.368774 
	C102.151619,374.941681 104.502899,373.939301 107.254601,372.911072 
	C111.961365,370.765686 109.237419,366.880432 110.075668,363.622742 
	C110.726631,363.189880 111.225769,362.986084 112.051971,363.070984 
	C112.560143,363.817719 112.860367,364.264496 112.904846,364.735413 
	C113.868484,374.937836 113.884987,374.774536 124.343010,376.177765 
	C126.037086,376.405060 128.307190,378.563049 128.813690,380.272369 
	C130.456650,385.816711 133.169235,391.528961 129.085602,397.293823 
	C128.613144,397.960815 128.952087,399.251923 129.022919,400.245911 
	C129.328751,404.536957 129.463531,408.858154 130.125992,413.095642 
	C130.344711,414.494843 131.776810,416.512482 132.980392,416.780121 
	C135.426773,417.324127 137.530334,418.006683 136.848938,420.717682 
	C136.368683,422.628571 134.622894,424.234070 133.392105,425.939850 
	C132.298172,427.455994 130.704300,428.773926 130.125839,430.453156 
	C129.561691,432.090851 129.586029,434.212128 130.127136,435.867798 
	C130.631546,437.411163 131.998993,439.594360 133.259964,439.797424 
	C137.194931,440.430908 138.077881,442.646759 138.026550,446.083435 
	C137.932297,452.395233 137.999664,458.709412 137.999664,464.932312 
	C139.782974,465.410370 141.248215,465.803162 141.968842,465.996338 
	C140.712723,468.506134 139.852051,470.225800 138.630386,472.002747 
	C133.541565,473.283722 132.728394,476.814148 133.004517,481.238831 
	C132.998749,481.655823 132.996643,481.822632 132.997009,481.994141 
	C132.999496,481.998810 132.987534,481.994049 132.639221,482.064453 
	C129.706894,484.268188 131.231018,486.194763 133.068054,487.273071 
	C135.278427,488.570496 137.868195,489.556793 140.398132,489.893738 
	C143.929260,490.364044 147.901566,489.811035 144.548904,495.337219 
	C144.273911,495.790466 144.396896,496.661407 144.626892,497.217529 
	C146.942734,502.817383 146.231506,509.756531 152.037354,513.855469 
	C152.672714,514.304016 152.961273,515.640747 152.941437,516.555359 
	C152.821854,522.071899 154.358871,527.972107 149.443710,532.483093 
	C149.105820,532.793152 149.458420,533.855591 149.515839,535.155090 
	C152.899292,532.790833 155.782990,530.775757 158.599030,528.807983 
	C159.976318,530.513855 160.976364,531.752502 161.837128,533.297241 
	C157.791641,537.363831 153.885422,541.124146 149.979202,544.884521 
	C148.185654,542.161682 150.089752,537.890259 145.756363,536.068481 
	C142.129349,534.543640 137.231567,536.895569 136.983322,540.850281 
	C136.871262,542.635620 137.013779,544.645142 136.237381,546.133911 
	C134.933258,548.634644 133.152496,549.097961 132.304047,545.146790 
	C134.941452,540.324402 132.922852,534.115601 138.569107,531.304443 
	C138.716537,531.231079 137.774902,528.970276 137.341125,527.729553 
	C136.105515,528.170654 133.862122,528.489014 133.791153,529.071838 
	C133.129349,534.508484 129.747833,534.012207 125.999390,533.002441 
z"/>
<path fill="#EAF3F7" opacity="1.000000" stroke="none" 
	d="
M151.817810,551.678101 
	C153.794067,551.817383 155.770309,551.956665 157.967026,552.111511 
	C158.921021,555.965515 158.375275,558.076172 153.663055,558.067200 
	C146.168503,558.052856 146.153076,558.289307 146.133667,565.614319 
	C146.119110,571.114014 146.274216,576.617371 146.120148,582.112549 
	C145.997101,586.502197 147.774368,588.076721 152.175156,587.598511 
	C153.845886,587.416870 155.674316,588.686340 157.430893,589.294434 
	C156.071213,590.653442 154.952835,592.622864 153.312286,593.271301 
	C143.378220,597.198120 137.975723,593.416443 137.913757,582.713196 
	C137.867584,574.736328 137.904663,566.758850 137.904663,558.276062 
	C135.079483,558.101135 132.526276,557.943054 129.839401,557.776672 
	C129.533051,553.941345 130.163498,551.487610 134.655685,551.580200 
	C139.206467,551.674072 139.238266,547.973755 139.377991,544.828979 
	C139.554535,540.855225 141.311584,538.826538 146.181702,538.812805 
	C146.181702,541.455139 146.181702,543.863098 146.148743,547.088501 
	C145.804993,552.506104 148.989838,551.822388 151.817810,551.678101 
z"/>
<path fill="#0D65C3" opacity="1.000000" stroke="none" 
	d="
M151.928421,551.403809 
	C148.989838,551.822388 145.804993,552.506104 146.229004,547.545593 
	C147.470184,546.624390 148.598160,546.063416 149.852661,545.193481 
	C153.885422,541.124146 157.791641,537.363831 162.068298,533.207275 
	C162.727966,532.620422 162.919952,532.358459 163.306976,531.891846 
	C164.102112,530.869385 164.604996,529.980347 165.059219,529.045410 
	C165.010559,528.999634 165.092499,529.104858 165.410980,529.059814 
	C166.208908,528.368469 166.688324,527.722107 167.460266,526.919556 
	C171.487320,523.174255 175.221832,519.585266 179.272400,515.875000 
	C180.713669,514.494141 181.838928,513.234497 183.228119,511.881470 
	C183.974808,511.504364 184.457550,511.220642 185.253296,510.934235 
	C186.044403,510.584656 186.522552,510.237762 187.316147,509.865814 
	C188.418533,509.549469 189.205490,509.258148 190.368622,509.005829 
	C191.499435,509.013763 192.254059,508.982758 193.444824,508.986298 
	C195.921539,508.994141 197.962112,508.967438 200.259186,509.217773 
	C202.299850,509.614197 204.084045,509.733612 206.168320,509.892029 
	C206.971420,509.669556 207.474426,509.408142 208.430145,509.122742 
	C214.223587,509.107452 219.564362,509.116089 224.985214,509.451660 
	C225.535202,510.667175 226.005096,511.555756 227.289124,513.983765 
	C219.389755,513.983765 212.696518,513.983765 205.535278,513.978760 
	C200.829025,513.969116 196.525467,513.522156 192.378204,514.134216 
	C190.078461,514.473633 188.039871,516.582703 185.883575,517.894104 
	C181.129700,522.790405 176.454529,527.766357 171.601517,532.562378 
	C165.870529,538.226013 159.985886,543.734131 153.978302,549.590576 
	C153.205505,550.290833 152.622269,550.710144 151.928421,551.403809 
z"/>
<path fill="#011964" opacity="1.000000" stroke="none" 
	d="
M124.412598,588.006348 
	C125.003586,585.586426 125.997246,583.163025 126.999756,580.363525 
	C127.570229,578.502014 128.131851,577.016785 129.216858,574.147400 
	C131.189957,577.333923 132.693039,579.761292 134.849823,583.244446 
	C134.849823,575.628479 134.849823,568.683289 134.849823,561.738037 
	C135.349762,561.721375 135.849716,561.704651 136.349655,561.687988 
	C136.349655,569.587769 136.349655,577.487488 136.349655,585.259216 
	C135.007675,586.568909 133.417892,588.120422 131.607162,589.887634 
	C131.404831,589.542175 130.814514,588.534302 130.219543,587.528015 
	C128.415024,587.687317 126.615150,587.845093 124.412598,588.006348 
z"/>
<path fill="#011B6C" opacity="1.000000" stroke="none" 
	d="
M125.999542,533.401978 
	C129.747833,534.012207 133.129349,534.508484 133.791153,529.071838 
	C133.862122,528.489014 136.105515,528.170654 137.341125,527.729553 
	C137.774902,528.970276 138.716537,531.231079 138.569107,531.304443 
	C132.922852,534.115601 134.941452,540.324402 132.291351,544.764160 
	C130.291397,546.509521 128.584030,548.016602 126.701584,549.678223 
	C126.632904,548.157288 126.571297,546.793213 126.257385,545.215576 
	C126.003754,544.554016 126.002434,544.105957 125.997910,543.321655 
	C126.144203,541.107239 126.293709,539.229126 126.221436,537.174316 
	C125.999672,535.932190 125.999680,534.866821 125.999542,533.401978 
M130.545975,540.286255 
	C130.283905,540.011230 130.021820,539.736206 129.759766,539.461121 
	C129.645874,539.569702 129.531982,539.678284 129.418106,539.786865 
	C129.680496,540.061951 129.942902,540.337036 130.545975,540.286255 
z"/>
<path fill="#011964" opacity="1.000000" stroke="none" 
	d="
M125.374489,568.949341 
	C124.690018,568.238586 124.347878,567.459778 124.002426,566.341675 
	C124.935822,565.315186 125.872543,564.627930 126.809265,563.940674 
	C127.241455,564.240173 127.673645,564.539673 128.105835,564.839172 
	C127.309502,566.186523 126.513168,567.533875 125.374489,568.949341 
z"/>
<path fill="#0560DC" opacity="1.000000" stroke="none" 
	d="
M408.821228,49.562283 
	C402.776062,53.668087 402.776062,53.668087 396.355743,48.195229 
	C397.861511,44.217571 396.921936,38.394985 402.893158,37.430527 
	C405.190857,37.059406 409.391144,38.657158 410.066589,40.392441 
	C411.038147,42.888302 409.581146,46.329517 408.821228,49.562283 
M406.987701,42.634754 
	C405.498016,42.213314 403.990265,41.464348 402.528229,41.544899 
	C402.003845,41.573788 401.043610,43.741199 401.280548,44.680931 
	C401.512573,45.601406 403.526062,47.013157 403.868469,46.797493 
	C405.138092,45.997856 406.040771,44.615612 406.987701,42.634754 
z"/>
<path fill="#012174" opacity="1.000000" stroke="none" 
	d="
M983.930786,400.956970 
	C981.811584,402.872406 979.692383,404.787872 976.912964,406.852478 
	C971.963135,408.572876 968.856323,411.236450 967.979736,415.959778 
	C965.750732,418.103241 963.521790,420.246735 960.744507,422.695587 
	C959.120850,424.313354 958.045532,425.625763 956.970215,426.938171 
	C955.740540,428.100281 954.510864,429.262360 952.808411,430.751648 
	C951.883850,431.695709 951.432068,432.312500 950.980286,432.929260 
	C949.519531,434.195312 948.058838,435.461395 945.893616,436.864136 
	C943.171082,436.683502 941.153015,436.366241 939.135010,436.048950 
	C939.031555,435.488098 938.928101,434.927216 938.824646,434.366364 
	C942.865662,432.582062 947.413452,431.198914 946.178284,425.367462 
	C951.469788,425.123108 955.360535,424.093750 951.273621,418.091431 
	C950.996155,417.683990 951.367493,416.825684 951.455994,416.179657 
	C951.967468,412.448395 952.675842,408.731293 952.938171,404.982880 
	C953.140442,402.094269 952.700928,399.298279 956.771790,398.618347 
	C957.672607,398.467926 958.662292,396.391357 958.890930,395.076477 
	C960.303467,386.950684 959.098450,385.068420 951.292419,381.710632 
	C954.526123,381.497925 957.478088,381.455048 960.412170,381.663666 
	C966.521240,382.097931 972.649109,382.766663 976.995605,376.997009 
	C976.999268,376.998810 977.002441,376.998199 977.320557,376.953186 
	C978.091248,376.270599 978.543762,375.632965 979.282349,374.855072 
	C980.713074,373.476929 981.857727,372.239075 983.345581,370.964172 
	C985.125793,369.285126 986.562927,367.643188 988.282349,365.862762 
	C989.707031,364.814270 990.849426,363.904236 992.291138,362.849670 
	C993.726929,361.136749 994.863464,359.568390 996.263489,357.875610 
	C997.018005,357.167480 997.508972,356.583740 998.339050,355.963196 
	C999.451233,354.950775 1000.224426,353.975189 1001.188904,352.737915 
	C1003.250366,350.981720 1005.120483,349.487183 1006.990601,347.992615 
	C1009.499146,347.714325 1012.007629,347.436066 1013.658325,347.252930 
	C1014.445801,354.296600 1015.166382,360.742188 1015.602356,367.599762 
	C1014.540283,368.342010 1013.762817,368.672272 1012.652466,368.946899 
	C1009.027283,368.891296 1005.734924,368.891296 1003.710510,368.891296 
	C1001.903870,372.400940 1000.733154,374.693298 999.544373,376.976257 
	C998.994568,378.032043 998.409668,379.069580 997.840576,380.115356 
	C999.144165,379.983795 1000.739441,380.272156 1001.680420,379.618561 
	C1002.973083,378.720856 1003.583374,376.876801 1004.834656,375.872620 
	C1005.983337,374.950653 1007.594116,374.604309 1008.866821,374.314819 
	C1006.212280,377.404053 1003.741821,380.229858 1001.161926,382.951965 
	C995.447388,388.981232 989.677856,394.958496 983.930786,400.956970 
z"/>
<path fill="#010B64" opacity="1.000000" stroke="none" 
	d="
M983.002441,371.001221 
	C981.857727,372.239075 980.713074,373.476929 978.964172,374.900330 
	C977.907471,375.723328 977.454956,376.360779 977.002441,376.998199 
	C977.002441,376.998199 976.999268,376.998810 976.601196,377.000061 
	C975.130066,377.334930 974.001038,377.549225 972.993286,378.022003 
	C967.506226,380.596130 962.042114,380.937622 956.394104,378.394531 
	C957.042175,377.042023 957.526001,376.032318 958.341003,375.014709 
	C959.113953,375.000854 959.555786,374.994934 960.442871,374.994751 
	C968.309814,376.477661 973.012085,372.223480 977.445129,367.250916 
	C981.484131,362.720245 985.556641,358.211914 989.784973,353.859528 
	C994.493408,349.013000 999.427795,344.386902 1004.203247,339.604401 
	C1010.074951,333.724060 1015.880493,327.777649 1021.721924,321.866974 
	C1024.414673,319.142334 1027.525757,316.721893 1029.726929,313.647827 
	C1032.601685,309.633118 1035.907104,307.337097 1041.152466,308.279297 
	C1042.271362,310.001556 1043.172119,311.460785 1044.072876,312.919983 
	C1030.677490,320.059662 1022.031738,332.530060 1011.064697,342.394440 
	C1009.516968,343.786530 1008.393250,345.649872 1007.031921,347.643860 
	C1005.120483,349.487183 1003.250366,350.981720 1000.850098,352.774597 
	C999.546570,354.048615 998.773254,355.024292 998.000000,356.000000 
	C997.508972,356.583740 997.018005,357.167480 995.910217,357.915894 
	C994.192871,359.718414 993.092346,361.356262 991.991821,362.994171 
	C990.849426,363.904236 989.707031,364.814270 987.938965,365.899231 
	C985.876343,367.716522 984.439392,369.358856 983.002441,371.001221 
z"/>
<path fill="#032CA7" opacity="1.000000" stroke="none" 
	d="
M984.278564,400.938385 
	C989.677856,394.958496 995.447388,388.981232 1001.161926,382.951965 
	C1003.741821,380.229858 1006.212280,377.404053 1009.170288,374.178345 
	C1010.751099,372.495270 1011.893982,371.259094 1013.367676,370.019958 
	C1014.461060,369.694427 1015.223511,369.371887 1015.986023,369.049316 
	C1016.729492,369.052673 1017.473022,369.056000 1018.703369,369.452698 
	C1020.872803,370.229614 1022.551514,370.924286 1024.238403,370.944946 
	C1033.383545,371.057068 1042.530884,370.982758 1051.677124,371.028870 
	C1052.918213,371.035126 1054.157593,371.357117 1055.397827,371.532898 
	C1055.351562,372.021637 1055.305298,372.510376 1055.259033,372.999115 
	C1051.817139,372.999115 1048.361450,372.820374 1044.940308,373.087219 
	C1043.575073,373.193726 1042.286621,374.284149 1040.963135,374.926697 
	C1035.942749,374.939667 1030.922485,374.952606 1025.077759,374.808472 
	C1021.669678,374.169708 1018.867676,372.469818 1016.993835,375.951660 
	C1016.449463,376.146301 1015.905090,376.340942 1014.824829,376.774567 
	C1013.375671,377.605255 1012.333984,378.065002 1011.569397,378.809326 
	C1004.685303,385.511169 997.849792,392.262909 991.000000,399.000000 
	C988.875488,399.639923 986.750916,400.279877 984.278564,400.938385 
z"/>
<path fill="#022590" opacity="1.000000" stroke="none" 
	d="
M1041.434692,374.954529 
	C1042.286621,374.284149 1043.575073,373.193726 1044.940308,373.087219 
	C1048.361450,372.820374 1051.817139,372.999115 1055.259033,372.999115 
	C1055.305298,372.510376 1055.351562,372.021637 1055.397827,371.532898 
	C1054.157593,371.357117 1052.918213,371.035126 1051.677124,371.028870 
	C1042.530884,370.982758 1033.383545,371.057068 1024.238403,370.944946 
	C1022.551514,370.924286 1020.872803,370.229614 1019.074951,369.464355 
	C1037.237183,369.061920 1055.515503,368.966003 1073.791016,369.151337 
	C1075.394531,369.167603 1076.979370,371.027039 1078.738037,372.334015 
	C1078.904907,373.496368 1078.906616,374.355103 1078.832153,375.632996 
	C1077.050659,375.935059 1075.345581,375.817871 1073.326172,375.831787 
	C1068.435059,375.641357 1063.862549,375.167053 1059.280029,375.036682 
	C1053.492920,374.872040 1047.697876,374.987640 1041.434692,374.954529 
z"/>
<path fill="#032699" opacity="1.000000" stroke="none" 
	d="
M1044.398438,312.997101 
	C1043.172119,311.460785 1042.271362,310.001556 1041.165283,308.030762 
	C1040.960205,307.519165 1040.965088,307.021881 1041.325073,307.009766 
	C1042.467041,306.314270 1043.249023,305.630890 1044.370850,304.991455 
	C1046.464600,306.688446 1048.218506,308.341461 1050.159668,310.256714 
	C1051.900513,311.683075 1053.454224,312.847229 1055.007812,314.011353 
	C1055.307983,314.144196 1055.554688,314.343231 1055.904541,315.171570 
	C1058.697998,318.165253 1061.334595,320.595764 1063.971313,323.026245 
	C1066.832397,325.851410 1069.693604,328.676575 1072.770874,332.174591 
	C1072.994629,334.228638 1073.002197,335.609772 1072.997314,337.002869 
	C1072.984863,337.014832 1072.958740,337.039978 1072.958740,337.039978 
	C1070.869019,336.100861 1068.362061,335.616028 1066.760986,334.144989 
	C1059.278564,327.270294 1052.045776,320.123657 1044.398438,312.997101 
z"/>
<path fill="#032699" opacity="1.000000" stroke="none" 
	d="
M1077.123047,338.038147 
	C1077.109497,339.099670 1077.095947,340.161163 1076.702393,341.612549 
	C1075.544922,342.007141 1074.767578,342.011871 1073.990234,342.016602 
	C1073.650146,340.612396 1073.310059,339.208221 1072.964355,337.421997 
	C1072.958740,337.039978 1072.984863,337.014832 1073.398193,337.000610 
	C1074.882935,336.976868 1075.954346,336.967316 1077.025757,336.957764 
	C1077.025757,336.957764 1077.007690,336.985199 1077.030518,337.249878 
	C1077.076660,337.689117 1077.099854,337.863617 1077.123047,338.038147 
z"/>
<path fill="#011C7F" opacity="1.000000" stroke="none" 
	d="
M1074.046021,342.349945 
	C1074.767578,342.011871 1075.544922,342.007141 1076.713379,342.010559 
	C1077.400391,342.791107 1077.696289,343.563446 1077.991211,344.666046 
	C1076.694092,344.225342 1075.397949,343.454315 1074.046021,342.349945 
z"/>
<path fill="#022590" opacity="1.000000" stroke="none" 
	d="
M1015.990723,368.789978 
	C1015.223511,369.371887 1014.461060,369.694427 1013.351990,369.764832 
	C1012.998718,369.342621 1012.992004,369.172577 1012.985352,369.002502 
	C1013.762817,368.672272 1014.540283,368.342010 1015.654663,368.011810 
	C1015.992859,368.184814 1015.994202,368.357727 1015.990723,368.789978 
z"/>
<path fill="#012072" opacity="1.000000" stroke="none" 
	d="
M1017.000000,131.000610 
	C1008.999817,131.866684 1008.879333,131.868866 1009.043518,140.962280 
	C1009.148132,146.755356 1009.606323,152.545822 1010.034729,158.327927 
	C1010.333923,162.365311 1019.172302,169.570877 1023.020569,168.921005 
	C1024.464111,168.677216 1025.811768,167.905014 1027.227051,167.448120 
	C1032.657593,165.695038 1040.023926,169.869186 1040.645020,175.575012 
	C1035.851196,170.995728 1030.110596,170.373932 1023.523804,171.006927 
	C1005.376465,170.994247 987.696899,170.981812 969.918579,170.587662 
	C968.157166,169.803925 966.493530,169.068787 964.831970,169.073517 
	C961.924561,169.081772 959.997131,168.397415 959.837708,165.073502 
	C959.629333,160.725433 959.129822,156.387238 959.035095,152.038727 
	C958.876892,144.777939 959.260498,137.573196 963.218384,130.831024 
	C963.614258,130.090881 963.787354,129.545212 964.203918,128.961700 
	C964.447449,128.923843 964.937317,128.869690 965.187439,129.170776 
	C969.272278,128.739609 973.106934,128.007355 977.398438,127.205231 
	C981.240540,127.084869 984.625916,127.034386 988.276733,127.258804 
	C990.356812,127.673683 992.171387,127.813660 994.280029,128.252747 
	C1001.450012,128.659409 1008.325989,128.766953 1015.504028,128.869537 
	C1016.207703,128.896790 1016.609131,128.929031 1017.005981,129.301331 
	C1017.000854,130.094452 1017.000427,130.547531 1017.000000,131.000610 
z"/>
<path fill="#011C7F" opacity="1.000000" stroke="none" 
	d="
M1023.991516,171.007156 
	C1030.110596,170.373932 1035.851196,170.995728 1040.644409,175.962341 
	C1045.923218,181.202087 1050.856934,186.396973 1055.776733,191.604996 
	C1057.997192,193.955444 1060.189941,196.332092 1062.657959,199.201202 
	C1061.279297,200.809219 1059.637817,201.912918 1057.851807,202.715515 
	C1053.803223,198.610184 1049.899170,194.805969 1045.959351,190.658325 
	C1044.284058,188.875519 1042.644409,187.436157 1041.002441,185.748215 
	C1041.000000,185.499634 1040.995117,185.002441 1040.943115,184.666092 
	C1037.924438,181.891083 1034.957764,179.452408 1031.563599,177.011597 
	C1029.425537,177.011978 1027.714966,177.014481 1025.545898,177.011871 
	C1021.725830,177.006973 1018.364136,177.007202 1014.572937,177.005402 
	C1012.422668,176.996674 1010.701965,176.989975 1009.084229,176.592285 
	C1014.245728,175.581970 1019.304199,174.962631 1024.587769,174.315735 
	C1024.387695,173.205978 1024.189575,172.106567 1023.991516,171.007156 
z"/>
<path fill="#010C60" opacity="1.000000" stroke="none" 
	d="
M1057.996460,203.016602 
	C1059.637817,201.912918 1061.279297,200.809219 1062.960327,199.351410 
	C1064.940063,198.330872 1066.853882,197.570343 1068.826660,197.021896 
	C1071.304443,196.333084 1073.830444,195.818054 1076.724976,195.136383 
	C1076.733154,195.152115 1077.098633,195.852753 1077.708984,196.751373 
	C1077.961792,198.693924 1077.969604,200.438477 1077.717407,202.820221 
	C1076.460938,203.824463 1075.490356,204.385406 1074.463257,204.522690 
	C1071.227051,204.955170 1067.039307,204.079605 1064.913086,205.801361 
	C1060.398071,209.457504 1057.029785,214.506042 1053.012573,218.817108 
	C1052.018188,219.884277 1050.340576,220.314880 1048.636719,220.993622 
	C1047.186401,219.637894 1046.076172,218.326660 1044.979492,216.761627 
	C1044.995117,216.338562 1044.997559,216.169281 1045.269531,215.798431 
	C1046.745239,214.535690 1048.029175,213.549606 1049.142822,212.398788 
	C1052.132812,209.309036 1055.050293,206.148849 1057.996460,203.016602 
M1073.541260,200.284760 
	C1073.281616,200.011765 1073.021973,199.738785 1072.762329,199.465775 
	C1072.649414,199.573456 1072.432251,199.768799 1072.438477,199.776199 
	C1072.680786,200.064194 1072.943481,200.335022 1073.541260,200.284760 
z"/>
<path fill="#0946BC" opacity="1.000000" stroke="none" 
	d="
M1017.010620,128.961273 
	C1016.609131,128.929031 1016.207703,128.896790 1015.363892,128.512833 
	C1012.926819,127.719421 1010.932068,127.277725 1009.020081,126.513809 
	C1010.039612,125.558640 1010.976440,124.925697 1011.635193,124.480629 
	C1009.156738,123.952621 1006.568176,123.401161 1004.211914,122.621773 
	C1005.298096,122.216995 1006.152100,122.040154 1007.468262,121.908371 
	C1014.673584,121.705902 1021.514709,121.115540 1025.459351,128.504288 
	C1025.861694,129.258011 1027.091431,129.570053 1027.987793,130.425873 
	C1032.002563,134.546600 1035.966797,138.328491 1039.970825,142.414520 
	C1040.341431,143.467300 1040.672363,144.215958 1041.003296,144.964630 
	C1042.201904,146.975479 1043.400635,148.986298 1044.920044,151.535263 
	C1043.576172,151.337738 1042.616455,151.196686 1041.206055,150.815338 
	C1040.558594,150.314209 1040.310059,150.119522 1039.969604,149.648529 
	C1038.275879,147.877853 1036.622192,146.449646 1034.984375,145.010895 
	C1035.000000,145.000336 1034.979248,145.032440 1034.938232,144.700684 
	C1034.216064,143.934845 1033.534912,143.500763 1032.853638,143.066681 
	C1030.807251,141.391647 1028.760986,139.716614 1026.398560,137.745544 
	C1026.082520,137.449509 1026.112427,136.956696 1026.315918,136.769012 
	C1025.975586,136.062057 1025.431763,135.542770 1024.887939,135.023499 
	C1023.134216,133.426132 1021.380493,131.828735 1019.157227,129.903961 
	C1018.128662,129.371490 1017.569641,129.166382 1017.010620,128.961273 
z"/>
<path fill="#01257E" opacity="1.000000" stroke="none" 
	d="
M1048.146240,161.248383 
	C1050.999146,163.380478 1053.971924,165.836166 1056.980957,168.635483 
	C1054.100098,166.510056 1051.183228,164.041016 1048.146240,161.248383 
z"/>
<path fill="#01257E" opacity="1.000000" stroke="none" 
	d="
M1040.009766,149.990982 
	C1040.310059,150.119522 1040.558594,150.314209 1040.874146,150.782028 
	C1041.016235,151.736526 1041.039429,152.484039 1041.070801,153.604950 
	C1038.408691,151.219360 1035.738647,148.460388 1033.365967,145.345215 
	C1034.101929,145.003494 1034.540649,145.017975 1034.979248,145.032440 
	C1034.979248,145.032440 1035.000000,145.000336 1035.035156,145.349884 
	C1036.716675,147.129944 1038.363281,148.560455 1040.009766,149.990982 
z"/>
<path fill="#01257E" opacity="1.000000" stroke="none" 
	d="
M1065.063232,177.263199 
	C1067.357788,179.032013 1069.629395,181.178833 1071.943115,183.663483 
	C1067.936401,183.774506 1065.949829,181.295929 1065.063232,177.263199 
z"/>
<path fill="#01257E" opacity="1.000000" stroke="none" 
	d="
M1057.049316,169.335449 
	C1058.915039,170.456360 1060.808960,171.929840 1062.840332,173.705750 
	C1061.011108,172.568146 1059.044189,171.128082 1057.049316,169.335449 
z"/>
<path fill="#012072" opacity="1.000000" stroke="none" 
	d="
M1026.112427,136.956696 
	C1026.112427,136.956696 1026.082520,137.449509 1026.060303,137.694763 
	C1025.229858,138.110474 1024.421753,138.280930 1023.613708,138.451385 
	C1023.875427,137.996490 1024.137207,137.541611 1024.977173,137.033279 
	C1025.741211,136.972122 1025.926880,136.964401 1026.112427,136.956696 
z"/>
<path fill="#0433B7" opacity="1.000000" stroke="none" 
	d="
M1026.315918,136.769012 
	C1025.926880,136.964401 1025.741211,136.972122 1025.276855,136.990219 
	C1024.975464,136.561081 1024.952759,136.121536 1024.908936,135.352753 
	C1025.431763,135.542770 1025.975586,136.062057 1026.315918,136.769012 
z"/>
<path fill="#01257E" opacity="1.000000" stroke="none" 
	d="
M1017.005981,129.301331 
	C1017.569641,129.166382 1018.128662,129.371490 1018.826660,129.850769 
	C1018.500671,130.404877 1018.035645,130.684799 1017.285278,130.982666 
	C1017.000427,130.547531 1017.000854,130.094452 1017.005981,129.301331 
z"/>
<path fill="#0433B7" opacity="1.000000" stroke="none" 
	d="
M1034.938232,144.700684 
	C1034.540649,145.017975 1034.101929,145.003494 1033.334106,144.989929 
	C1032.977417,144.562485 1032.949951,144.134140 1032.888184,143.386230 
	C1033.534912,143.500763 1034.216064,143.934845 1034.938232,144.700684 
z"/>
<path fill="#021F84" opacity="1.000000" stroke="none" 
	d="
M1041.001709,69.004440 
	C1045.103760,69.436462 1049.205688,69.868492 1053.658691,70.142136 
	C1057.789551,69.989792 1061.582764,70.198387 1065.345581,69.948616 
	C1069.671997,69.661453 1073.471802,70.572792 1076.938477,73.818100 
	C1077.156982,75.163879 1077.136353,76.227951 1076.721802,77.653381 
	C1072.163086,74.094444 1066.982178,75.031013 1061.555420,75.011536 
	C1058.737183,75.003929 1056.363770,75.002457 1053.522827,74.997368 
	C1046.371948,74.998505 1039.688354,75.003258 1032.560059,75.004623 
	C1029.740723,75.006844 1027.366333,75.012451 1024.994873,75.006081 
	C1024.997925,74.994102 1025.000000,74.966881 1025.034058,74.622223 
	C1025.377930,73.170914 1025.687744,72.064270 1026.301147,70.675110 
	C1031.403687,69.929886 1036.202759,69.467163 1041.001709,69.004440 
z"/>
<path fill="#01196A" opacity="1.000000" stroke="none" 
	d="
M1039.931152,142.110382 
	C1035.966797,138.328491 1032.002563,134.546600 1028.155762,130.105377 
	C1029.162720,127.877014 1030.163330,126.358337 1030.905518,124.722466 
	C1031.463745,123.491814 1031.690674,122.110802 1032.065063,120.796707 
	C1030.427368,120.985535 1028.753052,121.022911 1027.162354,121.406647 
	C1025.632568,121.775703 1024.189331,122.503189 1022.708069,123.073189 
	C1022.447754,122.686256 1022.187439,122.299324 1021.927124,121.912392 
	C1022.485779,121.047028 1022.969421,120.120499 1023.616150,119.326935 
	C1026.250366,116.094490 1029.358276,113.155678 1031.469849,109.618126 
	C1033.239990,106.652321 1031.600952,104.041649 1028.186279,103.312233 
	C1026.109863,102.868698 1023.847961,103.330246 1021.748535,102.949028 
	C1017.138428,102.111908 1012.578674,100.997612 1008.299500,99.707718 
	C1010.401184,99.279518 1012.204590,98.989197 1014.001709,99.024384 
	C1028.878296,99.315727 1043.753174,99.698425 1058.629883,99.984100 
	C1063.989990,100.087044 1069.353882,100.000648 1074.716187,100.000648 
	C1074.406982,100.645813 1074.097656,101.290977 1073.788452,101.936142 
	C1075.181641,103.625809 1076.574829,105.315468 1077.967896,107.005142 
	C1077.958618,116.764488 1077.949341,126.523827 1077.865967,136.774506 
	C1071.069702,130.874115 1064.318970,124.511620 1057.674438,118.040207 
	C1057.084229,117.465385 1057.079712,116.192307 1056.981201,115.225418 
	C1056.352295,109.058403 1054.147827,106.832390 1048.800049,107.050278 
	C1044.384644,107.230164 1042.178955,110.202110 1041.802734,116.774719 
	C1042.276611,123.195511 1042.276611,123.195511 1046.032104,121.943024 
	C1046.585815,122.079788 1047.139404,122.216553 1047.814941,123.003510 
	C1047.524048,129.935379 1047.111328,136.217087 1046.662720,143.043442 
	C1053.043945,142.273636 1052.206787,138.041107 1053.025513,134.831848 
	C1053.531494,132.848862 1054.331909,130.940994 1055.402710,129.000000 
	C1056.880859,128.995621 1057.956421,128.990646 1059.031860,128.985672 
	C1059.620483,129.466476 1060.209229,129.947281 1060.738037,131.027695 
	C1060.223755,133.585312 1059.769165,135.543304 1059.314453,137.501236 
	C1059.025513,138.745361 1059.120850,140.722610 1058.382568,141.109619 
	C1054.722656,143.028076 1054.870239,145.796387 1055.751953,149.201721 
	C1056.002686,150.270203 1056.501343,151.134796 1057.266357,152.239273 
	C1057.513184,157.953064 1062.355103,159.185059 1066.603760,161.692383 
	C1065.026123,163.139023 1064.005737,164.074783 1062.985229,165.010559 
	C1059.084717,161.230072 1055.184204,157.449570 1051.093994,153.037079 
	C1050.244385,151.947037 1049.584473,151.488998 1048.924438,151.030960 
	C1047.288208,149.305542 1045.651855,147.580093 1043.962158,145.151794 
	C1043.129150,142.740097 1043.126099,139.710968 1039.931152,142.110382 
M1042.854126,134.953674 
	C1043.185059,133.539993 1043.646484,132.140625 1043.803467,130.707886 
	C1043.918823,129.654526 1043.635864,128.557510 1043.530396,127.479927 
	C1042.694092,128.239822 1041.781982,128.934357 1041.049194,129.783676 
	C1040.595215,130.309921 1040.413330,131.071152 1039.864136,132.255722 
	C1038.371216,130.255829 1037.254150,128.759308 1036.137085,127.262779 
	C1035.692261,127.305702 1035.247559,127.348618 1034.802734,127.391533 
	C1034.802734,128.662109 1034.330444,130.742340 1034.883423,131.064941 
	C1037.453979,132.564423 1037.800293,137.984406 1042.854126,134.953674 
M1049.386841,145.817032 
	C1050.602051,146.572662 1051.817139,147.328293 1053.032227,148.083908 
	C1053.254517,147.384216 1053.476807,146.684509 1053.698975,145.984802 
	C1052.434570,145.760376 1051.170166,145.535950 1049.386841,145.817032 
z"/>
<path fill="#001A66" opacity="1.000000" stroke="none" 
	d="
M1056.000000,148.997803 
	C1054.870239,145.796387 1054.722656,143.028076 1058.382568,141.109619 
	C1059.120850,140.722610 1059.025513,138.745361 1059.314453,137.501236 
	C1059.769165,135.543304 1060.223755,133.585312 1060.848877,131.296906 
	C1062.597656,132.418945 1064.175659,133.871399 1065.887939,135.989746 
	C1067.424316,138.067383 1068.826538,139.479126 1070.228638,140.890869 
	C1070.228638,140.890884 1070.619385,140.882980 1070.854736,141.252472 
	C1071.761841,142.065781 1072.433472,142.509613 1073.105103,142.953461 
	C1073.422119,143.383331 1073.739258,143.813187 1074.086304,144.515244 
	C1075.217163,146.108643 1076.318115,147.429840 1077.735229,148.898254 
	C1078.027222,157.720459 1078.002808,166.395462 1077.596924,175.536911 
	C1076.476929,176.001617 1075.738403,175.999893 1074.939453,175.652222 
	C1073.891602,174.884720 1072.904175,174.463181 1071.916870,174.041656 
	C1070.366089,172.568604 1068.815430,171.095566 1067.085693,169.005890 
	C1066.253662,167.934464 1065.600342,167.479660 1064.947144,167.024841 
	C1064.370239,166.530655 1063.793335,166.036484 1063.100830,165.276428 
	C1064.005737,164.074783 1065.026123,163.139023 1066.603760,161.692383 
	C1062.355103,159.185059 1057.513184,157.953064 1057.264038,151.902283 
	C1056.663452,150.549530 1056.331787,149.773666 1056.000000,148.997803 
M1074.000122,165.510941 
	C1074.085083,166.587204 1074.170044,167.663452 1074.254883,168.739716 
	C1074.376465,168.721329 1074.498047,168.702957 1074.619629,168.684570 
	C1074.619629,166.569412 1074.619629,164.454254 1074.619629,162.339081 
	C1074.413330,162.354645 1074.207031,162.370193 1074.000732,162.385757 
	C1074.000732,163.176208 1074.000732,163.966660 1074.000122,165.510941 
z"/>
<path fill="#011D6F" opacity="1.000000" stroke="none" 
	d="
M1039.970825,142.414520 
	C1043.126099,139.710968 1043.129150,142.740097 1043.908936,144.822159 
	C1043.183594,145.133423 1042.458008,145.071487 1041.367798,144.987091 
	C1040.672363,144.215958 1040.341431,143.467300 1039.970825,142.414520 
z"/>
<path fill="#011D6F" opacity="1.000000" stroke="none" 
	d="
M1071.973267,174.377167 
	C1072.904175,174.463181 1073.891602,174.884720 1074.935425,175.921585 
	C1074.985962,176.716400 1074.980103,176.895874 1074.974121,177.075363 
	C1073.992676,176.287796 1073.011108,175.500229 1071.973267,174.377167 
z"/>
<path fill="#011B6C" opacity="1.000000" stroke="none" 
	d="
M1075.122925,177.349014 
	C1074.980103,176.895874 1074.985962,176.716400 1074.995972,176.267548 
	C1075.738403,175.999893 1076.476929,176.001617 1077.583252,176.005905 
	C1077.956299,176.793900 1077.961548,177.579330 1077.915039,178.757523 
	C1076.999390,178.641068 1076.135620,178.131866 1075.122925,177.349014 
z"/>
<path fill="#011D6F" opacity="1.000000" stroke="none" 
	d="
M1065.001099,167.342010 
	C1065.600342,167.479660 1066.253662,167.934464 1066.942383,168.717361 
	C1066.336914,168.583359 1065.695923,168.121277 1065.001099,167.342010 
z"/>
<path fill="#011D6F" opacity="1.000000" stroke="none" 
	d="
M1048.984863,151.347778 
	C1049.584473,151.488998 1050.244385,151.947037 1050.938110,152.737579 
	C1050.329712,152.601562 1049.687378,152.133072 1048.984863,151.347778 
z"/>
<path fill="#010C60" opacity="1.000000" stroke="none" 
	d="
M1055.304199,313.719391 
	C1053.454224,312.847229 1051.900513,311.683075 1050.243530,309.884491 
	C1051.659790,308.503235 1053.131470,307.264771 1054.708984,307.110931 
	C1058.107300,306.779480 1061.559814,307.005768 1065.166138,307.244446 
	C1065.900513,307.650513 1066.459106,307.815979 1067.337891,307.993317 
	C1068.084473,308.022552 1068.510742,308.039917 1068.987549,308.396118 
	C1069.697021,310.157288 1070.355835,311.579651 1071.014648,313.001984 
	C1065.876587,313.143799 1060.738525,313.285614 1055.304199,313.719391 
z"/>
<path fill="#011480" opacity="1.000000" stroke="none" 
	d="
M1071.356323,313.060089 
	C1070.355835,311.579651 1069.697021,310.157288 1069.340332,308.408020 
	C1071.702148,308.892395 1073.290161,308.948181 1073.336182,306.047424 
	C1075.085815,306.748596 1076.494873,307.390381 1077.903931,308.032166 
	C1077.942261,309.400208 1077.980591,310.768250 1078.034424,312.544983 
	C1077.962280,314.795959 1077.874512,316.638184 1077.786743,318.480438 
	C1077.367065,318.651276 1076.947510,318.822113 1076.527832,318.992950 
	C1074.917847,317.034698 1073.307983,315.076447 1071.356323,313.060089 
z"/>
<path fill="#011C7F" opacity="1.000000" stroke="none" 
	d="
M1063.945312,322.669373 
	C1061.334595,320.595764 1058.697998,318.165253 1056.016357,315.383911 
	C1060.196045,315.739380 1062.645142,318.384399 1063.945312,322.669373 
z"/>
<path fill="#011C7F" opacity="1.000000" stroke="none" 
	d="
M1077.018921,336.582306 
	C1075.954346,336.967316 1074.882935,336.976868 1073.410645,336.988678 
	C1073.002197,335.609772 1072.994629,334.228638 1072.983765,332.433716 
	C1074.324341,333.415588 1075.668213,334.811218 1077.018921,336.582306 
z"/>
<path fill="#011C7F" opacity="1.000000" stroke="none" 
	d="
M1077.266846,337.926514 
	C1077.099854,337.863617 1077.076660,337.689117 1077.046997,337.250549 
	C1077.163818,337.262634 1077.287231,337.538727 1077.266846,337.926514 
z"/>
<path fill="#001A66" opacity="1.000000" stroke="none" 
	d="
M1055.000000,128.999390 
	C1054.331909,130.940994 1053.531494,132.848862 1053.025513,134.831848 
	C1052.206787,138.041107 1053.043945,142.273636 1046.662720,143.043442 
	C1047.111328,136.217087 1047.524048,129.935379 1048.011719,123.216370 
	C1048.818237,122.803482 1049.550049,122.827927 1050.678589,123.233101 
	C1052.383545,125.409012 1053.691772,127.204193 1055.000000,128.999390 
z"/>
<path fill="#0C8FEC" opacity="1.000000" stroke="none" 
	d="
M1054.927734,114.620789 
	C1054.890381,116.479019 1054.810059,117.930115 1054.699707,119.927277 
	C1052.495850,119.927277 1050.255493,120.543526 1048.717651,119.767632 
	C1046.891479,118.846291 1044.966553,116.930649 1044.425293,115.058289 
	C1044.044922,113.741814 1045.524048,110.929359 1046.864136,110.329483 
	C1048.362061,109.658997 1050.764893,110.433258 1052.532593,111.163071 
	C1053.561157,111.587746 1054.116699,113.158539 1054.927734,114.620789 
z"/>
<path fill="#011D6F" opacity="1.000000" stroke="none" 
	d="
M1055.402710,128.999985 
	C1053.691772,127.204193 1052.383545,125.409012 1051.044922,123.259064 
	C1053.590820,124.728188 1056.167236,126.552071 1058.887695,128.680817 
	C1057.956421,128.990646 1056.880859,128.995621 1055.402710,128.999985 
z"/>
<path fill="#011D6F" opacity="1.000000" stroke="none" 
	d="
M1045.882080,121.658249 
	C1042.276611,123.195511 1042.276611,123.195511 1041.810547,117.186913 
	C1043.290771,118.433769 1044.511475,119.903618 1045.882080,121.658249 
z"/>
<path fill="#011D6F" opacity="1.000000" stroke="none" 
	d="
M1070.145508,140.566223 
	C1068.826538,139.479126 1067.424316,138.067383 1066.036255,136.276520 
	C1067.387695,137.345490 1068.724976,138.793549 1070.145508,140.566223 
z"/>
<path fill="#011D6F" opacity="1.000000" stroke="none" 
	d="
M1073.037842,142.635834 
	C1072.433472,142.509613 1071.761841,142.065781 1071.046265,141.291275 
	C1071.658447,141.413147 1072.314453,141.865692 1073.037842,142.635834 
z"/>
<path fill="#011B6C" opacity="1.000000" stroke="none" 
	d="
M1077.935303,307.561096 
	C1076.494873,307.390381 1075.085815,306.748596 1073.135986,305.779419 
	C1068.461914,301.231567 1064.306885,297.032074 1060.199951,292.786194 
	C1052.656616,284.987732 1045.295898,277.004669 1037.556274,269.407135 
	C1033.012939,264.947296 1027.885254,261.082794 1022.958984,256.623535 
	C1021.014099,253.420975 1019.017883,253.833313 1016.591064,256.083282 
	C1015.489380,256.071625 1014.754883,256.069305 1014.163147,255.752686 
	C1016.380798,253.896545 1018.595093,252.508514 1020.501526,250.780853 
	C1024.476318,247.178711 1028.339600,243.447403 1032.095703,239.618973 
	C1032.516846,239.189682 1031.951050,237.792252 1032.054199,236.600311 
	C1033.158325,235.254761 1034.050903,234.152710 1035.324707,233.034698 
	C1038.488647,231.332153 1041.271484,229.645569 1044.395752,228.004135 
	C1054.056519,236.850983 1063.326538,245.705978 1072.749268,254.395691 
	C1074.129272,255.668365 1076.234985,256.154175 1078.004883,257.004059 
	C1077.992065,273.699371 1077.979370,290.394714 1077.935303,307.561096 
M1043.809326,258.498383 
	C1045.683105,261.972321 1048.437622,263.217346 1052.459229,262.759918 
	C1057.216797,262.218750 1058.727173,258.530823 1059.654175,255.138153 
	C1060.138916,253.363724 1058.446655,250.129395 1056.756836,248.851685 
	C1054.783569,247.359726 1051.009155,245.939072 1049.309326,246.863983 
	C1045.429565,248.975006 1042.255859,252.493591 1043.809326,258.498383 
z"/>
<path fill="#011B6C" opacity="1.000000" stroke="none" 
	d="
M1048.977051,221.038147 
	C1050.340576,220.314880 1052.018188,219.884277 1053.012573,218.817108 
	C1057.029785,214.506042 1060.398071,209.457504 1064.913086,205.801361 
	C1067.039307,204.079605 1071.227051,204.955170 1074.463257,204.522690 
	C1075.490356,204.385406 1076.460938,203.824463 1077.704834,203.254547 
	C1077.863403,218.157776 1077.774292,233.263870 1077.019897,248.690826 
	C1074.678345,247.738586 1072.841309,246.628967 1071.353882,245.164124 
	C1064.779663,238.689682 1058.262329,232.155640 1051.834961,225.535645 
	C1050.623413,224.287796 1049.916626,222.549942 1048.977051,221.038147 
z"/>
<path fill="#02228D" opacity="1.000000" stroke="none" 
	d="
M1048.636719,220.993652 
	C1049.916626,222.549942 1050.623413,224.287796 1051.834961,225.535645 
	C1058.262329,232.155640 1064.779663,238.689682 1071.353882,245.164124 
	C1072.841309,246.628967 1074.678345,247.738586 1076.760864,249.016144 
	C1077.456787,251.404724 1077.746338,253.788879 1078.020386,256.588562 
	C1076.234985,256.154175 1074.129272,255.668365 1072.749268,254.395691 
	C1063.326538,245.705978 1054.056519,236.850983 1044.385986,227.628006 
	C1043.034302,225.786591 1042.033813,224.366455 1040.966797,222.610382 
	C1039.935181,221.844116 1038.969971,221.413773 1037.965820,220.640747 
	C1036.279663,218.203384 1034.632446,216.108734 1032.732422,213.742264 
	C1030.989014,213.321655 1029.498779,213.172882 1027.640381,213.052170 
	C1018.509033,213.046417 1009.745789,213.012573 1001.085266,212.588379 
	C1002.692566,211.801224 1004.190613,211.093521 1005.702942,211.061798 
	C1012.010315,210.929413 1018.326416,211.124069 1024.629028,210.904953 
	C1026.106445,210.853577 1027.543945,209.654358 1029.108643,208.727417 
	C1029.477295,207.981018 1029.737183,207.490402 1030.370605,207.000854 
	C1031.491211,207.005325 1032.238281,207.008728 1033.047363,207.350510 
	C1035.069580,208.796570 1037.029907,209.904236 1039.121094,211.284485 
	C1039.841187,212.369522 1040.430298,213.181961 1041.057129,214.348938 
	C1042.385132,215.474121 1043.675537,216.244766 1044.966064,217.015411 
	C1046.076172,218.326660 1047.186401,219.637894 1048.636719,220.993652 
z"/>
<path fill="#010F62" opacity="1.000000" stroke="none" 
	d="
M1077.978271,106.667793 
	C1076.574829,105.315468 1075.181641,103.625809 1073.788452,101.936142 
	C1074.097656,101.290977 1074.406982,100.645813 1074.716187,100.000648 
	C1069.353882,100.000648 1063.989990,100.087044 1058.629883,99.984100 
	C1043.753174,99.698425 1028.878296,99.315727 1014.001709,99.024384 
	C1012.204590,98.989197 1010.401184,99.279518 1007.862549,99.707993 
	C1005.084534,99.996338 1003.044739,99.995667 1000.905884,99.602966 
	C997.140686,98.807236 993.471069,98.430817 989.809631,97.987000 
	C988.243896,97.797211 986.695190,97.467010 985.138733,97.200623 
	C985.161011,96.749771 985.183289,96.298920 985.205505,95.848068 
	C986.208557,95.567368 987.199463,95.103493 988.216614,95.036125 
	C994.162537,94.642319 1000.110962,94.165878 1006.065308,94.042053 
	C1023.664368,93.676048 1041.267090,93.492149 1058.866577,93.145294 
	C1060.812744,93.106934 1062.747314,92.476524 1064.687256,92.120689 
	C1063.724976,90.181168 1062.925537,88.133827 1061.738647,86.343170 
	C1061.043091,85.293640 1059.411743,84.850044 1058.756958,83.790497 
	C1057.007690,80.959755 1055.558594,77.943489 1053.990234,75.000977 
	C1056.363770,75.002457 1058.737183,75.003929 1061.595703,75.361015 
	C1067.379517,80.807747 1072.678223,85.898865 1077.977051,90.989983 
	C1077.977539,93.046928 1077.978027,95.103867 1077.701660,97.861732 
	C1077.607422,100.702019 1077.789917,102.841377 1077.972412,104.980736 
	C1077.977783,105.430641 1077.983276,105.880554 1077.978271,106.667793 
z"/>
<path fill="#001A66" opacity="1.000000" stroke="none" 
	d="
M1077.985352,90.533737 
	C1072.678223,85.898865 1067.379517,80.807747 1062.040283,75.367157 
	C1066.982178,75.031013 1072.163086,74.094444 1076.726074,78.054276 
	C1077.413818,82.088371 1077.703613,86.082932 1077.985352,90.533737 
z"/>
<path fill="#01196A" opacity="1.000000" stroke="none" 
	d="
M1077.980225,104.546707 
	C1077.789917,102.841377 1077.607422,100.702019 1077.690186,98.302551 
	C1077.966309,100.065865 1077.977295,102.089272 1077.980225,104.546707 
z"/>
<path fill="#022590" opacity="1.000000" stroke="none" 
	d="
M990.952271,399.351410 
	C997.849792,392.262909 1004.685303,385.511169 1011.569397,378.809326 
	C1012.333984,378.065002 1013.375671,377.605255 1014.650146,377.011139 
	C1015.011536,377.008759 1015.024170,377.499786 1015.034119,377.745117 
	C1011.792236,381.460114 1008.540466,384.929779 1005.144348,388.699738 
	C1005.000000,389.000000 1004.995117,388.994781 1004.995117,388.994781 
	C998.259705,395.789398 991.625061,402.687988 984.754761,409.343384 
	C979.901733,414.044556 974.729431,418.416168 969.357178,422.973022 
	C972.922974,418.428558 976.689697,413.714355 980.785522,409.305908 
	C983.944397,405.905914 987.516296,402.889679 990.952271,399.351410 
z"/>
<path fill="#022590" opacity="1.000000" stroke="none" 
	d="
M1017.264404,376.239441 
	C1018.867676,372.469818 1021.669678,374.169708 1024.608765,374.805786 
	C1023.179199,378.760498 1020.115540,376.498657 1017.264404,376.239441 
z"/>
<path fill="#0A4DBA" opacity="1.000000" stroke="none" 
	d="
M111.724907,362.782288 
	C111.225769,362.986084 110.726631,363.189880 109.762314,363.824677 
	C108.482826,367.149414 107.668503,370.043182 106.854187,372.936951 
	C104.502899,373.939301 102.151619,374.941681 99.401314,375.956116 
	C97.088295,374.510986 95.174301,373.053772 93.115753,370.883179 
	C92.949806,368.778656 92.928406,367.387512 92.906998,365.996338 
	C93.205505,365.216705 93.504005,364.437103 94.227692,363.142212 
	C96.218033,362.082581 97.811455,361.606323 99.339706,360.972839 
	C100.628578,360.438599 101.841782,359.721802 103.041519,359.048828 
	C102.994568,359.011871 103.082588,359.095398 103.431709,359.061584 
	C107.580193,355.959900 111.379562,352.892029 115.178940,349.824158 
	C116.441658,350.552795 117.704361,351.281464 118.940933,352.694061 
	C116.518166,356.512756 114.121529,359.647491 111.724907,362.782288 
M104.068359,371.382355 
	C104.643364,368.358673 107.658569,364.441681 102.310989,363.232300 
	C100.392815,362.798492 97.475433,364.453400 95.843025,366.019196 
	C94.960907,366.865326 95.331360,370.892487 95.995247,371.135071 
	C98.286499,371.972260 100.955254,371.776276 104.068359,371.382355 
z"/>
<path fill="#011D6F" opacity="1.000000" stroke="none" 
	d="
M98.999084,356.000000 
	C96.551521,355.105865 94.103943,354.211792 90.578995,352.924103 
	C90.578995,347.798248 90.578995,342.199219 90.289703,335.924377 
	C89.999855,334.498260 89.999290,333.747894 89.998734,332.997559 
	C95.370605,334.451447 96.896172,339.589539 99.739090,343.913757 
	C96.117737,346.583130 96.421890,349.058258 98.995461,351.569000 
	C100.617226,353.151154 101.807838,354.514008 98.999084,356.000000 
z"/>
<path fill="#01196A" opacity="1.000000" stroke="none" 
	d="
M98.999435,356.374847 
	C101.807838,354.514008 100.617226,353.151154 98.995461,351.569000 
	C96.421890,349.058258 96.117737,346.583130 99.795235,344.252472 
	C100.000916,344.000000 100.500458,344.000000 100.750458,344.400208 
	C99.869354,350.196228 106.208275,349.781677 107.530235,352.871033 
	C105.937836,355.099548 104.510208,357.097473 103.082581,359.095398 
	C103.082588,359.095398 102.994568,359.011871 102.632011,359.019531 
	C101.180145,359.017731 100.090836,359.008270 99.001526,358.998779 
	C99.000946,358.249084 99.000366,357.499390 98.999435,356.374847 
z"/>
<path fill="#001A66" opacity="1.000000" stroke="none" 
	d="
M87.355652,357.690430 
	C92.065750,361.082092 89.702301,363.863281 87.272423,367.115784 
	C83.831993,364.464111 80.275787,362.311462 81.632187,357.568146 
	C81.772980,357.075806 85.142754,357.506836 87.355652,357.690430 
z"/>
<path fill="#011D6F" opacity="1.000000" stroke="none" 
	d="
M103.431702,359.061584 
	C104.510208,357.097473 105.937836,355.099548 107.530235,352.871033 
	C106.208275,349.781677 99.869354,350.196228 101.000229,344.400208 
	C102.472992,344.792542 105.021812,345.364471 105.237244,346.414917 
	C106.307472,351.633606 109.138809,351.150482 112.909225,349.058105 
	C113.682106,348.952911 114.252007,349.138153 115.000420,349.573792 
	C111.379562,352.892029 107.580193,355.959900 103.431702,359.061584 
z"/>
<path fill="#001A66" opacity="1.000000" stroke="none" 
	d="
M89.958374,332.636108 
	C89.999290,333.747894 89.999855,334.498260 90.000664,335.623688 
	C85.955399,336.635040 84.103500,334.298828 83.428169,327.479187 
	C87.687500,326.288147 88.768265,329.330536 89.958374,332.636108 
z"/>
<path fill="#011D6F" opacity="1.000000" stroke="none" 
	d="
M98.642349,358.959167 
	C100.090836,359.008270 101.180145,359.017731 102.678970,359.056519 
	C101.841782,359.721802 100.628578,360.438599 99.339706,360.972839 
	C97.811455,361.606323 96.218033,362.082581 94.354172,362.852966 
	C93.672470,361.281921 93.289459,359.484863 92.737404,356.894684 
	C95.267365,357.818420 96.775269,358.369019 98.642349,358.959167 
z"/>
<path fill="#012173" opacity="1.000000" stroke="none" 
	d="
M92.561066,366.075317 
	C92.928406,367.387512 92.949806,368.778656 92.945740,370.586639 
	C90.143990,369.756622 90.293236,368.084351 92.561066,366.075317 
z"/>
<path fill="#023497" opacity="1.000000" stroke="none" 
	d="
M92.409119,215.511154 
	C95.430641,212.043686 98.821243,211.856903 101.596420,214.655060 
	C104.053802,217.132751 103.284088,220.279663 100.670845,222.495483 
	C97.741409,224.979477 95.279594,223.125519 93.543610,220.819321 
	C92.592827,219.556259 92.637985,217.543488 92.409119,215.511154 
z"/>
<path fill="#053B9F" opacity="1.000000" stroke="none" 
	d="
M100.249481,178.052567 
	C97.797363,180.606003 95.597427,181.767303 93.108833,178.579086 
	C91.027657,175.912842 92.203186,173.904144 94.366379,172.310944 
	C95.236801,171.669861 97.489723,171.297836 97.723747,171.672165 
	C98.892670,173.541901 99.600014,175.700241 100.249481,178.052567 
z"/>
<path fill="#001A66" opacity="1.000000" stroke="none" 
	d="
M91.702133,181.867050 
	C90.158081,182.972000 88.316185,184.680313 86.471527,184.683304 
	C85.009346,184.685684 83.155037,182.835388 82.241051,181.339569 
	C81.772736,180.573135 82.915199,177.690445 83.447624,177.657272 
	C85.031036,177.558640 86.672882,178.398300 88.646103,178.935333 
	C89.801239,179.911163 90.602173,180.822952 91.702133,181.867050 
z"/>
<path fill="#001A66" opacity="1.000000" stroke="none" 
	d="
M81.366333,272.925781 
	C81.424034,271.386810 81.847771,269.769958 82.638458,268.077148 
	C82.581070,269.616791 82.156715,271.232330 81.366333,272.925781 
z"/>
<path fill="#031E6F" opacity="1.000000" stroke="none" 
	d="
M75.133873,195.601166 
	C77.913841,193.027466 79.551270,194.149216 79.890602,197.082779 
	C80.229538,200.012848 80.153145,203.031067 79.863159,205.971619 
	C79.764244,206.974701 78.464760,207.859406 77.713837,208.798203 
	C76.818863,207.839355 75.257217,206.947739 75.152451,205.909180 
	C74.823051,202.643921 75.041290,199.323410 75.133873,195.601166 
z"/>
<path fill="#031E6F" opacity="1.000000" stroke="none" 
	d="
M83.040100,259.438141 
	C83.277061,261.211945 83.184456,262.904083 83.091850,264.596222 
	C82.646011,264.695435 82.200180,264.794647 81.754341,264.893829 
	C80.953278,264.024963 80.152214,263.156067 79.351143,262.287201 
	C80.470940,261.310303 81.590736,260.333374 83.040100,259.438141 
z"/>
<path fill="#001A66" opacity="1.000000" stroke="none" 
	d="
M73.743698,244.909119 
	C72.950882,245.254654 72.242249,245.297531 71.533615,245.340424 
	C71.737419,244.772064 71.941223,244.203720 72.145027,243.635345 
	C72.705986,243.959061 73.266930,244.282761 73.743698,244.909119 
z"/>
<path fill="#051F6B" opacity="1.000000" stroke="none" 
	d="
M106.893753,553.714844 
	C106.434654,553.817993 105.883293,553.650635 105.023094,552.926636 
	C103.476891,551.580750 102.239517,550.791626 101.002136,550.002441 
	C101.002136,550.002441 101.000000,550.000000 100.998932,549.998779 
	C101.162598,548.760925 101.327332,547.524292 101.544182,545.896301 
	C105.689438,547.258057 108.636734,548.713257 106.893753,553.714844 
z"/>
<path fill="#011964" opacity="1.000000" stroke="none" 
	d="
M101.002983,550.339600 
	C102.239517,550.791626 103.476891,551.580750 104.852554,552.686157 
	C104.240036,553.002502 103.489235,553.002563 102.362640,553.007568 
	C101.659180,552.233887 101.331505,551.455322 101.002983,550.339600 
z"/>
<path fill="#012173" opacity="1.000000" stroke="none" 
	d="
M112.051956,363.070984 
	C114.121529,359.647491 116.518166,356.512756 118.976662,353.014282 
	C119.206581,352.708771 119.374641,352.766998 119.921265,353.254761 
	C121.978226,358.056000 125.518677,358.228424 129.645264,358.937439 
	C144.155838,361.430573 158.543900,361.728729 173.000885,359.391510 
	C172.020325,361.466431 171.399490,364.135712 169.992188,364.649048 
	C166.155594,366.048584 165.795212,368.709381 165.757858,372.267120 
	C164.035645,372.765839 162.547562,372.971008 160.916229,372.864258 
	C158.236649,370.133667 157.354965,371.465515 156.986481,374.380066 
	C156.707993,374.829620 156.468658,374.996643 155.828949,375.080750 
	C155.034836,376.072845 154.583435,377.065308 154.126297,378.486145 
	C154.092896,386.355072 154.676407,387.137939 161.058868,388.456451 
	C162.080078,388.529877 163.040268,388.288025 164.323517,387.940826 
	C166.108597,385.890472 167.570618,383.945496 169.403351,381.916992 
	C170.141571,380.137299 170.509094,378.441101 170.912140,376.468506 
	C171.302750,375.794769 171.657806,375.397461 172.385925,375.003326 
	C173.505783,375.004364 174.252594,375.002197 175.173645,375.332977 
	C175.557541,378.399597 176.920074,382.712311 175.754929,383.563049 
	C172.593094,385.871582 172.566391,388.349030 174.070251,390.780884 
	C178.241440,397.526001 178.109940,404.877991 178.091064,412.325165 
	C178.088226,413.445496 178.827835,415.037872 179.732132,415.613159 
	C185.158493,419.065552 185.454575,420.854492 184.814301,427.472595 
	C183.778030,438.183563 183.442108,448.971100 183.101532,459.735535 
	C182.993790,463.140686 184.919083,464.836426 188.770264,465.144836 
	C194.149826,465.575562 199.476730,466.870422 204.765671,468.063660 
	C205.910461,468.321899 206.798462,469.718323 207.804749,470.590515 
	C206.640091,471.373810 205.568817,472.562256 204.291458,472.856720 
	C201.565506,473.485107 198.668427,473.406769 195.973694,474.118561 
	C194.811569,474.425537 193.977570,475.974701 192.553070,477.009369 
	C189.742371,477.027740 187.373108,476.996307 184.985870,476.559143 
	C182.083084,472.628326 176.490982,473.074554 174.529755,476.997803 
	C167.044785,477.020050 160.026031,476.993530 152.969437,476.674225 
	C152.611206,476.066895 152.290817,475.752380 151.970444,475.437866 
	C151.641129,475.933990 151.311813,476.430115 150.646729,476.975098 
	C149.871323,477.126251 149.431671,477.228577 148.730942,477.217712 
	C148.281799,477.079285 148.093750,477.054108 147.837524,476.757080 
	C147.501068,475.993256 147.232819,475.501282 147.156586,474.831970 
	C146.232147,473.759369 145.115707,472.864105 144.118652,471.692078 
	C144.146637,470.764282 144.055206,470.113251 143.963776,469.462250 
	C143.648041,470.288269 143.332291,471.114288 142.615250,471.950653 
	C141.143646,471.973541 140.073349,471.986084 138.999649,471.986481 
	C138.996246,471.974365 138.991379,471.945465 138.991379,471.945465 
	C139.852051,470.225800 140.712723,468.506134 141.968842,465.996338 
	C141.248215,465.803162 139.782974,465.410370 137.999664,464.932312 
	C137.999664,458.709412 137.932297,452.395233 138.026550,446.083435 
	C138.077881,442.646759 137.194931,440.430908 133.259964,439.797424 
	C131.998993,439.594360 130.631546,437.411163 130.127136,435.867798 
	C129.586029,434.212128 129.561691,432.090851 130.125839,430.453156 
	C130.704300,428.773926 132.298172,427.455994 133.392105,425.939850 
	C134.622894,424.234070 136.368683,422.628571 136.848938,420.717682 
	C137.530334,418.006683 135.426773,417.324127 132.980392,416.780121 
	C131.776810,416.512482 130.344711,414.494843 130.125992,413.095642 
	C129.463531,408.858154 129.328751,404.536957 129.022919,400.245911 
	C128.952087,399.251923 128.613144,397.960815 129.085602,397.293823 
	C133.169235,391.528961 130.456650,385.816711 128.813690,380.272369 
	C128.307190,378.563049 126.037086,376.405060 124.343010,376.177765 
	C113.884987,374.774536 113.868484,374.937836 112.904846,364.735413 
	C112.860367,364.264496 112.560143,363.817719 112.051956,363.070984 
M118.619896,359.068085 
	C117.847595,360.076447 117.075302,361.084778 116.303009,362.093140 
	C117.249763,362.670105 118.184006,363.669403 119.147011,363.697968 
	C120.004669,363.723328 120.894180,362.674255 121.769638,362.099396 
	C120.973434,361.089020 120.177238,360.078613 118.619896,359.068085 
z"/>
<path fill="#02267C" opacity="1.000000" stroke="none" 
	d="
M197.016663,347.083649 
	C207.995773,339.699799 217.751648,331.095245 225.147522,319.267029 
	C227.862869,318.277466 229.957870,317.537384 232.040100,317.233826 
	C232.407135,323.665710 232.705078,329.668671 233.264847,335.647156 
	C233.339264,336.441956 234.743484,337.112213 235.671387,338.049011 
	C235.808823,338.425293 235.806732,338.592712 235.457001,338.875977 
	C234.645859,339.868896 233.604935,341.340149 233.804932,341.534485 
	C237.131134,344.766968 240.612854,347.839417 244.031097,350.974030 
	C244.002350,351.000458 244.048416,350.937744 244.055115,351.268799 
	C244.764923,353.881744 244.454514,356.929321 247.832596,357.244995 
	C246.084641,357.633698 244.447693,358.233887 243.198105,357.776276 
	C239.255081,356.332336 235.509995,354.693420 231.411697,357.483368 
	C231.004013,357.760895 229.181732,356.469879 228.383209,355.578094 
	C224.317307,351.037170 217.786041,352.232330 215.070892,358.408752 
	C215.013962,359.139984 214.983978,359.558289 214.586182,360.064148 
	C213.822922,360.819275 213.138840,361.460846 213.076050,362.158325 
	C212.336624,370.371948 219.311356,375.054443 227.262527,371.758362 
	C228.637680,371.188324 230.354614,368.638092 231.108536,372.562195 
	C230.643982,373.203217 230.252090,373.574280 229.497894,373.959656 
	C228.093674,375.300415 227.051773,376.626831 225.857727,378.257965 
	C219.543976,384.463623 213.382385,390.364594 206.796600,396.187683 
	C203.856964,396.740570 201.341507,397.371368 198.464050,397.990112 
	C196.507187,394.812592 194.916382,391.645050 193.315567,388.482605 
	C192.593323,387.055847 191.867828,384.428467 191.115234,384.418091 
	C186.842148,384.359161 186.347015,381.796967 185.470581,378.421143 
	C184.489166,374.640900 186.229950,372.719421 188.229416,370.087830 
	C192.141373,364.939117 195.449051,359.331299 199.617950,352.983063 
	C196.444443,353.463593 194.692200,353.728912 193.067429,353.677002 
	C194.468826,351.267761 195.742752,349.175720 197.016663,347.083649 
z"/>
<path fill="#022274" opacity="1.000000" stroke="none" 
	d="
M128.760834,338.675171 
	C121.050369,332.170624 118.305077,323.329742 117.467651,313.337891 
	C118.849884,310.180359 119.163788,306.920410 120.903305,304.869843 
	C125.803688,299.093231 131.271576,293.795807 136.553162,288.345703 
	C139.299957,285.511292 142.125793,282.753510 145.291840,279.970459 
	C147.111237,278.959412 148.554733,277.938995 150.398529,276.827637 
	C154.513977,276.468475 158.229111,276.200226 162.384521,275.987579 
	C164.873474,276.122986 166.922150,276.202759 168.986115,276.644135 
	C170.367706,278.194885 171.782318,279.333954 173.084656,280.589508 
	C174.077652,281.546844 175.415024,282.552155 175.656219,283.729340 
	C175.796677,284.414917 174.184906,286.102081 173.180618,286.272522 
	C167.764053,287.191681 166.768814,290.418671 167.911224,295.373138 
	C166.851089,296.773895 165.934570,297.896484 164.671509,299.045776 
	C163.250793,300.405701 162.176590,301.738953 161.023468,303.407867 
	C158.935394,305.384827 156.751434,306.854980 154.947357,308.697144 
	C147.163635,316.645142 139.417099,324.633148 131.843079,332.779602 
	C130.386734,334.346008 129.766693,336.689941 128.760834,338.675171 
z"/>
<path fill="#02267D" opacity="1.000000" stroke="none" 
	d="
M202.260300,186.765503 
	C210.528763,194.167725 218.381790,194.534515 223.685318,187.626312 
	C224.842987,186.118378 225.455154,184.191650 226.795319,182.337036 
	C227.689423,182.865295 228.106781,183.514297 228.802048,184.595474 
	C229.627686,182.864029 230.311569,181.429825 230.997742,179.997818 
	C231.000000,180.000000 230.995575,179.995316 231.346893,180.001678 
	C233.132126,180.672043 234.566071,181.336014 235.993332,182.380402 
	C234.181061,184.766937 232.375458,186.773087 230.444962,188.917999 
	C231.218857,190.452972 232.240646,192.479614 233.389206,194.757706 
	C234.871933,194.961456 236.764709,195.221527 238.657486,195.481613 
	C238.825562,196.020996 238.993637,196.560379 239.161713,197.099762 
	C237.710236,197.731781 236.128906,199.099228 234.829559,198.869797 
	C228.890228,197.821014 226.973648,201.849564 224.618835,205.998108 
	C222.823166,206.981216 221.407303,207.963165 219.649963,209.006561 
	C217.209747,210.715195 215.111023,212.362381 212.686691,214.052338 
	C211.923904,214.759216 211.486740,215.423340 210.890579,216.380157 
	C207.552139,219.819580 204.372696,222.966293 200.704132,226.080719 
	C191.151840,226.033401 182.088669,226.018372 173.024139,225.614838 
	C173.407867,223.787842 173.529373,222.202850 174.231155,220.940430 
	C175.846802,218.034088 177.428665,215.370865 173.885651,212.630035 
	C173.469345,212.307983 173.751038,210.256210 174.355118,209.650589 
	C175.121231,208.882477 176.564499,208.815048 177.686218,208.370880 
	C179.753342,207.552353 182.749298,207.242630 183.656311,205.712234 
	C185.564041,202.493347 183.903915,194.589539 181.615417,194.002716 
	C179.860062,193.552612 177.622574,194.052597 175.872849,194.783981 
	C174.268036,195.454803 173.175201,197.321457 171.577957,198.049423 
	C170.495331,198.542847 168.407150,198.511292 167.674423,197.791946 
	C164.914307,195.082230 162.525146,191.994614 160.381393,189.019211 
	C162.707169,190.008133 164.983765,190.674866 166.509277,192.129364 
	C168.644485,194.165161 170.975433,194.472031 172.543945,192.527634 
	C175.920288,188.342163 180.309891,188.897171 184.720581,188.931091 
	C188.678970,188.961517 192.656860,189.216187 196.587631,188.897263 
	C198.523438,188.740189 200.372162,187.510056 202.260300,186.765503 
z"/>
<path fill="#062678" opacity="1.000000" stroke="none" 
	d="
M146.282272,181.934158 
	C148.230362,166.965790 160.774765,152.911377 174.547699,149.838547 
	C186.180389,147.243225 204.809952,150.945435 210.334686,161.354324 
	C200.388229,165.679199 197.940552,171.016556 200.263763,182.835724 
	C198.595413,182.908386 197.317947,182.318405 196.048325,182.334854 
	C182.644455,182.508484 169.242477,182.857361 155.838440,182.964920 
	C152.656006,182.990448 149.467819,182.296921 146.282272,181.934158 
z"/>
<path fill="#02277E" opacity="1.000000" stroke="none" 
	d="
M222.824402,305.376831 
	C222.407715,305.342194 221.991043,305.307526 221.278961,304.769470 
	C220.278580,301.407654 219.323990,301.176422 217.899033,303.901764 
	C216.155716,307.235962 214.199051,310.462494 212.548538,313.840088 
	C211.234650,316.528900 210.241913,319.374634 209.106598,322.150696 
	C209.106583,322.150696 209.045898,322.038666 208.721924,322.045532 
	C206.759598,323.044373 205.032669,323.917236 203.502563,325.054840 
	C200.884689,327.001251 198.672668,329.889282 195.767700,330.947296 
	C192.583832,332.106934 192.959656,333.915100 193.102509,336.162445 
	C193.102509,336.162445 193.052734,336.062469 192.686142,336.054077 
	C187.570099,337.600281 182.820648,339.154846 177.556244,340.877930 
	C177.556244,334.052765 177.839813,327.369781 177.474075,320.722534 
	C177.129333,314.456848 178.647156,306.862274 169.260773,305.254425 
	C169.141251,305.233917 169.057358,305.005463 169.006042,304.539246 
	C172.006363,298.921722 176.465881,296.560760 182.534958,296.927704 
	C185.994064,297.136871 189.477051,296.950897 193.422943,296.966492 
	C203.058380,296.993011 212.222244,297.115814 221.381241,296.957001 
	C227.585159,296.849396 233.782227,296.345947 240.008942,296.280792 
	C240.033905,296.717133 240.032288,296.892181 239.997009,297.421143 
	C238.667175,300.571899 237.371017,303.368774 235.859741,306.311676 
	C235.667999,306.778687 235.691391,307.099670 235.675354,307.790100 
	C235.320938,309.096893 235.005920,310.034271 234.380539,310.926941 
	C232.217163,311.235687 230.364136,311.589111 228.511108,311.942505 
	C228.651459,311.195526 228.791794,310.448517 229.403412,309.253082 
	C229.901535,307.626373 229.928406,306.448090 229.955276,305.269836 
	C227.578323,305.305511 225.201355,305.341187 222.824402,305.376831 
M181.777252,302.557526 
	C183.496887,301.554932 185.330368,300.323242 182.112350,299.122009 
	C178.867630,297.910858 173.988144,300.888672 172.616928,304.656982 
	C175.483749,304.035187 178.256393,303.433807 181.777252,302.557526 
z"/>
<path fill="#042475" opacity="1.000000" stroke="none" 
	d="
M168.956848,304.874084 
	C169.057358,305.005463 169.141251,305.233917 169.260773,305.254425 
	C178.647156,306.862274 177.129333,314.456848 177.474075,320.722534 
	C177.839813,327.369781 177.556244,334.052765 177.556244,340.877930 
	C182.820648,339.154846 187.570099,337.600281 192.737106,336.076904 
	C187.219452,339.020966 181.520569,342.661133 175.296051,344.683685 
	C164.825409,348.085907 153.891937,349.162231 143.015549,346.530273 
	C139.249329,345.618927 135.777069,343.492798 132.216919,341.322449 
	C133.073761,340.200989 134.023132,339.811768 134.672379,339.134735 
	C139.156586,334.458527 143.578217,329.722321 148.393250,324.999146 
	C149.847565,323.670654 150.928589,322.348419 152.162201,320.722198 
	C155.226730,317.617920 158.138702,314.817627 161.415924,312.007996 
	C165.059814,311.324524 167.009659,309.502136 167.156372,305.733368 
	C167.869537,305.285889 168.413193,305.079987 168.956848,304.874084 
z"/>
<path fill="#0337B4" opacity="1.000000" stroke="none" 
	d="
M251.043823,148.056458 
	C251.472961,148.029404 251.902084,148.002350 253.122711,147.981735 
	C268.876801,147.986664 283.839508,148.016235 298.801727,147.941635 
	C300.538696,147.932983 302.272766,147.346649 304.008179,147.028137 
	C307.365875,147.027054 310.723602,147.025986 314.809967,147.291168 
	C317.379272,147.625778 319.219910,147.694168 321.060547,147.762543 
	C323.510101,147.681290 325.959625,147.600052 329.159912,147.277481 
	C333.590057,147.069931 337.269440,147.103714 340.999664,147.411331 
	C341.698120,147.789719 342.345734,147.894257 343.421051,147.997375 
	C345.556793,148.016541 347.264801,148.037125 348.982025,148.426422 
	C349.846863,150.530289 350.325134,152.644424 351.622375,153.936081 
	C357.795074,160.082275 364.268982,165.924927 370.481934,172.032028 
	C375.437775,176.903412 380.172180,182.000061 385.048706,187.315369 
	C385.729156,188.087143 386.365784,188.540298 387.150696,189.290222 
	C388.863800,190.728577 390.428589,191.870132 392.035706,193.355133 
	C394.140289,195.753479 396.434509,197.626160 398.220276,199.898407 
	C403.043732,206.035858 408.364777,210.881805 416.924530,209.391891 
	C415.677612,210.174744 414.478455,210.500015 413.374786,211.025803 
	C412.203400,211.583878 411.122742,212.332275 409.584930,212.998611 
	C407.775208,213.003403 406.383026,213.007141 404.598083,213.007629 
	C403.547852,212.928513 402.646240,213.103775 402.270294,212.738266 
	C398.108246,208.691940 393.975433,204.612411 389.936005,200.443741 
	C381.340302,191.573059 372.900269,182.549423 364.206543,173.776657 
	C359.394104,168.920471 353.274841,165.090302 349.441162,159.610703 
	C345.784943,154.384720 342.007843,152.128632 335.527771,153.005508 
	C324.704071,153.011810 314.348663,153.021317 303.525269,153.020767 
	C293.035614,153.025421 283.013885,153.040131 272.549011,153.041779 
	C269.739746,153.038452 267.373657,153.048172 264.577881,153.036880 
	C261.765320,153.342590 259.382477,153.669327 256.693237,154.130188 
	C254.585144,155.831177 252.783417,157.397995 250.644257,159.029282 
	C246.334747,161.908447 245.097626,164.848999 248.625702,169.241318 
	C251.454941,172.763626 249.679474,177.250809 245.846756,179.661102 
	C241.334335,182.498840 237.997971,179.729446 234.400497,177.896469 
	C232.011353,176.679153 230.138901,176.058319 230.995575,179.995316 
	C230.995575,179.995316 231.000000,180.000000 230.620590,179.995026 
	C229.486664,179.985504 228.732132,179.980942 227.880859,179.772186 
	C227.598740,179.317673 227.359253,179.140411 227.065674,179.036194 
	C227.304886,178.222458 227.544098,177.408707 228.248840,176.269196 
	C232.353256,174.652863 235.901016,173.335846 239.288345,176.813339 
	C239.800629,177.339279 241.784576,176.431732 243.351074,176.079651 
	C244.071472,175.650436 244.528580,175.328827 245.331940,174.951431 
	C247.562088,173.213348 247.770737,171.839142 244.754669,170.912674 
	C244.280884,170.617111 244.089539,170.372040 243.980774,169.674210 
	C243.811539,167.192429 243.831573,165.059738 243.352432,163.045837 
	C243.162201,162.246353 241.864868,161.710251 241.078400,160.703842 
	C242.280289,158.849365 243.462326,157.334717 244.673294,155.843567 
	C246.787430,153.240250 248.919357,150.651382 251.043823,148.056458 
z"/>
<path fill="#052273" opacity="1.000000" stroke="none" 
	d="
M116.829071,300.039734 
	C118.025330,283.324707 124.569839,269.055145 137.134125,257.083557 
	C140.157547,256.876282 142.462402,256.796143 141.337585,253.379150 
	C141.337585,253.379150 141.477829,253.466705 141.810883,253.577576 
	C143.275360,253.731125 144.640732,253.374817 145.501266,253.879425 
	C152.546677,258.010834 156.911240,253.978714 161.493408,249.004608 
	C177.642365,249.016571 193.324310,249.027252 208.905045,249.422897 
	C207.470047,250.204971 206.160553,250.755661 204.798035,250.971054 
	C200.339691,251.675812 195.862061,252.258499 190.533615,253.009018 
	C186.553604,247.632156 180.063675,253.473022 174.788803,250.943237 
	C174.510437,253.280243 173.914505,254.969070 174.243011,256.453278 
	C174.574646,257.951630 176.003510,259.169617 176.636749,260.645599 
	C178.708450,265.474335 176.555237,268.632446 171.156403,269.038940 
	C170.083252,269.119751 169.046265,269.681091 167.577728,270.020844 
	C165.779129,270.020020 164.395432,270.019135 162.804596,269.766357 
	C161.717499,270.208618 160.837555,270.902740 159.508240,271.695892 
	C155.048859,271.865692 151.038864,271.936493 146.850250,271.779785 
	C146.161682,272.073151 145.651718,272.594025 145.094116,273.075562 
	C145.046478,273.036194 145.111557,273.141235 144.750580,273.126831 
	C141.400543,274.707001 139.068237,278.040497 134.802322,276.450226 
	C134.420898,276.307983 132.505859,278.929413 132.124634,280.470581 
	C131.621979,282.502625 131.932266,284.735809 131.649261,287.110596 
	C128.636505,289.921509 125.867546,292.504333 122.751221,295.103027 
	C121.910126,296.038177 121.416389,296.957489 120.726074,298.154999 
	C119.296013,298.968719 118.062538,299.504242 116.829071,300.039734 
z"/>
<path fill="#02277E" opacity="1.000000" stroke="none" 
	d="
M193.119080,239.552170 
	C195.543289,239.389664 197.967499,239.227142 200.839188,239.034637 
	C201.346115,236.594162 201.864334,234.099289 202.967438,231.269302 
	C207.754074,231.301163 208.427658,227.363754 210.627548,225.236954 
	C213.952591,222.022385 217.507416,219.045517 221.283600,215.937119 
	C222.079514,215.295029 222.556427,214.683456 223.475250,214.056793 
	C226.278992,214.029129 228.640839,214.016571 231.296661,214.291046 
	C235.467270,214.542664 239.649765,216.895111 243.456345,213.096497 
	C245.230667,213.137543 246.594833,213.192871 247.973129,213.640503 
	C248.141937,218.642670 251.676071,216.613052 253.902649,216.955475 
	C256.778107,217.397720 257.121613,215.421265 257.470154,213.181366 
	C259.923187,213.178543 261.944397,213.216827 263.975159,213.601898 
	C264.489380,215.579987 264.994019,217.211319 265.680328,219.429977 
	C263.797668,219.881119 261.863770,221.024033 260.502228,220.543869 
	C256.352997,219.080658 253.455200,220.529236 250.860641,223.498245 
	C249.373199,225.200317 247.918121,226.930649 246.116623,229.036087 
	C244.629517,228.173737 243.150009,226.684341 241.569473,226.568085 
	C239.816605,226.439148 236.544220,227.330444 236.404709,228.182251 
	C235.657730,232.742432 233.448074,237.562576 236.758026,242.164032 
	C236.362625,242.911880 236.203094,243.474609 235.822601,244.048492 
	C235.601639,244.059631 235.160599,244.023727 234.682190,244.015762 
	C220.445755,243.976440 206.687714,243.945084 192.943222,243.551590 
	C193.010864,241.977020 193.064972,240.764603 193.119080,239.552170 
z"/>
<path fill="#0439BA" opacity="1.000000" stroke="none" 
	d="
M247.959015,213.248215 
	C246.594833,213.192871 245.230667,213.137543 242.995514,213.058655 
	C238.417252,213.358063 234.709976,213.681030 231.002686,214.003998 
	C228.640839,214.016571 226.278992,214.029129 223.152908,214.073486 
	C221.913986,214.726059 221.439285,215.346832 220.964600,215.967621 
	C217.507416,219.045517 213.952591,222.022385 210.627548,225.236954 
	C208.427658,227.363754 207.754074,231.301163 203.159561,230.920990 
	C202.329102,230.029877 201.891388,229.151932 201.234100,227.815277 
	C201.074112,226.942047 201.133682,226.527527 201.193268,226.113007 
	C204.372696,222.966293 207.552139,219.819580 211.203522,216.313660 
	C212.121094,215.306168 212.566696,214.657867 213.012299,214.009583 
	C215.111023,212.362381 217.209747,210.715195 220.084503,209.022186 
	C227.251495,208.655014 233.639771,208.198853 240.034622,208.070297 
	C243.142303,208.007828 244.271896,207.046829 245.360764,203.848709 
	C246.292145,201.113174 249.670090,198.981400 252.399796,197.277832 
	C252.955902,196.930771 255.446228,199.682968 257.033600,201.382294 
	C257.688416,203.501938 258.354584,205.245743 259.287445,207.272263 
	C263.704163,207.704514 267.854218,207.854050 272.299133,207.990173 
	C273.072357,207.677521 273.550659,207.378250 274.494934,207.072479 
	C280.625946,207.070297 286.291016,207.074600 292.068054,207.462708 
	C293.681213,208.227051 295.175629,208.900513 296.684845,208.935562 
	C302.116516,209.061691 307.553223,208.972427 313.433411,208.978210 
	C316.255585,208.985931 318.632355,208.980011 321.452454,208.980316 
	C324.261017,208.983505 326.626251,208.980499 329.458740,208.986877 
	C334.945831,209.004456 339.965637,209.012634 344.989929,209.419739 
	C344.999329,210.885788 345.004272,211.952896 344.608948,213.028275 
	C338.856018,213.353806 333.500885,213.972382 328.151184,213.928589 
	C315.087952,213.821625 302.027222,213.408127 288.501587,213.088989 
	C282.362274,213.108261 276.686829,213.156601 270.573608,213.163177 
	C268.079071,213.165985 266.022308,213.210541 263.965576,213.255112 
	C261.944397,213.216827 259.923187,213.178543 257.018250,213.116364 
	C253.409317,213.144409 250.684158,213.196304 247.959015,213.248215 
M249.104279,202.481415 
	C249.405396,203.965073 249.330521,205.742462 250.164398,206.809937 
	C250.588898,207.353348 253.162521,207.083664 253.830353,206.341141 
	C254.581085,205.506531 254.278397,203.724396 254.433975,202.354446 
	C252.836227,202.226273 251.238480,202.098083 249.104279,202.481415 
z"/>
<path fill="#0639BE" opacity="1.000000" stroke="none" 
	d="
M169.006042,304.539246 
	C168.413193,305.079987 167.869537,305.285889 166.861145,305.878387 
	C164.614471,308.182434 162.832565,310.099884 161.050659,312.017334 
	C158.138702,314.817627 155.226730,317.617920 151.869476,320.848022 
	C150.289444,322.520325 149.154694,323.762848 148.019958,325.005402 
	C143.578217,329.722321 139.156586,334.458527 134.672379,339.134735 
	C134.023132,339.811768 133.073761,340.200989 132.053238,341.021118 
	C130.864014,340.631287 129.884827,339.943787 128.833221,338.965729 
	C129.766693,336.689941 130.386734,334.346008 131.843079,332.779602 
	C139.417099,324.633148 147.163635,316.645142 154.947357,308.697144 
	C156.751434,306.854980 158.935394,305.384827 161.353256,303.347107 
	C162.847321,301.640167 163.932678,300.329620 165.018036,299.019073 
	C165.934570,297.896484 166.851089,296.773895 168.245575,295.296356 
	C170.129257,294.266998 171.535019,293.592621 173.245209,292.921265 
	C174.034088,292.612122 174.518509,292.299988 175.459320,291.985962 
	C181.249329,291.692657 186.582962,291.401276 192.045746,291.487122 
	C195.688187,292.246613 199.201233,292.631165 202.714890,293.009949 
	C204.274261,293.178040 205.834869,293.334625 207.394913,293.496460 
	C207.348724,293.997620 207.302536,294.498749 207.256348,294.999908 
	C203.806595,294.999908 200.342804,294.820343 196.914078,295.088776 
	C195.552444,295.195374 194.268570,296.295227 192.949310,296.942871 
	C189.477051,296.950897 185.994064,297.136871 182.534958,296.927704 
	C176.465881,296.560760 172.006363,298.921722 169.006042,304.539246 
z"/>
<path fill="#053FC9" opacity="1.000000" stroke="none" 
	d="
M146.098221,182.188873 
	C149.467819,182.296921 152.656006,182.990448 155.838440,182.964920 
	C169.242477,182.857361 182.644455,182.508484 196.048325,182.334854 
	C197.317947,182.318405 198.595413,182.908386 200.306778,183.331238 
	C201.265976,184.369110 201.787659,185.292572 202.284821,186.490784 
	C200.372162,187.510056 198.523438,188.740189 196.587631,188.897263 
	C192.656860,189.216187 188.678970,188.961517 184.720581,188.931091 
	C180.309891,188.897171 175.920288,188.342163 172.543945,192.527634 
	C170.975433,194.472031 168.644485,194.165161 166.509277,192.129364 
	C164.983765,190.674866 162.707169,190.008133 159.930161,188.988068 
	C156.088501,188.931061 153.082413,188.875900 149.854706,188.587021 
	C148.438080,187.884506 147.243057,187.415695 145.811432,186.902069 
	C145.574844,186.857269 145.101349,186.769302 145.101349,186.769302 
	C145.372284,185.327393 145.643234,183.885498 146.098221,182.188873 
z"/>
<path fill="#00056D" opacity="1.000000" stroke="none" 
	d="
M192.929672,243.913727 
	C206.687714,243.945084 220.445755,243.976440 234.611816,244.364319 
	C233.012482,246.482910 231.005127,248.244980 228.621155,249.838181 
	C224.494522,249.458130 220.744492,249.246933 216.550934,249.028000 
	C213.740341,249.026154 211.373306,249.032059 209.006271,249.037949 
	C193.324310,249.027252 177.642365,249.016571 161.261902,248.740387 
	C158.673447,247.263199 156.783493,246.051529 154.893555,244.839874 
	C156.028488,244.227325 157.163406,243.614777 158.629272,243.409119 
	C160.042877,247.009140 162.009995,246.294983 164.492920,245.002563 
	C165.881500,244.979294 166.889755,244.935699 168.140137,245.178391 
	C172.068115,245.589386 175.775391,246.018555 179.434387,245.761307 
	C183.953781,245.443573 188.433060,244.555405 192.929672,243.913727 
z"/>
<path fill="#1173BF" opacity="1.000000" stroke="none" 
	d="
M196.655579,347.052307 
	C195.742752,349.175720 194.468826,351.267761 192.896637,353.896729 
	C192.052429,354.926300 191.506470,355.418945 190.659286,356.064575 
	C187.230484,359.472717 184.102905,362.727875 180.697449,366.104065 
	C179.904663,367.050140 179.389771,367.875153 178.546753,368.803680 
	C176.150024,370.938141 174.081436,372.969147 172.012848,375.000122 
	C171.657806,375.397461 171.302750,375.794769 170.553162,376.598511 
	C169.783295,378.670105 169.407959,380.335297 169.032639,382.000519 
	C167.570618,383.945496 166.108597,385.890472 163.947876,387.912903 
	C162.498749,388.040649 161.748306,388.090912 160.997864,388.141174 
	C154.676407,387.137939 154.092896,386.355072 154.392761,378.323608 
	C155.167206,376.848785 155.669434,375.964935 156.171677,375.081055 
	C156.468658,374.996643 156.707993,374.829620 157.338120,374.345276 
	C158.877533,373.799133 159.968491,373.487671 161.059464,373.176208 
	C162.547562,372.971008 164.035645,372.765839 166.066467,372.142212 
	C170.137253,367.828400 173.665298,363.932983 177.246368,359.808838 
	C177.299393,359.580078 177.416504,359.125244 177.714020,359.082611 
	C178.467926,358.423981 178.924316,357.807953 179.669403,357.152466 
	C180.636292,356.741547 181.314484,356.370087 182.309387,355.864380 
	C183.419571,354.646362 184.213028,353.562592 185.006500,352.478821 
	C188.769165,350.659546 192.531830,348.840271 196.655579,347.052307 
M157.066086,378.458954 
	C157.446518,380.481842 157.310623,382.871735 158.393524,384.395386 
	C159.086182,385.369995 162.105835,385.843262 163.138214,385.145142 
	C164.572037,384.175568 165.469620,381.982544 165.920425,380.147156 
	C166.201965,379.000885 165.124207,376.388336 164.850906,376.415131 
	C162.405258,376.655182 160.002869,377.335968 157.066086,378.458954 
z"/>
<path fill="#1774CB" opacity="1.000000" stroke="none" 
	d="
M123.098572,295.087158 
	C125.867546,292.504333 128.636505,289.921509 132.008575,286.972839 
	C133.082672,284.787811 133.351318,282.879700 134.097641,281.181488 
	C134.575043,280.095123 136.037155,278.385895 136.581253,278.548706 
	C141.666473,280.070160 143.244720,276.446991 145.111557,273.141235 
	C145.111557,273.141235 145.046478,273.036194 145.379395,273.039673 
	C146.151154,272.697876 146.590012,272.352570 147.028870,272.007294 
	C151.038864,271.936493 155.048859,271.865692 159.831726,271.821747 
	C161.060852,271.896729 161.517120,271.944824 161.973099,272.387878 
	C161.963287,273.832550 161.953766,274.882263 161.944244,275.931946 
	C158.229111,276.200226 154.513977,276.468475 150.051697,276.840546 
	C147.841675,277.949951 146.378799,278.955536 144.915924,279.961090 
	C142.125793,282.753510 139.299957,285.511292 136.553162,288.345703 
	C131.271576,293.795807 125.803688,299.093231 120.903305,304.869843 
	C119.163788,306.920410 118.849884,310.180359 117.528595,312.927795 
	C116.908363,308.878448 116.644699,304.789520 116.605057,300.370148 
	C118.062538,299.504242 119.296013,298.968719 121.032791,298.065125 
	C122.056915,296.827026 122.577736,295.957092 123.098572,295.087158 
z"/>
<path fill="#1A316D" opacity="1.000000" stroke="none" 
	d="
M144.966049,187.185364 
	C145.101349,186.769302 145.574844,186.857269 145.792053,187.280670 
	C146.014679,188.458450 146.020096,189.212860 146.012482,190.425446 
	C144.353729,197.258499 147.224319,203.195068 149.643784,208.291550 
	C154.142731,217.768356 162.203201,224.093750 173.025497,226.003342 
	C182.088669,226.018372 191.151840,226.033401 200.704132,226.080719 
	C201.133682,226.527527 201.074112,226.942047 200.964127,227.667786 
	C190.988815,227.917953 180.995667,228.572037 171.154572,227.632843 
	C155.296356,226.119385 142.903931,208.898026 144.289017,191.541199 
	C144.394318,190.221924 144.646729,188.914398 144.966049,187.185364 
z"/>
<path fill="#051F6B" opacity="1.000000" stroke="none" 
	d="
M181.992676,355.998596 
	C181.314484,356.370087 180.636292,356.741547 179.267761,357.121216 
	C176.718903,357.753754 174.860367,358.378082 173.001831,359.002441 
	C158.543900,361.728729 144.155838,361.430573 129.645264,358.937439 
	C125.518677,358.228424 121.978226,358.056000 120.158646,353.376495 
	C120.986015,353.126526 122.063934,352.924927 122.907059,353.280334 
	C142.245926,361.432312 161.671539,359.996826 181.560303,354.229309 
	C181.974106,355.025146 181.983398,355.511871 181.992676,355.998596 
z"/>
<path fill="#1546AD" opacity="1.000000" stroke="none" 
	d="
M192.943222,243.551575 
	C188.433060,244.555405 183.953781,245.443573 179.434387,245.761307 
	C175.775391,246.018555 172.068115,245.589386 168.340149,244.930084 
	C169.511353,243.017746 170.724701,241.640015 171.938049,240.262299 
	C175.232742,239.964279 178.522171,239.557312 181.823563,239.398209 
	C185.277863,239.231750 188.743668,239.303467 192.661758,239.411621 
	C193.064972,240.764603 193.010864,241.977020 192.943222,243.551575 
z"/>
<path fill="#010558" opacity="1.000000" stroke="none" 
	d="
M154.528870,244.794708 
	C156.783493,246.051529 158.673447,247.263199 160.794891,248.739105 
	C156.911240,253.978714 152.546677,258.010834 145.501266,253.879425 
	C144.640732,253.374817 143.275360,253.731125 141.774536,253.503342 
	C145.658127,250.462006 149.911148,247.605774 154.528870,244.794708 
z"/>
<path fill="#0455DA" opacity="1.000000" stroke="none" 
	d="
M243.087784,176.187256 
	C241.784576,176.431732 239.800629,177.339279 239.288345,176.813339 
	C235.901016,173.335846 232.353256,174.652863 228.331116,175.942764 
	C227.947845,175.942123 227.736221,175.599762 228.020508,175.217056 
	C228.579880,174.259415 228.854950,173.684464 229.452850,173.046906 
	C230.846893,171.645279 231.918106,170.306274 233.253067,168.766998 
	C235.325516,167.028961 237.134216,165.491180 239.291946,163.922897 
	C240.111298,162.945770 240.581619,161.999146 241.049377,161.044632 
	C241.046844,161.036743 241.071579,161.054245 241.071579,161.054245 
	C241.864868,161.710251 243.162201,162.246353 243.352432,163.045837 
	C243.831573,165.059738 243.811539,167.192429 243.589279,169.677643 
	C241.599609,170.999084 240.012238,171.920395 238.424850,172.841705 
	C239.979172,173.956879 241.533478,175.072067 243.087784,176.187256 
z"/>
<path fill="#042475" opacity="1.000000" stroke="none" 
	d="
M193.469360,336.161469 
	C192.959656,333.915100 192.583832,332.106934 195.767700,330.947296 
	C198.672668,329.889282 200.884689,327.001251 203.502563,325.054840 
	C205.032669,323.917236 206.759598,323.044373 208.774841,322.080994 
	C204.046539,326.793274 198.941376,331.476868 193.469360,336.161469 
z"/>
<path fill="#2159B0" opacity="1.000000" stroke="none" 
	d="
M228.286926,312.166809 
	C230.364136,311.589111 232.217163,311.235687 234.462189,311.255066 
	C234.438034,313.132690 234.021866,314.637512 233.313171,316.170776 
	C232.698059,316.398590 232.375473,316.597961 232.052887,316.797302 
	C229.957870,317.537384 227.862869,318.277466 225.381607,319.019897 
	C226.017807,316.811890 227.040253,314.601501 228.286926,312.166809 
z"/>
<path fill="#042475" opacity="1.000000" stroke="none" 
	d="
M209.453629,322.061768 
	C210.241913,319.374634 211.234650,316.528900 212.548538,313.840088 
	C214.199051,310.462494 216.155716,307.235962 217.899033,303.901764 
	C219.323990,301.176422 220.278580,301.407654 220.992065,304.632446 
	C217.661407,310.144531 214.326111,315.292755 210.977417,320.432281 
	C210.625656,320.972168 210.194809,321.460541 209.453629,322.061768 
z"/>
<path fill="#318AC2" opacity="1.000000" stroke="none" 
	d="
M171.540466,240.184128 
	C170.724701,241.640015 169.511353,243.017746 168.098007,244.643799 
	C166.889755,244.935699 165.881500,244.979294 164.351486,244.729675 
	C162.907166,243.691406 161.984604,242.946350 161.062057,242.201294 
	C164.422333,241.502853 167.782623,240.804413 171.540466,240.184128 
z"/>
<path fill="#032275" opacity="1.000000" stroke="none" 
	d="
M226.856537,172.032349 
	C226.712006,171.425827 226.567474,170.819321 226.641876,169.576965 
	C226.610306,168.307571 226.359787,167.674026 226.109253,167.040466 
	C225.479599,165.211090 224.849945,163.381729 224.224091,161.199982 
	C224.355164,160.698135 224.482391,160.548660 225.191696,160.250366 
	C226.870300,163.098999 227.966827,166.096451 229.051575,169.454254 
	C228.993271,170.536194 228.946716,171.257782 228.559479,171.985168 
	C227.764725,172.004761 227.310623,172.018555 226.856537,172.032349 
z"/>
<path fill="#042475" opacity="1.000000" stroke="none" 
	d="
M223.107361,305.587433 
	C225.201355,305.341187 227.578323,305.305511 229.955276,305.269836 
	C229.928406,306.448090 229.901535,307.626373 229.529663,308.893005 
	C227.253204,307.920258 225.321762,306.859131 223.107361,305.587433 
z"/>
<path fill="#1546AD" opacity="1.000000" stroke="none" 
	d="
M160.759277,242.210541 
	C161.984604,242.946350 162.907166,243.691406 163.971161,244.709351 
	C162.009995,246.294983 160.042877,247.009140 158.961853,243.444672 
	C159.461151,242.788849 159.958817,242.504318 160.759277,242.210541 
z"/>
<path fill="#012B87" opacity="1.000000" stroke="none" 
	d="
M227.977615,179.976379 
	C228.732132,179.980942 229.486664,179.985504 230.618317,179.992859 
	C230.311569,181.429825 229.627686,182.864029 228.802048,184.595474 
	C228.106781,183.514297 227.689423,182.865295 227.007507,182.059464 
	C226.741074,181.458603 226.739197,181.014557 227.136505,180.277130 
	C227.535675,179.983734 227.977615,179.976379 227.977615,179.976379 
z"/>
<path fill="#010558" opacity="1.000000" stroke="none" 
	d="
M141.042542,253.477631 
	C142.462402,256.796143 140.157547,256.876282 137.459930,256.985352 
	C138.292435,255.866745 139.519974,254.721420 141.042542,253.477631 
z"/>
<path fill="#012173" opacity="1.000000" stroke="none" 
	d="
M182.309387,355.864380 
	C181.983398,355.511871 181.974106,355.025146 181.944962,354.174072 
	C182.724640,353.434662 183.524170,353.059570 184.665100,352.581635 
	C184.213028,353.562592 183.419571,354.646362 182.309387,355.864380 
z"/>
<path fill="#042A80" opacity="1.000000" stroke="none" 
	d="
M226.743454,172.382965 
	C227.310623,172.018555 227.764725,172.004761 228.610733,172.269135 
	C229.045120,172.734741 229.087570,172.922134 229.130035,173.109512 
	C228.854950,173.684464 228.579880,174.259415 228.033600,175.016235 
	C227.385056,174.376617 227.007706,173.555099 226.743454,172.382965 
z"/>
<path fill="#042A80" opacity="1.000000" stroke="none" 
	d="
M226.055481,167.391876 
	C226.359787,167.674026 226.610306,168.307571 226.801300,169.298691 
	C226.495102,169.018600 226.248413,168.380936 226.055481,167.391876 
z"/>
<path fill="#02267D" opacity="1.000000" stroke="none" 
	d="
M227.880859,179.772186 
	C227.977615,179.976379 227.535675,179.983734 227.314713,179.987549 
	C227.032272,179.833786 226.970779,179.676178 226.987488,179.277374 
	C227.359253,179.140411 227.598740,179.317673 227.880859,179.772186 
z"/>
<path fill="#011D6E" opacity="1.000000" stroke="none" 
	d="
M84.599457,112.305069 
	C83.128593,109.644684 79.597244,108.073563 82.164116,103.542465 
	C84.673141,105.772308 87.165680,107.578224 89.032082,109.889717 
	C89.609871,110.605301 88.485184,112.695541 88.139954,114.156403 
	C87.025558,113.639969 85.911163,113.123535 84.599457,112.305069 
z"/>
<path fill="#053FC9" opacity="1.000000" stroke="none" 
	d="
M832.750000,102.697441 
	C831.167847,103.140190 829.326721,103.326866 827.239746,103.235321 
	C828.826233,102.785179 830.658630,102.613274 832.750000,102.697441 
z"/>
<path fill="#011C7F" opacity="1.000000" stroke="none" 
	d="
M774.452454,95.528870 
	C774.422913,95.478172 774.481995,95.579575 774.452454,95.528870 
z"/>
<path fill="#F8FBFC" opacity="1.000000" stroke="none" 
	d="
M529.022095,158.989136 
	C536.826294,153.854965 544.630493,148.720795 552.582336,143.294861 
	C567.033936,134.798325 582.238953,129.304916 598.417053,125.749725 
	C612.508484,122.653091 626.576294,121.586197 640.852112,121.952530 
	C658.535400,122.406296 675.605774,126.134300 692.794434,132.293732 
	C693.744324,132.503983 694.115784,132.525482 694.506653,132.529861 
	C694.526062,132.512741 694.574829,132.495148 694.640442,132.738647 
	C695.018616,133.088226 695.331116,133.194305 695.898071,133.546219 
	C699.102478,135.015015 702.052429,136.237976 705.262573,137.717896 
	C706.630005,138.432236 707.737244,138.889572 708.844482,139.346924 
	C708.844482,139.346939 709.017822,139.457062 709.343079,139.700668 
	C713.631470,142.275970 717.838440,144.279175 721.509949,147.003876 
	C730.778931,153.882645 739.806885,161.086182 749.002441,168.439301 
	C749.882935,169.489700 750.692383,170.260406 751.555298,171.420135 
	C756.122681,179.182022 755.390564,186.626694 751.651733,194.259949 
	C750.576721,195.552872 749.763794,196.694778 748.604309,197.928375 
	C739.143677,204.734070 730.060425,204.094757 720.944275,197.610199 
	C720.233948,196.888992 719.579834,196.463333 718.834351,195.710175 
	C712.380371,190.520752 706.017700,185.658829 699.486328,180.464645 
	C696.286621,178.656097 693.255676,177.179810 690.159302,175.779541 
	C690.093933,175.855560 690.265991,175.752563 690.125732,175.412598 
	C686.711609,173.471741 683.437805,171.870819 679.986938,170.031097 
	C679.251343,169.651245 678.692749,169.510193 677.908325,169.200378 
	C677.127930,168.990387 676.573242,168.949173 675.817993,168.624756 
	C673.432251,167.606995 671.247131,166.872437 668.702393,165.999954 
	C667.286804,165.434174 666.258057,164.913818 665.170593,164.592316 
	C649.418762,159.935486 633.294250,159.003723 617.216797,161.564438 
	C608.303894,162.984024 599.719971,166.469894 590.617798,169.066650 
	C586.829956,170.776031 583.411987,172.443207 579.638611,174.034027 
	C574.838684,176.688019 570.394226,179.418350 565.642944,182.181580 
	C564.228088,183.174713 563.120056,184.134949 561.670166,185.128510 
	C555.830994,189.965012 550.333557,194.768188 544.567017,199.614380 
	C544.173401,199.789566 544.048889,199.921783 543.598633,200.115936 
	C541.969238,200.872009 540.665649,201.566177 538.945862,202.286163 
	C532.976929,201.773621 527.424194,201.235260 521.831116,200.327332 
	C519.168762,196.312622 516.546814,192.667480 513.975586,188.692093 
	C513.662170,187.546783 513.298035,186.731720 512.986877,185.497879 
	C512.971802,183.395615 512.903748,181.712128 513.000732,179.736725 
	C515.837524,175.019989 518.509216,170.595200 521.490601,166.078400 
	C522.937012,164.741409 524.073853,163.496445 525.518921,162.155273 
	C526.892212,161.035751 527.957153,160.012436 529.022095,158.989136 
z"/>
<path fill="#003498" opacity="1.000000" stroke="none" 
	d="
M712.682983,123.947266 
	C714.258240,124.808372 715.479980,125.639610 716.808350,127.107559 
	C715.575989,130.971375 716.959412,132.503571 720.136658,132.919067 
	C720.136658,132.919067 720.555664,132.908813 720.740967,133.258179 
	C719.950867,134.746246 718.975342,135.884949 717.999878,137.023651 
	C715.955322,135.217957 712.798096,133.773651 712.072388,131.539581 
	C709.791748,124.517998 703.892822,124.059135 698.376343,123.078323 
	C693.608459,122.230614 689.797546,121.058632 689.197693,115.340080 
	C688.896484,112.468231 689.326172,111.392975 692.564758,110.853470 
	C698.783997,109.817421 701.332642,114.308762 704.846924,117.650223 
	C707.200012,119.887650 709.825684,121.838371 712.682983,123.947266 
z"/>
<path fill="#1C428F" opacity="1.000000" stroke="none" 
	d="
M717.796326,137.297516 
	C718.975342,135.884949 719.950867,134.746246 720.949585,133.277588 
	C720.972900,132.947662 720.987976,132.953369 721.270752,133.115051 
	C722.674622,133.881927 723.795715,134.487122 724.881775,135.436981 
	C720.354980,142.467331 720.354980,142.467331 727.169678,147.294083 
	C732.238403,151.056931 737.104980,154.546448 742.030518,158.367203 
	C744.400574,161.135941 746.711487,163.573456 749.016846,166.369354 
	C748.984680,167.205032 748.958008,167.682327 748.931396,168.159607 
	C739.806885,161.086182 730.778931,153.882645 721.509949,147.003876 
	C717.838440,144.279175 713.631470,142.275970 709.440125,139.667206 
	C712.005554,138.783859 714.799133,138.177612 717.796326,137.297516 
z"/>
<path fill="#0946BC" opacity="1.000000" stroke="none" 
	d="
M694.487183,132.546982 
	C694.115784,132.525482 693.744324,132.503983 693.175842,132.269394 
	C691.643066,126.418762 694.109314,123.769814 699.733032,125.562119 
	C703.004883,126.604866 706.082458,128.700195 708.815430,130.866806 
	C711.572388,133.052490 711.442871,135.950272 708.926758,138.970642 
	C707.737244,138.889572 706.630005,138.432236 705.282227,137.393143 
	C705.982239,131.375290 702.517212,128.733246 694.988403,129.321014 
	C694.826599,130.362366 694.656921,131.454666 694.487183,132.546982 
z"/>
<path fill="#033A9F" opacity="1.000000" stroke="none" 
	d="
M440.120453,80.588547 
	C439.140503,79.843094 438.077209,78.779617 437.014771,77.343338 
	C438.022827,78.070534 439.029968,79.170532 440.120453,80.588547 
z"/>
<path fill="#011C7F" opacity="1.000000" stroke="none" 
	d="
M848.543823,106.968369 
	C850.361206,107.001076 851.756348,107.066124 853.521179,107.514618 
	C850.595398,111.932350 847.299866,115.966637 844.002197,120.000465 
	C844.000000,120.000000 844.000000,120.003731 843.691101,120.140472 
	C842.253967,121.849998 841.125793,123.422775 839.695801,125.147644 
	C837.261902,127.198792 835.129700,129.097870 832.654236,131.035492 
	C830.540649,133.048477 828.770325,135.022919 826.654175,137.062225 
	C825.848022,138.331650 825.387756,139.536194 824.927490,140.740738 
	C823.882446,140.801819 822.837402,140.862900 821.179443,140.677643 
	C819.706116,140.271912 818.845703,140.112534 817.985352,139.953125 
	C822.287659,134.536087 826.621155,129.143417 830.883362,123.694977 
	C834.965393,118.476860 838.973450,113.200783 843.029358,107.608749 
	C844.737000,107.178467 846.429199,107.089592 848.543823,106.968369 
z"/>
<path fill="#0332AA" opacity="1.000000" stroke="none" 
	d="
M817.599670,139.801147 
	C818.845703,140.112534 819.706116,140.271912 820.786255,140.676056 
	C818.337830,140.944061 815.668701,141.022552 813.001587,140.979752 
	C806.198486,140.870621 799.396240,140.707336 792.295776,140.272110 
	C800.403259,139.868713 808.808655,139.758942 817.599670,139.801147 
z"/>
<path fill="#0430AF" opacity="1.000000" stroke="none" 
	d="
M791.031860,107.483604 
	C792.353088,107.104294 793.773560,107.063293 795.618713,107.065834 
	C794.774231,109.885803 793.063599,109.619316 791.031860,107.483604 
z"/>
<path fill="#11A7F5" opacity="1.000000" stroke="none" 
	d="
M573.859863,105.850479 
	C575.651001,105.683823 577.158020,105.799736 578.665039,105.915649 
	C578.291016,107.235703 578.260315,108.866508 577.435120,109.778183 
	C576.766663,110.516693 575.142639,110.390335 573.942505,110.647591 
	C573.820190,109.142746 573.697937,107.637894 573.859863,105.850479 
z"/>
<path fill="#023EA7" opacity="1.000000" stroke="none" 
	d="
M529.025085,158.620895 
	C527.957153,160.012436 526.892212,161.035751 525.140198,161.932953 
	C522.345764,161.525818 520.239014,161.005096 518.131042,161.000443 
	C494.019531,160.947235 469.907806,160.974716 445.796173,160.972031 
	C444.301361,160.971863 442.250427,161.520386 441.401703,160.760254 
	C435.761169,155.708359 430.377991,150.369186 424.911102,145.123428 
	C424.911102,145.123428 424.977264,145.015930 425.011871,144.961945 
	C426.775208,145.615921 428.503937,146.323883 430.635803,147.358658 
	C431.692291,148.456772 432.345612,149.228073 433.314453,150.140289 
	C435.817291,151.744141 438.094879,153.089890 440.174957,154.692093 
	C446.287506,159.400375 448.949371,158.579849 450.460999,151.487152 
	C450.654541,150.579041 451.473785,149.804291 452.003174,148.967773 
	C456.060028,148.322067 460.116913,147.676361 464.592896,147.039520 
	C466.747131,147.367859 468.481354,147.959396 470.217468,147.965042 
	C489.672791,148.028381 509.129059,148.085449 528.583374,147.927658 
	C532.452209,147.896286 536.313660,146.949219 541.576294,146.234619 
	C537.756836,149.340485 534.783325,151.575836 532.043884,154.068405 
	C530.799622,155.200531 530.018982,156.842163 529.025085,158.620895 
z"/>
<path fill="#043898" opacity="1.000000" stroke="none" 
	d="
M729.201172,131.293060 
	C732.680542,131.928741 734.645508,129.582825 736.252319,127.077507 
	C738.279968,123.915863 740.992920,123.362617 743.826599,125.185631 
	C747.202515,127.357430 750.236450,130.074646 753.343506,132.646255 
	C755.924561,134.782547 758.396851,137.050156 760.965637,139.608215 
	C755.787048,139.977631 750.536560,139.697098 745.339966,140.103943 
	C740.132568,140.511627 736.051636,139.302277 733.075317,134.761398 
	C732.217834,133.453232 730.600037,132.643433 729.201172,131.293060 
z"/>
<path fill="#0F3C91" opacity="1.000000" stroke="none" 
	d="
M709.000000,106.015823 
	C717.715576,110.306824 726.431213,114.597832 735.788269,119.204674 
	C732.380005,122.446091 730.048401,124.663582 727.218994,126.637527 
	C724.826111,125.252518 722.931030,124.111053 721.035950,122.969589 
	C718.470398,120.497574 715.904907,118.025566 713.318359,114.958031 
	C712.806519,113.012978 712.451538,111.592148 711.789856,110.332352 
	C710.996521,108.821754 709.941650,107.448532 709.000000,106.015823 
z"/>
<path fill="#00359C" opacity="1.000000" stroke="none" 
	d="
M741.971497,158.035965 
	C737.104980,154.546448 732.238403,151.056931 727.445312,147.040222 
	C728.020447,144.329559 728.522095,142.146057 729.023804,139.962555 
	C730.250793,140.785126 731.477783,141.607681 732.858521,143.093719 
	C734.008667,144.499985 735.005005,145.242798 736.001404,145.985596 
	C736.168091,145.984467 736.334717,145.983337 737.016968,146.266220 
	C739.357178,146.703415 741.181824,146.856598 742.952820,147.336395 
	C742.862305,149.314728 742.915405,150.974716 742.764465,152.615967 
	C742.597534,154.431580 742.243835,156.230042 741.971497,158.035965 
z"/>
<path fill="#00359C" opacity="1.000000" stroke="none" 
	d="
M769.559631,145.061340 
	C770.896667,145.551590 771.761841,146.032104 772.541931,147.036011 
	C765.758606,148.038712 759.064575,148.617172 752.358093,148.897827 
	C751.264893,148.943573 750.116638,147.673035 749.226562,146.851593 
	C749.648254,146.177856 749.837219,145.663406 750.026123,145.148972 
	C756.380005,145.116516 762.733948,145.084076 769.559631,145.061340 
z"/>
<path fill="#003498" opacity="1.000000" stroke="none" 
	d="
M729.019165,139.721344 
	C728.522095,142.146057 728.020447,144.329559 727.243164,146.766907 
	C720.354980,142.467331 720.354980,142.467331 725.147034,135.410019 
	C725.624207,135.031204 725.801086,135.024002 725.978027,135.016800 
	C726.302673,135.477554 726.627319,135.938309 727.019592,137.012756 
	C727.721558,138.083710 728.355896,138.540985 728.990234,138.998260 
	C728.990234,138.998260 729.014587,139.480148 729.019165,139.721344 
z"/>
<path fill="#023CA5" opacity="1.000000" stroke="none" 
	d="
M708.785400,105.761055 
	C709.941650,107.448532 710.996521,108.821754 711.789856,110.332352 
	C712.451538,111.592148 712.806519,113.012978 713.176514,114.659332 
	C709.470093,111.492744 705.884460,108.029327 702.173462,104.249939 
	C704.222290,104.458061 706.396545,104.982170 708.785400,105.761055 
z"/>
<path fill="#0332AA" opacity="1.000000" stroke="none" 
	d="
M749.564209,145.106964 
	C749.837219,145.663406 749.648254,146.177856 748.799133,146.849625 
	C746.428101,147.007889 744.717224,147.008835 743.006409,147.009766 
	C741.181824,146.856598 739.357178,146.703415 737.266968,146.270966 
	C741.034973,145.682800 745.068665,145.373886 749.564209,145.106964 
z"/>
<path fill="#093C9C" opacity="1.000000" stroke="none" 
	d="
M720.113525,132.604950 
	C716.959412,132.503571 715.575989,130.971375 716.946167,127.407410 
	C717.568665,127.540085 718.160095,128.009598 718.901428,129.046143 
	C719.394714,130.085037 719.738098,130.556915 720.081543,131.028809 
	C720.084473,131.449478 720.087402,131.870163 720.113525,132.604950 
z"/>
<path fill="#023CA5" opacity="1.000000" stroke="none" 
	d="
M721.169067,123.275131 
	C722.931030,124.111053 724.826111,125.252518 726.887939,126.694397 
	C727.038818,127.171104 727.022949,127.347404 726.976562,127.788063 
	C725.064758,126.561844 723.183472,125.071259 721.169067,123.275131 
z"/>
<path fill="#0332AA" opacity="1.000000" stroke="none" 
	d="
M725.837952,134.712601 
	C725.801086,135.024002 725.624207,135.031204 725.182007,135.065353 
	C723.795715,134.487122 722.674622,133.881927 721.273010,133.107712 
	C722.560913,133.428604 724.129395,133.918503 725.837952,134.712601 
z"/>
<path fill="#0332AA" opacity="1.000000" stroke="none" 
	d="
M735.870728,145.706360 
	C735.005005,145.242798 734.008667,144.499985 733.001648,143.389664 
	C733.907349,143.823792 734.823669,144.625443 735.870728,145.706360 
z"/>
<path fill="#0332AA" opacity="1.000000" stroke="none" 
	d="
M728.951294,138.679367 
	C728.355896,138.540985 727.721558,138.083710 727.047729,137.306305 
	C727.642944,137.444275 728.277649,137.902374 728.951294,138.679367 
z"/>
<path fill="#0332AA" opacity="1.000000" stroke="none" 
	d="
M720.290894,130.839874 
	C719.738098,130.556915 719.394714,130.085037 719.025635,129.308868 
	C719.500061,129.553360 720.000183,130.102158 720.290894,130.839874 
z"/>
<path fill="#012B87" opacity="1.000000" stroke="none" 
	d="
M814.980713,145.227692 
	C838.674011,145.154068 862.367249,145.080429 886.788574,145.277008 
	C890.679077,145.372482 893.841675,145.197754 896.953247,145.388382 
	C892.891602,147.169418 888.871338,148.558395 884.875244,150.014008 
	C883.307739,150.584991 881.794617,151.305222 880.256714,151.957458 
	C881.336426,152.988586 882.412354,154.023697 883.498230,155.048325 
	C883.818665,155.350739 884.167114,155.623566 885.744934,156.969940 
	C881.217468,157.388977 877.884460,157.552917 874.599976,158.042603 
	C871.382629,158.522247 867.127563,157.680511 866.529053,162.998581 
	C846.291748,163.330887 826.523438,163.702026 806.753479,163.930496 
	C804.891174,163.952011 803.018494,163.075760 800.029846,162.340637 
	C802.210632,160.536102 803.368042,159.851791 804.163452,158.873230 
	C807.824402,154.369598 811.384521,149.783966 814.980713,145.227692 
z"/>
<path fill="#013295" opacity="1.000000" stroke="none" 
	d="
M742.952820,147.336380 
	C744.717224,147.008835 746.428101,147.007889 748.566406,147.008911 
	C750.116638,147.673035 751.264893,148.943573 752.358093,148.897827 
	C759.064575,148.617172 765.758606,148.038712 772.728394,147.279694 
	C775.173340,146.537186 777.346680,146.074371 779.749939,145.335144 
	C787.342590,145.065948 794.705261,145.073181 802.581177,145.404770 
	C803.729553,146.484024 804.364746,147.238937 804.848389,148.298325 
	C799.801941,154.733292 794.907104,160.863785 789.667358,166.824478 
	C785.211731,166.423019 781.100952,166.191360 776.522217,165.961029 
	C769.921936,165.959503 763.789734,165.950394 757.657471,165.956589 
	C754.779114,165.959503 751.900818,165.991913 749.022461,166.010956 
	C746.711487,163.573456 744.400574,161.135941 742.030518,158.367203 
	C742.243835,156.230042 742.597534,154.431580 742.764465,152.615967 
	C742.915405,150.974716 742.862305,149.314728 742.952820,147.336380 
z"/>
<path fill="#011C7F" opacity="1.000000" stroke="none" 
	d="
M790.012268,166.994293 
	C794.907104,160.863785 799.801941,154.733292 805.230530,148.300018 
	C806.878052,147.070221 807.991821,146.143188 809.105530,145.216156 
	C810.786133,145.179657 812.466675,145.143173 814.563965,145.167191 
	C811.384521,149.783966 807.824402,154.369598 804.163452,158.873230 
	C803.368042,159.851791 802.210632,160.536102 800.029846,162.340637 
	C803.018494,163.075760 804.891174,163.952011 806.753479,163.930496 
	C826.523438,163.702026 846.291748,163.330887 866.997986,162.999603 
	C878.635010,163.330292 889.333801,163.866333 900.034424,163.903061 
	C905.335022,163.921249 908.197327,166.497910 909.585144,170.996216 
	C907.767273,171.024124 906.369202,171.046524 904.505676,171.049744 
	C896.700806,171.004578 889.361267,170.978607 881.933777,170.595093 
	C879.659912,168.611450 877.563293,167.703888 875.612061,170.971436 
	C874.494812,170.989273 873.749939,171.001190 872.670898,171.005630 
	C871.891113,170.997971 871.445557,170.997757 870.558716,170.996521 
	C864.875793,170.332626 859.639771,169.155167 854.391479,169.098114 
	C834.252258,168.879257 814.109131,169.019852 793.572998,169.023346 
	C792.123779,169.012421 791.068970,169.004486 790.007935,168.993988 
	C790.001770,168.991440 790.005310,168.976700 790.017334,168.646576 
	C790.023621,167.875732 790.017944,167.435013 790.012268,166.994293 
z"/>
<path fill="#012B87" opacity="1.000000" stroke="none" 
	d="
M832.997559,130.996948 
	C835.129700,129.097870 837.261902,127.198792 840.049438,125.108444 
	C841.803162,123.279358 842.901550,121.641541 844.000000,120.003723 
	C844.000000,120.003731 844.000000,120.000000 844.383545,119.993729 
	C846.179749,119.217766 847.590820,118.445190 849.005371,117.678909 
	C852.752747,115.648849 856.228943,117.138733 856.998230,120.908112 
	C857.554504,123.633751 858.484558,126.283127 859.485962,129.803345 
	C859.706848,129.148636 859.635254,129.475418 859.491943,129.766953 
	C858.518433,131.747269 857.529907,133.720169 856.546204,135.695435 
	C858.556335,136.131256 860.670410,136.315491 862.541443,137.088181 
	C863.866516,137.635483 864.863098,138.978485 865.540710,139.976273 
	C860.383118,140.304779 855.691162,140.623306 850.999146,140.941833 
	C845.315796,140.927765 839.632385,140.913696 833.475464,140.530579 
	C832.667908,139.107071 832.333923,138.052612 832.045898,136.619507 
	C832.393677,134.492874 832.695618,132.744904 832.997559,130.996948 
z"/>
<path fill="#0332AA" opacity="1.000000" stroke="none" 
	d="
M851.290894,141.243881 
	C855.691162,140.623306 860.383118,140.304779 866.008667,139.987366 
	C874.957336,139.980164 882.972595,139.971848 891.339355,139.970123 
	C892.478333,139.333771 893.265686,138.690826 894.357361,137.902740 
	C896.232056,136.546844 897.919495,135.459976 899.353088,134.104630 
	C907.056458,126.821793 914.794006,119.570244 922.315430,112.102051 
	C925.791138,108.650856 928.802368,104.731880 932.317383,100.995911 
	C933.077820,100.647751 933.545105,100.328712 934.416016,100.009590 
	C936.941895,99.974419 939.259888,99.665535 938.820984,103.290604 
	C932.574768,109.501122 926.427063,115.343536 920.421814,121.328972 
	C912.557068,129.167877 904.804871,137.119751 897.004211,145.023010 
	C893.841675,145.197754 890.679077,145.372482 887.257019,145.276260 
	C887.511169,144.376495 888.024841,143.747681 889.199646,142.309570 
	C876.182312,142.045303 863.882446,141.795609 851.290894,141.243881 
z"/>
<path fill="#012D8D" opacity="1.000000" stroke="none" 
	d="
M832.000000,136.998169 
	C832.333923,138.052612 832.667908,139.107071 833.010498,140.490417 
	C830.620544,140.845825 828.221985,140.872345 825.375427,140.819794 
	C825.387756,139.536194 825.848022,138.331650 827.070801,137.063477 
	C829.222229,136.999298 830.611084,136.998734 832.000000,136.998169 
z"/>
<path fill="#022D9A" opacity="1.000000" stroke="none" 
	d="
M808.669922,145.150650 
	C807.991821,146.143188 806.878052,147.070221 805.382141,147.995544 
	C804.364746,147.238937 803.729553,146.484024 803.048889,145.435425 
	C804.747070,145.122864 806.490723,145.104004 808.669922,145.150650 
z"/>
<path fill="#00359C" opacity="1.000000" stroke="none" 
	d="
M451.789154,148.715637 
	C451.473785,149.804291 450.654541,150.579041 450.460999,151.487152 
	C448.949371,158.579849 446.287506,159.400375 440.174957,154.692093 
	C438.094879,153.089890 435.817291,151.744141 433.613525,149.851028 
	C435.382141,149.279953 437.169434,149.162415 438.951752,148.993149 
	C442.302917,148.674927 445.651184,148.326843 449.000671,147.991165 
	C449.858826,148.148621 450.716980,148.306061 451.789154,148.715637 
z"/>
<path fill="#072C99" opacity="1.000000" stroke="none" 
	d="
M448.698853,147.704468 
	C445.651184,148.326843 442.302917,148.674927 438.951752,148.993149 
	C437.169434,149.162415 435.382141,149.279953 433.298035,149.710114 
	C432.345612,149.228073 431.692291,148.456772 430.997070,147.372253 
	C436.769104,147.178619 442.583069,147.298187 448.698853,147.704468 
z"/>
<path fill="#072C99" opacity="1.000000" stroke="none" 
	d="
M425.224121,143.712585 
	C425.019775,143.055237 425.001160,141.289017 425.071472,141.285461 
	C427.324097,141.171814 429.585327,141.228607 431.925201,141.623581 
	C429.820557,142.466766 427.635925,142.946121 425.224121,143.712585 
z"/>
<path fill="#022590" opacity="1.000000" stroke="none" 
	d="
M944.314880,448.939026 
	C948.743469,443.665680 953.528992,438.369812 958.660400,433.042236 
	C954.228149,438.312531 949.449951,443.614532 944.314880,448.939026 
z"/>
<path fill="#011B6C" opacity="1.000000" stroke="none" 
	d="
M1004.997559,388.998169 
	C1006.206238,389.723816 1007.417358,390.451294 1008.628540,391.178772 
	C1008.094604,391.714600 1007.560608,392.250488 1007.026672,392.786346 
	C1006.351746,391.778839 1005.676819,390.771332 1004.998535,389.379303 
	C1004.995117,388.994781 1005.000000,389.000000 1004.997559,388.998169 
z"/>
<path fill="#022590" opacity="1.000000" stroke="none" 
	d="
M964.341125,427.966461 
	C964.777222,427.024597 965.552002,426.048004 966.666626,425.039612 
	C966.230896,425.982422 965.455322,426.957062 964.341125,427.966461 
z"/>
<path fill="#02389F" opacity="1.000000" stroke="none" 
	d="
M631.086731,505.033203 
	C630.906006,504.189423 630.384827,502.706329 630.594055,502.594940 
	C636.542969,499.427002 637.014771,493.812958 637.296631,488.075775 
	C637.308594,487.833466 637.713135,487.610382 638.336182,486.958038 
	C640.131042,488.221771 641.993774,489.533295 643.856567,490.844818 
	C644.282593,490.667908 644.708618,490.490997 645.134705,490.314087 
	C644.648560,488.295258 644.718140,485.770630 643.492981,484.424561 
	C642.583923,483.425964 639.996094,483.906494 638.151733,483.817017 
	C631.219971,483.480652 625.930908,488.015015 619.878235,490.250366 
	C618.857849,490.627197 618.707458,493.414886 618.195862,495.101105 
	C617.484741,497.444977 617.497925,500.938293 615.953125,501.869751 
	C613.914307,503.098999 610.638123,502.544281 607.963928,502.204834 
	C607.481934,502.143616 606.894165,499.346863 607.128784,497.942352 
	C607.635742,494.908234 608.644714,491.957977 609.610291,488.371429 
	C607.918274,488.738159 607.372498,488.765503 607.356262,488.872925 
	C605.892090,498.553101 595.920776,501.801025 591.718323,509.502014 
	C590.033020,512.590515 586.078186,511.359680 583.321838,506.871887 
	C586.599304,502.655701 589.990295,498.870270 593.658386,495.375732 
	C602.165527,487.271149 610.952820,479.456451 619.343506,471.235443 
	C625.293152,465.406158 630.832886,459.152344 636.420837,452.966309 
	C637.318787,451.972229 637.510864,450.340576 638.455139,448.996582 
	C640.584412,448.956787 642.287720,448.924011 644.457642,448.921173 
	C649.619141,448.956024 654.314026,448.960968 659.390686,448.975647 
	C661.156616,448.985443 662.540894,448.985443 664.171753,448.985443 
	C663.597107,448.170074 663.276550,447.715240 663.241821,447.665955 
	C666.433533,447.327789 669.737427,446.977722 673.377808,446.441315 
	C674.478760,446.222778 675.243286,446.190613 676.135986,446.360779 
	C676.499512,446.792206 676.775085,446.951355 676.977783,447.349121 
	C677.996460,448.103485 679.123047,448.918732 680.261597,448.935852 
	C688.174377,449.055176 696.089661,449.008759 703.904541,449.406647 
	C701.798889,450.198517 699.808594,450.811371 697.783875,450.962128 
	C690.294556,451.519958 682.793945,451.925171 675.297546,452.387756 
	C675.164246,453.040741 675.031006,453.693695 674.897705,454.346649 
	C676.736389,454.897705 678.558533,455.513947 680.417847,455.983398 
	C682.761719,456.575195 685.442688,456.531860 687.415710,457.707062 
	C689.658020,459.042572 691.278931,461.421173 693.169922,463.346375 
	C691.635864,463.917297 690.135864,464.841156 688.561279,464.993469 
	C683.672058,465.466492 678.754578,465.646606 673.108948,466.008728 
	C671.882690,466.692383 671.395569,467.307617 670.908386,467.922852 
	C670.908386,467.922852 670.468079,467.975555 669.902832,468.031738 
	C667.930176,469.746613 666.522888,471.405334 665.115540,473.064056 
	C665.115540,473.064056 665.070312,473.069305 664.739746,473.102203 
	C663.964050,473.770874 663.518860,474.406616 663.073730,475.042358 
	C661.279236,476.993011 659.484741,478.943726 656.985718,480.964905 
	C650.215515,482.235565 646.860107,485.953827 646.055176,492.041809 
	C644.601868,493.660583 643.148560,495.279388 641.051270,497.013672 
	C639.960754,497.767731 639.514160,498.406250 639.067627,499.044800 
	C637.914368,500.279327 636.761108,501.513885 635.000000,502.927490 
	C633.953369,503.754517 633.514526,504.402527 633.075623,505.050537 
	C633.075623,505.050507 633.017944,505.025452 632.706665,505.031860 
	C631.959229,505.036591 631.522949,505.034912 631.086731,505.033203 
z"/>
<path fill="#24B6F9" opacity="1.000000" stroke="none" 
	d="
M657.885498,486.582336 
	C657.415161,490.443359 655.527588,492.304077 651.475952,492.166443 
	C650.595520,492.166687 650.098572,492.092194 649.291748,491.645081 
	C650.518860,487.605713 652.110535,483.997528 656.931580,483.846497 
	C657.252441,484.644928 657.573303,485.443359 657.885498,486.582336 
z"/>
<path fill="#0461C6" opacity="1.000000" stroke="none" 
	d="
M656.944458,483.498688 
	C652.110535,483.997528 650.518860,487.605713 648.914795,491.637115 
	C648.150085,492.008392 647.452332,492.014984 646.404907,492.031677 
	C646.860107,485.953827 650.215515,482.235565 656.658813,481.044891 
	C657.010132,481.753235 656.983765,482.452057 656.944458,483.498688 
z"/>
<path fill="#0461C6" opacity="1.000000" stroke="none" 
	d="
M601.275818,534.933960 
	C603.369202,532.963379 605.853577,531.036682 608.690063,529.088501 
	C607.751953,532.494995 605.752258,535.037781 601.275818,534.933960 
z"/>
<path fill="#02389F" opacity="1.000000" stroke="none" 
	d="
M623.877808,514.741455 
	C623.359924,513.313599 623.148315,511.798798 622.936646,510.283997 
	C623.601135,510.343109 624.265564,510.402191 624.981567,511.096710 
	C625.045837,512.168945 625.058533,512.605713 625.071228,513.042542 
	C624.775513,513.579834 624.479797,514.117188 623.877808,514.741455 
z"/>
<path fill="#0461C6" opacity="1.000000" stroke="none" 
	d="
M573.333984,536.976929 
	C573.877380,536.171692 574.742432,535.344482 575.802490,534.743408 
	C575.216919,535.631287 574.436279,536.293091 573.333984,536.976929 
z"/>
<path fill="#0461C6" opacity="1.000000" stroke="none" 
	d="
M631.058838,505.394104 
	C631.522949,505.034912 631.959229,505.036591 632.723694,505.058289 
	C632.533752,505.958069 632.015625,506.837830 631.187744,507.818176 
	C630.928955,507.197510 630.979980,506.476257 631.058838,505.394104 
z"/>
<path fill="#0461C6" opacity="1.000000" stroke="none" 
	d="
M617.387939,521.005676 
	C617.796814,520.347168 618.522278,519.648804 619.594849,518.928101 
	C619.196106,519.592407 618.450317,520.279114 617.387939,521.005676 
z"/>
<path fill="#0461C6" opacity="1.000000" stroke="none" 
	d="
M633.384521,504.995636 
	C633.514526,504.402527 633.953369,503.754517 634.717773,503.084381 
	C634.593323,503.688385 634.143372,504.314545 633.384521,504.995636 
z"/>
<path fill="#0461C6" opacity="1.000000" stroke="none" 
	d="
M625.381104,512.988464 
	C625.058533,512.605713 625.045837,512.168945 625.031860,511.406128 
	C625.473877,511.058197 625.917175,511.036255 626.693726,511.028473 
	C626.581604,511.673248 626.136230,512.303833 625.381104,512.988464 
z"/>
<path fill="#0461C6" opacity="1.000000" stroke="none" 
	d="
M609.377197,528.987915 
	C609.504700,528.393616 609.942871,527.745789 610.704224,527.070923 
	C610.580933,527.674011 610.134399,528.304199 609.377197,528.987915 
z"/>
<path fill="#0461C6" opacity="1.000000" stroke="none" 
	d="
M639.376099,498.995972 
	C639.514160,498.406250 639.960754,497.767731 640.733582,497.112488 
	C640.601440,497.712891 640.143005,498.330017 639.376099,498.995972 
z"/>
<path fill="#24B6F9" opacity="1.000000" stroke="none" 
	d="
M720.250366,503.058899 
	C718.017395,503.356628 715.778442,503.871094 714.129700,503.108887 
	C713.348389,502.747711 713.012207,499.521454 713.727234,498.373749 
	C714.408691,497.279877 716.890930,496.487762 718.279358,496.821136 
	C721.392944,497.568695 721.528992,500.095001 720.250366,503.058899 
z"/>
<path fill="#023497" opacity="1.000000" stroke="none" 
	d="
M693.532593,463.350952 
	C691.278931,461.421173 689.658020,459.042572 687.415710,457.707062 
	C685.442688,456.531860 682.761719,456.575195 680.417847,455.983398 
	C678.558533,455.513947 676.736389,454.897705 674.897705,454.346649 
	C675.031006,453.693695 675.164246,453.040741 675.297546,452.387756 
	C682.793945,451.925171 690.294556,451.519958 697.783875,450.962128 
	C699.808594,450.811371 701.798889,450.198517 704.236450,449.402893 
	C705.110352,449.012421 705.552795,449.016846 706.041260,449.373169 
	C707.163330,450.388733 708.214417,451.107269 709.355774,451.628021 
	C709.465149,451.677917 710.136719,450.495728 710.547363,449.885529 
	C709.704773,449.591156 708.862122,449.296783 708.015747,448.631592 
	C708.004700,447.519165 707.997437,446.777588 708.398865,445.959991 
	C710.350220,446.279633 711.872803,446.801453 713.438843,447.049652 
	C721.359741,448.304779 729.106140,447.603668 736.697876,445.128296 
	C735.527100,446.222778 734.295898,446.973785 733.064697,447.724762 
	C733.415161,448.234406 733.765564,448.744049 734.115967,449.253693 
	C743.066467,448.840302 752.016907,448.426910 760.976501,448.395630 
	C762.623047,451.277985 764.164429,453.848358 765.923706,456.259674 
	C767.852905,458.903778 769.983154,461.401215 771.555786,463.977448 
	C756.517822,463.911896 741.934570,464.248840 726.869629,463.790070 
	C729.666931,456.791138 728.601929,453.028076 724.294495,451.293671 
	C719.475159,449.353149 714.974121,451.629364 712.742798,457.252716 
	C711.941162,459.831635 711.954407,462.571320 708.296204,462.248077 
	C707.917175,462.214569 707.429321,463.412720 706.991943,464.039398 
	C705.530762,464.286987 704.069519,464.534546 702.277100,464.968506 
	C699.262329,464.555115 696.578857,463.955322 693.532593,463.350952 
z"/>
<path fill="#0461C6" opacity="1.000000" stroke="none" 
	d="
M665.447205,473.010590 
	C666.522888,471.405334 667.930176,469.746613 669.684082,468.068054 
	C668.613403,469.684509 667.196106,471.320831 665.447205,473.010590 
z"/>
<path fill="#0461C6" opacity="1.000000" stroke="none" 
	d="
M671.236755,467.908539 
	C671.395569,467.307617 671.882690,466.692383 672.689453,466.045563 
	C672.527771,466.640747 672.046448,467.267487 671.236755,467.908539 
z"/>
<path fill="#0461C6" opacity="1.000000" stroke="none" 
	d="
M663.380249,474.993683 
	C663.518860,474.406616 663.964050,473.770874 664.735901,473.124695 
	C664.604065,473.724487 664.145447,474.334747 663.380249,474.993683 
z"/>
<path fill="#0241B4" opacity="1.000000" stroke="none" 
	d="
M713.019409,456.995667 
	C714.974121,451.629364 719.475159,449.353149 724.294495,451.293671 
	C728.601929,453.028076 729.666931,456.791138 726.869629,463.790070 
	C741.934570,464.248840 756.517822,463.911896 771.764404,464.089539 
	C772.711853,464.373779 772.898132,464.622528 773.001831,464.933136 
	C773.002747,465.111938 773.003601,465.290710 773.001038,465.737671 
	C761.305115,466.001404 749.612732,465.996918 737.449585,466.012207 
	C733.291870,466.032776 729.604919,466.033539 725.332153,465.727905 
	C724.470215,464.600281 724.194153,463.779022 723.985107,462.611755 
	C725.845642,459.908081 726.326538,457.146851 724.145569,455.177521 
	C722.999207,454.142426 720.088257,453.796478 718.704590,454.525543 
	C714.487427,456.747620 716.715759,459.997986 717.976135,463.224182 
	C717.196106,464.058105 716.566467,464.614594 715.936890,465.171082 
	C715.936890,465.171112 715.469116,465.118286 715.233948,464.673279 
	C714.338989,461.817413 713.679199,459.406525 713.019409,456.995667 
z"/>
<path fill="#02389F" opacity="1.000000" stroke="none" 
	d="
M712.742798,457.252716 
	C713.679199,459.406525 714.338989,461.817413 715.002563,464.630341 
	C713.308289,465.052368 711.610291,465.072327 709.492065,465.126343 
	C708.501160,464.948456 707.930603,464.736450 707.175964,464.281921 
	C707.429321,463.412720 707.917175,462.214569 708.296204,462.248077 
	C711.954407,462.571320 711.941162,459.831635 712.742798,457.252716 
z"/>
<path fill="#065DD2" opacity="1.000000" stroke="none" 
	d="
M716.057495,465.528931 
	C716.566467,464.614594 717.196106,464.058105 718.304077,463.195587 
	C720.494263,462.912292 722.206238,462.935028 723.918091,462.957764 
	C724.194153,463.779022 724.470215,464.600281 724.871399,465.725525 
	C722.057068,465.981964 719.117554,465.934387 716.057495,465.528931 
z"/>
<path fill="#032A82" opacity="1.000000" stroke="none" 
	d="
M796.405396,561.033447 
	C796.040710,560.206726 796.027405,559.463196 796.026611,558.346863 
	C797.738037,557.981140 799.436951,557.988159 801.560791,557.995117 
	C803.434509,559.103760 804.883179,560.212341 807.269836,562.038696 
	C805.729004,565.261169 804.033997,568.806030 802.060364,572.933655 
	C799.991638,568.324402 798.374207,564.720520 796.405396,561.033447 
z"/>
<path fill="#0B76EC" opacity="1.000000" stroke="none" 
	d="
M800.615417,512.665039 
	C796.493958,512.894470 794.948730,510.207886 795.573303,507.445892 
	C795.960632,505.733215 798.743530,503.952850 800.751526,503.480865 
	C801.712524,503.255005 804.536865,506.091064 804.435059,507.375305 
	C804.290466,509.200134 802.226257,510.872833 800.615417,512.665039 
z"/>
<path fill="#032A82" opacity="1.000000" stroke="none" 
	d="
M817.729492,503.430237 
	C819.085144,507.174377 814.795715,513.701782 811.199463,513.850891 
	C810.621643,513.874817 809.613892,513.530579 809.516968,513.166809 
	C809.282532,512.286926 809.119812,511.082977 809.544495,510.384308 
	C811.553101,507.079895 813.768555,503.901245 816.109741,500.377594 
	C816.582214,501.260864 817.073853,502.179932 817.729492,503.430237 
z"/>
<path fill="#032A82" opacity="1.000000" stroke="none" 
	d="
M789.056885,478.399689 
	C795.021729,478.343109 801.045837,478.676422 807.538208,479.018280 
	C807.363708,479.663879 806.737793,480.832001 806.075562,480.853058 
	C801.250732,481.006348 796.414246,481.031433 791.592773,480.828491 
	C790.740112,480.792572 789.940369,479.501221 789.056885,478.399689 
z"/>
<path fill="#003CB2" opacity="1.000000" stroke="none" 
	d="
M775.283875,470.345581 
	C775.113220,469.293304 775.211060,468.502686 775.312500,467.682617 
	C777.552246,468.313843 779.524597,468.869690 781.747803,469.675842 
	C779.849854,470.153137 777.701050,470.380188 775.283875,470.345581 
z"/>
<path fill="#042F89" opacity="1.000000" stroke="none" 
	d="
M858.963989,465.172668 
	C840.291931,465.107758 821.619934,465.042847 802.400085,464.591248 
	C798.984192,461.974060 786.597351,460.928009 783.992798,462.824066 
	C783.432373,463.232056 783.317627,464.252197 782.997559,464.990326 
	C782.203735,464.797333 781.409912,464.604340 780.312988,463.891663 
	C778.457520,461.226776 777.011841,458.993134 775.325134,456.959442 
	C773.327759,454.551086 771.122498,452.315216 769.373535,450.043884 
	C770.927917,450.606110 772.071045,451.346497 773.314087,451.603485 
	C775.435852,452.042053 777.620178,452.177216 779.778259,452.439789 
	C779.852661,450.333405 779.927124,448.226990 780.405518,446.090210 
	C786.497253,445.888580 792.185120,445.717346 797.811951,445.873779 
	C797.158264,447.021606 796.565674,447.841797 795.727234,449.002197 
	C801.847046,449.002197 807.513062,449.123138 813.166626,448.911072 
	C814.464844,448.862396 815.720093,447.669037 817.418457,447.011108 
	C823.452271,447.339020 829.065369,447.883545 834.671387,447.817932 
	C836.059143,447.801727 837.423828,445.805908 838.815247,444.778961 
	C838.831421,444.837830 838.713013,444.807922 839.084961,444.816559 
	C840.194336,443.487366 841.413086,442.208618 841.585938,440.801422 
	C842.337646,434.681152 835.085693,436.048889 832.311340,431.610962 
	C839.955627,431.610962 846.664001,431.610962 853.579285,431.610962 
	C853.377930,432.879059 853.127136,433.994446 853.033630,435.122833 
	C852.475952,441.853882 853.429993,443.761139 859.907715,445.736877 
	C864.106812,447.017670 868.613831,447.288696 872.812744,448.331329 
	C869.884338,449.788025 867.126221,450.925598 864.368042,452.063141 
	C864.186707,452.455536 864.005371,452.847931 863.824036,453.240356 
	C865.466187,453.834717 867.074280,454.562531 868.758240,454.993408 
	C871.554565,455.708893 873.295715,456.610291 870.393921,459.408722 
	C869.906677,459.878693 869.724915,460.665405 869.049805,462.003998 
	C872.003662,462.003998 874.338257,461.669525 876.504456,462.128845 
	C877.811951,462.406128 878.857971,463.916473 879.553101,464.912079 
	C872.377869,465.025208 865.670898,465.098938 858.963989,465.172668 
z"/>
<path fill="#053FC9" opacity="1.000000" stroke="none" 
	d="
M858.973755,465.552185 
	C865.670898,465.098938 872.377869,465.025208 880.018433,464.952148 
	C893.623474,464.937317 906.294922,464.921753 919.136230,465.143250 
	C919.861633,464.903870 920.417236,464.427399 921.130615,463.655975 
	C923.140503,461.866882 924.992493,460.372772 926.844482,458.878601 
	C927.553040,459.668701 928.261658,460.458771 928.977417,461.611572 
	C928.962524,462.390594 928.940430,462.806854 928.895752,463.532349 
	C927.693176,464.363281 926.092163,464.563416 925.405518,465.462097 
	C922.366272,469.439972 918.186768,469.234528 913.506470,469.064270 
	C895.357361,469.053436 877.644470,469.030334 859.462158,469.003632 
	C858.989563,467.977234 858.986511,466.954468 858.973755,465.552185 
z"/>
<path fill="#021F84" opacity="1.000000" stroke="none" 
	d="
M769.007324,450.004578 
	C771.122498,452.315216 773.327759,454.551086 775.325134,456.959442 
	C777.011841,458.993134 778.457520,461.226776 780.032471,463.757935 
	C777.975037,464.416168 775.894836,464.688416 773.408264,464.946899 
	C772.898132,464.622528 772.711853,464.373779 772.234436,464.074707 
	C769.983154,461.401215 767.852905,458.903778 765.923706,456.259674 
	C764.164429,453.848358 762.623047,451.277985 761.387695,448.389343 
	C764.195312,448.331482 766.600830,448.662048 769.004028,449.245636 
	C769.003601,449.667297 769.005493,449.835938 769.007324,450.004578 
z"/>
<path fill="#012E8B" opacity="1.000000" stroke="none" 
	d="
M800.487671,470.919098 
	C814.088623,471.063446 827.241943,471.227509 840.702454,471.671875 
	C839.795776,472.300110 838.583435,472.944611 837.367859,472.950684 
	C823.142090,473.021881 808.915710,473.025024 794.690063,472.945984 
	C793.520386,472.939514 792.354736,472.202057 791.087402,471.413513 
	C794.005005,470.994781 797.022583,470.966797 800.487671,470.919098 
z"/>
<path fill="#023497" opacity="1.000000" stroke="none" 
	d="
M783.465820,465.007935 
	C783.317627,464.252197 783.432373,463.232056 783.992798,462.824066 
	C786.597351,460.928009 798.984192,461.974060 801.932617,464.572021 
	C795.986633,464.968231 789.960388,464.996887 783.465820,465.007935 
z"/>
<path fill="#003CB2" opacity="1.000000" stroke="none" 
	d="
M791.008850,470.614868 
	C790.487244,470.779907 789.916809,470.663818 789.166504,470.309448 
	C789.644470,470.158691 790.302246,470.246185 791.008850,470.614868 
z"/>
<path fill="#013290" opacity="1.000000" stroke="none" 
	d="
M699.259033,549.912109 
	C698.533997,549.567078 698.020203,549.113586 697.506409,548.660034 
	C698.223328,548.626953 698.940186,548.593872 699.850769,548.809998 
	C699.924438,549.362427 699.733093,549.610596 699.259033,549.912109 
z"/>
<path fill="#01359A" opacity="1.000000" stroke="none" 
	d="
M500.012451,509.049011 
	C504.363251,509.040039 508.714020,509.031067 513.615723,509.394257 
	C516.444519,509.845093 518.722412,509.923767 521.000244,510.002441 
	C532.634338,509.675079 544.267273,509.278320 555.903503,509.071075 
	C558.668518,509.021790 561.444885,509.606812 564.630188,509.969971 
	C563.788025,511.803558 562.531677,513.567322 560.760132,515.652405 
	C559.491028,516.644470 558.737183,517.315186 557.983276,517.985901 
	C557.670776,518.100464 557.414368,518.290588 556.759705,518.830444 
	C555.860779,520.064087 555.416138,521.023682 554.971497,521.983215 
	C551.158264,525.546082 547.329956,529.092957 543.536560,532.676819 
	C540.435242,535.606873 536.922852,538.248596 534.435608,541.636963 
	C532.300720,544.545166 529.932007,545.041931 526.785889,545.034546 
	C500.832520,544.973267 474.878967,544.990295 448.456390,544.951416 
	C450.270447,543.158875 452.553619,541.398438 455.266998,539.306335 
	C454.321777,538.759705 452.814911,537.888367 452.228668,537.549377 
	C450.612610,532.185364 449.426605,527.510010 447.708344,523.039124 
	C447.250244,521.847168 445.265778,520.863647 443.804596,520.502502 
	C438.181854,519.112793 433.343842,516.673645 430.052521,511.323181 
	C435.352692,510.656982 440.706757,510.330841 446.831055,510.294891 
	C464.265503,510.717041 480.929749,510.876190 497.594147,510.898071 
	C498.399445,510.899139 499.206299,509.692993 500.012451,509.049011 
M501.382843,519.108887 
	C499.671875,520.277466 497.102234,521.122742 496.504364,522.710510 
	C495.964355,524.144470 497.158112,527.590271 498.341003,528.006409 
	C500.279358,528.688354 503.246246,528.297180 505.032135,527.172241 
	C508.620911,524.911682 507.389587,521.748535 501.382843,519.108887 
z"/>
<path fill="#01359A" opacity="1.000000" stroke="none" 
	d="
M432.914307,544.518188 
	C430.965912,544.919922 428.919922,544.963501 426.435547,544.994019 
	C427.913208,541.740540 430.311798,542.508789 432.914307,544.518188 
z"/>
<path fill="#053ACF" opacity="1.000000" stroke="none" 
	d="
M643.991089,448.891235 
	C642.287720,448.924011 640.584412,448.956787 638.055542,448.986511 
	C635.848694,449.342102 634.035583,449.260406 633.152039,450.126709 
	C623.238770,459.846954 613.509521,469.754364 603.645386,479.525238 
	C597.303406,485.807251 590.610596,491.748413 584.483398,498.228516 
	C581.180786,501.721436 578.738037,506.027313 575.907288,509.966431 
	C574.559082,510.326202 573.210815,510.686005 571.518677,511.071716 
	C570.532349,510.846588 569.890015,510.595581 569.045776,510.052704 
	C569.840271,508.397308 570.836670,507.033783 571.894409,505.085266 
	C571.962219,504.330048 571.968689,504.159821 572.143677,503.690735 
	C578.999939,496.912903 585.796387,490.541565 592.350708,483.930206 
	C602.576355,473.615417 612.647278,463.146912 622.761597,452.722046 
	C624.897644,450.520447 626.938232,448.226227 629.344482,445.809631 
	C632.093872,444.756805 634.521667,443.869049 637.292725,442.981079 
	C638.092346,442.948730 638.548767,442.916565 639.422119,442.909393 
	C641.233459,442.965424 642.627747,442.996429 643.982056,443.367950 
	C643.958313,445.436035 643.974731,447.163635 643.991089,448.891235 
z"/>
<path fill="#0639BE" opacity="1.000000" stroke="none" 
	d="
M558.307617,517.962891 
	C558.737183,517.315186 559.491028,516.644470 560.589661,515.947632 
	C560.166931,516.594299 559.399475,517.267090 558.307617,517.962891 
z"/>
<path fill="#0639BE" opacity="1.000000" stroke="none" 
	d="
M555.265625,521.847534 
	C555.416138,521.023682 555.860779,520.064087 556.644897,519.043518 
	C556.509460,519.892273 556.034546,520.802124 555.265625,521.847534 
z"/>
<path fill="#01259D" opacity="1.000000" stroke="none" 
	d="
M576.279907,509.973053 
	C578.738037,506.027313 581.180786,501.721436 584.483398,498.228516 
	C590.610596,491.748413 597.303406,485.807251 603.645386,479.525238 
	C613.509521,469.754364 623.238770,459.846954 633.152039,450.126709 
	C634.035583,449.260406 635.848694,449.342102 637.629639,448.993530 
	C637.510864,450.340576 637.318787,451.972229 636.420837,452.966309 
	C630.832886,459.152344 625.293152,465.406158 619.343506,471.235443 
	C610.952820,479.456451 602.165527,487.271149 593.658386,495.375732 
	C589.990295,498.870270 586.599304,502.655701 583.043091,506.653320 
	C580.886719,507.991638 578.769653,508.985657 576.279907,509.973053 
z"/>
<path fill="#1C428F" opacity="1.000000" stroke="none" 
	d="
M489.136719,557.599060 
	C493.799591,560.558167 494.400574,561.828979 494.371460,568.407593 
	C488.168335,568.407593 481.958374,568.407593 475.746277,568.407593 
	C475.671448,560.812378 480.193604,557.050659 489.136719,557.599060 
z"/>
<path fill="#013193" opacity="1.000000" stroke="none" 
	d="
M594.210205,562.702637 
	C596.936646,561.368103 599.502625,560.005737 602.203247,559.641296 
	C604.069397,559.389465 606.121521,560.516479 608.438782,561.117676 
	C609.721924,565.418945 608.252319,567.474243 603.733948,567.077698 
	C601.622192,566.892395 599.474976,566.936035 597.354858,567.061584 
	C594.340759,567.240112 593.638672,565.691406 594.210205,562.702637 
z"/>
<path fill="#02257D" opacity="1.000000" stroke="none" 
	d="
M951.002625,381.994354 
	C959.098450,385.068420 960.303467,386.950684 958.890930,395.076477 
	C958.662292,396.391357 957.672607,398.467926 956.771790,398.618347 
	C952.700928,399.298279 953.140442,402.094269 952.938171,404.982880 
	C952.675842,408.731293 951.967468,412.448395 951.455994,416.179657 
	C951.367493,416.825684 950.996155,417.683990 951.273621,418.091431 
	C955.360535,424.093750 951.469788,425.123108 946.178284,425.367462 
	C947.413452,431.198914 942.865662,432.582062 938.824646,434.366364 
	C938.928101,434.927216 939.031555,435.488098 939.135010,436.048950 
	C941.153015,436.366241 943.171082,436.683502 945.604065,437.005554 
	C940.607971,442.343018 935.196899,447.675720 929.410889,452.675659 
	C927.149292,449.660675 925.526001,446.733124 923.311890,444.355865 
	C920.266418,441.085846 916.773926,438.232117 913.288147,435.030334 
	C921.755310,426.085358 927.810303,416.916473 927.995850,404.526978 
	C927.995178,396.668762 927.995178,389.278717 927.995178,381.994354 
	C936.014587,381.994354 943.508606,381.994354 951.002625,381.994354 
z"/>
<path fill="#02277E" opacity="1.000000" stroke="none" 
	d="
M918.966309,464.906189 
	C906.294922,464.921753 893.623474,464.937317 880.486755,464.912781 
	C878.857971,463.916473 877.811951,462.406128 876.504456,462.128845 
	C874.338257,461.669525 872.003662,462.003998 869.049805,462.003998 
	C869.724915,460.665405 869.906677,459.878693 870.393921,459.408722 
	C873.295715,456.610291 871.554565,455.708893 868.758240,454.993408 
	C867.074280,454.562531 865.466187,453.834717 863.824036,453.240356 
	C864.005371,452.847931 864.186707,452.455536 864.368042,452.063141 
	C867.126221,450.925598 869.884338,449.788025 873.281860,448.326721 
	C879.940918,447.505768 885.957581,446.961792 891.983582,446.556335 
	C893.018982,446.486664 894.124939,446.919006 895.142029,447.278717 
	C896.158813,447.638306 897.104919,448.197723 898.081909,448.669525 
	C898.232971,447.477905 898.354126,446.281433 898.546204,445.096497 
	C898.664062,444.369324 898.890564,443.659760 899.442627,442.951447 
	C901.628235,442.243439 903.389709,441.194672 905.266357,440.912872 
	C906.798218,440.682800 909.002869,440.815979 909.973572,441.755096 
	C912.636414,444.331390 914.352905,448.064819 917.294983,450.124817 
	C920.441284,452.327728 922.919922,456.906860 928.046753,455.037506 
	C927.692505,456.099030 927.338257,457.160583 926.914246,458.550354 
	C924.992493,460.372772 923.140503,461.866882 920.839966,463.684021 
	C919.916443,464.306763 919.441345,464.606476 918.966309,464.906189 
z"/>
<path fill="#031E6F" opacity="1.000000" stroke="none" 
	d="
M928.163940,454.792053 
	C922.919922,456.906860 920.441284,452.327728 917.294983,450.124817 
	C914.352905,448.064819 912.636414,444.331390 909.973572,441.755096 
	C909.002869,440.815979 906.798218,440.682800 905.266357,440.912872 
	C903.389709,441.194672 901.628235,442.243439 899.429443,442.731262 
	C899.041870,442.501892 899.015076,442.060486 899.200073,441.769989 
	C914.624695,433.930542 923.583862,421.732086 926.667053,404.982544 
	C927.287537,404.983948 927.642029,404.989532 927.996582,404.995117 
	C927.810303,416.916473 921.755310,426.085358 913.288147,435.030334 
	C916.773926,438.232117 920.266418,441.085846 923.311890,444.355865 
	C925.526001,446.733124 927.149292,449.660675 929.070251,452.744141 
	C928.830017,453.612488 928.555542,454.079559 928.163940,454.792053 
z"/>
<path fill="#021F84" opacity="1.000000" stroke="none" 
	d="
M968.284912,415.826538 
	C968.856323,411.236450 971.963135,408.572876 976.616211,406.995117 
	C974.183167,409.890167 971.386597,412.791718 968.284912,415.826538 
z"/>
<path fill="#021F84" opacity="1.000000" stroke="none" 
	d="
M957.275879,426.814026 
	C958.045532,425.625763 959.120850,424.313354 960.563171,422.989380 
	C959.813965,424.215179 958.697754,425.452515 957.275879,426.814026 
z"/>
<path fill="#021F84" opacity="1.000000" stroke="none" 
	d="
M951.300476,432.905212 
	C951.432068,432.312500 951.883850,431.695709 952.644531,431.032349 
	C952.509216,431.617584 952.064941,432.249390 951.300476,432.905212 
z"/>
<path fill="#01177C" opacity="1.000000" stroke="none" 
	d="
M864.983032,101.936569 
	C863.271851,101.972954 861.560730,102.009331 859.255981,101.795418 
	C858.102600,101.364357 857.542786,101.183586 856.988037,100.627502 
	C856.995483,99.501587 856.997742,98.750999 857.000000,98.000412 
	C857.000000,98.000412 857.003418,97.502449 857.371277,97.207703 
	C861.615723,95.941673 865.482788,94.930168 869.372986,94.016640 
	C871.226074,93.581451 873.122925,93.332664 875.000000,92.999451 
	C875.750000,92.999847 876.500000,93.000244 877.625000,93.000031 
	C882.166565,93.223793 886.334961,93.419632 890.498413,93.691360 
	C891.627625,93.765053 892.741943,94.067978 893.936279,94.956909 
	C894.010864,96.084564 894.012268,96.521011 894.013611,96.957436 
	C893.485291,97.527443 892.956970,98.097466 891.757446,98.828987 
	C883.856995,99.327797 876.620972,99.568962 869.404114,100.080566 
	C867.894104,100.187607 866.454895,101.291862 864.983032,101.936569 
z"/>
<path fill="#01257D" opacity="1.000000" stroke="none" 
	d="
M874.709473,92.714661 
	C873.122925,93.332664 871.226074,93.581451 869.372986,94.016640 
	C865.482788,94.930168 861.615723,95.941673 857.372009,96.958710 
	C859.101135,93.275665 861.458557,90.365753 866.571960,91.826332 
	C869.043457,92.532265 871.794434,92.259499 874.709473,92.714661 
z"/>
<path fill="#012E8A" opacity="1.000000" stroke="none" 
	d="
M856.982910,101.002823 
	C857.542786,101.183586 858.102600,101.364357 858.828125,101.796692 
	C857.486450,102.209450 855.979248,102.370621 854.234253,102.276917 
	C853.997375,101.850449 853.998230,101.678864 854.374390,101.253311 
	C855.494080,101.000496 856.238464,101.001656 856.982910,101.002823 
z"/>
<path fill="#01257D" opacity="1.000000" stroke="none" 
	d="
M856.988037,100.627495 
	C856.238464,101.001656 855.494080,101.000496 854.377258,100.995941 
	C854.777527,100.019867 855.550171,99.047180 856.661377,98.037460 
	C856.997742,98.750999 856.995483,99.501587 856.988037,100.627495 
z"/>
<path fill="#0430AF" opacity="1.000000" stroke="none" 
	d="
M904.631775,86.005455 
	C903.537964,87.617722 902.103455,89.273300 900.322144,90.957962 
	C901.413940,89.340958 902.852478,87.694862 904.631775,86.005455 
z"/>
<path fill="#012E8A" opacity="1.000000" stroke="none" 
	d="
M894.353088,96.920425 
	C894.012268,96.521011 894.010864,96.084564 894.016113,95.320190 
	C894.530701,94.735649 895.038635,94.479042 895.824158,94.149231 
	C895.631958,95.011818 895.162231,95.947609 894.353088,96.920425 
z"/>
<path fill="#0430AF" opacity="1.000000" stroke="none" 
	d="
M898.661987,92.013176 
	C898.535095,92.615509 898.088684,93.264427 897.321167,93.956726 
	C897.447510,93.353325 897.895020,92.706543 898.661987,92.013176 
z"/>
<path fill="#011B6D" opacity="1.000000" stroke="none" 
	d="
M1041.000244,68.618706 
	C1036.202759,69.467163 1031.403687,69.929886 1025.832764,70.684601 
	C1014.710449,70.641914 1004.360107,70.307243 994.009766,69.972565 
	C992.957581,68.628479 991.980042,67.214615 990.819397,65.971802 
	C990.293579,65.408730 988.971436,65.353661 988.748413,64.780876 
	C987.026611,60.358204 983.332642,58.766666 979.084229,57.376724 
	C995.492798,57.001141 1011.988770,57.080593 1028.482666,56.934677 
	C1031.881714,56.904606 1034.185791,58.032772 1035.944336,60.839302 
	C1037.528198,63.367115 1039.306396,65.773186 1041.000244,68.618706 
z"/>
<path fill="#032699" opacity="1.000000" stroke="none" 
	d="
M993.557373,69.984924 
	C1004.360107,70.307243 1014.710449,70.641914 1025.529175,70.967110 
	C1025.687744,72.064270 1025.377930,73.170914 1024.569336,74.629898 
	C1019.714600,74.992195 1015.358521,75.002144 1010.533569,75.002975 
	C999.593018,74.995613 989.058777,75.722015 978.664856,74.823898 
	C964.224731,73.576149 958.176208,83.882935 950.644348,93.112343 
	C948.548035,94.830673 946.759155,96.404739 944.655029,98.029495 
	C943.891602,98.719597 943.443359,99.359009 942.711365,100.135071 
	C941.279358,101.176521 940.131165,102.081329 938.982910,102.986145 
	C939.259888,99.665535 936.941895,99.974419 934.564087,99.694328 
	C935.872437,97.585190 937.436218,95.791229 939.000000,93.997261 
	C946.187378,87.117874 953.204895,80.045364 960.668213,73.479645 
	C962.838074,71.570793 966.282593,71.111000 969.944580,69.991119 
	C971.502136,69.987724 972.257812,69.980171 973.013550,69.972618 
	C974.399109,69.981255 975.784729,69.989891 977.759460,70.240128 
	C978.906128,70.637581 979.463562,70.793434 980.021057,70.949295 
	C984.382385,70.631950 988.743713,70.314621 993.557373,69.984924 
z"/>
<path fill="#011773" opacity="1.000000" stroke="none" 
	d="
M938.531616,93.997948 
	C937.436218,95.791229 935.872437,97.585190 934.160461,99.694420 
	C933.545105,100.328712 933.077820,100.647751 931.888611,100.986450 
	C928.423889,101.272240 925.660583,101.892174 922.942444,101.734413 
	C916.280640,101.347771 909.639587,100.602348 902.993164,99.597549 
	C902.977844,98.115776 902.959595,97.037750 902.941345,95.959717 
	C903.731934,95.360077 904.522461,94.760437 905.964417,94.027542 
	C908.075562,93.588051 909.535339,93.281815 911.402527,92.983414 
	C914.202209,93.321640 916.594482,93.652023 918.986755,93.982407 
	C925.345581,93.987808 931.704407,93.993217 938.531616,93.997948 
z"/>
<path fill="#012881" opacity="1.000000" stroke="none" 
	d="
M919.047729,93.646378 
	C916.594482,93.652023 914.202209,93.321640 911.403564,92.584686 
	C911.008667,91.111168 911.020203,90.044212 911.031738,88.977264 
	C914.509399,89.152245 917.987061,89.327217 922.153687,89.536850 
	C920.858826,91.141502 919.983826,92.225922 919.047729,93.646378 
z"/>
<path fill="#012A88" opacity="1.000000" stroke="none" 
	d="
M918.993469,78.991592 
	C921.412109,76.656616 923.830811,74.321640 926.609863,71.968567 
	C924.550354,74.945709 922.130432,77.940948 919.355347,80.968796 
	C919.000183,81.001411 918.975586,80.981728 918.981567,80.650192 
	C918.989563,79.876297 918.991516,79.433945 918.993469,78.991592 
z"/>
<path fill="#012A88" opacity="1.000000" stroke="none" 
	d="
M911.025757,88.738289 
	C911.020203,90.044212 911.008667,91.111168 910.996094,92.576843 
	C909.535339,93.281815 908.075562,93.588051 906.234131,93.888512 
	C907.356934,92.010765 908.861267,90.138786 910.686646,88.144089 
	C911.007690,88.021370 911.019836,88.499313 911.025757,88.738289 
z"/>
<path fill="#012A88" opacity="1.000000" stroke="none" 
	d="
M914.208923,85.896515 
	C913.869385,85.468506 913.906494,85.043449 914.295959,84.274918 
	C916.090698,82.948196 917.533142,81.964958 918.975586,80.981720 
	C918.975586,80.981728 919.000183,81.001411 919.012329,81.013123 
	C917.544800,82.649712 916.065186,84.274590 914.208923,85.896515 
z"/>
<path fill="#021F84" opacity="1.000000" stroke="none" 
	d="
M972.954956,69.645752 
	C972.257812,69.980171 971.502136,69.987724 970.368530,69.987625 
	C970.959229,69.759621 971.927795,69.539253 972.954956,69.645752 
z"/>
<path fill="#021F84" opacity="1.000000" stroke="none" 
	d="
M979.980591,70.666069 
	C979.463562,70.793434 978.906128,70.637581 978.175171,70.241272 
	C978.647827,70.128166 979.294006,70.255501 979.980591,70.666069 
z"/>
<path fill="#012881" opacity="1.000000" stroke="none" 
	d="
M902.990234,100.001297 
	C909.639587,100.602348 916.280640,101.347771 922.942444,101.734413 
	C925.660583,101.892174 928.423889,101.272240 931.595459,101.015579 
	C928.802368,104.731880 925.791138,108.650856 922.315430,112.102051 
	C914.794006,119.570244 907.056458,126.821793 899.353088,134.104630 
	C897.919495,135.459976 896.232056,136.546844 894.001221,137.892548 
	C892.556458,138.672821 891.772095,139.318176 890.987793,139.963531 
	C882.972595,139.971848 874.957336,139.980164 866.474243,139.977386 
	C864.863098,138.978485 863.866516,137.635483 862.541443,137.088181 
	C860.670410,136.315491 858.556335,136.131256 856.546204,135.695435 
	C857.529907,133.720169 858.518433,131.747269 859.491943,129.766953 
	C859.635254,129.475418 859.706848,129.148636 859.485962,129.803345 
	C858.484558,126.283127 857.554504,123.633751 856.998230,120.908112 
	C856.228943,117.138733 852.752747,115.648849 849.005371,117.678909 
	C847.590820,118.445190 846.179749,119.217766 844.385681,119.994194 
	C847.299866,115.966637 850.595398,111.932350 853.935425,107.570236 
	C865.337219,107.189072 876.694580,107.135727 888.706360,107.303619 
	C890.245239,107.019798 891.129700,106.514748 892.014099,106.009705 
	C893.848511,104.139893 895.682983,102.270088 898.088501,100.346092 
	C900.103149,100.195038 901.546692,100.098167 902.990234,100.001297 
z"/>
<path fill="#012E8A" opacity="1.000000" stroke="none" 
	d="
M865.452393,101.968697 
	C866.454895,101.291862 867.894104,100.187607 869.404114,100.080566 
	C876.620972,99.568962 883.856995,99.327797 891.506836,98.981720 
	C889.674683,99.987442 887.467529,101.732697 885.160522,101.876541 
	C878.769836,102.275009 872.337524,102.006012 865.452393,101.968697 
z"/>
<path fill="#012A88" opacity="1.000000" stroke="none" 
	d="
M902.993164,99.597549 
	C901.546692,100.098167 900.103149,100.195038 898.366699,100.167068 
	C899.490601,98.770508 900.907288,97.498787 902.632690,96.093391 
	C902.959595,97.037750 902.977844,98.115776 902.993164,99.597549 
z"/>
<path fill="#0430AF" opacity="1.000000" stroke="none" 
	d="
M918.981567,80.650192 
	C917.533142,81.964958 916.090698,82.948196 914.314697,83.954697 
	C915.430115,82.351921 916.879150,80.725891 918.660767,79.045715 
	C918.991516,79.433945 918.989563,79.876297 918.981567,80.650192 
z"/>
<path fill="#0430AF" opacity="1.000000" stroke="none" 
	d="
M891.669189,106.005005 
	C891.129700,106.514748 890.245239,107.019798 889.174133,107.308220 
	C889.766357,106.727829 890.545349,106.364059 891.669189,106.005005 
z"/>
<path fill="#012E8B" opacity="1.000000" stroke="none" 
	d="
M295.941376,82.165237 
	C293.917358,82.152069 291.893372,82.138893 289.437347,82.082047 
	C288.368958,77.865257 289.299225,74.279610 292.948425,71.339661 
	C305.111145,70.749870 317.210571,70.385735 329.311615,70.320663 
	C335.936768,70.285042 342.564087,70.770386 349.191376,70.992859 
	C359.471283,71.337936 369.750214,71.793015 380.033142,71.926476 
	C384.315308,71.982048 388.602905,71.124153 392.894684,71.045387 
	C400.981323,70.896996 401.133759,71.190071 399.395905,80.004120 
	C396.505219,80.004120 393.424561,80.043076 390.345245,79.993729 
	C386.903107,79.938568 385.275940,81.460869 384.904846,85.004219 
	C384.659821,87.343742 383.446594,89.581848 382.666809,91.865356 
	C379.762390,90.542915 376.487549,89.687683 374.060364,87.763298 
	C371.750397,85.931808 370.408325,82.795975 366.101746,84.696518 
	C365.014465,84.143669 364.502319,83.589417 363.927368,82.669312 
	C359.975616,80.872475 356.156036,79.147812 352.152374,78.202721 
	C351.056885,77.944115 349.409302,80.024216 347.713135,81.321693 
	C336.946411,81.758408 326.481628,81.904510 315.721680,81.750412 
	C308.931458,81.688560 302.436432,81.926895 295.941376,82.165237 
z"/>
<path fill="#086CD7" opacity="1.000000" stroke="none" 
	d="
M424.549500,145.067596 
	C430.377991,150.369186 435.761169,155.708359 441.401703,160.760254 
	C442.250427,161.520386 444.301361,160.971863 445.796173,160.972031 
	C469.907806,160.974716 494.019531,160.947235 518.131042,161.000443 
	C520.239014,161.005096 522.345764,161.525818 524.831909,162.029144 
	C524.073853,163.496445 522.937012,164.741409 521.223389,165.792435 
	C504.423523,165.758835 488.200500,165.919189 471.514099,166.054932 
	C466.369934,166.033646 461.689087,166.036987 456.563568,166.014633 
	C453.742310,166.010712 451.365784,166.032486 448.545837,166.037216 
	C445.733459,166.018341 443.364410,166.016525 440.788574,165.753021 
	C439.717957,165.340561 438.854187,165.189789 437.758881,165.014603 
	C437.527344,164.990204 437.062408,164.963959 437.009949,164.626831 
	C435.650726,163.178940 434.343994,162.068176 433.018616,160.978699 
	C433.000000,161.000000 433.042755,160.962906 432.999298,160.622406 
	C430.321594,157.835815 427.687317,155.389725 425.026245,152.971222 
	C424.999451,152.998779 425.056366,152.948074 425.011810,152.635498 
	C424.298126,151.578934 423.628998,150.834930 422.767090,149.795837 
	C421.066650,147.978485 419.559021,146.456253 417.894531,144.638626 
	C416.546112,143.518402 415.354523,142.693588 414.092407,141.529358 
	C412.341614,139.140747 410.661407,137.091553 408.943298,134.714630 
	C408.248932,133.933685 407.592499,133.480469 406.651642,132.881271 
	C405.244141,131.499954 404.121063,130.264618 403.000000,128.692078 
	C403.003815,127.905617 403.005554,127.456367 403.354614,127.064865 
	C405.181244,127.724884 406.660553,128.327133 408.139862,128.929398 
	C408.139862,128.929398 408.571930,128.971634 408.830627,129.325516 
	C409.702332,130.120361 410.315369,130.561310 410.928406,131.002258 
	C415.348206,135.672104 419.768036,140.341949 424.549500,145.067596 
z"/>
<path fill="#086BCC" opacity="1.000000" stroke="none" 
	d="
M316.016815,82.050606 
	C326.481628,81.904510 336.946411,81.758408 348.054504,81.322983 
	C349.152527,81.051697 349.607239,81.069733 349.999756,81.456543 
	C350.315369,83.207253 350.399689,85.583832 351.122406,85.797150 
	C354.087616,86.672302 355.423340,85.087143 355.382202,82.025635 
	C358.534515,82.355247 361.262360,82.695198 363.990173,83.035156 
	C364.502319,83.589417 365.014465,84.143669 365.772034,84.844879 
	C375.411591,94.576416 384.819427,104.147675 394.184845,113.760284 
	C395.554260,115.165848 396.721222,116.768623 397.688171,118.685944 
	C396.887909,119.799904 396.381287,120.508087 395.526276,121.151703 
	C394.469971,120.713127 393.614532,120.485619 393.076172,119.943336 
	C383.499298,110.296623 374.012451,100.559662 364.356781,90.992966 
	C362.962036,89.611061 360.796082,89.007515 358.598053,88.017502 
	C353.808472,87.987572 349.407867,87.983917 344.727448,87.714340 
	C341.962616,87.625862 339.477600,87.803299 336.556458,87.967560 
	C334.084412,87.957878 332.048553,87.961380 329.699707,87.693604 
	C324.943817,87.297195 320.501526,87.129280 316.057709,87.062904 
	C313.372314,87.022789 310.684540,87.140244 307.997833,87.186905 
	C308.002502,86.989342 308.007202,86.791786 308.360229,86.237679 
	C309.141571,84.909248 309.574524,83.937363 310.408203,82.950104 
	C312.544922,82.640015 314.280853,82.345306 316.016815,82.050606 
z"/>
<path fill="#086BCC" opacity="1.000000" stroke="none" 
	d="
M408.058777,128.597839 
	C406.660553,128.327133 405.181244,127.724884 403.350647,127.060928 
	C402.999329,126.999229 402.992676,126.992805 402.954102,126.700027 
	C402.599335,126.091629 402.283173,125.776009 401.967010,125.460396 
	C401.655273,125.963623 401.343567,126.466850 401.009216,126.992050 
	C400.986572,127.014038 400.941956,127.058624 400.610992,127.002342 
	C399.509064,125.967720 398.738068,124.989372 398.334961,123.998871 
	C399.464050,123.022942 400.225281,122.059174 400.986511,121.095398 
	C403.316895,123.485695 405.647308,125.875992 408.058777,128.597839 
z"/>
<path fill="#086CD7" opacity="1.000000" stroke="none" 
	d="
M400.692200,121.001152 
	C400.225281,122.059174 399.464050,123.022942 398.058502,123.861465 
	C396.901031,122.896240 396.387848,122.056252 395.874695,121.216270 
	C396.381287,120.508087 396.887909,119.799904 397.744049,119.026932 
	C398.861694,119.610405 399.629822,120.258652 400.692200,121.001152 
z"/>
<path fill="#012E8B" opacity="1.000000" stroke="none" 
	d="
M280.572693,70.019035 
	C278.704529,73.487144 275.967651,72.432953 273.081146,70.392227 
	C275.368103,70.014206 277.747284,70.010429 280.572693,70.019035 
z"/>
<path fill="#086BCC" opacity="1.000000" stroke="none" 
	d="
M410.899811,130.688660 
	C410.315369,130.561310 409.702332,130.120361 409.042114,129.374390 
	C409.620361,129.504608 410.245789,129.939850 410.899811,130.688660 
z"/>
<path fill="#012D89" opacity="1.000000" stroke="none" 
	d="
M345.007263,87.980263 
	C349.407867,87.983917 353.808472,87.987572 358.646545,88.381805 
	C362.852966,92.688820 362.383362,97.604897 361.770966,102.282173 
	C361.550446,103.966354 359.274811,106.803658 358.220367,106.680901 
	C355.428680,106.355911 352.555817,105.058624 350.158997,103.476158 
	C347.721863,101.867081 346.137177,98.809731 343.601501,97.542892 
	C339.535645,95.511559 337.414459,97.252693 337.022430,102.426056 
	C337.015594,103.877060 337.011841,104.932861 336.558167,105.992371 
	C331.832642,106.338280 327.539429,106.551193 323.295929,107.129211 
	C322.423370,107.248055 321.736603,108.730675 320.964203,109.584694 
	C307.286163,109.409691 293.608093,109.234688 279.462585,109.018036 
	C280.137360,107.985252 281.133209,106.575218 282.459839,106.111420 
	C284.037964,105.559708 285.900208,105.820724 288.530060,105.682915 
	C285.014038,98.750107 277.673431,104.893547 273.906891,99.164032 
	C277.303345,98.361908 280.392120,97.632446 284.346405,96.698578 
	C282.505249,95.245651 281.349487,94.333595 279.918304,93.204178 
	C281.030670,91.892593 281.948761,90.810059 283.223450,89.342316 
	C284.066559,88.388245 284.553040,87.819374 285.039520,87.250504 
	C288.713501,87.211357 292.387451,87.172211 296.634949,87.540695 
	C301.402985,88.919868 305.584137,90.606911 309.795288,90.685387 
	C315.302307,90.788017 320.837494,89.655197 326.350037,88.968918 
	C327.593292,88.814140 328.792938,88.308830 330.012665,87.964890 
	C332.048553,87.961380 334.084412,87.957878 336.826172,88.250504 
	C340.023773,88.357849 342.515503,88.169060 345.007263,87.980263 
z"/>
<path fill="#013899" opacity="1.000000" stroke="none" 
	d="
M329.699707,87.693604 
	C328.792938,88.308830 327.593292,88.814140 326.350037,88.968918 
	C320.837494,89.655197 315.302307,90.788017 309.795288,90.685387 
	C305.584137,90.606911 301.402985,88.919868 297.089600,87.600426 
	C300.341400,87.198341 303.712097,87.144165 307.540283,87.138451 
	C310.684540,87.140244 313.372314,87.022789 316.057709,87.062904 
	C320.501526,87.129280 324.943817,87.297195 329.699707,87.693604 
z"/>
<path fill="#01399A" opacity="1.000000" stroke="none" 
	d="
M315.721680,81.750412 
	C314.280853,82.345306 312.544922,82.640015 310.033386,82.935577 
	C308.508911,82.955490 307.760040,82.974548 307.011169,82.993607 
	C303.538727,82.879539 300.066284,82.765472 296.267609,82.408318 
	C302.436432,81.926895 308.931458,81.688560 315.721680,81.750412 
z"/>
<path fill="#013899" opacity="1.000000" stroke="none" 
	d="
M284.577545,87.162277 
	C284.553040,87.819374 284.066559,88.388245 283.273376,89.011459 
	C279.043091,88.722733 275.119537,88.379654 271.059998,87.683838 
	C275.321167,87.245422 279.718384,87.159737 284.577545,87.162277 
z"/>
<path fill="#0970D1" opacity="1.000000" stroke="none" 
	d="
M307.011719,83.332291 
	C307.760040,82.974548 308.508911,82.955490 309.632629,82.950951 
	C309.574524,83.937363 309.141571,84.909248 308.367859,85.941360 
	C307.688843,85.224709 307.350555,84.447838 307.011719,83.332291 
z"/>
<path fill="#11A7F5" opacity="1.000000" stroke="none" 
	d="
M116.080711,81.518806 
	C116.095741,81.114075 116.485146,80.975433 116.680756,80.909912 
	C120.843430,79.026527 123.861244,80.743210 126.833916,83.720497 
	C127.017418,84.695206 126.977821,85.408134 126.892059,86.471054 
	C126.383514,86.996613 125.929932,87.201591 125.457283,87.342735 
	C123.260559,87.998726 119.936325,89.826935 119.093681,89.032219 
	C117.376740,87.412949 116.996597,84.376244 116.080711,81.518806 
z"/>
<path fill="#012D89" opacity="1.000000" stroke="none" 
	d="
M276.732605,108.703995 
	C270.946259,109.248680 264.901672,109.521889 258.395691,109.659462 
	C257.810486,107.146599 258.652863,105.621834 261.316742,106.021217 
	C266.376068,106.779716 271.422516,107.623909 276.732605,108.703995 
z"/>
<path fill="#042A80" opacity="1.000000" stroke="none" 
	d="
M229.063354,169.093903 
	C227.966827,166.096451 226.870300,163.098999 225.398010,160.040283 
	C222.931244,156.126572 221.152298,152.051605 218.626480,148.509186 
	C217.222244,146.539780 216.076813,145.689728 219.631866,145.012329 
	C219.991287,145.003662 219.999969,145.000671 220.037582,145.352448 
	C221.162704,147.449280 222.250198,149.194351 223.720566,151.553772 
	C225.115036,150.056458 226.059952,149.041840 227.004868,148.027252 
	C234.702988,148.020645 242.401093,148.014053 250.571518,148.031952 
	C248.919357,150.651382 246.787430,153.240250 244.673294,155.843567 
	C243.462326,157.334717 242.280289,158.849365 241.078400,160.703842 
	C241.071579,161.054245 241.046844,161.036743 240.746765,161.158264 
	C239.945435,162.171005 239.444183,163.062195 238.942917,163.953400 
	C237.134216,165.491180 235.325516,167.028961 232.859406,168.768341 
	C231.155777,169.011276 230.109573,169.052582 229.063354,169.093903 
M230.014908,153.533295 
	C230.043304,156.854782 232.315186,157.385468 234.778824,156.882751 
	C235.937897,156.646225 237.849426,155.000488 237.703491,154.441772 
	C237.300598,152.899643 236.068283,150.517410 235.025864,150.444611 
	C233.427200,150.332932 231.713013,151.875076 230.014908,153.533295 
z"/>
<path fill="#011D6E" opacity="1.000000" stroke="none" 
	d="
M181.527802,136.542511 
	C181.478104,136.570633 181.577515,136.514404 181.527802,136.542511 
z"/>
<path fill="#013498" opacity="1.000000" stroke="none" 
	d="
M391.993408,193.011688 
	C390.428589,191.870132 388.863800,190.728577 387.104858,188.973175 
	C386.275421,187.905121 385.640167,187.450928 385.004883,186.996735 
	C380.172180,182.000061 375.437775,176.903412 370.481934,172.032028 
	C364.268982,165.924927 357.795074,160.082275 351.622375,153.936081 
	C350.325134,152.644424 349.846863,150.530289 349.453369,148.405334 
	C358.820038,148.008224 367.727661,148.122330 376.626617,147.895782 
	C378.506683,147.847931 380.355042,146.553009 382.217987,145.832672 
	C382.217987,145.832672 382.630859,145.827805 382.859833,146.249344 
	C384.602295,149.218521 384.033264,153.756775 389.042969,152.968445 
	C389.011719,153.004318 389.064728,152.928391 389.076843,153.279587 
	C390.412476,154.736450 391.736023,155.842102 393.029785,156.973572 
	C393.000000,156.999390 393.052246,156.940277 393.068115,157.290955 
	C396.359131,160.441162 399.634216,163.240692 403.184570,166.159607 
	C403.961121,166.880356 404.462402,167.481720 404.977661,168.442520 
	C405.440918,169.844772 405.652008,171.110031 406.381470,171.891296 
	C409.301056,175.018265 412.842010,177.666290 415.295776,181.103516 
	C419.072449,186.393890 423.882202,189.293961 430.208099,190.109558 
	C431.097961,190.224289 431.886383,191.126190 432.721741,191.663727 
	C432.652466,192.138443 432.583160,192.613144 432.513885,193.087860 
	C424.648132,192.089188 416.680298,190.213287 409.262482,194.553680 
	C411.910797,196.277649 414.300507,197.833252 416.690186,199.388855 
	C414.619324,202.030106 412.548492,204.671341 410.430725,207.372421 
	C405.478088,202.999527 401.356995,199.204758 397.044708,195.641403 
	C395.613708,194.458939 393.691193,193.871277 391.993408,193.011688 
z"/>
<path fill="#012F8C" opacity="1.000000" stroke="none" 
	d="
M312.977234,114.282227 
	C317.331207,114.212318 321.685181,114.142403 326.619080,114.476547 
	C329.209015,115.250565 331.204987,115.758179 333.231628,115.965469 
	C340.172150,116.675400 347.123505,117.279915 354.070709,117.924637 
	C354.070709,117.924637 354.000000,117.999695 354.062439,118.311813 
	C355.061707,119.096016 355.998566,119.568092 356.935394,120.040161 
	C356.935394,120.040161 356.988892,120.011131 356.988647,120.408257 
	C357.523621,123.659309 358.277252,126.297142 362.063385,125.934456 
	C362.063385,125.934456 362.000000,126.000000 362.013855,126.341888 
	C364.675262,129.138123 367.322784,131.592468 370.018677,134.382660 
	C371.062836,135.461868 372.058716,136.205246 373.054565,136.948639 
	C373.054565,136.948639 373.000000,136.999741 373.034607,137.305084 
	C373.700104,138.752411 374.330933,139.894394 374.635132,141.032303 
	C373.872955,141.024200 373.437439,141.020157 372.829681,140.792236 
	C372.104004,141.041718 371.550568,141.515060 370.529938,141.992111 
	C359.044830,141.986801 348.026947,141.977783 336.906860,141.578918 
	C335.963165,140.796661 335.137421,140.103806 334.277618,140.058655 
	C326.341125,139.641830 318.396973,139.373413 310.458679,138.986893 
	C305.010223,138.721603 299.528229,138.614426 294.144379,137.850693 
	C292.348083,137.595871 289.422638,135.510452 289.435394,134.287155 
	C289.463928,131.555557 288.061951,127.141167 293.439972,126.952820 
	C294.266418,126.923882 295.072723,126.319748 297.160706,125.454170 
	C293.422821,124.241722 290.904114,123.424728 287.002563,122.159195 
	C297.947784,117.271149 307.929260,122.811241 316.990906,120.601860 
	C315.563080,118.353745 314.270142,116.317986 312.977234,114.282227 
z"/>
<path fill="#012986" opacity="1.000000" stroke="none" 
	d="
M312.515869,114.200714 
	C314.270142,116.317986 315.563080,118.353745 316.990906,120.601860 
	C307.929260,122.811241 297.947784,117.271149 287.002563,122.159195 
	C290.904114,123.424728 293.422821,124.241722 297.160706,125.454170 
	C295.072723,126.319748 294.266418,126.923882 293.439972,126.952820 
	C288.061951,127.141167 289.463928,131.555557 289.435394,134.287155 
	C289.422638,135.510452 292.348083,137.595871 294.144379,137.850693 
	C299.528229,138.614426 305.010223,138.721603 310.458679,138.986893 
	C318.396973,139.373413 326.341125,139.641830 334.277618,140.058655 
	C335.137421,140.103806 335.963165,140.796661 336.439056,141.589111 
	C331.039856,142.001236 326.006348,142.013336 320.972809,142.025436 
	C318.940552,142.023590 316.908295,142.021729 314.142822,141.728333 
	C300.506714,141.312408 287.603912,141.176224 274.700775,141.103180 
	C274.136566,141.099991 273.569092,141.675522 273.003143,141.981476 
	C268.311584,141.983368 263.620026,141.985260 258.243713,141.728394 
	C256.685364,141.297668 255.811752,141.125717 254.996140,140.582062 
	C254.660294,137.632690 255.032867,134.448074 253.626541,132.672241 
	C252.530731,131.288528 248.993195,130.843887 246.944275,131.419144 
	C242.830948,132.574020 242.738083,136.381210 242.747940,139.998352 
	C242.499222,140.006149 242.001633,140.015488 241.730118,139.755310 
	C240.754349,133.381592 242.168396,130.880386 247.356354,128.936920 
	C248.095047,128.660202 248.976486,128.332535 249.409775,127.746132 
	C251.460037,124.971230 253.377625,122.098289 255.959564,118.368973 
	C254.184006,117.656288 252.120453,116.840874 250.067566,115.999466 
	C248.705444,115.441185 247.358566,114.845711 246.004868,114.266891 
	C268.021423,114.217659 290.037994,114.168434 312.515869,114.200714 
z"/>
<path fill="#013899" opacity="1.000000" stroke="none" 
	d="
M337.025513,102.030838 
	C337.414459,97.252693 339.535645,95.511559 343.601501,97.542892 
	C346.137177,98.809731 347.721863,101.867081 350.158997,103.476158 
	C352.555817,105.058624 355.428680,106.355911 358.220367,106.680901 
	C359.274811,106.803658 361.550446,103.966354 361.770966,102.282173 
	C362.383362,97.604897 362.852966,92.688820 359.035522,88.408066 
	C360.796082,89.007515 362.962036,89.611061 364.356781,90.992966 
	C374.012451,100.559662 383.499298,110.296623 393.076172,119.943336 
	C393.614532,120.485619 394.469971,120.713127 395.526276,121.151703 
	C396.387848,122.056252 396.901031,122.896240 397.690643,123.873627 
	C398.738068,124.989372 399.509064,125.967720 400.671387,127.314095 
	C401.707855,128.131180 402.352905,128.580246 402.997986,129.029297 
	C404.121063,130.264618 405.244141,131.499954 406.714661,133.194397 
	C407.701782,134.116455 408.341492,134.579407 408.981201,135.042358 
	C410.661407,137.091553 412.341614,139.140747 413.665039,141.571808 
	C406.641357,141.635483 399.969177,141.398514 393.309967,140.963364 
	C390.178497,140.758759 388.524689,141.728012 388.931366,145.087585 
	C385.681183,141.936401 382.393921,138.822372 379.189606,135.625198 
	C374.533813,130.979782 369.937836,126.274406 365.113129,120.987350 
	C364.285217,119.912941 363.660461,119.445801 363.035736,118.978661 
	C361.141022,117.256599 359.438110,115.228302 357.302612,113.890648 
	C354.683533,112.250069 351.702698,111.187057 348.397003,109.476532 
	C347.602295,107.493408 347.736572,105.578430 346.869446,104.400757 
	C345.597809,102.673653 343.800690,100.897346 341.859100,100.254318 
	C340.583893,99.831985 338.659302,101.370468 337.025513,102.030838 
z"/>
<path fill="#01257D" opacity="1.000000" stroke="none" 
	d="
M245.540405,114.184052 
	C247.358566,114.845711 248.705444,115.441185 250.067566,115.999466 
	C252.120453,116.840874 254.184006,117.656288 255.959564,118.368973 
	C253.377625,122.098289 251.460037,124.971230 249.409775,127.746132 
	C248.976486,128.332535 248.095047,128.660202 247.356354,128.936920 
	C242.168396,130.880386 240.754349,133.381592 241.620850,140.009445 
	C241.521927,141.010712 241.260757,141.497681 240.999603,141.984650 
	C236.384079,141.651352 231.738358,141.528107 227.169418,140.870895 
	C225.568710,140.640640 223.908249,139.341965 222.699570,138.113495 
	C215.425690,130.720734 208.275238,123.206566 201.438477,115.427094 
	C209.050262,115.376610 216.306320,115.667183 223.565292,115.808739 
	C224.050690,115.818207 224.558090,114.698578 225.055252,114.104095 
	C231.728821,114.103142 238.402390,114.102188 245.540405,114.184052 
z"/>
<path fill="#0975D5" opacity="1.000000" stroke="none" 
	d="
M404.963715,168.083099 
	C404.462402,167.481720 403.961121,166.880356 403.068298,165.849091 
	C399.468597,162.592896 396.260406,159.766586 393.052246,156.940277 
	C393.052246,156.940277 393.000000,156.999390 392.984131,156.637207 
	C391.667114,155.159470 390.365906,154.043930 389.064728,152.928391 
	C389.064728,152.928391 389.011719,153.004318 389.026367,152.629898 
	C388.333038,150.894669 387.625092,149.533859 386.917175,148.173035 
	C387.461212,147.786285 388.005249,147.399536 389.174988,147.103882 
	C391.173401,147.822250 392.546082,148.449509 393.964813,149.397598 
	C395.015137,150.795822 396.019379,151.873215 397.162231,153.242493 
	C398.546722,154.685730 399.792572,155.837112 401.165833,157.294022 
	C402.847412,158.759277 404.401642,159.918991 406.100220,161.378174 
	C407.797729,163.136383 409.350800,164.595123 410.964600,166.386078 
	C412.008881,167.488876 412.992371,168.259476 413.987946,169.015045 
	C414.000000,169.000000 413.970184,169.023819 414.016296,169.339249 
	C414.686127,170.125595 415.309845,170.596527 415.966187,171.034027 
	C415.998779,171.000610 415.931458,171.067993 415.978699,171.406281 
	C418.013519,173.502609 420.001129,175.260651 422.106598,177.233490 
	C422.432861,177.715805 422.699921,177.896698 423.210693,178.342392 
	C425.419525,179.141281 427.442200,179.972229 429.467346,179.978302 
	C457.256592,180.061615 485.046173,180.031174 512.835693,180.028656 
	C512.903748,181.712128 512.971802,183.395615 512.523804,185.520966 
	C493.185333,185.664856 474.363525,185.321671 455.540375,185.087769 
	C445.981476,184.968979 436.419281,184.967743 426.860352,185.074295 
	C422.899139,185.118439 420.177399,183.732513 417.555969,180.557800 
	C413.812714,176.024521 409.203217,172.206558 404.963715,168.083099 
z"/>
<path fill="#011480" opacity="1.000000" stroke="none" 
	d="
M321.064972,142.393555 
	C326.006348,142.013336 331.039856,142.001236 336.541199,141.978943 
	C348.026947,141.977783 359.044830,141.986801 370.825775,141.973938 
	C372.059845,141.640076 372.530884,141.328110 373.001923,141.016129 
	C373.437439,141.020157 373.872955,141.024200 374.879578,141.293930 
	C376.631104,141.709534 377.811584,141.859451 378.992035,142.009354 
	C379.291565,142.140366 379.545349,142.331970 379.910522,143.115875 
	C380.725983,144.084320 381.384247,144.521057 382.042511,144.957794 
	C382.042511,144.957794 382.131989,145.395554 382.174988,145.614105 
	C380.355042,146.553009 378.506683,147.847931 376.626617,147.895782 
	C367.727661,148.122330 358.820038,148.008224 349.444153,148.036591 
	C347.264801,148.037125 345.556793,148.016541 343.246033,147.756226 
	C342.078491,147.390137 341.513641,147.263809 340.948822,147.137482 
	C337.269440,147.103714 333.590057,147.069931 329.449951,147.012177 
	C326.378510,145.579361 323.767822,144.170502 321.064972,142.393555 
z"/>
<path fill="#001C8F" opacity="1.000000" stroke="none" 
	d="
M393.918762,149.076767 
	C392.546082,148.449509 391.173401,147.822250 389.518707,147.050598 
	C389.169495,146.501724 389.102325,146.097229 388.983246,145.390167 
	C388.524689,141.728012 390.178497,140.758759 393.309967,140.963364 
	C399.969177,141.398514 406.641357,141.635483 413.735596,141.911224 
	C415.354523,142.693588 416.546112,143.518402 417.856934,145.058548 
	C416.589813,146.517975 415.251648,147.781387 413.808594,147.915359 
	C407.889587,148.464859 401.938660,148.670349 395.652466,148.993668 
	C394.843201,149.015518 394.380981,149.046143 393.918762,149.076767 
z"/>
<path fill="#0350AC" opacity="1.000000" stroke="none" 
	d="
M337.022430,102.426056 
	C338.659302,101.370468 340.583893,99.831985 341.859100,100.254318 
	C343.800690,100.897346 345.597809,102.673653 346.869446,104.400757 
	C347.736572,105.578430 347.602295,107.493408 347.999390,109.449265 
	C344.655853,109.848785 341.228638,109.877762 337.371338,109.556702 
	C336.965149,108.811195 336.989075,108.415710 337.307159,107.976044 
	C337.410858,107.280617 337.220398,106.629372 337.022461,105.979431 
	C337.014984,105.980721 337.008087,105.988655 337.008087,105.988655 
	C337.011841,104.932861 337.015594,103.877060 337.022430,102.426056 
z"/>
<path fill="#03429E" opacity="1.000000" stroke="none" 
	d="
M354.012146,117.545074 
	C347.123505,117.279915 340.172150,116.675400 333.231628,115.965469 
	C331.204987,115.758179 329.209015,115.250565 327.080994,114.505051 
	C334.358124,114.085190 341.760651,113.852768 349.142670,114.142097 
	C350.783264,114.206398 352.351837,116.109207 354.012146,117.545074 
z"/>
<path fill="#023290" opacity="1.000000" stroke="none" 
	d="
M337.013000,108.020233 
	C336.989075,108.415710 336.965149,108.811195 336.908081,109.498405 
	C331.878357,109.798073 326.881744,109.806015 321.424683,109.699326 
	C321.736603,108.730675 322.423370,107.248055 323.295929,107.129211 
	C327.539429,106.551193 331.832642,106.338280 336.558167,105.992371 
	C337.008087,105.988655 337.014984,105.980721 337.011505,106.319786 
	C337.009674,107.112648 337.011322,107.566437 337.013000,108.020233 
z"/>
<path fill="#03429E" opacity="1.000000" stroke="none" 
	d="
M224.584045,114.047699 
	C224.558090,114.698578 224.050690,115.818207 223.565292,115.808739 
	C216.306320,115.667183 209.050262,115.376610 201.371155,115.098190 
	C200.948792,115.075233 200.906006,114.597878 200.903976,114.359375 
	C208.638916,114.077682 216.375885,114.034485 224.584045,114.047699 
z"/>
<path fill="#03429E" opacity="1.000000" stroke="none" 
	d="
M369.970337,134.046814 
	C367.322784,131.592468 364.675262,129.138123 362.046631,126.310165 
	C364.610138,128.095062 367.154724,130.253571 369.844666,132.969452 
	C369.983429,133.700180 369.976868,133.873505 369.970337,134.046814 
z"/>
<path fill="#023DA5" opacity="1.000000" stroke="none" 
	d="
M386.598755,148.081757 
	C387.625092,149.533859 388.333038,150.894669 389.057617,152.594025 
	C384.033264,153.756775 384.602295,149.218521 383.062012,146.291260 
	C384.116943,146.604584 385.198669,147.297516 386.598755,148.081757 
z"/>
<path fill="#03429E" opacity="1.000000" stroke="none" 
	d="
M362.017151,125.602005 
	C358.277252,126.297142 357.523621,123.659309 356.974091,120.434982 
	C358.630188,121.799576 360.300568,123.534569 362.017151,125.602005 
z"/>
<path fill="#023DA5" opacity="1.000000" stroke="none" 
	d="
M378.863586,141.701447 
	C377.811584,141.859451 376.631104,141.709534 375.206207,141.298004 
	C374.330933,139.894394 373.700104,138.752411 373.060486,137.277939 
	C374.946228,138.428131 376.840698,139.910828 378.863586,141.701447 
z"/>
<path fill="#023DA5" opacity="1.000000" stroke="none" 
	d="
M370.018677,134.382660 
	C369.976868,133.873505 369.983429,133.700180 369.994049,133.266769 
	C370.989471,134.105042 371.980865,135.203369 373.013428,136.625183 
	C372.058716,136.205246 371.062836,135.461868 370.018677,134.382660 
z"/>
<path fill="#03429E" opacity="1.000000" stroke="none" 
	d="
M356.820831,119.742714 
	C355.998566,119.568092 355.061707,119.096016 354.099487,118.275940 
	C354.951477,118.433708 355.828888,118.939491 356.820831,119.742714 
z"/>
<path fill="#0350AC" opacity="1.000000" stroke="none" 
	d="
M363.067566,119.299210 
	C363.660461,119.445801 364.285217,119.912941 364.947968,120.699234 
	C364.357117,120.552177 363.728241,120.085968 363.067566,119.299210 
z"/>
<path fill="#023DA5" opacity="1.000000" stroke="none" 
	d="
M381.997681,144.640198 
	C381.384247,144.521057 380.725983,144.084320 380.037933,143.319550 
	C380.656403,143.435226 381.304626,143.878906 381.997681,144.640198 
z"/>
<path fill="#011480" opacity="1.000000" stroke="none" 
	d="
M303.712219,146.730835 
	C302.272766,147.346649 300.538696,147.932983 298.801727,147.941635 
	C283.839508,148.016235 268.876801,147.986664 253.443939,147.952972 
	C253.790359,147.284225 254.608047,146.097443 255.423599,146.098907 
	C271.421326,146.127747 287.418762,146.294739 303.712219,146.730835 
z"/>
<path fill="#011480" opacity="1.000000" stroke="none" 
	d="
M273.300049,142.275391 
	C273.569092,141.675522 274.136566,141.099991 274.700775,141.103180 
	C287.603912,141.176224 300.506714,141.312408 313.707184,141.731888 
	C313.437134,142.330521 312.867981,142.901489 312.302002,142.898331 
	C299.400116,142.826553 286.498535,142.692078 273.300049,142.275391 
z"/>
<path fill="#011480" opacity="1.000000" stroke="none" 
	d="
M241.332001,142.136261 
	C241.260757,141.497681 241.521927,141.010712 241.892365,140.269623 
	C242.001633,140.015488 242.499222,140.006149 243.035736,140.206390 
	C247.201538,143.899323 251.024689,143.364655 254.938156,140.953781 
	C255.811752,141.125717 256.685364,141.297668 257.777283,141.725464 
	C252.606018,144.966476 247.173691,145.675720 241.332001,142.136261 
z"/>
<path fill="#032275" opacity="1.000000" stroke="none" 
	d="
M226.662262,147.961426 
	C226.059952,149.041840 225.115036,150.056458 223.720566,151.553772 
	C222.250198,149.194351 221.162704,147.449280 220.036133,145.356430 
	C222.104599,145.970963 224.212128,146.933289 226.662262,147.961426 
z"/>
<path fill="#011480" opacity="1.000000" stroke="none" 
	d="
M320.952026,147.428528 
	C319.219910,147.694168 317.379272,147.625778 315.267578,147.281509 
	C316.945526,147.035248 318.894501,147.064880 320.952026,147.428528 
z"/>
<path fill="#011773" opacity="1.000000" stroke="none" 
	d="
M207.171661,536.866150 
	C207.116287,538.291260 207.060913,539.716370 207.007828,541.569824 
	C204.650009,543.021973 206.443268,548.399170 201.381790,546.996582 
	C201.890671,543.899170 207.580017,541.983582 203.140930,537.306641 
	C203.001297,536.778503 203.025375,536.557556 203.447113,536.049561 
	C204.953735,536.130432 206.062698,536.498291 207.171661,536.866150 
z"/>
<path fill="#011773" opacity="1.000000" stroke="none" 
	d="
M215.349243,548.932007 
	C214.439514,548.941528 213.814697,548.908081 213.189896,548.874695 
	C213.190781,548.658569 213.191666,548.442505 213.192551,548.226379 
	C214.049332,548.226379 214.906372,548.217285 215.762482,548.241211 
	C215.842865,548.243530 216.005081,548.490601 215.976044,548.559448 
	C215.919525,548.693542 215.754349,548.781738 215.349243,548.932007 
z"/>
<path fill="#1546AD" opacity="1.000000" stroke="none" 
	d="
M209.600372,580.978088 
	C209.478470,581.574219 209.049835,582.235840 208.292419,582.921387 
	C208.406982,582.311401 208.850311,581.677490 209.600372,580.978088 
z"/>
<path fill="#033FA9" opacity="1.000000" stroke="none" 
	d="
M206.003281,513.983765 
	C212.696518,513.983765 219.389755,513.983765 227.289124,513.983765 
	C226.005096,511.555756 225.535202,510.667175 225.395859,509.399536 
	C226.821289,508.994873 227.916168,508.969299 229.347412,509.117737 
	C231.162720,509.618774 232.641647,509.945770 234.051514,510.602692 
	C234.977173,511.633698 235.957382,512.919067 236.968872,512.944214 
	C245.681290,513.160461 254.399353,513.149231 263.085754,513.505920 
	C263.039764,514.209534 263.023804,514.613098 263.007812,515.016724 
	C261.941071,515.015564 260.874359,515.014465 259.072174,515.010254 
	C257.889221,515.012634 257.441742,515.018250 256.994232,515.023804 
	C249.305069,515.019165 241.615921,515.014587 233.058380,515.012695 
	C231.123734,515.034424 230.057465,515.053467 228.561829,515.050049 
	C225.432343,515.385864 222.732224,515.744141 220.032104,516.102417 
	C215.670853,516.059448 211.309601,516.016479 206.478119,515.634583 
	C206.006348,514.858398 206.004822,514.421082 206.003281,513.983765 
z"/>
<path fill="#010B64" opacity="1.000000" stroke="none" 
	d="
M219.976349,516.441956 
	C222.732224,515.744141 225.432343,515.385864 228.557953,515.414368 
	C226.620819,522.142151 221.755844,526.026917 215.995789,529.033142 
	C215.031479,527.599915 214.067184,526.166748 213.066071,524.379333 
	C215.326355,521.610596 217.623474,519.196045 219.976349,516.441956 
z"/>
<path fill="#043898" opacity="1.000000" stroke="none" 
	d="
M205.535278,513.978760 
	C206.004822,514.421082 206.006348,514.858398 206.014648,515.622620 
	C202.226257,515.967651 198.411621,515.758423 194.642487,516.080750 
	C191.957657,516.310425 189.334000,517.255371 186.283310,517.887573 
	C188.039871,516.582703 190.078461,514.473633 192.378204,514.134216 
	C196.525467,513.522156 200.829025,513.969116 205.535278,513.978760 
z"/>
<path fill="#00056D" opacity="1.000000" stroke="none" 
	d="
M363.970032,508.948364 
	C352.966827,508.984283 341.963654,509.020172 330.495605,509.082581 
	C312.567047,509.082336 295.103271,509.025299 277.639771,509.071045 
	C275.684143,509.076172 273.730042,509.664001 271.394775,509.652893 
	C271.646332,507.869873 272.278412,506.415527 273.204376,504.817688 
	C274.674286,504.123840 275.850311,503.573547 277.422485,503.033813 
	C278.874359,503.040894 279.930084,503.037415 281.259399,503.304932 
	C285.282867,504.047913 289.030426,504.905670 292.783081,504.928375 
	C314.870575,505.062073 336.959290,505.001251 359.047577,505.005859 
	C360.814911,505.006226 362.582245,505.005859 364.696960,505.005859 
	C364.375275,506.750488 364.172668,507.849426 363.970032,508.948364 
z"/>
<path fill="#033FA9" opacity="1.000000" stroke="none" 
	d="
M272.910492,504.961182 
	C272.278412,506.415527 271.646332,507.869873 270.967285,509.665466 
	C269.803223,510.591614 268.686188,511.176544 267.259521,511.493774 
	C265.789886,510.743561 264.629883,509.844482 263.469940,509.844543 
	C254.310471,509.845001 245.151001,510.016937 236.006607,509.866638 
	C236.024994,509.417908 236.028290,509.238647 236.496643,509.036316 
	C241.436859,509.009674 245.917343,509.139313 250.385651,508.962158 
	C254.264969,508.808350 258.130493,508.307312 262.348145,507.931213 
	C263.812622,506.271545 264.931122,504.640472 266.351013,502.858276 
	C269.084869,500.785461 271.517273,498.863739 273.832581,497.270233 
	C272.815125,498.910065 271.934021,500.235413 271.010376,501.530426 
	C269.231537,504.024353 270.172577,504.983398 272.910492,504.961182 
z"/>
<path fill="#012276" opacity="1.000000" stroke="none" 
	d="
M216.055664,529.373901 
	C221.755844,526.026917 226.620819,522.142151 228.987335,515.436890 
	C230.057465,515.053467 231.123734,515.034424 232.589417,515.032227 
	C232.253220,516.743103 231.567322,518.461304 230.763290,520.122375 
	C230.281937,521.116760 229.602524,522.013916 229.035309,522.968567 
	C227.534698,525.494019 226.046753,528.026917 223.651260,529.899231 
	C223.811310,532.133911 223.971359,534.368530 224.131393,536.603210 
	C224.178940,536.078857 224.226486,535.554565 224.274033,535.030212 
	C221.554535,533.258423 218.835037,531.486572 216.055664,529.373901 
z"/>
<path fill="#0455DA" opacity="1.000000" stroke="none" 
	d="
M207.507019,536.904053 
	C206.062698,536.498291 204.953735,536.130432 203.485901,535.718506 
	C203.088562,534.030640 202.519760,532.140076 203.141464,530.803223 
	C203.979874,529.000366 205.451721,526.941895 207.165359,526.230896 
	C209.482101,525.269531 211.739594,526.367004 212.097763,529.383179 
	C212.365585,531.638428 212.804733,533.951233 212.483871,536.138123 
	C212.412796,536.622681 209.465393,536.685181 207.507019,536.904053 
z"/>
<path fill="#065DD2" opacity="1.000000" stroke="none" 
	d="
M235.991531,510.136108 
	C245.151001,510.016937 254.310471,509.845001 263.469940,509.844543 
	C264.629883,509.844482 265.789886,510.743561 266.960388,511.599274 
	C266.970886,511.972504 266.935638,512.408081 266.552612,512.656372 
	C265.151642,513.005066 264.133698,513.105469 263.115723,513.205933 
	C254.399353,513.149231 245.681290,513.160461 236.968872,512.944214 
	C235.957382,512.919067 234.977173,511.633698 234.360962,510.555115 
	C235.156830,510.163818 235.574173,510.149963 235.991531,510.136108 
z"/>
<path fill="#012D8D" opacity="1.000000" stroke="none" 
	d="
M319.214844,550.040527 
	C317.746887,549.949829 316.624115,549.757996 315.501312,549.566101 
	C315.773926,548.875122 315.972046,547.639404 316.332367,547.590088 
	C317.688965,547.404663 319.098358,547.605530 320.487976,547.661682 
	C320.178650,548.420898 319.869324,549.180115 319.214844,550.040527 
z"/>
<path fill="#012A88" opacity="1.000000" stroke="none" 
	d="
M263.085754,513.505920 
	C264.133698,513.105469 265.151642,513.005066 266.552338,512.874878 
	C265.759644,513.727295 264.584259,514.609497 263.208344,515.254211 
	C263.023804,514.613098 263.039764,514.209534 263.085754,513.505920 
z"/>
<path fill="#092672" opacity="1.000000" stroke="none" 
	d="
M254.715668,560.486938 
	C255.450058,560.481384 255.971283,560.682190 256.492523,560.882996 
	C256.361328,561.151001 256.230133,561.419006 256.098969,561.687012 
	C255.566818,561.355774 255.034683,561.024597 254.715668,560.486938 
z"/>
<path fill="#012A88" opacity="1.000000" stroke="none" 
	d="
M257.037842,515.314697 
	C257.441742,515.018250 257.889221,515.012634 258.672241,515.020386 
	C258.685730,515.535522 258.363770,516.037292 258.041809,516.539124 
	C257.721710,516.227966 257.401611,515.916809 257.037842,515.314697 
z"/>
<path fill="#064AD0" opacity="1.000000" stroke="none" 
	d="
M375.484467,504.302490 
	C375.301483,503.719025 375.523804,503.133331 376.314941,502.251648 
	C382.486084,502.442902 387.516327,501.429565 392.001038,497.571259 
	C395.391296,494.654541 399.828583,495.484772 404.020874,499.364929 
	C405.059937,500.128143 406.034088,500.550049 407.476196,500.984894 
	C422.292999,500.980530 436.641785,500.963226 451.459778,500.968689 
	C472.284882,500.980469 492.640778,500.969513 513.465332,500.976135 
	C519.516296,500.996552 525.104065,501.133484 530.677612,500.912903 
	C532.172668,500.853790 533.624695,499.705231 535.438599,499.052551 
	C536.856018,498.369751 537.931213,497.691101 539.301636,496.867981 
	C541.401917,494.817657 543.206909,492.911865 545.313354,490.855072 
	C548.075684,488.131653 550.536621,485.559235 553.297363,482.844421 
	C556.726318,479.466095 559.855347,476.230133 563.256042,472.803406 
	C565.360596,470.426453 567.193481,468.240265 569.366943,466.001587 
	C576.132629,459.596405 582.557617,453.243683 589.414917,446.912323 
	C591.574402,446.970520 593.301575,447.007294 594.982178,447.388000 
	C593.615173,450.147827 592.294861,452.563721 590.987244,454.989807 
	C591.000000,455.000000 590.979858,454.974976 590.645752,455.032898 
	C588.200256,457.388367 586.088806,459.685974 583.988708,461.991760 
	C584.000000,462.000000 583.982544,461.977936 583.635620,462.043396 
	C578.698669,465.290283 574.328979,468.682495 571.603638,474.011047 
	C570.170593,475.352509 569.075134,476.653595 567.643860,478.003906 
	C566.521667,479.027679 565.735229,480.002167 564.624084,480.979523 
	C563.867004,481.001801 563.434570,481.021210 562.683838,481.072693 
	C561.923157,481.737152 561.480774,482.369598 560.883667,483.294739 
	C558.817627,485.722229 556.906372,487.856934 554.696350,490.137909 
	C551.273376,493.527100 548.149170,496.770020 544.756958,500.235596 
	C544.258301,501.582611 544.027649,502.706909 543.796997,503.831238 
	C536.862732,504.235229 529.929321,504.970001 522.994019,504.988800 
	C482.575745,505.098328 442.157074,505.039825 401.293579,504.589905 
	C401.254974,500.887970 399.165802,499.377594 396.541229,499.409119 
	C393.439117,499.446350 392.023712,501.684845 392.282074,504.820007 
	C386.817963,504.646759 381.353882,504.473511 375.484467,504.302490 
z"/>
<path fill="#01359A" opacity="1.000000" stroke="none" 
	d="
M544.193970,504.028534 
	C544.027649,502.706909 544.258301,501.582611 545.123657,500.226410 
	C548.840210,497.331726 551.922058,494.668915 555.389587,492.012573 
	C559.367737,492.618561 559.532410,494.298157 557.935364,497.292938 
	C557.367310,498.358276 557.995056,500.811096 558.946533,501.708618 
	C559.762695,502.478485 562.293518,502.572052 563.248291,501.863159 
	C568.404175,498.035156 568.691589,498.004486 571.975159,503.989594 
	C571.968689,504.159821 571.962219,504.330048 571.914673,504.752747 
	C562.779297,504.745422 553.685181,504.485626 544.193970,504.028534 
z"/>
<path fill="#013290" opacity="1.000000" stroke="none" 
	d="
M499.542847,509.034302 
	C499.206299,509.692993 498.399445,510.899139 497.594147,510.898071 
	C480.929749,510.876190 464.265503,510.717041 447.298065,510.298767 
	C448.722748,509.677826 450.449341,509.060638 452.178711,509.052704 
	C467.809906,508.980957 483.441650,509.015900 499.542847,509.034302 
z"/>
<path fill="#35BDFA" opacity="1.000000" stroke="none" 
	d="
M392.456055,504.835083 
	C392.023712,501.684845 393.439117,499.446350 396.541229,499.409119 
	C399.165802,499.377594 401.254974,500.887970 400.816284,504.514465 
	C400.411133,504.934509 400.038300,504.981720 398.954681,505.029541 
	C396.484192,505.003296 394.724548,504.976440 392.964874,504.949615 
	C392.964874,504.949615 392.630066,504.850159 392.456055,504.835083 
z"/>
<path fill="#013290" opacity="1.000000" stroke="none" 
	d="
M520.724243,509.723206 
	C518.722412,509.923767 516.444519,509.845093 514.079834,509.402466 
	C516.144714,509.173645 518.296509,509.308807 520.724243,509.723206 
z"/>
<path fill="#064AD0" opacity="1.000000" stroke="none" 
	d="
M393.039429,505.318542 
	C394.724548,504.976440 396.484192,505.003296 398.680817,505.101624 
	C397.355682,508.136627 395.285706,507.505829 393.039429,505.318542 
z"/>
<path fill="#003498" opacity="1.000000" stroke="none" 
	d="
M403.955963,499.023560 
	C399.828583,495.484772 395.391296,494.654541 392.001038,497.571259 
	C387.516327,501.429565 382.486084,502.442902 376.429962,501.962036 
	C375.809052,501.942841 375.641937,501.917236 375.269348,501.542236 
	C375.709259,500.128143 376.354614,499.063446 377.425842,497.999390 
	C379.801910,498.314056 381.781158,498.989410 383.693787,498.835663 
	C385.441711,498.695190 388.115265,498.105713 388.642120,496.932953 
	C390.372375,493.081512 393.199554,491.956726 396.981171,491.990936 
	C400.194336,492.020050 402.074219,490.511841 401.940247,487.189789 
	C401.700684,481.248840 401.176025,475.316650 401.049683,469.374878 
	C400.977600,465.984619 399.076904,465.126678 396.287018,465.015594 
	C387.525116,464.666809 378.762390,464.337860 369.663147,463.946533 
	C368.879333,462.953766 368.432373,462.015625 368.454956,461.046326 
	C385.946991,461.043976 402.969513,461.072784 420.069275,461.437561 
	C422.775177,463.721069 425.403870,465.668640 428.650299,468.073883 
	C432.079926,461.831268 437.603912,462.640259 443.528503,463.854889 
	C446.165283,464.395447 449.622437,464.933594 451.461609,461.062256 
	C454.939331,461.057281 457.964142,461.070251 461.040680,461.450012 
	C462.046539,463.745209 463.000671,465.673645 463.954773,467.602051 
	C464.969482,466.093353 465.971191,464.575653 467.007477,463.081940 
	C467.284607,462.682404 467.667114,462.355988 468.417847,461.989563 
	C470.223358,461.988770 471.612305,461.994385 472.993286,462.380859 
	C470.314819,467.251129 470.897644,471.360016 475.117004,474.358368 
	C478.415863,476.702576 480.759583,479.158386 481.275879,483.446411 
	C481.434906,484.767242 483.743256,486.261841 485.367188,486.851807 
	C486.986298,487.440002 488.981689,486.973572 490.811523,487.004089 
	C495.678314,487.085083 498.103333,489.452332 497.842377,494.507690 
	C497.623932,498.740356 494.064819,497.948822 491.404663,497.966064 
	C479.424957,498.043793 467.444519,497.993011 455.464325,498.008728 
	C453.362946,498.011475 450.831665,497.457245 450.990601,500.945923 
	C436.641785,500.963226 422.292999,500.980530 407.413910,500.644287 
	C405.907745,499.868317 404.931854,499.445923 403.955963,499.023560 
M457.942474,479.511566 
	C461.407532,476.167603 458.687012,473.753754 456.462524,471.663361 
	C454.487823,469.807800 449.656952,471.035187 449.200043,473.165039 
	C448.339569,477.175812 452.998657,481.058441 457.942474,479.511566 
z"/>
<path fill="#01308F" opacity="1.000000" stroke="none" 
	d="
M376.999969,497.998779 
	C376.354614,499.063446 375.709259,500.128143 375.019012,501.501709 
	C374.232147,501.877808 373.490204,501.945007 372.374817,501.763611 
	C372.000946,501.342926 372.000458,501.170868 371.997833,500.597839 
	C372.422974,496.183594 368.913086,496.969666 366.894897,496.208588 
	C360.765778,493.897339 358.295349,487.957245 354.174652,483.673096 
	C353.658600,483.136597 354.011902,481.740326 354.005219,480.742126 
	C353.989624,478.409882 354.000427,476.077515 353.999908,473.745178 
	C353.998230,466.111145 353.999573,466.102142 346.389740,464.894775 
	C344.270477,464.558502 342.048035,464.565704 340.049347,463.891968 
	C338.853424,463.488861 337.999756,462.070496 337.460175,461.073669 
	C347.947205,461.051788 357.966339,461.064636 367.985443,461.077515 
	C368.432373,462.015625 368.879333,462.953766 369.664856,464.375061 
	C370.335693,470.420776 370.804077,475.979858 370.946747,481.547272 
	C371.026306,484.652374 371.248169,486.790314 375.243988,487.314758 
	C376.695618,487.505280 378.387421,490.588867 378.786957,492.588684 
	C379.110535,494.208405 377.662292,496.182098 376.999969,497.998779 
z"/>
<path fill="#011C7F" opacity="1.000000" stroke="none" 
	d="
M364.229187,508.971558 
	C364.172668,507.849426 364.375275,506.750488 364.696960,505.005859 
	C362.582245,505.005859 360.814911,505.006226 359.047577,505.005859 
	C336.959290,505.001251 314.870575,505.062073 292.783081,504.928375 
	C289.030426,504.905670 285.282867,504.047913 281.514282,503.023743 
	C297.997894,502.645630 314.500153,502.819763 331.470825,502.995667 
	C343.955383,502.998291 355.971588,502.999146 368.260620,503.004150 
	C368.715424,503.007568 368.897339,503.006836 369.079224,503.006104 
	C367.925201,504.909485 366.771149,506.812836 365.312378,508.872559 
	C364.834534,509.017578 364.661438,509.006195 364.229187,508.971558 
z"/>
<path fill="#003498" opacity="1.000000" stroke="none" 
	d="
M369.406311,503.014709 
	C368.897339,503.006836 368.715424,503.007568 368.333984,502.648682 
	C369.422974,501.858978 370.711487,501.428864 372.000000,500.998779 
	C372.000458,501.170868 372.000946,501.342926 372.002197,501.773499 
	C371.246429,502.362396 370.489899,502.692841 369.406311,503.014709 
z"/>
<path fill="#0E80D9" opacity="1.000000" stroke="none" 
	d="
M326.661041,530.724426 
	C324.701263,530.775269 322.227509,531.132568 321.536865,530.187134 
	C320.752930,529.113953 321.168030,526.687683 321.858856,525.226379 
	C322.178802,524.549561 325.433075,524.328796 325.567078,524.649414 
	C326.323456,526.459229 326.536346,528.496094 326.661041,530.724426 
z"/>
<path fill="#032A82" opacity="1.000000" stroke="none" 
	d="
M776.043152,565.276184 
	C776.502441,565.534729 776.975830,566.093628 777.229858,566.828064 
	C776.692810,566.527893 776.375000,566.052246 776.043152,565.276184 
z"/>
<path fill="#022072" opacity="1.000000" stroke="none" 
	d="
M178.956360,515.996277 
	C175.221832,519.585266 171.487320,523.174255 167.114746,526.933228 
	C166.015320,527.770386 165.553909,528.437622 165.092499,529.104858 
	C165.092499,529.104858 165.010559,528.999634 164.702774,529.066406 
	C163.934906,530.097229 163.474823,531.061218 163.014740,532.025208 
	C162.919952,532.358459 162.727966,532.620422 162.207581,532.901123 
	C160.976364,531.752502 159.976318,530.513855 158.599030,528.807983 
	C155.782990,530.775757 152.899292,532.790833 149.515839,535.155090 
	C149.458420,533.855591 149.105820,532.793152 149.443710,532.483093 
	C154.358871,527.972107 152.821854,522.071899 152.941437,516.555359 
	C152.961273,515.640747 152.672714,514.304016 152.037354,513.855469 
	C146.231506,509.756531 146.942734,502.817383 144.626892,497.217529 
	C144.396896,496.661407 144.273911,495.790466 144.548904,495.337219 
	C147.901566,489.811035 143.929260,490.364044 140.398132,489.893738 
	C137.868195,489.556793 135.278427,488.570496 133.068054,487.273071 
	C131.231018,486.194763 129.706894,484.268188 132.681259,482.424683 
	C134.584274,486.553589 137.255981,488.289856 141.515610,488.071747 
	C144.997482,487.893463 147.055420,486.373840 148.040771,483.118042 
	C148.005692,483.058868 147.953766,482.933716 148.322678,482.949463 
	C149.795929,482.641754 150.900253,482.318298 152.472748,482.010681 
	C160.980469,482.114380 169.020020,482.202271 177.162094,482.655273 
	C178.795151,483.346130 180.323029,483.938324 181.856674,483.953705 
	C190.903076,484.044342 199.950821,484.000824 208.955292,484.362366 
	C208.421799,486.366241 206.995255,488.603333 207.597977,489.554382 
	C210.664490,494.392914 208.511246,497.910645 206.106827,500.327637 
	C206.876312,503.474854 207.433746,505.754822 207.660751,508.034302 
	C206.889618,508.033661 206.448929,508.033508 205.756256,507.756775 
	C203.670410,507.967041 201.836548,508.453857 200.002686,508.940735 
	C197.962112,508.967438 195.921539,508.994141 193.388428,508.661621 
	C191.928085,508.523834 190.960251,508.745331 189.992432,508.966858 
	C189.205490,509.258148 188.418533,509.549469 187.131561,509.663300 
	C186.067795,509.969543 185.504059,510.453247 184.940308,510.936920 
	C184.457550,511.220642 183.974808,511.504364 182.911636,511.741760 
	C180.662613,512.393738 178.994003,513.091980 177.325409,513.790283 
	C177.869064,514.525574 178.412720,515.260925 178.956360,515.996277 
z"/>
<path fill="#012B84" opacity="1.000000" stroke="none" 
	d="
M148.075867,483.177185 
	C147.055420,486.373840 144.997482,487.893463 141.515610,488.071747 
	C137.255981,488.289856 134.584274,486.553589 133.029572,482.354309 
	C132.987534,481.994049 132.999496,481.998810 133.159271,481.905640 
	C133.380234,481.706726 133.518753,481.546234 133.489319,481.504639 
	C133.354706,481.314392 133.172974,481.157501 133.008148,480.988617 
	C132.728394,476.814148 133.541565,473.283722 138.630386,472.002747 
	C138.991379,471.945465 138.996246,471.974365 138.997467,472.337524 
	C138.995789,473.402618 138.992889,474.104614 138.656677,474.990784 
	C135.365616,477.443634 134.601227,480.606598 136.941223,483.230042 
	C139.576660,486.184631 143.041199,485.845154 146.328033,483.017273 
	C147.141663,483.107971 147.608765,483.142578 148.075867,483.177185 
z"/>
<path fill="#012173" opacity="1.000000" stroke="none" 
	d="
M107.254608,372.911072 
	C107.668503,370.043182 108.482826,367.149414 109.610504,364.053711 
	C109.237419,366.880432 111.961365,370.765686 107.254608,372.911072 
z"/>
<path fill="#022072" opacity="1.000000" stroke="none" 
	d="
M133.004517,481.238831 
	C133.172974,481.157501 133.354706,481.314392 133.489319,481.504639 
	C133.518753,481.546234 133.380234,481.706726 133.156799,481.900970 
	C132.996643,481.822632 132.998749,481.655823 133.004517,481.238831 
z"/>
<path fill="#01257E" opacity="1.000000" stroke="none" 
	d="
M207.991180,508.034760 
	C207.433746,505.754822 206.876312,503.474854 206.106827,500.327637 
	C208.511246,497.910645 210.664490,494.392914 207.597977,489.554382 
	C206.995255,488.603333 208.421799,486.366241 209.238861,484.220093 
	C210.046066,482.829987 210.526825,481.942017 211.231964,481.016602 
	C211.456345,480.979126 211.910675,480.955658 212.266571,480.933960 
	C215.044312,478.230743 217.466171,475.549194 219.936249,472.929504 
	C219.984467,472.991364 219.867142,472.887177 220.222595,472.893311 
	C222.062119,471.276062 223.546188,469.652649 225.369659,467.979553 
	C226.462631,466.927551 227.216217,465.925201 228.103729,464.639771 
	C228.496994,463.574860 228.756332,462.793091 229.164368,461.767334 
	C229.873901,461.018280 230.434753,460.513123 230.968475,460.097717 
	C230.941330,460.187439 230.975845,460.371979 231.049362,460.627441 
	C231.740295,460.569427 232.357712,460.255920 233.104340,459.656738 
	C233.503784,458.594604 233.774048,457.818207 234.023071,457.020294 
	C234.001831,456.998779 234.039108,457.044769 234.352661,457.023254 
	C235.429901,456.661591 236.193588,456.321442 237.245438,455.954163 
	C237.873978,455.661102 238.214340,455.395142 238.554703,455.129181 
	C238.162537,454.745514 237.770370,454.361877 237.289368,453.959290 
	C237.200562,453.940369 237.026367,453.991760 237.191193,453.731323 
	C238.010666,453.008118 238.665329,452.545349 239.668671,452.056732 
	C241.358200,451.034271 242.699051,450.037689 244.390228,448.991638 
	C246.873779,447.421753 248.862610,445.591980 251.196976,444.502563 
	C252.496033,443.896332 254.783142,443.935455 255.899689,444.718231 
	C258.180786,446.317444 260.147461,448.476013 261.898407,450.678680 
	C262.938354,451.986847 263.800232,453.787140 263.905121,455.419952 
	C264.181580,459.724731 263.510498,464.130615 264.129730,468.362671 
	C264.860687,473.358276 263.347321,479.578064 270.750458,481.848480 
	C272.337646,482.335205 273.170715,486.300964 273.735382,488.803101 
	C274.257111,491.114929 273.952911,493.613159 274.005981,496.258118 
	C273.999512,496.486938 273.949677,496.942017 273.949707,496.942017 
	C271.517273,498.863739 269.084869,500.785461 265.876923,502.855713 
	C255.852325,503.002899 246.601639,502.914978 237.355881,503.088959 
	C235.889572,503.116577 234.445480,504.324280 232.994690,504.991943 
	C232.998291,504.997467 232.986298,504.989868 232.612427,504.997223 
	C231.162720,506.317657 230.086884,507.630737 229.011047,508.943787 
	C227.916168,508.969299 226.821289,508.994873 225.315765,509.072571 
	C219.564362,509.116089 214.223587,509.107452 208.436050,508.844116 
	C207.989914,508.404541 207.990555,508.219635 207.991180,508.034760 
z"/>
<path fill="#01257E" opacity="1.000000" stroke="none" 
	d="
M179.272400,515.875000 
	C178.412720,515.260925 177.869064,514.525574 177.325409,513.790283 
	C178.994003,513.091980 180.662613,512.393738 182.647690,511.835144 
	C181.838928,513.234497 180.713669,514.494141 179.272400,515.875000 
z"/>
<path fill="#01257E" opacity="1.000000" stroke="none" 
	d="
M200.259186,509.217743 
	C201.836548,508.453857 203.670410,507.967041 205.744659,508.061249 
	C205.946091,509.045868 205.907166,509.449432 205.868225,509.853027 
	C204.084045,509.733612 202.299850,509.614197 200.259186,509.217743 
z"/>
<path fill="#01257E" opacity="1.000000" stroke="none" 
	d="
M190.368622,509.005798 
	C190.960251,508.745331 191.928085,508.523834 192.952301,508.627045 
	C192.254059,508.982758 191.499435,509.013763 190.368622,509.005798 
z"/>
<path fill="#01257E" opacity="1.000000" stroke="none" 
	d="
M163.306992,531.891846 
	C163.474823,531.061218 163.934906,530.097229 164.751434,529.112244 
	C164.604996,529.980347 164.102112,530.869385 163.306992,531.891846 
z"/>
<path fill="#033FA9" opacity="1.000000" stroke="none" 
	d="
M185.253296,510.934235 
	C185.504059,510.453247 186.067795,509.969543 186.816116,509.688354 
	C186.522552,510.237762 186.044403,510.584656 185.253296,510.934235 
z"/>
<path fill="#033FA9" opacity="1.000000" stroke="none" 
	d="
M206.168320,509.892029 
	C205.907166,509.449432 205.946091,509.045868 205.996628,508.337799 
	C206.448929,508.033508 206.889618,508.033661 207.660751,508.034302 
	C207.990555,508.219635 207.989914,508.404541 207.983368,508.868103 
	C207.474426,509.408142 206.971420,509.669556 206.168320,509.892029 
z"/>
<path fill="#01257E" opacity="1.000000" stroke="none" 
	d="
M165.410980,529.059814 
	C165.553909,528.437622 166.015320,527.770386 166.822235,527.089478 
	C166.688324,527.722107 166.208908,528.368469 165.410980,529.059814 
z"/>
<path fill="#001A66" opacity="1.000000" stroke="none" 
	d="
M130.375641,540.449219 
	C129.942902,540.337036 129.680496,540.061951 129.418091,539.786865 
	C129.531982,539.678284 129.645874,539.569702 129.759766,539.461121 
	C130.021820,539.736206 130.283905,540.011230 130.375641,540.449219 
z"/>
<path fill="#35BDFA" opacity="1.000000" stroke="none" 
	d="
M407.034485,43.043480 
	C406.040771,44.615612 405.138092,45.997856 403.868469,46.797493 
	C403.526062,47.013157 401.512573,45.601406 401.280548,44.680931 
	C401.043610,43.741199 402.003845,41.573788 402.528229,41.544899 
	C403.990265,41.464348 405.498016,42.213314 407.034485,43.043480 
z"/>
<path fill="#01116C" opacity="1.000000" stroke="none" 
	d="
M927.995850,404.526978 
	C927.642029,404.989532 927.287537,404.983948 926.551514,404.528137 
	C925.890015,396.136536 925.609985,388.195160 925.650696,380.118958 
	C926.645264,377.987000 927.319092,375.989868 928.461304,373.995575 
	C938.622986,374.339844 948.316406,374.681213 958.009766,375.022644 
	C957.526001,376.032318 957.042175,377.042023 956.394104,378.394531 
	C962.042114,380.937622 967.506226,380.596130 972.993286,378.022003 
	C974.001038,377.549225 975.130066,377.334930 976.597534,376.998230 
	C972.649109,382.766663 966.521240,382.097931 960.412170,381.663666 
	C957.478088,381.455048 954.526123,381.497925 951.292419,381.710632 
	C943.508606,381.994354 936.014587,381.994354 927.995178,381.994354 
	C927.995178,389.278717 927.995178,396.668762 927.995850,404.526978 
z"/>
<path fill="#011B6D" opacity="1.000000" stroke="none" 
	d="
M1012.652466,368.946899 
	C1012.992004,369.172577 1012.998718,369.342621 1013.021118,369.767822 
	C1011.893982,371.259094 1010.751099,372.495270 1009.304810,373.867889 
	C1007.594116,374.604309 1005.983337,374.950653 1004.834656,375.872620 
	C1003.583374,376.876801 1002.973083,378.720856 1001.680420,379.618561 
	C1000.739441,380.272156 999.144165,379.983795 997.840576,380.115356 
	C998.409668,379.069580 998.994568,378.032043 999.544373,376.976257 
	C1000.733154,374.693298 1001.903870,372.400940 1003.710510,368.891296 
	C1005.734924,368.891296 1009.027283,368.891296 1012.652466,368.946899 
z"/>
<path fill="#011B6D" opacity="1.000000" stroke="none" 
	d="
M983.345581,370.964172 
	C984.439392,369.358856 985.876343,367.716522 987.656616,366.037720 
	C986.562927,367.643188 985.125793,369.285126 983.345581,370.964172 
z"/>
<path fill="#011B6D" opacity="1.000000" stroke="none" 
	d="
M992.291077,362.849609 
	C993.092346,361.356262 994.192871,359.718414 995.646729,358.040283 
	C994.863464,359.568390 993.726929,361.136749 992.291077,362.849609 
z"/>
<path fill="#011B6D" opacity="1.000000" stroke="none" 
	d="
M998.338989,355.963196 
	C998.773254,355.024292 999.546570,354.048615 1000.658691,353.036255 
	C1000.224426,353.975189 999.451233,354.950775 998.338989,355.963196 
z"/>
<path fill="#011B6D" opacity="1.000000" stroke="none" 
	d="
M977.320557,376.953186 
	C977.454956,376.360779 977.907471,375.723328 978.678101,375.040649 
	C978.543762,375.632965 978.091248,376.270599 977.320557,376.953186 
z"/>
<path fill="#02257F" opacity="1.000000" stroke="none" 
	d="
M958.340942,375.014709 
	C948.316406,374.681213 938.622986,374.339844 928.320312,373.714844 
	C924.923523,371.716827 922.251160,369.764984 919.321655,368.343140 
	C914.821106,366.158813 910.428345,362.472504 904.648804,366.198547 
	C903.887634,366.217834 903.449951,366.134521 902.738098,365.759033 
	C896.738098,364.846802 889.937195,366.935547 886.760986,359.689606 
	C885.545593,356.916901 884.915955,353.887390 884.433044,350.957733 
	C888.427979,350.851044 892.027710,350.949280 895.583313,350.599060 
	C897.598389,350.400574 900.105042,350.049500 901.426392,348.770966 
	C911.506226,339.017273 921.372253,329.041595 931.255127,319.085724 
	C936.538757,313.763092 941.724060,308.342834 947.302490,302.944061 
	C949.421814,300.937103 951.192505,298.952759 952.981567,296.984192 
	C953.000000,297.000000 952.968567,296.963287 953.315430,296.939087 
	C955.116150,296.287994 956.570068,295.661163 957.978943,295.378082 
	C957.614197,297.491241 957.294434,299.260620 956.974365,301.271698 
	C956.974121,301.513397 956.956970,301.997253 956.644043,302.177063 
	C953.539795,305.237793 953.259033,308.517853 955.015198,311.966919 
	C956.398132,314.682892 956.378235,316.489685 952.998901,317.673767 
	C951.755005,318.109589 950.394653,319.843658 950.155029,321.177399 
	C949.651306,323.980621 949.674866,327.208435 953.513550,327.787903 
	C957.629150,328.409210 960.914001,325.741058 960.978821,322.054199 
	C961.048157,318.112396 961.096313,314.237427 965.935425,313.392120 
	C964.988770,318.160095 964.369324,322.612183 963.048767,326.845612 
	C962.540833,328.474030 960.635376,330.421997 959.023560,330.814087 
	C953.353333,332.193604 951.376770,333.964020 951.039307,339.680481 
	C950.872803,342.501129 950.587463,345.428009 951.157837,348.138062 
	C951.522766,349.871643 953.353271,351.296753 954.526794,352.860168 
	C955.682861,351.278717 957.524414,349.823547 957.843994,348.088074 
	C958.408142,345.024872 957.718750,341.739136 958.208618,338.650330 
	C958.448669,337.137146 959.928284,335.477417 961.306519,334.643768 
	C961.924011,334.270203 963.904480,335.522247 964.862976,336.452332 
	C968.341309,339.827576 967.614014,342.013062 963.516113,344.522003 
	C961.810669,345.566193 960.585999,348.301178 960.328308,350.412018 
	C960.204834,351.424469 963.182251,353.994019 964.154419,353.720764 
	C966.301025,353.117340 968.148499,351.255493 969.959778,349.720337 
	C971.209595,348.660950 971.958984,346.760223 973.337646,346.146149 
	C980.048645,343.157013 985.829773,339.521515 987.796143,331.700378 
	C988.283142,329.763306 989.783020,327.167664 991.393494,326.642853 
	C993.738281,325.878754 997.131531,325.897095 999.074768,327.144348 
	C1000.501526,328.060089 1001.162354,331.730164 1000.579468,333.704865 
	C1000.141479,335.188477 997.247253,336.058594 995.334229,336.964539 
	C993.340576,337.908661 990.548035,337.987061 989.261963,339.456635 
	C984.617615,344.763733 980.288269,350.373718 976.178467,356.111786 
	C973.343201,360.070343 971.147278,364.481903 968.469116,368.562073 
	C967.955139,369.345093 966.505127,369.958771 965.523254,369.910553 
	C961.825439,369.729034 960.521729,371.868378 959.997559,374.989014 
	C959.555786,374.994934 959.113953,375.000854 958.340942,375.014709 
z"/>
<path fill="#002173" opacity="1.000000" stroke="none" 
	d="
M960.442871,374.994751 
	C960.521729,371.868378 961.825439,369.729034 965.523254,369.910553 
	C966.505127,369.958771 967.955139,369.345093 968.469116,368.562073 
	C971.147278,364.481903 973.343201,360.070343 976.178467,356.111786 
	C980.288269,350.373718 984.617615,344.763733 989.261963,339.456635 
	C990.548035,337.987061 993.340576,337.908661 995.334229,336.964539 
	C997.247253,336.058594 1000.141479,335.188477 1000.579468,333.704865 
	C1001.162354,331.730164 1000.501526,328.060089 999.074768,327.144348 
	C997.131531,325.897095 993.738281,325.878754 991.393494,326.642853 
	C989.783020,327.167664 988.283142,329.763306 987.796143,331.700378 
	C985.829773,339.521515 980.048645,343.157013 973.337646,346.146149 
	C971.958984,346.760223 971.209595,348.660950 969.959778,349.720337 
	C968.148499,351.255493 966.301025,353.117340 964.154419,353.720764 
	C963.182251,353.994019 960.204834,351.424469 960.328308,350.412018 
	C960.585999,348.301178 961.810669,345.566193 963.516113,344.522003 
	C967.614014,342.013062 968.341309,339.827576 964.862976,336.452332 
	C963.904480,335.522247 961.924011,334.270203 961.306519,334.643768 
	C959.928284,335.477417 958.448669,337.137146 958.208618,338.650330 
	C957.718750,341.739136 958.408142,345.024872 957.843994,348.088074 
	C957.524414,349.823547 955.682861,351.278717 954.526794,352.860168 
	C953.353271,351.296753 951.522766,349.871643 951.157837,348.138062 
	C950.587463,345.428009 950.872803,342.501129 951.039307,339.680481 
	C951.376770,333.964020 953.353333,332.193604 959.023560,330.814087 
	C960.635376,330.421997 962.540833,328.474030 963.048767,326.845612 
	C964.369324,322.612183 964.988770,318.160095 965.939087,313.021301 
	C965.991028,311.505157 965.991882,310.762970 966.243896,310.014648 
	C966.662537,310.006500 966.830017,310.004486 967.374084,309.995178 
	C969.158813,308.860474 971.375183,307.983765 971.817871,306.556793 
	C972.970520,302.841339 973.345032,298.884491 974.411194,295.011169 
	C976.202148,294.691498 977.611816,294.384644 979.403564,294.074341 
	C981.217590,293.780609 982.649597,293.490326 984.477539,293.173279 
	C986.200684,293.178223 987.528015,293.209930 988.942261,293.536255 
	C989.705688,294.179565 990.382263,294.528229 991.373230,294.890747 
	C992.106934,294.889679 992.526245,294.874756 993.417542,294.904816 
	C1005.266846,294.954834 1016.644104,294.959900 1028.243164,294.992493 
	C1028.464966,295.019958 1028.908936,295.073425 1028.963867,295.418701 
	C1033.000854,299.516602 1036.982910,303.269257 1040.965088,307.021881 
	C1040.965088,307.021881 1040.960205,307.519165 1040.947266,307.767700 
	C1035.907104,307.337097 1032.601685,309.633118 1029.726929,313.647827 
	C1027.525757,316.721893 1024.414673,319.142334 1021.721924,321.866974 
	C1015.880493,327.777649 1010.074951,333.724060 1004.203247,339.604401 
	C999.427795,344.386902 994.493408,349.013000 989.784973,353.859528 
	C985.556641,358.211914 981.484131,362.720245 977.445129,367.250916 
	C973.012085,372.223480 968.309814,376.477661 960.442871,374.994751 
z"/>
<path fill="#011D6F" opacity="1.000000" stroke="none" 
	d="
M1064.990234,307.003815 
	C1061.559814,307.005768 1058.107300,306.779480 1054.708984,307.110931 
	C1053.131470,307.264771 1051.659790,308.503235 1050.056396,309.622284 
	C1048.218506,308.341461 1046.464600,306.688446 1044.071045,304.840332 
	C1039.986084,301.071686 1036.540894,297.498169 1033.028564,293.583496 
	C1030.961670,291.841614 1028.961914,290.440857 1026.496094,289.030975 
	C1018.024292,288.984100 1010.018677,288.946381 1001.951050,288.538818 
	C1000.927490,287.454651 1000.065186,286.459045 998.986023,286.078552 
	C995.698120,284.919403 992.326721,283.997437 989.044861,282.647217 
	C991.527405,280.132507 994.160889,278.141296 996.332031,275.733307 
	C1000.045044,271.615326 1003.468567,267.236267 1007.272522,263.065277 
	C1008.018311,263.425934 1008.505127,263.688202 1008.994873,264.362061 
	C1009.203064,266.906433 1008.328674,269.988586 1011.917175,269.584808 
	C1012.737732,269.492462 1013.239197,266.564270 1014.014160,264.732178 
	C1014.350647,264.266632 1014.604614,264.066254 1015.299500,263.937714 
	C1018.304871,264.222595 1021.785889,263.512329 1023.374573,264.927032 
	C1031.097168,271.803986 1038.351196,279.211700 1045.696899,286.506195 
	C1052.183716,292.947723 1058.572754,299.487762 1065.001953,306.239197 
	C1064.995972,306.664001 1064.993042,306.833923 1064.990234,307.003815 
z"/>
<path fill="#032CA7" opacity="1.000000" stroke="none" 
	d="
M1033.095581,293.924622 
	C1036.540894,297.498169 1039.986084,301.071686 1043.731201,304.796387 
	C1043.249023,305.630890 1042.467041,306.314270 1041.325073,307.009766 
	C1036.982910,303.269257 1033.000854,299.516602 1029.276367,295.371063 
	C1030.033691,294.645844 1030.533569,294.313538 1031.376465,293.982208 
	C1032.178345,293.963684 1032.636963,293.944153 1033.095581,293.924622 
z"/>
<path fill="#01257E" opacity="1.000000" stroke="none" 
	d="
M938.820984,103.290611 
	C940.131165,102.081329 941.279358,101.176521 942.720215,100.506897 
	C943.872864,103.486008 944.633240,106.321022 948.350952,106.833038 
	C948.475037,105.267952 948.326721,103.918671 948.761597,102.795166 
	C949.047424,102.056786 950.216309,101.147446 950.915344,101.205818 
	C951.887329,101.286995 953.610107,102.228676 953.593445,102.757240 
	C953.444824,107.462196 957.401184,108.909943 959.885071,111.657974 
	C961.582947,113.536430 961.977539,116.592934 962.618347,119.133911 
	C961.867432,118.980682 961.453064,118.816322 961.032349,118.345657 
	C959.993835,117.716179 958.961670,117.393005 957.697083,117.051254 
	C957.464661,117.032669 956.999756,116.997841 956.966003,116.641045 
	C955.156921,114.238319 953.620789,111.897652 951.549316,110.216537 
	C948.559570,107.790207 946.222290,108.949745 945.021118,113.017471 
	C945.000977,113.000366 945.034119,113.041183 944.640259,113.031586 
	C940.887146,113.195648 940.821228,115.459785 941.164490,117.934677 
	C941.963562,123.695625 947.022461,124.186401 951.311890,125.939957 
	C951.541504,125.972290 952.005188,125.975716 952.030273,126.337021 
	C953.006226,127.509399 953.957092,128.320480 955.160217,129.246033 
	C955.768982,129.454865 956.125488,129.549194 956.625427,129.913788 
	C957.509521,130.482895 958.250244,130.781738 959.196411,131.305695 
	C960.599854,131.362366 961.797729,131.193939 962.995667,131.025513 
	C959.260498,137.573196 958.876892,144.777939 959.035095,152.038727 
	C959.129822,156.387238 959.629333,160.725433 959.837708,165.073502 
	C959.997131,168.397415 961.924561,169.081772 964.831970,169.073517 
	C966.493530,169.068787 968.157166,169.803925 969.661621,170.601105 
	C969.331970,171.002075 969.160645,171.007874 968.521790,171.010651 
	C948.704407,171.001984 929.354675,170.996338 910.004883,170.990707 
	C908.197327,166.497910 905.335022,163.921249 900.034424,163.903061 
	C889.333801,163.866333 878.635010,163.330292 867.466492,162.999191 
	C867.127563,157.680511 871.382629,158.522247 874.599976,158.042603 
	C877.884460,157.552917 881.217468,157.388977 885.744934,156.969940 
	C884.167114,155.623566 883.818665,155.350739 883.498230,155.048325 
	C882.412354,154.023697 881.336426,152.988586 880.256714,151.957458 
	C881.794617,151.305222 883.307739,150.584991 884.875244,150.014008 
	C888.871338,148.558395 892.891602,147.169418 896.953247,145.388397 
	C904.804871,137.119751 912.557068,129.167877 920.421814,121.328972 
	C926.427063,115.343536 932.574768,109.501122 938.820984,103.290611 
z"/>
<path fill="#022590" opacity="1.000000" stroke="none" 
	d="
M968.989380,171.013687 
	C969.160645,171.007874 969.331970,171.002075 969.760376,170.982819 
	C987.696899,170.981812 1005.376465,170.994247 1023.523804,171.006927 
	C1024.189575,172.106567 1024.387695,173.205978 1024.587769,174.315735 
	C1019.304199,174.962631 1014.245728,175.581970 1008.617676,176.594208 
	C995.365417,176.992218 982.682678,176.997330 969.545715,176.999695 
	C966.055176,176.988647 963.018982,176.980347 960.082153,176.589539 
	C963.245117,175.584167 966.308655,174.961304 969.587769,174.294632 
	C969.389587,173.207901 969.189514,172.110794 968.989380,171.013687 
z"/>
<path fill="#0D65C3" opacity="1.000000" stroke="none" 
	d="
M1003.979675,122.849716 
	C1006.568176,123.401161 1009.156738,123.952621 1011.635193,124.480629 
	C1010.976440,124.925697 1010.039612,125.558640 1008.561401,126.554932 
	C1003.332153,126.913429 998.644226,126.908577 993.702148,126.651611 
	C991.635681,126.594315 989.823486,126.789116 988.011230,126.983910 
	C984.625916,127.034386 981.240540,127.084869 977.394775,126.932419 
	C976.942993,126.549347 976.951538,126.369217 977.208557,125.873245 
	C979.968628,124.697281 982.480347,123.837151 985.458618,122.921036 
	C991.943359,122.859940 997.961487,122.854828 1003.979675,122.849716 
z"/>
<path fill="#0E8ED8" opacity="1.000000" stroke="none" 
	d="
M984.992004,122.977020 
	C982.480347,123.837151 979.968628,124.697281 976.765320,125.860168 
	C972.303223,126.825165 967.766541,124.334953 964.937317,128.869690 
	C964.937317,128.869690 964.447449,128.923843 963.865234,128.947067 
	C962.830688,128.955154 962.378296,128.940002 962.000244,128.596375 
	C962.958862,126.501053 963.843140,124.734230 965.182861,122.984184 
	C969.770325,122.964905 973.902466,122.928848 978.469421,122.882568 
	C980.933533,122.907242 982.962769,122.942131 984.992004,122.977020 
z"/>
<path fill="#01257E" opacity="1.000000" stroke="none" 
	d="
M993.956299,126.903717 
	C998.644226,126.908577 1003.332153,126.913429 1008.478760,126.877159 
	C1010.932068,127.277725 1012.926819,127.719421 1015.061707,128.517822 
	C1008.325989,128.766953 1001.450012,128.659409 994.273315,127.990494 
	C993.967102,127.253990 993.961670,127.078850 993.956299,126.903717 
z"/>
<path fill="#01257E" opacity="1.000000" stroke="none" 
	d="
M965.187439,129.170776 
	C967.766541,124.334953 972.303223,126.825165 976.516907,126.176010 
	C976.951538,126.369217 976.942993,126.549347 976.937988,127.002296 
	C973.106934,128.007355 969.272278,128.739609 965.187439,129.170776 
z"/>
<path fill="#0946BC" opacity="1.000000" stroke="none" 
	d="
M993.702148,126.651611 
	C993.961670,127.078850 993.967102,127.253990 993.979309,127.691383 
	C992.171387,127.813660 990.356812,127.673683 988.276733,127.258812 
	C989.823486,126.789116 991.635681,126.594315 993.702148,126.651611 
z"/>
<path fill="#0D65C3" opacity="1.000000" stroke="none" 
	d="
M961.925964,128.924881 
	C962.378296,128.940002 962.830688,128.955154 963.621704,128.984924 
	C963.787354,129.545212 963.614258,130.090881 963.218384,130.831024 
	C961.797729,131.193939 960.599854,131.362366 959.197876,131.008392 
	C958.994995,130.288193 958.996155,130.090378 959.322815,129.886490 
	C960.407593,129.561920 961.166809,129.243408 961.925964,128.924881 
z"/>
<path fill="#002173" opacity="1.000000" stroke="none" 
	d="
M970.000000,177.002441 
	C982.682678,176.997330 995.365417,176.992218 1008.514648,176.985184 
	C1010.701965,176.989975 1012.422668,176.996674 1014.560547,177.426636 
	C1014.651733,180.948853 1014.462585,184.070114 1013.960999,187.140366 
	C1013.474182,190.120621 1012.666687,193.048508 1011.621216,196.087311 
	C1008.969666,199.718353 1008.942383,202.289185 1013.804260,203.570099 
	C1015.082703,203.906921 1015.950745,205.801544 1016.537842,206.988480 
	C1002.707214,207.003418 989.346069,207.010635 975.520142,207.016846 
	C968.039856,207.330383 961.024353,207.644943 953.893860,207.749115 
	C953.575012,207.279419 953.317810,207.094070 953.002930,206.569916 
	C952.267212,200.203232 947.610779,204.180222 945.086182,202.612885 
	C946.995911,201.859421 948.820679,201.149445 950.631775,201.182922 
	C954.738159,201.258865 958.848267,202.021194 962.936646,201.887146 
	C965.910400,201.789612 968.851746,200.704468 971.807556,200.059647 
	C971.732422,199.573639 971.657288,199.087646 971.582153,198.601639 
	C970.549377,198.172913 969.533447,197.425446 968.480957,197.369781 
	C963.508911,197.106827 962.948486,195.598404 964.261841,190.473160 
	C965.534546,185.506577 965.978271,180.641663 970.000000,177.002441 
z"/>
<path fill="#011B6C" opacity="1.000000" stroke="none" 
	d="
M1017.007263,206.980774 
	C1015.950745,205.801544 1015.082703,203.906921 1013.804260,203.570099 
	C1008.942383,202.289185 1008.969666,199.718353 1012.046753,196.085999 
	C1017.430176,195.972778 1022.012512,196.039764 1026.586426,195.875534 
	C1028.189209,195.817978 1030.163818,195.652496 1031.267212,194.712692 
	C1032.757080,193.443649 1033.570068,191.379684 1034.673462,189.656830 
	C1032.749146,189.298950 1030.732666,188.407837 1028.923950,188.719910 
	C1026.727417,189.098892 1024.114868,191.275650 1023.175110,187.884674 
	C1022.530823,185.559891 1023.414307,182.726288 1023.961487,180.195038 
	C1024.210571,179.042358 1025.297485,178.070755 1026.004395,177.016998 
	C1027.714966,177.014481 1029.425537,177.011978 1031.604736,177.354706 
	C1035.047241,180.134079 1038.021240,182.568253 1040.995117,185.002441 
	C1040.995117,185.002441 1041.000000,185.499634 1041.037842,186.091873 
	C1042.715454,188.123337 1044.355225,189.562546 1045.995117,191.001770 
	C1049.899170,194.805969 1053.803223,198.610184 1057.851807,202.715500 
	C1055.050293,206.148849 1052.132812,209.309036 1049.142822,212.398788 
	C1048.029175,213.549606 1046.745239,214.535690 1044.977051,215.655060 
	C1043.283325,215.140305 1042.151367,214.567352 1041.019531,213.994400 
	C1040.430298,213.181961 1039.841187,212.369522 1039.115112,210.905136 
	C1036.980591,209.172836 1034.983032,208.092468 1032.985352,207.012115 
	C1032.238281,207.008728 1031.491211,207.005325 1030.184692,206.756592 
	C1028.750122,206.670853 1027.875122,206.830444 1026.545898,206.992218 
	C1023.063721,206.989838 1020.035461,206.985306 1017.007263,206.980774 
z"/>
<path fill="#011D6F" opacity="1.000000" stroke="none" 
	d="
M1025.545898,177.011871 
	C1025.297485,178.070755 1024.210571,179.042358 1023.961487,180.195038 
	C1023.414307,182.726288 1022.530823,185.559891 1023.175110,187.884674 
	C1024.114868,191.275650 1026.727417,189.098892 1028.923950,188.719910 
	C1030.732666,188.407837 1032.749146,189.298950 1034.673462,189.656830 
	C1033.570068,191.379684 1032.757080,193.443649 1031.267212,194.712692 
	C1030.163818,195.652496 1028.189209,195.817978 1026.586426,195.875534 
	C1022.012512,196.039764 1017.430176,195.972778 1012.425537,195.998077 
	C1012.666687,193.048508 1013.474182,190.120621 1013.960999,187.140366 
	C1014.462585,184.070114 1014.651733,180.948853 1014.989990,177.428665 
	C1018.364136,177.007202 1021.725830,177.006973 1025.545898,177.011871 
z"/>
<path fill="#012073" opacity="1.000000" stroke="none" 
	d="
M1040.943115,184.666077 
	C1038.021240,182.568253 1035.047241,180.134079 1032.032227,177.356827 
	C1034.957764,179.452408 1037.924438,181.891083 1040.943115,184.666077 
z"/>
<path fill="#012073" opacity="1.000000" stroke="none" 
	d="
M1045.959351,190.658325 
	C1044.355225,189.562546 1042.715454,188.123337 1041.040283,186.340454 
	C1042.644409,187.436157 1044.284058,188.875519 1045.959351,190.658325 
z"/>
<path fill="#002173" opacity="1.000000" stroke="none" 
	d="
M1041.057129,214.348938 
	C1042.151367,214.567352 1043.283325,215.140305 1044.707520,215.856628 
	C1044.997559,216.169281 1044.995117,216.338562 1044.979492,216.761627 
	C1043.675537,216.244766 1042.385132,215.474121 1041.057129,214.348938 
z"/>
<path fill="#000B41" opacity="1.000000" stroke="none" 
	d="
M1073.372314,200.446442 
	C1072.943481,200.335022 1072.680786,200.064194 1072.438477,199.776199 
	C1072.432251,199.768799 1072.649414,199.573456 1072.762329,199.465790 
	C1073.021973,199.738785 1073.281616,200.011765 1073.372314,200.446442 
z"/>
<path fill="#022072" opacity="1.000000" stroke="none" 
	d="
M985.458618,122.921036 
	C982.962769,122.942131 980.933533,122.907242 978.287720,122.651550 
	C977.117859,122.240913 976.564514,122.051086 975.949219,121.549149 
	C974.921143,121.437508 973.954956,121.637978 972.592590,121.884872 
	C971.134644,121.902458 970.072876,121.873611 968.951538,121.535271 
	C967.590698,121.031082 966.289551,120.836380 964.730469,120.718071 
	C964.312195,120.874733 964.151794,120.955009 963.844849,120.802841 
	C963.450378,120.087860 963.202576,119.605309 962.954834,119.122757 
	C961.977539,116.592934 961.582947,113.536430 959.885071,111.657974 
	C957.401184,108.909943 953.444824,107.462196 953.593445,102.757240 
	C953.610107,102.228676 951.887329,101.286995 950.915344,101.205818 
	C950.216309,101.147446 949.047424,102.056786 948.761597,102.795166 
	C948.326721,103.918671 948.475037,105.267952 948.350952,106.833038 
	C944.633240,106.321022 943.872864,103.486008 943.003906,100.370262 
	C943.443359,99.359009 943.891602,98.719597 944.944702,98.316032 
	C953.365540,99.035507 961.181580,99.519127 969.095825,100.395630 
	C970.688538,101.192299 972.177795,101.920784 973.678467,101.945053 
	C981.128540,102.065506 988.583740,101.904160 996.031921,102.072762 
	C997.746155,102.111557 999.439758,103.058014 1001.142883,103.586777 
	C1001.063049,104.136635 1000.983276,104.686493 1000.903442,105.236343 
	C999.435547,105.840706 997.696106,106.146194 996.591187,107.150101 
	C995.798645,107.870224 995.178833,110.166359 995.643738,110.655594 
	C998.297913,113.449142 1001.339478,115.869972 1004.164734,118.508102 
	C1005.228577,119.501495 1006.065613,120.737778 1007.006042,121.863319 
	C1006.152100,122.040154 1005.298096,122.216995 1004.211914,122.621780 
	C997.961487,122.854828 991.943359,122.859940 985.458618,122.921036 
z"/>
<path fill="#011D6F" opacity="1.000000" stroke="none" 
	d="
M1007.468262,121.908386 
	C1006.065613,120.737778 1005.228577,119.501495 1004.164734,118.508102 
	C1001.339478,115.869972 998.297913,113.449142 995.643738,110.655594 
	C995.178833,110.166359 995.798645,107.870224 996.591187,107.150101 
	C997.696106,106.146194 999.435547,105.840706 1000.903442,105.236343 
	C1000.983276,104.686493 1001.063049,104.136635 1001.142883,103.586777 
	C999.439758,103.058014 997.746155,102.111557 996.031921,102.072762 
	C988.583740,101.904160 981.128540,102.065506 973.678467,101.945053 
	C972.177795,101.920784 970.688538,101.192299 969.564697,100.394623 
	C980.291931,99.998833 990.648376,99.996910 1001.004883,99.994987 
	C1003.044739,99.995667 1005.084534,99.996338 1007.561401,99.996742 
	C1012.578674,100.997612 1017.138428,102.111908 1021.748535,102.949028 
	C1023.847961,103.330246 1026.109863,102.868698 1028.186279,103.312233 
	C1031.600952,104.041649 1033.239990,106.652321 1031.469849,109.618126 
	C1029.358276,113.155678 1026.250366,116.094490 1023.616150,119.326935 
	C1022.969421,120.120499 1022.485779,121.047028 1021.927124,121.912392 
	C1022.187439,122.299324 1022.447754,122.686256 1022.708069,123.073189 
	C1024.189331,122.503189 1025.632568,121.775703 1027.162354,121.406647 
	C1028.753052,121.022911 1030.427368,120.985535 1032.065063,120.796707 
	C1031.690674,122.110802 1031.463745,123.491814 1030.905518,124.722466 
	C1030.163330,126.358337 1029.162720,127.877014 1028.105225,129.766541 
	C1027.091431,129.570053 1025.861694,129.258011 1025.459351,128.504288 
	C1021.514709,121.115540 1014.673584,121.705902 1007.468262,121.908386 
z"/>
<path fill="#0433B7" opacity="1.000000" stroke="none" 
	d="
M1039.969604,149.648529 
	C1038.363281,148.560455 1036.716675,147.129944 1035.019409,145.360443 
	C1036.622192,146.449646 1038.275879,147.877853 1039.969604,149.648529 
z"/>
<path fill="#01196A" opacity="1.000000" stroke="none" 
	d="
M1000.905884,99.602966 
	C990.648376,99.996910 980.291931,99.998833 969.466492,100.001747 
	C961.181580,99.519127 953.365540,99.035507 945.259888,98.265343 
	C946.759155,96.404739 948.548035,94.830673 951.086792,93.123749 
	C958.759216,92.995476 965.681885,92.976883 972.604248,93.013031 
	C977.024353,93.036118 981.523010,93.694695 985.845581,93.079170 
	C992.483276,92.133980 999.025879,90.441536 1005.544312,88.808655 
	C1006.739380,88.509277 1008.657776,86.375122 1008.504456,86.115982 
	C1005.791870,81.530914 1009.452393,78.519600 1011.002441,75.012100 
	C1015.358521,75.002144 1019.714600,74.992195 1024.535400,74.974564 
	C1025.000000,74.966881 1024.997925,74.994102 1025.036133,75.357620 
	C1026.088135,77.041550 1027.101685,78.361969 1028.115356,79.682396 
	C1029.745239,78.124268 1031.375000,76.566139 1033.004883,75.008011 
	C1039.688354,75.003258 1046.371948,74.998505 1053.522827,74.997360 
	C1055.558594,77.943489 1057.007690,80.959755 1058.756958,83.790497 
	C1059.411743,84.850044 1061.043091,85.293640 1061.738647,86.343170 
	C1062.925537,88.133827 1063.724976,90.181168 1064.687256,92.120689 
	C1062.747314,92.476524 1060.812744,93.106934 1058.866577,93.145294 
	C1041.267090,93.492149 1023.664368,93.676048 1006.065308,94.042053 
	C1000.110962,94.165878 994.162537,94.642319 988.216614,95.036125 
	C987.199463,95.103493 986.208557,95.567368 985.205505,95.848068 
	C985.183289,96.298920 985.161011,96.749771 985.138733,97.200623 
	C986.695190,97.467010 988.243896,97.797211 989.809631,97.987000 
	C993.471069,98.430817 997.140686,98.807236 1000.905884,99.602966 
z"/>
<path fill="#012072" opacity="1.000000" stroke="none" 
	d="
M1032.560059,75.004623 
	C1031.375000,76.566139 1029.745239,78.124268 1028.115356,79.682396 
	C1027.101685,78.361969 1026.088135,77.041550 1025.033203,75.369598 
	C1027.366333,75.012451 1029.740723,75.006844 1032.560059,75.004623 
z"/>
<path fill="#011D6F" opacity="1.000000" stroke="none" 
	d="
M1042.712891,135.289017 
	C1037.800293,137.984406 1037.453979,132.564423 1034.883423,131.064941 
	C1034.330444,130.742340 1034.802734,128.662109 1034.802734,127.391533 
	C1035.247559,127.348618 1035.692261,127.305702 1036.137085,127.262779 
	C1037.254150,128.759308 1038.371216,130.255829 1039.864136,132.255722 
	C1040.413330,131.071152 1040.595215,130.309921 1041.049194,129.783676 
	C1041.781982,128.934357 1042.694092,128.239822 1043.530396,127.479935 
	C1043.635864,128.557510 1043.918823,129.654526 1043.803467,130.707886 
	C1043.646484,132.140625 1043.185059,133.539993 1042.712891,135.289017 
z"/>
<path fill="#011D6F" opacity="1.000000" stroke="none" 
	d="
M1049.646240,145.564270 
	C1051.170166,145.535950 1052.434570,145.760376 1053.698975,145.984802 
	C1053.476807,146.684509 1053.254517,147.384216 1053.032227,148.083908 
	C1051.817139,147.328293 1050.602051,146.572662 1049.646240,145.564270 
z"/>
<path fill="#011D6F" opacity="1.000000" stroke="none" 
	d="
M1055.751953,149.201721 
	C1056.331787,149.773666 1056.663452,150.549530 1056.997559,151.662384 
	C1056.501343,151.134796 1056.002686,150.270203 1055.751953,149.201721 
z"/>
<path fill="#011D6F" opacity="1.000000" stroke="none" 
	d="
M1074.000488,165.134033 
	C1074.000732,163.966660 1074.000732,163.176208 1074.000732,162.385757 
	C1074.207031,162.370193 1074.413330,162.354645 1074.619629,162.339081 
	C1074.619629,164.454254 1074.619629,166.569412 1074.619629,168.684570 
	C1074.498047,168.702957 1074.376465,168.721329 1074.254883,168.739716 
	C1074.170044,167.663452 1074.085083,166.587204 1074.000488,165.134033 
z"/>
<path fill="#022590" opacity="1.000000" stroke="none" 
	d="
M1065.004883,305.984314 
	C1058.572754,299.487762 1052.183716,292.947723 1045.696899,286.506195 
	C1038.351196,279.211700 1031.097168,271.803986 1023.374573,264.927032 
	C1021.785889,263.512329 1018.304871,264.222595 1015.321167,263.576721 
	C1014.977722,262.474518 1015.009094,261.748993 1015.392944,260.945801 
	C1016.159424,259.579559 1016.573608,258.290985 1017.418579,256.998108 
	C1019.573059,256.979370 1021.296814,256.964935 1023.020508,256.950500 
	C1027.885254,261.082794 1033.012939,264.947296 1037.556274,269.407135 
	C1045.295898,277.004669 1052.656616,284.987732 1060.199951,292.786194 
	C1064.306885,297.032074 1068.461914,301.231567 1072.795410,305.720032 
	C1073.290161,308.948181 1071.702148,308.892395 1069.289795,308.069183 
	C1068.510742,308.039917 1068.084473,308.022552 1067.288452,307.676971 
	C1066.280762,306.893921 1065.642822,306.439117 1065.004883,305.984314 
z"/>
<path fill="#011480" opacity="1.000000" stroke="none" 
	d="
M1065.001953,306.239227 
	C1065.642822,306.439117 1066.280762,306.893921 1066.968140,307.665070 
	C1066.459106,307.815979 1065.900513,307.650513 1065.166138,307.244446 
	C1064.993042,306.833923 1064.995972,306.664001 1065.001953,306.239227 
z"/>
<path fill="#031AA5" opacity="1.000000" stroke="none" 
	d="
M1016.987793,257.002441 
	C1016.573608,258.290985 1016.159424,259.579559 1015.308167,260.592590 
	C1011.188904,257.531250 1010.247192,259.727020 1009.721924,263.143005 
	C1009.272095,263.424438 1009.094910,263.659424 1008.992004,263.950470 
	C1008.505127,263.688202 1008.018311,263.425934 1006.902588,262.904907 
	C1003.523438,262.448792 1000.773010,262.251404 997.592285,262.033752 
	C992.724182,262.331879 988.289368,262.867523 983.848328,262.925354 
	C973.235352,263.063507 962.619568,262.976471 951.738342,262.712463 
	C949.312866,262.624146 947.153992,262.803741 944.623047,262.983826 
	C943.507202,263.002563 942.763367,263.020874 941.764771,263.024353 
	C941.339905,263.004395 941.169983,262.999268 940.930847,262.642700 
	C938.880554,262.170349 936.899353,262.049438 934.982910,261.599640 
	C936.059570,259.858612 937.071533,258.446442 938.543762,257.030029 
	C957.456116,257.024353 975.908264,257.023712 994.360352,257.020996 
	C999.238647,257.020264 1004.117004,257.013000 1009.380859,257.010101 
	C1011.184448,256.696625 1012.602356,256.381805 1014.020325,256.067017 
	C1014.754883,256.069305 1015.489380,256.071625 1016.596497,256.310303 
	C1016.969055,256.546661 1016.987793,257.002441 1016.987793,257.002441 
z"/>
<path fill="#010B64" opacity="1.000000" stroke="none" 
	d="
M1014.163147,255.752701 
	C1012.602356,256.381805 1011.184448,256.696625 1009.381958,256.633667 
	C1008.988403,255.503433 1008.979370,254.750931 1009.014771,253.652374 
	C1015.106445,247.154617 1021.153748,241.002899 1027.489258,235.015808 
	C1029.132568,235.734909 1030.487671,236.289352 1031.842896,236.843796 
	C1031.951050,237.792252 1032.516846,239.189682 1032.095703,239.618973 
	C1028.339600,243.447403 1024.476318,247.178711 1020.501526,250.780853 
	C1018.595093,252.508514 1016.380798,253.896545 1014.163147,255.752701 
z"/>
<path fill="#022B9D" opacity="1.000000" stroke="none" 
	d="
M1043.640869,258.155121 
	C1042.255859,252.493591 1045.429565,248.975006 1049.309326,246.863983 
	C1051.009155,245.939072 1054.783569,247.359726 1056.756836,248.851685 
	C1058.446655,250.129395 1060.138916,253.363724 1059.654175,255.138153 
	C1058.727173,258.530823 1057.216797,262.218750 1052.459229,262.759918 
	C1048.437622,263.217346 1045.683105,261.972321 1043.640869,258.155121 
M1050.923584,258.370819 
	C1052.411011,257.561859 1054.051514,256.922577 1055.273193,255.819260 
	C1055.562256,255.558121 1054.715210,253.152847 1053.907227,252.779205 
	C1052.808960,252.271240 1051.245850,252.768311 1049.882080,252.834366 
	C1050.007324,254.561783 1050.132568,256.289185 1050.923584,258.370819 
z"/>
<path fill="#010C60" opacity="1.000000" stroke="none" 
	d="
M1041.033447,222.946320 
	C1042.033813,224.366455 1043.034302,225.786591 1044.044434,227.582855 
	C1041.271484,229.645569 1038.488647,231.332153 1035.306763,232.651093 
	C1034.874512,231.264862 1034.841431,230.246307 1034.873535,228.805420 
	C1034.965698,227.257111 1034.992676,226.131119 1035.382812,224.999451 
	C1037.508423,224.311264 1039.270996,223.628784 1041.033447,222.946320 
z"/>
<path fill="#0639BE" opacity="1.000000" stroke="none" 
	d="
M1034.808350,229.227737 
	C1034.841431,230.246307 1034.874512,231.264862 1034.925537,232.667053 
	C1034.050903,234.152710 1033.158325,235.254761 1032.054199,236.600311 
	C1030.487671,236.289352 1029.132568,235.734909 1027.448486,234.639465 
	C1028.000000,227.958527 1029.027344,227.307755 1034.808350,229.227737 
z"/>
<path fill="#01177C" opacity="1.000000" stroke="none" 
	d="
M1017.418579,256.998108 
	C1016.987793,257.002441 1016.969055,256.546661 1016.963623,256.319641 
	C1019.017883,253.833313 1021.014099,253.420975 1022.958984,256.623535 
	C1021.296814,256.964935 1019.573059,256.979370 1017.418579,256.998108 
z"/>
<path fill="#012073" opacity="1.000000" stroke="none" 
	d="
M1034.873535,228.805420 
	C1029.027344,227.307755 1028.000000,227.958527 1027.160278,234.474838 
	C1021.153748,241.002899 1015.106445,247.154617 1008.549438,253.653198 
	C993.027283,253.999832 978.014832,253.999603 962.939819,253.651627 
	C962.645569,250.752609 962.414001,248.201340 962.182434,245.650085 
	C962.629822,245.577927 963.077271,245.505768 963.524658,245.433609 
	C962.714661,244.248917 961.904602,243.064240 961.556519,242.555099 
	C962.885925,239.455429 964.428833,237.079712 964.890076,234.509857 
	C965.675537,230.133453 963.586304,225.208405 967.604736,221.442734 
	C967.926514,221.141281 967.635925,220.210251 967.670410,219.574005 
	C967.770874,217.717636 967.891907,215.862396 968.447266,214.005402 
	C971.606567,213.358536 974.323364,212.712982 977.481812,212.057983 
	C980.184387,212.278870 982.441284,212.644440 984.707214,212.712601 
	C990.130127,212.875778 995.557190,212.899002 1000.982605,212.978729 
	C1009.745789,213.012573 1018.509033,213.046417 1027.576172,213.393982 
	C1025.357056,215.174088 1022.552612,216.321335 1020.406128,218.214523 
	C1018.871765,219.567749 1018.219849,221.921539 1017.174194,223.828857 
	C1018.829041,224.552429 1020.481567,225.876038 1022.139343,225.882660 
	C1026.431396,225.899872 1030.725952,225.336639 1035.019531,225.005142 
	C1034.992676,226.131119 1034.965698,227.257111 1034.873535,228.805420 
z"/>
<path fill="#032CA7" opacity="1.000000" stroke="none" 
	d="
M1001.085266,212.588379 
	C995.557190,212.899002 990.130127,212.875778 984.707214,212.712601 
	C982.441284,212.644440 980.184387,212.278870 977.014587,212.034470 
	C971.738037,212.350601 967.370239,212.680786 962.586060,213.008209 
	C960.782043,213.008499 959.394348,213.011536 957.706665,212.723206 
	C951.599365,212.280334 945.792053,212.128860 939.987915,211.597168 
	C940.001526,210.454437 940.011963,209.691910 940.245605,208.900543 
	C940.468689,208.871689 940.918030,208.846939 941.384888,208.895294 
	C945.904114,208.615585 949.956482,208.287537 954.008789,207.959503 
	C961.024353,207.644943 968.039856,207.330383 975.622314,207.407196 
	C977.858704,208.195816 979.526306,208.928360 981.198059,208.937973 
	C996.468262,209.025955 1011.739075,208.988220 1027.341431,208.989487 
	C1028.115356,208.988922 1028.557617,208.986069 1028.999878,208.983215 
	C1027.543945,209.654358 1026.106445,210.853577 1024.629028,210.904953 
	C1018.326416,211.124069 1012.010315,210.929413 1005.702942,211.061798 
	C1004.190613,211.093521 1002.692566,211.801224 1001.085266,212.588379 
z"/>
<path fill="#011B6C" opacity="1.000000" stroke="none" 
	d="
M1035.382812,224.999451 
	C1030.725952,225.336639 1026.431396,225.899872 1022.139343,225.882660 
	C1020.481567,225.876038 1018.829041,224.552429 1017.174194,223.828857 
	C1018.219849,221.921539 1018.871765,219.567749 1020.406128,218.214523 
	C1022.552612,216.321335 1025.357056,215.174088 1027.944214,213.365906 
	C1029.498779,213.172882 1030.989014,213.321655 1032.741455,214.107941 
	C1034.670532,216.824799 1036.337646,218.904129 1038.004883,220.983459 
	C1038.969971,221.413773 1039.935181,221.844116 1040.966797,222.610382 
	C1039.270996,223.628784 1037.508423,224.311264 1035.382812,224.999451 
z"/>
<path fill="#002173" opacity="1.000000" stroke="none" 
	d="
M1033.047363,207.350494 
	C1034.983032,208.092468 1036.980591,209.172836 1038.984253,210.632538 
	C1037.029907,209.904236 1035.069580,208.796570 1033.047363,207.350494 
z"/>
<path fill="#01257D" opacity="1.000000" stroke="none" 
	d="
M1037.965820,220.640747 
	C1036.337646,218.904129 1034.670532,216.824799 1032.994385,214.379761 
	C1034.632446,216.108734 1036.279663,218.203384 1037.965820,220.640747 
z"/>
<path fill="#01247B" opacity="1.000000" stroke="none" 
	d="
M1029.108643,208.727417 
	C1028.557617,208.986069 1028.115356,208.988922 1027.339844,208.656860 
	C1027.004272,207.877991 1027.002197,207.434021 1027.000000,206.990051 
	C1027.875122,206.830444 1028.750122,206.670853 1029.811035,206.755524 
	C1029.737183,207.490402 1029.477295,207.981018 1029.108643,208.727417 
z"/>
<path fill="#0C8FEC" opacity="1.000000" stroke="none" 
	d="
M103.774307,371.678040 
	C100.955254,371.776276 98.286499,371.972260 95.995247,371.135071 
	C95.331360,370.892487 94.960907,366.865326 95.843025,366.019196 
	C97.475433,364.453400 100.392815,362.798492 102.310989,363.232300 
	C107.658569,364.441681 104.643364,368.358673 103.774307,371.678040 
z"/>
<path fill="#01247B" opacity="1.000000" stroke="none" 
	d="
M190.960510,355.911591 
	C191.506470,355.418945 192.052429,354.926300 192.769165,354.213928 
	C194.692200,353.728912 196.444443,353.463593 199.617950,352.983063 
	C195.449051,359.331299 192.141373,364.939117 188.229416,370.087830 
	C186.229950,372.719421 184.489166,374.640900 185.470581,378.421143 
	C186.347015,381.796967 186.842148,384.359161 191.115234,384.418091 
	C191.867828,384.428467 192.593323,387.055847 193.315567,388.482605 
	C194.916382,391.645050 196.507187,394.812592 198.523132,398.258545 
	C198.987915,398.716736 199.031616,398.894379 198.943100,399.275940 
	C198.603760,399.740112 198.345245,399.930481 197.701065,400.011841 
	C196.918396,399.985931 196.469986,399.999176 195.728729,400.040771 
	C195.300507,400.718262 195.165100,401.367432 195.028427,402.410034 
	C195.924454,404.947388 196.821762,407.091278 197.790985,409.523315 
	C197.905731,410.206787 197.948593,410.602173 197.735519,411.217682 
	C198.347733,412.981293 199.388184,414.455841 200.040115,416.085815 
	C200.842072,418.090881 201.988770,420.320435 201.711868,422.272156 
	C201.514572,423.662842 199.284866,424.848877 197.828781,425.949860 
	C196.737289,426.775146 194.408203,427.286682 194.385498,427.994263 
	C194.194672,433.943085 192.733887,440.708374 195.074417,445.587982 
	C196.708862,448.995514 201.079712,452.726593 204.493576,455.409149 
	C207.624847,457.869690 208.223068,460.138123 209.090576,463.217804 
	C209.579315,464.952850 211.006989,466.423462 211.972443,468.363586 
	C210.282532,471.448242 208.631866,474.182465 206.520248,476.969849 
	C201.704376,477.001862 197.349442,476.980713 192.994507,476.959534 
	C193.977570,475.974701 194.811569,474.425537 195.973694,474.118561 
	C198.668427,473.406769 201.565506,473.485107 204.291458,472.856720 
	C205.568817,472.562256 206.640091,471.373810 207.804749,470.590515 
	C206.798462,469.718323 205.910461,468.321899 204.765671,468.063660 
	C199.476730,466.870422 194.149826,465.575562 188.770264,465.144836 
	C184.919083,464.836426 182.993790,463.140686 183.101532,459.735535 
	C183.442108,448.971100 183.778030,438.183563 184.814301,427.472595 
	C185.454575,420.854492 185.158493,419.065552 179.732132,415.613159 
	C178.827835,415.037872 178.088226,413.445496 178.091064,412.325165 
	C178.109940,404.877991 178.241440,397.526001 174.070251,390.780884 
	C172.566391,388.349030 172.593094,385.871582 175.754929,383.563049 
	C176.920074,382.712311 175.557541,378.399597 175.378845,375.059479 
	C176.564819,372.535400 177.719833,370.617798 178.874863,368.700195 
	C179.389771,367.875153 179.904663,367.050140 181.075623,366.104309 
	C190.050446,363.044037 190.917801,362.097443 190.960510,355.911591 
z"/>
<path fill="#0E76D3" opacity="1.000000" stroke="none" 
	d="
M192.553070,477.009399 
	C197.349442,476.980713 201.704376,477.001862 206.867584,476.980225 
	C213.443863,471.213715 219.211838,465.489990 225.277252,459.675537 
	C226.295303,458.710419 227.015915,457.835999 228.026886,456.961975 
	C228.510773,456.958557 228.704315,456.954773 229.286270,456.969635 
	C230.449783,456.992188 231.224899,456.996094 232.005280,457.007050 
	C232.010544,457.014069 232.021194,457.028351 231.889343,457.319031 
	C231.503540,458.409180 231.249588,459.208588 230.995605,460.007996 
	C230.434753,460.513123 229.873901,461.018280 228.825684,461.795105 
	C227.550705,463.030060 226.763077,463.993317 225.762054,465.079712 
	C225.281998,465.407776 225.081238,465.664276 224.633804,466.024353 
	C223.879791,466.715607 223.438354,467.354889 222.709717,468.173615 
	C221.570724,469.864410 220.718933,471.375793 219.867126,472.887177 
	C219.867142,472.887177 219.984467,472.991364 219.583893,472.945221 
	C216.782669,474.932526 214.382004,476.965973 211.673187,479.035278 
	C210.897385,479.667053 210.429749,480.262909 209.592133,481.056061 
	C207.445145,481.496765 205.663712,481.976868 203.891891,481.943695 
	C194.968689,481.776581 186.048004,481.475555 176.926544,480.860596 
	C175.086914,480.001587 173.457825,479.119537 171.805527,479.073639 
	C165.573654,478.900513 159.331482,479.097046 153.099792,478.920624 
	C151.716644,478.881470 150.360565,477.886047 148.992035,477.330933 
	C149.431671,477.228577 149.871323,477.126251 150.981903,477.034119 
	C152.104324,477.018555 152.555801,476.992798 153.007294,476.967041 
	C160.026031,476.993530 167.044785,477.020050 174.981567,477.055664 
	C178.934372,477.031494 181.969116,476.998169 185.003860,476.964874 
	C187.373108,476.996307 189.742371,477.027740 192.553070,477.009399 
z"/>
<path fill="#0E8ED8" opacity="1.000000" stroke="none" 
	d="
M148.730942,477.217712 
	C150.360565,477.886047 151.716644,478.881470 153.099792,478.920624 
	C159.331482,479.097046 165.573654,478.900513 171.805527,479.073639 
	C173.457825,479.119537 175.086914,480.001587 176.895691,481.126007 
	C177.063004,481.931610 177.061295,482.110870 177.059586,482.290161 
	C169.020020,482.202271 160.980469,482.114380 152.253204,481.747833 
	C150.361603,481.957336 149.157684,482.445526 147.953766,482.933716 
	C147.953766,482.933716 148.005692,483.058868 148.040771,483.118042 
	C147.608765,483.142578 147.141663,483.107971 146.337921,482.596802 
	C145.965042,480.445160 145.928787,478.770050 146.228226,477.076019 
	C147.011185,477.047699 147.458450,477.038330 147.905716,477.028931 
	C148.093750,477.054108 148.281799,477.079285 148.730942,477.217712 
z"/>
<path fill="#02267C" opacity="1.000000" stroke="none" 
	d="
M173.000885,359.391510 
	C174.860367,358.378082 176.718903,357.753754 178.979065,357.160675 
	C178.924316,357.807953 178.467926,358.423981 177.420990,359.059265 
	C176.541077,359.261719 176.251709,359.444916 175.962357,359.628113 
	C176.372696,359.764587 176.783020,359.901093 177.193344,360.037598 
	C173.665298,363.932983 170.137253,367.828400 166.300598,371.848694 
	C165.795212,368.709381 166.155594,366.048584 169.992188,364.649048 
	C171.399490,364.135712 172.020325,361.466431 173.000885,359.391510 
z"/>
<path fill="#01247B" opacity="1.000000" stroke="none" 
	d="
M184.985870,476.559143 
	C181.969116,476.998169 178.934372,477.031494 175.447815,477.006958 
	C176.490982,473.074554 182.083084,472.628326 184.985870,476.559143 
z"/>
<path fill="#051F6B" opacity="1.000000" stroke="none" 
	d="
M119.000465,359.068146 
	C120.177238,360.078613 120.973434,361.089020 121.769638,362.099396 
	C120.894180,362.674255 120.004669,363.723328 119.147011,363.697968 
	C118.184006,363.669403 117.249763,362.670105 116.303009,362.093140 
	C117.075302,361.084778 117.847595,360.076447 119.000465,359.068146 
z"/>
<path fill="#01308D" opacity="1.000000" stroke="none" 
	d="
M138.989990,474.806580 
	C138.992889,474.104614 138.995789,473.402618 139.000854,472.349609 
	C140.073349,471.986084 141.143646,471.973541 142.860596,471.967041 
	C143.507233,471.973053 143.999283,471.968811 143.999268,471.968811 
	C145.115707,472.864105 146.232147,473.759369 146.782288,474.841705 
	C145.466888,475.048370 144.717758,475.067963 143.676727,474.910767 
	C141.919876,474.758148 140.454941,474.782349 138.989990,474.806580 
z"/>
<path fill="#02267C" opacity="1.000000" stroke="none" 
	d="
M178.546722,368.803680 
	C177.719833,370.617798 176.564819,372.535400 175.204590,374.726501 
	C174.252594,375.002197 173.505783,375.004364 172.385925,375.003326 
	C174.081436,372.969147 176.150024,370.938141 178.546722,368.803680 
z"/>
<path fill="#02267C" opacity="1.000000" stroke="none" 
	d="
M169.403351,381.916992 
	C169.407959,380.335297 169.783295,378.670105 170.517609,376.874878 
	C170.509094,378.441101 170.141571,380.137299 169.403351,381.916992 
z"/>
<path fill="#02267C" opacity="1.000000" stroke="none" 
	d="
M160.916229,372.864258 
	C159.968491,373.487671 158.877533,373.799133 157.434937,374.145355 
	C157.354965,371.465515 158.236649,370.133667 160.916229,372.864258 
z"/>
<path fill="#02267C" opacity="1.000000" stroke="none" 
	d="
M155.828949,375.080750 
	C155.669434,375.964935 155.167206,376.848785 154.398499,377.895203 
	C154.583435,377.065308 155.034836,376.072845 155.828949,375.080750 
z"/>
<path fill="#0E76D3" opacity="1.000000" stroke="none" 
	d="
M143.968643,475.087555 
	C144.717758,475.067963 145.466888,475.048370 146.590302,475.019043 
	C147.232819,475.501282 147.501068,475.993256 147.837524,476.757080 
	C147.458450,477.038330 147.011185,477.047699 145.927780,477.002625 
	C144.850632,476.327972 144.409637,475.707764 143.968643,475.087555 
z"/>
<path fill="#02267C" opacity="1.000000" stroke="none" 
	d="
M161.058868,388.456451 
	C161.748306,388.090912 162.498749,388.040649 163.624817,388.018311 
	C163.040268,388.288025 162.080078,388.529877 161.058868,388.456451 
z"/>
<path fill="#001B6E" opacity="1.000000" stroke="none" 
	d="
M144.118668,471.692047 
	C143.999283,471.968811 143.507233,471.973053 143.261902,471.956665 
	C143.332291,471.114288 143.648041,470.288269 143.963776,469.462250 
	C144.055206,470.113251 144.146637,470.764282 144.118668,471.692047 
z"/>
<path fill="#01308D" opacity="1.000000" stroke="none" 
	d="
M152.969437,476.674194 
	C152.555801,476.992798 152.104324,477.018555 151.317657,476.985291 
	C151.311813,476.430115 151.641129,475.933990 151.970444,475.437866 
	C152.290817,475.752380 152.611206,476.066895 152.969437,476.674194 
z"/>
<path fill="#F5F9FA" opacity="1.000000" stroke="none" 
	d="
M233.605698,316.142365 
	C234.021866,314.637512 234.438034,313.132690 234.772552,311.299744 
	C235.005920,310.034271 235.320938,309.096893 235.860535,307.631653 
	C236.276413,306.789032 236.272995,306.476318 236.074860,306.165649 
	C237.371017,303.368774 238.667175,300.571899 240.312073,297.290649 
	C246.280731,290.866028 251.900620,284.925751 257.657166,278.900085 
	C257.793823,278.814636 257.923218,278.519440 258.195190,278.353729 
	C259.706940,277.485077 260.946747,276.782104 262.579895,276.144287 
	C273.677948,273.255066 284.168213,272.094666 294.215424,278.659973 
	C296.879883,280.401062 296.999634,277.876953 297.459229,275.912476 
	C304.967255,275.830627 312.014374,275.699585 319.051239,275.910217 
	C320.445374,275.951935 321.800293,277.305603 322.899231,278.358429 
	C321.942871,283.747345 321.260590,288.832123 320.365723,294.250885 
	C319.476257,300.360260 318.799377,306.135651 317.938934,312.223114 
	C317.627228,313.701508 317.499084,314.867859 317.205444,316.376709 
	C316.999725,317.470123 316.959534,318.221039 316.744781,319.328308 
	C315.263855,328.285583 313.957520,336.886505 312.651215,345.487427 
	C313.178986,345.791992 313.706787,346.096527 314.234589,346.401093 
	C316.066895,344.749512 318.856903,343.426605 319.557037,341.386505 
	C321.923859,334.489502 326.844971,335.898315 332.097870,336.497223 
	C330.881927,344.154724 327.221252,349.831329 320.820953,354.139923 
	C309.106232,360.354919 298.623260,360.487640 289.950256,349.359497 
	C288.268036,350.482880 286.937714,351.446716 285.536987,352.294250 
	C277.167511,357.358765 268.191620,360.284943 258.502747,357.543488 
	C253.464935,356.118042 248.852829,353.188080 244.048416,350.937744 
	C244.048416,350.937744 244.002350,351.000458 243.918274,350.656677 
	C241.157684,346.461975 238.481155,342.611084 235.804626,338.760162 
	C235.806732,338.592712 235.808823,338.425293 235.633377,337.634918 
	C234.839142,330.055420 234.222427,323.098907 233.605698,316.142365 
M268.336365,297.242859 
	C266.630768,301.831848 264.925171,306.420868 262.872314,311.664246 
	C262.684357,313.100342 262.496399,314.536407 261.930359,316.731689 
	C260.632965,324.387085 260.625275,331.951385 264.347290,339.008026 
	C266.412689,342.923828 269.211212,346.334015 274.296967,345.917969 
	C279.279846,345.510345 287.505432,339.273193 288.171722,335.246948 
	C289.168945,329.220703 289.783417,323.131073 290.957428,316.371613 
	C291.033813,315.317474 291.110168,314.263336 291.671112,312.722290 
	C291.711304,311.825012 291.751495,310.927734 292.092102,309.277832 
	C292.666656,305.837311 293.719025,302.389069 293.663452,298.958771 
	C293.619537,296.249512 292.952972,292.927765 291.280396,290.978394 
	C285.008118,283.667969 279.309753,284.089081 272.130951,291.942841 
	C271.076904,293.617798 270.022888,295.292755 268.336365,297.242859 
z"/>
<path fill="#14A1D9" opacity="1.000000" stroke="none" 
	d="
M207.220795,396.265594 
	C213.382385,390.364594 219.543976,384.463623 226.155731,378.151703 
	C227.690659,376.475616 228.775421,375.210480 229.860199,373.945343 
	C230.252090,373.574280 230.643982,373.203217 231.434753,372.475769 
	C232.893845,371.076019 233.954041,370.032623 235.310776,368.848694 
	C236.095245,366.825073 236.583176,364.941925 237.157532,363.242432 
	C237.421555,363.664154 237.658264,363.769440 238.289490,363.777191 
	C239.411774,363.526001 240.198639,363.239594 241.286591,362.936737 
	C241.993530,362.544952 242.399368,362.169647 243.111298,361.555603 
	C245.955795,360.938843 248.494232,360.560791 251.301849,360.422852 
	C252.790085,360.660919 254.009094,360.658844 255.680222,360.686279 
	C263.350342,360.710938 270.568359,360.706116 278.261414,360.796722 
	C283.169189,360.641937 287.601898,360.391724 292.399597,360.111938 
	C293.496216,360.099762 294.227875,360.117157 295.212952,360.371460 
	C296.987366,360.457245 298.508362,360.306213 300.461456,360.147339 
	C305.587891,360.140625 310.282196,360.141754 315.301117,360.397400 
	C330.083832,360.770905 344.541901,360.889893 358.999878,361.370056 
	C359.001038,362.451508 359.002319,363.171783 358.613708,363.930695 
	C357.446747,364.056030 356.669678,364.142761 355.512085,364.185455 
	C354.385193,364.291229 353.638824,364.441071 352.447754,364.471008 
	C320.471771,364.306396 288.940521,364.257507 257.409241,364.240570 
	C256.613312,364.240143 255.817184,364.591644 254.636505,364.630859 
	C248.726776,363.710815 244.539749,366.092987 240.812286,370.168274 
	C240.298431,370.442413 240.155350,370.713837 239.855698,371.216370 
	C235.374252,375.600006 231.195236,379.829407 226.703400,384.151123 
	C225.616348,385.171082 224.842117,386.098694 223.778564,387.190826 
	C219.331253,391.592438 215.173233,395.829498 210.740479,400.133545 
	C209.970642,400.474792 209.475555,400.749084 208.639069,400.859314 
	C206.193726,400.129639 204.089752,399.564087 202.229767,398.738800 
	C204.056107,397.741241 205.638458,397.003418 207.220795,396.265594 
z"/>
<path fill="#1C428F" opacity="1.000000" stroke="none" 
	d="
M277.786377,360.701294 
	C270.568359,360.706116 263.350342,360.710938 255.529709,360.386169 
	C253.655304,359.717377 252.383499,359.378204 250.862320,359.013306 
	C249.782242,358.315826 248.951553,357.644104 248.120865,356.972382 
	C244.454514,356.929321 244.764923,353.881744 244.055115,351.268799 
	C248.852829,353.188080 253.464935,356.118042 258.502747,357.543488 
	C268.191620,360.284943 277.167511,357.358765 285.536987,352.294250 
	C286.937714,351.446716 288.268036,350.482880 289.950256,349.359497 
	C298.623260,360.487640 309.106232,360.354919 321.067505,354.119141 
	C321.668549,353.994537 322.160522,354.048615 322.060730,354.373505 
	C320.964783,356.095856 319.968658,357.493317 318.604187,358.921509 
	C317.149414,359.349121 316.062958,359.745972 314.976501,360.142822 
	C310.282196,360.141754 305.587891,360.140625 300.241211,359.853271 
	C298.045715,359.756195 296.502625,359.945374 294.959503,360.134583 
	C294.227875,360.117157 293.496216,360.099762 292.115234,359.797485 
	C286.906067,359.908844 282.346222,360.305054 277.786377,360.701294 
z"/>
<path fill="#013598" opacity="1.000000" stroke="none" 
	d="
M247.832596,357.244995 
	C248.951553,357.644104 249.782242,358.315826 250.825089,359.297852 
	C251.035721,359.799652 251.034195,359.991180 251.032654,360.182709 
	C248.494232,360.560791 245.955795,360.938843 242.814758,361.587708 
	C241.812485,361.870514 241.412811,361.882538 240.835144,361.593719 
	C238.576218,358.409241 235.720993,358.200867 233.611420,360.536896 
	C232.688171,361.559265 233.628967,364.365479 233.913971,366.334076 
	C234.047012,367.252960 234.633377,368.106232 235.014252,368.989227 
	C233.954041,370.032623 232.893845,371.076019 231.507416,372.205811 
	C230.354614,368.638092 228.637680,371.188324 227.262527,371.758362 
	C219.311356,375.054443 212.336624,370.371948 213.076050,362.158325 
	C213.138840,361.460846 213.822922,360.819275 214.612671,360.489258 
	C215.012924,361.654480 214.972809,362.485474 215.032745,363.309204 
	C215.293350,366.890228 216.645401,368.929932 220.814392,369.121094 
	C225.346054,369.328827 228.018051,367.111145 228.717560,363.393219 
	C229.347214,360.046600 227.032593,357.118652 223.423599,356.838715 
	C220.697266,356.627167 217.876556,357.632233 215.097839,358.095825 
	C217.786041,352.232330 224.317307,351.037170 228.383209,355.578094 
	C229.181732,356.469879 231.004013,357.760895 231.411697,357.483368 
	C235.509995,354.693420 239.255081,356.332336 243.198105,357.776276 
	C244.447693,358.233887 246.084641,357.633698 247.832596,357.244995 
z"/>
<path fill="#084EC4" opacity="1.000000" stroke="none" 
	d="
M215.070892,358.408752 
	C217.876556,357.632233 220.697266,356.627167 223.423599,356.838715 
	C227.032593,357.118652 229.347214,360.046600 228.717560,363.393219 
	C228.018051,367.111145 225.346054,369.328827 220.814392,369.121094 
	C216.645401,368.929932 215.293350,366.890228 215.032745,363.309204 
	C214.972809,362.485474 215.012924,361.654480 214.980484,360.401672 
	C214.983978,359.558289 215.013962,359.139984 215.070892,358.408752 
M223.639664,360.909180 
	C222.050827,361.159241 220.424210,361.294739 218.916870,361.791962 
	C218.708252,361.860748 218.715622,364.159210 219.286133,364.642273 
	C220.119034,365.347534 222.278625,365.923767 222.592270,365.531433 
	C223.489288,364.409363 223.740753,362.771240 223.639664,360.909180 
z"/>
<path fill="#1C428F" opacity="1.000000" stroke="none" 
	d="
M235.457001,338.875977 
	C238.481155,342.611084 241.157684,346.461975 243.947021,350.630249 
	C240.612854,347.839417 237.131134,344.766968 233.804932,341.534485 
	C233.604935,341.340149 234.645859,339.868896 235.457001,338.875977 
z"/>
<path fill="#1C428F" opacity="1.000000" stroke="none" 
	d="
M233.313171,316.170776 
	C234.222427,323.098907 234.839142,330.055420 235.493866,337.426056 
	C234.743484,337.112213 233.339264,336.441956 233.264847,335.647156 
	C232.705078,329.668671 232.407135,323.665710 232.040100,317.233826 
	C232.375473,316.597961 232.698059,316.398590 233.313171,316.170776 
z"/>
<path fill="#0350AC" opacity="1.000000" stroke="none" 
	d="
M206.796616,396.187683 
	C205.638458,397.003418 204.056107,397.741241 201.865082,398.750610 
	C200.529373,399.038788 199.802338,399.055389 199.075317,399.071991 
	C199.031616,398.894379 198.987915,398.716736 198.885117,398.270630 
	C201.341507,397.371368 203.856964,396.740570 206.796616,396.187683 
z"/>
<path fill="#013598" opacity="1.000000" stroke="none" 
	d="
M229.497894,373.959656 
	C228.775421,375.210480 227.690659,376.475616 226.307892,377.847015 
	C227.051773,376.626831 228.093674,375.300415 229.497894,373.959656 
z"/>
<path fill="#02277E" opacity="1.000000" stroke="none" 
	d="
M191.916595,291.109863 
	C186.582962,291.401276 181.249329,291.692657 175.285675,291.768646 
	C174.084030,292.008209 173.512390,292.463196 172.940765,292.918213 
	C171.535019,293.592621 170.129257,294.266998 168.389160,295.018188 
	C166.768814,290.418671 167.764053,287.191681 173.180618,286.272522 
	C174.184906,286.102081 175.796677,284.414917 175.656219,283.729340 
	C175.415024,282.552155 174.077652,281.546844 173.084656,280.589508 
	C171.782318,279.333954 170.367706,278.194885 169.452133,276.559937 
	C182.936508,276.101685 195.970139,276.089233 209.243225,276.326355 
	C212.780273,277.604736 214.355988,276.017120 215.102692,272.710052 
	C215.812927,271.958588 216.388855,271.462830 217.288315,270.937500 
	C218.076630,270.274017 218.541397,269.640076 219.136047,268.746857 
	C219.843719,267.991730 220.421555,267.495850 221.319916,266.957947 
	C222.084396,266.267395 222.528320,265.618958 223.042114,264.666351 
	C223.737091,263.905396 224.362183,263.448578 225.333923,262.958740 
	C230.451141,258.290619 235.221741,253.655548 240.461456,249.013672 
	C251.625870,249.020996 262.321198,249.035141 272.909821,249.437927 
	C270.631897,250.217682 268.468414,250.884735 266.287872,250.946198 
	C260.617584,251.106033 254.939789,250.999939 249.264984,250.999939 
	C249.164871,251.816391 249.064758,252.632858 248.964630,253.449310 
	C250.430557,253.756989 252.016266,254.580612 253.339447,254.273392 
	C256.980652,253.428024 258.271515,254.659317 258.021820,258.253693 
	C257.610077,264.181427 257.342651,270.119202 257.074921,276.494507 
	C257.398041,277.463989 257.660614,277.991699 257.923218,278.519440 
	C257.923218,278.519440 257.793823,278.814636 257.302643,278.650146 
	C254.450089,278.990936 252.107941,279.796356 249.723953,279.949188 
	C239.679153,280.593079 237.932907,282.299072 237.576172,291.961609 
	C222.061005,291.686493 206.988800,291.398163 191.916595,291.109863 
M252.547470,275.286774 
	C252.284836,275.011200 252.024582,274.733032 251.746048,274.474579 
	C251.738892,274.467926 251.531158,274.677490 251.416946,274.786224 
	C251.679916,275.061859 251.942886,275.337494 252.547470,275.286774 
z"/>
<path fill="#084EC4" opacity="1.000000" stroke="none" 
	d="
M209.003754,276.076752 
	C195.970139,276.089233 182.936508,276.101685 169.436859,276.198364 
	C166.922150,276.202759 164.873474,276.122986 162.384521,275.987610 
	C161.953766,274.882263 161.963287,273.832550 162.197266,272.339661 
	C162.421722,271.896484 162.869202,271.799072 163.302734,271.854614 
	C165.200577,271.879028 166.664886,271.847839 168.559372,271.838379 
	C171.289291,271.846893 173.589020,271.833710 176.365082,271.878876 
	C186.113480,271.955658 195.388840,272.113098 204.655319,271.894867 
	C206.971725,271.840271 209.831131,271.210114 211.452942,269.748352 
	C216.223480,265.448456 220.497757,260.597992 224.990524,255.735886 
	C225.014740,255.508698 225.062027,255.054184 225.394424,255.000488 
	C226.476837,253.955765 227.226868,252.964737 228.186462,251.952759 
	C228.396027,251.931793 228.817123,251.921402 229.160294,251.918396 
	C230.698700,251.987549 231.893921,252.059723 233.009903,252.452911 
	C230.282867,256.179871 227.635071,259.585815 224.987274,262.991760 
	C224.362183,263.448578 223.737091,263.905396 222.725647,264.716919 
	C221.892685,265.714447 221.446030,266.357208 220.999390,267.000000 
	C220.421555,267.495850 219.843719,267.991730 218.816986,268.783966 
	C217.900314,269.709259 217.432556,270.338165 216.964783,270.967072 
	C216.388855,271.462830 215.812927,271.958588 214.757904,272.755676 
	C212.520447,274.063568 210.762100,275.070160 209.003754,276.076752 
z"/>
<path fill="#022B9D" opacity="1.000000" stroke="none" 
	d="
M164.671509,299.045776 
	C163.932678,300.329620 162.847321,301.640167 161.432190,303.011475 
	C162.176590,301.738953 163.250793,300.405701 164.671509,299.045776 
z"/>
<path fill="#02277E" opacity="1.000000" stroke="none" 
	d="
M145.291840,279.970459 
	C146.378799,278.955536 147.841675,277.949951 149.651382,276.931458 
	C148.554733,277.938995 147.111237,278.959412 145.291840,279.970459 
z"/>
<path fill="#012B87" opacity="1.000000" stroke="none" 
	d="
M272.004242,208.003571 
	C267.854218,207.854050 263.704163,207.704514 259.523895,207.065826 
	C258.677399,204.719925 257.861176,202.863190 257.044922,201.006439 
	C255.446228,199.682968 252.955902,196.930771 252.399796,197.277832 
	C249.670090,198.981400 246.292145,201.113174 245.360764,203.848709 
	C244.271896,207.046829 243.142303,208.007828 240.034622,208.070297 
	C233.639771,208.198853 227.251495,208.655014 220.425995,208.960739 
	C221.407303,207.963165 222.823166,206.981216 225.086700,205.999222 
	C231.444412,205.999176 236.954453,205.999176 242.672501,205.999176 
	C241.855347,202.768631 241.367798,200.841202 241.044815,199.564270 
	C243.685654,198.493134 245.938644,198.079208 247.534073,196.827286 
	C250.772064,194.286484 253.650482,194.462250 257.073883,196.414780 
	C258.550049,197.256714 260.668182,196.973160 261.571777,197.085815 
	C261.332825,192.880508 261.165802,189.940689 261.332123,187.000549 
	C262.109894,187.000076 262.554352,186.999924 263.039734,187.352448 
	C263.613892,188.506943 263.978210,189.729584 264.714600,190.025146 
	C266.374878,190.691528 268.219269,190.899170 269.988770,191.293472 
	C269.532043,189.732986 269.009827,188.187073 268.657410,186.603378 
	C268.548065,186.112000 268.899323,185.518082 269.426636,184.946289 
	C271.238220,184.569809 273.286987,184.696213 273.983612,183.786835 
	C277.760345,178.856491 282.998444,180.025406 287.974121,179.940170 
	C290.289093,179.900497 292.603760,179.846985 295.363831,179.864197 
	C298.205688,179.954391 300.602356,179.980133 303.301056,180.292450 
	C309.400604,180.723969 315.198059,180.868942 320.995483,181.401581 
	C320.335236,183.180679 319.950470,184.823013 318.958313,185.912018 
	C316.809540,188.270554 314.349884,190.345856 311.909302,192.638489 
	C313.132324,194.234375 314.066681,195.453598 315.635193,197.500290 
	C311.983856,198.405594 309.031219,199.137680 304.837219,200.177521 
	C307.974945,201.918091 309.961060,203.019852 311.947174,204.121597 
	C311.917542,204.539429 311.887909,204.957245 311.858276,205.375076 
	C310.087067,205.582748 308.320251,205.844864 306.543823,205.987640 
	C301.683411,206.378265 296.818939,206.718643 291.956085,207.078903 
	C286.291016,207.074600 280.625946,207.070297 274.325684,206.836609 
	C273.128387,207.072662 272.566315,207.538116 272.004242,208.003571 
z"/>
<path fill="#012982" opacity="1.000000" stroke="none" 
	d="
M260.998779,187.000870 
	C261.165802,189.940689 261.332825,192.880508 261.571777,197.085815 
	C260.668182,196.973160 258.550049,197.256714 257.073883,196.414780 
	C253.650482,194.462250 250.772064,194.286484 247.534073,196.827286 
	C245.938644,198.079208 243.685654,198.493134 241.044815,199.564270 
	C241.367798,200.841202 241.855347,202.768631 242.672501,205.999176 
	C236.954453,205.999176 231.444412,205.999176 225.466507,205.998062 
	C226.973648,201.849564 228.890228,197.821014 234.829559,198.869797 
	C236.128906,199.099228 237.710236,197.731781 239.161713,197.099762 
	C238.993637,196.560379 238.825562,196.020996 238.657486,195.481613 
	C236.764709,195.221527 234.871933,194.961456 233.389206,194.757706 
	C232.240646,192.479614 231.218857,190.452972 230.444962,188.917999 
	C232.375458,186.773087 234.181061,184.766937 236.385788,182.388031 
	C238.030319,182.421951 239.291702,182.785721 240.518311,183.242874 
	C245.427292,185.072495 250.810532,182.749283 252.855545,177.960892 
	C253.652313,176.095291 254.612579,174.299530 255.535736,172.394852 
	C255.296021,172.057800 255.036224,171.636703 254.722641,171.260437 
	C252.327942,168.386963 246.324448,166.700714 251.511429,161.300919 
	C251.815735,160.984146 251.179977,159.764359 250.981689,158.964813 
	C252.783417,157.397995 254.585144,155.831177 256.781372,154.510376 
	C260.148254,155.858353 263.460907,158.529495 265.007568,153.057907 
	C267.373657,153.048172 269.739746,153.038452 272.557007,153.406433 
	C273.118622,155.191757 273.513184,156.647400 273.285004,157.997787 
	C272.662231,161.682922 271.776581,165.323654 270.989441,169.221436 
	C270.983795,169.460907 270.991028,169.939499 270.643311,170.006027 
	C268.552429,170.735641 266.809204,171.398727 264.713470,172.097305 
	C263.566956,173.754669 262.772949,175.376541 261.687439,177.041260 
	C261.085083,177.405151 260.774261,177.726196 260.463409,178.047256 
	C260.969940,178.365128 261.476440,178.682999 262.023376,179.344818 
	C262.378906,180.790482 262.694061,181.892197 262.632385,182.996765 
	C261.272278,183.335083 260.288940,183.670517 259.305634,184.005951 
	C259.869995,185.004257 260.434387,186.002563 260.998779,187.000870 
M263.396759,161.020340 
	C260.666260,161.212753 259.292572,162.480179 260.737305,165.138214 
	C261.151550,165.900360 263.442383,166.604324 263.602997,166.388794 
	C264.781677,164.806976 267.260956,163.111496 263.396759,161.020340 
M255.422424,184.881699 
	C255.711426,183.747757 256.000427,182.613800 256.289398,181.479843 
	C255.545975,181.546768 254.802567,181.613678 254.059143,181.680603 
	C254.267792,182.754578 254.476456,183.828537 255.422424,184.881699 
M257.157562,175.490738 
	C257.575989,175.785675 257.994446,176.080612 258.412872,176.375534 
	C258.524628,175.938934 258.636414,175.502350 258.748199,175.065750 
	C258.259064,175.013428 257.769897,174.961105 257.157562,175.490738 
M240.157532,191.490829 
	C240.575943,191.785751 240.994370,192.080688 241.412796,192.375610 
	C241.524582,191.939011 241.636368,191.502396 241.748154,191.065796 
	C241.259033,191.013474 240.769897,190.961166 240.157532,191.490829 
z"/>
<path fill="#012174" opacity="1.000000" stroke="none" 
	d="
M173.024139,225.614838 
	C162.203201,224.093750 154.142731,217.768356 149.643784,208.291550 
	C147.224319,203.195068 144.353729,197.258499 146.385742,190.435852 
	C147.873459,189.598969 148.974884,189.209854 150.076309,188.820740 
	C153.082413,188.875900 156.088501,188.931061 159.545837,189.017365 
	C162.525146,191.994614 164.914307,195.082230 167.674423,197.791946 
	C168.407150,198.511292 170.495331,198.542847 171.577957,198.049423 
	C173.175201,197.321457 174.268036,195.454803 175.872849,194.783981 
	C177.622574,194.052597 179.860062,193.552612 181.615417,194.002716 
	C183.903915,194.589539 185.564041,202.493347 183.656311,205.712234 
	C182.749298,207.242630 179.753342,207.552353 177.686218,208.370880 
	C176.564499,208.815048 175.121231,208.882477 174.355118,209.650589 
	C173.751038,210.256210 173.469345,212.307983 173.885651,212.630035 
	C177.428665,215.370865 175.846802,218.034088 174.231155,220.940430 
	C173.529373,222.202850 173.407867,223.787842 173.024139,225.614838 
M165.648514,217.648438 
	C166.262726,217.073059 166.876953,216.497696 167.491165,215.922318 
	C166.792938,215.665161 166.094696,215.408020 165.396469,215.150864 
	C165.289246,215.886383 165.182037,216.621902 165.648514,217.648438 
z"/>
<path fill="#012B87" opacity="1.000000" stroke="none" 
	d="
M250.644241,159.029266 
	C251.179977,159.764359 251.815735,160.984146 251.511429,161.300919 
	C246.324448,166.700714 252.327942,168.386963 254.722641,171.260437 
	C255.036224,171.636703 255.296021,172.057800 255.535736,172.394852 
	C254.612579,174.299530 253.652313,176.095291 252.855545,177.960892 
	C250.810532,182.749283 245.427292,185.072495 240.518311,183.242874 
	C239.291702,182.785721 238.030319,182.421951 236.392456,182.007629 
	C234.566071,181.336014 233.132126,180.672043 231.346893,180.001678 
	C230.138901,176.058319 232.011353,176.679153 234.400497,177.896469 
	C237.997971,179.729446 241.334335,182.498840 245.846756,179.661102 
	C249.679474,177.250809 251.454941,172.763626 248.625702,169.241318 
	C245.097626,164.848999 246.334747,161.908447 250.644241,159.029266 
z"/>
<path fill="#012B87" opacity="1.000000" stroke="none" 
	d="
M212.686707,214.052338 
	C212.566696,214.657867 212.121094,215.306168 211.362518,216.020966 
	C211.486740,215.423340 211.923904,214.759216 212.686707,214.052338 
z"/>
<path fill="#022B9D" opacity="1.000000" stroke="none" 
	d="
M192.045746,291.487122 
	C206.988800,291.398163 222.061005,291.686493 237.834137,292.169067 
	C239.017502,293.582062 239.499939,294.800812 239.982361,296.019531 
	C233.782227,296.345947 227.585159,296.849396 221.381241,296.957001 
	C212.222244,297.115814 203.058380,296.993011 193.422943,296.966522 
	C194.268570,296.295227 195.552444,295.195374 196.914078,295.088776 
	C200.342804,294.820343 203.806595,294.999908 207.256348,294.999908 
	C207.302536,294.498749 207.348724,293.997620 207.394913,293.496460 
	C205.834869,293.334625 204.274261,293.178040 202.714890,293.009949 
	C199.201233,292.631165 195.688187,292.246613 192.045746,291.487122 
z"/>
<path fill="#001168" opacity="1.000000" stroke="none" 
	d="
M240.008942,296.280792 
	C239.499939,294.800812 239.017502,293.582062 238.277115,292.155884 
	C237.932907,282.299072 239.679153,280.593079 249.723953,279.949188 
	C252.107941,279.796356 254.450089,278.990936 257.165985,278.735596 
	C251.900620,284.925751 246.280731,290.866028 240.345764,296.936768 
	C240.032288,296.892181 240.033905,296.717133 240.008942,296.280792 
z"/>
<path fill="#042475" opacity="1.000000" stroke="none" 
	d="
M181.403137,302.694977 
	C178.256393,303.433807 175.483749,304.035187 172.616928,304.656982 
	C173.988144,300.888672 178.867630,297.910858 182.112350,299.122009 
	C185.330368,300.323242 183.496887,301.554932 181.403137,302.694977 
z"/>
<path fill="#2159B0" opacity="1.000000" stroke="none" 
	d="
M235.859741,306.311676 
	C236.272995,306.476318 236.276413,306.789032 235.899963,307.262207 
	C235.691391,307.099670 235.667999,306.778687 235.859741,306.311676 
z"/>
<path fill="#02277E" opacity="1.000000" stroke="none" 
	d="
M161.415924,312.007996 
	C162.832565,310.099884 164.614471,308.182434 166.691620,306.119965 
	C167.009659,309.502136 165.059814,311.324524 161.415924,312.007996 
z"/>
<path fill="#02277E" opacity="1.000000" stroke="none" 
	d="
M148.393250,324.999146 
	C149.154694,323.762848 150.289444,322.520325 151.716888,321.151978 
	C150.928589,322.348419 149.847565,323.670654 148.393250,324.999146 
z"/>
<path fill="#00359C" opacity="1.000000" stroke="none" 
	d="
M344.985474,209.020813 
	C339.965637,209.012634 334.945831,209.004456 329.551910,208.608124 
	C330.010254,207.816925 330.823425,207.113373 331.678192,207.058548 
	C338.281158,206.634857 344.896454,206.400528 351.498047,205.960037 
	C353.435944,205.830719 355.517181,205.586472 357.221985,204.756592 
	C359.813721,203.494980 360.636658,201.783279 358.535370,198.815170 
	C356.487885,195.922974 355.468384,192.303009 354.347351,189.039810 
	C361.653290,194.387863 368.867950,199.463898 371.685120,208.422821 
	C372.027130,209.510529 372.374298,210.806549 373.164581,211.493622 
	C374.319061,212.497299 376.608948,214.004349 377.135406,213.605988 
	C379.343445,211.935150 382.658234,209.559677 382.564789,207.584320 
	C382.457397,205.313705 379.395660,202.936188 377.207458,201.091003 
	C375.546478,199.690414 372.833160,199.476639 371.299286,197.997330 
	C365.065826,191.985550 359.081665,185.713226 353.099457,179.447327 
	C352.674591,179.002319 353.007507,177.833847 353.289856,177.149857 
	C358.321320,180.990326 363.050903,184.682709 367.780518,188.375076 
	C368.365845,187.803375 368.951141,187.231674 369.536469,186.659973 
	C367.237701,183.478439 365.440704,178.311005 362.524261,177.574127 
	C357.985718,176.427383 356.492004,175.261337 358.015564,170.841751 
	C354.077881,167.913361 350.275696,165.131851 346.550964,162.250198 
	C346.141205,161.933182 346.080902,161.051468 346.019196,160.416061 
	C345.655273,156.669800 342.925781,155.751877 339.877777,154.971375 
	C338.506287,154.620209 337.285309,153.681076 335.996063,153.008713 
	C342.007843,152.128632 345.784943,154.384720 349.441162,159.610703 
	C353.274841,165.090302 359.394104,168.920471 364.206543,173.776657 
	C372.900269,182.549423 381.340302,191.573059 389.936005,200.443741 
	C393.975433,204.612411 398.108246,208.691940 402.270294,212.738266 
	C402.646240,213.103775 403.547852,212.928513 404.673676,213.377991 
	C408.759247,217.364731 412.863617,216.332642 417.449677,214.999420 
	C420.596954,215.007065 423.294556,215.010391 426.061890,215.367126 
	C427.856598,217.791763 429.581543,219.862991 431.179749,221.782013 
	C433.430725,222.880753 435.823792,224.048859 437.498291,224.866211 
	C434.840515,228.598969 432.918427,231.298477 430.663818,233.997437 
	C429.887939,233.997543 429.444580,233.998154 428.774780,233.740540 
	C427.362762,233.986176 426.177185,234.490021 424.523499,234.994141 
	C411.594757,234.995499 399.132965,235.080444 386.674744,234.908569 
	C384.773926,234.882339 382.889557,233.667526 381.380829,233.000488 
	C383.052063,232.466415 384.340027,231.934326 385.627991,231.402222 
	C385.013885,230.100540 384.749878,228.259232 383.723358,227.593246 
	C379.864258,225.089569 378.364899,223.087112 383.613556,220.391769 
	C384.495514,219.938858 384.367096,217.518448 384.705536,216.007126 
	C383.668610,215.785095 382.249878,215.013062 381.654144,215.426590 
	C376.044098,219.321030 372.471191,215.944733 369.622620,211.819077 
	C366.848541,207.801285 363.726440,208.877274 358.549225,210.141693 
	C363.745544,214.354950 367.756805,218.043884 372.227875,221.048584 
	C374.468781,222.554550 375.560577,223.568130 373.621826,225.967926 
	C370.303497,223.517456 367.162109,221.307480 364.438599,218.666595 
	C358.948059,213.342545 354.186035,206.779663 344.985474,209.020813 
z"/>
<path fill="#023DA5" opacity="1.000000" stroke="none" 
	d="
M404.977661,168.442535 
	C409.203217,172.206558 413.812714,176.024521 417.555969,180.557800 
	C420.177399,183.732513 422.899139,185.118439 426.860352,185.074295 
	C436.419281,184.967743 445.981476,184.968979 455.540375,185.087769 
	C474.363525,185.321671 493.185333,185.664856 512.470825,185.939758 
	C513.298035,186.731720 513.662170,187.546783 513.720154,188.955368 
	C512.998718,194.199432 508.925171,193.647919 506.322266,193.680405 
	C496.929138,193.797592 487.529053,193.235962 478.129639,193.016220 
	C471.666931,192.865143 464.801208,194.365555 459.952026,188.167038 
	C459.805145,187.979309 459.321228,188.055222 458.541107,188.003357 
	C455.063599,188.009430 452.039459,188.020340 448.628754,188.035355 
	C447.569519,188.400208 446.438721,188.632095 446.295410,189.141724 
	C444.916931,194.042725 442.292450,198.971252 445.586212,203.984589 
	C441.191833,203.906845 437.047241,203.360428 434.790527,208.193298 
	C434.071686,208.562775 433.603668,208.830948 432.736633,209.222961 
	C428.558411,209.565598 424.779205,209.784378 420.827148,209.761826 
	C420.097961,209.355591 419.541565,209.190674 418.656616,209.022736 
	C417.890137,209.020645 417.452209,209.021591 417.014282,209.022522 
	C408.364777,210.881805 403.043732,206.035858 398.220276,199.898407 
	C396.434509,197.626160 394.140289,195.753479 392.035706,193.355133 
	C393.691193,193.871277 395.613708,194.458939 397.044708,195.641403 
	C401.356995,199.204758 405.478088,202.999527 410.430725,207.372421 
	C412.548492,204.671341 414.619324,202.030106 416.690186,199.388855 
	C414.300507,197.833252 411.910797,196.277649 409.262482,194.553680 
	C416.680298,190.213287 424.648132,192.089188 432.513885,193.087860 
	C432.583160,192.613144 432.652466,192.138443 432.721741,191.663727 
	C431.886383,191.126190 431.097961,190.224289 430.208099,190.109558 
	C423.882202,189.293961 419.072449,186.393890 415.295776,181.103516 
	C412.842010,177.666290 409.301056,175.018265 406.381470,171.891296 
	C405.652008,171.110031 405.440918,169.844772 404.977661,168.442535 
z"/>
<path fill="#01308F" opacity="1.000000" stroke="none" 
	d="
M335.527771,153.005508 
	C337.285309,153.681076 338.506287,154.620209 339.877777,154.971375 
	C342.925781,155.751877 345.655273,156.669800 346.019196,160.416061 
	C346.080902,161.051468 346.141205,161.933182 346.550964,162.250198 
	C350.275696,165.131851 354.077881,167.913361 358.015564,170.841751 
	C356.492004,175.261337 357.985718,176.427383 362.524261,177.574127 
	C365.440704,178.311005 367.237701,183.478439 369.536469,186.659973 
	C368.951141,187.231674 368.365845,187.803375 367.780518,188.375076 
	C363.050903,184.682709 358.321320,180.990326 353.209869,176.777863 
	C350.889160,175.832413 348.950287,175.407074 346.931030,174.623169 
	C344.900421,174.175720 342.950195,174.086823 340.531342,173.998566 
	C331.371002,173.984848 322.679321,173.970474 313.983063,173.579529 
	C312.832458,168.968155 310.001099,168.238678 306.406281,169.862900 
	C302.957764,171.421021 301.614258,170.192749 301.191833,166.638199 
	C306.933136,164.648529 313.254272,167.789383 318.469574,163.793732 
	C315.553619,162.991577 312.629883,163.306854 309.829010,162.857101 
	C308.450867,162.635803 306.821289,161.244171 306.155121,159.946121 
	C305.068512,157.828842 304.677673,155.354492 303.993225,153.030823 
	C314.348663,153.021317 324.704071,153.011810 335.527771,153.005508 
z"/>
<path fill="#012B87" opacity="1.000000" stroke="none" 
	d="
M303.525269,153.020767 
	C304.677673,155.354492 305.068512,157.828842 306.155121,159.946121 
	C306.821289,161.244171 308.450867,162.635803 309.829010,162.857101 
	C312.629883,163.306854 315.553619,162.991577 318.469574,163.793732 
	C313.254272,167.789383 306.933136,164.648529 301.191833,166.638199 
	C301.614258,170.192749 302.957764,171.421021 306.406281,169.862900 
	C310.001099,168.238678 312.832458,168.968155 313.730042,173.580933 
	C313.312897,173.954147 313.144196,173.949356 312.757080,173.698181 
	C311.354309,173.646790 310.170013,173.841782 308.557861,174.047119 
	C304.392578,174.424530 300.655182,174.791611 296.555664,175.109283 
	C294.811371,174.336197 293.429230,173.612503 292.038086,172.534912 
	C291.780121,171.259735 291.531097,170.338440 291.282074,169.417145 
	C291.026123,169.439041 290.770172,169.460953 290.514221,169.482864 
	C290.340637,170.339874 290.167023,171.196884 289.787659,172.078461 
	C289.581848,172.103043 289.167389,172.101349 288.769989,172.104813 
	C283.878815,173.385071 279.505127,174.543808 275.557678,170.468369 
	C274.573914,169.452682 272.544128,169.450165 270.995117,168.981979 
	C271.776581,165.323654 272.662231,161.682922 273.285004,157.997787 
	C273.513184,156.647400 273.118622,155.191757 273.000153,153.419479 
	C283.013885,153.040131 293.035614,153.025421 303.525269,153.020767 
z"/>
<path fill="#0141BC" opacity="1.000000" stroke="none" 
	d="
M421.000000,210.003143 
	C424.779205,209.784378 428.558411,209.565598 432.707825,209.605865 
	C433.736267,211.902237 434.394531,213.939560 434.648499,215.933701 
	C431.493500,215.598251 428.742828,215.305969 425.992157,215.013702 
	C423.294556,215.010391 420.596954,215.007065 417.363403,214.621964 
	C414.552429,213.825974 412.277435,213.411758 410.002441,212.997559 
	C411.122742,212.332275 412.203400,211.583878 413.374786,211.025803 
	C414.478455,210.500015 415.677612,210.174744 416.924530,209.391891 
	C417.452209,209.021591 417.890137,209.020645 418.695068,209.308319 
	C419.708069,209.732346 420.354034,209.867752 421.000000,210.003143 
z"/>
<path fill="#24B6F9" opacity="1.000000" stroke="none" 
	d="
M243.351074,176.079651 
	C241.533478,175.072067 239.979172,173.956879 238.424850,172.841705 
	C240.012238,171.920395 241.599609,170.999084 243.578491,170.074356 
	C244.089539,170.372040 244.280884,170.617111 244.744705,171.310959 
	C244.958817,172.879593 244.972244,173.943390 244.985687,175.007202 
	C244.528580,175.328827 244.071472,175.650436 243.351074,176.079651 
z"/>
<path fill="#013FA8" opacity="1.000000" stroke="none" 
	d="
M409.584930,212.998596 
	C412.277435,213.411758 414.552429,213.825974 416.913696,214.617645 
	C412.863617,216.332642 408.759247,217.364731 405.066406,213.381256 
	C406.383026,213.007141 407.775208,213.003403 409.584930,212.998596 
z"/>
<path fill="#012B87" opacity="1.000000" stroke="none" 
	d="
M264.577881,153.036880 
	C263.460907,158.529495 260.148254,155.858353 257.087769,154.376236 
	C259.382477,153.669327 261.765320,153.342590 264.577881,153.036880 
z"/>
<path fill="#0455DA" opacity="1.000000" stroke="none" 
	d="
M245.331940,174.951416 
	C244.972244,173.943390 244.958817,172.879593 244.955353,171.417496 
	C247.770737,171.839142 247.562088,173.213348 245.331940,174.951416 
z"/>
<path fill="#023DA5" opacity="1.000000" stroke="none" 
	d="
M385.048706,187.315353 
	C385.640167,187.450928 386.275421,187.905121 386.956573,188.676392 
	C386.365784,188.540298 385.729156,188.087143 385.048706,187.315353 
z"/>
<path fill="#013498" opacity="1.000000" stroke="none" 
	d="
M340.999664,147.411331 
	C341.513641,147.263809 342.078491,147.390137 342.818329,147.757629 
	C342.345734,147.894257 341.698120,147.789719 340.999664,147.411331 
z"/>
<path fill="#01257D" opacity="1.000000" stroke="none" 
	d="
M225.062027,255.054184 
	C225.062027,255.054184 225.014740,255.508698 224.629807,255.753143 
	C221.224503,258.443024 217.755875,260.507629 215.283066,263.418243 
	C210.980942,268.482056 206.173935,270.846832 199.354950,270.150055 
	C193.125198,269.513489 186.775085,269.903473 180.486343,270.103119 
	C178.937210,270.152313 177.420425,271.220703 175.888763,271.820526 
	C173.589020,271.833710 171.289291,271.846893 168.523590,271.541992 
	C168.035965,270.822876 168.014282,270.421844 167.992599,270.020813 
	C169.046265,269.681091 170.083252,269.119751 171.156403,269.038940 
	C176.555237,268.632446 178.708450,265.474335 176.636749,260.645599 
	C176.003510,259.169617 174.574646,257.951630 174.243011,256.453278 
	C173.914505,254.969070 174.510437,253.280243 174.788803,250.943237 
	C180.063675,253.473022 186.553604,247.632156 190.533615,253.009018 
	C195.862061,252.258499 200.339691,251.675812 204.798035,250.971054 
	C206.160553,250.755661 207.470047,250.204971 208.905045,249.422897 
	C211.373306,249.032059 213.740341,249.026154 216.645386,249.403580 
	C220.123611,250.534042 223.063843,251.281189 225.878601,252.324005 
	C225.522766,253.431183 225.292404,254.242676 225.062027,255.054184 
z"/>
<path fill="#0239A0" opacity="1.000000" stroke="none" 
	d="
M144.750580,273.126831 
	C143.244720,276.446991 141.666473,280.070160 136.581253,278.548706 
	C136.037155,278.385895 134.575043,280.095123 134.097641,281.181488 
	C133.351318,282.879700 133.082672,284.787811 132.252365,286.744751 
	C131.932266,284.735809 131.621979,282.502625 132.124634,280.470581 
	C132.505859,278.929413 134.420898,276.307983 134.802322,276.450226 
	C139.068237,278.040497 141.400543,274.707001 144.750580,273.126831 
z"/>
<path fill="#0239A0" opacity="1.000000" stroke="none" 
	d="
M167.577728,270.020844 
	C168.014282,270.421844 168.035965,270.822876 168.093414,271.520264 
	C166.664886,271.847839 165.200577,271.879028 163.339188,271.561096 
	C162.965317,270.814117 162.988525,270.416199 163.011719,270.018280 
	C164.395432,270.019135 165.779129,270.020020 167.577728,270.020844 
z"/>
<path fill="#01257D" opacity="1.000000" stroke="none" 
	d="
M162.804596,269.766357 
	C162.988525,270.416199 162.965317,270.814117 162.905655,271.505554 
	C162.869202,271.799072 162.421722,271.896484 162.197556,271.944702 
	C161.517120,271.944824 161.060852,271.896729 160.281097,271.722748 
	C160.837555,270.902740 161.717499,270.208618 162.804596,269.766357 
z"/>
<path fill="#0239A0" opacity="1.000000" stroke="none" 
	d="
M122.751205,295.103027 
	C122.577736,295.957092 122.056915,296.827026 121.229378,297.786865 
	C121.416389,296.957489 121.910126,296.038177 122.751205,295.103027 
z"/>
<path fill="#0239A0" opacity="1.000000" stroke="none" 
	d="
M146.850250,271.779785 
	C146.590012,272.352570 146.151154,272.697876 145.427032,273.079041 
	C145.651718,272.594025 146.161682,272.073151 146.850250,271.779785 
z"/>
<path fill="#012986" opacity="1.000000" stroke="none" 
	d="
M263.975159,213.601868 
	C266.022308,213.210541 268.079071,213.165985 270.648682,213.488358 
	C273.226654,214.992050 275.311432,216.095093 277.347107,217.282303 
	C278.150604,217.750885 279.541229,218.494888 279.482025,218.964264 
	C278.813263,224.264191 282.788055,223.359207 285.788330,224.133514 
	C287.023438,224.452271 287.939056,226.008881 288.533569,227.006439 
	C283.044891,227.367828 278.022675,227.725220 272.534424,228.077194 
	C267.379944,228.054657 262.691437,228.037537 257.992828,227.651733 
	C257.368958,226.613312 256.755219,225.943573 256.141479,225.273834 
	C255.759918,226.189651 255.378357,227.105484 254.632202,228.036591 
	C253.538040,228.066940 252.808456,228.082031 251.727875,228.098663 
	C249.915771,229.728058 248.454681,231.355896 246.996338,232.991470 
	C246.999084,232.999222 246.983658,232.993484 246.641693,233.031174 
	C244.540085,235.050949 242.780441,237.033035 240.958038,239.334015 
	C239.933472,240.437775 238.971680,241.222672 237.755920,241.999542 
	C237.332581,241.987305 237.163239,241.983109 236.993896,241.978928 
	C233.448074,237.562576 235.657730,232.742432 236.404709,228.182251 
	C236.544220,227.330444 239.816605,226.439148 241.569473,226.568085 
	C243.150009,226.684341 244.629517,228.173737 246.116623,229.036087 
	C247.918121,226.930649 249.373199,225.200317 250.860641,223.498245 
	C253.455200,220.529236 256.352997,219.080658 260.502228,220.543869 
	C261.863770,221.024033 263.797668,219.881119 265.680328,219.429977 
	C264.994019,217.211319 264.489380,215.579987 263.975159,213.601868 
z"/>
<path fill="#053FC9" opacity="1.000000" stroke="none" 
	d="
M258.002960,228.020416 
	C262.691437,228.037537 267.379944,228.054657 272.846741,228.346802 
	C282.026764,228.745010 290.428558,228.949097 298.830231,228.944901 
	C301.553467,228.943542 304.276398,228.396515 307.289001,228.118713 
	C308.041473,228.439713 308.504425,228.741089 309.012329,229.381256 
	C310.697632,231.154678 312.337982,232.589325 314.277618,234.189163 
	C316.735199,237.216568 318.893463,240.078781 320.925079,243.169983 
	C320.526672,243.608566 320.254944,243.818161 319.619720,244.015961 
	C317.490356,243.353760 315.724426,242.703354 313.795349,241.814026 
	C313.105774,241.364227 312.579315,241.153381 312.001160,240.602295 
	C309.460266,236.447083 306.748077,233.001892 301.553864,233.010849 
	C287.000702,233.035934 272.446960,232.966904 257.894958,233.097595 
	C256.573914,233.109451 255.263382,234.291611 253.674225,235.068054 
	C252.600082,235.805649 251.799683,236.405502 250.656921,237.037079 
	C249.205612,238.374496 248.096649,239.680176 246.993851,240.992935 
	C247.000000,241.000000 246.985336,240.988144 246.650513,241.044067 
	C244.570099,242.431839 242.824493,243.763672 240.951782,245.340454 
	C240.524017,246.047577 240.223373,246.509735 239.594421,247.025757 
	C237.207138,248.763687 235.148148,250.447784 233.089157,252.131897 
	C231.893921,252.059723 230.698700,251.987549 229.195618,251.600433 
	C228.924438,250.859344 228.961121,250.433197 228.997787,250.007050 
	C231.005127,248.244980 233.012482,246.482910 235.090210,244.372284 
	C235.160599,244.023727 235.601639,244.059631 236.137543,243.998550 
	C237.118927,243.294174 237.564407,242.650864 238.009888,242.007568 
	C238.971680,241.222672 239.933472,240.437775 241.298584,239.291016 
	C243.462494,236.950592 245.223068,234.972031 246.983643,232.993469 
	C246.983658,232.993484 246.999084,232.999222 247.339905,232.961914 
	C249.146790,231.315460 250.612854,229.706284 252.078888,228.097122 
	C252.808456,228.082031 253.538040,228.066940 255.007874,228.050079 
	C256.499756,228.039017 257.251343,228.029724 258.002960,228.020416 
z"/>
<path fill="#012C87" opacity="1.000000" stroke="none" 
	d="
M247.973129,213.640503 
	C250.684158,213.196304 253.409317,213.144409 256.586365,213.157501 
	C257.121613,215.421265 256.778107,217.397720 253.902649,216.955475 
	C251.676071,216.613052 248.141937,218.642670 247.973129,213.640503 
z"/>
<path fill="#013193" opacity="1.000000" stroke="none" 
	d="
M231.296661,214.291046 
	C234.709976,213.681030 238.417252,213.358063 242.585373,213.072937 
	C239.649765,216.895111 235.467270,214.542664 231.296661,214.291046 
z"/>
<path fill="#013193" opacity="1.000000" stroke="none" 
	d="
M221.283600,215.937103 
	C221.439285,215.346832 221.913986,214.726059 222.710999,214.088577 
	C222.556427,214.683456 222.079514,215.295029 221.283600,215.937103 
z"/>
<path fill="#032CA7" opacity="1.000000" stroke="none" 
	d="
M237.755920,241.999542 
	C237.564407,242.650864 237.118927,243.294174 236.358521,243.987396 
	C236.203094,243.474609 236.362625,242.911880 236.758026,242.164032 
	C237.163239,241.983109 237.332581,241.987305 237.755920,241.999542 
z"/>
<path fill="#013193" opacity="1.000000" stroke="none" 
	d="
M321.051727,242.941010 
	C318.893463,240.078781 316.735199,237.216568 314.286072,233.931366 
	C313.997833,233.336609 314.000458,233.164856 314.371460,233.000031 
	C316.589661,233.878418 318.439423,234.749878 320.424286,235.684982 
	C320.424286,232.007629 320.424286,228.644485 320.424286,225.135056 
	C313.995514,227.229675 311.922791,225.600494 312.785645,219.343567 
	C313.198303,216.351166 311.749207,215.970642 309.419525,215.989441 
	C303.946564,216.033585 298.472870,215.981964 292.999664,216.007568 
	C290.921326,216.017288 288.835907,216.143066 288.965485,213.118027 
	C302.027222,213.408127 315.087952,213.821625 328.151184,213.928589 
	C333.500885,213.972382 338.856018,213.353806 344.968506,213.185287 
	C348.393921,214.266724 351.685638,214.512070 353.606781,216.262054 
	C359.185120,221.343292 364.283722,226.962738 369.439056,232.493576 
	C374.952087,238.408096 380.861115,242.505569 389.756165,241.256180 
	C393.310974,240.756882 397.248444,242.981857 400.542053,243.982269 
	C385.730469,244.003098 371.388519,244.008423 356.984680,243.652832 
	C354.419464,239.547394 352.439148,239.931152 350.507874,244.037811 
	C341.403107,244.013626 332.722565,244.002502 323.744415,243.873016 
	C322.648438,243.483444 321.850067,243.212234 321.051727,242.941010 
z"/>
<path fill="#01308F" opacity="1.000000" stroke="none" 
	d="
M353.997559,189.002579 
	C355.468384,192.303009 356.487885,195.922974 358.535370,198.815170 
	C360.636658,201.783279 359.813721,203.494980 357.221985,204.756592 
	C355.517181,205.586472 353.435944,205.830719 351.498047,205.960037 
	C344.896454,206.400528 338.281158,206.634857 331.678192,207.058548 
	C330.823425,207.113373 330.010254,207.816925 329.084656,208.598724 
	C326.626251,208.980499 324.261017,208.983505 321.360840,208.606827 
	C318.035004,206.578232 315.298767,205.510254 312.988007,208.964569 
	C307.553223,208.972427 302.116516,209.061691 296.684845,208.935562 
	C295.175629,208.900513 293.681213,208.227051 292.068054,207.462708 
	C296.818939,206.718643 301.683411,206.378265 306.543823,205.987640 
	C308.320251,205.844864 310.087067,205.582748 311.858276,205.375076 
	C311.887909,204.957245 311.917542,204.539429 311.947174,204.121597 
	C309.961060,203.019852 307.974945,201.918091 304.837219,200.177521 
	C309.031219,199.137680 311.983856,198.405594 315.635193,197.500290 
	C314.066681,195.453598 313.132324,194.234375 311.909302,192.638489 
	C314.349884,190.345856 316.809540,188.270554 318.958313,185.912018 
	C319.950470,184.823013 320.335236,183.180679 321.464569,181.394409 
	C327.409119,180.998993 332.920959,181.396530 338.350922,180.899353 
	C345.506378,180.244217 350.036285,183.889923 353.997559,189.002579 
z"/>
<path fill="#0137AC" opacity="1.000000" stroke="none" 
	d="
M401.011719,243.966736 
	C397.248444,242.981857 393.310974,240.756882 389.756165,241.256180 
	C380.861115,242.505569 374.952087,238.408096 369.439056,232.493576 
	C364.283722,226.962738 359.185120,221.343292 353.606781,216.262054 
	C351.685638,214.512070 348.393921,214.266724 345.368774,213.177017 
	C345.004272,211.952896 344.999329,210.885788 344.989929,209.419739 
	C354.186035,206.779663 358.948059,213.342545 364.438599,218.666595 
	C367.162109,221.307480 370.303497,223.517456 373.761780,226.249237 
	C375.179321,227.716766 376.090881,228.857468 377.053650,230.334015 
	C378.402435,231.447403 379.699982,232.224915 380.998779,233.001221 
	C381.000000,233.000000 380.997559,233.002441 380.997559,233.002441 
	C382.889557,233.667526 384.773926,234.882339 386.674744,234.908569 
	C399.132965,235.080444 411.594757,234.995499 424.889496,234.995880 
	C426.816162,234.664505 427.908691,234.331635 429.001221,233.998764 
	C429.444580,233.998154 429.887939,233.997543 431.030731,233.952972 
	C435.156555,232.552475 438.582947,231.195908 442.504883,229.643127 
	C443.776459,234.503128 444.887604,238.750046 445.783508,243.111115 
	C445.297882,243.425323 445.109070,243.684692 444.569824,244.002899 
	C436.420929,244.008621 428.703918,244.014862 420.992065,244.013153 
	C420.997253,244.005188 421.004730,243.987854 420.951050,243.658707 
	C419.930603,243.547714 418.963776,243.765869 417.529572,243.988312 
	C411.712006,243.983963 406.361877,243.975342 401.011719,243.966736 
z"/>
<path fill="#012C87" opacity="1.000000" stroke="none" 
	d="
M288.501587,213.088989 
	C288.835907,216.143066 290.921326,216.017288 292.999664,216.007568 
	C298.472870,215.981964 303.946564,216.033585 309.419525,215.989441 
	C311.749207,215.970642 313.198303,216.351166 312.785645,219.343567 
	C311.922791,225.600494 313.995514,227.229675 320.424286,225.135056 
	C320.424286,228.644485 320.424286,232.007629 320.424286,235.684982 
	C318.439423,234.749878 316.589661,233.878418 314.218719,232.705688 
	C312.120850,231.283768 310.544098,230.163116 308.967346,229.042450 
	C308.504425,228.741089 308.041473,228.439713 307.005859,227.805115 
	C300.622101,227.315399 294.811035,227.158920 289.000000,227.002441 
	C287.939056,226.008881 287.023438,224.452271 285.788330,224.133514 
	C282.788055,223.359207 278.813263,224.264191 279.482025,218.964264 
	C279.541229,218.494888 278.150604,217.750885 277.347107,217.282303 
	C275.311432,216.095093 273.226654,214.992050 271.086487,213.530121 
	C276.686829,213.156601 282.362274,213.108261 288.501587,213.088989 
z"/>
<path fill="#159CE3" opacity="1.000000" stroke="none" 
	d="
M249.372498,202.225647 
	C251.238480,202.098083 252.836227,202.226273 254.433975,202.354446 
	C254.278397,203.724396 254.581085,205.506531 253.830353,206.341141 
	C253.162521,207.083664 250.588898,207.353348 250.164398,206.809937 
	C249.330521,205.742462 249.405396,203.965073 249.372498,202.225647 
z"/>
<path fill="#00359C" opacity="1.000000" stroke="none" 
	d="
M313.433411,208.978210 
	C315.298767,205.510254 318.035004,206.578232 320.917542,208.600616 
	C318.632355,208.980011 316.255585,208.985931 313.433411,208.978210 
z"/>
<path fill="#01308F" opacity="1.000000" stroke="none" 
	d="
M257.033600,201.382294 
	C257.861176,202.863190 258.677399,204.719925 259.257202,206.783112 
	C258.354584,205.245743 257.688416,203.501938 257.033600,201.382294 
z"/>
<path fill="#01308F" opacity="1.000000" stroke="none" 
	d="
M272.299133,207.990173 
	C272.566315,207.538116 273.128387,207.072662 273.859741,206.843109 
	C273.550659,207.378250 273.072357,207.677521 272.299133,207.990173 
z"/>
<path fill="#022B9D" opacity="1.000000" stroke="none" 
	d="
M173.245209,292.921265 
	C173.512390,292.463196 174.084030,292.008209 174.829300,291.770538 
	C174.518509,292.299988 174.034088,292.612122 173.245209,292.921265 
z"/>
<path fill="#02267D" opacity="1.000000" stroke="none" 
	d="
M149.854706,188.587021 
	C148.974884,189.209854 147.873459,189.598969 146.398773,189.977676 
	C146.020096,189.212860 146.014679,188.458450 146.028641,187.325470 
	C147.243057,187.415695 148.438080,187.884506 149.854706,188.587021 
z"/>
<path fill="#052273" opacity="1.000000" stroke="none" 
	d="
M228.621155,249.838181 
	C228.961121,250.433197 228.924438,250.859344 228.852448,251.603455 
	C228.817123,251.921402 228.396027,251.931793 227.857742,251.955292 
	C226.880997,251.995300 226.442520,252.011810 226.004059,252.028320 
	C223.063843,251.281189 220.123611,250.534042 217.088928,249.411316 
	C220.744492,249.246933 224.494522,249.458130 228.621155,249.838181 
z"/>
<path fill="#0BC4F6" opacity="1.000000" stroke="none" 
	d="
M157.326935,378.173035 
	C160.002869,377.335968 162.405258,376.655182 164.850906,376.415131 
	C165.124207,376.388336 166.201965,379.000885 165.920425,380.147156 
	C165.469620,381.982544 164.572037,384.175568 163.138214,385.145142 
	C162.105835,385.843262 159.086182,385.369995 158.393524,384.395386 
	C157.310623,382.871735 157.446518,380.481842 157.326935,378.173035 
z"/>
<path fill="#02267C" opacity="1.000000" stroke="none" 
	d="
M190.659286,356.064575 
	C190.917801,362.097443 190.050446,363.044037 181.353516,365.983276 
	C184.102905,362.727875 187.230484,359.472717 190.659286,356.064575 
z"/>
<path fill="#012173" opacity="1.000000" stroke="none" 
	d="
M177.246368,359.808838 
	C176.783020,359.901093 176.372696,359.764587 175.962357,359.628113 
	C176.251709,359.444916 176.541077,359.261719 177.123474,359.101868 
	C177.416504,359.125244 177.299393,359.580078 177.246368,359.808838 
z"/>
<path fill="#0337B4" opacity="1.000000" stroke="none" 
	d="
M229.051590,169.454254 
	C230.109573,169.052582 231.155777,169.011276 232.595642,168.968613 
	C231.918106,170.306274 230.846893,171.645279 229.452850,173.046906 
	C229.087570,172.922134 229.045120,172.734741 228.951416,172.263367 
	C228.946716,171.257782 228.993271,170.536194 229.051590,169.454254 
z"/>
<path fill="#0337B4" opacity="1.000000" stroke="none" 
	d="
M239.291946,163.922897 
	C239.444183,163.062195 239.945435,162.171005 240.749313,161.166168 
	C240.581619,161.999146 240.111298,162.945770 239.291946,163.922897 
z"/>
<path fill="#013FA8" opacity="1.000000" stroke="none" 
	d="
M445.998779,242.996948 
	C444.887604,238.750046 443.776459,234.503128 442.504883,229.643127 
	C438.582947,231.195908 435.156555,232.552475 431.363251,233.953506 
	C432.918427,231.298477 434.840515,228.598969 437.498291,224.866211 
	C435.823792,224.048859 433.430725,222.880753 431.179749,221.782013 
	C429.581543,219.862991 427.856598,217.791763 426.061890,215.367126 
	C428.742828,215.305969 431.493500,215.598251 434.670410,216.153595 
	C435.096680,216.416656 435.112488,216.857910 435.109589,217.233917 
	C436.439545,218.713654 437.772430,219.817398 439.418030,220.920822 
	C440.147614,220.926910 440.564484,220.933334 441.168640,221.215332 
	C444.675995,223.973114 446.177246,222.254440 447.331604,218.975876 
	C448.395172,218.260696 449.155792,217.604462 450.312744,216.925049 
	C454.075287,216.267746 457.475739,214.950592 460.801819,215.117386 
	C475.432495,215.851074 490.235168,212.781967 504.726318,217.588516 
	C509.421417,219.145828 513.226990,216.382156 512.849304,212.682602 
	C512.613647,210.373993 511.417877,206.843765 509.812073,206.286987 
	C507.022705,205.319809 503.539246,205.963898 500.431946,206.462738 
	C498.786621,206.726868 497.331665,208.845963 495.771881,208.869324 
	C481.152283,209.088379 466.528625,209.034988 451.577820,209.053375 
	C450.494476,208.708969 449.739441,208.371582 448.990753,207.692871 
	C449.002289,206.896606 449.007477,206.441650 449.459961,205.987442 
	C455.307495,206.255508 460.524750,206.101517 464.345551,201.301636 
	C464.953003,200.538513 466.356537,200.131119 467.425690,200.059097 
	C473.839752,199.627029 480.272491,198.947861 486.682343,199.091675 
	C498.418152,199.354996 510.142395,200.133316 521.871460,200.696899 
	C527.424194,201.235260 532.976929,201.773621 539.245850,202.560028 
	C546.653442,209.705673 553.282288,216.666397 560.104553,223.432037 
	C561.114746,224.433792 563.038696,224.514053 564.573975,225.308273 
	C564.461548,226.142380 564.315063,226.688843 563.935303,227.286926 
	C563.761414,227.838242 563.820801,228.337952 563.897339,229.180298 
	C563.287842,230.692047 562.661133,231.861130 561.871826,233.114609 
	C561.822083,233.458740 561.934937,233.718475 562.153442,234.128616 
	C562.259155,234.279022 562.138611,234.626297 562.102966,235.059113 
	C562.045715,238.327560 562.024109,241.163162 561.533936,243.998154 
	C555.044373,243.992279 549.023438,243.987030 542.963257,243.627975 
	C541.277893,240.464325 539.945740,237.397751 537.909729,234.906448 
	C535.052002,231.409866 531.671082,228.340958 528.513916,225.089111 
	C526.059265,227.731644 523.595642,230.366043 521.167603,233.032806 
	C520.979797,233.239014 521.041809,233.672668 520.636230,234.063873 
	C518.520020,237.413284 516.755432,240.699432 514.658691,243.987762 
	C513.883545,243.993317 513.440552,243.996658 512.998779,244.000000 
	C513.000000,244.000000 513.000977,243.996017 512.921814,243.632538 
	C510.655182,241.573639 508.565521,240.669724 506.530396,243.993774 
	C500.376404,243.988052 494.690216,243.980026 488.946899,243.594299 
	C487.463074,242.478500 486.102203,241.530823 484.592621,241.056519 
	C482.792480,240.490921 480.864899,240.330994 478.988525,239.567825 
	C479.247284,236.392349 480.255157,233.455917 479.617676,230.935654 
	C478.289307,225.683990 474.238739,224.029282 468.750427,225.150848 
	C467.975311,226.207535 467.497955,227.128220 467.021271,228.286377 
	C467.021942,228.523849 467.005371,228.998520 466.646851,229.069824 
	C465.868744,230.448746 465.449158,231.756393 465.040588,233.428772 
	C465.368927,236.192703 465.686310,238.591888 465.700989,241.124863 
	C464.264740,241.841171 463.131165,242.423706 461.530884,243.005936 
	C456.042358,243.002747 451.020569,242.999847 445.998779,242.996948 
z"/>
<path fill="#0641A6" opacity="1.000000" stroke="none" 
	d="
M590.987793,169.024414 
	C599.719971,166.469894 608.303894,162.984024 617.216797,161.564438 
	C633.294250,159.003723 649.418762,159.935486 665.170593,164.592316 
	C666.258057,164.913818 667.286804,165.434174 668.605469,166.333603 
	C667.334290,167.636673 665.517517,168.178055 664.315735,169.350006 
	C659.160522,174.377289 654.046204,179.463333 649.218140,184.800430 
	C647.393066,186.817917 646.381531,189.571411 644.534912,192.058075 
	C633.095337,192.869598 622.120056,193.612457 610.997803,194.029297 
	C605.518066,188.297974 599.965149,183.085938 594.962341,177.390778 
	C593.002502,175.159790 592.276001,171.845398 590.987793,169.024414 
z"/>
<path fill="#053B9F" opacity="1.000000" stroke="none" 
	d="
M721.000488,197.905762 
	C730.060425,204.094757 739.143677,204.734070 748.716309,198.175629 
	C749.461914,198.545456 749.748962,198.759720 750.046021,199.275208 
	C750.372559,200.054657 750.688965,200.532898 750.963989,201.360260 
	C745.545044,207.649704 740.239136,213.656952 734.766724,219.508469 
	C730.623474,223.938858 726.516052,228.457123 721.943237,232.417343 
	C716.388977,237.227585 713.240234,246.764542 702.951294,242.555328 
	C706.405090,233.899384 705.539185,226.204803 699.378479,219.579788 
	C696.123535,216.079605 692.355530,213.056549 689.067383,209.591705 
	C691.462097,207.808426 694.087830,206.625961 695.656982,204.617859 
	C699.085938,200.229645 703.453735,199.835800 708.497437,199.823471 
	C712.666626,199.813293 716.832947,198.590988 721.000488,197.905762 
z"/>
<path fill="#0243AA" opacity="1.000000" stroke="none" 
	d="
M513.000732,179.736725 
	C485.046173,180.031174 457.256592,180.061615 429.467346,179.978302 
	C427.442200,179.972229 425.419525,179.141281 423.635590,178.342499 
	C435.662781,177.992950 447.450165,178.012726 459.237457,177.989410 
	C465.837250,177.976349 471.450348,172.693619 471.977478,166.079544 
	C488.200500,165.919189 504.423523,165.758835 520.913757,165.884430 
	C518.509216,170.595200 515.837524,175.019989 513.000732,179.736725 
z"/>
<path fill="#073FA4" opacity="1.000000" stroke="none" 
	d="
M699.655029,180.796906 
	C706.017700,185.658829 712.380371,190.520752 718.539185,195.805008 
	C713.557495,195.486710 708.799194,194.327133 703.997192,194.102692 
	C696.611755,193.757492 688.631287,200.362183 686.244019,207.747894 
	C679.923950,204.425186 673.968445,201.183121 668.102051,197.697144 
	C668.561340,196.517441 668.705933,195.377365 669.338379,194.679459 
	C673.118652,190.508148 676.817017,186.229340 680.934082,182.408203 
	C683.711426,179.830536 687.132751,177.946747 690.265991,175.752563 
	C690.265991,175.752563 690.093933,175.855560 690.131958,176.144165 
	C692.817383,179.005386 694.934692,182.729797 699.655029,180.796906 
z"/>
<path fill="#032699" opacity="1.000000" stroke="none" 
	d="
M521.831116,200.327332 
	C510.142395,200.133316 498.418152,199.354996 486.682343,199.091675 
	C480.272491,198.947861 473.839752,199.627029 467.425690,200.059097 
	C466.356537,200.131119 464.953003,200.538513 464.345551,201.301636 
	C460.524750,206.101517 455.307495,206.255508 449.394714,205.650482 
	C447.928619,204.866440 446.975067,204.420105 446.021515,203.973755 
	C442.292450,198.971252 444.916931,194.042725 446.295410,189.141724 
	C446.438721,188.632095 447.569519,188.400208 448.624390,188.427002 
	C448.360962,190.633224 447.810486,192.492874 447.048584,194.261459 
	C445.744324,197.289124 445.554688,200.004272 448.657288,202.091293 
	C451.747803,204.170212 454.923065,205.280228 458.092346,202.370422 
	C460.215363,200.421158 462.152100,198.269012 463.688049,196.701721 
	C461.854767,193.306076 460.424622,190.657135 458.994476,188.008194 
	C459.321228,188.055222 459.805145,187.979309 459.952026,188.167038 
	C464.801208,194.365555 471.666931,192.865143 478.129639,193.016220 
	C487.529053,193.235962 496.929138,193.797592 506.322266,193.680405 
	C508.925171,193.647919 512.998718,194.199432 513.669434,189.285629 
	C516.546814,192.667480 519.168762,196.312622 521.831116,200.327332 
z"/>
<path fill="#083797" opacity="1.000000" stroke="none" 
	d="
M751.913818,194.108917 
	C755.390564,186.626694 756.122681,179.182022 752.059937,171.384979 
	C762.007812,170.992279 771.504517,171.023758 780.937134,171.395599 
	C776.721069,176.684326 772.877991,181.947357 768.289917,186.451401 
	C765.910645,188.787048 764.605347,190.869644 764.791870,194.306442 
	C763.735718,194.675964 762.894409,194.804352 761.975159,194.602448 
	C760.596558,194.174042 759.295837,194.075928 757.562256,193.984833 
	C755.390869,194.030869 753.652344,194.069901 751.913818,194.108917 
z"/>
<path fill="#0442AB" opacity="1.000000" stroke="none" 
	d="
M565.949768,182.148682 
	C570.394226,179.418350 574.838684,176.688019 579.699341,174.339798 
	C582.476624,175.771667 585.270325,176.336319 587.106934,177.974258 
	C590.699646,181.178497 593.865479,184.890991 596.986633,188.580399 
	C598.288147,190.118927 599.024048,192.136017 599.665405,194.111786 
	C592.271179,194.146606 585.226807,194.057755 578.188965,193.793884 
	C577.355225,193.762634 576.441284,192.889282 575.762573,192.208801 
	C572.454712,188.892273 569.214172,185.508606 565.949768,182.148682 
z"/>
<path fill="#072C99" opacity="1.000000" stroke="none" 
	d="
M690.125732,175.412598 
	C687.132751,177.946747 683.711426,179.830536 680.934082,182.408203 
	C676.817017,186.229340 673.118652,190.508148 669.338379,194.679459 
	C668.705933,195.377365 668.561340,196.517441 667.741943,197.736176 
	C666.832581,197.883453 666.372559,197.747803 665.793823,197.461624 
	C665.467285,197.142563 665.229675,197.060898 664.585938,197.006714 
	C659.803284,195.661774 655.396729,194.376236 650.994507,192.817184 
	C650.994324,192.361389 650.989807,192.179077 651.205688,191.959946 
	C651.425964,191.923111 651.870728,191.880859 652.301392,191.920670 
	C655.489502,192.301849 658.246887,192.643204 661.197815,193.213867 
	C662.211243,192.853348 663.175110,192.388840 663.820679,191.647247 
	C665.106323,190.170334 665.890930,187.929611 667.462402,187.091690 
	C671.845947,184.754196 673.529297,180.019730 676.947632,177.169144 
	C679.539673,175.007584 680.047729,173.014267 680.163940,170.269913 
	C683.437805,171.870819 686.711609,173.471741 690.125732,175.412598 
z"/>
<path fill="#022B9D" opacity="1.000000" stroke="none" 
	d="
M600.017151,193.935516 
	C599.024048,192.136017 598.288147,190.118927 596.986633,188.580399 
	C593.865479,184.890991 590.699646,181.178497 587.106934,177.974258 
	C585.270325,176.336319 582.476624,175.771667 580.054810,174.416138 
	C583.411987,172.443207 586.829956,170.776031 590.617798,169.066650 
	C592.276001,171.845398 593.002502,175.159790 594.962341,177.390778 
	C599.965149,183.085938 605.518066,188.297974 610.796753,194.245239 
	C610.170776,194.878387 609.598999,194.969574 608.997437,195.000000 
	C608.967712,194.939209 608.850342,194.871964 608.791870,194.729340 
	C608.733398,194.586700 608.332581,194.577286 608.332581,194.577286 
	C608.332581,194.577286 608.187561,194.986847 608.128357,195.085480 
	C608.069153,195.184097 608.012756,195.407150 607.666443,195.582977 
	C606.554565,195.826691 605.788940,195.894577 605.024292,195.640015 
	C604.350952,194.881027 603.676819,194.444489 602.629150,194.001923 
	C601.509521,193.975769 600.763367,193.955627 600.017151,193.935516 
z"/>
<path fill="#065DD2" opacity="1.000000" stroke="none" 
	d="
M651.870728,191.880859 
	C651.870728,191.880859 651.425964,191.923111 650.918274,191.968170 
	C649.930359,192.293137 649.450073,192.573059 648.621643,192.912964 
	C647.182007,192.645111 646.090637,192.317261 644.999207,191.989426 
	C646.381531,189.571411 647.393066,186.817917 649.218140,184.800430 
	C654.046204,179.463333 659.160522,174.377289 664.315735,169.350006 
	C665.517517,168.178055 667.334290,167.636673 668.965088,166.471527 
	C671.247131,166.872437 673.432251,167.606995 675.751465,168.984467 
	C673.913086,170.744965 671.940552,171.862549 669.754395,173.090332 
	C669.274170,173.397842 669.086975,173.653275 668.680298,174.121063 
	C662.877991,180.143829 657.374390,186.012344 651.870728,191.880859 
z"/>
<path fill="#065DD2" opacity="1.000000" stroke="none" 
	d="
M599.665405,194.111786 
	C600.763367,193.955627 601.509521,193.975769 602.629150,194.382080 
	C603.001282,195.529205 603.000000,196.290146 602.807678,197.331726 
	C601.098877,198.089493 599.581055,198.566605 597.800903,198.766693 
	C588.937988,198.618301 580.111023,201.075775 571.855591,195.715607 
	C570.149658,194.597015 568.603882,193.770676 567.007812,192.603210 
	C565.312439,190.500015 563.667297,188.737946 562.012207,186.662491 
	C562.005554,185.931137 562.008789,185.513168 562.012024,185.095184 
	C563.120056,184.134949 564.228088,183.174713 565.642944,182.181610 
	C569.214172,185.508606 572.454712,188.892273 575.762573,192.208801 
	C576.441284,192.889282 577.355225,193.762634 578.188965,193.793884 
	C585.226807,194.057755 592.271179,194.146606 599.665405,194.111786 
z"/>
<path fill="#065DD2" opacity="1.000000" stroke="none" 
	d="
M686.608582,207.828552 
	C688.631287,200.362183 696.611755,193.757492 703.997192,194.102692 
	C708.799194,194.327133 713.557495,195.486710 718.630554,196.132507 
	C719.579834,196.463333 720.233948,196.888992 720.944275,197.610199 
	C716.832947,198.590988 712.666626,199.813293 708.497437,199.823471 
	C703.453735,199.835800 699.085938,200.229645 695.656982,204.617859 
	C694.087830,206.625961 691.462097,207.808426 688.809692,209.573700 
	C687.738708,209.130905 687.173645,208.479736 686.608582,207.828552 
z"/>
<path fill="#064AD0" opacity="1.000000" stroke="none" 
	d="
M781.001160,171.055222 
	C771.504517,171.023758 762.007812,170.992279 752.006531,170.995956 
	C750.692383,170.260406 749.882935,169.489700 749.002441,168.439301 
	C748.958008,167.682327 748.984680,167.205032 749.016846,166.369354 
	C751.900818,165.991913 754.779114,165.959503 757.657471,165.956589 
	C763.789734,165.950394 769.921936,165.959503 776.805969,166.253632 
	C781.706909,167.355515 785.856140,168.166107 790.005310,168.976700 
	C790.005310,168.976700 790.001770,168.991440 789.997803,169.367996 
	C789.983704,170.492340 789.973572,171.240143 789.734741,171.993484 
	C789.505981,171.999039 789.048462,172.005112 788.740356,171.873840 
	C787.583008,171.529724 786.733765,171.316864 785.478149,171.079834 
	C783.714844,171.055511 782.358032,171.055374 781.001160,171.055222 
z"/>
<path fill="#072C99" opacity="1.000000" stroke="none" 
	d="
M567.058105,192.944305 
	C568.603882,193.770676 570.149658,194.597015 571.835632,196.104126 
	C569.324036,201.404755 565.076782,199.957825 561.174744,199.536926 
	C555.976929,198.976257 550.800476,198.267883 545.723145,201.093002 
	C545.477905,201.081009 544.993164,201.002594 544.965332,200.643524 
	C544.903748,200.046768 544.869934,199.809067 544.836121,199.571381 
	C550.333557,194.768188 555.830994,189.965012 561.670166,185.128510 
	C562.008789,185.513168 562.005554,185.931137 561.739014,186.857025 
	C560.168152,189.021225 558.860535,190.677505 557.070984,192.944305 
	C561.155334,192.944305 564.106689,192.944305 567.058105,192.944305 
z"/>
<path fill="#0E76D3" opacity="1.000000" stroke="none" 
	d="
M544.993164,201.002594 
	C544.993164,201.002594 545.477905,201.081009 545.884521,201.376266 
	C553.087097,208.459000 559.883057,215.246475 566.648926,222.347504 
	C565.925903,223.447601 565.232910,224.234131 564.539917,225.020645 
	C563.038696,224.514053 561.114746,224.433792 560.104553,223.432037 
	C553.282288,216.666397 546.653442,209.705673 539.662048,202.534210 
	C540.665649,201.566177 541.969238,200.872009 543.733521,200.321442 
	C544.387878,200.752243 544.654175,200.931442 544.993164,201.002594 
z"/>
<path fill="#043BA1" opacity="1.000000" stroke="none" 
	d="
M668.979065,173.966827 
	C669.086975,173.653275 669.274170,173.397842 670.205688,173.097504 
	C673.541931,172.287109 677.994507,175.148712 678.134155,169.369156 
	C678.692749,169.510193 679.251343,169.651245 679.986938,170.031097 
	C680.047729,173.014267 679.539673,175.007584 676.947632,177.169144 
	C673.529297,180.019730 671.845947,184.754196 667.462402,187.091690 
	C665.890930,187.929611 665.106323,190.170334 663.820679,191.647247 
	C663.175110,192.388840 662.211243,192.853348 661.350342,192.868317 
	C662.851624,188.755112 663.471313,183.931442 666.165405,181.997833 
	C669.677307,179.477142 669.883606,177.294525 668.979065,173.966827 
z"/>
<path fill="#064DCF" opacity="1.000000" stroke="none" 
	d="
M751.651733,194.259949 
	C753.652344,194.069901 755.390869,194.030869 757.801575,194.255280 
	C759.666870,194.656723 760.859924,194.794739 762.053040,194.932739 
	C762.894409,194.804352 763.735718,194.675964 765.157898,194.331696 
	C766.217163,194.215271 766.695679,194.314728 767.129395,194.773163 
	C767.091003,196.375076 767.097351,197.617996 766.712891,198.913803 
	C765.534363,199.030930 764.746643,199.095139 763.504517,199.120331 
	C759.409790,199.099640 755.769409,199.117981 751.781738,199.083496 
	C750.968262,199.011780 750.502075,198.992874 750.035950,198.973984 
	C749.748962,198.759720 749.461914,198.545456 749.062866,198.083923 
	C749.763794,196.694778 750.576721,195.552872 751.651733,194.259949 
z"/>
<path fill="#086CD7" opacity="1.000000" stroke="none" 
	d="
M694.506592,132.529861 
	C694.656921,131.454666 694.826599,130.362366 694.988403,129.321014 
	C702.517212,128.733246 705.982239,131.375290 705.022095,137.136169 
	C702.052429,136.237976 699.102478,135.015015 696.100464,133.357986 
	C695.557312,132.780991 695.066040,132.638077 694.574829,132.495148 
	C694.574829,132.495148 694.526062,132.512741 694.506592,132.529861 
z"/>
<path fill="#072C99" opacity="1.000000" stroke="none" 
	d="
M699.486328,180.464661 
	C694.934692,182.729797 692.817383,179.005386 690.197388,176.068146 
	C693.255676,177.179810 696.286621,178.656097 699.486328,180.464661 
z"/>
<path fill="#0341AA" opacity="1.000000" stroke="none" 
	d="
M677.908325,169.200378 
	C677.994507,175.148712 673.541931,172.287109 670.419373,172.987305 
	C671.940552,171.862549 673.913086,170.744965 675.952087,169.267670 
	C676.573242,168.949173 677.127930,168.990387 677.908325,169.200378 
z"/>
<path fill="#0946BC" opacity="1.000000" stroke="none" 
	d="
M694.640442,132.738647 
	C695.066040,132.638077 695.557312,132.780991 695.846069,133.112152 
	C695.331116,133.194305 695.018616,133.088226 694.640442,132.738647 
z"/>
<path fill="#0842A7" opacity="1.000000" stroke="none" 
	d="
M544.965332,200.643524 
	C544.654175,200.931442 544.387878,200.752243 544.059326,200.259521 
	C544.048889,199.921783 544.173401,199.789566 544.567017,199.614365 
	C544.869934,199.809067 544.903748,200.046768 544.965332,200.643524 
z"/>
<path fill="#012881" opacity="1.000000" stroke="none" 
	d="
M832.045898,136.619507 
	C830.611084,136.998734 829.222229,136.999298 827.416626,136.998611 
	C828.770325,135.022919 830.540649,133.048477 832.654175,131.035492 
	C832.695618,132.744904 832.393677,134.492874 832.045898,136.619507 
z"/>
<path fill="#012881" opacity="1.000000" stroke="none" 
	d="
M843.691101,120.140472 
	C842.901550,121.641541 841.803162,123.279358 840.351196,124.956367 
	C841.125793,123.422775 842.253967,121.849998 843.691101,120.140472 
z"/>
<path fill="#033ABC" opacity="1.000000" stroke="none" 
	d="
M790.017334,168.646576 
	C785.856140,168.166107 781.706909,167.355515 777.273926,166.252319 
	C781.100952,166.191360 785.211731,166.423019 789.667358,166.824478 
	C790.017944,167.435013 790.023621,167.875732 790.017334,168.646576 
z"/>
<path fill="#012E8B" opacity="1.000000" stroke="none" 
	d="
M793.967407,169.026337 
	C814.109131,169.019852 834.252258,168.879257 854.391479,169.098114 
	C859.639771,169.155167 864.875793,170.332626 870.450073,171.255524 
	C870.524719,172.013123 870.266724,172.510696 869.896973,173.264160 
	C869.520996,174.010941 869.256836,174.501801 868.676941,175.038544 
	C867.897949,175.711426 867.434753,176.338440 866.637695,177.027222 
	C864.691650,178.414673 862.851013,179.544373 861.512268,181.104691 
	C858.208130,184.955536 855.134277,189.003937 851.599731,193.032074 
	C848.527527,197.091858 844.547241,197.200043 840.245911,197.037811 
	C834.167908,196.808563 828.073914,197.004944 821.594116,197.010681 
	C819.786804,196.340164 818.372131,195.678360 817.204041,194.822540 
	C816.923401,189.689957 816.430237,184.747269 815.837219,179.816605 
	C815.731873,178.941193 815.328247,177.485748 814.857727,177.398224 
	C813.875061,177.215393 812.462524,177.450867 811.766663,178.096588 
	C810.892151,178.908112 810.558350,180.302414 809.657288,182.118286 
	C807.649719,180.778931 805.941650,179.682037 804.279358,178.519684 
	C802.165527,177.041550 800.088867,175.510391 797.703979,173.855087 
	C796.270752,173.145935 795.129700,172.583786 793.993103,172.014618 
	C793.997559,172.007584 793.977417,172.012711 793.987427,171.638916 
	C793.987488,170.518860 793.977478,169.772598 793.967407,169.026337 
z"/>
<path fill="#022B9D" opacity="1.000000" stroke="none" 
	d="
M968.521851,171.010651 
	C969.189514,172.110794 969.389587,173.207901 969.587769,174.294632 
	C966.308655,174.961304 963.245117,175.584167 959.615479,176.595734 
	C945.697021,176.997742 932.344727,177.011032 918.548340,177.013092 
	C915.731384,176.998993 913.358337,176.996140 910.548767,176.990387 
	C908.067383,176.976547 906.022583,176.965607 904.030640,176.581741 
	C904.379395,174.495499 904.675232,172.782211 904.971069,171.068909 
	C906.369202,171.046524 907.767273,171.024124 909.585144,170.996216 
	C929.354675,170.996338 948.704407,171.001984 968.521851,171.010651 
z"/>
<path fill="#0332AA" opacity="1.000000" stroke="none" 
	d="
M904.505676,171.049728 
	C904.675232,172.782211 904.379395,174.495499 903.564514,176.592041 
	C894.367310,176.999786 885.689148,177.024277 876.630493,177.035034 
	C875.206055,177.347183 873.841553,177.360565 873.169983,178.049423 
	C868.702271,182.632401 864.382812,187.359848 859.766174,192.031158 
	C859.517212,192.021988 859.019348,192.020676 859.013123,191.655411 
	C858.777588,190.303452 858.548218,189.316772 858.220764,187.907867 
	C855.825928,189.847733 853.896851,191.410324 851.967834,192.972931 
	C855.134277,189.003937 858.208130,184.955536 861.512268,181.104691 
	C862.851013,179.544373 864.691650,178.414673 866.959961,176.993973 
	C868.074890,176.263504 868.533813,175.628098 868.992676,174.992676 
	C869.256836,174.501801 869.520996,174.010941 870.247620,173.256363 
	C871.475098,172.332809 872.240112,171.672958 873.005127,171.013092 
	C873.749939,171.001190 874.494812,170.989273 876.043091,170.985291 
	C878.571655,170.979706 880.296753,170.966156 882.021790,170.952621 
	C889.361267,170.978607 896.700806,171.004578 904.505676,171.049728 
z"/>
<path fill="#012E8B" opacity="1.000000" stroke="none" 
	d="
M881.933777,170.595093 
	C880.296753,170.966156 878.571655,170.979706 876.415527,170.979370 
	C877.563293,167.703888 879.659912,168.611450 881.933777,170.595093 
z"/>
<path fill="#033ABC" opacity="1.000000" stroke="none" 
	d="
M793.572998,169.023346 
	C793.977478,169.772598 793.987488,170.518860 793.587280,171.641022 
	C792.105896,172.007278 791.034607,171.997604 789.963379,171.987930 
	C789.973572,171.240143 789.983704,170.492340 790.004028,169.370544 
	C791.068970,169.004486 792.123779,169.012421 793.572998,169.023346 
z"/>
<path fill="#022B9D" opacity="1.000000" stroke="none" 
	d="
M872.670898,171.005646 
	C872.240112,171.672958 871.475098,172.332809 870.359375,173.000458 
	C870.266724,172.510696 870.524719,172.013123 870.891357,171.256561 
	C871.445557,170.997757 871.891113,170.997971 872.670898,171.005646 
z"/>
<path fill="#012D8D" opacity="1.000000" stroke="none" 
	d="
M891.339355,139.970123 
	C891.772095,139.318176 892.556458,138.672821 893.696899,138.037689 
	C893.265686,138.690826 892.478333,139.333771 891.339355,139.970123 
z"/>
<path fill="#EFF6F8" opacity="1.000000" stroke="none" 
	d="
M676.007751,446.158447 
	C675.243286,446.190613 674.478760,446.222778 672.926636,446.357666 
	C665.635681,446.994568 659.839722,445.598816 655.462219,439.989502 
	C652.834778,434.983154 654.369385,429.875702 654.027588,424.615021 
	C654.597656,421.775116 655.058472,419.321625 655.387329,416.850555 
	C656.889099,405.564056 658.353882,394.272644 659.924072,382.604980 
	C660.232605,380.808258 660.448975,379.389526 660.682495,377.591919 
	C657.445129,376.890167 654.190552,376.567322 650.871521,375.823578 
	C650.973328,374.003510 651.139709,372.604309 651.243408,371.187714 
	C651.180725,371.170319 651.211792,371.296600 651.537537,371.198761 
	C653.718140,369.492981 655.572937,367.885040 657.346802,366.316040 
	C657.265869,366.354980 657.406738,366.466492 657.613037,366.448151 
	C657.819336,366.429810 658.044861,366.082397 658.298706,366.077332 
	C658.728821,365.802917 658.905029,365.533600 659.404175,365.166168 
	C660.319519,364.411713 660.911865,363.755402 661.823608,363.062134 
	C664.499390,360.753021 666.855835,358.480865 669.505066,356.109558 
	C670.299194,355.376801 670.800415,354.743225 671.510498,354.109253 
	C672.016846,353.985992 672.155334,353.763916 672.384827,353.371033 
	C672.799500,353.003632 672.964172,352.707855 673.475891,352.252808 
	C675.816528,351.228394 677.810181,350.363281 680.590210,349.156921 
	C680.092224,354.111603 679.747620,357.540344 679.195068,361.371735 
	C678.506653,368.600800 678.929077,369.072021 685.565063,368.972443 
	C686.693298,368.955505 687.819885,368.828369 689.408447,368.837708 
	C693.862488,368.923279 697.855286,368.923279 703.142517,368.923279 
	C701.440613,372.302094 700.271240,374.623779 698.681030,376.830750 
	C696.834961,376.791626 695.409729,376.867157 693.528381,376.832306 
	C688.923279,376.680176 684.765503,376.790466 680.628113,376.549438 
	C677.212646,376.350464 675.539490,377.426392 676.057373,381.402710 
	C673.859375,395.064575 671.461243,408.323120 669.981384,421.683411 
	C668.906555,431.387115 674.709045,435.759460 684.310242,433.290619 
	C687.440857,432.485596 690.419678,431.090485 694.971130,429.410370 
	C694.030334,432.307831 693.962097,434.594452 692.784119,435.741608 
	C689.724182,438.721497 686.176453,441.200500 682.867859,443.897156 
	C682.912415,443.914917 682.892578,443.821014 682.567322,443.812073 
	C681.744812,444.162018 681.247559,444.520905 680.433350,444.856628 
	C679.415466,445.264099 678.714478,445.694702 677.677124,446.044617 
	C676.896423,446.028748 676.452087,446.093597 676.007751,446.158447 
z"/>
<path fill="#0540BE" opacity="1.000000" stroke="none" 
	d="
M707.990173,446.036011 
	C707.997437,446.777588 708.004700,447.519165 707.678101,448.630005 
	C706.894592,449.006592 706.444946,449.013947 705.995300,449.021301 
	C705.552795,449.016846 705.110352,449.012421 704.335999,449.011719 
	C696.089661,449.008759 688.174377,449.055176 680.261597,448.935852 
	C679.123047,448.918732 677.996460,448.103485 677.196472,447.341003 
	C677.528564,447.024353 677.968262,447.015564 678.325867,447.005798 
	C680.111511,446.333740 681.539490,445.671448 682.965698,444.711060 
	C682.940186,444.215668 682.916382,444.018341 682.892578,443.821014 
	C682.892578,443.821014 682.912415,443.914917 683.295898,443.937317 
	C690.957031,442.451172 698.261536,440.336426 705.577209,444.890625 
	C706.586487,445.255981 707.288330,445.645996 707.990173,446.036011 
z"/>
<path fill="#0348CC" opacity="1.000000" stroke="none" 
	d="
M644.457642,448.921143 
	C643.974731,447.163635 643.958313,445.436035 644.375610,443.345306 
	C645.860901,442.949158 646.912598,442.916138 648.377319,442.911835 
	C653.026428,443.541687 657.743591,443.326782 659.008972,448.965881 
	C654.314026,448.960968 649.619141,448.956024 644.457642,448.921143 
z"/>
<path fill="#1546AD" opacity="1.000000" stroke="none" 
	d="
M659.390686,448.975677 
	C657.743591,443.326782 653.026428,443.541687 648.454590,442.589539 
	C649.910034,440.504700 651.723816,439.230804 653.733948,441.963287 
	C654.308350,441.424408 654.882751,440.885498 655.457153,440.346619 
	C659.839722,445.598816 665.635681,446.994568 672.590088,446.544006 
	C669.737427,446.977722 666.433533,447.327789 663.241821,447.665955 
	C663.276550,447.715240 663.597107,448.170074 664.171753,448.985443 
	C662.540894,448.985443 661.156616,448.985443 659.390686,448.975677 
z"/>
<path fill="#1546AD" opacity="1.000000" stroke="none" 
	d="
M677.968262,447.015564 
	C677.968262,447.015564 677.528564,447.024353 677.309814,447.032471 
	C676.775085,446.951355 676.499512,446.792206 676.135986,446.360779 
	C676.452087,446.093597 676.896423,446.028748 677.667725,446.267273 
	C677.994690,446.570618 677.968262,447.015564 677.968262,447.015564 
z"/>
<path fill="#EDF4F7" opacity="1.000000" stroke="none" 
	d="
M708.398865,445.960022 
	C707.288330,445.645996 706.586487,445.255981 705.465454,444.553284 
	C697.225708,434.907440 697.034546,432.527466 703.626526,424.106415 
	C703.821594,424.069733 703.983398,423.706573 704.308105,423.640625 
	C706.417053,422.684998 708.201233,421.795319 710.304321,421.004517 
	C711.519958,422.465668 712.416687,423.828033 713.302490,425.541412 
	C713.732849,426.957123 714.174133,428.021790 714.637390,429.456482 
	C716.240540,432.113678 717.445190,434.837067 719.482422,436.596252 
	C723.585449,440.139374 728.431702,439.629425 733.064758,437.534790 
	C736.189758,436.122009 738.792969,434.095062 738.038696,429.606018 
	C736.531128,426.659637 734.987061,424.097839 733.415222,421.556366 
	C733.387451,421.576721 733.344543,421.630646 733.170959,421.346008 
	C731.460510,420.212860 729.923584,419.364410 728.292358,418.442627 
	C728.197937,418.369263 727.836304,418.414429 727.168457,418.439484 
	C726.483826,418.909760 726.466980,419.354980 726.926758,419.676270 
	C726.905640,418.562134 726.407898,417.571869 725.801514,416.278625 
	C724.173645,414.998260 722.655518,414.019043 721.134888,413.043671 
	C712.381958,407.429443 705.726807,400.593506 707.367798,388.844360 
	C707.633545,388.068695 707.685730,387.496735 707.916992,386.616272 
	C711.148987,381.607391 714.201843,376.907104 717.201111,372.191162 
	C717.147522,372.175507 717.190735,372.278442 717.552002,372.280945 
	C720.361267,370.858673 722.809326,369.433868 725.234131,367.998352 
	C725.210876,367.987610 725.241760,367.982971 725.669983,367.955261 
	C733.605103,365.392578 741.130859,365.749298 748.805176,368.057495 
	C750.406128,369.224792 751.869385,370.114960 753.447144,371.335999 
	C757.406555,376.264069 756.937195,381.230591 754.226501,385.971558 
	C751.643799,390.488525 747.811707,393.469604 742.077393,392.861237 
	C742.029602,392.876312 742.126831,392.900696 742.100220,392.467468 
	C741.601868,388.427277 741.412476,384.753998 740.584961,381.230560 
	C739.605469,377.060028 737.023682,373.985657 732.431885,373.703705 
	C728.037415,373.433838 724.214783,374.707672 722.060181,379.291809 
	C721.639465,379.931824 721.442261,380.493073 721.061768,381.402496 
	C718.848328,386.126038 720.221436,390.061798 723.476196,392.846497 
	C727.283691,396.104065 731.764221,398.575012 736.110474,401.636841 
	C737.158264,402.331421 738.048401,402.776398 739.057129,403.475891 
	C739.676880,403.955658 740.177917,404.180878 740.774048,404.344910 
	C740.869080,404.283691 740.651001,404.343475 740.768433,404.622070 
	C741.579346,405.275452 742.272827,405.650238 743.119263,406.322876 
	C745.472229,408.807281 747.672363,410.993835 749.884155,413.133667 
	C749.895874,413.086914 749.804382,413.117065 749.847290,413.456055 
	C750.708862,415.631714 751.527527,417.468384 752.295654,419.675323 
	C752.470215,421.499817 752.695190,422.954041 752.651123,424.700684 
	C751.309326,432.761353 746.035034,437.666290 740.560791,442.478027 
	C740.605469,442.551514 740.643127,442.383728 740.293213,442.419556 
	C739.432312,442.921509 738.921265,443.387634 738.295166,443.810974 
	C738.179993,443.768158 738.023682,443.957733 737.730103,443.959930 
	C737.193420,444.248230 736.950317,444.534332 736.686035,444.819275 
	C736.664856,444.818115 736.637512,444.784851 736.637512,444.784851 
	C729.106140,447.603668 721.359741,448.304779 713.438843,447.049652 
	C711.872803,446.801453 710.350220,446.279633 708.398865,445.960022 
z"/>
<path fill="#0332AA" opacity="1.000000" stroke="none" 
	d="
M738.023682,443.957733 
	C738.023682,443.957733 738.179993,443.768158 738.692871,443.815125 
	C739.684814,443.369293 740.163940,442.876495 740.643127,442.383728 
	C740.643127,442.383728 740.605469,442.551514 740.947388,442.661316 
	C741.848755,442.837891 742.408264,442.904602 743.425354,442.981140 
	C746.933777,442.977814 749.984497,442.964661 753.412720,442.969360 
	C756.192993,442.199799 758.610840,446.966309 761.462097,442.976532 
	C765.271118,442.996887 768.623047,443.008331 772.145874,443.325226 
	C773.931885,444.375000 775.546814,445.119293 777.123596,445.907654 
	C777.085388,445.951721 777.100403,445.836029 777.059448,446.226501 
	C776.433655,449.893066 774.494141,449.054260 772.308594,448.388611 
	C771.357422,448.098877 770.115967,448.762146 769.006348,448.992615 
	C766.600830,448.662048 764.195312,448.331482 761.378540,448.007202 
	C752.016907,448.426910 743.066467,448.840302 734.115967,449.253693 
	C733.765564,448.744049 733.415161,448.234406 733.064697,447.724762 
	C734.295898,446.973785 735.527100,446.222778 736.697876,445.128296 
	C736.637512,444.784851 736.664856,444.818115 736.962891,444.776733 
	C737.515198,444.476135 737.769470,444.216919 738.023682,443.957733 
z"/>
<path fill="#02389F" opacity="1.000000" stroke="none" 
	d="
M706.041260,449.373169 
	C706.444946,449.013947 706.894592,449.006592 707.681885,449.000824 
	C708.862122,449.296783 709.704773,449.591156 710.547363,449.885529 
	C710.136719,450.495728 709.465149,451.677917 709.355774,451.628021 
	C708.214417,451.107269 707.163330,450.388733 706.041260,449.373169 
z"/>
<path fill="#086CD7" opacity="1.000000" stroke="none" 
	d="
M723.985107,462.611755 
	C722.206238,462.935028 720.494263,462.912292 718.454468,462.918152 
	C716.715759,459.997986 714.487427,456.747620 718.704590,454.525543 
	C720.088257,453.796478 722.999207,454.142426 724.145569,455.177521 
	C726.326538,457.146851 725.845642,459.908081 723.985107,462.611755 
z"/>
<path fill="#F0F5F8" opacity="1.000000" stroke="none" 
	d="
M797.872986,445.546112 
	C792.185120,445.717346 786.497253,445.888580 780.075928,445.910431 
	C778.595093,445.786041 777.847717,445.811035 777.100403,445.836029 
	C777.100403,445.836029 777.085388,445.951721 777.037109,445.577179 
	C771.443726,440.832886 769.328857,435.008392 769.978333,428.138397 
	C770.677246,420.745544 771.549377,413.369049 772.529175,405.597412 
	C772.777771,403.791260 772.843140,402.373047 772.998779,400.570862 
	C773.825378,396.388031 774.506592,392.577545 775.309753,388.792908 
	C776.857239,381.501068 776.884766,381.509766 770.226929,378.500366 
	C768.746704,377.831299 767.278076,377.136597 765.350220,376.243347 
	C766.135376,373.857758 766.875916,371.607819 767.858215,369.401672 
	C768.437744,369.411102 768.734009,369.281799 769.442261,369.147156 
	C774.163086,368.901550 778.427673,368.356262 782.698914,368.298401 
	C786.044800,368.253082 789.398926,368.811371 792.742432,369.509521 
	C792.113281,374.926483 791.491150,379.935394 790.613892,385.254578 
	C788.977417,394.878296 787.492981,404.178192 786.264343,413.511749 
	C785.758301,417.355652 785.802063,421.271912 785.628906,425.536560 
	C786.472412,430.974823 789.396851,434.662567 794.476257,434.335205 
	C799.766235,433.994232 805.123047,432.469391 810.113464,430.574341 
	C812.886292,429.521423 815.083862,426.714264 814.310181,422.772217 
	C814.451965,422.574249 814.304504,422.110291 814.502686,421.780090 
	C815.430176,415.980560 816.159546,410.511200 817.039062,404.660645 
	C818.214355,397.801086 819.174438,391.311432 820.295105,384.849640 
	C820.775696,382.078247 819.949707,380.720917 816.791443,380.659851 
	C813.565491,379.590973 810.583618,378.800385 807.654968,378.023926 
	C808.134705,375.249084 808.498962,373.142426 809.108765,370.757385 
	C810.798706,370.147644 812.243164,369.816284 814.092896,369.571960 
	C820.420715,369.082062 826.346802,368.539124 832.264221,367.914215 
	C835.298462,367.593750 837.196960,368.370453 836.403137,372.204559 
	C835.741638,376.660645 835.356140,380.815826 834.711304,385.276306 
	C833.698242,391.743774 832.944641,397.905945 832.042480,404.440369 
	C831.040588,410.065338 830.142334,415.311249 829.342651,420.572113 
	C826.938538,436.387299 828.620178,434.362488 839.753235,438.087433 
	C839.412720,440.287384 839.062866,442.547668 838.713013,444.807922 
	C838.713013,444.807922 838.831421,444.837830 838.425537,444.889709 
	C831.014526,446.637726 824.010559,447.577393 816.989746,444.677673 
	C816.862244,444.097229 816.627380,443.930420 816.120422,443.588318 
	C814.995117,440.078339 814.012085,436.926514 813.039246,433.807556 
	C809.541199,436.776276 805.959473,439.815948 802.286987,442.808044 
	C802.196167,442.760529 802.043396,442.897186 801.735168,442.898010 
	C801.194641,443.221344 800.962280,443.543854 800.812866,443.879883 
	C800.895813,443.893402 800.883301,443.725800 800.580811,443.786255 
	C800.087219,444.199615 799.896118,444.552490 799.422607,444.818695 
	C798.717773,445.003357 798.295349,445.274750 797.872986,445.546112 
z"/>
<path fill="#0C237E" opacity="1.000000" stroke="none" 
	d="
M839.084961,444.816559 
	C839.062866,442.547668 839.412720,440.287384 839.753235,438.087433 
	C828.620178,434.362488 826.938538,436.387299 829.342651,420.572113 
	C830.142334,415.311249 831.040588,410.065338 832.285156,404.743774 
	C832.451416,409.780853 832.226196,414.886749 831.850708,420.337158 
	C831.435608,422.393646 831.170898,424.105652 831.033081,424.996796 
	C837.919006,424.996796 844.432373,424.888794 850.939392,425.044922 
	C853.945557,425.117004 855.720337,424.166077 855.962891,420.661102 
	C856.584473,419.198853 857.195068,418.102081 857.844971,417.421082 
	C857.206177,422.436646 856.460632,427.027740 855.863831,431.638092 
	C854.588379,441.491669 855.540222,442.831055 865.356506,445.226532 
	C866.806213,445.580292 868.299500,445.763885 869.778564,445.987335 
	C879.904053,447.517181 889.578979,445.673370 899.015076,442.060486 
	C899.015076,442.060486 899.041870,442.501892 899.055054,442.722107 
	C898.890564,443.659760 898.664062,444.369324 898.546204,445.096497 
	C898.354126,446.281433 898.232971,447.477905 898.081909,448.669525 
	C897.104919,448.197723 896.158813,447.638306 895.142029,447.278717 
	C894.124939,446.919006 893.018982,446.486664 891.983582,446.556335 
	C885.957581,446.961792 879.940918,447.505768 873.452026,448.007568 
	C868.613831,447.288696 864.106812,447.017670 859.907715,445.736877 
	C853.429993,443.761139 852.475952,441.853882 853.033630,435.122833 
	C853.127136,433.994446 853.377930,432.879059 853.579285,431.610962 
	C846.664001,431.610962 839.955627,431.610962 832.311340,431.610962 
	C835.085693,436.048889 842.337646,434.681152 841.585938,440.801422 
	C841.413086,442.208618 840.194336,443.487366 839.084961,444.816559 
z"/>
<path fill="#01319F" opacity="1.000000" stroke="none" 
	d="
M816.262634,443.946472 
	C816.627380,443.930420 816.862244,444.097229 816.983887,445.026794 
	C816.998657,446.071930 816.996887,446.537201 816.995117,447.002441 
	C815.720093,447.669037 814.464844,448.862396 813.166626,448.911072 
	C807.513062,449.123138 801.847046,449.002197 795.727234,449.002197 
	C796.565674,447.841797 797.158264,447.021606 797.811951,445.873779 
	C798.295349,445.274750 798.717773,445.003357 799.700928,444.716980 
	C800.468933,444.376587 800.676147,444.051178 800.883301,443.725800 
	C800.883301,443.725800 800.895813,443.893402 801.092834,443.812683 
	C801.541016,443.453705 801.792236,443.175446 802.043396,442.897186 
	C802.043396,442.897186 802.196167,442.760529 802.750366,442.843079 
	C804.529968,442.943176 805.755310,442.960693 807.301025,442.940613 
	C808.084595,442.270874 808.547852,441.638702 809.183472,440.769531 
	C809.902771,440.356873 810.449707,440.181213 811.240967,440.009827 
	C811.485291,440.014069 811.973450,440.034882 812.085083,440.288635 
	C812.463928,441.026520 812.731140,441.510590 813.019226,442.292358 
	C813.357056,443.062164 813.674072,443.534302 814.369507,444.001770 
	C815.252808,443.980225 815.757751,443.963348 816.262634,443.946472 
z"/>
<path fill="#083797" opacity="1.000000" stroke="none" 
	d="
M777.059448,446.226501 
	C777.847717,445.811035 778.595093,445.786041 779.671997,445.940826 
	C779.927124,448.226990 779.852661,450.333405 779.778259,452.439789 
	C777.620178,452.177216 775.435852,452.042053 773.314087,451.603485 
	C772.071045,451.346497 770.927917,450.606110 769.373535,450.043884 
	C769.005493,449.835938 769.003601,449.667297 769.004028,449.245636 
	C770.115967,448.762146 771.357422,448.098877 772.308594,448.388611 
	C774.494141,449.054260 776.433655,449.893066 777.059448,446.226501 
z"/>
<path fill="#0C237E" opacity="1.000000" stroke="none" 
	d="
M817.418457,447.011108 
	C816.996887,446.537201 816.998657,446.071930 817.006348,445.257538 
	C824.010559,447.577393 831.014526,446.637726 838.409424,444.830841 
	C837.423828,445.805908 836.059143,447.801727 834.671387,447.817932 
	C829.065369,447.883545 823.452271,447.339020 817.418457,447.011108 
z"/>
<path fill="#0433B7" opacity="1.000000" stroke="none" 
	d="
M919.136230,465.143250 
	C919.441345,464.606476 919.916443,464.306763 920.682129,463.979004 
	C920.417236,464.427399 919.861633,464.903870 919.136230,465.143250 
z"/>
<path fill="#0C8FEC" opacity="1.000000" stroke="none" 
	d="
M501.777222,519.129272 
	C507.389587,521.748535 508.620911,524.911682 505.032135,527.172241 
	C503.246246,528.297180 500.279358,528.688354 498.341003,528.006409 
	C497.158112,527.590271 495.964355,524.144470 496.504364,522.710510 
	C497.102234,521.122742 499.671875,520.277466 501.777222,519.129272 
z"/>
<path fill="#0139A3" opacity="1.000000" stroke="none" 
	d="
M572.143677,503.690735 
	C568.691589,498.004486 568.404175,498.035156 563.248291,501.863159 
	C562.293518,502.572052 559.762695,502.478485 558.946533,501.708618 
	C557.995056,500.811096 557.367310,498.358276 557.935364,497.292938 
	C559.532410,494.298157 559.367737,492.618561 555.388428,491.676788 
	C554.999512,490.886902 554.997314,490.439301 554.995117,489.991638 
	C556.906372,487.856934 558.817627,485.722229 561.194153,483.248291 
	C562.106995,482.286285 562.554565,481.663483 563.002136,481.040649 
	C563.434570,481.021210 563.867004,481.001801 564.971130,480.952148 
	C566.421753,479.932831 567.200684,478.943756 567.979614,477.954651 
	C569.075134,476.653595 570.170593,475.352509 571.995483,474.020935 
	C576.038818,473.428802 583.129944,465.862152 583.982544,461.977936 
	C583.982544,461.977936 584.000000,462.000000 584.346436,461.960571 
	C587.960327,460.666382 591.020386,459.223816 590.979858,454.974976 
	C590.979858,454.974976 591.000000,455.000000 591.371704,455.004150 
	C593.894348,455.565369 596.045288,456.122467 598.049011,456.641449 
	C598.385132,453.911743 598.689697,451.437683 599.451111,448.965942 
	C604.945984,448.661896 609.984070,448.355530 615.172363,448.261169 
	C615.879700,448.314056 616.436890,448.154968 617.328613,447.839203 
	C621.112793,446.421478 624.562439,445.160370 628.113525,444.170227 
	C628.484253,444.952332 628.753540,445.463531 629.022888,445.974731 
	C626.938232,448.226227 624.897644,450.520447 622.761597,452.722046 
	C612.647278,463.146912 602.576355,473.615417 592.350708,483.930206 
	C585.796387,490.541565 578.999939,496.912903 572.143677,503.690735 
z"/>
<path fill="#022590" opacity="1.000000" stroke="none" 
	d="
M655.462219,439.989502 
	C654.882751,440.885498 654.308350,441.424408 653.733948,441.963287 
	C651.723816,439.230804 649.910034,440.504700 648.041565,442.560791 
	C646.912598,442.916138 645.860901,442.949158 644.415649,443.004822 
	C642.627747,442.996429 641.233459,442.965424 639.424194,442.540497 
	C640.226196,440.425262 641.338501,438.616577 642.684875,437.003174 
	C644.678894,434.613617 648.718628,433.384888 647.056458,428.677429 
	C648.009949,426.134125 652.808167,429.687988 652.347778,425.008636 
	C653.052307,424.998383 653.454895,424.995850 653.857544,424.993317 
	C654.369385,429.875702 652.834778,434.983154 655.462219,439.989502 
z"/>
<path fill="#01389F" opacity="1.000000" stroke="none" 
	d="
M647.000000,429.003662 
	C648.718628,433.384888 644.678894,434.613617 642.684875,437.003174 
	C641.338501,438.616577 640.226196,440.425262 639.007202,442.515503 
	C638.548767,442.916565 638.092346,442.948730 637.099243,442.715881 
	C635.336914,441.972961 634.111389,441.494995 632.952759,440.661835 
	C633.999329,436.896667 634.979004,433.486694 636.184753,429.793213 
	C639.940552,429.341003 643.470276,429.172333 647.000000,429.003662 
z"/>
<path fill="#1546AD" opacity="1.000000" stroke="none" 
	d="
M632.885864,441.017029 
	C634.111389,441.494995 635.336914,441.972961 636.755981,442.716125 
	C634.521667,443.869049 632.093872,444.756805 629.344482,445.809631 
	C628.753540,445.463531 628.484253,444.952332 628.270752,443.888519 
	C629.846252,442.562958 631.366089,441.789978 632.885864,441.017029 
z"/>
<path fill="#F0F5F8" opacity="1.000000" stroke="none" 
	d="
M899.200073,441.769989 
	C889.578979,445.673370 879.904053,447.517181 869.778564,445.987335 
	C868.299500,445.763885 866.806213,445.580292 865.356506,445.226532 
	C855.540222,442.831055 854.588379,441.491669 855.863831,431.638092 
	C856.460632,427.027740 857.206177,422.436646 858.036255,417.125000 
	C858.151428,415.952759 858.114563,415.492340 858.270264,414.695892 
	C858.962219,409.902832 859.461731,405.445831 860.246338,400.706909 
	C861.971680,389.948669 863.412048,379.472321 864.933533,368.583435 
	C865.017639,366.766998 865.020569,365.363190 865.383484,363.784973 
	C866.447510,359.594849 867.329651,355.597290 867.743225,351.551788 
	C867.862305,350.387238 866.630737,349.084625 865.796753,347.575195 
	C862.468750,346.147675 859.362366,344.990234 856.202759,343.457397 
	C855.918945,336.738373 860.598206,336.554291 865.450684,336.116821 
	C868.371582,336.093262 870.848328,336.034821 873.316101,335.853180 
	C883.684326,335.090027 883.683350,335.076508 883.255981,345.255066 
	C883.029846,346.702484 882.993652,347.856354 882.724609,349.311462 
	C880.809265,359.402191 879.126648,369.191711 877.213684,380.321747 
	C884.997437,372.380157 893.247925,367.744263 903.012329,366.051208 
	C903.449951,366.134521 903.887634,366.217834 904.988892,366.368896 
	C915.484436,366.381500 920.962891,372.526306 925.329956,380.253784 
	C925.609985,388.195160 925.890015,396.136536 926.285645,404.532318 
	C923.583862,421.732086 914.624695,433.930542 899.200073,441.769989 
M905.739014,424.742706 
	C910.830750,414.103577 912.457764,402.897766 910.612610,391.309204 
	C908.851135,380.246094 901.732117,375.596069 890.917786,378.541870 
	C886.923401,379.629913 883.144714,381.753876 879.525818,383.851013 
	C878.166870,384.638550 876.998657,386.584290 876.685242,388.186249 
	C875.861755,392.396088 875.555847,396.707184 874.594604,401.648773 
	C873.165283,410.645538 871.638672,419.629120 870.415527,428.653870 
	C870.240356,429.946716 871.148315,431.910522 872.202515,432.764801 
	C882.865967,441.406494 899.180176,437.526031 905.739014,424.742706 
z"/>
<path fill="#012072" opacity="1.000000" stroke="none" 
	d="
M1010.533569,75.002975 
	C1009.452393,78.519600 1005.791870,81.530914 1008.504456,86.115982 
	C1008.657776,86.375122 1006.739380,88.509277 1005.544312,88.808655 
	C999.025879,90.441536 992.483276,92.133980 985.845581,93.079170 
	C981.523010,93.694695 977.024353,93.036118 972.604248,93.013031 
	C965.681885,92.976883 958.759216,92.995476 951.394226,92.979477 
	C958.176208,83.882935 964.224731,73.576149 978.664856,74.823898 
	C989.058777,75.722015 999.593018,74.995613 1010.533569,75.002975 
z"/>
<path fill="#01399A" opacity="1.000000" stroke="none" 
	d="
M350.061951,81.087776 
	C349.607239,81.069733 349.152527,81.051697 348.356445,81.032364 
	C349.409302,80.024216 351.056885,77.944115 352.152374,78.202721 
	C356.156036,79.147812 359.975616,80.872475 363.927368,82.669312 
	C361.262360,82.695198 358.534515,82.355247 355.128906,81.758118 
	C352.988068,81.363213 351.525024,81.225494 350.061951,81.087776 
z"/>
<path fill="#01389F" opacity="1.000000" stroke="none" 
	d="
M471.514099,166.054932 
	C471.450348,172.693619 465.837250,177.976349 459.237457,177.989410 
	C447.450165,178.012726 435.662781,177.992950 423.450562,177.991058 
	C422.699921,177.896698 422.432861,177.715805 422.075745,176.886932 
	C419.928497,174.573059 417.929993,172.820526 415.931458,171.067993 
	C415.931458,171.067993 415.998779,171.000610 415.945862,170.705261 
	C415.251984,169.947891 414.611084,169.485855 413.970184,169.023819 
	C413.970184,169.023819 414.000000,169.000000 413.960602,168.668427 
	C412.915436,167.575867 411.909637,166.814880 410.903870,166.053894 
	C409.350800,164.595123 407.797729,163.136383 406.079407,161.016296 
	C404.288910,159.232803 402.663666,158.110641 401.038422,156.988495 
	C399.792572,155.837112 398.546722,154.685730 397.154541,152.898087 
	C396.671997,151.175354 396.335754,150.088898 395.999481,149.002441 
	C401.938660,148.670349 407.889587,148.464859 413.808594,147.915359 
	C415.251648,147.781387 416.589813,146.517975 418.013794,145.353943 
	C419.559021,146.456253 421.066650,147.978485 422.784546,150.140671 
	C423.682037,151.503113 424.369202,152.225586 425.056366,152.948059 
	C425.056366,152.948074 424.999451,152.998779 425.043427,153.321442 
	C427.739197,156.083725 430.390961,158.523315 433.042755,160.962906 
	C433.042755,160.962906 433.000000,161.000000 433.039795,161.327682 
	C434.407196,162.758224 435.734802,163.861099 437.062408,164.963959 
	C437.062408,164.963959 437.527344,164.990204 437.809387,165.331390 
	C439.059387,165.786621 440.027405,165.900665 440.995392,166.014709 
	C443.364410,166.016525 445.733459,166.018341 448.639618,166.407074 
	C451.965454,168.394302 454.705750,169.491852 457.008270,166.040329 
	C461.689087,166.036987 466.369934,166.033646 471.514099,166.054932 
z"/>
<path fill="#0243AA" opacity="1.000000" stroke="none" 
	d="
M432.999298,160.622406 
	C430.390961,158.523315 427.739197,156.083725 425.070251,153.293884 
	C427.687317,155.389725 430.321594,157.835815 432.999298,160.622406 
z"/>
<path fill="#0243AA" opacity="1.000000" stroke="none" 
	d="
M456.563568,166.014633 
	C454.705750,169.491852 451.965454,168.394302 449.083008,166.424133 
	C451.365784,166.032486 453.742310,166.010712 456.563568,166.014633 
z"/>
<path fill="#0243AA" opacity="1.000000" stroke="none" 
	d="
M437.009949,164.626831 
	C435.734802,163.861099 434.407196,162.758224 433.058411,161.306381 
	C434.343994,162.068176 435.650726,163.178940 437.009949,164.626831 
z"/>
<path fill="#0243AA" opacity="1.000000" stroke="none" 
	d="
M440.788574,165.753021 
	C440.027405,165.900665 439.059387,165.786621 438.040894,165.355774 
	C438.854187,165.189789 439.717957,165.340561 440.788574,165.753021 
z"/>
<path fill="#0243AA" opacity="1.000000" stroke="none" 
	d="
M425.011810,152.635498 
	C424.369202,152.225586 423.682037,151.503113 422.977356,150.435791 
	C423.628998,150.834930 424.298126,151.578934 425.011810,152.635498 
z"/>
<path fill="#086BCC" opacity="1.000000" stroke="none" 
	d="
M408.943298,134.714630 
	C408.341492,134.579407 407.701782,134.116455 406.999084,133.340378 
	C407.592499,133.480469 408.248932,133.933685 408.943298,134.714630 
z"/>
<path fill="#086BCC" opacity="1.000000" stroke="none" 
	d="
M403.000000,128.692078 
	C402.352905,128.580246 401.707855,128.131180 401.002380,127.370377 
	C400.941956,127.058624 400.986572,127.014038 401.335571,127.001480 
	C402.120575,126.990227 402.556641,126.991516 402.992676,126.992805 
	C402.992676,126.992805 402.999329,126.999229 403.003296,127.003174 
	C403.005554,127.456367 403.003815,127.905617 403.000000,128.692078 
z"/>
<path fill="#159CE3" opacity="1.000000" stroke="none" 
	d="
M349.999756,81.456543 
	C351.525024,81.225494 352.988068,81.363213 354.704437,81.768456 
	C355.423340,85.087143 354.087616,86.672302 351.122406,85.797150 
	C350.399689,85.583832 350.315369,83.207253 349.999756,81.456543 
z"/>
<path fill="#013899" opacity="1.000000" stroke="none" 
	d="
M344.727448,87.714340 
	C342.515503,88.169060 340.023773,88.357849 337.262299,88.263695 
	C339.477600,87.803299 341.962616,87.625862 344.727448,87.714340 
z"/>
<path fill="#086CD7" opacity="1.000000" stroke="none" 
	d="
M402.954102,126.700027 
	C402.556641,126.991516 402.120575,126.990227 401.358185,126.979500 
	C401.343567,126.466850 401.655273,125.963623 401.967010,125.460396 
	C402.283173,125.776009 402.599335,126.091629 402.954102,126.700027 
z"/>
<path fill="#0442AB" opacity="1.000000" stroke="none" 
	d="
M230.031387,153.124329 
	C231.713013,151.875076 233.427200,150.332932 235.025864,150.444611 
	C236.068283,150.517410 237.300598,152.899643 237.703491,154.441772 
	C237.849426,155.000488 235.937897,156.646225 234.778824,156.882751 
	C232.315186,157.385468 230.043304,156.854782 230.031387,153.124329 
z"/>
<path fill="#023DA5" opacity="1.000000" stroke="none" 
	d="
M393.068115,157.290955 
	C396.260406,159.766586 399.468597,162.592896 402.793030,165.729706 
	C399.634216,163.240692 396.359131,160.441162 393.068115,157.290955 
z"/>
<path fill="#023DA5" opacity="1.000000" stroke="none" 
	d="
M389.076843,153.279587 
	C390.365906,154.043930 391.667114,155.159470 393.013947,156.611389 
	C391.736023,155.842102 390.412476,154.736450 389.076843,153.279587 
z"/>
<path fill="#012986" opacity="1.000000" stroke="none" 
	d="
M372.829681,140.792236 
	C372.530884,141.328110 372.059845,141.640076 371.292969,141.970215 
	C371.550568,141.515060 372.104004,141.041718 372.829681,140.792236 
z"/>
<path fill="#0540BE" opacity="1.000000" stroke="none" 
	d="
M254.996140,140.582062 
	C251.024689,143.364655 247.201538,143.899323 243.284454,140.198608 
	C242.738083,136.381210 242.830948,132.574020 246.944275,131.419144 
	C248.993195,130.843887 252.530731,131.288528 253.626541,132.672241 
	C255.032867,134.448074 254.660294,137.632690 254.996140,140.582062 
M247.504028,139.443451 
	C249.005341,138.864426 250.506638,138.285400 252.007935,137.706360 
	C251.023270,136.621063 250.038605,135.535751 249.053940,134.450455 
	C248.383621,135.924026 247.713318,137.397614 247.504028,139.443451 
z"/>
<path fill="#0243AA" opacity="1.000000" stroke="none" 
	d="
M415.978699,171.406281 
	C417.929993,172.820526 419.928497,174.573059 421.957886,176.672150 
	C420.001129,175.260651 418.013519,173.502609 415.978699,171.406281 
z"/>
<path fill="#0243AA" opacity="1.000000" stroke="none" 
	d="
M401.165833,157.294037 
	C402.663666,158.110641 404.288910,159.232803 405.934998,160.716827 
	C404.401642,159.918991 402.847412,158.759277 401.165833,157.294037 
z"/>
<path fill="#0243AA" opacity="1.000000" stroke="none" 
	d="
M395.652466,148.993668 
	C396.335754,150.088898 396.671997,151.175354 397.015930,152.606216 
	C396.019379,151.873215 395.015137,150.795822 393.964813,149.397598 
	C394.380981,149.046143 394.843201,149.015518 395.652466,148.993668 
z"/>
<path fill="#0243AA" opacity="1.000000" stroke="none" 
	d="
M410.964600,166.386078 
	C411.909637,166.814880 412.915436,167.575867 413.948547,168.683472 
	C412.992371,168.259476 412.008881,167.488876 410.964600,166.386078 
z"/>
<path fill="#0243AA" opacity="1.000000" stroke="none" 
	d="
M414.016296,169.339249 
	C414.611084,169.485855 415.251984,169.947891 415.913239,170.738693 
	C415.309845,170.596527 414.686127,170.125595 414.016296,169.339249 
z"/>
<path fill="#013899" opacity="1.000000" stroke="none" 
	d="
M337.307129,107.976044 
	C337.011322,107.566437 337.009674,107.112648 337.018982,106.318497 
	C337.220398,106.629372 337.410858,107.280617 337.307129,107.976044 
z"/>
<path fill="#011773" opacity="1.000000" stroke="none" 
	d="
M229.347412,509.117767 
	C230.086884,507.630737 231.162720,506.317657 232.622955,505.354889 
	C234.015427,506.823273 235.023514,507.941345 236.031586,509.059387 
	C236.028290,509.238647 236.024994,509.417908 236.006607,509.866669 
	C235.574173,510.149963 235.156830,510.163818 234.430023,510.225220 
	C232.641647,509.945770 231.162720,509.618774 229.347412,509.117767 
z"/>
<path fill="#012986" opacity="1.000000" stroke="none" 
	d="
M331.002441,502.993896 
	C314.500153,502.819763 297.997894,502.645630 281.240723,502.752716 
	C279.930084,503.037415 278.874359,503.040894 277.541199,502.715210 
	C278.558075,500.031616 279.852325,497.677185 281.501282,495.241760 
	C285.254639,492.442169 288.653351,489.723602 292.416809,486.999023 
	C297.784698,485.943939 300.179504,482.245697 302.125793,477.704895 
	C302.785492,477.281128 303.295654,477.092499 304.205841,476.930481 
	C307.077667,475.801331 309.811401,474.997192 311.954803,473.400574 
	C314.618835,471.416199 315.925812,472.063171 317.358856,474.663605 
	C320.232422,479.878113 319.147797,481.639954 313.153168,482.087708 
	C310.353760,482.296753 307.538452,483.237854 304.913025,484.299072 
	C304.040466,484.651794 303.737610,486.414001 303.174316,487.531799 
	C304.273865,488.020813 305.436615,488.405396 306.458038,489.023499 
	C307.970673,489.938843 310.428314,490.838409 310.641205,492.064606 
	C311.267883,495.673981 313.489624,495.516876 316.241425,496.155884 
	C321.542877,497.386963 328.106293,496.426025 331.002441,502.993896 
z"/>
<path fill="#0858C9" opacity="1.000000" stroke="none" 
	d="
M281.146606,495.322754 
	C279.852325,497.677185 278.558075,500.031616 277.145081,502.704651 
	C275.850311,503.573547 274.674286,504.123840 273.204376,504.817688 
	C270.172577,504.983398 269.231537,504.024353 271.010376,501.530426 
	C271.934021,500.235413 272.815125,498.910065 273.832581,497.270203 
	C273.949677,496.942017 273.999512,496.486938 274.344849,496.221375 
	C276.401031,494.951355 278.111816,493.946899 280.113464,493.142181 
	C280.651733,494.002167 280.899170,494.662476 281.146606,495.322754 
z"/>
<path fill="#010B64" opacity="1.000000" stroke="none" 
	d="
M236.496628,509.036316 
	C235.023514,507.941345 234.015427,506.823273 232.996826,505.347534 
	C232.986298,504.989868 232.998291,504.997467 233.464294,504.995972 
	C241.994812,504.994904 250.059402,505.017059 258.123749,504.981201 
	C260.253235,504.971741 262.218994,504.996460 262.002136,507.959778 
	C258.130493,508.307312 254.264969,508.808350 250.385651,508.962158 
	C245.917343,509.139313 241.436859,509.009674 236.496628,509.036316 
z"/>
<path fill="#011773" opacity="1.000000" stroke="none" 
	d="
M262.348145,507.931213 
	C262.218994,504.996460 260.253235,504.971741 258.123749,504.981201 
	C250.059402,505.017059 241.994812,504.994904 233.460709,504.990417 
	C234.445480,504.324280 235.889572,503.116577 237.355881,503.088959 
	C246.601639,502.914978 255.852325,503.002899 265.575500,503.006836 
	C264.931122,504.640472 263.812622,506.271545 262.348145,507.931213 
z"/>
<path fill="#01389F" opacity="1.000000" stroke="none" 
	d="
M451.459778,500.968689 
	C450.831665,497.457245 453.362946,498.011475 455.464325,498.008728 
	C467.444519,497.993011 479.424957,498.043793 491.404663,497.966064 
	C494.064819,497.948822 497.623932,498.740356 497.842377,494.507690 
	C498.103333,489.452332 495.678314,487.085083 490.811523,487.004089 
	C488.981689,486.973572 486.986298,487.440002 485.367188,486.851807 
	C483.743256,486.261841 481.434906,484.767242 481.275879,483.446411 
	C480.759583,479.158386 478.415863,476.702576 475.117004,474.358368 
	C470.897644,471.360016 470.314819,467.251129 473.461700,462.370483 
	C487.194336,461.980072 500.450592,461.972412 513.706848,461.993591 
	C515.188354,461.995972 517.302124,461.566467 518.043945,462.353699 
	C521.671997,466.203857 524.761047,463.979370 528.390381,461.994568 
	C529.861572,462.002258 530.931396,462.002777 532.193848,462.237427 
	C533.257690,461.648499 534.128845,460.825470 535.113037,459.785950 
	C535.428345,459.299927 535.689087,459.111359 536.397522,459.011658 
	C537.859070,460.199432 539.630005,461.252716 539.871094,462.583160 
	C540.558777,466.379395 542.409302,466.388794 545.155396,464.911865 
	C546.252686,464.321747 547.340332,463.713745 548.373596,463.146149 
	C552.386108,468.457458 555.748047,469.315735 562.585022,467.064392 
	C560.852600,460.464508 560.890381,458.856171 565.796082,456.020538 
	C569.544861,453.853668 574.108337,453.096100 579.307007,451.382935 
	C575.268494,457.146118 572.147461,461.600128 569.026367,466.054077 
	C567.193481,468.240265 565.360596,470.426453 562.886780,472.810577 
	C556.936279,474.270752 553.605347,477.366821 552.997559,482.986816 
	C550.536621,485.559235 548.075684,488.131653 544.941895,490.857635 
	C540.945740,491.635712 539.012695,493.479309 539.006348,497.012451 
	C537.931213,497.691101 536.856018,498.369751 534.983276,499.032654 
	C528.957642,498.678558 523.724243,498.056671 518.504395,498.153992 
	C516.651672,498.188568 514.831665,499.972931 512.996704,500.958527 
	C492.640778,500.969513 472.284882,500.980469 451.459778,500.968689 
M546.986450,481.089203 
	C547.353210,477.915009 547.719971,474.740814 548.086731,471.566620 
	C547.539612,471.449738 546.992493,471.332886 546.445374,471.216034 
	C545.765686,472.248138 544.444458,473.337158 544.529968,474.301331 
	C544.761047,476.904327 543.505066,480.124878 546.986450,481.089203 
z"/>
<path fill="#013AA5" opacity="1.000000" stroke="none" 
	d="
M569.367004,466.001617 
	C572.147461,461.600128 575.268494,457.146118 579.307007,451.382935 
	C574.108337,453.096100 569.544861,453.853668 565.796082,456.020538 
	C560.890381,458.856171 560.852600,460.464508 562.585022,467.064392 
	C555.748047,469.315735 552.386108,468.457458 548.373596,463.146149 
	C547.340332,463.713745 546.252686,464.321747 545.155396,464.911865 
	C542.409302,466.388794 540.558777,466.379395 539.871094,462.583160 
	C539.630005,461.252716 537.859070,460.199432 536.504639,458.752502 
	C536.479858,457.988251 536.737122,457.491089 536.997192,456.996948 
	C537.000000,457.000000 536.994080,456.992767 537.338867,456.959412 
	C539.795349,454.628540 541.907104,452.331024 544.261475,449.834717 
	C544.332153,448.737122 544.160217,447.838348 544.259766,446.671692 
	C549.360901,446.581787 554.195007,446.683411 559.018860,446.961914 
	C562.899780,447.185974 565.523560,444.447113 565.985413,439.718231 
	C566.368225,435.798981 564.296326,434.743439 560.955933,434.573181 
	C561.693298,430.431244 563.751770,429.670319 567.424683,430.512665 
	C570.477661,431.212799 573.778870,430.830658 577.025146,431.293213 
	C578.560425,436.749786 581.424072,440.829987 585.794067,443.887604 
	C586.977844,444.715881 587.926208,445.880615 588.982666,446.890930 
	C582.557617,453.243683 576.132629,459.596405 569.367004,466.001617 
z"/>
<path fill="#204C9E" opacity="1.000000" stroke="none" 
	d="
M589.414917,446.912354 
	C587.926208,445.880615 586.977844,444.715881 585.794067,443.887604 
	C581.424072,440.829987 578.560425,436.749786 577.034058,430.851318 
	C577.096130,428.867401 577.204407,427.690674 577.550537,426.787964 
	C580.340149,436.545990 586.814270,442.397827 595.395508,446.412903 
	C595.284790,446.629791 595.028809,447.044098 595.028809,447.044098 
	C593.301575,447.007294 591.574402,446.970520 589.414917,446.912354 
z"/>
<path fill="#013AA5" opacity="1.000000" stroke="none" 
	d="
M513.465332,500.976135 
	C514.831665,499.972931 516.651672,498.188568 518.504395,498.153992 
	C523.724243,498.056671 528.957642,498.678558 534.641052,499.036835 
	C533.624695,499.705231 532.172668,500.853790 530.677612,500.912903 
	C525.104065,501.133484 519.516296,500.996552 513.465332,500.976135 
z"/>
<path fill="#023CA5" opacity="1.000000" stroke="none" 
	d="
M598.994263,448.963623 
	C598.689697,451.437683 598.385132,453.911743 598.049011,456.641449 
	C596.045288,456.122467 593.894348,455.565369 591.359009,454.993927 
	C592.294861,452.563721 593.615173,450.147827 594.982178,447.388000 
	C595.028809,447.044098 595.284790,446.629791 595.729675,446.415131 
	C596.787598,446.445770 597.400696,446.691071 598.024597,447.236450 
	C598.355042,448.012238 598.674622,448.487946 598.994263,448.963623 
z"/>
<path fill="#023CA5" opacity="1.000000" stroke="none" 
	d="
M583.635620,462.043396 
	C583.129944,465.862152 576.038818,473.428802 572.333069,473.980530 
	C574.328979,468.682495 578.698669,465.290283 583.635620,462.043396 
z"/>
<path fill="#013AA5" opacity="1.000000" stroke="none" 
	d="
M553.297363,482.844421 
	C553.605347,477.366821 556.936279,474.270752 562.615112,473.001343 
	C559.855347,476.230133 556.726318,479.466095 553.297363,482.844421 
z"/>
<path fill="#023CA5" opacity="1.000000" stroke="none" 
	d="
M554.696411,490.137939 
	C554.997314,490.439301 554.999512,490.886902 555.002808,491.670319 
	C551.922058,494.668915 548.840210,497.331726 545.391602,500.003723 
	C548.149170,496.770020 551.273376,493.527100 554.696411,490.137939 
z"/>
<path fill="#023CA5" opacity="1.000000" stroke="none" 
	d="
M590.645752,455.032898 
	C591.020386,459.223816 587.960327,460.666382 584.335144,461.952362 
	C586.088806,459.685974 588.200256,457.388367 590.645752,455.032898 
z"/>
<path fill="#013AA5" opacity="1.000000" stroke="none" 
	d="
M539.301636,496.867981 
	C539.012695,493.479309 540.945740,491.635712 544.640442,491.008636 
	C543.206909,492.911865 541.401917,494.817657 539.301636,496.867981 
z"/>
<path fill="#023CA5" opacity="1.000000" stroke="none" 
	d="
M567.643860,478.003906 
	C567.200684,478.943756 566.421753,479.932831 565.295776,480.949280 
	C565.735229,480.002167 566.521667,479.027679 567.643860,478.003906 
z"/>
<path fill="#013AA5" opacity="1.000000" stroke="none" 
	d="
M404.020874,499.364929 
	C404.931854,499.445923 405.907745,499.868317 406.945923,500.631348 
	C406.034088,500.550049 405.059937,500.128143 404.020874,499.364929 
z"/>
<path fill="#023CA5" opacity="1.000000" stroke="none" 
	d="
M562.683838,481.072693 
	C562.554565,481.663483 562.106995,482.286285 561.348877,482.955566 
	C561.480774,482.369598 561.923157,481.737152 562.683838,481.072693 
z"/>
<path fill="#013290" opacity="1.000000" stroke="none" 
	d="
M377.425842,497.999390 
	C377.662292,496.182098 379.110535,494.208405 378.786957,492.588684 
	C378.387421,490.588867 376.695618,487.505280 375.243988,487.314758 
	C371.248169,486.790314 371.026306,484.652374 370.946747,481.547272 
	C370.804077,475.979858 370.335693,470.420776 370.001709,464.429718 
	C378.762390,464.337860 387.525116,464.666809 396.287018,465.015594 
	C399.076904,465.126678 400.977600,465.984619 401.049683,469.374878 
	C401.176025,475.316650 401.700684,481.248840 401.940247,487.189789 
	C402.074219,490.511841 400.194336,492.020050 396.981171,491.990936 
	C393.199554,491.956726 390.372375,493.081512 388.642120,496.932953 
	C388.115265,498.105713 385.441711,498.695190 383.693787,498.835663 
	C381.781158,498.989410 379.801910,498.314056 377.425842,497.999390 
z"/>
<path fill="#064DCF" opacity="1.000000" stroke="none" 
	d="
M281.501282,495.241760 
	C280.899170,494.662476 280.651733,494.002167 280.284546,492.829590 
	C281.013336,491.177338 281.753571,489.933899 282.727936,488.914154 
	C289.102966,482.241791 295.528625,475.617828 302.182709,468.742676 
	C303.929932,467.307037 305.432190,466.106750 307.275604,464.880615 
	C309.082245,462.598114 310.547760,460.341492 312.349701,458.036713 
	C314.771118,457.633911 316.856079,457.279266 319.412903,456.947327 
	C355.256073,456.980408 390.627411,456.990814 425.999268,457.398132 
	C426.001282,458.853607 426.002808,459.912109 425.574860,460.980591 
	C423.427612,461.027557 421.709808,461.064575 419.992035,461.101593 
	C402.969513,461.072784 385.946991,461.043976 368.454956,461.046326 
	C357.966339,461.064636 347.947205,461.051788 336.993591,461.036926 
	C332.927124,461.026184 329.795135,461.010803 326.663147,461.009796 
	C317.219055,461.006744 307.492249,467.773102 303.805847,476.903870 
	C303.295654,477.092499 302.785492,477.281128 301.784607,477.757690 
	C298.213287,481.032135 295.132660,484.018555 292.052002,487.005005 
	C288.653351,489.723602 285.254639,492.442169 281.501282,495.241760 
z"/>
<path fill="#0348CC" opacity="1.000000" stroke="none" 
	d="
M426.004333,460.970642 
	C426.002808,459.912109 426.001282,458.853607 426.468048,457.394653 
	C456.497040,456.995728 486.057922,457.031891 515.618103,456.913483 
	C517.415955,456.906281 519.208923,455.672882 521.336975,455.007080 
	C522.113647,455.001984 522.557434,454.999786 523.148071,455.216736 
	C523.864502,455.299377 524.434082,455.162842 525.372437,455.027405 
	C526.478760,455.033813 527.216309,455.039154 528.025330,455.375122 
	C529.271179,455.751801 530.978394,456.318878 531.529480,455.755310 
	C534.201965,453.022583 536.564209,449.986481 539.448914,447.044373 
	C541.237061,447.000458 542.612610,446.970032 543.988281,446.939575 
	C544.160217,447.838348 544.332153,448.737122 543.899292,449.830109 
	C541.194336,452.347137 539.094238,454.669952 536.994080,456.992767 
	C536.994080,456.992767 537.000000,457.000000 536.644897,457.000549 
	C534.857605,457.998688 533.425293,458.996277 531.620117,459.996948 
	C530.161072,460.662445 529.075073,461.324890 527.989014,461.987335 
	C524.761047,463.979370 521.671997,466.203857 518.043945,462.353699 
	C517.302124,461.566467 515.188354,461.995972 513.706848,461.993591 
	C500.450592,461.972412 487.194336,461.980072 473.469666,461.989624 
	C471.612305,461.994385 470.223358,461.988770 468.140289,461.712372 
	C465.293732,461.322144 463.141327,461.202728 460.988922,461.083252 
	C457.964142,461.070251 454.939331,461.057281 450.992920,461.034058 
	C446.626862,461.015594 443.179565,460.915222 439.738770,461.022552 
	C435.437347,461.156677 430.577850,459.464783 427.602539,465.474457 
	C426.849609,463.352692 426.426971,462.161682 426.004333,460.970642 
z"/>
<path fill="#01389F" opacity="1.000000" stroke="none" 
	d="
M425.574860,460.980591 
	C426.426971,462.161682 426.849609,463.352692 427.602539,465.474457 
	C430.577850,459.464783 435.437347,461.156677 439.738770,461.022552 
	C443.179565,460.915222 446.626862,461.015594 450.539948,461.052032 
	C449.622437,464.933594 446.165283,464.395447 443.528503,463.854889 
	C437.603912,462.640259 432.079926,461.831268 428.650299,468.073883 
	C425.403870,465.668640 422.775177,463.721069 420.069275,461.437561 
	C421.709808,461.064575 423.427612,461.027557 425.574860,460.980591 
z"/>
<path fill="#013AA5" opacity="1.000000" stroke="none" 
	d="
M457.682739,479.764587 
	C452.998657,481.058441 448.339569,477.175812 449.200043,473.165039 
	C449.656952,471.035187 454.487823,469.807800 456.462524,471.663361 
	C458.687012,473.753754 461.407532,476.167603 457.682739,479.764587 
z"/>
<path fill="#01389F" opacity="1.000000" stroke="none" 
	d="
M461.040680,461.450043 
	C463.141327,461.202728 465.293732,461.322144 467.723694,461.718781 
	C467.667114,462.355988 467.284607,462.682404 467.007477,463.081940 
	C465.971191,464.575653 464.969482,466.093353 463.954773,467.602051 
	C463.000671,465.673645 462.046539,463.745209 461.040680,461.450043 
z"/>
<path fill="#012E8A" opacity="1.000000" stroke="none" 
	d="
M304.205841,476.930481 
	C307.492249,467.773102 317.219055,461.006744 326.663147,461.009796 
	C329.795135,461.010803 332.927124,461.026184 336.525696,461.071716 
	C337.999756,462.070496 338.853424,463.488861 340.049347,463.891968 
	C342.048035,464.565704 344.270477,464.558502 346.389740,464.894775 
	C353.999573,466.102142 353.998230,466.111145 353.999908,473.745178 
	C354.000427,476.077515 353.989624,478.409882 354.005219,480.742126 
	C354.011902,481.740326 353.658600,483.136597 354.174652,483.673096 
	C358.295349,487.957245 360.765778,493.897339 366.894897,496.208588 
	C368.913086,496.969666 372.422974,496.183594 371.997833,500.597839 
	C370.711487,501.428864 369.422974,501.858978 368.061157,502.644531 
	C355.971588,502.999146 343.955383,502.998291 331.470825,502.995667 
	C328.106293,496.426025 321.542877,497.386963 316.241425,496.155884 
	C313.489624,495.516876 311.267883,495.673981 310.641205,492.064606 
	C310.428314,490.838409 307.970673,489.938843 306.458038,489.023499 
	C305.436615,488.405396 304.273865,488.020813 303.174316,487.531799 
	C303.737610,486.414001 304.040466,484.651794 304.913025,484.299072 
	C307.538452,483.237854 310.353760,482.296753 313.153168,482.087708 
	C319.147797,481.639954 320.232422,479.878113 317.358856,474.663605 
	C315.925812,472.063171 314.618835,471.416199 311.954803,473.400574 
	C309.811401,474.997192 307.077667,475.801331 304.205841,476.930481 
z"/>
<path fill="#012B84" opacity="1.000000" stroke="none" 
	d="
M177.162094,482.655273 
	C177.061295,482.110870 177.063004,481.931610 177.095581,481.486938 
	C186.048004,481.475555 194.968689,481.776581 203.891891,481.943695 
	C205.663712,481.976868 207.445145,481.496765 209.852020,481.113708 
	C210.657135,481.000732 210.832367,481.027405 211.007584,481.054047 
	C210.526825,481.942017 210.046066,482.829987 209.281708,483.860199 
	C199.950821,484.000824 190.903076,484.044342 181.856674,483.953705 
	C180.323029,483.938324 178.795151,483.346130 177.162094,482.655273 
z"/>
<path fill="#0364B6" opacity="1.000000" stroke="none" 
	d="
M148.322678,482.949463 
	C149.157684,482.445526 150.361603,481.957336 151.785065,481.731995 
	C150.900253,482.318298 149.795929,482.641754 148.322678,482.949463 
z"/>
<path fill="#11A7F5" opacity="1.000000" stroke="none" 
	d="
M143.676727,474.910767 
	C144.409637,475.707764 144.850632,476.327972 145.592087,477.021545 
	C145.928787,478.770050 145.965042,480.445160 145.991409,482.540710 
	C143.041199,485.845154 139.576660,486.184631 136.941223,483.230042 
	C134.601227,480.606598 135.365616,477.443634 138.656677,474.990784 
	C140.454941,474.782349 141.919876,474.758148 143.676727,474.910767 
z"/>
<path fill="#012B84" opacity="1.000000" stroke="none" 
	d="
M301.937805,468.978088 
	C295.528625,475.617828 289.102966,482.241791 282.727936,488.914154 
	C281.753571,489.933899 281.013336,491.177338 279.993713,492.629883 
	C278.111816,493.946899 276.401031,494.951355 274.351349,495.992554 
	C273.952911,493.613159 274.257111,491.114929 273.735382,488.803101 
	C273.170715,486.300964 272.337646,482.335205 270.750458,481.848480 
	C263.347321,479.578064 264.860687,473.358276 264.129730,468.362671 
	C263.510498,464.130615 264.181580,459.724731 263.905121,455.419952 
	C263.800232,453.787140 262.938354,451.986847 261.898407,450.678680 
	C260.147461,448.476013 258.180786,446.317444 255.899689,444.718231 
	C254.783142,443.935455 252.496033,443.896332 251.196976,444.502563 
	C248.862610,445.591980 246.873779,447.421753 244.361160,448.961395 
	C243.981750,448.980591 243.924316,448.921021 243.980850,448.585480 
	C249.701248,442.503540 255.365112,436.757172 261.401886,431.007812 
	C264.906738,430.088715 268.498383,429.640381 267.986115,424.607422 
	C269.710175,422.184845 271.376099,420.112122 273.251160,417.920074 
	C273.720978,417.599884 273.914978,417.347717 274.315125,416.922974 
	C275.404938,416.263519 276.221924,415.725372 277.079651,415.472168 
	C277.763367,415.921570 278.406342,416.085999 279.049316,416.250427 
	C279.055511,415.205170 279.061737,414.159882 279.225281,412.834229 
	C280.510223,411.993500 281.637787,411.433197 283.135895,410.876373 
	C288.661346,408.447754 293.767181,404.106506 299.151672,410.495880 
	C299.335968,410.714630 299.768524,410.842163 300.073944,410.823608 
	C307.493469,410.373322 315.240082,413.055023 322.222260,408.395508 
	C322.627319,412.233337 320.978607,413.915100 316.509369,413.973694 
	C309.356354,414.067505 302.201233,414.007965 295.046997,414.002747 
	C291.666321,414.000305 289.696106,415.257629 289.973846,419.054474 
	C290.408386,424.994507 290.493958,430.965820 291.124237,436.881561 
	C291.273346,438.281097 293.055420,440.675690 293.992584,440.631378 
	C299.829651,440.355316 304.865784,441.186981 307.765991,447.043182 
	C308.287415,448.096069 309.067993,449.020569 309.728943,450.004333 
	C308.455139,450.487122 307.160461,450.922058 305.913666,451.466675 
	C304.733856,451.982025 302.651886,452.590088 302.637634,453.196564 
	C302.600342,454.786896 303.474487,456.398560 303.992401,458.333954 
	C304.003357,459.109161 304.007385,459.552185 303.741394,460.243469 
	C302.960175,463.320526 302.449005,466.149323 301.937805,468.978088 
M293.842377,469.509186 
	C293.424011,469.214294 293.005615,468.919373 292.587219,468.624481 
	C292.475433,469.061005 292.363617,469.497559 292.251831,469.934113 
	C292.740936,469.986450 293.230011,470.038788 293.842377,469.509186 
z"/>
<path fill="#0E76D3" opacity="1.000000" stroke="none" 
	d="
M239.319977,452.082581 
	C238.665329,452.545349 238.010666,453.008118 236.847733,453.765656 
	C235.572662,455.055206 234.805878,456.049988 234.039093,457.044769 
	C234.039108,457.044769 234.001831,456.998779 233.685669,456.999512 
	C232.920074,457.009613 232.470642,457.018982 232.021194,457.028351 
	C232.021194,457.028351 232.010544,457.014069 232.039368,456.654144 
	C232.357819,455.152740 232.647461,454.011292 232.958862,452.571472 
	C233.003387,451.875977 233.026154,451.478851 233.354538,451.033295 
	C234.351913,450.309753 235.043686,449.634613 236.030624,448.961823 
	C236.520981,448.961090 236.716171,448.957947 237.252319,449.016541 
	C238.094727,449.778290 238.596161,450.478241 239.131042,451.409760 
	C239.164474,451.641296 239.319977,452.082581 239.319977,452.082581 
z"/>
<path fill="#012B84" opacity="1.000000" stroke="none" 
	d="
M211.981339,478.999420 
	C214.382004,476.965973 216.782669,474.932526 219.535675,472.883362 
	C217.466171,475.549194 215.044312,478.230743 212.297043,480.609131 
	C211.974869,479.870453 211.978104,479.434937 211.981339,478.999420 
z"/>
<path fill="#012B84" opacity="1.000000" stroke="none" 
	d="
M220.222580,472.893311 
	C220.718933,471.375793 221.570724,469.864410 223.036774,468.178955 
	C224.086090,467.988708 224.521164,467.972565 224.974701,467.974670 
	C224.993164,467.992889 225.030273,468.029236 225.030273,468.029236 
	C223.546188,469.652649 222.062119,471.276062 220.222580,472.893311 
z"/>
<path fill="#012B84" opacity="1.000000" stroke="none" 
	d="
M239.668671,452.056732 
	C239.319977,452.082581 239.164474,451.641296 239.436310,451.326904 
	C240.161316,450.367371 240.614471,449.722229 241.034302,449.038727 
	C241.000977,449.000366 241.077881,449.067322 241.434937,449.054382 
	C242.502777,449.001312 243.213547,448.961151 243.924316,448.921021 
	C243.924316,448.921021 243.981750,448.980591 244.010834,449.010834 
	C242.699051,450.037689 241.358200,451.034271 239.668671,452.056732 
z"/>
<path fill="#012B84" opacity="1.000000" stroke="none" 
	d="
M224.946381,465.972382 
	C225.081238,465.664276 225.281998,465.407776 226.094849,465.095642 
	C227.083954,464.966614 227.526886,464.944763 227.969818,464.922913 
	C227.216217,465.925201 226.462631,466.927551 225.369659,467.979553 
	C225.030273,468.029236 224.993164,467.992889 224.983429,467.644592 
	C224.964584,466.854980 224.955490,466.413696 224.946381,465.972382 
z"/>
<path fill="#0251BD" opacity="1.000000" stroke="none" 
	d="
M234.352661,457.023254 
	C234.805878,456.049988 235.572662,455.055206 236.682907,454.026093 
	C237.026367,453.991760 237.200562,453.940369 237.158066,454.279144 
	C237.062805,455.072388 237.010040,455.526855 236.957275,455.981323 
	C236.193588,456.321442 235.429901,456.661591 234.352661,457.023254 
z"/>
<path fill="#0251BD" opacity="1.000000" stroke="none" 
	d="
M231.889343,457.319031 
	C232.470642,457.018982 232.920074,457.009613 233.706909,457.021057 
	C233.774048,457.818207 233.503784,458.594604 232.762741,459.684692 
	C231.853241,460.122894 231.414551,460.247437 230.975845,460.371979 
	C230.975845,460.371979 230.941330,460.187439 230.968475,460.097717 
	C231.249588,459.208588 231.503540,458.409180 231.889343,457.319031 
z"/>
<path fill="#0251BD" opacity="1.000000" stroke="none" 
	d="
M228.103729,464.639771 
	C227.526886,464.944763 227.083954,464.966614 226.308243,464.972534 
	C226.763077,463.993317 227.550705,463.030060 228.677002,462.039032 
	C228.756332,462.793091 228.496994,463.574860 228.103729,464.639771 
z"/>
<path fill="#012B84" opacity="1.000000" stroke="none" 
	d="
M237.245453,455.954163 
	C237.010040,455.526855 237.062805,455.072388 237.246887,454.298096 
	C237.770370,454.361877 238.162537,454.745514 238.554703,455.129181 
	C238.214340,455.395142 237.873978,455.661102 237.245453,455.954163 
z"/>
<path fill="#012B84" opacity="1.000000" stroke="none" 
	d="
M231.049362,460.627441 
	C231.414551,460.247437 231.853241,460.122894 232.633545,459.970398 
	C232.357712,460.255920 231.740295,460.569427 231.049362,460.627441 
z"/>
<path fill="#0364B6" opacity="1.000000" stroke="none" 
	d="
M211.673187,479.035278 
	C211.978104,479.434937 211.974869,479.870453 211.941147,480.630798 
	C211.910675,480.955658 211.456345,480.979126 211.231964,481.016602 
	C210.832367,481.027405 210.657135,481.000732 210.222015,480.916412 
	C210.429749,480.262909 210.897385,479.667053 211.673187,479.035278 
z"/>
<path fill="#092672" opacity="1.000000" stroke="none" 
	d="
M925.650696,380.118958 
	C920.962891,372.526306 915.484436,366.381500 905.312500,366.266296 
	C910.428345,362.472504 914.821106,366.158813 919.321655,368.343140 
	C922.251160,369.764984 924.923523,371.716827 927.851990,373.711975 
	C927.319092,375.989868 926.645264,377.987000 925.650696,380.118958 
z"/>
<path fill="#084EC4" opacity="1.000000" stroke="none" 
	d="
M882.957458,349.010254 
	C882.993652,347.856354 883.029846,346.702484 883.708679,345.231293 
	C887.454651,344.618561 890.564575,344.378143 893.658508,344.004822 
	C895.249268,343.812897 897.288696,343.906403 898.307373,342.975677 
	C903.983154,337.789429 909.408447,332.329041 914.945923,326.967377 
	C914.972412,326.972321 914.962097,326.919342 915.277344,326.795166 
	C918.759827,324.124207 921.301819,321.356964 921.232483,316.797119 
	C921.214294,315.600922 922.994812,313.374298 924.032715,313.323486 
	C932.510498,312.907959 936.163391,305.472168 941.999268,301.291626 
	C943.142029,300.473083 943.477112,298.527161 944.382263,296.992432 
	C944.823120,296.689331 944.989014,296.442871 945.431580,296.104370 
	C948.160034,294.368958 950.534363,292.673523 953.309570,290.985077 
	C955.142334,291.665192 956.574158,292.338287 957.684204,293.148407 
	C955.897827,294.511383 954.433167,295.737335 952.968567,296.963287 
	C952.968567,296.963287 953.000000,297.000000 952.640747,297.025421 
	C950.505554,299.022766 948.729614,300.994690 946.953735,302.966644 
	C941.724060,308.342834 936.538757,313.763092 931.255127,319.085724 
	C921.372253,329.041595 911.506226,339.017273 901.426392,348.770966 
	C900.105042,350.049500 897.598389,350.400574 895.583313,350.599060 
	C892.027710,350.949280 888.427979,350.851044 884.398315,350.666656 
	C883.619080,349.931915 883.288269,349.471069 882.957458,349.010254 
z"/>
<path fill="#092672" opacity="1.000000" stroke="none" 
	d="
M882.724609,349.311462 
	C883.288269,349.471069 883.619080,349.931915 883.984619,350.683838 
	C884.915955,353.887390 885.545593,356.916901 886.760986,359.689606 
	C889.937195,366.935547 896.738098,364.846802 902.738098,365.759033 
	C893.247925,367.744263 884.997437,372.380157 877.213684,380.321747 
	C879.126648,369.191711 880.809265,359.402191 882.724609,349.311462 
z"/>
<path fill="#01096A" opacity="1.000000" stroke="none" 
	d="
M974.029968,295.023956 
	C973.345032,298.884491 972.970520,302.841339 971.817871,306.556793 
	C971.375183,307.983765 969.158813,308.860474 967.373413,309.593109 
	C966.634277,303.230652 964.000427,301.083893 956.974670,301.029999 
	C957.294434,299.260620 957.614197,297.491241 958.269287,295.344666 
	C959.078552,294.668030 959.552429,294.368622 960.486694,294.062042 
	C964.963867,294.034454 968.980530,294.014008 973.110596,294.216919 
	C973.492676,294.634827 973.761292,294.829407 974.029968,295.023956 
z"/>
<path fill="#02228D" opacity="1.000000" stroke="none" 
	d="
M965.992676,310.020752 
	C965.991882,310.762970 965.991028,311.505157 965.986572,312.618195 
	C961.096313,314.237427 961.048157,318.112396 960.978821,322.054199 
	C960.914001,325.741058 957.629150,328.409210 953.513550,327.787903 
	C949.674866,327.208435 949.651306,323.980621 950.155029,321.177399 
	C950.394653,319.843658 951.755005,318.109589 952.998901,317.673767 
	C956.378235,316.489685 956.398132,314.682892 955.015198,311.966919 
	C953.259033,308.517853 953.539795,305.237793 956.659180,302.550354 
	C956.990845,303.490875 956.994446,304.237823 956.852173,305.336365 
	C955.441528,308.420807 955.479553,311.316833 958.462402,312.317993 
	C959.890259,312.797211 962.131470,310.853455 964.335754,310.004364 
	C965.109070,310.007996 965.550842,310.014374 965.992676,310.020752 
z"/>
<path fill="#011480" opacity="1.000000" stroke="none" 
	d="
M966.243896,310.014648 
	C965.550842,310.014374 965.109070,310.007996 964.330261,309.618530 
	C963.937439,304.073090 960.834717,303.924988 956.998047,304.984802 
	C956.994446,304.237823 956.990845,303.490875 956.972168,302.370575 
	C956.956970,301.997253 956.974121,301.513397 956.974365,301.271698 
	C964.000427,301.083893 966.634277,303.230652 966.996826,309.600372 
	C966.830017,310.004486 966.662537,310.006500 966.243896,310.014648 
z"/>
<path fill="#032CA7" opacity="1.000000" stroke="none" 
	d="
M947.302490,302.944061 
	C948.729614,300.994690 950.505554,299.022766 952.622314,297.009613 
	C951.192505,298.952759 949.421814,300.937103 947.302490,302.944061 
z"/>
<path fill="#032CA7" opacity="1.000000" stroke="none" 
	d="
M960.026306,294.069153 
	C959.552429,294.368622 959.078552,294.668030 958.314331,295.000854 
	C956.570068,295.661163 955.116150,296.287994 953.315430,296.939087 
	C954.433167,295.737335 955.897827,294.511383 957.986084,293.175110 
	C959.081970,293.399567 959.554138,293.734344 960.026306,294.069153 
z"/>
<path fill="#0639BE" opacity="1.000000" stroke="none" 
	d="
M1031.033325,293.981232 
	C1030.533569,294.313538 1030.033691,294.645844 1029.221436,295.025787 
	C1028.908936,295.073425 1028.464966,295.019958 1028.141113,294.601990 
	C1027.145020,293.794403 1026.474976,293.069855 1025.800171,293.065399 
	C1015.694519,292.998444 1005.587646,292.982574 995.483093,293.111237 
	C994.629944,293.122101 993.791016,294.250580 992.945496,294.859863 
	C992.526245,294.874756 992.106934,294.889679 991.325684,294.592896 
	C990.599548,293.813202 990.235474,293.345245 989.893311,292.484375 
	C989.946411,291.042542 989.977600,289.993622 990.468994,288.940613 
	C994.623779,288.927246 998.318420,288.917969 1002.013062,288.908691 
	C1010.018677,288.946381 1018.024292,288.984100 1026.543213,289.362793 
	C1028.382202,291.129608 1029.707764,292.555450 1031.033325,293.981232 
z"/>
<path fill="#0639BE" opacity="1.000000" stroke="none" 
	d="
M960.486694,294.062012 
	C959.554138,293.734344 959.081970,293.399567 958.307861,293.038086 
	C956.574158,292.338287 955.142334,291.665192 953.332031,290.658112 
	C952.977844,289.887512 953.002014,289.450836 953.493286,289.015717 
	C961.638245,288.988434 969.316284,288.959595 977.413086,288.949677 
	C979.492126,288.901093 981.152344,288.833588 982.872620,289.147064 
	C983.315674,290.752045 983.698608,291.976074 984.081604,293.200073 
	C982.649597,293.490326 981.217590,293.780609 979.159424,293.791229 
	C976.687927,293.672241 974.842590,293.832886 972.997253,293.993561 
	C968.980530,294.014008 964.963867,294.034454 960.486694,294.062012 
z"/>
<path fill="#032CA7" opacity="1.000000" stroke="none" 
	d="
M993.417542,294.904816 
	C993.791016,294.250580 994.629944,293.122101 995.483093,293.111237 
	C1005.587646,292.982574 1015.694519,292.998444 1025.800171,293.065399 
	C1026.474976,293.069855 1027.145020,293.794403 1027.919312,294.574524 
	C1016.644104,294.959900 1005.266846,294.954834 993.417542,294.904816 
z"/>
<path fill="#0974E0" opacity="1.000000" stroke="none" 
	d="
M984.477539,293.173279 
	C983.698608,291.976074 983.315674,290.752045 983.012695,288.840485 
	C984.015686,287.530121 984.938599,286.907288 986.180298,286.478790 
	C987.668945,287.430359 988.838867,288.187561 990.008789,288.944733 
	C989.977600,289.993622 989.946411,291.042542 989.630859,292.550690 
	C989.182739,293.087158 989.019043,293.164398 988.855286,293.241669 
	C987.528015,293.209930 986.200684,293.178223 984.477539,293.173279 
z"/>
<path fill="#032CA7" opacity="1.000000" stroke="none" 
	d="
M973.110596,294.216919 
	C974.842590,293.832886 976.687927,293.672241 978.777405,293.794678 
	C977.611816,294.384644 976.202148,294.691498 974.411194,295.011169 
	C973.761292,294.829407 973.492676,294.634827 973.110596,294.216919 
z"/>
<path fill="#032CA7" opacity="1.000000" stroke="none" 
	d="
M988.942261,293.536255 
	C989.019043,293.164398 989.182739,293.087158 989.608887,292.943634 
	C990.235474,293.345245 990.599548,293.813202 991.011230,294.579041 
	C990.382263,294.528229 989.705688,294.179565 988.942261,293.536255 
z"/>
<path fill="#010B64" opacity="1.000000" stroke="none" 
	d="
M985.861511,286.284424 
	C984.938599,286.907288 984.015686,287.530121 982.952637,288.459534 
	C981.152344,288.833588 979.492126,288.901093 977.417847,288.556946 
	C978.576721,285.026245 979.592407,281.407471 981.843689,278.896698 
	C985.978882,274.284912 990.895447,270.381165 995.363953,266.055908 
	C996.488098,264.967773 997.148926,263.400879 998.022644,262.054016 
	C1000.773010,262.251404 1003.523438,262.448792 1006.643677,262.806519 
	C1003.468567,267.236267 1000.045044,271.615326 996.332031,275.733307 
	C994.160889,278.141296 991.527405,280.132507 988.714722,282.686035 
	C987.505493,284.135315 986.683533,285.209839 985.861511,286.284424 
z"/>
<path fill="#01247B" opacity="1.000000" stroke="none" 
	d="
M986.180298,286.478760 
	C986.683533,285.209839 987.505493,284.135315 988.657654,283.021973 
	C992.326721,283.997437 995.698120,284.919403 998.986023,286.078552 
	C1000.065186,286.459045 1000.927490,287.454651 1001.951050,288.538818 
	C998.318420,288.917969 994.623779,288.927246 990.468994,288.940613 
	C988.838867,288.187561 987.668945,287.430359 986.180298,286.478760 
z"/>
<path fill="#0946BC" opacity="1.000000" stroke="none" 
	d="
M1009.920288,263.040466 
	C1010.247192,259.727020 1011.188904,257.531250 1014.955811,260.670258 
	C1015.009094,261.748993 1014.977722,262.474518 1014.924561,263.561005 
	C1014.604614,264.066254 1014.350647,264.266632 1013.655029,264.734558 
	C1012.086182,264.310791 1011.003235,263.675629 1009.920288,263.040466 
z"/>
<path fill="#02257D" opacity="1.000000" stroke="none" 
	d="
M1009.721924,263.143005 
	C1011.003235,263.675629 1012.086182,264.310791 1013.528320,264.943604 
	C1013.239197,266.564270 1012.737732,269.492462 1011.917175,269.584808 
	C1008.328674,269.988586 1009.203064,266.906433 1008.994873,264.362061 
	C1009.094910,263.659424 1009.272095,263.424438 1009.721924,263.143005 
z"/>
<path fill="#032699" opacity="1.000000" stroke="none" 
	d="
M1031.376465,293.982239 
	C1029.707764,292.555450 1028.382202,291.129608 1027.009399,289.371948 
	C1028.961914,290.440857 1030.961670,291.841614 1033.028564,293.583496 
	C1032.636963,293.944153 1032.178345,293.963684 1031.376465,293.982239 
z"/>
<path fill="#0964CE" opacity="1.000000" stroke="none" 
	d="
M956.999756,116.997841 
	C956.999756,116.997841 957.464661,117.032669 957.623718,117.260201 
	C957.552307,117.861267 957.321838,118.234795 956.894409,118.917511 
	C955.454590,121.154831 954.211792,123.082977 952.856567,125.217285 
	C952.565796,125.698410 952.319458,125.882500 952.005188,125.975716 
	C952.005188,125.975716 951.541504,125.972290 951.292786,125.570625 
	C950.799561,124.581955 950.652283,123.552071 950.296082,123.473892 
	C944.211365,122.138489 944.737183,117.543091 945.034119,113.041183 
	C945.034119,113.041183 945.000977,113.000366 945.451660,113.019867 
	C950.623352,111.494263 953.514160,115.079712 956.999756,116.997841 
z"/>
<path fill="#0BC4F6" opacity="1.000000" stroke="none" 
	d="
M952.968872,125.011124 
	C954.211792,123.082977 955.454590,121.154831 957.283936,118.999283 
	C958.926514,118.731911 959.982544,118.691940 961.038635,118.651962 
	C961.453064,118.816322 961.867432,118.980682 962.618347,119.133904 
	C963.202576,119.605309 963.450378,120.087860 963.880005,121.063217 
	C964.283630,122.026466 964.505493,122.496918 964.727417,122.967392 
	C963.843140,124.734230 962.958862,126.501053 962.000244,128.596375 
	C961.166809,129.243408 960.407593,129.561920 959.067139,129.650162 
	C957.817932,129.494431 957.149963,129.568985 956.481995,129.643539 
	C956.125488,129.549194 955.768982,129.454865 955.196777,128.887482 
	C954.310303,127.280014 953.639587,126.145576 952.968872,125.011124 
z"/>
<path fill="#01319F" opacity="1.000000" stroke="none" 
	d="
M944.640259,113.031586 
	C944.737183,117.543091 944.211365,122.138489 950.296082,123.473892 
	C950.652283,123.552071 950.799561,124.581955 951.063232,125.538284 
	C947.022461,124.186401 941.963562,123.695625 941.164490,117.934677 
	C940.821228,115.459785 940.887146,113.195648 944.640259,113.031586 
z"/>
<path fill="#01319F" opacity="1.000000" stroke="none" 
	d="
M956.966003,116.641045 
	C953.514160,115.079712 950.623352,111.494263 945.471802,113.036972 
	C946.222290,108.949745 948.559570,107.790207 951.549316,110.216537 
	C953.620789,111.897652 955.156921,114.238319 956.966003,116.641045 
z"/>
<path fill="#0E8ED8" opacity="1.000000" stroke="none" 
	d="
M952.856567,125.217300 
	C953.639587,126.145576 954.310303,127.280014 954.944458,128.773010 
	C953.957092,128.320480 953.006226,127.509399 952.030273,126.337021 
	C952.319458,125.882500 952.565796,125.698410 952.856567,125.217300 
z"/>
<path fill="#01319F" opacity="1.000000" stroke="none" 
	d="
M961.032349,118.345665 
	C959.982544,118.691940 958.926514,118.731911 957.480835,118.690094 
	C957.321838,118.234795 957.552307,117.861267 957.856140,117.278778 
	C958.961670,117.393005 959.993835,117.716179 961.032349,118.345665 
z"/>
<path fill="#0E8ED8" opacity="1.000000" stroke="none" 
	d="
M956.625427,129.913788 
	C957.149963,129.568985 957.817932,129.494431 958.741577,129.656219 
	C958.996155,130.090378 958.994995,130.288193 958.992310,130.783310 
	C958.250244,130.781738 957.509521,130.482895 956.625427,129.913788 
z"/>
<path fill="#01247B" opacity="1.000000" stroke="none" 
	d="
M918.992371,177.024323 
	C932.344727,177.011032 945.697021,176.997742 959.516052,176.978241 
	C963.018982,176.980347 966.055176,176.988647 969.545715,176.999695 
	C965.978271,180.641663 965.534546,185.506577 964.261841,190.473160 
	C962.948486,195.598404 963.508911,197.106827 968.480957,197.369781 
	C969.533447,197.425446 970.549377,198.172913 971.582153,198.601639 
	C971.657288,199.087646 971.732422,199.573639 971.807556,200.059647 
	C968.851746,200.704468 965.910400,201.789612 962.936646,201.887146 
	C958.848267,202.021194 954.738159,201.258865 950.631775,201.182922 
	C948.820679,201.149445 946.995911,201.859421 944.752930,202.559814 
	C939.150208,198.114731 933.809692,193.503021 928.848267,188.514252 
	C925.299683,184.946121 922.259460,180.872452 918.992371,177.024323 
z"/>
<path fill="#0D65C3" opacity="1.000000" stroke="none" 
	d="
M965.182861,122.984161 
	C964.505493,122.496918 964.283630,122.026466 964.026611,121.295647 
	C964.151794,120.955009 964.312195,120.874733 965.047974,120.892120 
	C966.752686,121.274780 967.881897,121.559769 969.011169,121.844757 
	C970.072876,121.873611 971.134644,121.902458 972.967407,121.951599 
	C974.496094,121.935020 975.253601,121.898140 976.011169,121.861267 
	C976.564514,122.051086 977.117859,122.240913 977.852905,122.661758 
	C973.902466,122.928848 969.770325,122.964905 965.182861,122.984161 
z"/>
<path fill="#011773" opacity="1.000000" stroke="none" 
	d="
M918.548340,177.013092 
	C922.259460,180.872452 925.299683,184.946121 928.848267,188.514252 
	C933.809692,193.503021 939.150208,198.114731 944.661865,202.940826 
	C947.610779,204.180222 952.267212,200.203232 952.543579,206.578430 
	C948.365173,207.615433 944.641602,208.231171 940.918030,208.846924 
	C940.918030,208.846939 940.468689,208.871689 939.926941,208.881439 
	C938.962158,208.848679 938.539124,208.806168 938.069214,208.399719 
	C936.352295,207.355942 934.682251,206.676147 932.970703,205.648712 
	C928.738464,200.097076 924.481201,194.944794 920.378113,189.672607 
	C917.148621,185.522842 914.109192,181.225220 910.985352,176.993301 
	C913.358337,176.996140 915.731384,176.998993 918.548340,177.013092 
z"/>
<path fill="#02228D" opacity="1.000000" stroke="none" 
	d="
M1026.545898,206.992218 
	C1027.002197,207.434021 1027.004272,207.877991 1027.008057,208.654572 
	C1011.739075,208.988220 996.468262,209.025955 981.198059,208.937973 
	C979.526306,208.928360 977.858704,208.195816 976.087036,207.408234 
	C989.346069,207.010635 1002.707214,207.003418 1016.537842,206.988480 
	C1020.035461,206.985306 1023.063721,206.989838 1026.545898,206.992218 
z"/>
<path fill="#02228D" opacity="1.000000" stroke="none" 
	d="
M941.384888,208.895279 
	C944.641602,208.231171 948.365173,207.615433 952.548035,206.991165 
	C953.317810,207.094070 953.575012,207.279419 953.893860,207.749115 
	C949.956482,208.287537 945.904114,208.615585 941.384888,208.895279 
z"/>
<path fill="#01257E" opacity="1.000000" stroke="none" 
	d="
M968.951538,121.535263 
	C967.881897,121.559769 966.752686,121.274780 965.305908,120.815735 
	C966.289551,120.836380 967.590698,121.031082 968.951538,121.535263 
z"/>
<path fill="#01257E" opacity="1.000000" stroke="none" 
	d="
M975.949219,121.549149 
	C975.253601,121.898140 974.496094,121.935020 973.363647,121.905182 
	C973.954956,121.637978 974.921143,121.437508 975.949219,121.549149 
z"/>
<path fill="#01247B" opacity="1.000000" stroke="none" 
	d="
M997.592285,262.033752 
	C997.148926,263.400879 996.488098,264.967773 995.363953,266.055908 
	C990.895447,270.381165 985.978882,274.284912 981.843689,278.896698 
	C979.592407,281.407471 978.576721,285.026245 976.999023,288.538025 
	C969.316284,288.959595 961.638245,288.988434 953.124939,289.013123 
	C949.885559,291.387451 947.481506,293.765900 945.077454,296.144348 
	C944.989014,296.442871 944.823120,296.689331 944.006104,296.988373 
	C941.266724,298.928131 939.138733,300.809937 936.926697,302.587219 
	C933.582642,305.273987 931.785034,310.242493 925.629883,308.715912 
	C923.747192,308.248932 919.835999,310.847961 918.844910,312.972015 
	C916.831970,317.285767 916.176819,322.233124 914.962036,326.919342 
	C914.962097,326.919342 914.972412,326.972321 914.562256,326.986206 
	C910.514771,328.117310 906.927795,329.509399 903.210510,330.190399 
	C902.040283,330.404785 900.520630,328.711761 899.160950,327.891754 
	C899.948364,326.929474 900.579834,325.733276 901.569092,325.073761 
	C902.475342,324.469666 903.789246,324.510315 904.886353,324.152893 
	C908.104553,323.104431 909.379517,321.880249 905.842224,319.228790 
	C904.769165,318.424438 904.572876,316.217773 904.336426,314.600525 
	C904.131897,313.201294 905.087402,311.168915 904.409363,310.382202 
	C901.367981,306.852631 904.109619,304.203217 905.856812,302.079376 
	C908.536316,298.822113 912.363098,296.521210 915.126038,293.316956 
	C919.029724,288.789795 922.407349,283.809052 926.179260,278.765350 
	C927.229126,277.671051 928.109619,276.833618 929.358887,275.992981 
	C933.340027,275.034576 935.772461,273.016724 936.114380,268.798309 
	C936.420410,268.302521 936.679504,268.109467 937.343262,267.963989 
	C939.130859,266.299164 940.575195,264.669159 942.019592,263.039124 
	C942.763367,263.020874 943.507202,263.002563 944.884766,263.260254 
	C947.680664,263.350952 949.842773,263.165680 952.004883,262.980408 
	C962.619568,262.976471 973.235352,263.063507 983.848328,262.925354 
	C988.289368,262.867523 992.724182,262.331879 997.592285,262.033752 
z"/>
<path fill="#01116C" opacity="1.000000" stroke="none" 
	d="
M938.083496,257.034302 
	C937.071533,258.446442 936.059570,259.858612 934.677612,261.628418 
	C933.898193,262.017578 933.488831,262.049133 932.725220,262.081543 
	C931.248413,262.383423 930.125732,262.684418 928.915771,262.612061 
	C926.218994,262.156097 923.609497,262.073486 920.545837,261.992126 
	C909.737061,261.673004 899.382385,261.352692 888.580322,261.020996 
	C885.747009,261.014587 883.361267,261.019592 880.533142,261.022095 
	C877.729004,261.018982 875.367249,261.018372 872.784180,260.750366 
	C871.436279,259.821228 870.309631,259.159454 869.183044,258.497711 
	C869.299377,257.991821 869.415771,257.485931 869.532166,256.980042 
	C874.691895,256.980042 879.851624,256.980042 885.460205,256.982697 
	C888.602112,256.983154 891.295288,256.980927 894.363281,256.984589 
	C895.831238,256.334808 896.924194,255.679169 898.484741,255.020966 
	C911.631653,255.015732 924.310913,255.013046 937.018677,255.314606 
	C937.392639,256.090637 937.738098,256.562469 938.083496,257.034302 
z"/>
<path fill="#033ABC" opacity="1.000000" stroke="none" 
	d="
M933.079407,262.080627 
	C933.488831,262.049133 933.898193,262.017578 934.612915,261.957275 
	C936.899353,262.049438 938.880554,262.170349 940.627930,262.801941 
	C939.262756,264.874634 938.131348,266.436707 937.000000,267.998779 
	C936.679504,268.109467 936.420410,268.302521 935.804077,268.940063 
	C933.253662,271.533478 931.121887,273.764832 928.990112,275.996155 
	C928.109619,276.833618 927.229126,277.671051 925.875183,278.900146 
	C917.596436,287.047943 909.843262,294.858063 901.932617,302.505249 
	C900.661987,303.733521 898.687622,304.233887 897.171265,304.755310 
	C897.493286,302.903259 897.682495,301.366669 897.917297,299.524353 
	C898.304749,298.472473 898.646729,297.726257 899.308350,296.941406 
	C900.084595,296.268127 900.541077,295.633453 901.266296,294.798889 
	C902.132935,293.814819 902.642395,292.939453 903.342102,292.260101 
	C912.227722,283.633240 921.168457,275.062805 930.015076,266.396240 
	C931.255432,265.181152 932.068542,263.529968 933.079407,262.080627 
z"/>
<path fill="#01177C" opacity="1.000000" stroke="none" 
	d="
M938.543762,257.030029 
	C937.738098,256.562469 937.392639,256.090637 937.473999,255.310684 
	C946.268066,254.668152 954.635254,254.333771 963.002441,253.999390 
	C978.014832,253.999603 993.027283,253.999832 1008.505005,253.999252 
	C1008.979370,254.750931 1008.988403,255.503433 1008.996338,256.632324 
	C1004.117004,257.013000 999.238647,257.020264 994.360352,257.020996 
	C975.908264,257.023712 957.456116,257.024353 938.543762,257.030029 
z"/>
<path fill="#022A86" opacity="1.000000" stroke="none" 
	d="
M937.343262,267.963989 
	C938.131348,266.436707 939.262756,264.874634 940.697021,263.153351 
	C941.169983,262.999268 941.339905,263.004395 941.764771,263.024323 
	C940.575195,264.669159 939.130859,266.299164 937.343262,267.963989 
z"/>
<path fill="#022A86" opacity="1.000000" stroke="none" 
	d="
M951.738342,262.712463 
	C949.842773,263.165680 947.680664,263.350952 945.256836,263.259796 
	C947.153992,262.803741 949.312866,262.624146 951.738342,262.712463 
z"/>
<path fill="#159CE3" opacity="1.000000" stroke="none" 
	d="
M1050.590576,258.193726 
	C1050.132568,256.289185 1050.007324,254.561783 1049.882080,252.834381 
	C1051.245850,252.768311 1052.808960,252.271240 1053.907227,252.779205 
	C1054.715210,253.152847 1055.562256,255.558121 1055.273193,255.819260 
	C1054.051514,256.922577 1052.411011,257.561859 1050.590576,258.193726 
z"/>
<path fill="#01257D" opacity="1.000000" stroke="none" 
	d="
M962.939819,253.651627 
	C954.635254,254.333771 946.268066,254.668152 937.445557,255.006470 
	C924.310913,255.013046 911.631653,255.015732 898.109192,255.014526 
	C896.173523,255.666672 895.080933,256.322693 893.988403,256.978729 
	C891.295288,256.980927 888.602112,256.983154 885.410156,256.666962 
	C884.272583,255.898239 883.633850,255.447906 883.092407,254.608154 
	C885.843201,253.812241 888.573242,253.664459 891.123291,252.908920 
	C892.844238,252.399017 894.311340,251.032104 895.892029,250.048584 
	C894.255432,249.033936 892.733337,247.602158 890.952454,247.113525 
	C888.870300,246.542252 886.562439,246.793396 883.939270,246.667999 
	C884.843628,242.070480 887.927979,243.094849 890.265381,243.038177 
	C894.614441,242.932693 898.914795,242.937286 901.429688,238.035294 
	C904.046448,237.605713 906.684814,237.812531 908.316528,236.630157 
	C910.861023,234.786285 912.773315,232.043335 914.860107,229.601578 
	C917.278015,226.772369 919.594849,223.856827 922.119873,220.683548 
	C923.293579,219.106079 924.078979,217.508072 925.355591,216.602432 
	C928.791870,214.164795 931.960632,210.404068 936.991882,213.494354 
	C936.567200,219.739639 940.905579,220.232056 945.076050,221.371765 
	C949.164429,223.690430 954.723083,222.393234 957.092224,218.622818 
	C957.871643,217.382462 957.732666,215.565018 958.011963,213.762238 
	C958.010315,213.513016 958.006653,213.014587 958.006653,213.014587 
	C959.394348,213.011536 960.782043,213.008499 962.841675,213.279022 
	C965.010742,213.703979 966.507812,213.855347 968.004883,214.006714 
	C967.891907,215.862396 967.770874,217.717636 967.670410,219.574005 
	C967.635925,220.210251 967.926514,221.141281 967.604736,221.442734 
	C963.586304,225.208405 965.675537,230.133453 964.890076,234.509857 
	C964.428833,237.079712 962.885925,239.455429 961.556519,242.555099 
	C961.904602,243.064240 962.714661,244.248917 963.524658,245.433609 
	C963.077271,245.505768 962.629822,245.577927 962.182434,245.650085 
	C962.414001,248.201340 962.645569,250.752609 962.939819,253.651627 
z"/>
<path fill="#02228D" opacity="1.000000" stroke="none" 
	d="
M968.447266,214.005402 
	C966.507812,213.855347 965.010742,213.703979 963.258057,213.281799 
	C967.370239,212.680786 971.738037,212.350601 976.572998,212.043915 
	C974.323364,212.712982 971.606567,213.358536 968.447266,214.005402 
z"/>
<path fill="#0235B0" opacity="1.000000" stroke="none" 
	d="
M936.984253,213.101349 
	C931.960632,210.404068 928.791870,214.164795 925.355591,216.602432 
	C924.078979,217.508072 923.293579,219.106079 921.777710,220.728348 
	C918.521179,223.046417 915.771179,225.024506 912.613159,227.000702 
	C911.115173,226.987350 910.025085,226.975906 909.002441,226.624847 
	C910.279602,224.992416 911.563782,223.760727 912.686584,222.396271 
	C917.833008,216.142303 922.970398,209.894180 931.075073,207.423248 
	C933.488831,208.117126 935.802490,208.440399 938.116089,208.763672 
	C938.539124,208.806168 938.962158,208.848679 939.703857,208.910294 
	C940.011963,209.691910 940.001526,210.454437 939.773926,211.716278 
	C939.284485,212.417038 939.096497,212.678909 938.657471,213.003876 
	C937.876221,213.038132 937.430237,213.069733 936.984253,213.101349 
z"/>
<path fill="#02228D" opacity="1.000000" stroke="none" 
	d="
M938.992676,213.001221 
	C939.096497,212.678909 939.284485,212.417038 939.770752,212.096497 
	C945.792053,212.128860 951.599365,212.280334 957.706665,212.723206 
	C958.006653,213.014587 958.010315,213.513016 957.677002,213.759491 
	C956.897461,214.007843 956.451172,214.009720 955.537476,214.005920 
	C950.045898,214.003967 945.021729,214.007690 939.780579,213.897339 
	C939.291260,213.582504 939.100952,213.321823 938.992676,213.001221 
z"/>
<path fill="#02267C" opacity="1.000000" stroke="none" 
	d="
M235.735458,448.959442 
	C235.043686,449.634613 234.351913,450.309753 233.163635,450.778687 
	C230.588272,452.176666 228.509430,453.780884 226.430603,455.385101 
	C226.865906,455.910583 227.301208,456.436066 227.736526,456.961578 
	C227.015915,457.835999 226.295303,458.710419 225.229599,459.385101 
	C223.884796,459.329468 222.626434,459.141052 221.933807,459.679871 
	C219.844757,461.305023 217.975632,463.212921 215.654968,465.011902 
	C214.196884,466.012726 213.104599,467.012115 212.007080,468.007080 
	C212.001862,468.002594 212.011688,468.013184 212.011688,468.013184 
	C211.006989,466.423462 209.579315,464.952850 209.090576,463.217804 
	C208.223068,460.138123 207.624847,457.869690 204.493576,455.409149 
	C201.079712,452.726593 196.708862,448.995514 195.074417,445.587982 
	C192.733887,440.708374 194.194672,433.943085 194.385498,427.994263 
	C194.408203,427.286682 196.737289,426.775146 197.828781,425.949860 
	C199.284866,424.848877 201.514572,423.662842 201.711868,422.272156 
	C201.988770,420.320435 200.842072,418.090881 200.040115,416.085815 
	C199.388184,414.455841 198.347733,412.981293 198.007034,411.323425 
	C199.354828,411.152100 200.175156,411.095154 201.411255,411.039490 
	C203.210922,411.010742 204.594818,410.980682 206.350800,410.977753 
	C207.466461,410.960571 208.210068,410.916290 209.102875,411.225006 
	C210.833694,414.719879 212.415314,417.861786 213.997787,421.412720 
	C212.932678,424.232819 211.866745,426.643829 210.420013,429.916199 
	C216.306503,429.115784 218.992905,423.979340 223.327179,420.967529 
	C224.088318,420.939545 224.522934,420.921265 225.026794,421.260559 
	C227.108719,422.614777 229.152145,423.554413 231.127182,424.620728 
	C234.422562,426.399963 234.102554,427.946594 231.415344,430.445770 
	C228.879013,432.804657 227.049011,436.002075 225.269180,439.041931 
	C224.802139,439.839600 225.673920,441.421143 225.930847,442.642700 
	C227.265305,442.263489 229.024841,442.285706 229.843155,441.419098 
	C231.407654,439.762390 232.307281,437.498718 233.729980,435.680298 
	C234.796188,434.317505 236.222977,433.227417 237.536652,432.069763 
	C239.514587,430.326782 241.533920,428.630737 243.535660,426.914734 
	C243.261322,426.432587 242.986984,425.950439 242.712646,425.468262 
	C238.444489,425.382446 238.040115,417.880737 232.369614,420.486481 
	C231.913086,419.324310 231.456543,418.162170 231.007599,416.615204 
	C231.951477,414.856842 232.887741,413.483246 233.823990,412.109680 
	C234.680481,413.293243 235.358780,414.689484 236.444656,415.599152 
	C237.483017,416.468964 238.894745,417.096832 240.233780,417.352814 
	C241.946198,417.680237 244.771439,416.848846 245.338547,417.700775 
	C247.836533,421.453430 252.669052,424.318024 250.855438,430.296326 
	C250.146179,431.739197 249.582718,432.888214 248.896301,434.300385 
	C248.182388,435.040619 247.591431,435.517700 246.995636,435.646301 
	C246.493744,434.661713 245.996704,434.025574 245.499649,433.389435 
	C244.333084,434.926270 243.166534,436.463135 241.734009,438.197205 
	C239.311417,441.114532 237.086166,443.785706 235.073425,446.608459 
	C234.777390,447.023651 235.489685,448.157898 235.735458,448.959442 
z"/>
<path fill="#0BC4F6" opacity="1.000000" stroke="none" 
	d="
M198.943100,399.275940 
	C199.802338,399.055389 200.529373,399.038788 201.621094,399.010376 
	C204.089752,399.564087 206.193726,400.129639 208.635895,401.228943 
	C208.948013,402.502563 208.921936,403.242462 208.644073,404.206696 
	C207.912003,405.931732 207.431686,407.432434 206.968704,408.964355 
	C206.986023,408.995575 206.929871,408.951263 206.484192,408.961334 
	C204.220840,409.111420 202.403168,409.251465 200.405670,409.375793 
	C200.225861,409.360077 199.894791,409.216156 199.785370,408.836090 
	C199.102325,406.298065 198.528717,404.140076 197.985138,401.660034 
	C198.021896,400.908997 198.028610,400.480011 198.035324,400.051025 
	C198.345245,399.930481 198.603760,399.740112 198.943100,399.275940 
z"/>
<path fill="#01308D" opacity="1.000000" stroke="none" 
	d="
M216.020737,465.010529 
	C217.975632,463.212921 219.844757,461.305023 221.933807,459.679871 
	C222.626434,459.141052 223.884796,459.329468 224.932159,459.475830 
	C219.211838,465.489990 213.443863,471.213715 207.328552,476.927063 
	C208.631866,474.182465 210.282532,471.448242 211.972443,468.363586 
	C212.011688,468.013184 212.001862,468.002594 212.334595,467.973389 
	C213.785110,466.966278 214.902924,465.988403 216.020737,465.010529 
z"/>
<path fill="#0350AC" opacity="1.000000" stroke="none" 
	d="
M197.701065,400.011841 
	C198.028610,400.480011 198.021896,400.908997 197.720825,401.913879 
	C197.620758,404.656738 197.815033,406.823669 197.938263,409.053528 
	C197.867203,409.116486 197.719086,409.235199 197.719086,409.235199 
	C196.821762,407.091278 195.924454,404.947388 195.265106,402.235229 
	C195.675903,401.115448 195.848740,400.563965 196.021576,400.012451 
	C196.469986,399.999176 196.918396,399.985931 197.701065,400.011841 
z"/>
<path fill="#0350AC" opacity="1.000000" stroke="none" 
	d="
M199.894791,409.216156 
	C199.894791,409.216156 200.225861,409.360077 200.502884,409.640625 
	C200.851776,410.293518 200.923630,410.665863 200.995499,411.038208 
	C200.175156,411.095154 199.354828,411.152100 198.262970,411.103302 
	C197.948593,410.602173 197.905731,410.206787 197.790985,409.523315 
	C197.719086,409.235199 197.867203,409.116486 198.252228,409.096863 
	C199.056442,409.123535 199.475616,409.169861 199.894791,409.216156 
z"/>
<path fill="#02267C" opacity="1.000000" stroke="none" 
	d="
M195.728729,400.040771 
	C195.848740,400.563965 195.675903,401.115448 195.266388,401.841766 
	C195.165100,401.367432 195.300507,400.718262 195.728729,400.040771 
z"/>
<path fill="#01308D" opacity="1.000000" stroke="none" 
	d="
M228.026886,456.961975 
	C227.301208,456.436066 226.865906,455.910583 226.430603,455.385101 
	C228.509430,453.780884 230.588272,452.176666 232.858032,450.827087 
	C233.026154,451.478851 233.003387,451.875977 232.625214,452.641479 
	C231.145813,454.323547 230.021835,455.637268 228.897858,456.950989 
	C228.704315,456.954773 228.510773,456.958557 228.026886,456.961975 
z"/>
<path fill="#0964CE" opacity="1.000000" stroke="none" 
	d="
M229.286270,456.969635 
	C230.021835,455.637268 231.145813,454.323547 232.603455,452.939819 
	C232.647461,454.011292 232.357819,455.152740 232.034088,456.647125 
	C231.224899,456.996094 230.449783,456.992188 229.286270,456.969635 
z"/>
<path fill="#0251BD" opacity="1.000000" stroke="none" 
	d="
M224.633820,466.024353 
	C224.955490,466.413696 224.964584,466.854980 224.964966,467.626343 
	C224.521164,467.972565 224.086090,467.988708 223.323959,467.999512 
	C223.438354,467.354889 223.879791,466.715607 224.633820,466.024353 
z"/>
<path fill="#012D89" opacity="1.000000" stroke="none" 
	d="
M257.014404,276.052765 
	C257.342651,270.119202 257.610077,264.181427 258.021820,258.253693 
	C258.271515,254.659317 256.980652,253.428024 253.339447,254.273392 
	C252.016266,254.580612 250.430557,253.756989 248.964630,253.449310 
	C249.064758,252.632858 249.164871,251.816391 249.264984,250.999939 
	C254.939789,250.999939 260.617584,251.106033 266.287872,250.946198 
	C268.468414,250.884735 270.631897,250.217682 273.376587,249.426422 
	C286.913544,249.017578 299.877350,249.045563 312.840332,248.956512 
	C314.576019,248.944611 316.307587,248.341141 318.406616,248.006317 
	C319.504700,248.013092 320.237183,248.025162 320.978363,248.426178 
	C321.004578,249.852432 321.022095,250.889740 321.059479,252.287125 
	C320.707855,254.216690 320.693665,256.861145 319.889374,257.128479 
	C318.290039,257.660095 316.022125,257.351379 314.443176,256.563171 
	C308.704865,253.698593 307.043976,254.564514 307.002167,260.921478 
	C306.992340,262.413910 306.389160,264.418884 307.123871,265.306366 
	C309.688965,268.404846 312.256042,272.292236 315.702057,273.654297 
	C319.335785,275.090576 323.977722,273.976227 328.552124,273.976227 
	C329.406250,275.341888 330.585083,277.226685 332.380798,280.097809 
	C329.224091,279.631073 327.108643,279.318298 324.754395,278.910065 
	C324.068146,278.561188 323.620697,278.307739 323.173279,278.054291 
	C321.800293,277.305603 320.445374,275.951935 319.051239,275.910217 
	C312.014374,275.699585 304.967255,275.830627 297.198181,275.643188 
	C288.551788,272.832520 280.458893,270.860840 272.108643,272.125122 
	C268.292786,272.702881 264.680573,274.625732 260.580200,275.956085 
	C259.127747,276.000031 258.071075,276.026398 257.014404,276.052765 
z"/>
<path fill="#0A3185" opacity="1.000000" stroke="none" 
	d="
M290.560669,317.068420 
	C289.783417,323.131073 289.168945,329.220703 288.171722,335.246948 
	C287.505432,339.273193 279.279846,345.510345 274.296967,345.917969 
	C269.211212,346.334015 266.412689,342.923828 264.347290,339.008026 
	C260.625275,331.951385 260.632965,324.387085 262.501282,316.430054 
	C263.695465,316.347992 264.338623,316.802002 264.938812,316.750916 
	C271.084167,316.227814 275.398346,317.733673 276.486633,324.893646 
	C276.685638,326.202881 280.782898,327.619934 283.106842,327.665955 
	C284.725769,327.697968 287.080933,325.803070 287.860138,324.172882 
	C288.861633,322.077545 288.614258,319.385284 288.944824,316.955811 
	C288.984833,316.961487 288.937378,316.894775 289.208008,316.925964 
	C289.839294,316.994263 290.199982,317.031342 290.560669,317.068420 
z"/>
<path fill="#023290" opacity="1.000000" stroke="none" 
	d="
M318.122498,311.911041 
	C318.799377,306.135651 319.476257,300.360260 320.739502,294.089111 
	C328.696198,292.850067 336.066589,292.106720 343.122009,291.395142 
	C343.956848,294.183472 344.376404,295.584778 344.878510,297.398956 
	C346.774170,302.356628 348.587311,306.901459 350.046326,311.562256 
	C339.168945,311.755859 328.645752,311.833435 318.122498,311.911041 
z"/>
<path fill="#08348D" opacity="1.000000" stroke="none" 
	d="
M316.919373,318.971954 
	C316.959534,318.221039 316.999725,317.470123 317.593506,316.445007 
	C329.749939,316.372864 341.352722,316.574890 353.323059,316.796753 
	C353.933838,316.786682 354.177094,316.756714 354.596222,316.691528 
	C354.772064,316.656311 355.071014,316.854431 355.092651,317.232605 
	C355.852783,319.567474 356.078979,321.020386 353.129669,321.135712 
	C351.391998,321.203674 349.685760,322.075104 347.649353,322.742737 
	C346.868469,322.932983 346.403595,322.965698 345.545227,322.961487 
	C339.338135,323.566620 337.129730,326.147064 337.570099,332.078247 
	C337.402252,332.912048 337.228363,333.457947 336.834717,334.114136 
	C336.350037,334.431824 336.156250,334.692139 335.606628,335.003204 
	C327.309601,332.906403 320.183868,333.103485 315.545319,341.864105 
	C315.464142,338.918457 316.181854,336.626984 316.339142,334.297668 
	C316.683411,329.199280 316.743317,324.081696 316.919373,318.971954 
z"/>
<path fill="#01177C" opacity="1.000000" stroke="none" 
	d="
M344.795959,296.986084 
	C344.376404,295.584778 343.956848,294.183472 343.122009,291.395142 
	C336.066589,292.106720 328.696198,292.850067 320.952057,293.755127 
	C321.260590,288.832123 321.942871,283.747345 322.899231,278.358429 
	C323.620697,278.307739 324.068146,278.561188 324.597107,279.264984 
	C324.467072,281.258850 324.255524,282.802368 323.977936,284.827759 
	C330.271912,285.243835 335.952271,285.983337 341.609070,285.833527 
	C344.274689,285.762939 346.893463,283.980408 349.536621,282.981995 
	C351.315613,282.310028 353.100098,281.652710 354.719208,281.273895 
	C349.158112,285.344421 346.064026,290.587616 344.795959,296.986084 
z"/>
<path fill="#084EC4" opacity="1.000000" stroke="none" 
	d="
M288.904846,316.950165 
	C288.614258,319.385284 288.861633,322.077545 287.860138,324.172882 
	C287.080933,325.803070 284.725769,327.697968 283.106842,327.665955 
	C280.782898,327.619934 276.685638,326.202881 276.486633,324.893646 
	C275.398346,317.733673 271.084167,316.227814 264.938812,316.750916 
	C264.338623,316.802002 263.695465,316.347992 262.690338,316.050476 
	C262.496399,314.536407 262.684357,313.100342 263.257996,311.310242 
	C263.643707,310.956238 264.070709,310.944031 264.529572,310.987732 
	C271.788269,311.015869 278.591156,310.884216 285.385925,311.062134 
	C287.201019,311.109680 288.991364,312.103668 290.888519,312.801758 
	C290.983978,312.940369 291.186523,313.209198 291.186523,313.209198 
	C291.110168,314.263336 291.033813,315.317474 290.759033,316.720032 
	C290.199982,317.031342 289.839294,316.994263 289.189941,316.534210 
	C287.155426,312.543030 281.758026,311.716736 276.716797,314.005066 
	C277.512146,316.181641 278.038605,318.450653 279.148926,320.384430 
	C279.956970,321.791748 281.657196,323.793945 282.742096,323.667297 
	C284.247772,323.491547 285.674561,321.684418 286.896515,320.356201 
	C287.769073,319.407715 288.249451,318.098450 288.904846,316.950165 
z"/>
<path fill="#084EC4" opacity="1.000000" stroke="none" 
	d="
M352.955536,316.776917 
	C341.352722,316.574890 329.749939,316.372864 317.759033,316.102509 
	C317.499084,314.867859 317.627228,313.701508 317.938965,312.223114 
	C328.645752,311.833435 339.168945,311.755859 350.007263,311.663544 
	C350.322327,311.648804 350.107941,311.669678 350.240173,311.981628 
	C350.769287,312.491730 351.166199,312.689911 351.858917,312.941956 
	C352.515686,313.857697 352.876648,314.719543 353.152588,315.876892 
	C353.030243,316.373840 352.992889,316.575378 352.955536,316.776917 
z"/>
<path fill="#0C307F" opacity="1.000000" stroke="none" 
	d="
M316.744751,319.328308 
	C316.743317,324.081696 316.683411,329.199280 316.339142,334.297668 
	C316.181854,336.626984 315.464142,338.918457 315.545319,341.864105 
	C320.183868,333.103485 327.309601,332.906403 335.592346,335.400940 
	C335.989410,336.862579 335.973816,337.924194 335.613770,339.054932 
	C334.180634,341.747406 333.091919,344.370789 331.845795,347.294525 
	C328.512421,349.746155 325.336487,351.897400 322.160522,354.048615 
	C322.160522,354.048615 321.668549,353.994537 321.421997,354.015320 
	C327.221252,349.831329 330.881927,344.154724 332.097870,336.497223 
	C326.844971,335.898315 321.923859,334.489502 319.557037,341.386505 
	C318.856903,343.426605 316.066895,344.749512 314.234589,346.401093 
	C313.706787,346.096527 313.178986,345.791992 312.651215,345.487427 
	C313.957520,336.886505 315.263855,328.285583 316.744751,319.328308 
z"/>
<path fill="#142A6D" opacity="1.000000" stroke="none" 
	d="
M272.753754,291.719757 
	C279.309753,284.089081 285.008118,283.667969 291.280396,290.978394 
	C292.952972,292.927765 293.619537,296.249512 293.663452,298.958771 
	C293.719025,302.389069 292.666656,305.837311 291.745544,309.632202 
	C291.398956,309.986603 291.003662,309.983185 291.005463,309.526184 
	C291.155365,305.756195 290.843597,302.338867 291.566193,299.156342 
	C292.500641,295.040771 290.620728,292.636688 287.259888,292.183380 
	C282.605927,291.555634 277.800873,292.047882 272.983154,291.975952 
	C272.905823,291.891327 272.753754,291.719757 272.753754,291.719757 
z"/>
<path fill="#02277E" opacity="1.000000" stroke="none" 
	d="
M260.976013,275.938507 
	C264.680573,274.625732 268.292786,272.702881 272.108643,272.125122 
	C280.458893,270.860840 288.551788,272.832520 296.733643,275.713898 
	C296.999634,277.876953 296.879883,280.401062 294.215424,278.659973 
	C284.168213,272.094666 273.677948,273.255066 262.276642,276.115021 
	C261.378693,275.993286 261.177338,275.965881 260.976013,275.938507 
z"/>
<path fill="#142A6D" opacity="1.000000" stroke="none" 
	d="
M264.070709,310.944031 
	C264.070709,310.944031 263.643707,310.956238 263.431641,310.983032 
	C264.925171,306.420868 266.630768,301.831848 268.683075,297.133179 
	C269.029785,297.023499 269.091553,297.078369 269.064911,297.478333 
	C267.382416,302.233521 265.726562,306.588776 264.070709,310.944031 
z"/>
<path fill="#142A6D" opacity="1.000000" stroke="none" 
	d="
M268.999329,296.995605 
	C270.022888,295.292755 271.076904,293.617798 272.442352,291.831299 
	C272.753754,291.719757 272.905823,291.891327 272.954407,292.327454 
	C271.699158,294.201813 270.395355,295.640076 269.091553,297.078369 
	C269.091553,297.078369 269.029785,297.023499 268.999329,296.995605 
z"/>
<path fill="#001168" opacity="1.000000" stroke="none" 
	d="
M260.580200,275.956085 
	C261.177338,275.965881 261.378693,275.993286 261.883301,276.049927 
	C260.946747,276.782104 259.706940,277.485077 258.195190,278.353729 
	C257.660614,277.991699 257.398041,277.463989 257.074921,276.494507 
	C258.071075,276.026398 259.127747,276.000031 260.580200,275.956085 
z"/>
<path fill="#084EC4" opacity="1.000000" stroke="none" 
	d="
M291.003662,309.983185 
	C291.003662,309.983185 291.398956,309.986603 291.595337,310.008545 
	C291.751495,310.927734 291.711304,311.825012 291.428833,312.965759 
	C291.186523,313.209198 290.983978,312.940369 290.942017,312.473999 
	C290.934601,311.332825 290.969116,310.658020 291.003662,309.983185 
z"/>
<path fill="#012880" opacity="1.000000" stroke="none" 
	d="
M213.996948,421.003662 
	C212.415314,417.861786 210.833694,414.719879 209.189209,410.866211 
	C209.755981,406.791809 210.385605,403.429199 211.015228,400.066589 
	C215.173233,395.829498 219.331253,391.592438 224.136932,387.181885 
	C225.528458,386.025208 226.272324,385.041992 227.016205,384.058807 
	C231.195236,379.829407 235.374252,375.600006 240.058456,371.112579 
	C240.816071,370.657776 240.973923,370.402466 241.037216,370.088684 
	C244.539749,366.092987 248.726776,363.710815 254.816833,364.918762 
	C257.809998,365.576080 260.237640,365.802917 262.666412,366.017242 
	C266.610413,366.365356 270.555176,366.705170 273.288849,366.943085 
	C272.794769,372.133331 272.047394,376.251129 272.100922,380.358521 
	C272.169342,385.609467 277.851013,389.438477 282.726379,387.490265 
	C287.538910,385.567200 292.114288,383.567871 297.278625,387.095886 
	C298.058136,387.761719 298.576782,388.307495 298.920227,389.179626 
	C297.425781,389.927856 295.645996,391.106537 294.870636,390.634705 
	C292.172852,388.993042 290.543793,388.669739 288.310211,391.669220 
	C286.934937,393.516113 283.017303,393.510773 281.723816,395.369110 
	C278.999603,399.282959 277.250977,403.875916 275.092987,408.394470 
	C275.083984,408.598724 274.991943,408.997223 274.627747,408.999634 
	C269.026855,413.708893 263.751709,418.374603 258.606995,423.179962 
	C258.029999,423.718842 258.191711,425.048645 258.005432,426.005829 
	C258.000000,426.000000 258.011871,426.010925 257.651428,426.008301 
	C255.194412,427.337952 253.097824,428.670197 251.001221,430.002441 
	C252.669052,424.318024 247.836533,421.453430 245.338547,417.700775 
	C244.771439,416.848846 241.946198,417.680237 240.233780,417.352814 
	C238.894745,417.096832 237.483017,416.468964 236.444656,415.599152 
	C235.358780,414.689484 234.680481,413.293243 233.823990,412.109680 
	C232.887741,413.483246 231.951477,414.856842 230.601868,416.615173 
	C229.106644,416.990265 228.024750,416.980560 227.108276,416.622803 
	C227.720032,414.036163 228.868622,411.639343 228.410172,409.604645 
	C227.973907,407.668488 226.176620,405.242340 224.406082,404.594849 
	C221.907684,403.681213 217.984482,403.025299 216.254120,404.341370 
	C211.328613,408.087677 211.240677,411.380554 214.055237,417.201019 
	C214.610825,418.349976 214.742020,419.704132 214.801056,420.977142 
	C214.354446,420.994965 214.175705,420.999298 213.996948,421.003662 
z"/>
<path fill="#01308D" opacity="1.000000" stroke="none" 
	d="
M297.017761,386.975861 
	C292.114288,383.567871 287.538910,385.567200 282.726379,387.490265 
	C277.851013,389.438477 272.169342,385.609467 272.100922,380.358521 
	C272.047394,376.251129 272.794769,372.133331 273.288849,366.943085 
	C270.555176,366.705170 266.610413,366.365356 262.666412,366.017242 
	C260.237640,365.802917 257.809998,365.576080 255.201508,365.066956 
	C255.817184,364.591644 256.613312,364.240143 257.409241,364.240570 
	C288.940521,364.257507 320.471771,364.306396 352.485535,364.761658 
	C353.976288,365.457458 354.984528,365.742737 356.459351,366.019989 
	C361.620117,366.015350 366.314301,366.018768 371.291504,366.300171 
	C374.050446,366.719177 376.526459,366.860199 378.931793,367.368652 
	C376.518707,368.419617 374.208160,369.517365 371.827209,369.700714 
	C366.834564,370.085236 361.786713,369.792297 356.805481,370.252716 
	C355.789032,370.346680 354.587402,372.409668 354.154968,373.780670 
	C353.681122,375.282959 353.999390,377.035156 353.512695,378.761719 
	C347.691010,378.935242 342.329163,379.025421 336.503662,379.065369 
	C333.220123,379.000793 330.397095,379.066345 327.580872,378.956787 
	C319.804840,378.654388 311.539978,380.736969 304.879059,374.170685 
	C303.518311,374.119293 302.233063,374.343750 300.675690,374.685181 
	C300.211121,374.846100 300.018616,374.890106 299.485077,374.945312 
	C298.463867,375.669586 297.783722,376.382629 296.949097,377.433228 
	C296.896393,380.149261 296.998169,382.527740 297.067535,385.250305 
	C297.029297,386.054871 297.023529,386.515381 297.017761,386.975861 
z"/>
<path fill="#159CE3" opacity="1.000000" stroke="none" 
	d="
M359.003601,363.892090 
	C359.002319,363.171783 359.001038,362.451508 359.285187,361.343750 
	C360.054321,360.694305 360.537994,360.432373 361.390289,360.142456 
	C362.497437,360.135040 363.235962,360.155548 364.305237,360.427795 
	C379.518860,360.776886 394.412964,361.271942 409.281281,360.852875 
	C422.545593,360.479004 436.109619,363.478394 449.027161,358.361816 
	C448.982025,359.455750 448.927521,360.184326 448.699493,361.153442 
	C447.690094,361.940063 446.854156,362.486115 445.601288,363.074585 
	C442.903931,363.412018 440.625641,363.944336 438.342804,363.964630 
	C419.515411,364.132080 400.687195,364.204651 381.414032,364.261536 
	C377.687378,364.235779 374.405914,364.254425 370.662781,364.190704 
	C366.468567,364.036255 362.736084,363.964172 359.003601,363.892090 
z"/>
<path fill="#0870B6" opacity="1.000000" stroke="none" 
	d="
M361.021698,360.170410 
	C360.537994,360.432373 360.054321,360.694305 359.285309,360.982574 
	C344.541901,360.889893 330.083832,360.770905 315.301117,360.397400 
	C316.062958,359.745972 317.149414,359.349121 319.074280,358.966858 
	C330.977112,358.967407 342.041534,358.953339 353.404846,358.953766 
	C354.105469,358.963257 354.507172,358.958252 355.287109,358.978943 
	C356.800385,358.674561 357.935425,358.344452 359.400909,357.910950 
	C360.154358,357.962494 360.577423,358.117371 361.019562,358.588074 
	C361.032990,359.326050 361.027344,359.748230 361.021698,360.170410 
z"/>
<path fill="#0251BD" opacity="1.000000" stroke="none" 
	d="
M235.310791,368.848694 
	C234.633377,368.106232 234.047012,367.252960 233.913971,366.334076 
	C233.628967,364.365479 232.688171,361.559265 233.611420,360.536896 
	C235.720993,358.200867 238.576218,358.409241 240.825195,361.858826 
	C240.990631,362.600952 240.988052,362.777039 240.985504,362.953186 
	C240.198639,363.239594 239.411774,363.526001 238.183929,363.618591 
	C237.559052,363.229309 237.335098,363.107330 237.071106,363.058807 
	C236.583176,364.941925 236.095245,366.825073 235.310791,368.848694 
z"/>
<path fill="#0364B6" opacity="1.000000" stroke="none" 
	d="
M358.613708,363.930695 
	C362.736084,363.964172 366.468567,364.036255 370.633179,364.478638 
	C371.046356,365.240051 371.027405,365.631104 371.008484,366.022186 
	C366.314301,366.018768 361.620117,366.015350 356.452637,365.718201 
	C355.950439,365.026154 355.921509,364.627838 355.892578,364.229492 
	C356.669678,364.142761 357.446747,364.056030 358.613708,363.930695 
z"/>
<path fill="#0870B6" opacity="1.000000" stroke="none" 
	d="
M278.261414,360.796722 
	C282.346222,360.305054 286.906067,359.908844 291.750275,359.827057 
	C287.601898,360.391724 283.169189,360.641937 278.261414,360.796722 
z"/>
<path fill="#0350AC" opacity="1.000000" stroke="none" 
	d="
M210.740479,400.133545 
	C210.385605,403.429199 209.755981,406.791809 209.040009,410.513214 
	C208.210068,410.916290 207.466461,410.960571 206.455597,410.720123 
	C206.435501,409.940674 206.682678,409.445953 206.929871,408.951263 
	C206.929871,408.951263 206.986023,408.995575 207.302261,408.824036 
	C208.044281,407.095764 208.470062,405.539032 208.895859,403.982330 
	C208.921936,403.242462 208.948013,402.502563 208.977280,401.393036 
	C209.475555,400.749084 209.970642,400.474792 210.740479,400.133545 
z"/>
<path fill="#0870B6" opacity="1.000000" stroke="none" 
	d="
M295.212952,360.371429 
	C296.502625,359.945374 298.045715,359.756195 299.809082,359.861115 
	C298.508362,360.306213 296.987366,360.457245 295.212952,360.371429 
z"/>
<path fill="#03429E" opacity="1.000000" stroke="none" 
	d="
M355.512085,364.185455 
	C355.921509,364.627838 355.950439,365.026154 355.986084,365.726257 
	C354.984528,365.742737 353.976288,365.457458 352.930237,364.881531 
	C353.638824,364.441071 354.385193,364.291229 355.512085,364.185455 
z"/>
<path fill="#0870B6" opacity="1.000000" stroke="none" 
	d="
M251.301849,360.422852 
	C251.034195,359.991180 251.035721,359.799652 251.074478,359.323608 
	C252.383499,359.378204 253.655304,359.717377 255.077606,360.356659 
	C254.009094,360.658844 252.790085,360.660919 251.301849,360.422852 
z"/>
<path fill="#0350AC" opacity="1.000000" stroke="none" 
	d="
M226.703384,384.151123 
	C226.272324,385.041992 225.528458,386.025208 224.426239,387.017365 
	C224.842117,386.098694 225.616348,385.171082 226.703384,384.151123 
z"/>
<path fill="#0870B6" opacity="1.000000" stroke="none" 
	d="
M241.286591,362.936707 
	C240.988052,362.777039 240.990631,362.600952 241.003174,362.159729 
	C241.412811,361.882538 241.812485,361.870514 242.508682,361.826385 
	C242.399368,362.169647 241.993530,362.544952 241.286591,362.936707 
z"/>
<path fill="#0870B6" opacity="1.000000" stroke="none" 
	d="
M237.157532,363.242432 
	C237.335098,363.107330 237.559052,363.229309 237.848511,363.583313 
	C237.658264,363.769440 237.421555,363.664154 237.157532,363.242432 
z"/>
<path fill="#0350AC" opacity="1.000000" stroke="none" 
	d="
M240.812286,370.168274 
	C240.973923,370.402466 240.816071,370.657776 240.360886,370.958374 
	C240.155350,370.713837 240.298431,370.442413 240.812286,370.168274 
z"/>
<path fill="#023290" opacity="1.000000" stroke="none" 
	d="
M353.105927,358.939270 
	C342.041534,358.953339 330.977112,358.967407 319.442627,358.936127 
	C319.968658,357.493317 320.964783,356.095856 322.060730,354.373505 
	C325.336487,351.897400 328.512421,349.746155 332.180603,347.352539 
	C334.075439,348.900085 335.596130,350.346832 338.279114,349.238953 
	C341.921112,351.771393 345.363617,354.050659 348.825470,356.300232 
	C350.230591,357.213257 351.677856,358.061523 353.105927,358.939270 
z"/>
<path fill="#35BDFA" opacity="1.000000" stroke="none" 
	d="
M223.942871,361.122620 
	C223.740753,362.771240 223.489288,364.409363 222.592270,365.531433 
	C222.278625,365.923767 220.119034,365.347534 219.286133,364.642273 
	C218.715622,364.159210 218.708252,361.860748 218.916870,361.791962 
	C220.424210,361.294739 222.050827,361.159241 223.942871,361.122620 
z"/>
<path fill="#000A73" opacity="1.000000" stroke="none" 
	d="
M318.041077,248.011597 
	C316.307587,248.341141 314.576019,248.944611 312.840332,248.956512 
	C299.877350,249.045563 286.913544,249.017578 273.483276,249.037781 
	C262.321198,249.035141 251.625870,249.020996 240.459747,248.671600 
	C239.966858,247.881531 239.944794,247.426712 239.922729,246.971909 
	C240.223373,246.509735 240.524017,246.047577 241.325989,245.314484 
	C243.528320,244.657791 245.229309,244.272034 247.339539,243.867279 
	C252.166000,243.895767 256.583130,243.978699 261.000397,243.984497 
	C278.652069,244.007690 296.303741,244.006912 314.148804,244.269867 
	C315.575165,245.687012 316.808105,246.849304 318.041077,248.011597 
z"/>
<path fill="#032CA7" opacity="1.000000" stroke="none" 
	d="
M239.594437,247.025757 
	C239.944794,247.426712 239.966858,247.881531 239.990631,248.678406 
	C235.221741,253.655548 230.451141,258.290619 225.333908,262.958740 
	C227.635071,259.585815 230.282867,256.179871 233.009903,252.452911 
	C235.148148,250.447784 237.207138,248.763687 239.594437,247.025757 
z"/>
<path fill="#01389F" opacity="1.000000" stroke="none" 
	d="
M209.243225,276.326355 
	C210.762100,275.070160 212.520447,274.063568 214.623596,273.011353 
	C214.355988,276.017120 212.780273,277.604736 209.243225,276.326355 
z"/>
<path fill="#01389F" opacity="1.000000" stroke="none" 
	d="
M217.288330,270.937500 
	C217.432556,270.338165 217.900314,269.709259 218.687134,269.043213 
	C218.541397,269.640076 218.076630,270.274017 217.288330,270.937500 
z"/>
<path fill="#01389F" opacity="1.000000" stroke="none" 
	d="
M221.319916,266.957947 
	C221.446030,266.357208 221.892685,265.714447 222.655792,265.021057 
	C222.528320,265.618958 222.084396,266.267395 221.319916,266.957947 
z"/>
<path fill="#012D89" opacity="1.000000" stroke="none" 
	d="
M252.376648,275.449951 
	C251.942886,275.337494 251.679916,275.061859 251.416946,274.786224 
	C251.531158,274.677490 251.738892,274.467926 251.746048,274.474579 
	C252.024582,274.733032 252.284836,275.011200 252.376648,275.449951 
z"/>
<path fill="#0239A0" opacity="1.000000" stroke="none" 
	d="
M176.365082,271.878876 
	C177.420425,271.220703 178.937210,270.152313 180.486343,270.103119 
	C186.775085,269.903473 193.125198,269.513489 199.354950,270.150055 
	C206.173935,270.846832 210.980942,268.482056 215.283066,263.418243 
	C217.755875,260.507629 221.224503,258.443024 224.605591,255.980331 
	C220.497757,260.597992 216.223480,265.448456 211.452942,269.748352 
	C209.831131,271.210114 206.971725,271.840271 204.655319,271.894867 
	C195.388840,272.113098 186.113480,271.955658 176.365082,271.878876 
z"/>
<path fill="#0239A0" opacity="1.000000" stroke="none" 
	d="
M225.878601,252.324005 
	C226.442520,252.011810 226.880997,251.995300 227.648163,251.976257 
	C227.226868,252.964737 226.476837,253.955765 225.394424,255.000488 
	C225.292404,254.242676 225.522766,253.431183 225.878601,252.324005 
z"/>
<path fill="#0137AC" opacity="1.000000" stroke="none" 
	d="
M354.347351,189.039810 
	C350.036285,183.889923 345.506378,180.244217 338.350922,180.899353 
	C332.920959,181.396530 327.409119,180.998993 321.464630,181.006744 
	C315.198059,180.868942 309.400604,180.723969 303.602814,180.005051 
	C317.500031,179.191147 331.397552,178.951202 345.295074,178.711258 
	C345.294312,178.105118 345.293549,177.498978 345.292786,176.892838 
	C336.422455,176.590744 327.550049,176.336014 318.683441,175.948914 
	C316.778259,175.865753 314.893921,175.304276 312.992493,174.708511 
	C312.981812,174.284119 312.978638,174.114349 312.975494,173.944565 
	C313.144196,173.949356 313.312897,173.954147 313.734619,173.957520 
	C322.679321,173.970474 331.371002,173.984848 340.799072,174.275238 
	C343.360779,174.694733 345.186096,174.838226 347.011414,174.981720 
	C348.950287,175.407074 350.889160,175.832413 352.908020,176.629761 
	C353.007507,177.833847 352.674591,179.002319 353.099457,179.447327 
	C359.081665,185.713226 365.065826,191.985550 371.299286,197.997330 
	C372.833160,199.476639 375.546478,199.690414 377.207458,201.091003 
	C379.395660,202.936188 382.457397,205.313705 382.564789,207.584320 
	C382.658234,209.559677 379.343445,211.935150 377.135406,213.605988 
	C376.608948,214.004349 374.319061,212.497299 373.164581,211.493622 
	C372.374298,210.806549 372.027130,209.510529 371.685120,208.422821 
	C368.867950,199.463898 361.653290,194.387863 354.347351,189.039810 
z"/>
<path fill="#064DCF" opacity="1.000000" stroke="none" 
	d="
M313.000000,174.963120 
	C314.893921,175.304276 316.778259,175.865753 318.683441,175.948914 
	C327.550049,176.336014 336.422455,176.590744 345.292786,176.892838 
	C345.293549,177.498978 345.294312,178.105118 345.295074,178.711258 
	C331.397552,178.951202 317.500031,179.191147 303.300751,179.718475 
	C300.602356,179.980133 298.205688,179.954391 295.449585,179.550323 
	C295.699341,177.834229 296.308533,176.496445 296.917755,175.158676 
	C300.655182,174.791611 304.392578,174.424530 308.800964,174.306305 
	C310.647949,174.691147 311.823975,174.827133 313.000000,174.963120 
z"/>
<path fill="#0E76D3" opacity="1.000000" stroke="none" 
	d="
M296.555664,175.109283 
	C296.308533,176.496445 295.699341,177.834229 295.004333,179.485870 
	C292.603760,179.846985 290.289093,179.900497 287.974121,179.940170 
	C282.998444,180.025406 277.760345,178.856491 273.983612,183.786835 
	C273.286987,184.696213 271.238220,184.569809 269.295502,184.719513 
	C268.485870,184.085587 268.194336,183.653625 268.284180,183.122681 
	C274.503418,182.057404 276.332275,179.474442 275.516602,173.976227 
	C277.698517,174.364349 279.511078,175.100662 281.240814,174.940781 
	C284.051483,174.681000 287.901215,176.788177 289.167389,172.101349 
	C289.167389,172.101349 289.581848,172.103043 289.965027,172.315063 
	C290.914520,172.647644 291.480774,172.768219 292.047058,172.888809 
	C293.429230,173.612503 294.811371,174.336197 296.555664,175.109283 
z"/>
<path fill="#012982" opacity="1.000000" stroke="none" 
	d="
M267.902771,183.221649 
	C268.194336,183.653625 268.485870,184.085587 268.908569,184.744324 
	C268.899323,185.518082 268.548065,186.112000 268.657410,186.603378 
	C269.009827,188.187073 269.532043,189.732986 269.988770,191.293472 
	C268.219269,190.899170 266.374878,190.691528 264.714600,190.025146 
	C263.978210,189.729584 263.613892,188.506943 263.178162,187.037689 
	C263.514008,185.268677 263.752411,184.167114 264.324921,182.998840 
	C264.879639,182.878189 265.100281,182.824219 265.644531,182.803253 
	C266.613007,182.964737 267.257904,183.093201 267.902771,183.221649 
z"/>
<path fill="#02267D" opacity="1.000000" stroke="none" 
	d="
M263.990814,183.065552 
	C263.752411,184.167114 263.514008,185.268677 263.137207,186.684998 
	C262.554352,186.999924 262.109894,187.000076 261.332123,187.000549 
	C260.434387,186.002563 259.869995,185.004257 259.305634,184.005951 
	C260.288940,183.670517 261.272278,183.335083 262.878204,183.008347 
	C263.500824,183.017044 263.990814,183.065552 263.990814,183.065552 
z"/>
<path fill="#01399A" opacity="1.000000" stroke="none" 
	d="
M288.769989,172.104813 
	C287.901215,176.788177 284.051483,174.681000 281.240814,174.940781 
	C279.511078,175.100662 277.698517,174.364349 275.505371,173.971176 
	C275.087616,173.911911 275.080627,173.891098 275.035980,173.572296 
	C274.311920,172.863022 273.632477,172.472565 272.949310,172.069763 
	C272.945557,172.057434 272.917297,172.049332 272.843933,171.761536 
	C272.177399,170.962311 271.584229,170.450912 270.991028,169.939499 
	C270.991028,169.939499 270.983795,169.460907 270.989441,169.221436 
	C272.544128,169.450165 274.573914,169.452682 275.557678,170.468369 
	C279.505127,174.543808 283.878815,173.385071 288.769989,172.104813 
z"/>
<path fill="#012B87" opacity="1.000000" stroke="none" 
	d="
M263.771515,161.033707 
	C267.260956,163.111496 264.781677,164.806976 263.602997,166.388794 
	C263.442383,166.604324 261.151550,165.900360 260.737305,165.138214 
	C259.292572,162.480179 260.666260,161.212753 263.771515,161.033707 
z"/>
<path fill="#01399A" opacity="1.000000" stroke="none" 
	d="
M264.324921,182.998840 
	C263.990814,183.065552 263.500824,183.017044 263.255005,183.005463 
	C262.694061,181.892197 262.378906,180.790482 262.029297,179.011093 
	C261.989532,177.888412 261.984253,177.443405 261.978943,176.998398 
	C262.772949,175.376541 263.566956,173.754669 264.788177,172.382690 
	C265.280060,172.818848 265.344666,173.005096 265.127930,173.480469 
	C265.004730,176.769791 265.162811,179.770020 265.320892,182.770233 
	C265.100281,182.824219 264.879639,182.878189 264.324921,182.998840 
z"/>
<path fill="#02267D" opacity="1.000000" stroke="none" 
	d="
M255.053772,184.892105 
	C254.476456,183.828537 254.267792,182.754578 254.059143,181.680603 
	C254.802567,181.613678 255.545975,181.546768 256.289398,181.479843 
	C256.000427,182.613800 255.711426,183.747757 255.053772,184.892105 
z"/>
<path fill="#0E76D3" opacity="1.000000" stroke="none" 
	d="
M265.409241,173.191345 
	C265.344666,173.005096 265.280060,172.818848 265.140717,172.347198 
	C266.809204,171.398727 268.552429,170.735641 270.643311,170.006027 
	C271.584229,170.450912 272.177399,170.962311 272.448700,171.774002 
	C269.887604,172.446640 267.648438,172.818985 265.409241,173.191345 
z"/>
<path fill="#012B87" opacity="1.000000" stroke="none" 
	d="
M257.219177,175.199768 
	C257.769897,174.961105 258.259064,175.013428 258.748199,175.065750 
	C258.636414,175.502350 258.524628,175.938934 258.412872,176.375534 
	C257.994446,176.080612 257.575989,175.785675 257.219177,175.199768 
z"/>
<path fill="#02267D" opacity="1.000000" stroke="none" 
	d="
M240.219147,191.199844 
	C240.769897,190.961166 241.259033,191.013474 241.748154,191.065796 
	C241.636368,191.502396 241.524582,191.939011 241.412796,192.375610 
	C240.994370,192.080688 240.575943,191.785751 240.219147,191.199844 
z"/>
<path fill="#012B87" opacity="1.000000" stroke="none" 
	d="
M261.687439,177.041260 
	C261.984253,177.443405 261.989532,177.888412 261.988892,178.667145 
	C261.476440,178.682999 260.969940,178.365128 260.463409,178.047256 
	C260.774261,177.726196 261.085083,177.405151 261.687439,177.041260 
z"/>
<path fill="#02267D" opacity="1.000000" stroke="none" 
	d="
M165.361664,217.502930 
	C165.182037,216.621902 165.289246,215.886383 165.396469,215.150864 
	C166.094696,215.408020 166.792938,215.665161 167.491180,215.922333 
	C166.876953,216.497696 166.262726,217.073059 165.361664,217.502930 
z"/>
<path fill="#033494" opacity="1.000000" stroke="none" 
	d="
M377.002441,229.998169 
	C376.090881,228.857468 375.179321,227.716766 374.127808,226.294769 
	C375.560577,223.568130 374.468781,222.554550 372.227875,221.048584 
	C367.756805,218.043884 363.745544,214.354950 358.549225,210.141693 
	C363.726440,208.877274 366.848541,207.801285 369.622620,211.819077 
	C372.471191,215.944733 376.044098,219.321030 381.654144,215.426590 
	C382.249878,215.013062 383.668610,215.785095 384.705536,216.007126 
	C384.367096,217.518448 384.495514,219.938858 383.613556,220.391769 
	C378.364899,223.087112 379.864258,225.089569 383.723358,227.593246 
	C384.749878,228.259232 385.013885,230.100540 385.627991,231.402222 
	C384.340027,231.934326 383.052063,232.466415 381.380829,233.000488 
	C380.997559,233.002441 381.000000,233.000000 380.956726,232.646011 
	C379.609772,231.527405 378.306091,230.762787 377.002441,229.998169 
z"/>
<path fill="#013FA8" opacity="1.000000" stroke="none" 
	d="
M428.774780,233.740540 
	C427.908691,234.331635 426.816162,234.664505 425.357605,234.995636 
	C426.177185,234.490021 427.362762,233.986176 428.774780,233.740540 
z"/>
<path fill="#001C8F" opacity="1.000000" stroke="none" 
	d="
M458.541107,188.003357 
	C460.424622,190.657135 461.854767,193.306076 463.688049,196.701721 
	C462.152100,198.269012 460.215363,200.421158 458.092346,202.370422 
	C454.923065,205.280228 451.747803,204.170212 448.657288,202.091293 
	C445.554688,200.004272 445.744324,197.289124 447.048584,194.261459 
	C447.810486,192.492874 448.360962,190.633224 449.010956,188.422897 
	C452.039459,188.020340 455.063599,188.009430 458.541107,188.003357 
z"/>
<path fill="#0560DC" opacity="1.000000" stroke="none" 
	d="
M440.981354,220.939758 
	C440.564484,220.933334 440.147614,220.926910 439.354340,220.589081 
	C437.689453,219.124435 436.400970,217.991180 435.112488,216.857910 
	C435.112488,216.857910 435.096680,216.416656 435.074738,216.196777 
	C434.394531,213.939560 433.736267,211.902237 433.106842,209.482025 
	C433.603668,208.830948 434.071686,208.562775 435.108521,208.320190 
	C440.113037,208.241928 444.548737,208.138077 448.984406,208.034210 
	C449.739441,208.371582 450.494476,208.708969 451.599884,209.443954 
	C451.272308,212.210434 450.594391,214.579330 449.916443,216.948227 
	C449.155792,217.604462 448.395172,218.260696 446.964355,219.001587 
	C444.523254,219.704071 442.752289,220.321915 440.981354,220.939758 
M445.052185,215.304642 
	C444.725128,213.447510 444.543884,211.538040 443.916168,209.788818 
	C443.820343,209.521774 441.053070,209.565887 440.587189,210.222412 
	C439.774109,211.368134 439.097931,214.043213 439.592163,214.406372 
	C440.890106,215.360046 442.849091,215.414062 445.052185,215.304642 
z"/>
<path fill="#053FC9" opacity="1.000000" stroke="none" 
	d="
M448.990753,207.692886 
	C444.548737,208.138077 440.113037,208.241928 435.359375,208.218887 
	C437.047241,203.360428 441.191833,203.906845 445.586212,203.984589 
	C446.975067,204.420105 447.928619,204.866440 448.947418,205.649750 
	C449.007477,206.441650 449.002289,206.896606 448.990753,207.692886 
z"/>
<path fill="#0337B4" opacity="1.000000" stroke="none" 
	d="
M420.827148,209.761841 
	C420.354034,209.867752 419.708069,209.732346 419.023651,209.311340 
	C419.541565,209.190674 420.097961,209.355591 420.827148,209.761841 
z"/>
<path fill="#00359C" opacity="1.000000" stroke="none" 
	d="
M346.931030,174.623169 
	C345.186096,174.838226 343.360779,174.694733 341.267731,174.274582 
	C342.950195,174.086823 344.900421,174.175720 346.931030,174.623169 
z"/>
<path fill="#01308F" opacity="1.000000" stroke="none" 
	d="
M312.992493,174.708511 
	C311.823975,174.827133 310.647949,174.691147 309.228821,174.295959 
	C310.170013,173.841782 311.354309,173.646790 312.757080,173.698181 
	C312.978638,174.114349 312.981812,174.284119 312.992493,174.708511 
z"/>
<path fill="#01399A" opacity="1.000000" stroke="none" 
	d="
M292.038086,172.534912 
	C291.480774,172.768219 290.914520,172.647644 290.170837,172.290482 
	C290.167023,171.196884 290.340637,170.339874 290.514221,169.482864 
	C290.770172,169.460953 291.026123,169.439041 291.282074,169.417145 
	C291.531097,170.338440 291.780121,171.259735 292.038086,172.534912 
z"/>
<path fill="#032CA7" opacity="1.000000" stroke="none" 
	d="
M288.533569,227.006439 
	C294.811035,227.158920 300.622101,227.315399 306.716309,227.785492 
	C304.276398,228.396515 301.553467,228.943542 298.830231,228.944901 
	C290.428558,228.949097 282.026764,228.745010 273.312744,228.352234 
	C278.022675,227.725220 283.044891,227.367828 288.533569,227.006439 
z"/>
<path fill="#032CA7" opacity="1.000000" stroke="none" 
	d="
M246.641693,233.031158 
	C245.223068,234.972031 243.462494,236.950592 241.361359,238.972137 
	C242.780441,237.033035 244.540085,235.050949 246.641693,233.031158 
z"/>
<path fill="#032CA7" opacity="1.000000" stroke="none" 
	d="
M251.727875,228.098663 
	C250.612854,229.706284 249.146790,231.315460 247.337158,232.954163 
	C248.454681,231.355896 249.915771,229.728058 251.727875,228.098663 
z"/>
<path fill="#02277E" opacity="1.000000" stroke="none" 
	d="
M257.992828,227.651733 
	C257.251343,228.029724 256.499756,228.039017 255.372467,228.034805 
	C255.378357,227.105484 255.759918,226.189651 256.141479,225.273834 
	C256.755219,225.943573 257.368958,226.613312 257.992828,227.651733 
z"/>
<path fill="#012986" opacity="1.000000" stroke="none" 
	d="
M313.955414,244.015015 
	C296.303741,244.006912 278.652069,244.007690 261.000397,243.984497 
	C256.583130,243.978699 252.166000,243.895767 247.357559,243.507751 
	C246.972672,242.440872 246.979004,241.714508 246.985336,240.988144 
	C246.985336,240.988144 247.000000,241.000000 247.337631,240.962997 
	C248.783264,239.619125 249.891281,238.312241 250.999283,237.005371 
	C251.799683,236.405502 252.600082,235.805649 254.147980,235.090637 
	C268.178375,234.985321 281.461914,235.071945 294.743927,234.968994 
	C300.430298,234.924927 306.125519,234.374344 309.814117,240.128464 
	C310.154724,240.659851 311.287781,240.683258 312.052887,240.942535 
	C312.579315,241.153381 313.105774,241.364227 313.803162,242.140335 
	C313.967865,243.142075 313.961639,243.578537 313.955414,244.015015 
z"/>
<path fill="#0639BE" opacity="1.000000" stroke="none" 
	d="
M321.039612,251.927063 
	C321.022095,250.889740 321.004578,249.852432 320.964111,248.077225 
	C320.621857,246.235474 320.302521,245.131607 319.983185,244.027756 
	C320.254944,243.818161 320.526672,243.608566 320.925079,243.169983 
	C321.850067,243.212234 322.648438,243.483444 323.772583,244.222458 
	C326.044617,246.164566 327.990845,247.638885 330.012817,249.435272 
	C334.514648,254.133606 338.932007,258.518829 343.369171,262.883911 
	C349.148499,268.569366 353.926178,276.233734 364.744415,272.388641 
	C363.749847,274.521912 363.278534,275.532898 362.611145,276.770508 
	C360.617462,277.829803 358.819885,278.662384 356.556946,279.387024 
	C351.714111,279.641510 348.691193,277.503387 345.798645,274.404358 
	C340.496399,268.723633 334.946350,263.259674 329.276886,257.942871 
	C326.815369,255.634491 323.802216,253.914368 321.039612,251.927063 
z"/>
<path fill="#032CA7" opacity="1.000000" stroke="none" 
	d="
M312.001160,240.602310 
	C311.287781,240.683258 310.154724,240.659851 309.814117,240.128464 
	C306.125519,234.374344 300.430298,234.924927 294.743927,234.968994 
	C281.461914,235.071945 268.178375,234.985321 254.421722,234.952911 
	C255.263382,234.291611 256.573914,233.109451 257.894958,233.097595 
	C272.446960,232.966904 287.000702,233.035934 301.553864,233.010849 
	C306.748077,233.001892 309.460266,236.447083 312.001160,240.602310 
z"/>
<path fill="#032CA7" opacity="1.000000" stroke="none" 
	d="
M319.619720,244.015961 
	C320.302521,245.131607 320.621857,246.235474 320.955444,247.688278 
	C320.237183,248.025162 319.504700,248.013092 318.406616,248.006317 
	C316.808105,246.849304 315.575165,245.687012 314.148804,244.269867 
	C313.961639,243.578537 313.967865,243.142075 313.966309,242.379272 
	C315.724426,242.703354 317.490356,243.353760 319.619720,244.015961 
z"/>
<path fill="#032CA7" opacity="1.000000" stroke="none" 
	d="
M309.012329,229.381256 
	C310.544098,230.163116 312.120850,231.283768 313.850342,232.698761 
	C314.000458,233.164856 313.997833,233.336609 313.986755,233.766174 
	C312.337982,232.589325 310.697632,231.154678 309.012329,229.381256 
z"/>
<path fill="#032CA7" opacity="1.000000" stroke="none" 
	d="
M246.650513,241.044067 
	C246.979004,241.714508 246.972672,242.440872 246.948303,243.526764 
	C245.229309,244.272034 243.528320,244.657791 241.453094,245.069519 
	C242.824493,243.763672 244.570099,242.431839 246.650513,241.044067 
z"/>
<path fill="#032CA7" opacity="1.000000" stroke="none" 
	d="
M250.656921,237.037094 
	C249.891281,238.312241 248.783264,239.619125 247.331482,240.955933 
	C248.096649,239.680176 249.205612,238.374496 250.656921,237.037094 
z"/>
<path fill="#011480" opacity="1.000000" stroke="none" 
	d="
M400.542053,243.982254 
	C406.361877,243.975342 411.712006,243.983963 417.905426,244.000214 
	C419.500702,244.001175 420.252716,243.994522 421.004730,243.987854 
	C421.004730,243.987854 420.997253,244.005188 420.975586,244.421570 
	C419.239258,245.557495 417.557983,246.375336 415.801575,246.971832 
	C413.559509,247.733261 411.256714,248.315994 408.513672,248.980804 
	C390.683533,248.994125 373.319794,249.002350 355.968262,248.641144 
	C356.335846,246.852386 356.691193,245.433060 357.046570,244.013733 
	C371.388519,244.008423 385.730469,244.003098 400.542053,243.982254 
z"/>
<path fill="#011480" opacity="1.000000" stroke="none" 
	d="
M329.937103,249.113190 
	C327.990845,247.638885 326.044617,246.164566 324.070190,244.340820 
	C332.722565,244.002502 341.403107,244.013626 350.563721,244.374908 
	C351.701782,246.152756 352.359802,247.580444 352.547211,249.002258 
	C344.696777,249.035339 337.316925,249.074265 329.937103,249.113190 
z"/>
<path fill="#0430AF" opacity="1.000000" stroke="none" 
	d="
M353.017822,249.008118 
	C352.359802,247.580444 351.701782,246.152756 350.987976,244.387985 
	C352.439148,239.931152 354.419464,239.547394 356.984680,243.652832 
	C356.691193,245.433060 356.335846,246.852386 355.601746,248.633789 
	C354.487976,248.999939 353.752899,249.004028 353.017822,249.008118 
z"/>
<path fill="#01259D" opacity="1.000000" stroke="none" 
	d="
M489.004059,243.971985 
	C494.690216,243.980026 500.376404,243.988052 506.959076,243.998215 
	C509.570709,243.998901 511.285858,243.997452 513.000977,243.996017 
	C513.000977,243.996017 513.000000,244.000000 512.988647,244.373596 
	C510.315765,246.173187 507.654236,247.599182 504.524780,249.019287 
	C491.042419,249.017242 478.027924,249.021088 464.558014,249.012939 
	C461.067474,248.999344 458.032318,248.997742 455.039490,248.626923 
	C455.944366,246.153427 455.707550,244.983032 452.966766,244.939896 
	C450.307495,244.898010 447.656403,244.336639 445.001740,244.003403 
	C445.109070,243.684692 445.297882,243.425323 445.783508,243.111115 
	C451.020569,242.999847 456.042358,243.002747 461.763489,243.272919 
	C464.335999,243.990875 466.209137,244.441559 468.387543,244.893677 
	C469.099823,244.892807 469.506836,244.890518 470.354950,244.925690 
	C476.865387,244.632767 482.934723,244.302368 489.004059,243.971985 
z"/>
<path fill="#001C8F" opacity="1.000000" stroke="none" 
	d="
M444.569824,244.002899 
	C447.656403,244.336639 450.307495,244.898010 452.966766,244.939896 
	C455.707550,244.983032 455.944366,246.153427 455.042603,248.633820 
	C455.003357,249.009949 455.005585,249.024658 454.536499,249.014008 
	C443.380249,249.013321 432.693024,249.023285 421.542297,249.024292 
	C417.045868,249.002121 413.013000,248.988922 408.980103,248.975723 
	C411.256714,248.315994 413.559509,247.733261 415.801575,246.971832 
	C417.557983,246.375336 419.239258,245.557495 420.970398,244.429535 
	C428.703918,244.014862 436.420929,244.008621 444.569824,244.002899 
z"/>
<path fill="#00359C" opacity="1.000000" stroke="none" 
	d="
M377.053650,230.334015 
	C378.306091,230.762787 379.609772,231.527405 380.955505,232.647217 
	C379.699982,232.224915 378.402435,231.447403 377.053650,230.334015 
z"/>
<path fill="#013193" opacity="1.000000" stroke="none" 
	d="
M420.951050,243.658707 
	C420.252716,243.994522 419.500702,244.001175 418.372833,243.995941 
	C418.963776,243.765869 419.930603,243.547714 420.951050,243.658707 
z"/>
<path fill="#F7FBFC" opacity="1.000000" stroke="none" 
	d="
M564.574036,225.308289 
	C565.232910,224.234131 565.925903,223.447601 566.919800,222.119141 
	C575.775513,211.703568 586.637939,204.988403 598.063293,199.043701 
	C599.581055,198.566605 601.098877,198.089493 603.119080,197.336914 
	C604.088806,196.695129 604.556091,196.328796 605.023376,195.962463 
	C605.788940,195.894577 606.554565,195.826691 607.864868,195.705276 
	C608.786682,195.633759 608.992554,195.436768 609.027161,195.060760 
	C609.598999,194.969574 610.170776,194.878387 610.943726,194.571259 
	C622.120056,193.612457 633.095337,192.869598 644.534912,192.058075 
	C646.090637,192.317261 647.182007,192.645111 648.935364,193.081940 
	C650.061584,193.157516 650.525940,193.124100 650.990234,193.090668 
	C655.396729,194.376236 659.803284,195.661774 664.652710,197.225479 
	C665.095642,197.503632 665.477722,197.756470 665.477722,197.756470 
	C665.477722,197.756470 665.912598,197.612152 665.912598,197.612152 
	C666.372559,197.747803 666.832581,197.883453 667.652710,197.980087 
	C673.968445,201.183121 679.923950,204.425186 686.244019,207.747894 
	C687.173645,208.479736 687.738708,209.130905 688.561523,209.800079 
	C692.355530,213.056549 696.123535,216.079605 699.378479,219.579788 
	C705.539185,226.204803 706.405090,233.899384 702.659302,242.713852 
	C700.388123,245.462082 698.488831,247.670822 696.244141,249.936646 
	C690.824341,252.475708 685.557251,253.541336 679.614136,252.041992 
	C677.991821,251.315506 676.713013,250.708679 675.405762,249.988449 
	C675.377258,249.875046 675.181763,249.746735 675.077271,249.450760 
	C674.019409,248.398178 673.066101,247.641586 671.972839,246.583221 
	C669.621216,244.446106 667.409485,242.610748 665.047485,240.479553 
	C660.749023,238.014893 656.601013,235.846069 652.400757,233.635986 
	C652.348572,233.594727 652.217407,233.572067 651.984863,233.311859 
	C647.494080,232.235626 643.235779,231.419601 638.562805,230.476379 
	C636.417786,230.317871 634.687439,230.286575 632.717896,230.197647 
	C632.166382,230.293579 631.955322,230.535538 631.457397,230.886749 
	C623.835205,230.401825 617.056519,231.614700 610.836182,236.176682 
	C610.018921,236.567886 609.531433,236.962631 608.728271,237.346283 
	C607.390869,238.061798 606.369080,238.788422 605.209656,239.516937 
	C605.071960,239.518814 604.916382,239.746033 604.612549,239.774124 
	C604.069519,240.117996 603.830261,240.433777 603.319946,240.946960 
	C600.350342,243.272369 597.532288,245.266785 594.976318,247.554123 
	C587.989624,253.806580 579.197998,254.188919 571.358643,249.203995 
	C565.802368,245.670853 564.496887,239.925339 562.138611,234.626297 
	C562.138611,234.626297 562.259155,234.279022 562.229553,233.890686 
	C562.144775,233.344971 562.089600,233.187592 562.034424,233.030212 
	C562.661133,231.861130 563.287842,230.692047 564.090393,228.954285 
	C564.233643,228.002167 564.201050,227.618744 564.168518,227.235306 
	C564.315063,226.688843 564.461548,226.142380 564.574036,225.308289 
z"/>
<path fill="#0141BC" opacity="1.000000" stroke="none" 
	d="
M450.312744,216.925049 
	C450.594391,214.579330 451.272308,212.210434 451.928192,209.450958 
	C466.528625,209.034988 481.152283,209.088379 495.771881,208.869324 
	C497.331665,208.845963 498.786621,206.726868 500.431946,206.462738 
	C503.539246,205.963898 507.022705,205.319809 509.812073,206.286987 
	C511.417877,206.843765 512.613647,210.373993 512.849304,212.682602 
	C513.226990,216.382156 509.421417,219.145828 504.726318,217.588516 
	C490.235168,212.781967 475.432495,215.851074 460.801819,215.117386 
	C457.475739,214.950592 454.075287,216.267746 450.312744,216.925049 
z"/>
<path fill="#0430AF" opacity="1.000000" stroke="none" 
	d="
M504.992676,249.025177 
	C507.654236,247.599182 510.315765,246.173187 512.987427,244.373596 
	C513.440552,243.996658 513.883545,243.993317 515.009155,243.989243 
	C516.791321,243.660080 517.890747,243.331650 519.288574,243.026489 
	C520.059448,243.369705 520.531921,243.689651 520.950806,244.341995 
	C519.931519,246.121094 518.965759,247.567795 517.728882,249.215714 
	C514.724121,252.377762 511.974854,255.324310 509.260559,258.302734 
	C505.051971,262.920898 500.906372,267.597656 496.625702,272.147675 
	C496.051453,272.758087 494.846313,272.774994 493.593781,272.898804 
	C491.499176,272.484131 489.744110,272.237427 487.996155,271.625977 
	C488.144836,270.621277 488.075562,269.784851 488.460541,269.371857 
	C492.158447,265.405212 496.121338,261.669342 499.615845,257.535492 
	C501.761414,254.997375 503.223602,251.881546 504.992676,249.025177 
z"/>
<path fill="#0141BC" opacity="1.000000" stroke="none" 
	d="
M521.004395,244.009583 
	C520.531921,243.689651 520.059448,243.369705 519.339294,242.650406 
	C519.723694,239.500916 520.355774,236.750763 520.987793,234.000610 
	C521.041809,233.672668 520.979797,233.239014 521.167603,233.032806 
	C523.595642,230.366043 526.059265,227.731644 528.513916,225.089111 
	C531.671082,228.340958 535.052002,231.409866 537.909729,234.906448 
	C539.945740,237.397751 541.277893,240.464325 542.640991,243.804443 
	C539.568665,244.557709 536.779480,244.780731 533.990173,244.614594 
	C532.971191,242.550003 531.276001,239.330048 531.036621,239.434860 
	C528.230286,240.663757 525.642578,242.391861 522.665039,243.991669 
	C521.890076,243.999939 521.447205,244.004761 521.004395,244.009583 
z"/>
<path fill="#072C99" opacity="1.000000" stroke="none" 
	d="
M562.102966,235.059113 
	C564.496887,239.925339 565.802368,245.670853 571.358643,249.203995 
	C579.197998,254.188919 587.989624,253.806580 594.976318,247.554123 
	C597.532288,245.266785 600.350342,243.272369 603.669434,241.048065 
	C604.530334,240.983917 604.770569,241.016022 605.026855,241.458374 
	C606.267334,242.577682 607.456177,243.811111 608.723083,243.897202 
	C612.481689,244.152634 616.271118,243.956009 620.341125,244.097580 
	C622.061218,245.836929 623.488647,247.414795 624.452637,248.988281 
	C619.335205,248.990173 614.673340,249.170425 610.028992,248.961319 
	C603.343445,248.660278 597.266052,249.768570 591.062134,252.854660 
	C583.619446,256.556976 575.780457,255.748383 568.822388,250.404465 
	C567.430237,249.335281 565.628357,248.799500 563.963196,247.688965 
	C563.274780,246.239685 562.638611,245.119232 562.002441,243.998779 
	C562.024109,241.163162 562.045715,238.327560 562.102966,235.059113 
z"/>
<path fill="#064DCF" opacity="1.000000" stroke="none" 
	d="
M469.913849,244.888214 
	C469.506836,244.890518 469.099823,244.892807 468.314697,244.578705 
	C467.292297,243.171890 466.647980,242.081482 466.003662,240.991074 
	C465.686310,238.591888 465.368927,236.192703 465.393005,233.341248 
	C466.158081,231.592163 466.581757,230.295349 467.005371,228.998535 
	C467.005371,228.998520 467.021942,228.523849 467.360413,228.216049 
	C468.148651,226.943787 468.598419,225.979309 469.048157,225.014847 
	C474.238739,224.029282 478.289307,225.683990 479.617676,230.935654 
	C480.255157,233.455917 479.247284,236.392349 478.664673,239.712616 
	C475.534454,241.819336 472.724152,243.353775 469.913849,244.888214 
z"/>
<path fill="#01259D" opacity="1.000000" stroke="none" 
	d="
M561.533936,243.998154 
	C562.638611,245.119232 563.274780,246.239685 563.659058,247.975464 
	C548.271423,248.732040 533.135742,248.873276 518.000000,249.014496 
	C518.965759,247.567795 519.931519,246.121094 520.950806,244.341980 
	C521.447205,244.004761 521.890076,243.999939 523.105957,243.997406 
	C527.249451,244.334366 530.619812,244.669052 533.990234,245.003754 
	C536.779480,244.780731 539.568665,244.557709 542.680176,244.158234 
	C549.023438,243.987030 555.044373,243.992279 561.533936,243.998154 
z"/>
<path fill="#0137AC" opacity="1.000000" stroke="none" 
	d="
M470.354950,244.925690 
	C472.724152,243.353775 475.534454,241.819336 478.668640,240.140137 
	C480.864899,240.330994 482.792480,240.490921 484.592621,241.056519 
	C486.102203,241.530823 487.463074,242.478500 488.946899,243.594284 
	C482.934723,244.302368 476.865387,244.632767 470.354950,244.925690 
z"/>
<path fill="#0137AC" opacity="1.000000" stroke="none" 
	d="
M520.636230,234.063873 
	C520.355774,236.750763 519.723694,239.500916 519.040894,242.627136 
	C517.890747,243.331650 516.791321,243.660080 515.341370,243.987030 
	C516.755432,240.699432 518.520020,237.413284 520.636230,234.063873 
z"/>
<path fill="#0141BC" opacity="1.000000" stroke="none" 
	d="
M441.168640,221.215317 
	C442.752289,220.321915 444.523254,219.704071 446.661438,219.060516 
	C446.177246,222.254440 444.675995,223.973114 441.168640,221.215317 
z"/>
<path fill="#0137AC" opacity="1.000000" stroke="none" 
	d="
M465.700989,241.124863 
	C466.647980,242.081482 467.292297,243.171890 468.009460,244.577271 
	C466.209137,244.441559 464.335999,243.990875 462.230225,243.273209 
	C463.131165,242.423706 464.264740,241.841171 465.700989,241.124863 
z"/>
<path fill="#0137AC" opacity="1.000000" stroke="none" 
	d="
M512.921814,243.632538 
	C511.285858,243.997452 509.570709,243.998901 507.426880,243.995911 
	C508.565521,240.669724 510.655182,241.573639 512.921814,243.632538 
z"/>
<path fill="#0141BC" opacity="1.000000" stroke="none" 
	d="
M435.109589,217.233917 
	C436.400970,217.991180 437.689453,219.124435 439.041626,220.589417 
	C437.772430,219.817398 436.439545,218.713654 435.109589,217.233917 
z"/>
<path fill="#0137AC" opacity="1.000000" stroke="none" 
	d="
M466.646881,229.069824 
	C466.581757,230.295349 466.158081,231.592163 465.382019,232.976517 
	C465.449158,231.756393 465.868744,230.448746 466.646881,229.069824 
z"/>
<path fill="#0137AC" opacity="1.000000" stroke="none" 
	d="
M468.750427,225.150848 
	C468.598419,225.979309 468.148651,226.943787 467.359741,227.978577 
	C467.497955,227.128220 467.975311,226.207535 468.750427,225.150848 
z"/>
<path fill="#0E76D3" opacity="1.000000" stroke="none" 
	d="
M563.935303,227.286926 
	C564.201050,227.618744 564.233643,228.002167 564.073242,228.611633 
	C563.820801,228.337952 563.761414,227.838242 563.935303,227.286926 
z"/>
<path fill="#072C99" opacity="1.000000" stroke="none" 
	d="
M561.871826,233.114624 
	C562.089600,233.187592 562.144775,233.344971 562.123901,233.740280 
	C561.934937,233.718475 561.822083,233.458740 561.871826,233.114624 
z"/>
<path fill="#022590" opacity="1.000000" stroke="none" 
	d="
M696.589478,249.879547 
	C698.488831,247.670822 700.388123,245.462082 702.579407,243.094818 
	C713.240234,246.764542 716.388977,237.227585 721.943237,232.417343 
	C726.516052,228.457123 730.623474,223.938858 734.766724,219.508469 
	C740.239136,213.656952 745.545044,207.649704 751.199097,201.197388 
	C751.693481,200.169037 751.911316,199.652695 752.129150,199.136337 
	C755.769409,199.117981 759.409790,199.099640 763.468262,199.448410 
	C760.692993,205.735931 754.791504,208.943665 750.252075,213.513016 
	C744.979126,218.820633 740.063232,224.482956 734.714355,230.126373 
	C733.621826,231.171524 732.807251,232.081680 731.645020,232.999130 
	C730.197449,234.002670 729.097595,234.998901 727.733276,236.118637 
	C726.978333,236.827774 726.487915,237.413406 725.718445,238.132065 
	C724.625305,239.175446 723.811279,240.085831 722.653503,241.055695 
	C718.798035,243.740875 715.632385,247.451904 711.684814,248.710922 
	C707.004822,250.203506 701.651184,249.583786 696.589478,249.879547 
z"/>
<path fill="#0241B4" opacity="1.000000" stroke="none" 
	d="
M751.781738,199.083511 
	C751.911316,199.652695 751.693481,200.169037 751.240479,200.848251 
	C750.688965,200.532898 750.372559,200.054657 750.046021,199.275208 
	C750.502075,198.992874 750.968262,199.011780 751.781738,199.083511 
z"/>
<path fill="#02228D" opacity="1.000000" stroke="none" 
	d="
M767.174133,194.414169 
	C766.695679,194.314728 766.217163,194.215271 765.372681,194.090576 
	C764.605347,190.869644 765.910645,188.787048 768.289917,186.451401 
	C772.877991,181.947357 776.721069,176.684326 780.937134,171.395599 
	C782.358032,171.055374 783.714844,171.055511 785.548706,171.406281 
	C787.021362,172.491409 788.016968,173.225922 789.009766,173.976685 
	C789.006958,173.992935 789.039856,173.990082 789.021606,174.365494 
	C789.010681,175.492050 789.018066,176.243195 788.655029,177.000671 
	C787.644531,177.154312 786.877441,177.123596 786.383728,177.475952 
	C780.226074,181.870636 776.699219,189.337097 769.211914,192.221756 
	C768.977600,192.312027 769.032104,193.151840 768.655640,193.770767 
	C767.964233,194.070923 767.569153,194.242554 767.174133,194.414169 
z"/>
<path fill="#0235B0" opacity="1.000000" stroke="none" 
	d="
M761.975159,194.602448 
	C760.859924,194.794739 759.666870,194.656723 758.234436,194.248276 
	C759.295837,194.075928 760.596558,194.174042 761.975159,194.602448 
z"/>
<path fill="#0341AA" opacity="1.000000" stroke="none" 
	d="
M668.680298,174.121063 
	C669.883606,177.294525 669.677307,179.477142 666.165405,181.997833 
	C663.471313,183.931442 662.851624,188.755112 661.156860,192.639008 
	C658.246887,192.643204 655.489502,192.301849 652.301392,191.920685 
	C657.374390,186.012344 662.877991,180.143829 668.680298,174.121063 
z"/>
<path fill="#0341AA" opacity="1.000000" stroke="none" 
	d="
M650.994507,192.817169 
	C650.525940,193.124100 650.061584,193.157516 649.283569,193.021942 
	C649.450073,192.573059 649.930359,192.293137 650.697998,192.005005 
	C650.989807,192.179077 650.994324,192.361389 650.994507,192.817169 
z"/>
<path fill="#073FA4" opacity="1.000000" stroke="none" 
	d="
M665.793823,197.461624 
	C665.912598,197.612152 665.477722,197.756470 665.477722,197.756470 
	C665.477722,197.756470 665.095642,197.503632 665.028931,197.284866 
	C665.229675,197.060898 665.467285,197.142563 665.793823,197.461624 
z"/>
<path fill="#0442AB" opacity="1.000000" stroke="none" 
	d="
M605.024292,195.640015 
	C604.556091,196.328796 604.088806,196.695129 603.310120,197.056274 
	C603.000000,196.290146 603.001282,195.529205 603.002563,194.388107 
	C603.676819,194.444489 604.350952,194.881027 605.024292,195.640015 
z"/>
<path fill="#0442AB" opacity="1.000000" stroke="none" 
	d="
M608.997437,194.999985 
	C608.992554,195.436768 608.786682,195.633759 608.211182,195.529449 
	C608.012756,195.407150 608.069153,195.184097 608.295288,195.069122 
	C608.521423,194.954147 608.850342,194.871964 608.850342,194.871964 
	C608.850342,194.871964 608.967712,194.939209 608.997437,194.999985 
z"/>
<path fill="#0641A6" opacity="1.000000" stroke="none" 
	d="
M608.791870,194.729340 
	C608.850342,194.871964 608.521423,194.954147 608.354492,194.970490 
	C608.187561,194.986847 608.332581,194.577286 608.332581,194.577286 
	C608.332581,194.577286 608.733398,194.586700 608.791870,194.729340 
z"/>
<path fill="#0842A7" opacity="1.000000" stroke="none" 
	d="
M597.800903,198.766678 
	C586.637939,204.988403 575.775513,211.703568 566.949829,221.805588 
	C559.883057,215.246475 553.087097,208.459000 546.129761,201.388260 
	C550.800476,198.267883 555.976929,198.976257 561.174744,199.536926 
	C565.076782,199.957825 569.324036,201.404755 571.995789,196.396362 
	C580.111023,201.075775 588.937988,198.618301 597.800903,198.766678 
z"/>
<path fill="#0442AB" opacity="1.000000" stroke="none" 
	d="
M567.007812,192.603210 
	C564.106689,192.944305 561.155334,192.944305 557.070984,192.944305 
	C558.860535,190.677505 560.168152,189.021225 561.748901,187.170410 
	C563.667297,188.737946 565.312439,190.500015 567.007812,192.603210 
z"/>
<path fill="#0449D6" opacity="1.000000" stroke="none" 
	d="
M789.734680,171.993484 
	C791.034607,171.997604 792.105896,172.007278 793.577271,172.014832 
	C793.977417,172.012711 793.997559,172.007584 794.025330,172.347794 
	C795.375305,174.121445 796.697510,175.554886 798.352539,177.035019 
	C799.467224,178.043198 800.249084,179.004684 801.055908,180.311676 
	C802.077942,181.412842 803.074951,182.168503 804.316895,183.041306 
	C805.040161,183.410141 805.518555,183.661819 806.167053,184.187927 
	C807.964539,187.796890 810.969604,185.045456 813.129944,186.413788 
	C813.098938,188.201172 813.073486,189.575974 813.070923,191.273529 
	C813.712341,192.074997 814.330872,192.553741 815.078369,193.293076 
	C815.790710,194.041306 816.374146,194.528946 816.957520,195.016571 
	C818.372131,195.678360 819.786804,196.340164 821.579834,197.399292 
	C820.900330,201.640533 818.226929,199.935364 816.112854,199.315613 
	C814.636169,198.882690 813.348389,197.805206 811.686401,196.860855 
	C809.922729,195.146545 808.448853,193.587021 806.948975,191.680847 
	C805.599121,190.237518 804.275208,189.140839 802.667114,187.884583 
	C801.271423,186.792664 800.159912,185.860321 798.992126,184.618515 
	C798.305481,183.861832 797.675110,183.414612 797.025146,182.734344 
	C797.005615,182.501312 796.972229,182.034805 796.948486,181.690155 
	C795.939636,180.571579 794.954529,179.797638 793.736816,179.005463 
	C793.504272,178.987228 793.039551,178.944931 792.995483,178.604614 
	C791.647583,176.839554 790.343750,175.414810 789.039856,173.990082 
	C789.039856,173.990082 789.006958,173.992935 789.015259,173.651245 
	C789.031921,172.874725 789.040161,172.439926 789.048462,172.005112 
	C789.048462,172.005112 789.505981,171.999039 789.734680,171.993484 
z"/>
<path fill="#033ABC" opacity="1.000000" stroke="none" 
	d="
M788.740356,171.873840 
	C789.040161,172.439926 789.031921,172.874725 789.018127,173.634995 
	C788.016968,173.225922 787.021362,172.491409 785.955078,171.430450 
	C786.733765,171.316864 787.583008,171.529724 788.740356,171.873840 
z"/>
<path fill="#003499" opacity="1.000000" stroke="none" 
	d="
M734.992310,229.991333 
	C740.063232,224.482956 744.979126,218.820633 750.252075,213.513016 
	C754.791504,208.943665 760.692993,205.735931 763.922668,199.487457 
	C764.746643,199.095139 765.534363,199.030930 767.127930,198.950317 
	C769.617920,198.983566 771.301941,199.033218 773.011353,199.432434 
	C775.682129,202.865814 778.327454,205.949631 781.025391,209.346771 
	C781.710083,210.110825 782.342285,210.561569 782.986023,211.007629 
	C782.997559,211.002960 782.987732,211.025620 783.034302,211.342224 
	C783.723816,212.101929 784.366760,212.545059 785.157288,213.279602 
	C786.874390,215.378860 788.443909,217.186691 789.643372,219.006012 
	C784.563660,221.744873 783.532654,225.738281 787.446594,227.483414 
	C794.322083,230.548965 793.085205,235.962204 792.731812,241.411240 
	C792.643250,242.775925 792.555725,244.389404 793.166565,245.506393 
	C795.451843,249.684967 794.007141,254.242981 789.967346,256.623169 
	C787.984558,257.791473 785.166260,260.173462 785.305176,261.783630 
	C785.632202,265.574310 784.541870,270.530304 789.453918,272.635834 
	C795.424072,275.194916 792.408081,280.350952 792.894897,284.397461 
	C793.298401,287.751251 790.673889,288.561890 787.829346,289.289520 
	C783.794373,290.321655 783.097595,292.939423 785.243225,296.468109 
	C787.363159,299.954498 789.753479,298.647247 792.449036,297.313751 
	C797.233948,294.946533 798.843323,295.803802 799.537903,300.992188 
	C795.921631,301.002350 792.769531,300.887329 789.629089,301.028473 
	C784.749146,301.247772 779.877014,301.641418 774.533691,301.968262 
	C768.393433,301.961945 762.721130,301.948486 756.986572,301.622986 
	C756.267639,300.897217 755.610901,300.483459 754.624146,300.014801 
	C753.190979,298.652924 752.087830,297.345947 750.953491,295.689819 
	C745.608643,288.457611 740.295166,281.574524 734.425415,273.970886 
	C737.482666,273.326660 739.484802,272.904755 741.362610,272.509064 
	C741.233154,269.767059 741.207397,267.290436 740.972290,264.833832 
	C740.771545,262.736420 741.063538,259.909241 739.849731,258.727081 
	C736.910217,255.864059 736.444580,252.534042 737.095886,249.121567 
	C738.219849,243.231537 733.400574,237.591721 736.582886,231.675354 
	C736.698120,231.461182 735.551025,230.568024 734.992310,229.991333 
z"/>
<path fill="#0B76EC" opacity="1.000000" stroke="none" 
	d="
M772.986023,199.082886 
	C771.301941,199.033218 769.617920,198.983566 767.518738,198.897400 
	C767.097351,197.617996 767.091003,196.375076 767.129395,194.773163 
	C767.569153,194.242554 767.964233,194.070923 769.038574,193.862946 
	C771.458008,194.887711 773.198120,195.948868 774.669617,197.126907 
	C773.929321,197.856827 773.457703,198.469849 772.986023,199.082886 
z"/>
<path fill="#033ABC" opacity="1.000000" stroke="none" 
	d="
M811.976257,197.015640 
	C813.348389,197.805206 814.636169,198.882690 816.112854,199.315613 
	C818.226929,199.935364 820.900330,201.640533 821.972534,197.407990 
	C828.073914,197.004944 834.167908,196.808563 840.245911,197.037811 
	C844.547241,197.200043 848.527527,197.091858 851.599731,193.032059 
	C853.896851,191.410324 855.825928,189.847733 858.220764,187.907867 
	C858.548218,189.316772 858.777588,190.303452 858.823425,191.913467 
	C857.418396,194.019119 856.196838,195.501450 854.653687,197.037231 
	C853.554382,198.057922 852.776733,199.025146 851.601196,200.007034 
	C847.942078,200.683792 844.707947,201.645538 841.413757,201.943161 
	C835.292725,202.496201 829.136597,202.661911 822.569092,202.985870 
	C820.438782,202.982788 818.734497,202.982727 816.963867,202.644653 
	C815.257141,200.542969 813.616699,198.779312 811.976257,197.015640 
z"/>
<path fill="#013498" opacity="1.000000" stroke="none" 
	d="
M805.996948,183.913498 
	C805.518555,183.661819 805.040161,183.410141 804.261475,182.711761 
	C802.984436,181.498764 802.007690,180.732452 801.030945,179.966156 
	C800.249084,179.004684 799.467224,178.043198 798.346436,176.661911 
	C798.003723,175.495438 797.999939,174.748749 797.996155,174.002075 
	C800.088867,175.510391 802.165527,177.041550 804.279358,178.519684 
	C805.941650,179.682037 807.649719,180.778931 809.657288,182.118286 
	C810.558350,180.302414 810.892151,178.908112 811.766663,178.096588 
	C812.462524,177.450867 813.875061,177.215393 814.857727,177.398224 
	C815.328247,177.485748 815.731873,178.941193 815.837219,179.816605 
	C816.430237,184.747269 816.923401,189.689957 817.204041,194.822556 
	C816.374146,194.528946 815.790710,194.041306 815.054932,192.972046 
	C814.284363,191.910553 813.666199,191.430679 813.047974,190.950806 
	C813.073486,189.575974 813.098938,188.201172 813.344055,186.167053 
	C811.653809,182.467972 810.907471,182.310730 805.996948,183.913498 
z"/>
<path fill="#033ABC" opacity="1.000000" stroke="none" 
	d="
M797.703979,173.855072 
	C797.999939,174.748749 798.003723,175.495438 798.013611,176.615219 
	C796.697510,175.554886 795.375305,174.121445 794.020874,172.354828 
	C795.129700,172.583786 796.270752,173.145935 797.703979,173.855072 
z"/>
<path fill="#022B9D" opacity="1.000000" stroke="none" 
	d="
M868.676941,175.038544 
	C868.533813,175.628098 868.074890,176.263504 867.293701,176.932190 
	C867.434753,176.338440 867.897949,175.711426 868.676941,175.038544 
z"/>
<path fill="#012881" opacity="1.000000" stroke="none" 
	d="
M930.974854,207.052673 
	C922.970398,209.894180 917.833008,216.142303 912.686584,222.396271 
	C911.563782,223.760727 910.279602,224.992416 908.726318,226.768433 
	C907.257263,228.174301 906.131714,229.096954 904.638855,230.053604 
	C902.838867,230.433136 901.406189,230.778656 899.578552,231.104156 
	C898.129700,231.092697 897.075745,231.101273 895.552368,231.071930 
	C882.727295,231.003510 870.371643,230.972992 858.015869,230.525131 
	C858.956055,229.076279 859.767029,227.360321 860.863098,227.153198 
	C863.274292,226.697571 865.851562,226.791489 868.320679,227.048935 
	C872.153137,227.448547 873.188904,225.833466 873.071838,222.184814 
	C872.831970,214.703308 873.192078,207.200745 872.881592,199.724442 
	C872.789856,197.515320 871.088379,195.416077 870.488708,193.169296 
	C870.110840,191.753433 869.899963,189.907669 870.497620,188.687561 
	C872.450562,184.701096 874.808716,180.913101 877.010986,177.048767 
	C885.689148,177.024277 894.367310,176.999786 903.511597,176.964996 
	C906.022583,176.965607 908.067383,176.976547 910.548767,176.990387 
	C914.109192,181.225220 917.148621,185.522842 920.378113,189.672607 
	C924.481201,194.944794 928.738464,200.097076 932.667175,205.676117 
	C931.928406,206.384995 931.451660,206.718842 930.974854,207.052673 
z"/>
<path fill="#012E8A" opacity="1.000000" stroke="none" 
	d="
M876.630493,177.035034 
	C874.808716,180.913101 872.450562,184.701096 870.497620,188.687561 
	C869.899963,189.907669 870.110840,191.753433 870.488708,193.169296 
	C871.088379,195.416077 872.789856,197.515320 872.881592,199.724442 
	C873.192078,207.200745 872.831970,214.703308 873.071838,222.184814 
	C873.188904,225.833466 872.153137,227.448547 868.320679,227.048935 
	C865.851562,226.791489 863.274292,226.697571 860.863098,227.153198 
	C859.767029,227.360321 858.956055,229.076279 857.545776,230.546112 
	C851.179932,230.989304 845.279846,230.873322 839.391235,231.081909 
	C837.871155,231.135742 836.387146,232.208786 834.510620,232.795181 
	C826.433838,232.213120 818.732971,231.649536 810.697266,230.991104 
	C809.903809,229.938293 809.445190,228.980316 809.136475,227.694641 
	C810.869507,226.797272 812.453552,225.766174 814.035583,225.769394 
	C816.239380,225.773895 818.424072,226.752213 820.649048,226.946304 
	C823.130615,227.162766 826.062561,227.320435 825.919006,223.573868 
	C825.804749,220.590179 826.291931,216.978485 821.408386,216.939362 
	C818.264038,216.914169 817.481750,214.848770 817.424194,211.924026 
	C817.396240,210.501907 816.286194,208.947937 815.308167,207.744461 
	C814.475281,206.719513 813.129944,206.111084 810.679688,204.374710 
	C813.822937,203.685715 815.426575,203.334198 817.030212,202.982681 
	C818.734497,202.982727 820.438782,202.982788 822.868530,203.278076 
	C831.347717,203.708771 839.110901,204.089417 846.848267,203.793961 
	C848.610962,203.726654 850.284424,201.321472 851.999023,199.992355 
	C852.776733,199.025146 853.554382,198.057922 854.912354,197.046295 
	C855.665039,197.005325 855.837402,197.008759 856.007690,197.398163 
	C855.785767,202.342728 859.283875,202.646271 862.601257,203.779816 
	C863.260315,199.647308 860.403442,198.479187 858.154846,196.690750 
	C858.878174,194.935898 859.446655,193.488113 860.015137,192.040344 
	C864.382812,187.359848 868.702271,182.632401 873.169983,178.049423 
	C873.841553,177.360565 875.206055,177.347183 876.630493,177.035034 
M864.985657,194.472946 
	C864.782654,193.460037 864.579712,192.447113 864.376770,191.434189 
	C863.843872,191.420258 863.311035,191.406326 862.778137,191.392395 
	C862.688904,193.017761 862.599670,194.643143 862.510437,196.268539 
	C863.318176,195.922607 864.125854,195.576691 864.985657,194.472946 
z"/>
<path fill="#013193" opacity="1.000000" stroke="none" 
	d="
M856.009766,197.012207 
	C855.837402,197.008759 855.665039,197.005325 855.234009,196.992844 
	C856.196838,195.501450 857.418396,194.019119 858.829651,192.278732 
	C859.019348,192.020676 859.517212,192.021988 859.766174,192.031158 
	C859.446655,193.488113 858.878174,194.935898 857.823059,196.692474 
	C856.894226,197.004913 856.451965,197.008560 856.009766,197.012207 
z"/>
<path fill="#0240A9" opacity="1.000000" stroke="none" 
	d="
M673.128784,352.412109 
	C672.964172,352.707855 672.799500,353.003632 672.286804,352.944641 
	C670.511597,352.065796 669.085938,351.089417 667.657043,351.084534 
	C639.747925,350.988770 611.838318,350.979797 583.929321,351.081726 
	C582.598450,351.086578 581.271912,352.277161 579.611572,352.936768 
	C578.511963,353.300323 577.744080,353.642670 576.640869,354.028137 
	C575.536133,355.044464 574.766846,356.017609 573.722046,357.131104 
	C572.626526,358.179016 571.806458,359.086578 570.629150,360.034027 
	C569.180481,361.379547 568.088989,362.685150 566.794434,364.259399 
	C564.389954,366.688660 562.188599,368.849274 559.993652,371.005005 
	C560.000000,371.000061 560.009399,370.987793 559.732178,370.992096 
	C559.270203,370.998260 559.085388,371.000092 558.544434,371.000977 
	C553.197937,367.261169 548.208679,364.492401 541.347717,364.747101 
	C535.073547,364.980011 529.459167,366.313263 523.990723,368.585815 
	C526.217834,365.571655 528.209656,362.705597 530.632935,360.268555 
	C537.835693,353.024933 545.134644,345.872589 552.542480,338.838806 
	C556.869751,334.730042 561.477234,330.916260 566.334106,326.986816 
	C568.159912,327.320770 569.610291,327.637604 571.404419,328.113708 
	C573.494995,328.518341 575.241821,328.763702 577.060303,329.359680 
	C580.778076,332.249115 582.742249,330.514709 584.245605,326.993347 
	C584.483826,326.982544 584.960632,326.973206 585.333008,326.938965 
	C588.143494,325.614685 590.581665,324.324615 593.479126,323.024475 
	C597.624817,323.009186 601.311157,323.003998 605.460327,323.007080 
	C627.939087,323.333435 649.955139,323.651459 672.338989,324.022339 
	C680.053101,325.967743 684.537659,331.468597 688.638062,336.990417 
	C684.605042,334.244476 680.997070,331.413513 677.218384,328.832367 
	C676.284302,328.194305 674.796082,328.367554 673.562561,328.167877 
	C673.974792,329.407867 674.156555,330.801727 674.856567,331.849518 
	C675.765076,333.209473 677.085999,334.293945 678.687378,335.978882 
	C676.340698,336.751068 674.933838,337.213989 673.527039,337.676880 
	C675.135498,338.800537 676.701233,338.802917 678.190063,339.142578 
	C678.825745,339.287598 679.646118,340.076080 679.752075,340.682343 
	C679.858704,341.292328 679.311523,342.522461 678.828735,342.651825 
	C673.639099,344.042114 674.208923,348.695374 673.128784,352.412109 
M594.095337,337.865417 
	C595.660950,340.619537 593.860413,345.417389 599.505737,345.995911 
	C599.505737,345.995911 599.985596,346.013458 600.014465,346.775238 
	C601.504944,350.262390 603.186218,350.097687 605.813538,347.016388 
	C610.468445,347.096344 611.268494,344.142517 610.983704,340.390717 
	C610.717651,336.885437 609.022705,334.710693 605.398254,333.963196 
	C602.462769,333.357819 599.658691,332.018158 596.711853,331.601471 
	C594.932800,331.349945 592.032043,331.643616 591.344360,332.752350 
	C590.249939,334.516785 590.705200,337.088654 594.095337,337.865417 
z"/>
<path fill="#073FA4" opacity="1.000000" stroke="none" 
	d="
M654.027588,424.615021 
	C653.454895,424.995850 653.052307,424.998383 652.328125,424.971436 
	C652.006592,424.942017 651.971985,424.866089 651.978149,424.456390 
	C652.271545,420.628571 649.872803,420.985901 647.705261,420.988495 
	C637.470154,421.000793 627.235107,421.004608 616.657837,421.042725 
	C614.887024,421.718658 613.458435,422.363342 611.586426,423.005920 
	C604.927612,423.714630 598.781311,424.122620 593.624451,418.766968 
	C593.011780,414.423218 592.658142,410.375427 592.258179,405.797394 
	C594.848389,405.797394 596.477600,405.817200 598.106140,405.794220 
	C609.045410,405.639862 619.984558,405.376099 630.923584,405.381805 
	C633.904785,405.383362 635.570618,404.497192 636.236816,401.530548 
	C637.029968,397.998779 638.058228,394.519806 639.231873,391.208984 
	C639.793030,392.305634 640.106995,393.211243 640.216248,393.526337 
	C643.354248,392.147827 646.681091,389.309998 649.614990,389.670532 
	C656.759338,390.548462 656.681030,391.144806 658.381775,383.731903 
	C658.446106,383.451355 658.787659,383.234406 659.208008,382.985352 
	C659.415955,382.982849 659.831909,382.983002 659.831909,382.983002 
	C658.353882,394.272644 656.889099,405.564056 655.387329,416.850555 
	C655.058472,419.321625 654.597656,421.775116 654.027588,424.615021 
z"/>
<path fill="#0842A7" opacity="1.000000" stroke="none" 
	d="
M659.081238,365.264313 
	C658.905029,365.533600 658.728821,365.802917 658.172607,365.917908 
	C657.663940,365.997864 657.535339,366.232178 657.406738,366.466492 
	C657.406738,366.466492 657.265869,366.354980 656.962646,366.334290 
	C654.843567,367.974609 653.027649,369.635590 651.211792,371.296600 
	C651.211792,371.296600 651.180725,371.170319 650.860596,371.175903 
	C645.905151,375.752899 643.371216,376.016815 637.097046,372.828430 
	C636.553406,372.552155 635.540894,373.198608 634.433716,373.274353 
	C628.773010,367.191406 621.870972,364.898712 614.437317,366.968994 
	C607.743042,368.833405 601.492432,372.290680 594.974365,374.710571 
	C592.713623,374.580353 590.525696,374.947449 588.335632,374.960266 
	C580.530701,375.005951 572.725037,374.931152 564.976074,374.564880 
	C569.026062,369.821045 573.019714,365.414276 577.374939,360.962616 
	C580.823608,359.626251 583.910767,358.334778 587.435547,357.038025 
	C589.915466,357.037537 591.957703,357.042297 594.306396,357.326965 
	C607.173523,357.715790 619.734192,357.836639 632.295227,357.895081 
	C632.863159,357.897736 633.433716,357.335358 634.440125,357.032288 
	C636.916870,357.035919 638.956482,357.043427 641.301270,357.329285 
	C646.403259,357.388336 651.200195,357.169006 656.397217,356.953400 
	C657.864014,356.952179 658.930725,356.947235 660.179932,357.197571 
	C661.242065,357.279236 662.121826,357.105682 663.328064,356.934723 
	C664.089844,356.933044 664.525208,356.928833 665.188782,356.842682 
	C665.703125,356.580994 665.900024,356.330963 666.374756,355.993774 
	C667.475830,355.965637 668.209900,355.954407 669.011108,356.009552 
	C669.078186,356.075897 669.212219,356.208710 669.212219,356.208710 
	C666.855835,358.480865 664.499390,360.753021 661.438477,363.084686 
	C660.183105,363.850891 659.632141,364.557617 659.081238,365.264313 
z"/>
<path fill="#043BA1" opacity="1.000000" stroke="none" 
	d="
M673.475830,352.252777 
	C674.208923,348.695374 673.639099,344.042114 678.828735,342.651825 
	C679.311523,342.522461 679.858704,341.292328 679.752075,340.682343 
	C679.646118,340.076080 678.825745,339.287598 678.190063,339.142578 
	C676.701233,338.802917 675.135498,338.800537 673.527039,337.676880 
	C674.933838,337.213989 676.340698,336.751068 678.687378,335.978882 
	C677.085999,334.293945 675.765076,333.209473 674.856567,331.849518 
	C674.156555,330.801727 673.974792,329.407867 673.562561,328.167877 
	C674.796082,328.367554 676.284302,328.194305 677.218384,328.832367 
	C680.997070,331.413513 684.605042,334.244476 688.854126,337.103333 
	C689.702148,337.423615 689.892212,337.682495 690.046387,338.318054 
	C690.725281,339.090698 691.360229,339.545288 692.274353,340.138184 
	C693.369080,341.185822 694.184509,342.095123 695.035278,343.342529 
	C696.714539,345.452362 698.358459,347.224060 700.116211,349.210968 
	C700.430847,349.694427 700.689148,349.884644 701.040466,350.336426 
	C702.053528,351.447052 703.030945,352.218018 704.308472,353.141663 
	C710.075623,357.880005 715.542542,362.465637 720.944824,367.394836 
	C719.650269,369.251709 718.420532,370.765076 717.190735,372.278442 
	C717.190735,372.278442 717.147522,372.175507 716.802795,372.127380 
	C715.033447,372.783203 713.577209,373.430359 712.190796,374.202881 
	C709.218140,375.859314 704.031738,375.145386 704.200806,372.966522 
	C704.637390,367.342987 701.131348,367.868988 697.562317,367.980743 
	C694.752441,368.068726 691.937744,368.004486 689.078796,367.626587 
	C687.950317,361.991455 683.144775,358.859253 679.403015,360.969116 
	C679.747620,357.540344 680.092224,354.111603 680.590210,349.156921 
	C677.810181,350.363281 675.816528,351.228394 673.475830,352.252777 
z"/>
<path fill="#113598" opacity="1.000000" stroke="none" 
	d="
M703.431458,424.143097 
	C697.034546,432.527466 697.225708,434.907440 705.157959,444.577942 
	C698.261536,440.336426 690.957031,442.451172 683.251343,443.919556 
	C686.176453,441.200500 689.724182,438.721497 692.784119,435.741608 
	C693.962097,434.594452 694.030334,432.307831 694.971130,429.410370 
	C690.419678,431.090485 687.440857,432.485596 684.310242,433.290619 
	C674.709045,435.759460 668.906555,431.387115 669.981384,421.683411 
	C671.461243,408.323120 673.859375,395.064575 676.288025,381.657074 
	C676.808777,382.696289 676.905640,383.840820 677.000916,385.428894 
	C675.808777,393.316803 674.527649,400.748291 673.459473,408.210236 
	C672.755554,413.126984 672.358154,418.087646 671.880249,423.419983 
	C672.825928,424.504486 673.743225,425.831909 674.599487,425.793732 
	C684.215515,425.364777 693.822266,424.725677 703.431458,424.143097 
z"/>
<path fill="#084EC4" opacity="1.000000" stroke="none" 
	d="
M579.943359,352.915527 
	C581.271912,352.277161 582.598450,351.086578 583.929321,351.081726 
	C611.838318,350.979797 639.747925,350.988770 667.657043,351.084534 
	C669.085938,351.089417 670.511597,352.065796 672.036743,353.016235 
	C672.155334,353.763916 672.016846,353.985992 671.133667,354.082825 
	C670.044739,354.045349 669.541504,354.033936 668.735596,353.728363 
	C666.631042,353.288666 664.829285,353.019562 663.027039,353.016052 
	C635.332520,352.962158 607.637939,352.942871 579.943359,352.915527 
z"/>
<path fill="#032699" opacity="1.000000" stroke="none" 
	d="
M634.746155,373.416443 
	C635.540894,373.198608 636.553406,372.552155 637.097046,372.828430 
	C643.371216,376.016815 645.905151,375.752899 650.923279,371.193298 
	C651.139709,372.604309 650.973328,374.003510 650.975342,376.199646 
	C654.317566,377.321350 657.491455,377.646088 660.665283,377.970825 
	C660.448975,379.389526 660.232605,380.808258 659.924072,382.604980 
	C659.831909,382.983002 659.415955,382.982849 659.196655,382.601410 
	C658.273315,381.560242 657.557373,380.328583 656.867126,380.342773 
	C654.275024,380.396088 651.484985,380.344269 649.153320,381.284668 
	C645.762390,382.652252 640.931274,380.144318 638.702393,384.688843 
	C637.267822,380.709930 636.006958,377.063202 634.746155,373.416443 
z"/>
<path fill="#0B3189" opacity="1.000000" stroke="none" 
	d="
M689.125183,368.007843 
	C691.937744,368.004486 694.752441,368.068726 697.562317,367.980743 
	C701.131348,367.868988 704.637390,367.342987 704.200806,372.966522 
	C704.031738,375.145386 709.218140,375.859314 712.190796,374.202881 
	C713.577209,373.430359 715.033447,372.783203 716.856384,372.143005 
	C714.201843,376.907104 711.148987,381.607391 707.705200,386.315887 
	C706.498718,383.801514 708.127197,379.598785 702.796204,379.787109 
	C701.392517,379.019958 700.188965,378.508423 699.005005,377.732971 
	C699.050354,377.294556 699.076111,377.119995 699.101868,376.945435 
	C700.271240,374.623779 701.440613,372.302094 703.142517,368.923279 
	C697.855286,368.923279 693.862488,368.923279 689.451294,368.651123 
	C689.032837,368.378998 689.125183,368.007843 689.125183,368.007843 
z"/>
<path fill="#084EC4" opacity="1.000000" stroke="none" 
	d="
M689.078796,367.626587 
	C689.125183,368.007843 689.032837,368.378998 688.989990,368.565552 
	C687.819885,368.828369 686.693298,368.955505 685.565063,368.972443 
	C678.929077,369.072021 678.506653,368.600800 679.195068,361.371735 
	C683.144775,358.859253 687.950317,361.991455 689.078796,367.626587 
z"/>
<path fill="#021F84" opacity="1.000000" stroke="none" 
	d="
M677.002441,384.985382 
	C676.905640,383.840820 676.808777,382.696289 676.481323,381.297363 
	C675.539490,377.426392 677.212646,376.350464 680.628113,376.549438 
	C684.765503,376.790466 688.923279,376.680176 693.399353,377.160522 
	C693.476685,378.732758 693.226990,379.866394 692.512817,380.994904 
	C689.223206,380.990234 686.369324,381.243744 683.580322,380.926544 
	C680.148499,380.536224 677.998169,381.689087 677.002441,384.985382 
z"/>
<path fill="#0842A7" opacity="1.000000" stroke="none" 
	d="
M660.682495,377.591919 
	C657.491455,377.646088 654.317566,377.321350 651.039917,376.620544 
	C654.190552,376.567322 657.445129,376.890167 660.682495,377.591919 
z"/>
<path fill="#032699" opacity="1.000000" stroke="none" 
	d="
M651.537537,371.198761 
	C653.027649,369.635590 654.843567,367.974609 657.043579,366.295349 
	C655.572937,367.885040 653.718140,369.492981 651.537537,371.198761 
z"/>
<path fill="#0439BA" opacity="1.000000" stroke="none" 
	d="
M692.977234,381.000000 
	C693.226990,379.866394 693.476685,378.732758 693.855469,377.270935 
	C695.409729,376.867157 696.834961,376.791626 698.681030,376.830780 
	C699.076111,377.119995 699.050354,377.294556 698.619690,377.742615 
	C697.144043,379.009949 696.073242,380.003723 694.665039,381.000183 
	C693.877563,381.001953 693.427368,381.000977 692.977234,381.000000 
z"/>
<path fill="#02389F" opacity="1.000000" stroke="none" 
	d="
M678.325867,447.005798 
	C677.968262,447.015564 677.994690,446.570618 678.004089,446.347961 
	C678.714478,445.694702 679.415466,445.264099 680.801758,444.897156 
	C681.980530,444.976959 682.474060,444.993042 682.967529,445.009155 
	C681.539490,445.671448 680.111511,446.333740 678.325867,447.005798 
z"/>
<path fill="#032699" opacity="1.000000" stroke="none" 
	d="
M659.404175,365.166168 
	C659.632141,364.557617 660.183105,363.850891 661.119141,363.121643 
	C660.911865,363.755402 660.319519,364.411713 659.404175,365.166168 
z"/>
<path fill="#0842A7" opacity="1.000000" stroke="none" 
	d="
M669.038208,354.022522 
	C669.541504,354.033936 670.044739,354.045349 670.924866,354.083221 
	C670.800415,354.743225 670.299194,355.376801 669.505066,356.109558 
	C669.212219,356.208710 669.078186,356.075897 669.016724,355.689026 
	C668.982849,354.875610 669.010498,354.449066 669.038208,354.022522 
z"/>
<path fill="#113598" opacity="1.000000" stroke="none" 
	d="
M682.965698,444.711060 
	C682.474060,444.993042 681.980530,444.976959 681.118652,444.920349 
	C681.247559,444.520905 681.744812,444.162018 682.567322,443.812073 
	C682.916382,444.018341 682.940186,444.215668 682.965698,444.711060 
z"/>
<path fill="#032699" opacity="1.000000" stroke="none" 
	d="
M657.613037,366.448151 
	C657.535339,366.232178 657.663940,365.997864 657.918701,365.922974 
	C658.044861,366.082397 657.819336,366.429810 657.613037,366.448151 
z"/>
<path fill="#073CA0" opacity="1.000000" stroke="none" 
	d="
M692.512817,380.994904 
	C693.427368,381.000977 693.877563,381.001953 694.988525,381.058716 
	C697.094299,382.417542 698.539368,383.720612 699.992554,385.389282 
	C700.659119,388.377686 701.274109,391.066345 705.253296,389.041992 
	C705.685730,389.022125 705.858337,389.007996 706.311768,389.007874 
	C706.779785,389.030579 706.967041,389.039307 707.154236,389.048065 
	C705.726807,400.593506 712.381958,407.429443 721.134888,413.043671 
	C722.655518,414.019043 724.173645,414.998260 725.838318,416.821716 
	C726.139221,418.378540 726.294678,419.089386 726.450195,419.800201 
	C726.466980,419.354980 726.483826,418.909760 726.689697,418.840454 
	C725.640381,419.872467 724.446533,420.963470 723.154663,421.095459 
	C720.333130,421.383759 717.461914,421.184601 714.308472,421.184601 
	C714.615967,422.762238 714.825684,423.838409 714.604492,424.980621 
	C713.886841,425.094574 713.600098,425.142487 713.313416,425.190399 
	C712.416687,423.828033 711.519958,422.465668 710.256592,420.694336 
	C707.903809,420.870880 705.917603,421.456390 703.611023,421.855347 
	C701.634888,421.152527 699.987549,420.224548 698.322021,420.190796 
	C690.811523,420.038513 683.293579,420.249634 675.783691,420.084869 
	C673.418335,420.032928 671.874451,420.324524 671.823486,423.028625 
	C672.358154,418.087646 672.755554,413.126984 673.459473,408.210236 
	C674.527649,400.748291 675.808777,393.316803 677.000916,385.428894 
	C677.998169,381.689087 680.148499,380.536224 683.580322,380.926544 
	C686.369324,381.243744 689.223206,380.990234 692.512817,380.994904 
z"/>
<path fill="#043698" opacity="1.000000" stroke="none" 
	d="
M792.749390,369.101440 
	C789.398926,368.811371 786.044800,368.253082 782.698914,368.298401 
	C778.427673,368.356262 774.163086,368.901550 769.425903,368.730743 
	C768.092224,367.491119 767.287231,366.216705 766.354492,366.115082 
	C761.681091,365.606079 756.973450,365.209412 752.279663,365.228027 
	C751.072266,365.232788 749.871399,366.885040 748.667542,367.780426 
	C741.130859,365.749298 733.605103,365.392578 725.606323,367.459778 
	C725.074646,366.662567 725.034851,366.333099 725.237915,365.994568 
	C725.480774,365.985443 725.966736,365.980774 726.285706,365.850037 
	C727.266541,364.905060 728.334900,364.170746 728.513672,363.261505 
	C729.037354,360.597382 729.206970,357.863708 729.515320,355.157257 
	C730.002502,350.205536 729.465027,345.079468 730.227966,340.154846 
	C730.612854,337.670654 733.093994,335.259125 735.163574,333.417297 
	C735.944763,332.722015 738.314453,334.147675 739.827332,333.873749 
	C743.925354,333.131836 747.952209,331.997131 752.254761,331.012146 
	C752.502258,331.006104 752.997559,330.997559 753.213318,331.110687 
	C753.699402,331.423187 753.889709,331.681488 753.953003,332.378052 
	C753.435486,334.643585 752.965027,336.529785 752.688843,337.637085 
	C750.132080,338.164886 748.025391,338.599792 745.488281,339.034973 
	C741.882080,339.621582 738.278503,339.490662 735.678223,341.041748 
	C733.889587,342.108673 732.456360,345.617920 732.691223,347.842773 
	C733.167786,352.357330 736.829651,355.134491 741.084534,355.569977 
	C743.782837,355.846100 747.646851,355.571289 748.127502,350.918274 
	C748.176636,350.695862 748.282593,350.252808 748.569763,350.227173 
	C749.239929,350.173248 749.622864,350.144958 750.203125,350.340759 
	C751.267944,350.393677 752.135498,350.222504 753.470825,350.035339 
	C759.959778,350.030121 765.980957,350.040863 772.308716,350.330139 
	C779.409424,350.740570 786.203491,350.872498 792.998535,351.436646 
	C793.035461,358.634430 793.034973,358.559723 800.228943,358.510071 
	C801.309509,358.502594 802.414734,359.540527 803.478333,360.147797 
	C805.717346,361.426208 807.895569,362.817291 810.176453,364.014069 
	C811.970337,364.955322 813.880737,365.674530 815.366577,366.328369 
	C814.649414,367.676605 814.168457,368.580780 813.687500,369.484924 
	C812.243164,369.816284 810.798706,370.147644 808.760742,370.798523 
	C800.640259,377.265442 796.679443,376.747375 792.749390,369.101440 
z"/>
<path fill="#083797" opacity="1.000000" stroke="none" 
	d="
M772.908569,400.954865 
	C772.843140,402.373047 772.777771,403.791260 772.288818,405.594635 
	C771.542847,405.987823 771.221924,405.969116 770.916626,405.515228 
	C771.750916,399.617767 767.707214,399.305634 764.111145,399.068085 
	C756.489807,398.564545 748.845276,398.170074 741.214417,398.233124 
	C739.451965,398.247681 737.706177,400.282196 735.952759,401.387207 
	C731.764221,398.575012 727.283691,396.104065 723.476196,392.846497 
	C720.221436,390.061798 718.848328,386.126038 721.279419,381.685181 
	C722.993652,383.070892 724.307007,384.522125 725.620361,385.973358 
	C726.739746,384.324310 727.859131,382.675262 729.427856,381.015137 
	C738.846985,380.967377 738.795166,380.974579 740.084351,390.225647 
	C740.219238,391.193420 741.420410,392.012604 742.126831,392.900696 
	C742.126831,392.900696 742.029602,392.876312 742.106689,393.243835 
	C742.799377,394.061096 743.373901,394.823151 744.037170,394.910309 
	C748.649292,395.516479 755.073792,391.641296 756.850891,385.814728 
	C758.306824,381.041260 761.560547,380.741241 765.071350,380.550751 
	C771.996094,380.175049 773.696838,382.376617 773.075256,389.415985 
	C772.737793,393.237396 772.946228,397.106995 772.908569,400.954865 
z"/>
<path fill="#083797" opacity="1.000000" stroke="none" 
	d="
M771.974915,443.019775 
	C768.623047,443.008331 765.271118,442.996887 761.419067,442.636658 
	C759.219666,440.307068 757.793945,437.959167 755.752319,436.437775 
	C753.161926,434.507507 752.197266,432.495514 752.939697,429.343414 
	C753.306458,427.786285 752.949585,426.058716 752.920105,424.408264 
	C752.695190,422.954041 752.470215,421.499817 752.616821,419.501251 
	C751.927002,417.010315 750.865662,415.063690 749.804382,413.117065 
	C749.804382,413.117065 749.895874,413.086914 749.910828,412.751404 
	C748.936584,409.962158 747.947388,407.508392 747.345337,404.990356 
	C753.449829,404.944885 759.169250,404.885071 764.883423,405.030060 
	C766.606934,405.073853 768.316040,405.686310 770.027588,406.472656 
	C769.667664,411.617615 769.311890,416.328186 768.548584,420.967102 
	C764.381042,420.600067 760.620361,420.123657 756.861389,420.136658 
	C755.921387,420.139893 754.986755,421.718384 754.049683,422.572906 
	C755.031799,423.381775 755.972717,424.820404 757.003296,424.887756 
	C760.954895,425.146057 764.935364,424.964142 768.878113,425.356628 
	C767.181274,432.008636 767.886414,437.820496 771.974915,443.019775 
z"/>
<path fill="#163A84" opacity="1.000000" stroke="none" 
	d="
M772.998779,400.570862 
	C772.946228,397.106995 772.737793,393.237396 773.075256,389.415985 
	C773.696838,382.376617 771.996094,380.175049 765.071350,380.550751 
	C761.560547,380.741241 758.306824,381.041260 756.850891,385.814728 
	C755.073792,391.641296 748.649292,395.516479 744.037170,394.910309 
	C743.373901,394.823151 742.799377,394.061096 742.154480,393.228760 
	C747.811707,393.469604 751.643799,390.488525 754.226501,385.971558 
	C756.937195,381.230591 757.406555,376.264069 753.813965,371.338013 
	C754.854919,371.180725 755.953003,371.079407 756.382935,371.567108 
	C759.700073,375.330200 762.359863,375.052094 764.830261,370.655396 
	C765.237793,369.930023 766.662048,369.775970 767.616455,369.357849 
	C766.875916,371.607819 766.135376,373.857758 765.350220,376.243347 
	C767.278076,377.136597 768.746704,377.831299 770.226929,378.500366 
	C776.884766,381.509766 776.857239,381.501068 775.309753,388.792908 
	C774.506592,392.577545 773.825378,396.388031 772.998779,400.570862 
z"/>
<path fill="#0540BE" opacity="1.000000" stroke="none" 
	d="
M770.031738,406.038269 
	C768.316040,405.686310 766.606934,405.073853 764.883423,405.030060 
	C759.169250,404.885071 753.449829,404.944885 746.978027,404.975464 
	C745.487183,404.991150 744.750854,404.957520 743.764221,404.658844 
	C742.559631,404.377014 741.605347,404.360229 740.651001,404.343475 
	C740.651001,404.343475 740.869080,404.283691 740.849121,404.018372 
	C740.198975,403.575836 739.568726,403.398590 738.938477,403.221375 
	C738.048401,402.776398 737.158264,402.331421 736.110474,401.636841 
	C737.706177,400.282196 739.451965,398.247681 741.214417,398.233124 
	C748.845276,398.170074 756.489807,398.564545 764.111145,399.068085 
	C767.707214,399.305634 771.750916,399.617767 770.699341,405.543457 
	C770.467957,405.980225 770.031738,406.038269 770.031738,406.038269 
z"/>
<path fill="#0F3C91" opacity="1.000000" stroke="none" 
	d="
M738.002075,429.990601 
	C738.792969,434.095062 736.189758,436.122009 733.064758,437.534790 
	C728.431702,439.629425 723.585449,440.139374 719.482422,436.596252 
	C717.445190,434.837067 716.240540,432.113678 715.027466,429.550293 
	C722.931091,429.512939 730.466614,429.751770 738.002075,429.990601 
z"/>
<path fill="#053ACF" opacity="1.000000" stroke="none" 
	d="
M671.880249,423.419983 
	C671.874451,420.324524 673.418335,420.032928 675.783691,420.084869 
	C683.293579,420.249634 690.811523,420.038513 698.322021,420.190796 
	C699.987549,420.224548 701.634888,421.152527 703.620544,422.132141 
	C703.961426,422.965881 703.972412,423.336243 703.983398,423.706573 
	C703.983398,423.706573 703.821594,424.069733 703.626526,424.106415 
	C693.822266,424.725677 684.215515,425.364777 674.599487,425.793732 
	C673.743225,425.831909 672.825928,424.504486 671.880249,423.419983 
z"/>
<path fill="#032495" opacity="1.000000" stroke="none" 
	d="
M738.038696,429.606018 
	C730.466614,429.751770 722.931091,429.512939 715.005554,429.180298 
	C714.174133,428.021790 713.732849,426.957123 713.302490,425.541412 
	C713.600098,425.142487 713.886841,425.094574 714.840637,425.160522 
	C720.732849,425.148254 725.969727,425.167847 731.171143,424.748260 
	C732.009338,424.680603 732.689697,422.655975 733.442993,421.536011 
	C734.987061,424.097839 736.531128,426.659637 738.038696,429.606018 
z"/>
<path fill="#0C237E" opacity="1.000000" stroke="none" 
	d="
M752.651123,424.700684 
	C752.949585,426.058716 753.306458,427.786285 752.939697,429.343414 
	C752.197266,432.495514 753.161926,434.507507 755.752319,436.437775 
	C757.793945,437.959167 759.219666,440.307068 760.961914,442.627747 
	C758.610840,446.966309 756.192993,442.199799 753.336304,442.622314 
	C749.320312,438.923431 746.064392,439.842041 742.967712,442.971313 
	C742.408264,442.904602 741.848755,442.837891 740.902710,442.587830 
	C746.035034,437.666290 751.309326,432.761353 752.651123,424.700684 
z"/>
<path fill="#0C237E" opacity="1.000000" stroke="none" 
	d="
M742.100220,392.467468 
	C741.420410,392.012604 740.219238,391.193420 740.084351,390.225647 
	C738.795166,380.974579 738.846985,380.967377 729.097839,380.824402 
	C726.306885,380.167480 724.295288,379.690247 722.283691,379.212982 
	C724.214783,374.707672 728.037415,373.433838 732.431885,373.703705 
	C737.023682,373.985657 739.605469,377.060028 740.584961,381.230560 
	C741.412476,384.753998 741.601868,388.427277 742.100220,392.467468 
z"/>
<path fill="#0741A6" opacity="1.000000" stroke="none" 
	d="
M767.858215,369.401672 
	C766.662048,369.775970 765.237793,369.930023 764.830261,370.655396 
	C762.359863,375.052094 759.700073,375.330200 756.382935,371.567108 
	C755.953003,371.079407 754.854919,371.180725 753.699463,371.007141 
	C751.869385,370.114960 750.406128,369.224792 748.805176,368.057495 
	C749.871399,366.885040 751.072266,365.232788 752.279663,365.228027 
	C756.973450,365.209412 761.681091,365.606079 766.354492,366.115082 
	C767.287231,366.216705 768.092224,367.491119 768.972473,368.641144 
	C768.734009,369.281799 768.437744,369.411102 767.858215,369.401672 
z"/>
<path fill="#0639BE" opacity="1.000000" stroke="none" 
	d="
M733.415222,421.556366 
	C732.689697,422.655975 732.009338,424.680603 731.171143,424.748260 
	C725.969727,425.167847 720.732849,425.148254 715.271606,425.094482 
	C714.825684,423.838409 714.615967,422.762238 714.308472,421.184601 
	C717.461914,421.184601 720.333130,421.383759 723.154663,421.095459 
	C724.446533,420.963470 725.640381,419.872467 727.357544,418.815430 
	C727.836304,418.414429 728.197937,418.369263 728.127075,418.916199 
	C729.818970,420.185638 731.581787,420.908142 733.344543,421.630646 
	C733.344543,421.630646 733.387451,421.576721 733.415222,421.556366 
z"/>
<path fill="#0A4DBA" opacity="1.000000" stroke="none" 
	d="
M722.060181,379.291809 
	C724.295288,379.690247 726.306885,380.167480 728.648499,380.835480 
	C727.859131,382.675262 726.739746,384.324310 725.620361,385.973358 
	C724.307007,384.522125 722.993652,383.070892 721.462646,381.336975 
	C721.442261,380.493073 721.639465,379.931824 722.060181,379.291809 
z"/>
<path fill="#0C237E" opacity="1.000000" stroke="none" 
	d="
M744.014404,404.923889 
	C744.750854,404.957520 745.487183,404.991150 746.590881,405.039673 
	C747.947388,407.508392 748.936584,409.962158 749.899109,412.798157 
	C747.672363,410.993835 745.472229,408.807281 743.353882,406.205017 
	C743.628601,405.500854 743.821533,405.212372 744.014404,404.923889 
z"/>
<path fill="#0439BA" opacity="1.000000" stroke="none" 
	d="
M707.367798,388.844360 
	C706.967041,389.039307 706.779785,389.030579 706.303833,388.620239 
	C705.008911,385.493378 704.002563,382.768036 702.996277,380.042725 
	C708.127197,379.598785 706.498718,383.801514 707.526123,386.624451 
	C707.685730,387.496735 707.633545,388.068695 707.367798,388.844360 
z"/>
<path fill="#0B3189" opacity="1.000000" stroke="none" 
	d="
M717.552002,372.280945 
	C718.420532,370.765076 719.650269,369.251709 721.275269,367.383484 
	C722.111694,367.028381 722.552856,367.028137 723.197876,367.279449 
	C724.020203,367.690369 724.638794,367.849731 725.257324,368.009094 
	C722.809326,369.433868 720.361267,370.858673 717.552002,372.280945 
z"/>
<path fill="#113598" opacity="1.000000" stroke="none" 
	d="
M704.308105,423.640625 
	C703.972412,423.336243 703.961426,422.965881 703.940918,422.318726 
	C705.917603,421.456390 707.903809,420.870880 709.937683,420.595520 
	C708.201233,421.795319 706.417053,422.684998 704.308105,423.640625 
z"/>
<path fill="#0C237E" opacity="1.000000" stroke="none" 
	d="
M749.847290,413.456055 
	C750.865662,415.063690 751.927002,417.010315 752.667236,419.130981 
	C751.527527,417.468384 750.708862,415.631714 749.847290,413.456055 
z"/>
<path fill="#032495" opacity="1.000000" stroke="none" 
	d="
M733.170959,421.346008 
	C731.581787,420.908142 729.818970,420.185638 728.221436,418.989563 
	C729.923584,419.364410 731.460510,420.212860 733.170959,421.346008 
z"/>
<path fill="#083797" opacity="1.000000" stroke="none" 
	d="
M743.764160,404.658813 
	C743.821533,405.212372 743.628601,405.500854 743.200989,405.907166 
	C742.272827,405.650238 741.579346,405.275452 740.768433,404.622070 
	C741.605347,404.360229 742.559631,404.377014 743.764160,404.658813 
z"/>
<path fill="#083797" opacity="1.000000" stroke="none" 
	d="
M739.057129,403.475922 
	C739.568726,403.398590 740.198975,403.575836 740.754089,404.079590 
	C740.177917,404.180878 739.676880,403.955658 739.057129,403.475922 
z"/>
<path fill="#043BA1" opacity="1.000000" stroke="none" 
	d="
M725.234131,367.998352 
	C724.638794,367.849731 724.020203,367.690369 723.367432,367.033936 
	C723.887146,366.359161 724.441162,366.181396 724.995117,366.003662 
	C725.034851,366.333099 725.074646,366.662567 725.178101,367.487488 
	C725.241760,367.982971 725.210876,367.987610 725.234131,367.998352 
z"/>
<path fill="#0C237E" opacity="1.000000" stroke="none" 
	d="
M740.293213,442.419556 
	C740.163940,442.876495 739.684814,443.369293 738.807983,443.857910 
	C738.921265,443.387634 739.432312,442.921509 740.293213,442.419556 
z"/>
<path fill="#032495" opacity="1.000000" stroke="none" 
	d="
M726.926758,419.676270 
	C726.294678,419.089386 726.139221,418.378540 725.946899,417.124664 
	C726.407898,417.571869 726.905640,418.562134 726.926758,419.676270 
z"/>
<path fill="#023497" opacity="1.000000" stroke="none" 
	d="
M737.730103,443.959930 
	C737.769470,444.216919 737.515198,444.476135 736.984070,444.777893 
	C736.950317,444.534332 737.193420,444.248230 737.730103,443.959930 
z"/>
<path fill="#163A84" opacity="1.000000" stroke="none" 
	d="
M770.027588,406.472656 
	C770.031738,406.038269 770.467957,405.980225 770.685242,405.951965 
	C771.221924,405.969116 771.542847,405.987823 772.105652,405.982605 
	C771.549377,413.369049 770.677246,420.745544 769.978333,428.138397 
	C769.328857,435.008392 771.443726,440.832886 777.075317,445.533112 
	C775.546814,445.119293 773.931885,444.375000 772.145874,443.325256 
	C767.886414,437.820496 767.181274,432.008636 768.901611,424.967163 
	C768.953369,423.124603 768.954773,422.081665 768.956177,421.038727 
	C769.311890,416.328186 769.667664,411.617615 770.027588,406.472656 
z"/>
<path fill="#023497" opacity="1.000000" stroke="none" 
	d="
M743.425354,442.981140 
	C746.064392,439.842041 749.320312,438.923431 752.958862,442.604462 
	C749.984497,442.964661 746.933777,442.977814 743.425354,442.981140 
z"/>
<path fill="#04308B" opacity="1.000000" stroke="none" 
	d="
M814.092896,369.571960 
	C814.168457,368.580780 814.649414,367.676605 815.366577,366.328369 
	C813.880737,365.674530 811.970337,364.955322 810.176453,364.014069 
	C807.895569,362.817291 805.717346,361.426208 803.478333,360.147797 
	C802.414734,359.540527 801.309509,358.502594 800.228943,358.510071 
	C793.034973,358.559723 793.035461,358.634430 793.467468,351.431335 
	C801.435120,350.984863 808.935913,351.040588 816.433594,350.913605 
	C817.965576,350.887665 819.485901,350.175964 821.411987,349.798187 
	C822.880615,349.848145 823.948975,349.880249 825.044067,350.223206 
	C825.491028,350.857605 825.911133,351.181183 826.331238,351.504730 
	C826.531189,350.954071 826.731140,350.403412 827.175659,349.633514 
	C828.282959,349.328918 829.145813,349.243561 830.320801,349.426208 
	C835.399170,349.803955 840.168701,350.072784 844.930542,349.974487 
	C848.962646,349.891327 852.985962,349.383423 857.336548,349.053406 
	C858.090576,349.054504 858.521118,349.065643 859.217957,349.342102 
	C861.662231,349.020020 863.840332,348.432648 866.018372,347.845276 
	C866.630737,349.084625 867.862305,350.387238 867.743225,351.551788 
	C867.329651,355.597290 866.447510,359.594849 865.368896,363.402405 
	C864.040894,361.817017 863.125671,359.346527 862.125366,359.311584 
	C860.507446,359.255005 858.370605,360.412567 857.265564,361.735992 
	C853.076660,366.752930 850.462280,367.629669 843.855652,365.917694 
	C842.524475,365.572784 840.939514,366.207153 839.471558,366.390045 
	C840.144714,368.244812 840.569946,370.249664 841.571960,371.905396 
	C842.331604,373.160645 843.826355,373.970978 844.623047,374.940521 
	C841.727783,373.902405 839.203430,372.903046 836.679138,371.903687 
	C837.196960,368.370453 835.298462,367.593750 832.264221,367.914215 
	C826.346802,368.539124 820.420715,369.082062 814.092896,369.571960 
z"/>
<path fill="#08348D" opacity="1.000000" stroke="none" 
	d="
M817.035461,380.938171 
	C819.949707,380.720917 820.775696,382.078247 820.295105,384.849640 
	C819.174438,391.311432 818.214355,397.801086 816.787964,404.922546 
	C814.932373,410.703918 813.477905,415.842194 811.595947,420.987000 
	C804.857056,420.660370 798.540649,420.400269 792.236206,419.966522 
	C788.506287,419.709869 786.508057,420.969574 786.662354,425.027222 
	C786.069885,425.098999 785.832825,425.127136 785.595764,425.155273 
	C785.802063,421.271912 785.758301,417.355652 786.264343,413.511749 
	C787.492981,404.178192 788.977417,394.878296 790.983032,385.210541 
	C793.849670,381.067657 796.914734,380.787079 801.158142,381.343262 
	C806.343811,382.022949 811.733826,381.143433 817.035461,380.938171 
M800.036865,398.838013 
	C797.224731,398.158142 794.412598,397.478241 791.024597,396.659119 
	C790.719116,399.668549 790.415039,402.664337 790.183533,404.945160 
	C793.777222,404.945160 796.236755,404.942749 798.696350,404.945709 
	C803.092529,404.951019 807.521057,405.261627 811.866882,404.797394 
	C813.321838,404.641968 814.566040,402.512665 815.906616,401.285675 
	C814.557861,400.522949 813.256836,399.227600 811.851074,399.101593 
	C808.234680,398.777344 804.571838,398.971222 800.036865,398.838013 
z"/>
<path fill="#0B3189" opacity="1.000000" stroke="none" 
	d="
M858.077698,415.031952 
	C858.114563,415.492340 858.151428,415.952759 857.997009,416.709229 
	C857.195068,418.102081 856.584473,419.198853 855.554688,420.592834 
	C847.424072,420.590881 839.712524,420.291779 832.001038,419.992676 
	C832.226196,414.886749 832.451416,409.780853 832.433838,404.371521 
	C832.944641,397.905945 833.698242,391.743774 835.058838,385.220825 
	C837.104919,383.908417 838.543945,382.956787 840.003662,382.395386 
	C841.676636,386.198212 843.328857,389.610809 845.026672,393.377075 
	C845.612061,394.874969 846.151733,396.019226 846.969238,397.752350 
	C843.613770,398.015686 840.636597,397.888458 837.882141,398.610565 
	C836.401611,398.998657 835.303284,400.844574 834.032532,402.032715 
	C835.180847,403.021637 836.297302,404.819244 837.482727,404.865936 
	C844.300476,405.134552 851.134583,404.988495 857.970947,405.397766 
	C858.011719,408.885681 858.044678,411.958832 858.077698,415.031952 
z"/>
<path fill="#0C237E" opacity="1.000000" stroke="none" 
	d="
M785.628906,425.536560 
	C785.832825,425.127136 786.069885,425.098999 786.931641,425.153198 
	C788.393616,425.473816 789.228638,425.903778 790.068665,425.913757 
	C792.410767,425.941620 794.762451,425.638580 797.096558,425.751404 
	C801.504089,425.964386 805.899170,426.440826 810.307129,426.625305 
	C810.853882,426.648193 811.447144,425.560730 812.265137,424.816650 
	C813.063782,424.087067 813.616089,423.528595 814.168457,422.970154 
	C815.083862,426.714264 812.886292,429.521423 810.113464,430.574341 
	C805.123047,432.469391 799.766235,433.994232 794.476257,434.335205 
	C789.396851,434.662567 786.472412,430.974823 785.628906,425.536560 
z"/>
<path fill="#0C237E" opacity="1.000000" stroke="none" 
	d="
M816.791443,380.659851 
	C811.733826,381.143433 806.343811,382.022949 801.158142,381.343262 
	C796.914734,380.787079 793.849670,381.067657 791.238159,384.900269 
	C791.491150,379.935394 792.113281,374.926483 792.742432,369.509521 
	C796.679443,376.747375 800.640259,377.265442 808.515137,371.076904 
	C808.498962,373.142426 808.134705,375.249084 807.654968,378.023926 
	C810.583618,378.800385 813.565491,379.590973 816.791443,380.659851 
z"/>
<path fill="#01177C" opacity="1.000000" stroke="none" 
	d="
M839.982910,382.005127 
	C838.543945,382.956787 837.104919,383.908417 835.318298,384.915558 
	C835.356140,380.815826 835.741638,376.660645 836.403137,372.204559 
	C839.203430,372.903046 841.727783,373.902405 844.630859,374.948914 
	C845.009583,374.996063 845.029297,374.992676 844.980042,375.336914 
	C843.297180,377.790588 841.663574,379.899994 840.018677,382.007446 
	C840.007324,382.005554 839.982910,382.005127 839.982910,382.005127 
z"/>
<path fill="#0C237E" opacity="1.000000" stroke="none" 
	d="
M810.996704,440.005615 
	C810.449707,440.181213 809.902771,440.356873 808.865356,440.812805 
	C807.910217,441.721436 807.445496,442.349823 806.980713,442.978210 
	C805.755310,442.960693 804.529968,442.943176 802.841187,442.890625 
	C805.959473,439.815948 809.541199,436.776276 813.039246,433.807556 
	C814.012085,436.926514 814.995117,440.078339 816.120422,443.588318 
	C815.757751,443.963348 815.252808,443.980225 814.350098,443.703918 
	C813.634338,442.938690 813.316345,442.466705 812.998352,441.994690 
	C812.731140,441.510590 812.463928,441.026520 812.006348,440.118896 
	C811.542969,439.798767 811.269836,439.902191 810.996704,440.005615 
z"/>
<path fill="#0C237E" opacity="1.000000" stroke="none" 
	d="
M812.023438,420.980469 
	C813.477905,415.842194 814.932373,410.703918 816.637878,405.303772 
	C816.159546,410.511200 815.430176,415.980560 814.126221,421.748169 
	C813.048950,422.017822 812.546143,421.989197 812.036072,421.715607 
	C812.028748,421.470612 812.023499,420.980469 812.023438,420.980469 
z"/>
<path fill="#08348D" opacity="1.000000" stroke="none" 
	d="
M812.043396,421.960602 
	C812.546143,421.989197 813.048950,422.017822 813.928101,422.078369 
	C814.304504,422.110291 814.451965,422.574249 814.310181,422.772217 
	C813.616089,423.528595 813.063782,424.087067 812.259644,424.438965 
	C812.019775,423.475098 812.031555,422.717865 812.043396,421.960602 
z"/>
<path fill="#042F89" opacity="1.000000" stroke="none" 
	d="
M801.735168,442.898010 
	C801.792236,443.175446 801.541016,443.453705 801.009888,443.799164 
	C800.962280,443.543854 801.194641,443.221344 801.735168,442.898010 
z"/>
<path fill="#042F89" opacity="1.000000" stroke="none" 
	d="
M800.580811,443.786255 
	C800.676147,444.051178 800.468933,444.376587 799.983398,444.803711 
	C799.896118,444.552490 800.087219,444.199615 800.580811,443.786255 
z"/>
<path fill="#022D9A" opacity="1.000000" stroke="none" 
	d="
M831.850708,420.337158 
	C839.712524,420.291779 847.424072,420.590881 855.543823,420.958252 
	C855.720337,424.166077 853.945557,425.117004 850.939392,425.044922 
	C844.432373,424.888794 837.919006,424.996796 831.033081,424.996796 
	C831.170898,424.105652 831.435608,422.393646 831.850708,420.337158 
z"/>
<path fill="#042F89" opacity="1.000000" stroke="none" 
	d="
M807.301025,442.940613 
	C807.445496,442.349823 807.910217,441.721436 808.692993,441.049805 
	C808.547852,441.638702 808.084595,442.270874 807.301025,442.940613 
z"/>
<path fill="#042F89" opacity="1.000000" stroke="none" 
	d="
M813.019226,442.292358 
	C813.316345,442.466705 813.634338,442.938690 813.971680,443.708588 
	C813.674072,443.534302 813.357056,443.062164 813.019226,442.292358 
z"/>
<path fill="#042F89" opacity="1.000000" stroke="none" 
	d="
M811.240967,440.009827 
	C811.269836,439.902191 811.542969,439.798767 811.894775,439.865112 
	C811.973450,440.034882 811.485291,440.014069 811.240967,440.009827 
z"/>
<path fill="#204C9E" opacity="1.000000" stroke="none" 
	d="
M599.451111,448.965942 
	C598.674622,448.487946 598.355042,448.012238 598.483154,447.185791 
	C610.828369,447.920807 621.566956,444.940308 631.210999,437.842804 
	C633.584229,436.096222 634.819519,434.458832 632.412842,431.778320 
	C628.626587,432.953705 624.937012,434.614441 621.086243,435.183136 
	C613.908997,436.243042 606.527527,437.220917 601.058472,431.149353 
	C602.629272,431.065582 603.774048,431.039398 605.102905,431.319885 
	C610.258789,435.123657 611.973022,434.748260 617.460022,429.059875 
	C623.938293,429.396667 629.948425,429.736694 635.958618,430.076721 
	C634.979004,433.486694 633.999329,436.896667 632.952759,440.661804 
	C631.366089,441.789978 629.846252,442.562958 628.169250,443.617615 
	C624.562439,445.160370 621.112793,446.421478 616.999634,447.840454 
	C615.898132,448.015289 615.460083,448.032227 615.022095,448.049133 
	C609.984070,448.355530 604.945984,448.661896 599.451111,448.965942 
z"/>
<path fill="#023CA5" opacity="1.000000" stroke="none" 
	d="
M615.172363,448.261169 
	C615.460083,448.032227 615.898132,448.015289 616.665039,447.997101 
	C616.436890,448.154968 615.879700,448.314056 615.172363,448.261169 
z"/>
<path fill="#0122A1" opacity="1.000000" stroke="none" 
	d="
M636.184692,429.793213 
	C629.948425,429.736694 623.938293,429.396667 617.477722,428.646332 
	C618.358643,427.486023 619.612976,426.483551 621.040283,426.047852 
	C622.959045,425.462128 625.011414,425.313843 627.466614,424.986267 
	C635.941895,424.951843 643.956909,424.908966 651.971985,424.866089 
	C651.971985,424.866089 652.006592,424.942017 652.026245,424.979187 
	C652.808167,429.687988 648.009949,426.134125 647.056458,428.677429 
	C643.470276,429.172333 639.940552,429.341003 636.184692,429.793213 
z"/>
<path fill="#022A86" opacity="1.000000" stroke="none" 
	d="
M897.038513,305.070679 
	C898.687622,304.233887 900.661987,303.733521 901.932617,302.505249 
	C909.843262,294.858063 917.596436,287.047943 925.705811,279.156982 
	C922.407349,283.809052 919.029724,288.789795 915.126038,293.316956 
	C912.363098,296.521210 908.536316,298.822113 905.856812,302.079376 
	C904.109619,304.203217 901.367981,306.852631 904.409363,310.382202 
	C905.087402,311.168915 904.131897,313.201294 904.336426,314.600525 
	C904.572876,316.217773 904.769165,318.424438 905.842224,319.228790 
	C909.379517,321.880249 908.104553,323.104431 904.886353,324.152893 
	C903.789246,324.510315 902.475342,324.469666 901.569092,325.073761 
	C900.579834,325.733276 899.948364,326.929474 899.160950,327.891754 
	C900.520630,328.711761 902.040283,330.404785 903.210510,330.190399 
	C906.927795,329.509399 910.514771,328.117310 914.535767,326.981262 
	C909.408447,332.329041 903.983154,337.789429 898.307373,342.975677 
	C897.288696,343.906403 895.249268,343.812897 893.658508,344.004822 
	C890.564575,344.378143 887.454651,344.618561 883.898560,344.937744 
	C883.683350,335.076508 883.684326,335.090027 873.316101,335.853180 
	C870.848328,336.034821 868.371582,336.093262 865.388306,335.770447 
	C863.580139,334.227753 862.282532,333.123016 861.322754,331.978210 
	C862.787048,331.251953 863.913452,330.565796 865.345337,329.878784 
	C866.058228,329.880463 866.465515,329.882935 867.250183,329.920837 
	C870.418579,327.076508 874.497925,324.634552 875.664368,321.202606 
	C877.025940,317.196503 875.067993,312.879028 870.273987,310.787994 
	C869.173340,310.308014 868.738892,308.300720 868.466614,306.986298 
	C876.818115,306.969971 884.703308,307.060303 892.581787,306.872253 
	C894.080872,306.836426 895.553589,305.699524 897.038513,305.070679 
z"/>
<path fill="#01177C" opacity="1.000000" stroke="none" 
	d="
M860.984863,332.018311 
	C862.282532,333.123016 863.580139,334.227753 864.940063,335.678864 
	C860.598206,336.554291 855.918945,336.738373 855.834473,343.492432 
	C855.024963,343.913696 854.530518,343.924469 854.029968,343.536957 
	C854.195435,340.878418 855.304810,338.030457 854.336121,336.484741 
	C852.522400,333.590637 849.504700,331.451019 846.838379,328.691956 
	C842.756958,325.414581 840.590881,319.413879 833.568115,320.989441 
	C831.085632,320.982025 829.041260,320.976624 826.745239,320.724182 
	C823.722107,320.601685 820.927246,321.011993 818.184265,320.787292 
	C813.474121,320.401459 808.806946,319.209320 804.102783,319.087128 
	C793.668518,318.816223 783.222046,319.047943 772.781616,318.951843 
	C770.848450,318.934082 768.920532,318.339447 766.993408,317.635254 
	C767.002563,316.507904 767.008606,315.756378 767.482361,315.005035 
	C781.439331,315.000610 794.929993,315.114349 808.417236,314.950256 
	C817.538269,314.839294 826.660156,314.443817 835.770630,313.966156 
	C840.465881,313.719940 843.883972,315.125580 846.876099,319.017731 
	C848.846741,321.581055 852.231445,323.057373 855.026550,325.374451 
	C857.041748,327.825104 859.013306,329.921722 860.984863,332.018311 
z"/>
<path fill="#0C237E" opacity="1.000000" stroke="none" 
	d="
M905.108948,425.072998 
	C899.180176,437.526031 882.865967,441.406494 872.202515,432.764801 
	C871.148315,431.910522 870.240356,429.946716 870.415527,428.653870 
	C871.638672,419.629120 873.165283,410.645538 875.058350,401.325104 
	C875.522034,401.001434 876.000854,401.015625 876.005127,401.351074 
	C876.337646,402.463104 876.665833,403.239655 876.986572,404.406982 
	C875.984802,409.866455 874.990479,414.935181 873.720825,420.274689 
	C872.757141,422.829926 872.447937,424.981537 875.842651,424.980865 
	C885.506897,424.978943 895.171143,424.913300 904.901245,424.926208 
	C904.967163,424.979706 905.108948,425.072998 905.108948,425.072998 
z"/>
<path fill="#065EAF" opacity="1.000000" stroke="none" 
	d="
M854.036072,343.935303 
	C854.530518,343.924469 855.024963,343.913696 855.887695,343.867859 
	C859.362366,344.990234 862.468750,346.147675 865.796753,347.575195 
	C863.840332,348.432648 861.662231,349.020020 859.155334,348.965942 
	C856.748657,347.565338 854.691467,346.215759 852.589661,346.142303 
	C838.319336,345.643951 824.041016,345.273285 809.762512,345.133820 
	C791.299438,344.953522 772.833618,345.061737 754.369629,344.944031 
	C752.281006,344.930725 750.196533,344.260986 748.074768,343.582275 
	C748.024170,342.847687 748.008911,342.426453 748.410278,342.053223 
	C750.936340,342.409943 753.043945,342.971130 755.155457,342.985870 
	C767.431946,343.071625 779.709290,343.028992 792.456543,343.034546 
	C813.296448,343.338013 833.666260,343.636658 854.036072,343.935303 
z"/>
<path fill="#01319F" opacity="1.000000" stroke="none" 
	d="
M845.001831,374.987671 
	C843.826355,373.970978 842.331604,373.160645 841.571960,371.905396 
	C840.569946,370.249664 840.144714,368.244812 839.471558,366.390045 
	C840.939514,366.207153 842.524475,365.572784 843.855652,365.917694 
	C850.462280,367.629669 853.076660,366.752930 857.265564,361.735992 
	C858.370605,360.412567 860.507446,359.255005 862.125366,359.311584 
	C863.125671,359.346527 864.040894,361.817017 865.008911,363.576752 
	C865.020569,365.363190 865.017639,366.766998 864.592896,368.658203 
	C862.774841,372.323761 860.469299,374.379395 856.520325,374.980957 
	C852.384644,374.991241 848.706970,374.991943 845.029297,374.992676 
	C845.029297,374.992676 845.009583,374.996063 845.001831,374.987671 
z"/>
<path fill="#142A6D" opacity="1.000000" stroke="none" 
	d="
M876.000854,401.015625 
	C876.000854,401.015625 875.522034,401.001434 875.282837,400.989685 
	C875.555847,396.707184 875.861755,392.396088 876.685242,388.186249 
	C876.998657,386.584290 878.166870,384.638550 879.525818,383.851013 
	C883.144714,381.753876 886.923401,379.629913 890.917786,378.541870 
	C901.732117,375.596069 908.851135,380.246094 910.612610,391.309204 
	C912.457764,402.897766 910.830750,414.103577 905.423950,424.907837 
	C905.108948,425.072998 904.967163,424.979706 904.949158,424.512878 
	C904.980957,422.369568 905.030762,420.693054 905.356445,418.775024 
	C909.124329,410.482086 909.955200,401.933594 908.671021,393.475311 
	C908.029114,389.247345 908.160645,383.494598 901.775635,381.694885 
	C895.428650,378.509064 890.043335,380.245972 884.590881,383.978577 
	C878.975769,384.805664 878.460022,388.875946 878.006470,392.997803 
	C877.999512,393.001709 877.994568,393.016907 877.736816,393.224152 
	C877.323792,394.288513 877.168518,395.145599 877.006836,396.388733 
	C876.667236,398.188416 876.334106,399.602020 876.000854,401.015625 
z"/>
<path fill="#0B3189" opacity="1.000000" stroke="none" 
	d="
M856.978271,374.971436 
	C860.469299,374.379395 862.774841,372.323761 864.511719,369.070801 
	C863.412048,379.472321 861.971680,389.948669 859.922852,400.714050 
	C858.882996,401.003601 858.451660,401.004089 857.931885,400.628998 
	C855.308167,399.422211 852.772766,398.591034 849.352539,397.469757 
	C852.603638,395.568695 854.802124,394.283173 857.266724,392.767456 
	C858.669312,389.045624 859.805786,385.553986 860.958008,381.690552 
	C859.641968,379.203003 858.310120,377.087219 856.978271,374.971436 
z"/>
<path fill="#0C237E" opacity="1.000000" stroke="none" 
	d="
M858.020264,401.004578 
	C858.451660,401.004089 858.882996,401.003601 859.637817,400.995972 
	C859.461731,405.445831 858.962219,409.902832 858.270264,414.695892 
	C858.044678,411.958832 858.011719,408.885681 857.981262,405.000427 
	C857.995972,403.127045 858.008118,402.065826 858.020264,401.004578 
z"/>
<path fill="#24B6F9" opacity="1.000000" stroke="none" 
	d="
M247.273529,139.157318 
	C247.713318,137.397614 248.383621,135.924026 249.053940,134.450455 
	C250.038605,135.535751 251.023270,136.621063 252.007935,137.706360 
	C250.506638,138.285400 249.005341,138.864426 247.273529,139.157318 
z"/>
<path fill="#012E8A" opacity="1.000000" stroke="none" 
	d="
M292.416809,486.999023 
	C295.132660,484.018555 298.213287,481.032135 301.635101,477.992889 
	C300.179504,482.245697 297.784698,485.943939 292.416809,486.999023 
z"/>
<path fill="#013AA5" opacity="1.000000" stroke="none" 
	d="
M546.903442,481.454712 
	C543.505066,480.124878 544.761047,476.904327 544.529968,474.301331 
	C544.444458,473.337158 545.765686,472.248138 546.445374,471.216034 
	C546.992493,471.332886 547.539612,471.449738 548.086731,471.566620 
	C547.719971,474.740814 547.353210,477.915009 546.903442,481.454712 
z"/>
<path fill="#0241B4" opacity="1.000000" stroke="none" 
	d="
M531.993042,459.993896 
	C533.425293,458.996277 534.857605,457.998688 536.642090,456.997498 
	C536.737122,457.491089 536.479858,457.988251 536.115479,458.744568 
	C535.689087,459.111359 535.428345,459.299927 534.740540,459.785492 
	C533.510620,460.008331 532.766296,460.015137 532.015015,460.015076 
	C532.008118,460.008209 531.993042,459.993896 531.993042,459.993896 
z"/>
<path fill="#0241B4" opacity="1.000000" stroke="none" 
	d="
M528.390381,461.994568 
	C529.075073,461.324890 530.161072,460.662445 531.620117,459.996948 
	C531.993042,459.993896 532.008118,460.008209 532.009094,460.344971 
	C532.007080,461.122253 532.004150,461.562775 532.001221,462.003296 
	C530.931396,462.002777 529.861572,462.002258 528.390381,461.994568 
z"/>
<path fill="#013AA5" opacity="1.000000" stroke="none" 
	d="
M532.193848,462.237427 
	C532.004150,461.562775 532.007080,461.122253 532.015991,460.351868 
	C532.766296,460.015137 533.510620,460.008331 534.627441,460.001984 
	C534.128845,460.825470 533.257690,461.648499 532.193848,462.237427 
z"/>
<path fill="#1E4796" opacity="1.000000" stroke="none" 
	d="
M544.259766,446.671692 
	C542.612610,446.970032 541.237061,447.000458 539.046570,447.023712 
	C537.161499,447.019135 536.091370,447.021759 534.561279,447.014740 
	C529.801270,446.704895 525.501099,446.404694 521.533997,446.127747 
	C520.937683,438.511536 523.630066,435.272552 530.192200,434.619080 
	C532.761536,434.363190 536.330688,433.231476 536.182861,428.689026 
	C536.569214,426.525726 536.796265,424.719635 537.084717,422.514832 
	C538.120789,415.055450 539.095581,407.994812 540.245239,400.820648 
	C540.641846,400.530609 540.812073,400.313599 541.302246,400.043213 
	C541.921753,400.035217 542.169556,400.040100 542.426758,400.494629 
	C541.195374,409.489807 539.489563,418.001587 538.865723,426.591949 
	C538.392822,433.102966 535.807678,436.544373 529.321777,437.175201 
	C525.083679,437.587402 523.541809,440.251038 523.770325,444.651886 
	C535.067078,444.651886 546.170715,444.662048 557.274353,444.647614 
	C562.859070,444.640320 563.418762,443.966431 563.103577,437.822327 
	C561.113708,437.099701 559.116089,436.374237 557.576172,435.483307 
	C558.995605,435.194977 559.957397,435.072144 560.919128,434.949341 
	C564.296326,434.743439 566.368225,435.798981 565.985413,439.718231 
	C565.523560,444.447113 562.899780,447.185974 559.018860,446.961914 
	C554.195007,446.683411 549.360901,446.581787 544.259766,446.671692 
z"/>
<path fill="#0639BE" opacity="1.000000" stroke="none" 
	d="
M560.955933,434.573181 
	C559.957397,435.072144 558.995605,435.194977 557.580139,435.450134 
	C557.126404,435.582428 557.187195,435.560486 557.035339,435.236359 
	C556.282532,434.612640 555.681641,434.313049 554.931519,433.708466 
	C555.432129,431.638885 555.934387,429.800354 556.771973,428.129913 
	C557.661377,426.356049 558.868896,424.741669 560.305176,423.039124 
	C562.442017,422.341064 564.211243,421.661591 566.024170,421.332397 
	C567.068420,422.780029 568.068848,423.877441 569.235596,425.284424 
	C572.194214,428.115082 574.066040,427.325104 575.434570,424.101624 
	C576.059509,424.477570 576.476013,424.726776 577.053284,425.201538 
	C577.246948,425.789368 577.279785,426.151672 577.312622,426.513947 
	C577.204407,427.690674 577.096130,428.867401 576.979004,430.486023 
	C573.778870,430.830658 570.477661,431.212799 567.424683,430.512665 
	C563.751770,429.670319 561.693298,430.431244 560.955933,434.573181 
z"/>
<path fill="#0241B4" opacity="1.000000" stroke="none" 
	d="
M537.338867,456.959412 
	C539.094238,454.669952 541.194336,452.347137 543.656616,450.028931 
	C541.907104,452.331024 539.795349,454.628540 537.338867,456.959412 
z"/>
<path fill="#EFF6F8" opacity="1.000000" stroke="none" 
	d="
M577.550537,426.787964 
	C577.279785,426.151672 577.246948,425.789368 577.092834,424.776794 
	C575.371826,414.615509 576.819153,405.384216 579.737000,396.001282 
	C581.069153,393.189911 582.192749,390.645966 583.545898,388.035583 
	C584.069153,387.747070 584.159912,387.460693 584.318420,386.968781 
	C584.776428,386.253662 584.963562,385.679779 585.138306,385.096313 
	C585.125854,385.086731 585.159363,385.119354 585.475220,385.003052 
	C588.876099,381.606293 591.961121,378.325836 595.046143,375.045410 
	C601.492432,372.290680 607.743042,368.833405 614.437317,366.968994 
	C621.870972,364.898712 628.773010,367.191406 634.433716,373.274353 
	C636.006958,377.063202 637.267822,380.709930 638.596741,385.108032 
	C638.771484,387.578918 638.878052,389.298431 638.984680,391.017975 
	C638.058228,394.519806 637.029968,397.998779 636.236816,401.530548 
	C635.570618,404.497192 633.904785,405.383362 630.923584,405.381805 
	C619.984558,405.376099 609.045410,405.639862 598.106140,405.794220 
	C596.477600,405.817200 594.848389,405.797394 592.258179,405.797394 
	C592.658142,410.375427 593.011780,414.423218 593.619141,419.144165 
	C596.126038,423.613861 598.379211,427.410400 600.632385,431.206909 
	C606.527527,437.220917 613.908997,436.243042 621.086243,435.183136 
	C624.937012,434.614441 628.626587,432.953705 632.412842,431.778320 
	C634.819519,434.458832 633.584229,436.096222 631.210999,437.842804 
	C621.566956,444.940308 610.828369,447.920807 598.472290,446.885742 
	C597.400696,446.691071 596.787598,446.445770 595.840393,446.198242 
	C586.814270,442.397827 580.340149,436.545990 577.550537,426.787964 
M595.817261,391.135986 
	C595.233887,393.551819 594.650574,395.967651 593.886719,399.130981 
	C596.994507,398.827484 598.921082,398.639343 601.582825,398.478149 
	C602.808716,398.408691 604.034668,398.339233 605.267517,398.492126 
	C605.267517,398.492126 605.585876,398.498535 605.585876,398.498535 
	C605.585876,398.498535 605.652161,398.236389 606.613037,398.194397 
	C608.264221,398.095428 609.915039,397.989990 611.566650,397.898529 
	C622.846619,397.273865 624.280396,395.592407 623.283508,384.136200 
	C623.226135,383.477112 623.161438,382.816193 623.050293,382.164612 
	C622.310242,377.825073 619.678223,375.556702 615.353821,375.075287 
	C610.799011,374.568207 606.565857,375.610168 603.706055,379.152802 
	C600.853088,382.686981 598.738525,386.817383 595.817261,391.135986 
z"/>
<path fill="#003CB2" opacity="1.000000" stroke="none" 
	d="
M521.004150,455.010010 
	C519.208923,455.672882 517.415955,456.906281 515.618103,456.913483 
	C486.057922,457.031891 456.497040,456.995728 426.467560,456.997742 
	C390.627411,456.990814 355.256073,456.980408 319.427551,456.627258 
	C318.979736,455.855530 318.989044,455.426544 319.467224,455.000488 
	C335.956573,455.000214 351.977081,454.996979 368.466431,454.999878 
	C395.621765,455.001587 422.308197,454.997070 449.463623,454.995911 
	C470.765747,454.999146 491.598877,454.998627 512.432007,454.999451 
	C515.289368,454.999573 518.146790,455.006348 521.004150,455.010010 
z"/>
<path fill="#033FA9" opacity="1.000000" stroke="none" 
	d="
M304.011414,459.995178 
	C304.007385,459.552185 304.003357,459.109161 304.145569,458.000427 
	C305.985718,451.831818 308.997681,454.022797 311.981018,456.362244 
	C311.995026,457.165710 312.004150,457.625305 312.013275,458.084869 
	C310.547760,460.341492 309.082245,462.598114 307.286560,464.538849 
	C305.974701,462.813660 304.993042,461.404419 304.011414,459.995178 
z"/>
<path fill="#012F8B" opacity="1.000000" stroke="none" 
	d="
M303.741394,460.243469 
	C304.993042,461.404419 305.974701,462.813660 306.945374,464.564697 
	C305.432190,466.106750 303.929932,467.307037 302.182739,468.742676 
	C302.449005,466.149323 302.960175,463.320526 303.741394,460.243469 
z"/>
<path fill="#033494" opacity="1.000000" stroke="none" 
	d="
M312.349701,458.036713 
	C312.004150,457.625305 311.995026,457.165710 312.339722,456.316833 
	C314.795166,455.617523 316.896790,455.307556 318.998383,454.997559 
	C318.989044,455.426544 318.979736,455.855530 318.955750,456.604584 
	C316.856079,457.279266 314.771118,457.633911 312.349701,458.036713 
z"/>
<path fill="#043BA2" opacity="1.000000" stroke="none" 
	d="
M521.336975,455.007111 
	C518.146790,455.006348 515.289368,454.999573 512.432007,454.999451 
	C491.598877,454.998627 470.765747,454.999146 449.519318,454.592316 
	C449.397247,453.068054 450.054138,451.903473 449.917023,450.841400 
	C449.287201,445.962067 451.381348,444.215729 456.175232,443.591400 
	C459.566528,443.149658 462.921600,441.222687 465.919891,439.358826 
	C468.247803,437.911652 469.999390,435.537384 472.562958,433.031982 
	C470.723938,429.035217 473.939209,429.042572 477.581268,428.901276 
	C479.400940,428.830719 481.179443,427.699646 483.271851,426.904602 
	C484.385559,426.521118 485.204010,426.281250 486.022888,426.406891 
	C487.013031,427.523132 488.002747,428.273865 488.987000,429.401947 
	C487.569946,431.165283 486.385406,433.052155 484.693207,433.819336 
	C482.183441,434.957153 478.877319,434.675751 476.730194,436.173798 
	C474.875519,437.467834 473.551392,440.308655 473.198730,442.647491 
	C473.014008,443.872559 475.389465,446.705841 476.662720,446.739014 
	C489.092438,447.062714 501.536957,447.087708 513.967285,446.786835 
	C515.570496,446.747986 518.386353,444.371307 518.382751,443.083832 
	C518.377808,441.298889 521.188843,437.915344 516.822021,437.141357 
	C516.870300,437.116516 516.844971,437.222168 516.833740,436.839630 
	C515.744202,435.879303 514.705322,434.903961 513.580688,434.792206 
	C508.588745,434.296173 507.247253,431.419312 508.419739,426.960236 
	C509.873932,426.991211 510.932098,427.006409 512.096130,427.411102 
	C513.697083,428.199646 515.185242,428.910309 516.688477,428.943665 
	C523.131165,429.086609 529.578186,429.030060 536.023682,429.046204 
	C536.330688,433.231476 532.761536,434.363190 530.192200,434.619080 
	C523.630066,435.272552 520.937683,438.511536 521.533997,446.127747 
	C525.501099,446.404694 529.801270,446.704895 534.548157,447.377167 
	C532.647888,450.181000 530.300903,452.612732 527.953857,455.044495 
	C527.216309,455.039154 526.478760,455.033813 525.226746,454.802795 
	C524.141968,454.717255 523.571594,454.857391 523.001282,454.997559 
	C522.557434,454.999786 522.113647,455.001984 521.336975,455.007111 
z"/>
<path fill="#003CB2" opacity="1.000000" stroke="none" 
	d="
M528.025391,455.375153 
	C530.300903,452.612732 532.647888,450.181000 535.008057,447.386810 
	C536.091370,447.021759 537.161499,447.019135 538.634033,447.037170 
	C536.564209,449.986481 534.201965,453.022583 531.529480,455.755310 
	C530.978394,456.318878 529.271179,455.751801 528.025391,455.375153 
z"/>
<path fill="#003CB2" opacity="1.000000" stroke="none" 
	d="
M523.148071,455.216736 
	C523.571594,454.857391 524.141968,454.717255 524.857971,454.801697 
	C524.434082,455.162842 523.864502,455.299377 523.148071,455.216736 
z"/>
<path fill="#012F8B" opacity="1.000000" stroke="none" 
	d="
M319.467224,455.000519 
	C316.896790,455.307556 314.795166,455.617523 312.334869,455.972900 
	C308.997681,454.022797 305.985718,451.831818 304.138611,457.668213 
	C303.474487,456.398560 302.600342,454.786896 302.637634,453.196564 
	C302.651886,452.590088 304.733856,451.982025 305.913666,451.466675 
	C307.160461,450.922058 308.455139,450.487122 309.728943,450.004333 
	C309.067993,449.020569 308.287415,448.096069 307.765991,447.043182 
	C304.865784,441.186981 299.829651,440.355316 293.992584,440.631378 
	C293.055420,440.675690 291.273346,438.281097 291.124237,436.881561 
	C290.493958,430.965820 290.408386,424.994507 289.973846,419.054474 
	C289.696106,415.257629 291.666321,414.000305 295.046997,414.002747 
	C302.201233,414.007965 309.356354,414.067505 316.509369,413.973694 
	C320.978607,413.915100 322.627319,412.233337 322.222260,408.395508 
	C315.240082,413.055023 307.493469,410.373322 300.073944,410.823608 
	C299.768524,410.842163 299.335968,410.714630 299.151672,410.495880 
	C293.767181,404.106506 288.661346,408.447754 283.192719,410.521484 
	C284.897186,408.368103 286.915405,406.573120 289.404663,404.843140 
	C299.348450,404.928040 308.821533,404.997986 318.293579,404.925934 
	C320.203247,404.911407 322.108795,404.358917 324.413971,404.053894 
	C325.597626,403.954132 326.383606,403.854919 327.547455,403.797943 
	C329.964203,403.643341 332.003082,403.446503 334.130981,403.630981 
	C340.485504,404.051666 346.750977,404.091064 353.012878,404.504211 
	C355.011597,407.719177 356.947784,410.610107 359.035736,413.386963 
	C360.955292,415.939789 363.038177,418.369812 364.577576,420.905945 
	C358.180237,420.967133 352.253113,421.054871 346.328888,420.960663 
	C341.539337,420.884521 336.407410,422.240448 332.591370,417.611053 
	C332.024689,416.923523 330.121429,417.337708 328.559570,417.200867 
	C327.910645,417.187347 327.538666,417.215210 326.792603,417.171997 
	C322.967163,417.650818 320.900787,419.380707 321.210388,423.444580 
	C321.194855,424.092682 321.218170,424.464233 321.160950,425.218597 
	C321.145111,429.494110 322.706970,431.784790 327.338745,431.136841 
	C328.106415,431.135742 328.545105,431.140594 329.209106,431.368958 
	C330.288086,431.040802 331.141754,430.489136 332.263031,429.800842 
	C333.237305,428.980499 333.943939,428.296783 334.966614,427.709534 
	C335.940826,427.362183 336.598999,426.918396 337.702026,426.428406 
	C342.714478,426.375122 347.282104,426.368073 351.992493,426.488220 
	C352.475067,426.531677 352.814850,426.447906 353.571533,426.342133 
	C355.654785,426.385254 357.321136,426.450348 358.984192,426.880249 
	C358.920898,431.458313 355.846588,431.681549 352.770020,432.019348 
	C350.883575,432.226471 349.020142,432.643646 348.429657,432.745392 
	C349.590607,438.186432 350.453918,442.489349 351.436005,446.764954 
	C352.469421,451.263916 355.269745,452.207397 359.409821,450.153107 
	C361.555725,449.088348 364.193695,449.015167 366.609314,448.493988 
	C367.072052,450.660583 367.534790,452.827148 367.997559,454.993713 
	C351.977081,454.996979 335.956573,455.000214 319.467224,455.000519 
z"/>
<path fill="#0964CE" opacity="1.000000" stroke="none" 
	d="
M327.169556,403.755737 
	C326.383606,403.854919 325.597626,403.954132 324.117950,403.754211 
	C312.636536,403.322052 301.848907,403.156128 291.060944,403.130890 
	C290.353119,403.129242 289.642792,404.204071 288.933624,404.778168 
	C286.915405,406.573120 284.897186,408.368103 282.822174,410.517975 
	C281.637787,411.433197 280.510223,411.993500 278.892944,412.855469 
	C277.948456,413.833832 277.493683,414.510529 277.038940,415.187195 
	C276.221924,415.725372 275.404938,416.263519 274.080139,417.009644 
	C273.395538,417.491516 273.218781,417.765472 273.042053,418.039398 
	C271.376099,420.112122 269.710175,422.184845 267.634094,424.626770 
	C265.158997,427.000916 263.093994,429.005859 261.028992,431.010803 
	C255.365112,436.757172 249.701248,442.503540 243.980850,448.585480 
	C243.213547,448.961151 242.502777,449.001312 241.366272,448.916199 
	C240.856873,448.736847 240.773224,448.682739 240.689590,448.628601 
	C240.815613,448.778107 240.941635,448.927612 241.067642,449.077087 
	C240.614471,449.722229 240.161316,450.367371 239.402893,451.095367 
	C238.596161,450.478241 238.094727,449.778290 237.320679,448.680847 
	C240.045395,444.860992 243.042725,441.438568 246.273010,437.835938 
	C246.670822,437.102051 246.835648,436.548401 247.000473,435.994781 
	C247.591431,435.517700 248.182388,435.040619 249.237732,434.257263 
	C252.472031,431.304260 255.241959,428.657593 258.011871,426.010925 
	C258.011871,426.010925 258.000000,426.000000 258.308594,425.868134 
	C264.075439,420.156586 269.533691,414.576904 274.991943,408.997192 
	C274.991943,408.997223 275.083984,408.598724 275.428284,408.313751 
	C278.824158,405.663757 281.875763,403.298737 285.247253,400.918304 
	C286.063629,400.311798 286.560181,399.720734 287.501587,399.103577 
	C295.969604,399.075287 303.992706,399.073059 312.315674,399.342712 
	C316.412445,399.401184 320.209412,399.187775 324.314819,398.957336 
	C325.034088,398.911499 325.444946,398.882721 326.130707,398.898010 
	C326.589783,398.954163 326.773926,398.966309 326.976868,399.380981 
	C327.053619,401.107574 327.111572,402.431641 327.169556,403.755737 
z"/>
<path fill="#012F8B" opacity="1.000000" stroke="none" 
	d="
M261.401886,431.007812 
	C263.093994,429.005859 265.158997,427.000916 267.575989,424.976624 
	C268.498383,429.640381 264.906738,430.088715 261.401886,431.007812 
z"/>
<path fill="#012F8B" opacity="1.000000" stroke="none" 
	d="
M277.079651,415.472168 
	C277.493683,414.510529 277.948456,413.833832 278.735596,413.135864 
	C279.061737,414.159882 279.055511,415.205170 279.049316,416.250427 
	C278.406342,416.085999 277.763367,415.921570 277.079651,415.472168 
z"/>
<path fill="#012F8B" opacity="1.000000" stroke="none" 
	d="
M293.780762,469.800171 
	C293.230011,470.038788 292.740936,469.986450 292.251831,469.934082 
	C292.363617,469.497559 292.475433,469.061005 292.587219,468.624451 
	C293.005615,468.919373 293.424011,469.214294 293.780762,469.800171 
z"/>
<path fill="#0350AC" opacity="1.000000" stroke="none" 
	d="
M273.251160,417.920074 
	C273.218781,417.765472 273.395538,417.491516 273.807312,417.130920 
	C273.914978,417.347717 273.720978,417.599884 273.251160,417.920074 
z"/>
<path fill="#01308D" opacity="1.000000" stroke="none" 
	d="
M246.040039,438.016174 
	C243.042725,441.438568 240.045395,444.860992 236.979721,448.619080 
	C236.716171,448.957947 236.520981,448.961090 236.030624,448.961823 
	C235.489685,448.157898 234.777390,447.023651 235.073425,446.608459 
	C237.086166,443.785706 239.311417,441.114532 242.137482,438.197449 
	C243.884644,438.005737 244.962341,438.010986 246.040039,438.016174 
z"/>
<path fill="#022A86" opacity="1.000000" stroke="none" 
	d="
M915.277283,326.795166 
	C916.176819,322.233124 916.831970,317.285767 918.844910,312.972015 
	C919.835999,310.847961 923.747192,308.248932 925.629883,308.715912 
	C931.785034,310.242493 933.582642,305.273987 936.926697,302.587219 
	C939.138733,300.809937 941.266724,298.928131 943.808533,297.097168 
	C943.477112,298.527161 943.142029,300.473083 941.999268,301.291626 
	C936.163391,305.472168 932.510498,312.907959 924.032715,313.323486 
	C922.994812,313.374298 921.214294,315.600922 921.232483,316.797119 
	C921.301819,321.356964 918.759827,324.124207 915.277283,326.795166 
z"/>
<path fill="#022A86" opacity="1.000000" stroke="none" 
	d="
M945.431580,296.104370 
	C947.481506,293.765900 949.885559,291.387451 952.657959,289.011597 
	C953.002014,289.450836 952.977844,289.887512 952.931152,290.651123 
	C950.534363,292.673523 948.160034,294.368958 945.431580,296.104370 
z"/>
<path fill="#0433B7" opacity="1.000000" stroke="none" 
	d="
M956.852173,305.336365 
	C960.834717,303.924988 963.937439,304.073090 963.998779,309.621277 
	C962.131470,310.853455 959.890259,312.797211 958.462402,312.317993 
	C955.479553,311.316833 955.441528,308.420807 956.852173,305.336365 
z"/>
<path fill="#02228D" opacity="1.000000" stroke="none" 
	d="
M931.075073,207.423279 
	C931.451660,206.718842 931.928406,206.384995 932.708679,206.023743 
	C934.682251,206.676147 936.352295,207.355942 938.069214,208.399719 
	C935.802490,208.440399 933.488831,208.117126 931.075073,207.423279 
z"/>
<path fill="#022A86" opacity="1.000000" stroke="none" 
	d="
M929.358887,275.992981 
	C931.121887,273.764832 933.253662,271.533478 935.695801,269.160370 
	C935.772461,273.016724 933.340027,275.034576 929.358887,275.992981 
z"/>
<path fill="#012A88" opacity="1.000000" stroke="none" 
	d="
M898.988647,296.980042 
	C898.646729,297.726257 898.304749,298.472473 897.718994,299.664368 
	C897.219177,300.320435 897.033875,300.581116 896.698547,300.900940 
	C896.477722,300.909790 896.035706,300.909943 895.982422,300.626099 
	C895.599548,300.046753 895.270020,299.751251 894.940491,299.455750 
	C894.620178,299.955383 894.299805,300.455048 893.615479,300.965942 
	C892.167969,301.325287 891.084473,301.673309 889.556030,302.012024 
	C881.132690,302.273499 873.154358,302.544250 865.017395,302.450439 
	C863.167114,301.726715 861.479553,301.080841 859.783081,301.056763 
	C851.193420,300.934784 842.601562,300.974365 834.008545,300.544922 
	C833.719421,298.378571 834.026855,295.636200 833.041138,295.043182 
	C828.968506,292.592957 830.348877,290.215393 832.215271,287.321350 
	C832.871399,286.303925 833.009888,284.780701 832.955627,283.506714 
	C832.710144,277.740662 832.564575,271.945801 831.770630,266.246643 
	C831.595215,264.987274 829.056152,263.770691 827.381165,263.072510 
	C824.734070,261.969208 821.460999,261.927368 819.245911,260.333923 
	C816.118286,258.083954 813.713684,254.828873 811.382935,252.009369 
	C813.498352,252.342850 815.230896,252.670410 817.009644,253.344849 
	C819.315735,257.829498 822.571411,260.674316 827.434692,260.958984 
	C835.298706,261.419220 843.174255,261.682831 851.037048,262.442139 
	C851.004089,264.233093 850.979004,265.611481 850.581421,266.944824 
	C844.862854,266.978149 839.516907,267.056458 834.170898,267.134796 
	C834.123718,267.973236 834.076538,268.811676 834.029419,269.650116 
	C836.016968,269.934875 838.076721,270.699738 839.978455,270.413727 
	C845.529602,269.578857 847.464966,271.028412 849.194946,276.129974 
	C850.137512,278.909363 852.217102,281.943817 854.657166,283.391724 
	C857.026184,284.797516 860.935425,286.546906 863.339233,281.964691 
	C864.769653,281.606232 865.859558,281.288788 867.335327,280.981079 
	C871.966797,279.960785 873.243347,277.657898 871.034119,274.596375 
	C872.792908,269.431610 870.592957,265.309387 869.235046,261.102997 
	C870.709839,261.074005 871.857605,261.045898 873.005432,261.017761 
	C875.367249,261.018372 877.729004,261.018982 880.628540,261.395691 
	C883.965515,263.425995 886.708801,264.484955 889.027771,261.032410 
	C899.382385,261.352692 909.737061,261.673004 920.825928,262.277100 
	C924.041077,262.702393 926.522095,262.843903 929.003052,262.985413 
	C930.125732,262.684418 931.248413,262.383423 932.725220,262.081543 
	C932.068542,263.529968 931.255432,265.181152 930.015076,266.396240 
	C921.168457,275.062805 912.227722,283.633240 903.342102,292.260101 
	C902.642395,292.939453 902.132935,293.814819 900.949341,294.842865 
	C899.905273,295.717834 899.446960,296.348938 898.988647,296.980042 
M894.705811,286.771149 
	C899.322144,286.040833 902.213562,283.035187 900.602539,279.852570 
	C899.853699,278.373230 896.770325,277.509552 894.725891,277.473938 
	C893.581177,277.454010 891.404236,279.740845 891.454773,280.912384 
	C891.538208,282.850403 893.046021,284.727081 894.705811,286.771149 
z"/>
<path fill="#01177C" opacity="1.000000" stroke="none" 
	d="
M851.044922,262.029480 
	C843.174255,261.682831 835.298706,261.419220 827.434692,260.958984 
	C822.571411,260.674316 819.315735,257.829498 817.081665,253.003067 
	C818.739929,252.207672 820.372437,252.100937 822.174011,252.311249 
	C824.622925,255.453949 827.550232,256.381805 831.143066,256.038879 
	C836.252319,255.551193 841.385010,255.112488 846.512390,255.052109 
	C858.671814,254.908905 870.834045,255.000732 882.995117,254.997559 
	C883.633850,255.447906 884.272583,255.898239 884.961304,256.664307 
	C879.851624,256.980042 874.691895,256.980042 869.532166,256.980042 
	C869.415771,257.485931 869.299377,257.991821 869.183044,258.497711 
	C870.309631,259.159454 871.436279,259.821228 872.784180,260.750366 
	C871.857605,261.045898 870.709839,261.074005 868.778198,261.083923 
	C862.344543,261.386963 856.694763,261.708221 851.044922,262.029480 
z"/>
<path fill="#01177C" opacity="1.000000" stroke="none" 
	d="
M928.915771,262.612061 
	C926.522095,262.843903 924.041077,262.702393 921.280029,262.275879 
	C923.609497,262.073486 926.218994,262.156097 928.915771,262.612061 
z"/>
<path fill="#01177C" opacity="1.000000" stroke="none" 
	d="
M888.580322,261.020996 
	C886.708801,264.484955 883.965515,263.425995 881.070801,261.398193 
	C883.361267,261.019592 885.747009,261.014587 888.580322,261.020996 
z"/>
<path fill="#01177C" opacity="1.000000" stroke="none" 
	d="
M894.363281,256.984589 
	C895.080933,256.322693 896.173523,255.666672 897.641602,255.017090 
	C896.924194,255.679169 895.831238,256.334808 894.363281,256.984589 
z"/>
<path fill="#0449D6" opacity="1.000000" stroke="none" 
	d="
M865.175964,302.815002 
	C873.154358,302.544250 881.132690,302.273499 889.824341,302.285095 
	C892.837585,302.666962 895.623413,304.617188 896.919373,300.892090 
	C897.033875,300.581116 897.219177,300.320435 897.673462,299.970032 
	C897.682495,301.366669 897.493286,302.903259 897.171265,304.755249 
	C895.553589,305.699524 894.080872,306.836426 892.581787,306.872253 
	C884.703308,307.060303 876.818115,306.969971 868.151489,306.835144 
	C865.911804,306.796844 864.455872,306.899628 862.531433,306.992401 
	C852.373779,306.992432 842.684631,307.002533 832.698547,306.719696 
	C826.639771,306.288910 820.874939,306.225311 815.117249,305.987915 
	C811.060852,305.820618 807.014221,305.417175 802.972900,304.764160 
	C802.988464,303.935699 802.994263,303.462219 803.468628,302.986023 
	C824.350220,302.927216 844.763062,302.871094 865.175964,302.815002 
z"/>
<path fill="#022D9A" opacity="1.000000" stroke="none" 
	d="
M899.308350,296.941406 
	C899.446960,296.348938 899.905273,295.717834 900.680542,295.042725 
	C900.541077,295.633453 900.084595,296.268127 899.308350,296.941406 
z"/>
<path fill="#012B87" opacity="1.000000" stroke="none" 
	d="
M883.092407,254.608154 
	C870.834045,255.000732 858.671814,254.908905 846.512390,255.052109 
	C841.385010,255.112488 836.252319,255.551193 831.143066,256.038879 
	C827.550232,256.381805 824.622925,255.453949 822.172485,251.936249 
	C821.999573,250.493912 821.997375,249.743591 822.332642,248.987915 
	C823.077576,248.332626 823.975464,247.289948 823.817444,247.092651 
	C821.944824,244.753952 819.914734,242.541351 817.096191,239.358749 
	C820.125366,239.195633 821.866455,239.021973 823.607666,239.020187 
	C846.428833,238.996857 869.250244,239.038071 892.070679,238.909027 
	C893.727173,238.899658 895.376892,237.697174 897.424988,237.042648 
	C898.872986,237.036697 899.925720,237.037521 900.981812,237.291061 
	C900.990051,237.712173 900.995056,237.880569 901.000000,238.048965 
	C898.914795,242.937286 894.614441,242.932693 890.265381,243.038177 
	C887.927979,243.094849 884.843628,242.070480 883.939270,246.667999 
	C886.562439,246.793396 888.870300,246.542252 890.952454,247.113525 
	C892.733337,247.602158 894.255432,249.033936 895.892029,250.048584 
	C894.311340,251.032104 892.844238,252.399017 891.123291,252.908920 
	C888.573242,253.664459 885.843201,253.812241 883.092407,254.608154 
z"/>
<path fill="#01319F" opacity="1.000000" stroke="none" 
	d="
M901.429688,238.035294 
	C900.995056,237.880569 900.990051,237.712173 901.302490,237.139771 
	C905.420410,233.491394 909.220825,230.247009 913.021179,227.002609 
	C915.771179,225.024506 918.521179,223.046417 921.613403,221.023544 
	C919.594849,223.856827 917.278015,226.772369 914.860107,229.601578 
	C912.773315,232.043335 910.861023,234.786285 908.316528,236.630157 
	C906.684814,237.812531 904.046448,237.605713 901.429688,238.035294 
z"/>
<path fill="#012276" opacity="1.000000" stroke="none" 
	d="
M956.004883,214.011612 
	C956.451172,214.009720 956.897461,214.007843 957.678711,214.008698 
	C957.732666,215.565018 957.871643,217.382462 957.092224,218.622818 
	C954.723083,222.393234 949.164429,223.690430 945.484802,221.369019 
	C950.271545,220.218002 955.226562,220.161224 956.004883,214.011612 
z"/>
<path fill="#012276" opacity="1.000000" stroke="none" 
	d="
M938.657471,213.003876 
	C939.100952,213.321823 939.291260,213.582504 939.786377,214.260498 
	C941.672729,216.825180 943.336365,218.912598 944.997559,221.001999 
	C940.905579,220.232056 936.567200,219.739639 936.991882,213.494354 
	C937.430237,213.069733 937.876221,213.038132 938.657471,213.003876 
z"/>
<path fill="#0639BE" opacity="1.000000" stroke="none" 
	d="
M912.613159,227.000702 
	C909.220825,230.247009 905.420410,233.491394 901.299255,236.887054 
	C899.925720,237.037521 898.872986,237.036697 896.955750,237.021851 
	C891.064087,236.689423 886.036865,236.371033 881.007935,235.671844 
	C881.001343,234.529694 880.996399,233.768341 881.458008,233.003021 
	C885.332825,232.998016 888.754456,233.169952 892.142761,232.909714 
	C893.473999,232.807465 894.731018,231.738525 896.021790,231.109833 
	C897.075745,231.101273 898.129700,231.092697 899.645813,231.411957 
	C901.740662,231.166428 903.373413,230.593018 905.006104,230.019623 
	C906.131714,229.096954 907.257263,228.174301 908.658936,227.108047 
	C910.025085,226.975906 911.115173,226.987350 912.613159,227.000702 
z"/>
<path fill="#011773" opacity="1.000000" stroke="none" 
	d="
M945.406372,220.999268 
	C943.336365,218.912598 941.672729,216.825180 940.003357,214.374603 
	C945.021729,214.007690 950.045898,214.003967 955.537476,214.005920 
	C955.226562,220.161224 950.271545,220.218002 945.406372,220.999268 
z"/>
<path fill="#02257F" opacity="1.000000" stroke="none" 
	d="
M226.942856,416.970886 
	C228.024750,416.980560 229.106644,416.990265 230.594269,416.999969 
	C231.456543,418.162170 231.913086,419.324310 232.369614,420.486481 
	C238.040115,417.880737 238.444489,425.382446 242.712646,425.468262 
	C242.986984,425.950439 243.261322,426.432587 243.535660,426.914734 
	C241.533920,428.630737 239.514587,430.326782 237.536652,432.069763 
	C236.222977,433.227417 234.796188,434.317505 233.729980,435.680298 
	C232.307281,437.498718 231.407654,439.762390 229.843155,441.419098 
	C229.024841,442.285706 227.265305,442.263489 225.930847,442.642700 
	C225.673920,441.421143 224.802139,439.839600 225.269180,439.041931 
	C227.049011,436.002075 228.879013,432.804657 231.415344,430.445770 
	C234.102554,427.946594 234.422562,426.399963 231.127182,424.620728 
	C229.152145,423.554413 227.108719,422.614777 225.029480,420.897369 
	C225.622879,419.108002 226.282867,418.039429 226.942856,416.970886 
z"/>
<path fill="#0137AC" opacity="1.000000" stroke="none" 
	d="
M227.108276,416.622803 
	C226.282867,418.039429 225.622879,419.108002 224.960220,420.539734 
	C224.522934,420.921265 224.088318,420.939545 222.884552,420.963196 
	C219.766571,420.966888 217.417755,420.965271 215.068924,420.963654 
	C214.742020,419.704132 214.610825,418.349976 214.055237,417.201019 
	C211.240677,411.380554 211.328613,408.087677 216.254120,404.341370 
	C217.984482,403.025299 221.907684,403.681213 224.406082,404.594849 
	C226.176620,405.242340 227.973907,407.668488 228.410172,409.604645 
	C228.868622,411.639343 227.720032,414.036163 227.108276,416.622803 
M223.041885,412.526733 
	C221.925720,411.186920 220.809555,409.847076 219.693390,408.507233 
	C218.809326,409.388550 217.369781,410.167389 217.183365,411.177460 
	C216.956055,412.409149 217.781998,413.835266 218.152374,415.177307 
	C219.755478,414.545013 221.358582,413.912720 223.041885,412.526733 
z"/>
<path fill="#02257F" opacity="1.000000" stroke="none" 
	d="
M214.801056,420.977142 
	C217.417755,420.965271 219.766571,420.966888 222.558014,420.972870 
	C218.992905,423.979340 216.306503,429.115784 210.420013,429.916199 
	C211.866745,426.643829 212.932678,424.232819 213.997787,421.412720 
	C214.175705,420.999298 214.354446,420.994965 214.801056,420.977142 
z"/>
<path fill="#01308D" opacity="1.000000" stroke="none" 
	d="
M257.651428,426.008301 
	C255.241959,428.657593 252.472031,431.304260 249.360687,433.994080 
	C249.582718,432.888214 250.146179,431.739197 250.855438,430.296326 
	C253.097824,428.670197 255.194412,427.337952 257.651428,426.008301 
z"/>
<path fill="#012880" opacity="1.000000" stroke="none" 
	d="
M246.273026,437.835938 
	C244.962341,438.010986 243.884644,438.005737 242.403458,438.000244 
	C243.166534,436.463135 244.333084,434.926270 245.499649,433.389435 
	C245.996704,434.025574 246.493744,434.661713 246.995636,435.646301 
	C246.835648,436.548401 246.670822,437.102051 246.273026,437.835938 
z"/>
<path fill="#14A1D9" opacity="1.000000" stroke="none" 
	d="
M206.484192,408.961334 
	C206.682678,409.445953 206.435501,409.940674 206.083527,410.693024 
	C204.594818,410.980682 203.210922,411.010742 201.411255,411.039490 
	C200.923630,410.665863 200.851776,410.293518 200.682709,409.656311 
	C202.403168,409.251465 204.220840,409.111420 206.484192,408.961334 
z"/>
<path fill="#01247B" opacity="1.000000" stroke="none" 
	d="
M215.654968,465.011902 
	C214.902924,465.988403 213.785110,466.966278 212.339813,467.977844 
	C213.104599,467.012115 214.196884,466.012726 215.654968,465.011902 
z"/>
<path fill="#14A1D9" opacity="1.000000" stroke="none" 
	d="
M199.785370,408.836090 
	C199.475616,409.169861 199.056442,409.123535 198.323288,409.033905 
	C197.815033,406.823669 197.620758,404.656738 197.690781,402.235962 
	C198.528717,404.140076 199.102325,406.298065 199.785370,408.836090 
z"/>
<path fill="#14A1D9" opacity="1.000000" stroke="none" 
	d="
M208.644073,404.206696 
	C208.470062,405.539032 208.044281,407.095764 207.284943,408.792786 
	C207.431686,407.432434 207.912003,405.931732 208.644073,404.206696 
z"/>
<path fill="#013290" opacity="1.000000" stroke="none" 
	d="
M321.059479,252.287140 
	C323.802216,253.914368 326.815369,255.634491 329.276886,257.942871 
	C334.946350,263.259674 340.496399,268.723633 345.798645,274.404358 
	C348.691193,277.503387 351.714111,279.641510 356.394165,279.695129 
	C356.091858,280.403748 355.487000,280.696320 354.882141,280.988861 
	C353.100098,281.652710 351.315613,282.310028 349.536621,282.981995 
	C346.893463,283.980408 344.274689,285.762939 341.609070,285.833527 
	C335.952271,285.983337 330.271912,285.243835 323.977936,284.827759 
	C324.255524,282.802368 324.467072,281.258850 324.835907,279.360413 
	C327.108643,279.318298 329.224091,279.631073 332.380798,280.097809 
	C330.585083,277.226685 329.406250,275.341888 328.552124,273.976227 
	C323.977722,273.976227 319.335785,275.090576 315.702057,273.654297 
	C312.256042,272.292236 309.688965,268.404846 307.123871,265.306366 
	C306.389160,264.418884 306.992340,262.413910 307.002167,260.921478 
	C307.043976,254.564514 308.704865,253.698593 314.443176,256.563171 
	C316.022125,257.351379 318.290039,257.660095 319.889374,257.128479 
	C320.693665,256.861145 320.707855,254.216690 321.059479,252.287140 
z"/>
<path fill="#F3F8F9" opacity="1.000000" stroke="none" 
	d="
M354.719208,281.273895 
	C355.487000,280.696320 356.091858,280.403748 356.859497,279.803101 
	C358.819885,278.662384 360.617462,277.829803 363.037537,276.763794 
	C375.335663,272.043732 386.851685,272.464478 398.358337,277.897430 
	C400.366333,279.766663 402.228577,281.393036 404.097809,283.374603 
	C404.720703,285.499390 405.336578,287.268951 405.923950,289.455139 
	C405.964142,291.260529 406.032898,292.649323 405.892761,294.320068 
	C403.194641,298.228729 400.705383,301.855469 398.120789,305.471405 
	C398.025421,305.460632 397.862366,305.561890 397.424622,305.596680 
	C387.632629,307.191986 384.026917,304.996246 379.374573,294.492035 
	C379.492554,291.023285 379.630188,287.980804 379.804810,284.121216 
	C376.894653,284.593872 374.121155,284.682007 371.602875,285.528961 
	C366.392670,287.281250 366.012634,289.824921 370.122253,294.258057 
	C377.084290,300.446381 383.948669,306.311432 390.891907,312.125610 
	C390.970764,312.074768 390.816681,312.166656 390.888062,312.419006 
	C391.264984,312.807373 391.570526,312.943329 392.008179,313.341003 
	C392.752258,314.041626 393.364166,314.480560 394.027557,315.082336 
	C394.079010,315.245209 394.420624,315.248322 394.535278,315.580994 
	C396.929108,318.540771 399.654236,320.903015 401.401825,323.845764 
	C406.159882,331.857941 403.592987,343.021606 394.965637,348.953949 
	C389.070557,353.007507 382.209412,355.656097 375.346924,358.908997 
	C371.265594,358.780426 367.617554,358.675720 363.614044,358.443787 
	C362.505859,358.301788 361.753174,358.287018 361.000458,358.272278 
	C360.577423,358.117371 360.154358,357.962494 359.081970,357.827393 
	C357.995300,357.867371 357.558014,357.887512 356.968140,357.585083 
	C348.892883,354.338959 341.244965,351.047241 337.334778,342.730469 
	C337.325195,342.727509 337.357300,342.716278 337.358276,342.283020 
	C337.257660,339.234436 337.156067,336.619141 337.054504,334.003815 
	C337.228363,333.457947 337.402252,332.912048 337.831177,331.854584 
	C340.703735,328.561462 343.321228,325.779938 345.938751,322.998413 
	C346.403595,322.965698 346.868469,322.932983 348.076477,322.817505 
	C361.948730,322.815247 367.474609,331.922913 362.983551,348.391968 
	C368.478088,347.381226 373.525848,347.049530 378.104248,345.408478 
	C381.360199,344.241425 382.053650,340.809875 379.211884,338.112244 
	C373.001984,332.217438 366.582672,326.543243 360.149597,320.895935 
	C360.050262,321.012909 360.226105,320.765564 360.111206,320.453125 
	C358.354523,319.045227 356.712769,317.949829 355.071014,316.854431 
	C355.071014,316.854431 354.772064,316.656311 354.504974,316.409851 
	C353.904449,315.969391 353.571045,315.775391 353.237610,315.581421 
	C352.876648,314.719543 352.515686,313.857697 351.731506,312.613037 
	C350.908173,312.043427 350.508057,311.856537 350.107941,311.669678 
	C350.107941,311.669678 350.322327,311.648804 350.361389,311.547546 
	C348.587311,306.901459 346.774170,302.356628 344.878510,297.398956 
	C346.064026,290.587616 349.158112,285.344421 354.719208,281.273895 
z"/>
<path fill="#2B4B89" opacity="1.000000" stroke="none" 
	d="
M355.092651,317.232605 
	C356.712769,317.949829 358.354523,319.045227 359.751282,320.532227 
	C358.771759,321.239838 358.037170,321.555817 357.302582,321.871796 
	C357.549652,322.232971 357.796692,322.594147 358.043732,322.955353 
	C358.778778,322.229858 359.513855,321.504395 360.248901,320.778931 
	C366.582672,326.543243 373.001984,332.217438 379.211884,338.112244 
	C382.053650,340.809875 381.360199,344.241425 378.104248,345.408478 
	C373.525848,347.049530 368.478088,347.381226 362.983551,348.391968 
	C367.474609,331.922913 361.948730,322.815247 348.392517,322.659973 
	C349.685760,322.075104 351.391998,321.203674 353.129669,321.135712 
	C356.078979,321.020386 355.852783,319.567474 355.092651,317.232605 
M365.222626,330.592865 
	C366.113678,335.630188 367.004730,340.667511 367.901520,345.737396 
	C370.165100,345.469330 372.321381,345.424744 374.365509,344.923157 
	C377.542023,344.143707 379.269257,341.859650 376.967957,339.131287 
	C373.042999,334.478058 368.504578,330.342224 364.223846,325.989044 
	C363.898254,326.231812 363.572693,326.474579 363.247101,326.717316 
	C363.797241,327.786652 364.347382,328.855988 365.222626,330.592865 
z"/>
<path fill="#2B4B89" opacity="1.000000" stroke="none" 
	d="
M345.545227,322.961487 
	C343.321228,325.779938 340.703735,328.561462 337.825165,331.566650 
	C337.129730,326.147064 339.338135,323.566620 345.545227,322.961487 
z"/>
<path fill="#2B4B89" opacity="1.000000" stroke="none" 
	d="
M336.834717,334.114136 
	C337.156067,336.619141 337.257660,339.234436 337.023010,342.159576 
	C336.443939,341.308197 336.201080,340.147034 335.958252,338.985840 
	C335.973816,337.924194 335.989410,336.862579 336.019318,335.403198 
	C336.156250,334.692139 336.350037,334.431824 336.834717,334.114136 
z"/>
<path fill="#2B4B89" opacity="1.000000" stroke="none" 
	d="
M353.152588,315.876892 
	C353.571045,315.775391 353.904449,315.969391 354.329132,316.445038 
	C354.177094,316.756714 353.933838,316.786682 353.323059,316.796783 
	C352.992889,316.575378 353.030243,316.373840 353.152588,315.876892 
z"/>
<path fill="#04308B" opacity="1.000000" stroke="none" 
	d="
M291.005463,309.526184 
	C290.969116,310.658020 290.934601,311.332825 290.846558,312.335388 
	C288.991364,312.103668 287.201019,311.109680 285.385925,311.062134 
	C278.591156,310.884216 271.788269,311.015869 264.529572,310.987732 
	C265.726562,306.588776 267.382416,302.233521 269.064911,297.478333 
	C270.395355,295.640076 271.699158,294.201813 273.031738,292.412048 
	C277.800873,292.047882 282.605927,291.555634 287.259888,292.183380 
	C290.620728,292.636688 292.500641,295.040771 291.566193,299.156342 
	C290.843597,302.338867 291.155365,305.756195 291.005463,309.526184 
z"/>
<path fill="#0B76EC" opacity="1.000000" stroke="none" 
	d="
M288.944824,316.955811 
	C288.249451,318.098450 287.769073,319.407715 286.896515,320.356201 
	C285.674561,321.684418 284.247772,323.491547 282.742096,323.667297 
	C281.657196,323.793945 279.956970,321.791748 279.148926,320.384430 
	C278.038605,318.450653 277.512146,316.181641 276.716797,314.005066 
	C281.758026,311.716736 287.155426,312.543030 288.919342,316.503021 
	C288.937378,316.894775 288.984833,316.961487 288.944824,316.955811 
z"/>
<path fill="#023290" opacity="1.000000" stroke="none" 
	d="
M350.240173,311.981628 
	C350.508057,311.856537 350.908173,312.043427 351.435699,312.559204 
	C351.166199,312.689911 350.769287,312.491730 350.240173,311.981628 
z"/>
<path fill="#08348D" opacity="1.000000" stroke="none" 
	d="
M335.613770,339.054932 
	C336.201080,340.147034 336.443939,341.308197 337.022034,342.592834 
	C337.357300,342.716278 337.325195,342.727509 337.223206,343.126160 
	C337.443268,345.341125 337.765320,347.157501 338.087372,348.973846 
	C335.596130,350.346832 334.075439,348.900085 332.338074,347.052155 
	C333.091919,344.370789 334.180634,341.747406 335.613770,339.054932 
z"/>
<path fill="#01308D" opacity="1.000000" stroke="none" 
	d="
M312.015869,399.070862 
	C303.992706,399.073059 295.969604,399.075287 287.179260,399.099365 
	C285.917175,399.725372 285.422272,400.329559 284.927368,400.933716 
	C281.875763,403.298737 278.824158,405.663757 275.437256,408.109497 
	C277.250977,403.875916 278.999603,399.282959 281.723816,395.369110 
	C283.017303,393.510773 286.934937,393.516113 288.310211,391.669220 
	C290.543793,388.669739 292.172852,388.993042 294.870636,390.634705 
	C295.645996,391.106537 297.425781,389.927856 299.240845,389.205078 
	C300.494110,388.619720 301.251556,388.335236 302.382874,388.033112 
	C303.505219,387.985718 304.253693,387.955933 305.196167,388.181274 
	C310.362000,386.184174 315.293732,384.051422 321.048645,384.832062 
	C322.950073,385.089966 325.018616,384.115845 327.448792,383.723297 
	C329.931641,383.846893 331.976471,383.952240 334.026886,384.477081 
	C333.659424,388.971954 333.286377,393.047302 332.530457,397.101379 
	C331.136322,396.941742 330.125061,396.803314 329.025635,396.378235 
	C325.823700,394.684692 326.227631,397.202026 325.855804,398.853943 
	C325.444946,398.882721 325.034088,398.911499 324.045105,398.695923 
	C320.661407,398.055206 317.577332,398.116577 316.909424,394.311096 
	C316.729340,393.284973 316.464752,391.408417 316.212860,391.403992 
	C314.828888,391.379669 313.230133,391.503540 312.136627,392.227905 
	C311.607025,392.578705 312.013275,394.352539 312.008331,395.482147 
	C312.003113,396.678345 312.012726,397.874634 312.015869,399.070862 
z"/>
<path fill="#01308D" opacity="1.000000" stroke="none" 
	d="
M274.627747,408.999634 
	C269.533691,414.576904 264.075439,420.156586 258.314026,425.873962 
	C258.191711,425.048645 258.029999,423.718842 258.606995,423.179962 
	C263.751709,418.374603 269.026855,413.708893 274.627747,408.999634 
z"/>
<path fill="#065EAF" opacity="1.000000" stroke="none" 
	d="
M302.009033,388.050751 
	C301.251556,388.335236 300.494110,388.619720 299.416016,388.878723 
	C298.576782,388.307495 298.058136,387.761719 297.278625,387.095886 
	C297.023529,386.515381 297.029297,386.054871 297.379761,385.270752 
	C298.142120,384.959747 298.559784,384.972382 299.247131,385.149109 
	C300.271637,385.674713 301.026428,386.036285 301.809631,386.674896 
	C301.895050,387.318207 301.952026,387.684479 302.009033,388.050751 
z"/>
<path fill="#01379A" opacity="1.000000" stroke="none" 
	d="
M353.972473,378.678406 
	C353.999390,377.035156 353.681122,375.282959 354.154968,373.780670 
	C354.587402,372.409668 355.789032,370.346680 356.805481,370.252716 
	C361.786713,369.792297 366.834564,370.085236 371.827209,369.700714 
	C374.208160,369.517365 376.518707,368.419617 379.268860,367.364746 
	C380.451874,366.660095 381.227142,366.326782 382.407867,365.992859 
	C387.003448,365.705750 385.711273,368.882294 386.081970,371.405518 
	C388.065796,373.452942 389.809296,374.060303 390.995911,370.621552 
	C391.441315,366.564697 393.732758,365.710632 397.016815,366.143982 
	C399.782288,366.508850 402.575226,366.972656 405.347778,366.923889 
	C418.230713,366.697449 431.110718,366.307892 444.262512,366.260193 
	C450.270294,368.559967 450.208649,364.855133 450.299438,360.840790 
	C452.905243,358.330383 456.893219,357.032135 455.929626,352.253418 
	C458.373505,350.329681 459.046997,351.020325 458.638336,353.565918 
	C457.387390,361.357391 456.167908,369.153931 454.679749,376.723724 
	C453.524078,375.867767 452.626465,374.693451 451.725403,374.690796 
	C444.135559,374.668427 436.544434,374.954224 428.955444,374.900574 
	C427.608612,374.891052 425.833008,374.086121 425.005920,373.049622 
	C422.049774,369.345093 417.157410,367.342987 412.367767,369.213287 
	C408.326599,370.791290 406.618073,374.378143 406.607147,378.865204 
	C388.748932,378.841858 371.360718,378.760132 353.972473,378.678406 
z"/>
<path fill="#0E8ED8" opacity="1.000000" stroke="none" 
	d="
M353.512695,378.761719 
	C371.360718,378.760132 388.748932,378.841858 406.579895,378.935394 
	C407.022644,378.947205 406.950562,379.075775 406.945923,379.456116 
	C404.959259,380.894775 402.977203,381.953064 400.527039,383.010986 
	C392.378723,383.028961 384.698517,383.047333 376.768250,382.788086 
	C374.653870,382.925446 372.789520,383.340393 370.525574,383.752686 
	C363.752502,383.819092 357.379120,383.888184 350.662964,383.930603 
	C349.867889,383.966522 349.415558,384.029053 348.506439,384.039490 
	C344.358368,383.820282 340.667114,383.653168 336.966797,383.120300 
	C336.960907,381.541565 336.964081,380.328613 336.967285,379.115631 
	C342.329163,379.025421 347.691010,378.935242 353.512695,378.761719 
z"/>
<path fill="#13B0DF" opacity="1.000000" stroke="none" 
	d="
M336.503662,379.065369 
	C336.964081,380.328613 336.960907,381.541565 336.609314,383.242188 
	C335.514374,383.839081 334.767822,383.948334 334.021271,384.057556 
	C331.976471,383.952240 329.931641,383.846893 327.111572,383.497131 
	C319.931549,383.386230 313.298248,381.355927 307.201172,385.181915 
	C307.110168,385.099670 306.932800,384.930145 307.004120,384.597534 
	C308.973297,381.904419 309.203369,379.777771 306.448181,377.790100 
	C305.752747,377.288391 305.475861,376.206482 304.982056,375.155670 
	C304.957458,374.920166 304.954529,374.446564 304.954529,374.446564 
	C311.539978,380.736969 319.804840,378.654388 327.580872,378.956787 
	C330.397095,379.066345 333.220123,379.000793 336.503662,379.065369 
z"/>
<path fill="#03429E" opacity="1.000000" stroke="none" 
	d="
M382.002441,365.993469 
	C381.227142,366.326782 380.451874,366.660095 379.339508,366.997314 
	C376.526459,366.860199 374.050446,366.719177 371.291473,366.300171 
	C371.027405,365.631104 371.046356,365.240051 371.094849,364.561035 
	C374.405914,364.254425 377.687378,364.235779 381.451294,364.539185 
	C381.956665,365.238647 381.979553,365.616058 382.002441,365.993469 
z"/>
<path fill="#13B0DF" opacity="1.000000" stroke="none" 
	d="
M298.977448,384.985046 
	C298.559784,384.972382 298.142120,384.959747 297.412231,384.926666 
	C296.998169,382.527740 296.896393,380.149261 297.246094,377.407532 
	C298.090179,376.977295 298.482819,376.910278 298.960938,376.937866 
	C299.046387,377.032471 299.220764,377.218506 299.146759,377.635773 
	C299.040985,380.363708 299.009216,382.674377 298.977448,384.985046 
z"/>
<path fill="#0350AC" opacity="1.000000" stroke="none" 
	d="
M300.947784,374.568237 
	C302.233063,374.343750 303.518311,374.119293 304.879059,374.170685 
	C304.954529,374.446564 304.957458,374.920166 304.628967,375.042175 
	C303.565704,375.179138 302.830994,375.194122 301.804871,375.056519 
	C301.324890,374.792023 301.136322,374.680115 300.947784,374.568237 
z"/>
<path fill="#13B0DF" opacity="1.000000" stroke="none" 
	d="
M300.675690,374.685181 
	C301.136322,374.680115 301.324890,374.792023 301.721710,375.362488 
	C301.026886,376.286835 300.123810,376.752655 299.220764,377.218506 
	C299.220764,377.218506 299.046387,377.032471 299.099487,376.706909 
	C299.377075,375.898956 299.601593,375.416534 299.826111,374.934113 
	C300.018616,374.890106 300.211121,374.846100 300.675690,374.685181 
z"/>
<path fill="#0350AC" opacity="1.000000" stroke="none" 
	d="
M299.485077,374.945312 
	C299.601593,375.416534 299.377075,375.898956 299.014008,376.612305 
	C298.482819,376.910278 298.090179,376.977295 297.400543,377.069977 
	C297.783722,376.382629 298.463867,375.669586 299.485077,374.945312 
z"/>
<path fill="#0870B6" opacity="1.000000" stroke="none" 
	d="
M363.969513,358.571014 
	C367.617554,358.675720 371.265594,358.780426 375.829407,358.937500 
	C392.930359,358.953613 409.115601,358.917328 425.691772,358.849243 
	C428.403015,358.814789 430.764526,359.090454 433.035248,358.753784 
	C438.375671,357.961914 443.672760,356.877563 448.971161,356.263336 
	C448.968842,357.084259 448.982727,357.553406 448.995331,358.010925 
	C448.994049,357.999237 449.017792,357.996399 449.017792,357.996399 
	C436.109619,363.478394 422.545593,360.479004 409.281281,360.852875 
	C394.412964,361.271942 379.518860,360.776886 364.307434,360.158661 
	C363.975769,359.282196 363.972626,358.926605 363.969513,358.571014 
z"/>
<path fill="#0364B6" opacity="1.000000" stroke="none" 
	d="
M382.407867,365.992859 
	C381.979553,365.616058 381.956665,365.238647 381.896484,364.583588 
	C400.687195,364.204651 419.515411,364.132080 438.342804,363.964630 
	C440.625641,363.944336 442.903931,363.412018 445.562683,363.382690 
	C445.291290,364.424469 444.641541,365.200592 443.991791,365.976715 
	C431.110718,366.307892 418.230713,366.697449 405.347778,366.923889 
	C402.575226,366.972656 399.782288,366.508850 397.016815,366.143982 
	C393.732758,365.710632 391.441315,366.564697 390.581604,370.623352 
	C388.774353,371.025452 387.392395,371.039551 386.010437,371.053619 
	C385.711273,368.882294 387.003448,365.705750 382.407867,365.992859 
z"/>
<path fill="#0364B6" opacity="1.000000" stroke="none" 
	d="
M455.695862,352.501923 
	C456.893219,357.032135 452.905243,358.330383 450.017273,360.827087 
	C449.247284,360.955383 449.060150,360.934113 448.872986,360.912872 
	C448.927521,360.184326 448.982025,359.455750 449.027161,358.361816 
	C449.017792,357.996399 448.994049,357.999237 449.334717,357.960815 
	C450.116180,356.977570 450.557037,356.032776 451.261963,354.877441 
	C452.915985,353.945251 454.305908,353.223602 455.695862,352.501923 
z"/>
<path fill="#0641A6" opacity="1.000000" stroke="none" 
	d="
M448.699493,361.153442 
	C449.060150,360.934113 449.247284,360.955383 449.716614,360.990356 
	C450.208649,364.855133 450.270294,368.559967 444.262512,366.260193 
	C444.641541,365.200592 445.291290,364.424469 445.979675,363.340240 
	C446.854156,362.486115 447.690094,361.940063 448.699493,361.153442 
z"/>
<path fill="#023290" opacity="1.000000" stroke="none" 
	d="
M363.614044,358.443787 
	C363.972626,358.926605 363.975769,359.282196 363.976685,359.906921 
	C363.235962,360.155548 362.497437,360.135040 361.390289,360.142456 
	C361.027344,359.748230 361.032990,359.326050 361.019562,358.588074 
	C361.753174,358.287018 362.505859,358.301788 363.614044,358.443787 
z"/>
<path fill="#2B4B89" opacity="1.000000" stroke="none" 
	d="
M338.279114,349.238953 
	C337.765320,347.157501 337.443268,345.341125 337.232819,343.129089 
	C341.244965,351.047241 348.892883,354.338959 356.656586,357.642700 
	C355.968018,358.333008 355.438477,358.643158 354.908905,358.953247 
	C354.507172,358.958252 354.105469,358.963257 353.404846,358.953766 
	C351.677856,358.061523 350.230591,357.213257 348.825470,356.300232 
	C345.363617,354.050659 341.921112,351.771393 338.279114,349.238953 
z"/>
<path fill="#023290" opacity="1.000000" stroke="none" 
	d="
M355.287109,358.978973 
	C355.438477,358.643158 355.968018,358.333008 356.809143,357.965271 
	C357.558014,357.887512 357.995300,357.867371 358.751526,357.930786 
	C357.935425,358.344452 356.800385,358.674561 355.287109,358.978973 
z"/>
<path fill="#0BC4F6" opacity="1.000000" stroke="none" 
	d="
M265.127930,173.480453 
	C267.648438,172.818985 269.887604,172.446640 272.522034,172.061798 
	C272.917297,172.049332 272.945557,172.057434 272.989258,172.391205 
	C273.715515,173.113693 274.398071,173.502396 275.080627,173.891098 
	C275.080627,173.891098 275.087616,173.911911 275.098846,173.916962 
	C276.332275,179.474442 274.503418,182.057404 268.284180,183.122681 
	C267.257904,183.093201 266.613007,182.964737 265.644531,182.803253 
	C265.162811,179.770020 265.004730,176.769791 265.127930,173.480453 
z"/>
<path fill="#0E76D3" opacity="1.000000" stroke="none" 
	d="
M275.035980,173.572296 
	C274.398071,173.502396 273.715515,173.113693 272.993011,172.403549 
	C273.632477,172.472565 274.311920,172.863022 275.035980,173.572296 
z"/>
<path fill="#35BDFA" opacity="1.000000" stroke="none" 
	d="
M444.798462,215.565582 
	C442.849091,215.414062 440.890106,215.360046 439.592163,214.406372 
	C439.097931,214.043213 439.774109,211.368134 440.587189,210.222412 
	C441.053070,209.565887 443.820343,209.521774 443.916168,209.788818 
	C444.543884,211.538040 444.725128,213.447510 444.798462,215.565582 
z"/>
<path fill="#023497" opacity="1.000000" stroke="none" 
	d="
M398.212555,277.654572 
	C386.851685,272.464478 375.335663,272.043732 363.233582,276.537140 
	C363.278534,275.532898 363.749847,274.521912 364.744415,272.388641 
	C353.926178,276.233734 349.148499,268.569366 343.369171,262.883911 
	C338.932007,258.518829 334.514648,254.133606 330.012817,249.435272 
	C337.316925,249.074265 344.696777,249.035339 352.547211,249.002258 
	C353.752899,249.004028 354.487976,248.999939 355.589539,249.003220 
	C373.319794,249.002350 390.683533,248.994125 408.513672,248.980804 
	C413.013000,248.988922 417.045868,249.002121 421.439697,249.412766 
	C419.805969,250.206863 417.819489,250.887756 415.815033,250.945557 
	C410.324127,251.103897 404.825623,251.000137 399.483368,251.000137 
	C399.330170,252.409027 399.210602,252.747787 399.272614,253.049194 
	C400.919769,261.053986 400.684998,261.039764 394.548340,265.645721 
	C393.217560,266.644562 393.384033,269.768951 393.165344,271.944946 
	C393.139404,272.202789 395.046997,272.654999 396.088898,273.379333 
	C396.817169,275.045898 397.514862,276.350250 398.212555,277.654572 
z"/>
<path fill="#013FA8" opacity="1.000000" stroke="none" 
	d="
M504.524780,249.019287 
	C503.223602,251.881546 501.761414,254.997375 499.615845,257.535492 
	C496.121338,261.669342 492.158447,265.405212 488.460541,269.371857 
	C488.075562,269.784851 488.144836,270.621277 487.530029,271.632812 
	C481.748322,272.373871 476.439911,272.743378 471.118469,273.109558 
	C471.105438,273.106293 471.109955,273.129272 470.715057,273.096771 
	C469.550964,273.744507 468.241333,274.332703 468.107056,275.121063 
	C467.553558,278.371246 467.356659,281.682129 466.645996,285.144531 
	C461.469391,285.543610 456.672577,285.720978 451.882355,286.007904 
	C447.350311,286.279388 446.534058,284.136658 447.346008,280.089020 
	C447.797119,277.840088 447.151031,275.371124 446.999420,272.750671 
	C446.999237,272.332977 446.999054,272.165924 447.064484,271.641663 
	C447.743713,270.163208 448.171722,268.875000 449.003601,267.950073 
	C452.300720,264.284180 455.131165,258.965485 459.250214,257.582764 
	C464.448914,255.837616 465.041351,253.185928 465.013428,249.024933 
	C478.027924,249.021088 491.042419,249.017242 504.524780,249.019287 
z"/>
<path fill="#003CB2" opacity="1.000000" stroke="none" 
	d="
M464.558014,249.012939 
	C465.041351,253.185928 464.448914,255.837616 459.250214,257.582764 
	C455.131165,258.965485 452.300720,264.284180 449.003601,267.950073 
	C448.171722,268.875000 447.743713,270.163208 446.687378,271.641632 
	C445.490387,272.005951 444.736145,272.013123 443.546448,272.017639 
	C441.076019,272.006165 439.041077,271.997345 437.004486,271.589111 
	C438.213379,268.858246 439.032501,266.185822 440.709595,264.260803 
	C445.281189,259.013336 450.215149,254.081512 455.005554,249.024658 
	C455.005585,249.024658 455.003357,249.009949 455.000275,249.003052 
	C458.032318,248.997742 461.067474,248.999344 464.558014,249.012939 
z"/>
<path fill="#0239A0" opacity="1.000000" stroke="none" 
	d="
M454.536499,249.014008 
	C450.215149,254.081512 445.281189,259.013336 440.709595,264.260803 
	C439.032501,266.185822 438.213379,268.858246 436.536469,271.595917 
	C430.720062,272.349823 425.370087,272.697571 419.919769,273.177979 
	C419.819458,273.310638 419.492615,273.248840 419.199280,273.275574 
	C418.599823,273.535004 418.293701,273.767670 417.653625,273.856445 
	C410.263611,272.531311 403.063232,275.762146 396.058319,273.017090 
	C395.046997,272.654999 393.139404,272.202789 393.165344,271.944946 
	C393.384033,269.768951 393.217560,266.644562 394.548340,265.645721 
	C400.684998,261.039764 400.919769,261.053986 399.272614,253.049194 
	C399.210602,252.747787 399.330170,252.409027 399.483368,251.000137 
	C404.825623,251.000137 410.324127,251.103897 415.815033,250.945557 
	C417.819489,250.887756 419.805969,250.206863 421.903229,249.421722 
	C432.693024,249.023285 443.380249,249.013321 454.536499,249.014008 
z"/>
<path fill="#02389F" opacity="1.000000" stroke="none" 
	d="
M734.714355,230.126343 
	C735.551025,230.568024 736.698120,231.461182 736.582886,231.675354 
	C733.400574,237.591721 738.219849,243.231537 737.095886,249.121567 
	C736.444580,252.534042 736.910217,255.864059 739.849731,258.727081 
	C741.063538,259.909241 740.771545,262.736420 740.972290,264.833832 
	C741.207397,267.290436 741.233154,269.767059 741.362610,272.509064 
	C739.484802,272.904755 737.482666,273.326660 734.425415,273.970886 
	C740.295166,281.574524 745.608643,288.457611 750.611572,295.645447 
	C747.856140,293.640259 745.411316,291.330322 742.940186,288.674835 
	C740.937744,286.560120 738.961548,284.790985 736.729736,282.884766 
	C735.981873,282.166077 735.489624,281.584473 734.962280,280.658691 
	C732.285706,277.875641 729.644043,275.436768 727.001221,272.998962 
	C727.000000,273.000000 727.002441,272.997620 726.964722,272.654510 
	C724.954895,270.536346 722.982727,268.761261 720.881653,266.726440 
	C720.172485,265.974243 719.592224,265.481781 718.968018,264.637878 
	C716.935425,260.648285 713.884644,258.950226 709.724609,258.998108 
	C703.482300,259.070007 697.238342,259.010803 690.995300,258.629028 
	C690.277405,257.362396 689.610779,255.767670 688.832214,255.711014 
	C685.841003,255.493378 682.790100,256.014252 679.813477,255.724686 
	C678.794495,255.625549 677.928223,253.956390 677.316772,253.001083 
	C678.413513,252.718994 679.185547,252.440338 679.957642,252.161682 
	C685.557251,253.541336 690.824341,252.475708 696.244141,249.936646 
	C701.651184,249.583786 707.004822,250.203506 711.684814,248.710922 
	C715.632385,247.451904 718.798035,243.740875 723.023560,241.048279 
	C724.490723,239.987289 725.244141,238.993179 725.997559,237.999054 
	C726.487915,237.413406 726.978333,236.827774 728.097046,236.115265 
	C729.814453,234.989548 730.903564,233.990662 731.992676,232.991821 
	C732.807251,232.081680 733.621826,231.171524 734.714355,230.126343 
M730.625366,267.999207 
	C732.689514,267.725281 734.753662,267.451355 736.817810,267.177429 
	C737.330444,266.222290 737.843079,265.267151 738.355774,264.311981 
	C735.885132,262.691223 733.385498,261.112518 730.951416,259.438690 
	C728.126587,257.496155 725.581482,258.302155 725.378967,261.689484 
	C725.206970,264.569183 725.422302,267.938751 730.625366,267.999207 
M698.717529,253.346024 
	C698.595642,252.769867 698.473694,252.193695 698.351807,251.617523 
	C697.329163,252.398926 696.306458,253.180313 695.283813,253.961716 
	C695.527771,254.281265 695.771729,254.600815 696.015686,254.920380 
	C696.803406,254.588928 697.591064,254.257492 698.717529,253.346024 
z"/>
<path fill="#013FA8" opacity="1.000000" stroke="none" 
	d="
M676.992126,253.004547 
	C677.928223,253.956390 678.794495,255.625549 679.813477,255.724686 
	C682.790100,256.014252 685.841003,255.493378 688.832214,255.711014 
	C689.610779,255.767670 690.277405,257.362396 690.527222,258.631653 
	C679.099365,259.010437 668.139404,258.965424 657.180481,259.049988 
	C655.449707,259.063354 653.723450,259.661926 651.620728,259.996948 
	C650.497314,259.999695 649.748230,259.995483 648.734131,259.719482 
	C645.147949,259.063904 641.826904,258.680115 638.247986,258.266479 
	C638.149109,256.870575 638.046448,255.421539 637.961914,253.576599 
	C638.013977,252.124481 638.047974,251.068268 638.366394,249.984085 
	C639.101379,249.639221 639.551941,249.322311 640.470947,249.003906 
	C651.787720,249.310806 662.635925,249.619202 673.760010,249.846771 
	C674.417786,249.759567 674.799805,249.753159 675.181763,249.746735 
	C675.181763,249.746735 675.377258,249.875046 675.300049,250.380188 
	C675.812561,251.591721 676.402344,252.298141 676.992126,253.004547 
z"/>
<path fill="#086CD7" opacity="1.000000" stroke="none" 
	d="
M609.043945,237.357391 
	C609.531433,236.962631 610.018921,236.567886 611.076172,236.391373 
	C616.056030,235.370377 620.408997,233.823425 624.894470,232.990448 
	C628.406128,232.338318 632.795837,231.232452 635.534607,232.692764 
	C641.015137,235.615021 646.482056,234.710312 652.014526,234.972549 
	C652.007996,234.985031 652.028137,234.991104 652.231812,235.255249 
	C653.301514,235.672958 654.167664,235.826523 655.304504,236.107803 
	C656.402283,237.090164 657.229309,237.944809 657.783813,239.140503 
	C651.391602,239.691711 645.271851,239.901840 638.782227,240.076050 
	C637.918030,240.002411 637.423889,239.964676 636.988281,239.581512 
	C638.268005,233.846710 634.101440,235.839630 631.391418,235.299896 
	C630.107056,235.268524 629.169434,235.298950 627.828369,235.239502 
	C624.019592,234.925095 621.520325,235.715897 621.711243,239.951019 
	C620.866882,240.005066 620.383789,240.034256 619.448303,240.058197 
	C615.655701,240.029434 612.315430,240.005890 608.989563,239.654160 
	C609.017334,238.669785 609.030640,238.013580 609.043945,237.357391 
z"/>
<path fill="#2159B0" opacity="1.000000" stroke="none" 
	d="
M652.021057,234.960068 
	C646.482056,234.710312 641.015137,235.615021 635.534607,232.692764 
	C632.795837,231.232452 628.406128,232.338318 624.894470,232.990448 
	C620.408997,233.823425 616.056030,235.370377 611.406006,236.394913 
	C617.056519,231.614700 623.835205,230.401825 631.680054,230.867142 
	C632.591431,230.727707 632.813538,230.537247 632.957092,230.255280 
	C634.687439,230.286575 636.417786,230.317871 638.733521,230.763184 
	C643.618469,231.975479 647.917908,232.773773 652.217407,233.572067 
	C652.217407,233.572067 652.348572,233.594727 652.281250,233.953125 
	C652.149719,234.527695 652.085388,234.743881 652.021057,234.960068 
z"/>
<path fill="#0240AA" opacity="1.000000" stroke="none" 
	d="
M608.975159,239.982361 
	C612.315430,240.005890 615.655701,240.029434 619.479248,240.444122 
	C619.991150,241.868881 620.019775,242.902496 620.048462,243.936096 
	C616.271118,243.956009 612.481689,244.152634 608.723083,243.897202 
	C607.456177,243.811111 606.267334,242.577682 605.021667,241.131226 
	C604.972534,240.177917 604.944458,239.961975 604.916382,239.746033 
	C604.916382,239.746033 605.071960,239.518814 605.518127,239.708160 
	C606.967896,239.925781 607.971558,239.954071 608.975159,239.982361 
z"/>
<path fill="#318AC2" opacity="1.000000" stroke="none" 
	d="
M652.014526,234.972549 
	C652.085388,234.743881 652.149719,234.527695 652.333496,233.994385 
	C656.601013,235.846069 660.749023,238.014893 664.969604,240.568085 
	C665.042175,240.952484 664.884338,241.127594 664.521240,241.054535 
	C662.124207,240.254135 660.090271,239.526794 658.056274,238.799469 
	C657.229309,237.944809 656.402283,237.090164 655.085815,235.857178 
	C653.740295,235.316269 652.884216,235.153687 652.028137,234.991104 
	C652.028137,234.991104 652.007996,234.985031 652.014526,234.972549 
z"/>
<path fill="#318AC2" opacity="1.000000" stroke="none" 
	d="
M651.984863,233.311859 
	C647.917908,232.773773 643.618469,231.975479 639.148193,230.890381 
	C643.235779,231.419601 647.494080,232.235626 651.984863,233.311859 
z"/>
<path fill="#318AC2" opacity="1.000000" stroke="none" 
	d="
M665.119995,240.863937 
	C667.409485,242.610748 669.621216,244.446106 671.875732,246.682953 
	C671.918579,247.084427 671.724731,247.284103 671.418945,247.157639 
	C669.193542,245.589523 667.273926,244.147873 665.290894,242.439255 
	C665.113098,241.824051 664.998718,241.475815 664.884338,241.127594 
	C664.884338,241.127594 665.042175,240.952484 665.119995,240.863937 
z"/>
<path fill="#2159B0" opacity="1.000000" stroke="none" 
	d="
M608.989563,239.654175 
	C607.971558,239.954071 606.967896,239.925781 605.655823,239.706284 
	C606.369080,238.788422 607.390869,238.061798 608.728271,237.346283 
	C609.030640,238.013580 609.017334,238.669785 608.989563,239.654175 
z"/>
<path fill="#022B9D" opacity="1.000000" stroke="none" 
	d="
M677.316772,253.001099 
	C676.402344,252.298141 675.812561,251.591721 675.328491,250.493591 
	C676.713013,250.708679 677.991821,251.315506 679.614136,252.041992 
	C679.185547,252.440338 678.413513,252.718994 677.316772,253.001099 
z"/>
<path fill="#318AC2" opacity="1.000000" stroke="none" 
	d="
M675.077271,249.450760 
	C674.799805,249.753159 674.417786,249.759567 673.578064,249.577240 
	C672.655090,248.687057 672.189941,247.985580 671.724731,247.284103 
	C671.724731,247.284103 671.918579,247.084427 672.015686,246.984711 
	C673.066101,247.641586 674.019409,248.398178 675.077271,249.450760 
z"/>
<path fill="#318AC2" opacity="1.000000" stroke="none" 
	d="
M632.717896,230.197647 
	C632.813538,230.537247 632.591431,230.727707 632.068237,230.846283 
	C631.955322,230.535538 632.166382,230.293579 632.717896,230.197647 
z"/>
<path fill="#2159B0" opacity="1.000000" stroke="none" 
	d="
M604.612549,239.774124 
	C604.944458,239.961975 604.972534,240.177917 605.005737,240.720978 
	C604.770569,241.016022 604.530334,240.983917 603.940552,240.850677 
	C603.830261,240.433777 604.069519,240.117996 604.612549,239.774124 
z"/>
<path fill="#0240AA" opacity="1.000000" stroke="none" 
	d="
M517.728882,249.215729 
	C533.135742,248.873276 548.271423,248.732040 563.711304,248.304291 
	C565.628357,248.799500 567.430237,249.335281 568.822388,250.404465 
	C575.780457,255.748383 583.619446,256.556976 591.062134,252.854660 
	C597.266052,249.768570 603.343445,248.660278 610.028992,248.961319 
	C614.673340,249.170425 619.335205,248.990173 624.484009,249.399231 
	C624.477600,253.070969 625.576965,255.294266 628.971924,256.436523 
	C627.395203,257.567200 625.839844,258.898895 624.272888,258.912720 
	C607.233337,259.062958 590.191833,258.977905 573.151245,259.050812 
	C571.433167,259.058136 569.717712,259.680267 567.563782,260.024689 
	C565.086365,260.026367 563.046265,260.020386 560.757141,259.737366 
	C559.023010,259.295471 557.537781,259.130646 555.811279,258.805908 
	C555.370422,258.114471 555.170898,257.582916 554.948669,256.703217 
	C552.299255,253.435638 550.962463,253.827209 549.037720,258.038635 
	C548.999268,258.008759 549.068176,258.079041 548.669800,258.054016 
	C546.172058,258.992371 544.072693,259.955750 541.649048,261.011658 
	C538.080017,264.371735 534.875916,267.680817 531.583191,270.899200 
	C520.469543,281.761688 509.338226,292.606232 498.170837,303.413452 
	C497.003510,304.543121 495.551605,305.378754 494.159851,305.864807 
	C494.036438,294.608124 493.984863,283.837402 493.933289,273.066711 
	C494.846313,272.774994 496.051453,272.758087 496.625702,272.147675 
	C500.906372,267.597656 505.051971,262.920898 509.260559,258.302734 
	C511.974854,255.324310 514.724121,252.377762 517.728882,249.215729 
z"/>
<path fill="#1C428F" opacity="1.000000" stroke="none" 
	d="
M493.593781,272.898773 
	C493.984863,283.837402 494.036438,294.608124 494.131775,306.152985 
	C494.207611,307.316589 494.239685,307.705994 494.161499,308.291809 
	C494.051208,308.488159 493.948761,308.926819 493.728851,309.037537 
	C493.225098,309.355896 493.070129,309.635071 493.018677,309.989746 
	C492.993286,309.993713 492.999481,310.045441 492.792969,309.998444 
	C492.315063,309.870636 492.039032,309.811188 491.667389,309.419861 
	C491.576355,297.234100 491.576355,285.401672 491.576355,273.112854 
	C484.279816,273.112854 477.705688,273.112854 471.131531,273.112854 
	C476.439911,272.743378 481.748322,272.373871 487.522858,271.997559 
	C489.744110,272.237427 491.499176,272.484131 493.593781,272.898773 
z"/>
<path fill="#0137AC" opacity="1.000000" stroke="none" 
	d="
M533.990173,244.614594 
	C530.619812,244.669052 527.249451,244.334366 523.438110,243.993958 
	C525.642578,242.391861 528.230286,240.663757 531.036621,239.434860 
	C531.276001,239.330048 532.971191,242.550003 533.990173,244.614594 
z"/>
<path fill="#0455DA" opacity="1.000000" stroke="none" 
	d="
M628.987793,256.025574 
	C625.576965,255.294266 624.477600,253.070969 624.947388,249.403610 
	C623.488647,247.414795 622.061218,245.836929 620.341125,244.097580 
	C620.019775,242.902496 619.991150,241.868881 619.931519,240.449371 
	C620.383789,240.034256 620.866882,240.005066 621.952942,239.981964 
	C622.555847,239.988068 623.037476,240.069748 623.146240,240.272827 
	C623.460205,240.726837 623.723450,240.874832 624.328064,241.134415 
	C627.445251,244.750412 630.411987,245.014908 633.697449,241.899338 
	C634.593018,241.050079 635.842590,240.574188 636.929688,239.926956 
	C637.423889,239.964676 637.918030,240.002411 638.735657,240.475906 
	C639.041016,242.264267 639.022949,243.616882 638.967773,245.206161 
	C638.930664,245.442841 638.878967,245.919205 638.557983,245.990082 
	C638.185303,247.377975 638.133667,248.695023 638.081970,250.012054 
	C638.047974,251.068268 638.013977,252.124481 637.568848,253.582672 
	C636.104370,253.990158 635.051025,253.995682 634.129517,253.661560 
	C635.558777,250.915680 635.148071,248.853287 632.331482,248.468338 
	C631.141357,248.305695 629.720581,249.831345 628.404663,250.589630 
	C629.770447,251.716461 631.102661,252.888962 632.527161,253.935852 
	C632.856018,254.177521 633.500916,253.989136 633.929199,254.367340 
	C632.234680,255.165894 630.611206,255.595734 628.987793,256.025574 
z"/>
<path fill="#003499" opacity="1.000000" stroke="none" 
	d="
M731.645020,232.999115 
	C730.903564,233.990662 729.814453,234.989548 728.361572,235.991791 
	C729.097595,234.998901 730.197449,234.002670 731.645020,232.999115 
z"/>
<path fill="#003499" opacity="1.000000" stroke="none" 
	d="
M725.718445,238.132080 
	C725.244141,238.993179 724.490723,239.987289 723.367310,240.988800 
	C723.811279,240.085831 724.625305,239.175446 725.718445,238.132080 
z"/>
<path fill="#013193" opacity="1.000000" stroke="none" 
	d="
M816.963867,202.644653 
	C815.426575,203.334198 813.822937,203.685715 810.679688,204.374710 
	C813.129944,206.111084 814.475281,206.719513 815.308167,207.744461 
	C816.286194,208.947937 817.396240,210.501907 817.424194,211.924026 
	C817.481750,214.848770 818.264038,216.914169 821.408386,216.939362 
	C826.291931,216.978485 825.804749,220.590179 825.919006,223.573868 
	C826.062561,227.320435 823.130615,227.162766 820.649048,226.946304 
	C818.424072,226.752213 816.239380,225.773895 814.035583,225.769394 
	C812.453552,225.766174 810.869507,226.797272 808.879822,227.583801 
	C807.981445,227.536804 807.489502,227.272964 806.831848,226.766418 
	C806.121704,226.342163 805.577393,226.160599 804.910889,225.767075 
	C804.581787,225.291702 804.322144,225.099533 803.971252,224.642395 
	C802.960876,223.532837 801.988953,222.759491 800.945740,221.649033 
	C798.243164,218.548325 795.611877,215.784744 792.951965,212.675842 
	C791.605103,211.229996 790.286987,210.129425 788.733521,209.012390 
	C788.498169,208.995926 788.027771,208.960068 787.982544,208.615570 
	C784.280273,204.528091 780.623108,200.785095 776.628540,197.024994 
	C775.840149,197.008591 775.389160,197.009308 774.938232,197.010010 
	C773.198120,195.948868 771.458008,194.887711 769.334961,193.734406 
	C769.032104,193.151840 768.977600,192.312027 769.211914,192.221756 
	C776.699219,189.337097 780.226074,181.870636 786.383728,177.475952 
	C786.877441,177.123596 787.644531,177.154312 788.947815,177.143616 
	C790.753845,177.835114 791.896729,178.390030 793.039551,178.944931 
	C793.039551,178.944931 793.504272,178.987228 793.778442,179.341919 
	C795.025818,180.476028 795.999023,181.255417 796.972229,182.034805 
	C796.972229,182.034805 797.005615,182.501312 797.054077,183.059158 
	C797.751160,184.054001 798.399780,184.490982 799.048401,184.927979 
	C800.159912,185.860321 801.271423,186.792664 802.718689,188.220764 
	C804.361267,189.820190 805.668152,190.923843 806.974976,192.027496 
	C808.448853,193.587021 809.922729,195.146545 811.686401,196.860855 
	C813.616699,198.779312 815.257141,200.542969 816.963867,202.644653 
z"/>
<path fill="#033ABC" opacity="1.000000" stroke="none" 
	d="
M792.995483,178.604614 
	C791.896729,178.390030 790.753845,177.835114 789.318237,177.137268 
	C789.018066,176.243195 789.010681,175.492050 789.021606,174.365479 
	C790.343750,175.414810 791.647583,176.839554 792.995483,178.604614 
z"/>
<path fill="#0141BC" opacity="1.000000" stroke="none" 
	d="
M806.167053,184.187927 
	C810.907471,182.310730 811.653809,182.467972 813.349548,185.754486 
	C810.969604,185.045456 807.964539,187.796890 806.167053,184.187927 
z"/>
<path fill="#033ABC" opacity="1.000000" stroke="none" 
	d="
M806.948975,191.680847 
	C805.668152,190.923843 804.361267,189.820190 803.002930,188.380356 
	C804.275208,189.140839 805.599121,190.237518 806.948975,191.680847 
z"/>
<path fill="#033ABC" opacity="1.000000" stroke="none" 
	d="
M801.055908,180.311661 
	C802.007690,180.732452 802.984436,181.498764 804.016602,182.594620 
	C803.074951,182.168503 802.077942,181.412842 801.055908,180.311661 
z"/>
<path fill="#033ABC" opacity="1.000000" stroke="none" 
	d="
M796.948486,181.690155 
	C795.999023,181.255417 795.025818,180.476028 794.010986,179.360168 
	C794.954529,179.797638 795.939636,180.571579 796.948486,181.690155 
z"/>
<path fill="#033ABC" opacity="1.000000" stroke="none" 
	d="
M798.992126,184.618500 
	C798.399780,184.490982 797.751160,184.054001 797.073608,183.292206 
	C797.675110,183.414612 798.305481,183.861832 798.992126,184.618500 
z"/>
<path fill="#0141BC" opacity="1.000000" stroke="none" 
	d="
M813.070923,191.273529 
	C813.666199,191.430679 814.284363,191.910553 814.926025,192.711456 
	C814.330872,192.553741 813.712341,192.074997 813.070923,191.273529 
z"/>
<path fill="#01308F" opacity="1.000000" stroke="none" 
	d="
M811.000000,252.003479 
	C813.713684,254.828873 816.118286,258.083954 819.245911,260.333923 
	C821.460999,261.927368 824.734070,261.969208 827.381165,263.072510 
	C829.056152,263.770691 831.595215,264.987274 831.770630,266.246643 
	C832.564575,271.945801 832.710144,277.740662 832.955627,283.506714 
	C833.009888,284.780701 832.871399,286.303925 832.215271,287.321350 
	C830.348877,290.215393 828.968506,292.592957 833.041138,295.043182 
	C834.026855,295.636200 833.719421,298.378571 833.538940,300.562988 
	C822.049927,300.989563 811.028625,300.985687 800.007324,300.981812 
	C798.843323,295.803802 797.233948,294.946533 792.449036,297.313751 
	C789.753479,298.647247 787.363159,299.954498 785.243225,296.468109 
	C783.097595,292.939423 783.794373,290.321655 787.829346,289.289520 
	C790.673889,288.561890 793.298401,287.751251 792.894897,284.397461 
	C792.408081,280.350952 795.424072,275.194916 789.453918,272.635834 
	C784.541870,270.530304 785.632202,265.574310 785.305176,261.783630 
	C785.166260,260.173462 787.984558,257.791473 789.967346,256.623169 
	C794.007141,254.242981 795.451843,249.684967 793.166565,245.506393 
	C792.555725,244.389404 792.643250,242.775925 792.731812,241.411240 
	C793.085205,235.962204 794.322083,230.548965 787.446594,227.483414 
	C783.532654,225.738281 784.563660,221.744873 789.907227,219.126846 
	C791.030273,219.818497 791.519409,220.400848 792.013062,221.355560 
	C793.018921,222.481567 794.020325,223.235214 795.236694,224.094284 
	C795.720520,224.391968 795.910767,224.643570 796.016663,225.309875 
	C796.340576,226.776596 796.670288,227.888000 796.965576,229.342651 
	C796.620667,230.782242 796.310059,231.878586 795.718750,233.255264 
	C794.307678,241.358978 796.414307,245.341202 802.658752,246.665573 
	C804.702637,247.099060 806.879211,246.906906 809.000122,247.356934 
	C809.670105,249.143158 810.335083,250.573318 811.000000,252.003479 
z"/>
<path fill="#065DD2" opacity="1.000000" stroke="none" 
	d="
M742.966492,289.020416 
	C745.411316,291.330322 747.856140,293.640259 750.642822,295.994568 
	C752.087830,297.345947 753.190979,298.652924 754.682861,300.327576 
	C755.730713,301.108459 756.389771,301.521759 757.048767,301.935028 
	C762.721130,301.948486 768.393433,301.961945 774.829712,302.263916 
	C784.729065,302.697876 793.864563,302.843323 803.000000,302.988770 
	C802.994263,303.462219 802.988464,303.935699 802.516357,304.743591 
	C791.601318,305.396271 781.151733,305.692169 770.705200,306.071869 
	C769.795959,306.104919 768.906799,306.689392 767.562256,307.007690 
	C764.731995,307.044586 762.347656,307.091553 759.640015,307.114166 
	C758.884460,307.092743 758.452209,307.095673 757.773804,306.806580 
	C756.011841,306.357178 754.496155,306.199768 752.990356,306.025696 
	C753.000183,306.009003 752.964111,306.022369 752.830200,305.718201 
	C749.453857,302.292389 746.211548,299.170746 742.981445,296.027863 
	C742.993652,296.006622 742.950928,296.030762 742.822754,295.727783 
	C741.463806,294.615509 740.233154,293.806213 738.768616,292.831543 
	C738.024841,292.109406 737.514954,291.552704 736.972717,290.652161 
	C735.292786,288.874725 733.645203,287.441132 731.885498,285.789764 
	C731.571777,285.300812 731.310608,285.111633 730.852295,284.703400 
	C728.805115,282.610046 726.895752,280.817749 724.848511,278.737183 
	C723.468506,277.301849 722.226318,276.154724 720.949463,274.686218 
	C720.277283,273.909119 719.639893,273.453430 718.838989,272.760559 
	C718.148987,272.323212 717.622437,272.123016 717.034180,271.573975 
	C715.655334,270.497040 714.338196,269.769012 713.076904,268.692719 
	C714.423279,267.226532 715.713745,266.108551 717.338745,264.989502 
	C718.119568,264.988708 718.565796,264.988983 719.012024,264.989258 
	C719.592224,265.481781 720.172485,265.974243 720.914001,267.071869 
	C723.051025,269.450562 725.026733,271.224091 727.002441,272.997620 
	C727.002441,272.997620 727.000000,273.000000 727.036621,273.343018 
	C729.714539,276.124969 732.355957,278.563934 734.997314,281.002899 
	C735.489624,281.584473 735.981873,282.166077 736.769531,283.226013 
	C739.032166,285.476379 740.999329,287.248413 742.966492,289.020416 
z"/>
<path fill="#064AD0" opacity="1.000000" stroke="none" 
	d="
M792.008545,220.983185 
	C791.519409,220.400848 791.030273,219.818497 790.277283,219.115326 
	C788.443909,217.186691 786.874390,215.378860 785.110718,212.962006 
	C784.273621,211.910507 783.630676,211.468063 782.987732,211.025620 
	C782.987732,211.025620 782.997559,211.002960 782.949951,210.686279 
	C782.259216,209.924210 781.616028,209.478821 780.972839,209.033447 
	C778.327454,205.949631 775.682129,202.865814 773.011353,199.432434 
	C773.457703,198.469849 773.929321,197.856827 774.669617,197.126907 
	C775.389160,197.009308 775.840149,197.008591 776.675476,197.364090 
	C780.715820,201.466888 784.371826,205.213470 788.027771,208.960052 
	C788.027771,208.960068 788.498169,208.995926 788.777588,209.351105 
	C790.364929,210.811218 791.672791,211.916183 792.980652,213.021149 
	C795.611877,215.784744 798.243164,218.548325 800.975342,221.990295 
	C802.054138,223.438644 803.031921,224.208618 804.009766,224.978592 
	C804.322144,225.099533 804.581787,225.291702 804.900940,226.098923 
	C805.028015,227.084091 805.042908,227.525436 804.855896,228.210632 
	C803.414001,228.321640 802.174072,228.188843 800.692993,228.049011 
	C800.451843,228.041977 799.968262,228.054184 799.933228,227.697296 
	C798.606262,226.545135 797.314331,225.749847 796.022461,224.954544 
	C795.910767,224.643570 795.720520,224.391968 795.104858,223.813049 
	C793.841431,222.611984 792.924988,221.797577 792.008545,220.983185 
z"/>
<path fill="#033ABC" opacity="1.000000" stroke="none" 
	d="
M803.468628,302.986023 
	C793.864563,302.843323 784.729065,302.697876 775.297607,302.256805 
	C779.877014,301.641418 784.749146,301.247772 789.629089,301.028473 
	C792.769531,300.887329 795.921631,301.002350 799.537903,300.992188 
	C811.028625,300.985687 822.049927,300.989563 833.540833,300.975372 
	C842.601562,300.974365 851.193420,300.934784 859.783081,301.056763 
	C861.479553,301.080841 863.167114,301.726715 865.017395,302.450409 
	C844.763062,302.871094 824.350220,302.927216 803.468628,302.986023 
z"/>
<path fill="#033ABC" opacity="1.000000" stroke="none" 
	d="
M756.986572,301.622986 
	C756.389771,301.521759 755.730713,301.108459 755.012939,300.382446 
	C755.610901,300.483459 756.267639,300.897217 756.986572,301.622986 
z"/>
<path fill="#0241B4" opacity="1.000000" stroke="none" 
	d="
M783.034302,211.342224 
	C783.630676,211.468063 784.273621,211.910507 784.963135,212.670563 
	C784.366760,212.545059 783.723816,212.101929 783.034302,211.342224 
z"/>
<path fill="#0241B4" opacity="1.000000" stroke="none" 
	d="
M781.025391,209.346771 
	C781.616028,209.478821 782.259216,209.924210 782.938477,210.690948 
	C782.342285,210.561569 781.710083,210.110825 781.025391,209.346771 
z"/>
<path fill="#013193" opacity="1.000000" stroke="none" 
	d="
M851.601196,200.007019 
	C850.284424,201.321472 848.610962,203.726654 846.848267,203.793961 
	C839.110901,204.089417 831.347717,203.708771 823.294556,203.281082 
	C829.136597,202.661911 835.292725,202.496201 841.413757,201.943161 
	C844.707947,201.645538 847.942078,200.683792 851.601196,200.007019 
z"/>
<path fill="#0235B0" opacity="1.000000" stroke="none" 
	d="
M895.552368,231.071930 
	C894.731018,231.738525 893.473999,232.807465 892.142761,232.909714 
	C888.754456,233.169952 885.332825,232.998016 880.990234,233.002518 
	C864.999512,232.941864 849.943054,232.877762 834.886597,232.813660 
	C836.387146,232.208786 837.871155,231.135742 839.391235,231.081909 
	C845.279846,230.873322 851.179932,230.989304 857.545837,230.963440 
	C870.371643,230.972992 882.727295,231.003510 895.552368,231.071930 
z"/>
<path fill="#0235B0" opacity="1.000000" stroke="none" 
	d="
M904.638855,230.053619 
	C903.373413,230.593018 901.740662,231.166428 900.040710,231.432007 
	C901.406189,230.778656 902.838867,230.433136 904.638855,230.053619 
z"/>
<path fill="#043ED5" opacity="1.000000" stroke="none" 
	d="
M834.510620,232.795197 
	C849.943054,232.877762 864.999512,232.941864 880.523682,233.006485 
	C880.996399,233.768341 881.001343,234.529694 880.538025,235.663605 
	C866.138428,236.353622 852.208313,236.825211 838.275391,236.932434 
	C829.505432,236.999954 820.731201,236.521591 811.633301,236.208282 
	C810.863220,236.083893 810.418823,236.039307 809.984497,235.629456 
	C810.340515,233.871445 810.686279,232.478699 811.032104,231.085938 
	C818.732971,231.649536 826.433838,232.213120 834.510620,232.795197 
z"/>
<path fill="#0639BE" opacity="1.000000" stroke="none" 
	d="
M810.697266,230.991104 
	C810.686279,232.478699 810.340515,233.871445 809.686707,235.491959 
	C807.914307,234.829330 806.449951,233.938950 804.753174,233.016968 
	C804.520874,232.985352 804.054321,232.938400 803.999146,232.606064 
	C802.940674,230.867828 801.937378,229.461945 800.934143,228.056046 
	C802.174072,228.188843 803.414001,228.321640 805.142334,228.193878 
	C806.086304,227.625244 806.541931,227.317154 806.997620,227.009094 
	C807.489502,227.272964 807.981445,227.536804 808.729980,227.911499 
	C809.445190,228.980316 809.903809,229.938293 810.697266,230.991104 
z"/>
<path fill="#012881" opacity="1.000000" stroke="none" 
	d="
M856.007690,197.398163 
	C856.451965,197.008560 856.894226,197.004913 857.668213,196.999542 
	C860.403442,198.479187 863.260315,199.647308 862.601257,203.779816 
	C859.283875,202.646271 855.785767,202.342728 856.007690,197.398163 
z"/>
<path fill="#012881" opacity="1.000000" stroke="none" 
	d="
M864.959595,194.851868 
	C864.125854,195.576691 863.318176,195.922607 862.510437,196.268524 
	C862.599670,194.643143 862.688904,193.017761 862.778137,191.392395 
	C863.311035,191.406326 863.843872,191.420258 864.376770,191.434189 
	C864.579712,192.447113 864.782654,193.460037 864.959595,194.851868 
z"/>
<path fill="#0455DA" opacity="1.000000" stroke="none" 
	d="
M565.958679,326.969727 
	C561.477234,330.916260 556.869751,334.730042 552.542480,338.838806 
	C545.134644,345.872589 537.835693,353.024933 530.632935,360.268555 
	C528.209656,362.705597 526.217834,365.571655 523.999634,368.601196 
	C523.970398,368.960785 523.953735,368.983643 523.725464,369.078552 
	C523.220215,369.371429 523.019470,369.628876 522.562500,369.990906 
	C520.831360,371.029541 519.432739,372.022980 517.736450,373.062042 
	C517.241821,373.145630 517.044922,373.183594 516.649536,373.193420 
	C516.450989,373.165314 516.051453,373.199463 516.010132,372.886597 
	C515.680237,372.033234 515.391602,371.492706 515.048950,370.589050 
	C514.965210,369.495758 514.935608,368.765594 515.253052,367.974060 
	C516.434570,366.951599 517.210938,365.931824 518.112488,365.038361 
	C529.572266,353.681366 541.067749,342.360382 552.509644,330.985413 
	C555.097290,328.412964 557.514282,325.668823 560.158325,322.720612 
	C561.209717,321.963715 562.112549,321.489868 563.401733,321.005646 
	C566.507019,320.330597 569.225952,319.665924 571.989868,319.396515 
	C572.395874,323.760010 575.420898,322.877350 577.891602,323.011902 
	C580.731079,323.166534 582.083313,321.773468 582.527405,318.981781 
	C589.505371,319.658264 596.069519,320.274719 602.608337,321.092834 
	C603.470703,321.200714 604.204346,322.338165 604.997559,322.998779 
	C601.311157,323.003998 597.624817,323.009186 593.065674,323.010010 
	C589.022644,322.943542 585.718018,322.636780 584.960632,326.973206 
	C584.960632,326.973206 584.483826,326.982544 583.868042,327.033813 
	C581.164307,327.726440 579.076477,328.367798 576.988586,329.009094 
	C575.241821,328.763702 573.494995,328.518341 571.342102,327.760529 
	C569.411560,324.746918 567.826782,323.324890 565.958679,326.969727 
z"/>
<path fill="#043ED5" opacity="1.000000" stroke="none" 
	d="
M605.460327,323.007080 
	C604.204346,322.338165 603.470703,321.200714 602.608337,321.092834 
	C596.069519,320.274719 589.505371,319.658264 582.423828,318.732422 
	C581.847595,318.131561 581.796631,317.777222 581.822937,316.974365 
	C580.932617,313.349274 579.965088,310.172699 579.361450,306.997437 
	C582.498352,309.003693 585.271423,311.008698 587.989502,313.378326 
	C586.059814,318.753265 589.804443,317.984924 592.463379,317.990601 
	C617.014526,318.042999 641.568054,317.857483 666.115356,318.173431 
	C671.408508,318.241547 676.679321,320.049042 681.958191,321.425293 
	C681.565430,325.063629 679.716370,323.635376 678.062927,323.039093 
	C675.884338,322.253357 673.499573,320.395477 671.971191,323.969482 
	C649.955139,323.651459 627.939087,323.333435 605.460327,323.007080 
z"/>
<path fill="#0560DC" opacity="1.000000" stroke="none" 
	d="
M668.735596,353.728333 
	C669.010498,354.449066 668.982849,354.875610 668.949585,355.622681 
	C668.209900,355.954407 667.475830,355.965637 666.156616,356.091064 
	C665.299377,356.386780 665.095703,356.626556 664.960510,356.924591 
	C664.525208,356.928833 664.089844,356.933044 663.134949,356.703217 
	C661.742737,356.626862 660.870117,356.784576 659.997498,356.942322 
	C658.930725,356.947235 657.864014,356.952179 656.092957,356.675598 
	C650.591187,356.613007 645.793640,356.831940 640.996155,357.050903 
	C638.956482,357.043427 636.916870,357.035919 634.143799,356.735901 
	C620.830322,356.324310 608.250366,356.192841 595.669983,356.127563 
	C595.114929,356.124664 594.556763,356.726776 594.000000,357.047089 
	C591.957703,357.042297 589.915466,357.037537 587.191223,356.760986 
	C582.027222,355.227936 579.179871,357.402252 577.013367,361.007507 
	C573.019714,365.414276 569.026062,369.821045 564.608826,374.591949 
	C562.157532,375.624969 560.129822,376.293823 557.990295,376.793640 
	C557.878479,376.624542 557.887878,376.219208 558.047852,375.868347 
	C558.438782,374.012329 558.669678,372.507141 558.900574,371.001953 
	C559.085388,371.000092 559.270203,370.998260 559.780396,371.127625 
	C560.186951,371.307465 560.268127,371.356110 560.349304,371.404724 
	C560.228638,371.273132 560.107971,371.141510 559.987305,371.009918 
	C562.188599,368.849274 564.389954,366.688660 567.137207,364.227722 
	C568.784241,362.616302 569.885254,361.305206 570.986328,359.994110 
	C571.806458,359.086578 572.626526,358.179016 574.061523,357.097412 
	C575.442993,355.943878 576.209595,354.964417 576.976257,353.984985 
	C577.744080,353.642670 578.511963,353.300323 579.611572,352.936768 
	C607.637939,352.942871 635.332520,352.962158 663.027039,353.016052 
	C664.829285,353.019562 666.631042,353.288666 668.735596,353.728333 
z"/>
<path fill="#0141BC" opacity="1.000000" stroke="none" 
	d="
M672.338989,324.022308 
	C673.499573,320.395477 675.884338,322.253357 678.062927,323.039093 
	C679.716370,323.635376 681.565430,325.063629 682.217773,321.402222 
	C682.652893,321.002502 682.826233,320.995789 683.151001,321.293854 
	C685.274658,323.741943 687.150940,325.984253 689.240356,328.006317 
	C692.757263,331.409760 696.409546,334.673309 700.045654,338.342102 
	C706.141418,344.837402 712.247864,350.936829 718.228271,357.157349 
	C720.936768,359.974518 723.395020,363.032196 725.966736,365.980774 
	C725.966736,365.980774 725.480774,365.985443 725.237915,365.994537 
	C724.441162,366.181396 723.887146,366.359161 723.163574,366.782410 
	C722.552856,367.028137 722.111694,367.028381 721.340027,367.039978 
	C715.542542,362.465637 710.075623,357.880005 704.269287,352.803986 
	C702.954956,351.541351 701.979919,350.769073 701.004883,349.996796 
	C700.689148,349.884644 700.430847,349.694427 700.111328,348.846252 
	C698.328491,346.512360 696.664246,344.758392 695.000000,343.004425 
	C694.184509,342.095123 693.369080,341.185822 692.231140,339.820068 
	C691.273254,338.909088 690.637878,338.454559 690.002441,338.000000 
	C689.892212,337.682495 689.702148,337.423615 689.216064,337.110474 
	C684.537659,331.468597 680.053101,325.967743 672.338989,324.022308 
z"/>
<path fill="#0455DA" opacity="1.000000" stroke="none" 
	d="
M599.985596,346.013458 
	C599.985596,346.013458 599.505737,345.995911 599.212158,345.616394 
	C598.271729,343.263672 597.841675,341.176971 596.891479,339.362640 
	C596.376282,338.378876 594.974976,337.859131 593.973389,337.130066 
	C590.705200,337.088654 590.249939,334.516785 591.344360,332.752350 
	C592.032043,331.643616 594.932800,331.349945 596.711853,331.601471 
	C599.658691,332.018158 602.462769,333.357819 605.398254,333.963196 
	C609.022705,334.710693 610.717651,336.885437 610.983704,340.390717 
	C611.268494,344.142517 610.468445,347.096344 605.041504,347.010559 
	C602.841553,346.674286 601.413574,346.343872 599.985596,346.013458 
M600.182678,342.025085 
	C601.572876,344.980194 604.038391,345.886475 606.670044,344.347137 
	C607.839905,343.662903 609.019165,340.922607 608.530212,339.965668 
	C607.817749,338.571289 605.702209,337.430573 604.007324,337.104370 
	C601.095154,336.543884 599.775208,338.318207 600.182678,342.025085 
z"/>
<path fill="#1E4796" opacity="1.000000" stroke="none" 
	d="
M558.544434,371.000977 
	C558.669678,372.507141 558.438782,374.012329 557.756836,375.680511 
	C554.687012,369.309631 549.383789,366.971527 542.829956,366.507080 
	C533.314941,365.832672 525.629272,370.317627 518.046570,374.824097 
	C518.028259,373.980835 518.031189,373.498627 518.034119,373.016388 
	C519.432739,372.022980 520.831360,371.029541 522.803711,369.910889 
	C523.569519,369.518311 523.761658,369.250977 523.953735,368.983643 
	C523.953735,368.983643 523.970398,368.960785 523.961548,368.945374 
	C529.459167,366.313263 535.073547,364.980011 541.347717,364.747101 
	C548.208679,364.492401 553.197937,367.261169 558.544434,371.000977 
z"/>
<path fill="#0141BC" opacity="1.000000" stroke="none" 
	d="
M594.034363,337.497742 
	C594.974976,337.859131 596.376282,338.378876 596.891479,339.362640 
	C597.841675,341.176971 598.271729,343.263672 598.972107,345.611389 
	C593.860413,345.417389 595.660950,340.619537 594.034363,337.497742 
z"/>
<path fill="#0141BC" opacity="1.000000" stroke="none" 
	d="
M577.060303,329.359680 
	C579.076477,328.367798 581.164307,327.726440 583.629761,327.044617 
	C582.742249,330.514709 580.778076,332.249115 577.060303,329.359680 
z"/>
<path fill="#0141BC" opacity="1.000000" stroke="none" 
	d="
M585.333008,326.938965 
	C585.718018,322.636780 589.022644,322.943542 592.606445,323.020111 
	C590.581665,324.324615 588.143494,325.614685 585.333008,326.938965 
z"/>
<path fill="#0141BC" opacity="1.000000" stroke="none" 
	d="
M600.000000,346.394348 
	C601.413574,346.343872 602.841553,346.674286 604.644165,347.020691 
	C603.186218,350.097687 601.504944,350.262390 600.000000,346.394348 
z"/>
<path fill="#084EC4" opacity="1.000000" stroke="none" 
	d="
M570.629150,360.034027 
	C569.885254,361.305206 568.784241,362.616302 567.340332,363.959045 
	C568.088989,362.685150 569.180481,361.379547 570.629150,360.034027 
z"/>
<path fill="#0141BC" opacity="1.000000" stroke="none" 
	d="
M566.334106,326.986847 
	C567.826782,323.324890 569.411560,324.746918 570.998291,327.601257 
	C569.610291,327.637604 568.159912,327.320770 566.334106,326.986847 
z"/>
<path fill="#084EC4" opacity="1.000000" stroke="none" 
	d="
M576.640869,354.028137 
	C576.209595,354.964417 575.442993,355.943878 574.336975,356.957031 
	C574.766846,356.017609 575.536133,355.044464 576.640869,354.028137 
z"/>
<path fill="#0241B4" opacity="1.000000" stroke="none" 
	d="
M639.231873,391.208984 
	C638.878052,389.298431 638.771484,387.578918 638.770508,385.440186 
	C640.931274,380.144318 645.762390,382.652252 649.153320,381.284668 
	C651.484985,380.344269 654.275024,380.396088 656.867126,380.342773 
	C657.557373,380.328583 658.273315,381.560242 658.988708,382.603882 
	C658.787659,383.234406 658.446106,383.451355 658.381775,383.731903 
	C656.681030,391.144806 656.759338,390.548462 649.614990,389.670532 
	C646.681091,389.309998 643.354248,392.147827 640.216248,393.526337 
	C640.106995,393.211243 639.793030,392.305634 639.231873,391.208984 
z"/>
<path fill="#0449D6" opacity="1.000000" stroke="none" 
	d="
M651.978149,424.456390 
	C643.956909,424.908966 635.941895,424.951843 627.172485,424.706482 
	C624.353333,424.277435 622.190552,424.462097 620.258850,423.879822 
	C618.998840,423.499969 618.074646,422.006226 617.000000,421.011444 
	C627.235107,421.004608 637.470154,421.000793 647.705261,420.988495 
	C649.872803,420.985901 652.271545,420.628571 651.978149,424.456390 
z"/>
<path fill="#032699" opacity="1.000000" stroke="none" 
	d="
M601.058472,431.149353 
	C598.379211,427.410400 596.126038,423.613861 593.878174,419.440155 
	C598.781311,424.122620 604.927612,423.714630 611.544739,423.349182 
	C609.603943,426.134125 607.261414,428.573669 604.918823,431.013214 
	C603.774048,431.039398 602.629272,431.065582 601.058472,431.149353 
z"/>
<path fill="#053ACF" opacity="1.000000" stroke="none" 
	d="
M605.102905,431.319885 
	C607.261414,428.573669 609.603943,426.134125 611.988159,423.351288 
	C613.458435,422.363342 614.887024,421.718658 616.657837,421.042725 
	C618.074646,422.006226 618.998840,423.499969 620.258850,423.879822 
	C622.190552,424.462097 624.353333,424.277435 626.712280,424.698059 
	C625.011414,425.313843 622.959045,425.462128 621.040283,426.047852 
	C619.612976,426.483551 618.358643,427.486023 617.009644,428.649597 
	C611.973022,434.748260 610.258789,435.123657 605.102905,431.319885 
z"/>
<path fill="#032699" opacity="1.000000" stroke="none" 
	d="
M558.102112,376.962708 
	C560.129822,376.293823 562.157532,375.624969 564.552490,374.929016 
	C572.725037,374.931152 580.530701,375.005951 588.335632,374.960266 
	C590.525696,374.947449 592.713623,374.580353 594.974365,374.710571 
	C591.961121,378.325836 588.876099,381.606293 585.427734,384.576782 
	C583.820557,383.181946 582.615051,381.215637 581.326599,381.159698 
	C574.547485,380.865631 567.747498,381.054077 560.716431,380.856628 
	C559.687500,379.758362 558.896912,378.878571 558.049072,377.739990 
	C558.028687,377.308350 558.065430,377.135529 558.102112,376.962708 
z"/>
<path fill="#084EC4" opacity="1.000000" stroke="none" 
	d="
M594.306396,357.326965 
	C594.556763,356.726776 595.114929,356.124664 595.669983,356.127563 
	C608.250366,356.192841 620.830322,356.324310 633.706726,356.739777 
	C633.433716,357.335358 632.863159,357.897736 632.295227,357.895081 
	C619.734192,357.836639 607.173523,357.715790 594.306396,357.326965 
z"/>
<path fill="#084EC4" opacity="1.000000" stroke="none" 
	d="
M641.301270,357.329285 
	C645.793640,356.831940 650.591187,356.613007 655.692871,356.671875 
	C651.200195,357.169006 646.403259,357.388336 641.301270,357.329285 
z"/>
<path fill="#084EC4" opacity="1.000000" stroke="none" 
	d="
M577.374939,360.962616 
	C579.179871,357.402252 582.027222,355.227936 586.753540,356.766235 
	C583.910767,358.334778 580.823608,359.626251 577.374939,360.962616 
z"/>
<path fill="#084EC4" opacity="1.000000" stroke="none" 
	d="
M660.179932,357.197540 
	C660.870117,356.784576 661.742737,356.626862 662.808472,356.700653 
	C662.121826,357.105682 661.242065,357.279236 660.179932,357.197540 
z"/>
<path fill="#084EC4" opacity="1.000000" stroke="none" 
	d="
M665.188782,356.842682 
	C665.095703,356.626556 665.299377,356.386780 665.789673,356.107971 
	C665.900024,356.330963 665.703125,356.580994 665.188782,356.842682 
z"/>
<path fill="#0240A9" opacity="1.000000" stroke="none" 
	d="
M695.035278,343.342529 
	C696.664246,344.758392 698.328491,346.512360 699.997559,348.631042 
	C698.358459,347.224060 696.714539,345.452362 695.035278,343.342529 
z"/>
<path fill="#0240A9" opacity="1.000000" stroke="none" 
	d="
M701.040466,350.336426 
	C701.979919,350.769073 702.954956,351.541351 703.969177,352.651306 
	C703.030945,352.218018 702.053528,351.447052 701.040466,350.336426 
z"/>
<path fill="#0240A9" opacity="1.000000" stroke="none" 
	d="
M690.046387,338.318054 
	C690.637878,338.454559 691.273254,338.909088 691.951904,339.681732 
	C691.360229,339.545288 690.725281,339.090698 690.046387,338.318054 
z"/>
<path fill="#043ED5" opacity="1.000000" stroke="none" 
	d="
M702.796204,379.787109 
	C704.002563,382.768036 705.008911,385.493378 706.023071,388.606293 
	C705.858337,389.007996 705.685730,389.022125 705.113892,388.736084 
	C703.137939,387.298492 701.561157,386.161102 699.984375,385.023682 
	C698.539368,383.720612 697.094299,382.417542 695.325867,381.055969 
	C696.073242,380.003723 697.144043,379.009949 698.600098,378.006531 
	C700.188965,378.508423 701.392517,379.019958 702.796204,379.787109 
z"/>
<path fill="#0439BA" opacity="1.000000" stroke="none" 
	d="
M699.992554,385.389282 
	C701.561157,386.161102 703.137939,387.298492 704.854126,388.741760 
	C701.274109,391.066345 700.659119,388.377686 699.992554,385.389282 
z"/>
<path fill="#01389F" opacity="1.000000" stroke="none" 
	d="
M726.285706,365.850037 
	C723.395020,363.032196 720.936768,359.974518 718.228271,357.157349 
	C712.247864,350.936829 706.141418,344.837402 700.039429,337.977325 
	C699.092651,335.775238 698.317810,334.189240 697.269165,332.811676 
	C694.236816,328.828339 691.094360,324.928864 688.380188,320.947632 
	C694.616943,320.687195 700.468933,320.481995 706.320190,320.259674 
	C707.882019,320.200317 709.441772,320.088409 711.300537,320.148987 
	C713.279053,321.911011 715.043701,323.446838 716.623474,325.153748 
	C720.387939,329.221436 724.336182,331.866028 730.537292,331.185669 
	C737.614868,330.409180 744.844055,331.013794 752.007324,331.018219 
	C747.952209,331.997131 743.925354,333.131836 739.827332,333.873749 
	C738.314453,334.147675 735.944763,332.722015 735.163574,333.417297 
	C733.093994,335.259125 730.612854,337.670654 730.227966,340.154846 
	C729.465027,345.079468 730.002502,350.205536 729.515320,355.157257 
	C729.206970,357.863708 729.037354,360.597382 728.513672,363.261505 
	C728.334900,364.170746 727.266541,364.905060 726.285706,365.850037 
z"/>
<path fill="#013498" opacity="1.000000" stroke="none" 
	d="
M791.986389,343.029755 
	C779.709290,343.028992 767.431946,343.071625 755.155457,342.985870 
	C753.043945,342.971130 750.936340,342.409943 748.161377,341.925659 
	C747.024414,341.446655 746.553101,341.143188 746.065430,340.537781 
	C746.005615,339.835480 745.962158,339.435059 745.918640,339.034668 
	C748.025391,338.599792 750.132080,338.164886 752.688843,337.637085 
	C752.965027,336.529785 753.435486,334.643585 754.421631,332.377106 
	C755.936584,331.996674 756.939026,331.944794 757.934692,332.004303 
	C768.496033,332.635193 779.053467,333.345795 789.621155,333.835510 
	C790.725769,333.886688 791.889587,332.659943 793.443481,332.005432 
	C799.116760,331.285126 804.372437,330.585480 809.628113,329.885834 
	C809.629211,329.162720 809.630310,328.439606 809.631409,327.716492 
	C806.751831,327.474823 803.872253,327.233124 801.063599,326.623779 
	C803.314026,325.542542 805.478516,324.293243 807.676208,324.231750 
	C811.728149,324.118347 815.860291,325.244202 819.840698,324.798035 
	C822.337708,324.518066 824.618896,322.312744 826.996887,320.971252 
	C829.041260,320.976624 831.085632,320.982025 833.604492,321.338226 
	C835.386780,324.126007 836.694580,326.563019 837.963623,329.354492 
	C834.768188,336.685120 832.265259,338.404968 826.885254,335.721771 
	C817.202759,330.892761 807.082275,333.750000 797.164673,333.214935 
	C796.549744,333.181793 795.887268,334.030914 795.124023,334.553680 
	C795.741272,335.923035 796.255493,337.063873 796.524414,337.660431 
	C794.803467,339.696686 793.394897,341.363220 791.986389,343.029755 
z"/>
<path fill="#14A1D9" opacity="1.000000" stroke="none" 
	d="
M748.110168,343.895660 
	C750.196533,344.260986 752.281006,344.930725 754.369629,344.944031 
	C772.833618,345.061737 791.299438,344.953522 809.762512,345.133820 
	C824.041016,345.273285 838.319336,345.643951 852.589661,346.142303 
	C854.691467,346.215759 856.748657,347.565338 858.889099,348.700623 
	C858.521118,349.065643 858.090576,349.054504 857.052612,348.740356 
	C849.661377,348.295166 842.877197,348.057129 836.093567,348.069244 
	C834.064636,348.072876 832.036926,348.775696 830.008667,349.158173 
	C829.145813,349.243561 828.282959,349.328918 826.856689,349.612946 
	C825.867981,349.845215 825.442627,349.878815 825.017273,349.912384 
	C823.948975,349.880249 822.880615,349.848145 821.092651,349.539795 
	C804.980713,349.209381 789.588562,349.141998 774.196350,349.141602 
	C773.464905,349.141571 772.733521,349.734680 772.002075,350.051605 
	C765.980957,350.040863 759.959778,350.030121 753.282959,349.791748 
	C751.753418,349.748291 750.879639,349.932465 750.005859,350.116669 
	C749.622864,350.144958 749.239929,350.173248 748.431946,350.100159 
	C748.006958,349.998749 747.739929,349.741486 747.823608,349.358368 
	C747.844299,347.406891 747.781311,345.838562 747.816162,344.176422 
	C747.914001,344.082642 748.110168,343.895660 748.110168,343.895660 
z"/>
<path fill="#0137AC" opacity="1.000000" stroke="none" 
	d="
M793.025940,332.026062 
	C791.889587,332.659943 790.725769,333.886688 789.621155,333.835510 
	C779.053467,333.345795 768.496033,332.635193 757.934692,332.004303 
	C756.939026,331.944794 755.936584,331.996674 754.468628,331.997803 
	C753.889709,331.681488 753.699402,331.423187 753.210510,330.760376 
	C752.661072,329.195831 752.330261,328.094666 752.112427,326.778259 
	C752.427673,326.296112 752.686646,326.107056 753.452271,325.998169 
	C756.601379,325.999725 759.300720,325.998962 762.428467,326.000366 
	C764.571289,326.001251 766.285645,326.000031 768.467285,326.000732 
	C774.601013,325.687042 780.265320,325.314117 785.935730,325.109314 
	C786.946777,325.072784 787.982910,325.733521 788.841492,326.377808 
	C788.267578,327.989227 787.859619,329.295166 787.451721,330.601105 
	C789.269592,331.035980 791.087524,331.470856 792.935547,331.935791 
	C792.965698,331.965881 793.025940,332.026062 793.025940,332.026062 
z"/>
<path fill="#0337B4" opacity="1.000000" stroke="none" 
	d="
M751.999390,326.993530 
	C752.330261,328.094666 752.661072,329.195831 752.994751,330.647278 
	C752.997559,330.997559 752.502258,331.006104 752.254761,331.012146 
	C744.844055,331.013794 737.614868,330.409180 730.537292,331.185669 
	C724.336182,331.866028 720.387939,329.221436 716.623474,325.153748 
	C715.043701,323.446838 713.279053,321.911011 711.575684,319.867676 
	C713.714294,319.275360 715.875671,319.112732 718.352173,318.988953 
	C719.458740,319.660706 720.250366,320.293579 721.067261,321.288513 
	C723.467163,325.625854 727.312866,326.828369 731.573853,326.964752 
	C736.048584,327.107941 740.531555,326.993561 745.447632,326.991577 
	C747.922729,326.992157 749.961060,326.992828 751.999390,326.993530 
z"/>
<path fill="#065EAF" opacity="1.000000" stroke="none" 
	d="
M772.308716,350.330139 
	C772.733521,349.734680 773.464905,349.141571 774.196350,349.141602 
	C789.588562,349.141998 804.980713,349.209381 820.692261,349.521973 
	C819.485901,350.175964 817.965576,350.887665 816.433594,350.913605 
	C808.935913,351.040588 801.435120,350.984863 793.466492,350.999084 
	C786.203491,350.872498 779.409424,350.740570 772.308716,350.330139 
z"/>
<path fill="#065EAF" opacity="1.000000" stroke="none" 
	d="
M745.488281,339.034973 
	C745.962158,339.435059 746.005615,339.835480 745.704224,340.594971 
	C744.877197,341.007172 744.395142,341.060272 743.536804,341.098389 
	C742.154724,341.109406 741.148865,341.135437 739.772888,341.128998 
	C734.605408,344.102570 733.875916,346.620972 736.847168,350.354034 
	C736.914368,350.556885 737.071228,350.954376 737.188721,351.284943 
	C741.087769,355.787598 744.562195,352.990448 748.078369,351.140686 
	C747.646851,355.571289 743.782837,355.846100 741.084534,355.569977 
	C736.829651,355.134491 733.167786,352.357330 732.691223,347.842773 
	C732.456360,345.617920 733.889587,342.108673 735.678223,341.041748 
	C738.278503,339.490662 741.882080,339.621582 745.488281,339.034973 
z"/>
<path fill="#14A1D9" opacity="1.000000" stroke="none" 
	d="
M748.127502,350.918274 
	C744.562195,352.990448 741.087769,355.787598 737.572510,351.304382 
	C741.464844,353.151306 744.523071,350.819214 747.739929,349.741486 
	C747.739929,349.741486 748.006958,349.998749 748.144775,350.125793 
	C748.282593,350.252808 748.176636,350.695862 748.127502,350.918274 
z"/>
<path fill="#065EAF" opacity="1.000000" stroke="none" 
	d="
M750.203125,350.340759 
	C750.879639,349.932465 751.753418,349.748291 752.815063,349.807739 
	C752.135498,350.222504 751.267944,350.393677 750.203125,350.340759 
z"/>
<path fill="#0337B4" opacity="1.000000" stroke="none" 
	d="
M768.548584,420.967102 
	C768.954773,422.081665 768.953369,423.124603 768.928528,424.557007 
	C764.935364,424.964142 760.954895,425.146057 757.003296,424.887756 
	C755.972717,424.820404 755.031799,423.381775 754.049683,422.572906 
	C754.986755,421.718384 755.921387,420.139893 756.861389,420.136658 
	C760.620361,420.123657 764.381042,420.600067 768.548584,420.967102 
z"/>
<path fill="#065EAF" opacity="1.000000" stroke="none" 
	d="
M830.320801,349.426208 
	C832.036926,348.775696 834.064636,348.072876 836.093567,348.069244 
	C842.877197,348.057129 849.661377,348.295166 856.729248,348.750427 
	C852.985962,349.383423 848.962646,349.891327 844.930542,349.974487 
	C840.168701,350.072784 835.399170,349.803955 830.320801,349.426208 
z"/>
<path fill="#03429E" opacity="1.000000" stroke="none" 
	d="
M825.044067,350.223236 
	C825.442627,349.878815 825.867981,349.845215 826.612244,349.832184 
	C826.731140,350.403412 826.531189,350.954071 826.331238,351.504730 
	C825.911133,351.181183 825.491028,350.857605 825.044067,350.223236 
z"/>
<path fill="#0332AA" opacity="1.000000" stroke="none" 
	d="
M800.482178,398.898041 
	C804.571838,398.971222 808.234680,398.777344 811.851074,399.101593 
	C813.256836,399.227600 814.557861,400.522949 815.906616,401.285645 
	C814.566040,402.512665 813.321838,404.641968 811.866882,404.797394 
	C807.521057,405.261627 803.092529,404.951019 798.696350,404.945709 
	C796.236755,404.942749 793.777222,404.945160 790.183533,404.945160 
	C790.415039,402.664337 790.719116,399.668549 791.024597,396.659119 
	C794.412598,397.478241 797.224731,398.158142 800.482178,398.898041 
z"/>
<path fill="#0122A1" opacity="1.000000" stroke="none" 
	d="
M812.036072,421.715607 
	C812.031555,422.717865 812.019775,423.475098 812.013428,424.610046 
	C811.447144,425.560730 810.853882,426.648193 810.307129,426.625305 
	C805.899170,426.440826 801.504089,425.964386 797.096558,425.751404 
	C794.762451,425.638580 792.410767,425.941620 790.068665,425.913757 
	C789.228638,425.903778 788.393616,425.473816 787.287048,425.109558 
	C786.508057,420.969574 788.506287,419.709869 792.236206,419.966522 
	C798.540649,420.400269 804.857056,420.660370 811.595947,420.987000 
	C812.023499,420.980469 812.028748,421.470612 812.036072,421.715607 
z"/>
<path fill="#022D9A" opacity="1.000000" stroke="none" 
	d="
M857.931946,400.629028 
	C858.008118,402.065826 857.995972,403.127045 857.973511,404.585632 
	C851.134583,404.988495 844.300476,405.134552 837.482727,404.865936 
	C836.297302,404.819244 835.180847,403.021637 834.032532,402.032715 
	C835.303284,400.844574 836.401611,398.998657 837.882141,398.610565 
	C840.636597,397.888458 843.613770,398.015686 846.969238,397.752350 
	C846.151733,396.019226 845.612061,394.874969 845.433167,393.377441 
	C849.529541,393.015350 853.265015,393.006531 857.000549,392.997681 
	C854.802124,394.283173 852.603638,395.568695 849.352539,397.469757 
	C852.772766,398.591034 855.308167,399.422211 857.931946,400.629028 
z"/>
<path fill="#0433B7" opacity="1.000000" stroke="none" 
	d="
M857.266724,392.767456 
	C853.265015,393.006531 849.529541,393.015350 845.387512,393.023804 
	C843.328857,389.610809 841.676636,386.198212 840.003662,382.395386 
	C839.982910,382.005127 840.007324,382.005554 840.391724,382.006592 
	C841.523865,382.014008 842.271667,382.020386 843.024353,382.426514 
	C842.849365,385.657623 843.150513,388.137848 846.977722,388.469849 
	C849.174011,389.442688 852.505554,391.178406 853.158813,390.464294 
	C855.285034,388.139801 856.432129,384.919739 858.329956,382.028778 
	C859.449951,382.029541 860.196106,382.045929 860.942261,382.062317 
	C859.805786,385.553986 858.669312,389.045624 857.266724,392.767456 
z"/>
<path fill="#031AA5" opacity="1.000000" stroke="none" 
	d="
M843.019409,382.026794 
	C842.271667,382.020386 841.523865,382.014008 840.403076,382.008484 
	C841.663574,379.899994 843.297180,377.790588 844.980042,375.336914 
	C848.706970,374.991943 852.384644,374.991241 856.520264,374.980988 
	C858.310120,377.087219 859.641968,379.203003 860.958008,381.690552 
	C860.196106,382.045929 859.449951,382.029541 857.968140,382.004089 
	C856.505798,381.973755 855.779114,381.952515 854.847168,381.816895 
	C854.386597,381.510529 854.187500,381.271698 853.964722,380.609863 
	C849.648926,377.109985 846.420288,380.090118 843.019409,382.026794 
z"/>
<path fill="#0348CC" opacity="1.000000" stroke="none" 
	d="
M866.872864,329.885376 
	C866.465515,329.882935 866.058228,329.880463 865.258423,329.526428 
	C861.393616,327.641846 858.117920,326.756470 857.410400,321.567993 
	C856.694641,316.319702 859.445435,313.858032 862.826477,312.988678 
	C865.633423,312.266876 870.103088,313.278961 872.092346,315.267456 
	C874.779541,317.953705 875.196045,322.169617 871.249573,325.304626 
	C869.611450,326.605927 868.321289,328.345245 866.872864,329.885376 
M868.835266,319.069244 
	C867.101624,318.366119 865.296570,317.028809 863.662842,317.212830 
	C862.773071,317.312988 861.498047,320.079529 861.673767,321.483765 
	C861.797546,322.473175 864.522217,324.177948 865.287476,323.826294 
	C866.807617,323.127808 867.776733,321.230347 868.835266,319.069244 
z"/>
<path fill="#01319F" opacity="1.000000" stroke="none" 
	d="
M867.250183,329.920837 
	C868.321289,328.345245 869.611450,326.605927 871.249573,325.304626 
	C875.196045,322.169617 874.779541,317.953705 872.092346,315.267456 
	C870.103088,313.278961 865.633423,312.266876 862.826477,312.988678 
	C859.445435,313.858032 856.694641,316.319702 857.410400,321.567993 
	C858.117920,326.756470 861.393616,327.641846 864.952942,329.527252 
	C863.913452,330.565796 862.787048,331.251953 861.322754,331.978241 
	C859.013306,329.921722 857.041748,327.825104 855.032593,324.928467 
	C854.278809,318.563751 855.557983,314.056366 860.994141,311.199921 
	C862.094238,310.621887 862.353271,308.443207 863.000000,307.002441 
	C864.455872,306.899628 865.911804,306.796844 867.682800,306.845154 
	C868.738892,308.300720 869.173340,310.308014 870.273987,310.787994 
	C875.067993,312.879028 877.025940,317.196503 875.664368,321.202606 
	C874.497925,324.634552 870.418579,327.076508 867.250183,329.920837 
z"/>
<path fill="#012D8D" opacity="1.000000" stroke="none" 
	d="
M862.531433,306.992401 
	C862.353271,308.443207 862.094238,310.621887 860.994141,311.199921 
	C855.557983,314.056366 854.278809,318.563751 854.988953,324.574463 
	C852.231445,323.057373 848.846741,321.581055 846.876099,319.017731 
	C843.883972,315.125580 840.465881,313.719940 835.770630,313.966156 
	C826.660156,314.443817 817.538269,314.839294 808.417236,314.950256 
	C794.929993,315.114349 781.439331,315.000610 767.013062,315.001556 
	C761.988831,314.996796 757.892151,315.152893 753.820251,314.903595 
	C752.512329,314.823517 751.269958,313.673248 750.441711,313.008545 
	C754.287781,312.813232 758.994690,314.491791 758.022583,307.794769 
	C758.026917,307.562714 758.020020,307.098602 758.020020,307.098633 
	C758.452209,307.095673 758.884460,307.092743 759.658325,307.498169 
	C761.026794,309.589569 761.835632,312.513458 763.118469,312.738739 
	C768.399597,313.666290 772.696899,310.587799 777.484131,308.021759 
	C794.571350,308.014343 811.191162,308.037415 827.810486,307.959259 
	C829.540222,307.951111 831.267273,307.342560 832.995544,307.012604 
	C842.684631,307.002533 852.373779,306.992432 862.531433,306.992401 
z"/>
<path fill="#001C8F" opacity="1.000000" stroke="none" 
	d="
M749.997559,313.012573 
	C751.269958,313.673248 752.512329,314.823517 753.820251,314.903595 
	C757.892151,315.152893 761.988831,314.996796 766.545288,315.001404 
	C767.008606,315.756378 767.002563,316.507904 766.611450,317.801697 
	C764.433960,318.563080 762.642700,318.959595 760.848877,318.971558 
	C751.371216,319.034515 741.891357,318.916199 732.416382,319.087738 
	C730.928467,319.114685 729.461548,320.303528 727.739990,320.965393 
	C727.495178,320.975647 727.004883,320.989014 726.577881,320.988007 
	C724.447876,320.966827 722.744934,320.946655 721.041931,320.926483 
	C720.250366,320.293579 719.458740,319.660706 718.342041,318.593018 
	C718.010864,317.097412 718.004822,316.036621 718.371765,314.979431 
	C719.490540,314.978271 720.236389,314.973572 721.361694,314.977844 
	C722.500244,314.978729 723.259399,314.970673 724.481567,314.967438 
	C727.438416,314.977325 729.946167,315.143005 732.420654,314.927399 
	C733.912964,314.797394 735.353699,314.074677 736.817505,313.618286 
	C736.158875,312.192078 735.785522,310.516174 734.792908,309.382324 
	C730.166138,304.096863 725.622559,298.692688 720.534546,293.874512 
	C717.561401,291.059052 713.730347,287.554565 710.095581,287.321808 
	C697.512146,286.516083 684.845581,286.992737 672.210938,287.047882 
	C671.474426,287.051086 670.740540,287.642212 669.540527,287.972839 
	C664.723938,288.008789 660.372253,288.031586 655.880127,287.796387 
	C655.424683,287.315399 655.109619,287.092438 654.779785,286.438568 
	C654.942444,284.011017 655.119812,282.014374 655.553528,280.067017 
	C656.210388,280.407074 656.611023,280.697815 657.102173,281.384277 
	C658.856384,282.186249 660.518005,282.935944 662.183838,282.945282 
	C677.633911,283.031830 693.132019,283.721527 708.518372,282.725677 
	C718.496765,282.079895 722.981079,288.773865 728.738098,294.125854 
	C735.185181,300.119324 741.273560,306.497681 747.597107,312.627014 
	C748.073853,313.089142 749.182617,312.899353 749.997559,313.012573 
z"/>
<path fill="#01308D" opacity="1.000000" stroke="none" 
	d="
M792.456543,343.034546 
	C793.394897,341.363220 794.803467,339.696686 796.524414,337.660431 
	C796.255493,337.063873 795.741272,335.923035 795.124023,334.553680 
	C795.887268,334.030914 796.549744,333.181793 797.164673,333.214935 
	C807.082275,333.750000 817.202759,330.892761 826.885254,335.721771 
	C832.265259,338.404968 834.768188,336.685120 838.354248,329.354736 
	C841.518372,328.999512 844.253113,328.998535 846.987793,328.997559 
	C849.504700,331.451019 852.522400,333.590637 854.336121,336.484741 
	C855.304810,338.030457 854.195435,340.878418 854.029968,343.536987 
	C833.666260,343.636658 813.296448,343.338013 792.456543,343.034546 
z"/>
<path fill="#022590" opacity="1.000000" stroke="none" 
	d="
M727.984863,320.955139 
	C729.461548,320.303528 730.928467,319.114685 732.416382,319.087738 
	C741.891357,318.916199 751.371216,319.034515 760.848877,318.971558 
	C762.642700,318.959595 764.433960,318.563080 766.608337,318.177551 
	C768.920532,318.339447 770.848450,318.934082 772.781616,318.951843 
	C783.222046,319.047943 793.668518,318.816223 804.102783,319.087128 
	C808.806946,319.209320 813.474121,320.401459 818.184265,320.787292 
	C820.927246,321.011993 823.722107,320.601685 826.745239,320.724182 
	C824.618896,322.312744 822.337708,324.518066 819.840698,324.798035 
	C815.860291,325.244202 811.728149,324.118347 807.676208,324.231750 
	C805.478516,324.293243 803.314026,325.542542 800.677734,326.625671 
	C798.483276,326.658661 796.745789,326.322021 794.960205,325.616394 
	C793.738220,321.697327 791.215332,320.867889 787.645691,320.926514 
	C775.766602,321.121674 763.882324,321.001648 751.531372,321.001221 
	C743.370117,320.985046 735.677490,320.970093 727.984863,320.955139 
z"/>
<path fill="#022590" opacity="1.000000" stroke="none" 
	d="
M846.838379,328.691956 
	C844.253113,328.998535 841.518372,328.999512 838.393066,329.000244 
	C836.694580,326.563019 835.386780,324.126007 834.042603,321.340271 
	C840.590881,319.413879 842.756958,325.414581 846.838379,328.691956 
z"/>
<path fill="#042A80" opacity="1.000000" stroke="none" 
	d="
M902.021912,381.984100 
	C908.160645,383.494598 908.029114,389.247345 908.671021,393.475311 
	C909.955200,401.933594 909.124329,410.482086 904.931641,418.821350 
	C902.117371,419.405884 900.006897,419.931152 897.889954,419.958557 
	C889.926514,420.061584 881.960876,420.002319 873.996094,420.003876 
	C874.990479,414.935181 875.984802,409.866455 877.045959,404.058807 
	C878.264038,402.458954 879.292725,401.238251 880.587097,400.797394 
	C887.340332,398.497131 891.897339,393.894867 895.596741,387.945282 
	C897.084900,385.551849 899.842224,383.947540 902.021912,381.984100 
z"/>
<path fill="#022590" opacity="1.000000" stroke="none" 
	d="
M901.775635,381.694885 
	C899.842224,383.947540 897.084900,385.551849 895.596741,387.945282 
	C891.897339,393.894867 887.340332,398.497131 880.587097,400.797394 
	C879.292725,401.238251 878.264038,402.458954 877.053406,403.668030 
	C876.665833,403.239655 876.337646,402.463104 876.005188,401.351074 
	C876.334106,399.602020 876.667236,398.188416 877.260620,396.177307 
	C877.678711,394.725555 877.836670,393.871216 877.994568,393.016907 
	C877.994568,393.016907 877.999512,393.001709 878.372070,392.990662 
	C880.817688,389.974243 882.890747,386.968842 884.963806,383.963440 
	C890.043335,380.245972 895.428650,378.509064 901.775635,381.694885 
z"/>
<path fill="#022590" opacity="1.000000" stroke="none" 
	d="
M873.720825,420.274689 
	C881.960876,420.002319 889.926514,420.061584 897.889954,419.958557 
	C900.006897,419.931152 902.117371,419.405884 904.655701,419.062897 
	C905.030762,420.693054 904.980957,422.369568 904.883301,424.459381 
	C895.171143,424.913300 885.506897,424.978943 875.842651,424.980865 
	C872.447937,424.981537 872.757141,422.829926 873.720825,420.274689 
z"/>
<path fill="#14A1D9" opacity="1.000000" stroke="none" 
	d="
M743.913025,341.113342 
	C744.395142,341.060272 744.877197,341.007172 745.720520,340.896912 
	C746.553101,341.143188 747.024414,341.446655 747.744751,341.877686 
	C748.008911,342.426453 748.024170,342.847687 748.074768,343.582275 
	C748.110168,343.895660 747.914001,344.082642 747.530640,344.056366 
	C746.069153,343.057831 744.991089,342.085571 743.913025,341.113342 
z"/>
<path fill="#042A80" opacity="1.000000" stroke="none" 
	d="
M884.590881,383.978577 
	C882.890747,386.968842 880.817688,389.974243 878.378967,392.986755 
	C878.460022,388.875946 878.975769,384.805664 884.590881,383.978577 
z"/>
<path fill="#042A80" opacity="1.000000" stroke="none" 
	d="
M877.736816,393.224152 
	C877.836670,393.871216 877.678711,394.725555 877.267029,395.791260 
	C877.168518,395.145599 877.323792,394.288513 877.736816,393.224152 
z"/>
<path fill="#EDF5F8" opacity="1.000000" stroke="none" 
	d="
M557.990295,376.793640 
	C558.065430,377.135529 558.028687,377.308350 557.983093,378.147949 
	C558.406189,381.875977 558.838196,384.937195 559.121216,388.307129 
	C558.969727,389.067139 558.967224,389.518463 558.743164,390.295074 
	C556.535400,404.157928 554.565186,417.697906 552.545959,431.230560 
	C552.238098,433.294006 551.550171,435.419708 555.302368,435.245422 
	C556.157776,435.479004 556.672485,435.519745 557.187195,435.560486 
	C557.187195,435.560486 557.126404,435.582428 557.122437,435.615601 
	C559.116089,436.374237 561.113708,437.099701 563.103577,437.822327 
	C563.418762,443.966431 562.859070,444.640320 557.274353,444.647614 
	C546.170715,444.662048 535.067078,444.651886 523.770325,444.651886 
	C523.541809,440.251038 525.083679,437.587402 529.321777,437.175201 
	C535.807678,436.544373 538.392822,433.102966 538.865723,426.591949 
	C539.489563,418.001587 541.195374,409.489807 542.532776,400.267395 
	C542.531982,399.288269 542.434509,398.985962 542.591431,398.357788 
	C542.931885,396.671417 543.017944,395.310913 543.280518,393.664551 
	C543.432373,391.866302 543.407776,390.353882 543.405762,388.444031 
	C542.048889,381.559265 538.502808,378.574005 531.662842,378.141785 
	C524.746399,377.704773 513.682678,382.870453 512.340332,387.164917 
	C512.290894,387.323029 512.245422,387.484680 512.221008,387.648102 
	C510.753235,397.466980 509.287842,407.286255 507.613373,417.273621 
	C507.452881,417.946930 507.501221,418.452087 507.452026,419.267426 
	C507.350098,420.008606 507.345703,420.439636 507.169434,421.218140 
	C506.907227,422.828369 506.816833,424.091064 506.490051,425.703613 
	C505.931641,428.780304 505.609619,431.507141 505.225006,434.764221 
	C509.238159,435.613129 513.041565,436.417633 516.844971,437.222168 
	C516.844971,437.222168 516.870300,437.116516 516.904541,437.529236 
	C516.533325,440.753113 517.280884,444.582733 512.918030,444.646576 
	C500.478516,444.828583 488.034607,444.710510 475.659363,444.710510 
	C474.732056,438.180267 479.392029,437.560364 483.446533,436.401672 
	C491.216522,434.181091 491.222565,434.202179 491.552704,425.690765 
	C491.801239,424.217163 491.868530,423.120361 492.123779,421.657867 
	C493.284790,414.510712 494.257812,407.729218 495.379150,400.911194 
	C495.698090,400.686493 495.690491,400.505737 495.618652,399.906281 
	C495.845245,398.020721 495.957825,396.561218 496.255096,394.753967 
	C496.603760,392.613220 496.767670,390.820221 497.056274,388.761230 
	C497.163177,388.111786 497.145416,387.728302 497.344147,387.004150 
	C498.750977,383.277191 498.049316,380.998962 494.134247,380.222717 
	C492.378876,379.874634 490.484131,379.344452 489.091400,378.307587 
	C487.768005,377.322296 487.016907,375.568298 486.203308,373.818787 
	C487.742401,372.368774 488.934937,370.695618 490.477325,370.259369 
	C493.137634,369.506989 496.010254,369.460510 498.803101,369.231171 
	C504.167328,368.790710 509.537994,368.429504 514.906006,368.035431 
	C514.935608,368.765594 514.965210,369.495758 514.844055,370.895386 
	C513.916321,373.876617 513.139343,376.188354 512.362366,378.500092 
	C512.710144,378.666687 513.057861,378.833282 513.405640,378.999908 
	C514.959717,377.728302 516.513733,376.456696 518.067810,375.185089 
	C525.629272,370.317627 533.314941,365.832672 542.829956,366.507080 
	C549.383789,366.971527 554.687012,369.309631 557.596802,376.031372 
	C557.887878,376.219208 557.878479,376.624542 557.990295,376.793640 
z"/>
<path fill="#086CD7" opacity="1.000000" stroke="none" 
	d="
M543.383179,388.841461 
	C543.407776,390.353882 543.432373,391.866302 543.021423,393.838348 
	C542.384888,394.875488 542.183899,395.453003 541.705200,396.259735 
	C541.261902,397.678009 541.096252,398.867035 540.930664,400.056091 
	C540.812073,400.313599 540.641846,400.530609 539.877625,400.850098 
	C536.394714,403.554962 533.282410,405.947449 530.552185,408.716644 
	C525.930847,413.403839 521.550964,418.329071 516.742920,423.098755 
	C516.203003,422.985962 515.989746,422.928650 515.788452,422.480347 
	C515.719910,420.862793 515.639282,419.636200 515.507812,417.635620 
	C512.369568,418.878754 509.855438,419.874664 507.341278,420.870605 
	C507.345703,420.439636 507.350098,420.008606 507.599426,419.141998 
	C507.960724,418.471832 508.095459,418.248383 508.578308,417.938416 
	C509.734558,417.044769 510.501526,415.638000 511.397156,415.550629 
	C517.775208,414.928528 520.611389,409.636200 524.641846,405.906738 
	C530.843262,400.168488 535.999023,393.280243 543.383179,388.841461 
z"/>
<path fill="#043BA2" opacity="1.000000" stroke="none" 
	d="
M517.069580,423.154236 
	C521.550964,418.329071 525.930847,413.403839 530.552185,408.716644 
	C533.282410,405.947449 536.394714,403.554962 539.702759,400.963623 
	C539.095581,407.994812 538.120789,415.055450 536.614868,422.547821 
	C529.745667,423.037781 523.407654,423.096008 517.069580,423.154236 
z"/>
<path fill="#032495" opacity="1.000000" stroke="none" 
	d="
M516.742920,423.098755 
	C523.407654,423.096008 529.745667,423.037781 536.553589,422.946533 
	C536.796265,424.719635 536.569214,426.525726 536.182861,428.689026 
	C529.578186,429.030060 523.131165,429.086609 516.688477,428.943665 
	C515.185242,428.910309 513.697083,428.199646 512.104370,427.395142 
	C512.006714,426.989685 512.039490,426.983887 512.410706,426.984619 
	C513.454529,426.189270 514.127197,425.393219 514.839722,424.343689 
	C515.178528,423.683929 515.477478,423.277618 515.776428,422.871307 
	C515.989746,422.928650 516.203003,422.985962 516.742920,423.098755 
z"/>
<path fill="#043BA2" opacity="1.000000" stroke="none" 
	d="
M541.302246,400.043213 
	C541.096252,398.867035 541.261902,397.678009 541.729614,396.705017 
	C542.133423,397.508606 542.235229,398.096130 542.337036,398.683655 
	C542.434509,398.985962 542.531982,399.288269 542.523438,399.817749 
	C542.169556,400.040100 541.921753,400.035217 541.302246,400.043213 
z"/>
<path fill="#0641A6" opacity="1.000000" stroke="none" 
	d="
M558.964600,389.969818 
	C558.967224,389.518463 558.969727,389.067139 559.474121,388.198792 
	C560.302246,385.546204 560.628479,383.310608 560.954712,381.075043 
	C567.747498,381.054077 574.547485,380.865631 581.326599,381.159698 
	C582.615051,381.215637 583.820557,383.181946 585.111816,384.693085 
	C585.159363,385.119354 585.125854,385.086731 584.860596,385.090363 
	C584.412781,385.765991 584.230225,386.437988 584.047607,387.109985 
	C584.159912,387.460693 584.069153,387.747070 583.169678,388.041779 
	C579.546265,390.085846 576.788818,392.154144 579.528381,396.268707 
	C576.819153,405.384216 575.371826,414.615509 576.932007,424.551208 
	C576.476013,424.726776 576.059509,424.477570 575.350220,423.763367 
	C574.697693,422.210419 574.337952,421.122467 573.994385,419.615479 
	C573.764343,417.498047 573.518188,415.799652 573.118896,413.044525 
	C570.137085,416.360168 568.058777,418.671143 565.980469,420.982117 
	C564.211243,421.661591 562.442017,422.341064 559.912964,423.027161 
	C558.224304,422.127228 556.408691,421.112091 556.504456,420.330994 
	C557.417297,412.877014 558.826538,405.482971 559.703613,398.025909 
	C560.011780,395.405792 559.242126,392.658844 558.964600,389.969818 
z"/>
<path fill="#1E4796" opacity="1.000000" stroke="none" 
	d="
M558.743103,390.295074 
	C559.242126,392.658844 560.011780,395.405792 559.703613,398.025909 
	C558.826538,405.482971 557.417297,412.877014 556.504456,420.330994 
	C556.408691,421.112091 558.224304,422.127228 559.545349,423.045715 
	C558.868896,424.741669 557.661377,426.356049 556.771973,428.129913 
	C555.934387,429.800354 555.432129,431.638885 554.904541,433.968475 
	C555.005066,434.706482 554.983398,434.879517 554.961670,435.052551 
	C551.550171,435.419708 552.238098,433.294006 552.545959,431.230560 
	C554.565186,417.697906 556.535400,404.157928 558.743103,390.295074 
z"/>
<path fill="#0449D6" opacity="1.000000" stroke="none" 
	d="
M566.024170,421.332397 
	C568.058777,418.671143 570.137085,416.360168 573.118896,413.044525 
	C573.518188,415.799652 573.764343,417.498047 573.624512,419.614838 
	C571.848816,421.680420 570.459045,423.327667 569.069275,424.974854 
	C568.068848,423.877441 567.068420,422.780029 566.024170,421.332397 
z"/>
<path fill="#0B76EC" opacity="1.000000" stroke="none" 
	d="
M569.235596,425.284454 
	C570.459045,423.327667 571.848816,421.680420 573.608398,420.033875 
	C574.337952,421.122467 574.697693,422.210419 575.141724,423.636597 
	C574.066040,427.325104 572.194214,428.115082 569.235596,425.284454 
z"/>
<path fill="#013AA5" opacity="1.000000" stroke="none" 
	d="
M555.302368,435.245422 
	C554.983398,434.879517 555.005066,434.706482 555.053711,434.273468 
	C555.681641,434.313049 556.282532,434.612640 557.035339,435.236359 
	C556.672485,435.519745 556.157776,435.479004 555.302368,435.245422 
z"/>
<path fill="#1546AD" opacity="1.000000" stroke="none" 
	d="
M605.260559,398.269775 
	C604.034668,398.339233 602.808716,398.408691 601.055298,398.309174 
	C600.139160,397.762268 599.750610,397.384308 599.703308,396.953491 
	C601.028137,395.921021 602.770081,395.011658 602.868408,393.950104 
	C603.384888,388.372803 606.054077,388.921173 610.445557,389.923737 
	C613.542236,390.630707 617.466492,389.359833 620.440979,387.838043 
	C621.219116,387.439941 620.580383,382.363342 619.291016,380.217346 
	C616.293396,375.228241 609.092468,376.234741 605.140564,381.687164 
	C602.549683,385.261871 599.739746,388.677856 596.870789,391.783020 
	C596.575867,391.162903 596.438049,390.924622 596.300232,390.686340 
	C598.738525,386.817383 600.853088,382.686981 603.706055,379.152802 
	C606.565857,375.610168 610.799011,374.568207 615.353821,375.075287 
	C619.678223,375.556702 622.310242,377.825073 623.050293,382.164612 
	C623.161438,382.816193 623.226135,383.477112 623.283508,384.136200 
	C624.280396,395.592407 622.846619,397.273865 611.566650,397.898529 
	C609.915039,397.989990 608.264221,398.095428 606.032166,398.161682 
	C605.451294,398.128937 605.260559,398.269775 605.260559,398.269775 
z"/>
<path fill="#35BDFA" opacity="1.000000" stroke="none" 
	d="
M599.362061,397.006348 
	C599.750610,397.384308 600.139160,397.762268 600.687622,398.295715 
	C598.921082,398.639343 596.994507,398.827484 593.886719,399.130981 
	C594.650574,395.967651 595.233887,393.551819 596.058716,390.911163 
	C596.438049,390.924622 596.575867,391.162903 597.005371,392.086243 
	C597.985413,394.183014 598.673706,395.594696 599.362061,397.006348 
z"/>
<path fill="#032699" opacity="1.000000" stroke="none" 
	d="
M579.737000,396.001282 
	C576.788818,392.154144 579.546265,390.085846 582.940063,388.108215 
	C582.192749,390.645966 581.069153,393.189911 579.737000,396.001282 
z"/>
<path fill="#032699" opacity="1.000000" stroke="none" 
	d="
M584.318420,386.968781 
	C584.230225,386.437988 584.412781,385.765991 584.873047,385.099945 
	C584.963562,385.679779 584.776428,386.253662 584.318420,386.968781 
z"/>
<path fill="#35BDFA" opacity="1.000000" stroke="none" 
	d="
M605.264038,398.380951 
	C605.260559,398.269775 605.451294,398.128937 605.551758,398.182678 
	C605.652161,398.236389 605.585876,398.498535 605.585876,398.498535 
	C605.585876,398.498535 605.267517,398.492126 605.264038,398.380951 
z"/>
<path fill="#033494" opacity="1.000000" stroke="none" 
	d="
M368.466431,454.999878 
	C367.534790,452.827148 367.072052,450.660583 366.609314,448.493988 
	C364.193695,449.015167 361.555725,449.088348 359.409821,450.153107 
	C355.269745,452.207397 352.469421,451.263916 351.436005,446.764954 
	C350.453918,442.489349 349.590607,438.186432 348.429657,432.745392 
	C349.020142,432.643646 350.883575,432.226471 352.770020,432.019348 
	C355.846588,431.681549 358.920898,431.458313 359.443359,426.791809 
	C364.605713,426.264435 369.305634,426.190277 374.323395,426.375610 
	C381.425323,426.416870 388.209351,426.198700 395.324341,425.991669 
	C396.093109,425.959595 396.531006,425.916412 397.276672,425.988281 
	C398.005249,426.219025 398.425964,426.334686 398.979431,426.728180 
	C400.462189,426.841003 401.812195,426.675934 403.506104,426.416626 
	C404.551849,426.199127 405.253662,426.075928 406.424347,426.007690 
	C412.055328,426.056946 417.219025,426.129059 422.379303,426.027771 
	C430.846985,425.861603 439.312531,425.582916 447.812225,425.841003 
	C447.485596,428.231995 447.125702,430.133636 446.585815,432.986176 
	C439.325317,429.216705 432.550781,428.033875 427.390015,435.924255 
	C424.416504,434.713715 421.789307,433.562561 418.723785,432.219330 
	C418.295288,434.263062 418.126434,434.888092 418.037140,435.524323 
	C417.562164,438.908508 418.754730,441.958313 422.015228,442.531403 
	C423.518005,442.795593 425.522644,440.115356 427.327240,438.808136 
	C427.845459,438.432770 428.459503,438.189667 429.352112,438.021454 
	C436.823151,443.627808 444.712952,444.572876 453.225311,442.150452 
	C462.181793,439.601624 467.702942,432.996765 473.186310,426.024963 
	C476.706543,426.482147 479.841553,426.765228 482.976562,427.048279 
	C481.179443,427.699646 479.400940,428.830719 477.581268,428.901276 
	C473.939209,429.042572 470.723938,429.035217 472.562958,433.031982 
	C469.999390,435.537384 468.247803,437.911652 465.919891,439.358826 
	C462.921600,441.222687 459.566528,443.149658 456.175232,443.591400 
	C451.381348,444.215729 449.287201,445.962067 449.917023,450.841400 
	C450.054138,451.903473 449.397247,453.068054 449.050323,454.588989 
	C422.308197,454.997070 395.621765,455.001587 368.466431,454.999878 
z"/>
<path fill="#103B8C" opacity="1.000000" stroke="none" 
	d="
M491.371460,426.067566 
	C491.222565,434.202179 491.216522,434.181091 483.446533,436.401672 
	C479.392029,437.560364 474.732056,438.180267 475.659363,444.710510 
	C488.034607,444.710510 500.478516,444.828583 512.918030,444.646576 
	C517.280884,444.582733 516.533325,440.753113 516.856201,437.554047 
	C521.188843,437.915344 518.377808,441.298889 518.382751,443.083832 
	C518.386353,444.371307 515.570496,446.747986 513.967285,446.786835 
	C501.536957,447.087708 489.092438,447.062714 476.662720,446.739014 
	C475.389465,446.705841 473.014008,443.872559 473.198730,442.647491 
	C473.551392,440.308655 474.875519,437.467834 476.730194,436.173798 
	C478.877319,434.675751 482.183441,434.957153 484.693207,433.819336 
	C486.385406,433.052155 487.569946,431.165283 489.115784,429.109253 
	C489.502991,427.641754 489.755920,426.844330 490.236267,426.040619 
	C490.463623,426.034332 490.916656,426.074951 490.916656,426.074951 
	C490.916656,426.074951 491.371460,426.067566 491.371460,426.067566 
z"/>
<path fill="#0D65C3" opacity="1.000000" stroke="none" 
	d="
M491.552704,425.690765 
	C491.371460,426.067566 490.916656,426.074951 490.916656,426.074951 
	C490.916656,426.074951 490.463623,426.034332 489.837402,426.019287 
	C488.148254,426.016602 487.085327,426.028992 486.022430,426.041382 
	C485.204010,426.281250 484.385559,426.521118 483.271851,426.904663 
	C479.841553,426.765228 476.706543,426.482147 473.247803,425.720703 
	C473.433105,424.125793 473.942078,423.009247 474.904419,421.886322 
	C480.883820,421.927826 486.409821,421.975677 491.935822,422.023560 
	C491.868530,423.120361 491.801239,424.217163 491.552704,425.690765 
z"/>
<path fill="#103B8C" opacity="1.000000" stroke="none" 
	d="
M516.833740,436.839630 
	C513.041565,436.417633 509.238159,435.613129 505.225006,434.764221 
	C505.609619,431.507141 505.931641,428.780304 506.702942,425.681335 
	C507.152283,425.309204 507.562805,425.190033 507.562805,425.190033 
	C507.562805,425.190033 507.985809,425.251648 507.968201,425.534393 
	C507.975006,426.192902 507.999359,426.568695 508.023743,426.944458 
	C507.247253,431.419312 508.588745,434.296173 513.580688,434.792206 
	C514.705322,434.903961 515.744202,435.879303 516.833740,436.839630 
z"/>
<path fill="#033494" opacity="1.000000" stroke="none" 
	d="
M486.022888,426.406891 
	C487.085327,426.028992 488.148254,426.016602 489.610016,426.025574 
	C489.755920,426.844330 489.502991,427.641754 489.121246,428.731873 
	C488.002747,428.273865 487.013031,427.523132 486.022888,426.406891 
z"/>
<path fill="#086CD7" opacity="1.000000" stroke="none" 
	d="
M508.419739,426.960236 
	C507.999359,426.568695 507.975006,426.192902 508.143372,425.267975 
	C509.876343,424.766846 511.416565,424.814880 512.875488,425.143005 
	C512.542603,425.943329 512.291077,426.463623 512.039490,426.983887 
	C512.039490,426.983887 512.006714,426.989685 511.998505,427.005615 
	C510.932098,427.006409 509.873932,426.991211 508.419739,426.960236 
z"/>
<path fill="#13B0DF" opacity="1.000000" stroke="none" 
	d="
M374.005524,426.116119 
	C369.305634,426.190277 364.605713,426.264435 359.446655,426.427002 
	C357.321136,426.450348 355.654785,426.385254 353.245056,426.266296 
	C352.284393,426.261963 352.067078,426.311462 351.849731,426.360992 
	C347.282104,426.368073 342.714478,426.375122 337.351746,426.284393 
	C336.060364,426.125305 335.564056,426.064056 334.794678,425.768524 
	C334.379456,424.340363 334.237335,423.146515 334.501221,422.008606 
	C343.239807,422.356049 351.570831,422.731781 359.905945,422.885162 
	C362.264771,422.928589 364.635559,422.322510 367.431396,422.010773 
	C369.584137,422.037689 371.306396,422.068115 373.322754,422.387329 
	C381.419434,422.802612 389.222046,422.929077 397.021729,423.408691 
	C397.002167,424.465607 396.985504,425.169403 396.968872,425.873199 
	C396.531006,425.916412 396.093109,425.959595 395.026001,425.702240 
	C387.599670,425.639832 380.802612,425.877960 374.005524,426.116119 
z"/>
<path fill="#011480" opacity="1.000000" stroke="none" 
	d="
M373.028625,422.098541 
	C371.306396,422.068115 369.584137,422.037689 367.282043,421.752258 
	C366.151154,421.282837 365.600128,421.068390 365.049072,420.853943 
	C363.038177,418.369812 360.955292,415.939789 359.035736,413.386963 
	C356.947784,410.610107 355.011597,407.719177 353.276367,404.416077 
	C353.718201,403.893463 353.892944,403.832764 354.458984,403.831085 
	C355.906616,403.907013 356.962982,403.923920 358.214355,404.209717 
	C359.262085,404.683441 360.114807,404.888336 360.976196,405.471344 
	C361.988464,406.898041 362.992096,407.946564 364.044556,409.312164 
	C364.725677,410.091217 365.358032,410.553192 366.037720,411.331360 
	C366.722870,412.093506 367.360657,412.539490 368.242798,412.996338 
	C368.487183,413.007202 368.976227,413.022430 368.981506,413.399780 
	C370.315460,416.181122 371.644165,418.585052 372.964905,421.267487 
	C372.980865,421.730164 373.004730,421.914368 373.028625,422.098541 
z"/>
<path fill="#0FF1F7" opacity="1.000000" stroke="none" 
	d="
M334.095215,421.952637 
	C334.237335,423.146515 334.379456,424.340363 334.694946,426.030731 
	C334.795746,426.889160 334.723145,427.251099 334.650574,427.613068 
	C333.943939,428.296783 333.237305,428.980499 332.083923,429.569214 
	C330.871155,429.515320 330.105164,429.556519 329.029419,429.451660 
	C328.141479,429.547333 327.563293,429.789124 326.649597,429.957001 
	C325.200226,428.255219 324.086334,426.627319 323.235291,424.796021 
	C323.497772,423.957855 323.497406,423.323151 323.593811,422.351562 
	C324.688263,421.172913 325.685944,420.331177 327.004517,419.615051 
	C327.887726,419.457153 328.450043,419.173706 329.350586,418.944305 
	C331.157562,419.983124 332.626404,420.967896 334.095215,421.952637 
z"/>
<path fill="#0870B6" opacity="1.000000" stroke="none" 
	d="
M334.501221,422.008606 
	C332.626404,420.967896 331.157562,419.983124 329.340576,418.667877 
	C328.940430,417.972321 328.888489,417.607239 328.836517,417.242188 
	C330.121429,417.337708 332.024689,416.923523 332.591370,417.611053 
	C336.407410,422.240448 341.539337,420.884521 346.328888,420.960663 
	C352.253113,421.054871 358.180237,420.967133 364.577576,420.905945 
	C365.600128,421.068390 366.151154,421.282837 366.851532,421.755798 
	C364.635559,422.322510 362.264771,422.928589 359.905945,422.885162 
	C351.570831,422.731781 343.239807,422.356049 334.501221,422.008606 
z"/>
<path fill="#054CBE" opacity="1.000000" stroke="none" 
	d="
M289.404663,404.843140 
	C289.642792,404.204071 290.353119,403.129242 291.060944,403.130890 
	C301.848907,403.156128 312.636536,403.322052 323.720245,403.754791 
	C322.108795,404.358917 320.203247,404.911407 318.293579,404.925934 
	C308.821533,404.997986 299.348450,404.928040 289.404663,404.843140 
z"/>
<path fill="#054CBE" opacity="1.000000" stroke="none" 
	d="
M354.067719,403.772034 
	C353.892944,403.832764 353.718201,403.893463 353.279968,404.042297 
	C346.750977,404.091064 340.485504,404.051666 334.075012,403.553711 
	C333.930054,403.095154 333.911652,402.908875 334.387024,402.947083 
	C338.827393,402.990784 342.795868,402.897095 346.756165,403.035583 
	C349.533661,403.132751 350.223877,401.754547 350.165649,399.235138 
	C350.611389,399.049805 350.794250,398.987000 351.351532,398.961426 
	C352.475220,398.966553 353.224518,398.934418 354.322998,398.934448 
	C355.137177,399.015320 355.602142,399.063995 356.001587,399.412476 
	C355.313263,401.065552 354.690491,402.418793 354.067719,403.772034 
z"/>
<path fill="#11A7F5" opacity="1.000000" stroke="none" 
	d="
M329.113800,396.664917 
	C330.125061,396.803314 331.136322,396.941742 332.629211,397.354736 
	C333.400513,398.089020 333.690186,398.548706 333.951660,399.399078 
	C333.919525,400.829468 333.915588,401.869171 333.911652,402.908875 
	C333.911652,402.908875 333.930054,403.095154 333.985992,403.172394 
	C332.003082,403.446503 329.964203,403.643341 327.547455,403.797974 
	C327.111572,402.431641 327.053619,401.107574 327.134338,399.107483 
	C327.886597,397.842621 328.500214,397.253784 329.113800,396.664917 
z"/>
<path fill="#0870B6" opacity="1.000000" stroke="none" 
	d="
M326.683624,419.489441 
	C325.685944,420.331177 324.688263,421.172913 323.322937,422.430176 
	C322.386597,422.953156 321.817902,423.060608 321.249207,423.168030 
	C320.900787,419.380707 322.967163,417.650818 326.750031,417.457581 
	C326.948944,418.372589 326.816284,418.931030 326.683624,419.489441 
z"/>
<path fill="#0870B6" opacity="1.000000" stroke="none" 
	d="
M322.972412,424.999451 
	C324.086334,426.627319 325.200226,428.255219 326.653259,430.234985 
	C326.998199,430.772186 327.003937,430.957489 327.009705,431.142822 
	C322.706970,431.784790 321.145111,429.494110 321.446899,425.257538 
	C322.199677,424.942261 322.586029,424.970856 322.972412,424.999451 
z"/>
<path fill="#0870B6" opacity="1.000000" stroke="none" 
	d="
M329.339172,429.597717 
	C330.105164,429.556519 330.871155,429.515320 331.816284,429.705811 
	C331.141754,430.489136 330.288086,431.040802 329.239685,431.101929 
	C329.143005,430.273468 329.241089,429.935608 329.339172,429.597717 
z"/>
<path fill="#13B0DF" opacity="1.000000" stroke="none" 
	d="
M327.004517,419.615051 
	C326.816284,418.931030 326.948944,418.372589 327.124146,417.528625 
	C327.538666,417.215210 327.910645,417.187347 328.559570,417.200867 
	C328.888489,417.607239 328.940430,417.972321 329.002380,418.613800 
	C328.450043,419.173706 327.887726,419.457153 327.004517,419.615051 
z"/>
<path fill="#13B0DF" opacity="1.000000" stroke="none" 
	d="
M323.235291,424.796021 
	C322.586029,424.970856 322.199677,424.942261 321.527374,424.874756 
	C321.218170,424.464233 321.194855,424.092682 321.210388,423.444580 
	C321.817902,423.060608 322.386597,422.953156 323.226166,422.767090 
	C323.497406,423.323151 323.497772,423.957855 323.235291,424.796021 
z"/>
<path fill="#0870B6" opacity="1.000000" stroke="none" 
	d="
M334.966614,427.709534 
	C334.723145,427.251099 334.795746,426.889160 334.968018,426.265015 
	C335.564056,426.064056 336.060364,426.125305 336.906921,426.330627 
	C336.598999,426.918396 335.940826,427.362183 334.966614,427.709534 
z"/>
<path fill="#13B0DF" opacity="1.000000" stroke="none" 
	d="
M329.029419,429.451660 
	C329.241089,429.935608 329.143005,430.273468 329.014343,430.878418 
	C328.545105,431.140594 328.106415,431.135742 327.338745,431.136841 
	C327.003937,430.957489 326.998199,430.772186 326.988770,430.308868 
	C327.563293,429.789124 328.141479,429.547333 329.029419,429.451660 
z"/>
<path fill="#0870B6" opacity="1.000000" stroke="none" 
	d="
M351.992493,426.488220 
	C352.067078,426.311462 352.284393,426.261963 352.828186,426.288269 
	C352.814850,426.447906 352.475067,426.531677 351.992493,426.488220 
z"/>
<path fill="#0A4DBA" opacity="1.000000" stroke="none" 
	d="
M312.315674,399.342712 
	C312.012726,397.874634 312.003113,396.678345 312.008331,395.482147 
	C312.013275,394.352539 311.607025,392.578705 312.136627,392.227905 
	C313.230133,391.503540 314.828888,391.379669 316.212860,391.403992 
	C316.464752,391.408417 316.729340,393.284973 316.909424,394.311096 
	C317.577332,398.116577 320.661407,398.055206 323.736694,398.712952 
	C320.209412,399.187775 316.412445,399.401184 312.315674,399.342712 
z"/>
<path fill="#0A4DBA" opacity="1.000000" stroke="none" 
	d="
M329.025635,396.378235 
	C328.500214,397.253784 327.886597,397.842621 327.115540,398.704956 
	C326.773926,398.966309 326.589783,398.954163 326.130707,398.898010 
	C326.227631,397.202026 325.823700,394.684692 329.025635,396.378235 
z"/>
<path fill="#0A4DBA" opacity="1.000000" stroke="none" 
	d="
M285.247253,400.918304 
	C285.422272,400.329559 285.917175,399.725372 286.734375,399.125427 
	C286.560181,399.720734 286.063629,400.311798 285.247253,400.918304 
z"/>
<path fill="#0E76D3" opacity="1.000000" stroke="none" 
	d="
M241.034317,449.038757 
	C240.941635,448.927612 240.815613,448.778107 240.689590,448.628601 
	C240.773224,448.682739 240.856873,448.736847 241.009201,448.929138 
	C241.077881,449.067322 241.000977,449.000366 241.034317,449.038757 
z"/>
<path fill="#033ABC" opacity="1.000000" stroke="none" 
	d="
M851.037048,262.442108 
	C856.694763,261.708221 862.344543,261.386963 868.451233,261.084778 
	C870.592957,265.309387 872.792908,269.431610 870.870850,274.888123 
	C869.461182,277.377655 868.205261,279.174500 866.949402,280.971375 
	C865.859558,281.288788 864.769653,281.606232 862.981689,281.800476 
	C859.508362,280.926849 856.423035,280.709869 854.022766,279.314484 
	C849.078064,276.440063 850.216919,271.555115 850.953918,266.989838 
	C850.979004,265.611481 851.004089,264.233093 851.037048,262.442108 
M863.791504,276.437134 
	C867.880066,274.446198 868.102173,270.500916 866.268188,267.707977 
	C865.124512,265.966431 861.127319,265.312836 858.513611,265.513184 
	C854.913269,265.789154 854.474609,269.145111 855.088440,272.029144 
	C855.982788,276.231720 859.209290,277.186188 863.791504,276.437134 
z"/>
<path fill="#01308F" opacity="1.000000" stroke="none" 
	d="
M850.581421,266.944824 
	C850.216919,271.555115 849.078064,276.440063 854.022766,279.314484 
	C856.423035,280.709869 859.508362,280.926849 862.641113,281.841492 
	C860.935425,286.546906 857.026184,284.797516 854.657166,283.391724 
	C852.217102,281.943817 850.137512,278.909363 849.194946,276.129974 
	C847.464966,271.028412 845.529602,269.578857 839.978455,270.413727 
	C838.076721,270.699738 836.016968,269.934875 834.029419,269.650116 
	C834.076538,268.811676 834.123718,267.973236 834.170898,267.134796 
	C839.516907,267.056458 844.862854,266.978149 850.581421,266.944824 
z"/>
<path fill="#11A7F5" opacity="1.000000" stroke="none" 
	d="
M894.329712,286.700378 
	C893.046021,284.727081 891.538208,282.850403 891.454773,280.912384 
	C891.404236,279.740845 893.581177,277.454010 894.725891,277.473938 
	C896.770325,277.509552 899.853699,278.373230 900.602539,279.852570 
	C902.213562,283.035187 899.322144,286.040833 894.329712,286.700378 
z"/>
<path fill="#032495" opacity="1.000000" stroke="none" 
	d="
M811.382935,252.009369 
	C810.335083,250.573318 809.670105,249.143158 809.206665,247.085388 
	C810.614197,244.300400 811.820190,242.143036 813.342041,240.036026 
	C813.776306,241.394440 813.894653,242.702515 813.857788,244.356125 
	C811.484131,251.010529 816.449341,248.448685 819.321533,248.957245 
	C820.467285,248.978851 821.231201,248.986069 821.995117,248.993286 
	C821.997375,249.743591 821.999573,250.493912 822.003357,251.619217 
	C820.372437,252.100937 818.739929,252.207672 817.035400,252.656189 
	C815.230896,252.670410 813.498352,252.342850 811.382935,252.009369 
z"/>
<path fill="#01308F" opacity="1.000000" stroke="none" 
	d="
M867.335327,280.981079 
	C868.205261,279.174500 869.461182,277.377655 870.880310,275.289062 
	C873.243347,277.657898 871.966797,279.960785 867.335327,280.981079 
z"/>
<path fill="#033ABC" opacity="1.000000" stroke="none" 
	d="
M896.698547,300.900940 
	C895.623413,304.617188 892.837585,302.666962 890.269287,302.294373 
	C891.084473,301.673309 892.167969,301.325287 893.958496,300.980804 
	C895.122253,300.959564 895.578979,300.934753 896.035706,300.909912 
	C896.035706,300.909943 896.477722,300.909790 896.698547,300.900940 
z"/>
<path fill="#022D9A" opacity="1.000000" stroke="none" 
	d="
M895.982422,300.626099 
	C895.578979,300.934753 895.122253,300.959564 894.322510,300.969543 
	C894.299805,300.455048 894.620178,299.955383 894.940491,299.455750 
	C895.270020,299.751251 895.599548,300.046753 895.982422,300.626099 
z"/>
<path fill="#0240AA" opacity="1.000000" stroke="none" 
	d="
M832.698547,306.719696 
	C831.267273,307.342560 829.540222,307.951111 827.810486,307.959259 
	C811.191162,308.037415 794.571350,308.014343 777.043457,308.012390 
	C773.426208,307.675537 770.717285,307.346619 768.008301,307.017700 
	C768.906799,306.689392 769.795959,306.104919 770.705200,306.071869 
	C781.151733,305.692169 791.601318,305.396271 802.506592,305.098633 
	C807.014221,305.417175 811.060852,305.820618 815.117249,305.987915 
	C820.874939,306.225311 826.639771,306.288910 832.698547,306.719696 
z"/>
<path fill="#01319F" opacity="1.000000" stroke="none" 
	d="
M822.332642,248.987915 
	C821.231201,248.986069 820.467285,248.978851 819.336975,248.567276 
	C819.305542,244.405914 817.027832,243.768250 814.013062,244.010574 
	C813.894653,242.702515 813.776306,241.394440 813.122925,239.925171 
	C812.316467,239.562729 812.124146,239.302933 812.008057,238.644333 
	C811.989746,237.632126 811.974365,236.960114 811.958923,236.288101 
	C820.731201,236.521591 829.505432,236.999954 838.275391,236.932434 
	C852.208313,236.825211 866.138428,236.353622 880.539673,236.044403 
	C886.036865,236.371033 891.064087,236.689423 896.560547,237.028625 
	C895.376892,237.697174 893.727173,238.899658 892.070679,238.909027 
	C869.250244,239.038071 846.428833,238.996857 823.607666,239.020187 
	C821.866455,239.021973 820.125366,239.195633 817.096191,239.358749 
	C819.914734,242.541351 821.944824,244.753952 823.817444,247.092651 
	C823.975464,247.289948 823.077576,248.332626 822.332642,248.987915 
z"/>
<path fill="#24B6F9" opacity="1.000000" stroke="none" 
	d="
M223.001785,412.903564 
	C221.358582,413.912720 219.755478,414.545013 218.152374,415.177307 
	C217.781998,413.835266 216.956055,412.409149 217.183365,411.177460 
	C217.369781,410.167389 218.809326,409.388550 219.693390,408.507233 
	C220.809555,409.847076 221.925720,411.186920 223.001785,412.903564 
z"/>
<path fill="#0C3E9A" opacity="1.000000" stroke="none" 
	d="
M425.300812,358.881042 
	C409.115601,358.917328 392.930359,358.953613 376.262665,358.961365 
	C382.209412,355.656097 389.070557,353.007507 394.965637,348.953949 
	C403.592987,343.021606 406.159882,331.857941 401.401825,323.845764 
	C399.654236,320.903015 396.929108,318.540771 394.927429,315.535156 
	C399.487244,315.764160 403.758362,316.581512 408.060852,316.811981 
	C408.981689,316.861298 410.452148,314.916687 410.844666,313.637543 
	C411.103638,312.793610 409.970520,310.608856 409.318939,310.548218 
	C405.274719,310.171997 401.142761,309.853180 397.147827,310.362183 
	C395.917023,310.518982 395.023865,313.325958 393.976105,314.919464 
	C393.364166,314.480560 392.752258,314.041626 391.926575,313.098572 
	C391.414093,312.451843 391.115387,312.309265 390.816681,312.166656 
	C390.816681,312.166656 390.970764,312.074768 390.949097,311.702087 
	C390.718170,308.738525 392.034271,308.024719 394.467651,307.846680 
	C395.648621,307.760223 396.733826,306.364746 397.862366,305.561890 
	C397.862366,305.561890 398.025421,305.460632 398.518097,305.598999 
	C404.243713,305.216278 405.471832,301.017853 407.251495,296.800476 
	C408.342651,294.657623 407.493622,291.578827 411.305847,291.978333 
	C415.193115,292.385742 413.618591,295.241852 413.990326,297.255676 
	C414.282684,298.839417 414.579803,300.422272 414.959778,302.341431 
	C414.928955,303.118561 414.813141,303.559784 414.343933,304.076904 
	C411.046936,306.238495 411.293457,308.615692 413.445770,311.598083 
	C412.461945,319.445221 411.376495,326.851593 410.305084,334.260040 
	C408.871094,344.175781 412.250153,350.951752 420.975433,355.881439 
	C422.496552,356.740906 423.862946,357.874207 425.300812,358.881042 
z"/>
<path fill="#2B4B89" opacity="1.000000" stroke="none" 
	d="
M397.424622,305.596680 
	C396.733826,306.364746 395.648621,307.760223 394.467651,307.846680 
	C392.034271,308.024719 390.718170,308.738525 390.870209,311.752930 
	C383.948669,306.311432 377.084290,300.446381 370.296509,293.941895 
	C374.022583,290.254333 376.612640,293.121124 379.394257,294.918304 
	C384.026917,304.996246 387.632629,307.191986 397.424622,305.596680 
z"/>
<path fill="#0946BC" opacity="1.000000" stroke="none" 
	d="
M396.088898,273.379333 
	C403.063232,275.762146 410.263611,272.531311 417.526672,274.180939 
	C417.160004,276.102539 416.586365,277.555725 415.647034,279.044037 
	C411.668884,279.781891 407.743195,279.656311 404.848480,282.942627 
	C404.426880,282.977997 404.258850,282.998718 404.090820,283.019409 
	C402.228577,281.393036 400.366333,279.766663 398.358337,277.897430 
	C397.514862,276.350250 396.817169,275.045898 396.088898,273.379333 
z"/>
<path fill="#0B3189" opacity="1.000000" stroke="none" 
	d="
M416.012695,279.008942 
	C416.586365,277.555725 417.160004,276.102539 417.860657,274.324829 
	C418.293701,273.767670 418.599823,273.535004 419.132507,273.699707 
	C417.864319,283.399902 416.369568,292.702728 414.874817,302.005524 
	C414.579803,300.422272 414.282684,298.839417 413.990326,297.255676 
	C413.618591,295.241852 415.193115,292.385742 411.305847,291.978333 
	C407.493622,291.578827 408.342651,294.657623 407.240662,296.464478 
	C406.666748,295.610229 406.384216,294.824188 406.101654,294.038116 
	C406.032898,292.649323 405.964142,291.260529 406.171509,289.239075 
	C406.623047,287.756470 406.798492,286.906494 407.404297,286.035950 
	C415.727081,285.842499 415.934052,285.665161 416.012695,279.008942 
z"/>
<path fill="#0540BE" opacity="1.000000" stroke="none" 
	d="
M394.027557,315.082336 
	C395.023865,313.325958 395.917023,310.518982 397.147827,310.362183 
	C401.142761,309.853180 405.274719,310.171997 409.318939,310.548218 
	C409.970520,310.608856 411.103638,312.793610 410.844666,313.637543 
	C410.452148,314.916687 408.981689,316.861298 408.060852,316.811981 
	C403.758362,316.581512 399.487244,315.764160 394.812775,315.202454 
	C394.420624,315.248322 394.079010,315.245209 394.027557,315.082336 
z"/>
<path fill="#0C307F" opacity="1.000000" stroke="none" 
	d="
M379.374573,294.492035 
	C376.612640,293.121124 374.022583,290.254333 370.198853,293.618622 
	C366.012634,289.824921 366.392670,287.281250 371.602875,285.528961 
	C374.121155,284.682007 376.894653,284.593872 379.804810,284.121216 
	C379.630188,287.980804 379.492554,291.023285 379.374573,294.492035 
z"/>
<path fill="#2B4B89" opacity="1.000000" stroke="none" 
	d="
M405.892761,294.320068 
	C406.384216,294.824188 406.666748,295.610229 406.960114,296.732300 
	C405.471832,301.017853 404.243713,305.216278 398.613464,305.609741 
	C400.705383,301.855469 403.194641,298.228729 405.892761,294.320068 
z"/>
<path fill="#2B4B89" opacity="1.000000" stroke="none" 
	d="
M406.973938,286.056549 
	C406.798492,286.906494 406.623047,287.756470 406.200073,288.822449 
	C405.336578,287.268951 404.720703,285.499390 404.097809,283.374603 
	C404.258850,282.998718 404.426880,282.977997 405.024445,283.094086 
	C405.681763,283.450226 405.852142,283.707581 405.989838,284.297974 
	C406.328461,285.058197 406.642334,285.523407 406.961426,286.005127 
	C406.966614,286.021667 406.973938,286.056549 406.973938,286.056549 
z"/>
<path fill="#2B4B89" opacity="1.000000" stroke="none" 
	d="
M390.888062,312.419006 
	C391.115387,312.309265 391.414093,312.451843 391.794434,312.836884 
	C391.570526,312.943329 391.264984,312.807373 390.888062,312.419006 
z"/>
<path fill="#033494" opacity="1.000000" stroke="none" 
	d="
M365.060059,330.259094 
	C364.347382,328.855988 363.797241,327.786652 363.247101,326.717316 
	C363.572693,326.474579 363.898254,326.231812 364.223846,325.989044 
	C368.504578,330.342224 373.042999,334.478058 376.967957,339.131287 
	C379.269257,341.859650 377.542023,344.143707 374.365509,344.923157 
	C372.321381,345.424744 370.165100,345.469330 367.901520,345.737396 
	C367.004730,340.667511 366.113678,335.630188 365.060059,330.259094 
z"/>
<path fill="#08348D" opacity="1.000000" stroke="none" 
	d="
M360.149597,320.895935 
	C359.513855,321.504395 358.778778,322.229858 358.043732,322.955353 
	C357.796692,322.594147 357.549652,322.232971 357.302582,321.871796 
	C358.037170,321.555817 358.771759,321.239838 359.866211,320.844696 
	C360.226105,320.765564 360.050262,321.012909 360.149597,320.895935 
z"/>
<path fill="#000A73" opacity="1.000000" stroke="none" 
	d="
M333.979858,399.008392 
	C333.690186,398.548706 333.400513,398.089020 333.012085,397.375977 
	C333.286377,393.047302 333.659424,388.971954 334.026886,384.477081 
	C334.767822,383.948334 335.514374,383.839081 336.618378,383.607971 
	C340.667114,383.653168 344.358368,383.820282 348.510437,384.401062 
	C349.646210,386.541534 350.321167,388.268402 350.990540,390.371765 
	C350.993347,391.501404 351.001831,392.254486 350.999542,393.431549 
	C350.984894,395.545074 350.981018,397.234650 350.977142,398.924194 
	C350.794250,398.987000 350.611389,399.049805 349.756653,399.146667 
	C347.411926,399.233307 345.739014,399.285858 343.642456,399.222717 
	C340.755981,399.346893 338.293121,399.586761 335.604279,399.704529 
	C334.912140,399.391052 334.445984,399.199738 333.979858,399.008392 
z"/>
<path fill="#065EAF" opacity="1.000000" stroke="none" 
	d="
M307.292175,385.264160 
	C313.298248,381.355927 319.931549,383.386230 326.673523,383.478882 
	C325.018616,384.115845 322.950073,385.089966 321.048645,384.832062 
	C315.293732,384.051422 310.362000,386.184174 305.357056,387.928741 
	C305.980042,386.702087 306.636108,385.983124 307.292175,385.264160 
z"/>
<path fill="#13B0DF" opacity="1.000000" stroke="none" 
	d="
M307.201172,385.181915 
	C306.636108,385.983124 305.980042,386.702087 305.163086,387.673645 
	C304.253693,387.955933 303.505219,387.985718 302.382874,388.033112 
	C301.952026,387.684479 301.895050,387.318207 302.169647,386.515503 
	C303.978424,385.696106 305.455627,385.313110 306.932800,384.930145 
	C306.932800,384.930145 307.110168,385.099670 307.201172,385.181915 
z"/>
<path fill="#0FF1F7" opacity="1.000000" stroke="none" 
	d="
M307.004120,384.597534 
	C305.455627,385.313110 303.978424,385.696106 302.141235,386.238464 
	C301.026428,386.036285 300.271637,385.674713 299.247131,385.149109 
	C299.009216,382.674377 299.040985,380.363708 299.146759,377.635773 
	C300.123810,376.752655 301.026886,376.286835 302.013092,375.515045 
	C302.830994,375.194122 303.565704,375.179138 304.653564,375.277649 
	C305.475861,376.206482 305.752747,377.288391 306.448181,377.790100 
	C309.203369,379.777771 308.973297,381.904419 307.004120,384.597534 
z"/>
<path fill="#F5F9FB" opacity="1.000000" stroke="none" 
	d="
M414.959778,302.341431 
	C416.369568,292.702728 417.864319,283.399902 419.425842,273.672974 
	C419.492615,273.248840 419.819458,273.310638 420.382294,273.237885 
	C428.618408,273.079102 436.291718,272.993073 444.256531,272.994934 
	C444.951752,273.094482 445.355377,273.106079 445.806519,273.533447 
	C444.869324,281.620331 443.884644,289.291412 442.628448,297.248291 
	C440.323059,311.166107 438.255127,324.793182 436.269958,338.432312 
	C435.300781,345.090881 439.271454,348.316162 445.453827,345.438721 
	C450.068390,343.290924 454.333588,340.307434 458.512756,337.354279 
	C460.010101,336.296173 461.527313,334.349884 461.796753,332.616455 
	C463.834625,319.504883 465.564331,306.345398 467.565979,292.966888 
	C467.769745,292.159241 467.797729,291.585541 467.989258,290.698975 
	C468.136993,289.918915 468.121185,289.451782 468.302185,288.703491 
	C468.549957,287.294708 468.600891,286.167023 468.845886,284.670410 
	C469.729980,280.577393 470.419983,276.853333 471.109985,273.129272 
	C471.109955,273.129272 471.105438,273.106293 471.118469,273.109558 
	C477.705688,273.112854 484.279816,273.112854 491.576355,273.112854 
	C491.576355,285.401672 491.576355,297.234100 491.468262,309.833557 
	C491.192902,312.693237 491.025665,314.785919 490.631531,317.210236 
	C488.724335,329.361725 487.043976,341.181580 485.182251,353.297791 
	C484.914124,354.994507 484.827362,356.394928 484.495911,358.114258 
	C483.541748,363.642517 482.832306,368.851898 481.951447,374.416016 
	C480.585938,383.487274 479.391876,392.203735 478.000366,401.148743 
	C477.813904,401.954132 477.824890,402.531036 477.627258,403.490509 
	C476.429443,409.879608 475.440247,415.886169 474.451050,421.892700 
	C473.942078,423.009247 473.433105,424.125793 472.862610,425.546600 
	C467.702942,432.996765 462.181793,439.601624 453.225311,442.150452 
	C444.712952,444.572876 436.823151,443.627808 429.276855,437.740234 
	C428.498138,436.876831 428.117249,436.430237 427.736328,435.983643 
	C432.550781,428.033875 439.325317,429.216705 446.585815,432.986176 
	C447.125702,430.133636 447.485596,428.231995 447.961365,425.611511 
	C448.094849,423.969513 448.112457,423.046387 448.305054,421.810638 
	C448.567932,420.559998 448.655792,419.621979 448.834747,418.597382 
	C448.925812,418.510834 448.769409,418.314178 448.871521,417.880280 
	C449.562744,412.973724 450.151855,408.501099 450.915894,403.694519 
	C451.125488,402.613525 451.160126,401.866425 451.470276,400.817017 
	C452.471222,394.671326 453.196655,388.828003 454.082153,382.679871 
	C454.368530,381.269714 454.494812,380.164368 454.764038,378.725403 
	C454.916718,377.910736 454.926483,377.429596 454.936218,376.948486 
	C456.167908,369.153931 457.387390,361.357391 458.638336,353.565918 
	C459.046997,351.020325 458.373505,350.329681 455.929626,352.253418 
	C454.305908,353.223602 452.915985,353.945251 450.961548,354.779968 
	C449.927185,355.232544 449.457275,355.572052 448.987366,355.911591 
	C443.672760,356.877563 438.375671,357.961914 433.035248,358.753784 
	C430.764526,359.090454 428.403015,358.814789 425.691772,358.849243 
	C423.862946,357.874207 422.496552,356.740906 420.975433,355.881439 
	C412.250153,350.951752 408.871094,344.175781 410.305084,334.260040 
	C411.376495,326.851593 412.461945,319.445221 413.639587,311.291687 
	C414.057831,308.364014 414.377594,306.182526 414.697357,304.001038 
	C414.813141,303.559784 414.928955,303.118561 414.959778,302.341431 
z"/>
<path fill="#001C8F" opacity="1.000000" stroke="none" 
	d="
M454.679749,376.723724 
	C454.926483,377.429596 454.916718,377.910736 454.323975,378.682068 
	C443.672394,378.985504 433.603760,378.994019 423.535156,379.013794 
	C418.006927,379.024658 412.478760,379.054504 406.950562,379.075775 
	C406.950562,379.075775 407.022644,378.947205 407.049896,378.877014 
	C406.618073,374.378143 408.326599,370.791290 412.367767,369.213287 
	C417.157410,367.342987 422.049774,369.345093 425.005920,373.049622 
	C425.833008,374.086121 427.608612,374.891052 428.955444,374.900574 
	C436.544434,374.954224 444.135559,374.668427 451.725403,374.690796 
	C452.626465,374.693451 453.524078,375.867767 454.679749,376.723724 
z"/>
<path fill="#03429E" opacity="1.000000" stroke="none" 
	d="
M386.081970,371.405518 
	C387.392395,371.039551 388.774353,371.025452 390.570618,371.009552 
	C389.809296,374.060303 388.065796,373.452942 386.081970,371.405518 
z"/>
<path fill="#013498" opacity="1.000000" stroke="none" 
	d="
M351.010315,393.007568 
	C351.001831,392.254486 350.993347,391.501404 351.254089,390.144714 
	C351.350769,387.679810 351.178253,385.818512 351.005707,383.957245 
	C357.379120,383.888184 363.752502,383.819092 370.955261,383.837891 
	C373.523224,383.957794 375.261841,383.989899 377.304504,384.306641 
	C385.401581,384.403961 393.194611,384.216644 401.448029,384.017822 
	C409.275146,383.992767 416.641815,383.979187 424.005890,384.397400 
	C425.630554,392.482422 419.068878,390.446228 415.816467,391.412720 
	C416.264496,394.290100 416.628754,396.629303 416.525177,398.989899 
	C396.060608,399.045074 376.063873,399.078857 356.067108,399.112671 
	C355.602142,399.063995 355.137177,399.015320 354.329590,398.559845 
	C352.994751,396.437866 352.002533,394.722717 351.010315,393.007568 
z"/>
<path fill="#086BCC" opacity="1.000000" stroke="none" 
	d="
M424.008514,383.965576 
	C416.641815,383.979187 409.275146,383.992767 401.451050,383.763214 
	C400.994171,383.350525 400.994659,383.180969 400.995148,383.011414 
	C402.977203,381.953064 404.959259,380.894775 406.945923,379.456116 
	C412.478760,379.054504 418.006927,379.024658 423.535156,379.013794 
	C433.603760,378.994019 443.672394,378.985504 454.181061,379.015625 
	C454.494812,380.164368 454.368530,381.269714 453.673340,382.745361 
	C451.167969,383.402130 449.234131,383.912689 447.294495,383.935760 
	C439.533478,384.027985 431.770660,383.968781 424.008514,383.965576 
z"/>
<path fill="#0243AA" opacity="1.000000" stroke="none" 
	d="
M400.527039,383.010986 
	C400.994659,383.180969 400.994171,383.350525 400.990662,383.774719 
	C393.194611,384.216644 385.401581,384.403961 377.309906,384.067505 
	C377.011230,383.543671 377.018341,383.065735 377.018341,383.065735 
	C384.698517,383.047333 392.378723,383.028961 400.527039,383.010986 
z"/>
<path fill="#01308D" opacity="1.000000" stroke="none" 
	d="
M350.662964,383.930603 
	C351.178253,385.818512 351.350769,387.679810 351.259705,389.768158 
	C350.321167,388.268402 349.646210,386.541534 348.967224,384.453125 
	C349.415558,384.029053 349.867889,383.966522 350.662964,383.930603 
z"/>
<path fill="#086BCC" opacity="1.000000" stroke="none" 
	d="
M376.768250,382.788116 
	C377.018341,383.065735 377.011230,383.543671 377.005829,383.782837 
	C375.261841,383.989899 373.523224,383.957794 371.354919,383.840515 
	C372.789520,383.340393 374.653870,382.925446 376.768250,382.788116 
z"/>
<path fill="#159CE3" opacity="1.000000" stroke="none" 
	d="
M448.971161,356.263336 
	C449.457275,355.572052 449.927185,355.232544 450.697479,354.990479 
	C450.557037,356.032776 450.116180,356.977570 449.335999,357.972473 
	C448.982727,357.553406 448.968842,357.084259 448.971161,356.263336 
z"/>
<path fill="#032495" opacity="1.000000" stroke="none" 
	d="
M468.651794,285.039368 
	C468.600891,286.167023 468.549957,287.294708 468.059265,288.822083 
	C467.688232,289.818512 467.756958,290.415192 467.825684,291.011871 
	C467.797729,291.585541 467.769745,292.159241 467.274841,292.775726 
	C466.351044,291.843872 465.894165,290.869202 465.676636,290.405151 
	C462.856384,290.971924 460.311340,291.886475 457.756683,291.914124 
	C452.184692,291.974396 446.448944,291.107819 442.899994,296.962524 
	C443.884644,289.291412 444.869324,281.620331 446.115845,273.496460 
	C446.584961,273.029602 446.792175,273.015442 446.999420,273.001312 
	C447.151031,275.371124 447.797119,277.840088 447.346008,280.089020 
	C446.534058,284.136658 447.350311,286.279388 451.882355,286.007904 
	C456.672577,285.720978 461.469391,285.543610 466.916138,285.164368 
	C467.929901,285.020050 468.290863,285.029724 468.651794,285.039368 
z"/>
<path fill="#1C428F" opacity="1.000000" stroke="none" 
	d="
M468.845886,284.670410 
	C468.290863,285.029724 467.929901,285.020050 467.298828,284.990540 
	C467.356659,281.682129 467.553558,278.371246 468.107056,275.121063 
	C468.241333,274.332703 469.550964,273.744507 470.715088,273.096771 
	C470.419983,276.853333 469.729980,280.577393 468.845886,284.670410 
z"/>
<path fill="#0239A0" opacity="1.000000" stroke="none" 
	d="
M446.999420,272.750671 
	C446.792175,273.015442 446.584961,273.029602 446.068359,273.080688 
	C445.355377,273.106079 444.951752,273.094482 444.265900,272.773346 
	C443.983704,272.463806 443.981873,272.020294 443.981873,272.020294 
	C444.736145,272.013123 445.490387,272.005951 446.621765,271.998810 
	C446.999054,272.165924 446.999237,272.332977 446.999420,272.750671 
z"/>
<path fill="#0B3189" opacity="1.000000" stroke="none" 
	d="
M443.546448,272.017639 
	C443.981873,272.020294 443.983704,272.463806 443.974365,272.685425 
	C436.291718,272.993073 428.618408,273.079102 420.482605,273.105225 
	C425.370087,272.697571 430.720062,272.349823 436.538086,271.995300 
	C439.041077,271.997345 441.076019,272.006165 443.546448,272.017639 
z"/>
<path fill="#0251BD" opacity="1.000000" stroke="none" 
	d="
M718.968018,264.637878 
	C718.565796,264.988983 718.119568,264.988708 717.042542,264.775818 
	C714.713989,263.707672 713.056824,262.214691 711.311707,262.103790 
	C702.407471,261.538025 693.482910,261.198395 684.560791,261.032104 
	C675.437561,260.862061 666.308350,261.035065 657.183105,260.934479 
	C655.450500,260.915375 653.724304,260.319611 651.995117,259.989929 
	C653.723450,259.661926 655.449707,259.063354 657.180481,259.049988 
	C668.139404,258.965424 679.099365,259.010437 690.527039,259.010010 
	C697.238342,259.010803 703.482300,259.070007 709.724609,258.998108 
	C713.884644,258.950226 716.935425,260.648285 718.968018,264.637878 
z"/>
<path fill="#01379A" opacity="1.000000" stroke="none" 
	d="
M730.206909,267.996582 
	C725.422302,267.938751 725.206970,264.569183 725.378967,261.689484 
	C725.581482,258.302155 728.126587,257.496155 730.951416,259.438690 
	C733.385498,261.112518 735.885132,262.691223 738.355774,264.311981 
	C737.843079,265.267151 737.330444,266.222290 736.817810,267.177429 
	C734.753662,267.451355 732.689514,267.725281 730.206909,267.996582 
z"/>
<path fill="#0251BD" opacity="1.000000" stroke="none" 
	d="
M734.962280,280.658691 
	C732.355957,278.563934 729.714539,276.124969 727.037842,273.341949 
	C729.644043,275.436768 732.285706,277.875641 734.962280,280.658691 
z"/>
<path fill="#0251BD" opacity="1.000000" stroke="none" 
	d="
M742.940186,288.674835 
	C740.999329,287.248413 739.032166,285.476379 737.025208,283.363098 
	C738.961548,284.790985 740.937744,286.560120 742.940186,288.674835 
z"/>
<path fill="#0251BD" opacity="1.000000" stroke="none" 
	d="
M726.964722,272.654510 
	C725.026733,271.224091 723.051025,269.450562 721.042969,267.331604 
	C722.982727,268.761261 724.954895,270.536346 726.964722,272.654510 
z"/>
<path fill="#013FA8" opacity="1.000000" stroke="none" 
	d="
M698.548157,253.636047 
	C697.591064,254.257492 696.803406,254.588928 696.015686,254.920380 
	C695.771729,254.600815 695.527771,254.281265 695.283813,253.961716 
	C696.306458,253.180313 697.329163,252.398926 698.351807,251.617523 
	C698.473694,252.193695 698.595642,252.769867 698.548157,253.636047 
z"/>
<path fill="#0974E0" opacity="1.000000" stroke="none" 
	d="
M651.620728,259.996948 
	C653.724304,260.319611 655.450500,260.915375 657.183105,260.934479 
	C666.308350,261.035065 675.437561,260.862061 684.560791,261.032104 
	C693.482910,261.198395 702.407471,261.538025 711.311707,262.103790 
	C713.056824,262.214691 714.713989,263.707672 716.708008,264.776917 
	C715.713745,266.108551 714.423279,267.226532 712.908447,268.977875 
	C710.917236,269.233917 707.737244,269.113800 707.614075,268.437256 
	C706.841492,264.193146 703.826050,265.029053 701.143494,265.017578 
	C683.756775,264.943176 666.369873,264.894043 648.987549,264.455292 
	C648.984924,263.043457 648.977783,262.012939 648.979370,260.734680 
	C648.988098,260.486938 648.999207,259.991272 648.999207,259.991272 
	C649.748230,259.995483 650.497314,259.999695 651.620728,259.996948 
z"/>
<path fill="#0251BD" opacity="1.000000" stroke="none" 
	d="
M648.734131,259.719482 
	C648.999207,259.991272 648.988098,260.486938 648.554077,260.733154 
	C646.412415,260.994812 644.704773,261.010254 642.749512,261.016235 
	C642.501953,261.006805 642.007324,261.036133 641.715454,261.240631 
	C641.207397,261.862976 640.991150,262.280823 640.522461,262.672791 
	C640.128174,262.554718 639.986328,262.462494 639.838623,262.164520 
	C639.582031,262.040497 639.331238,262.122223 638.624329,262.223572 
	C635.439941,262.156921 632.711609,262.070679 629.688293,261.691742 
	C627.752869,261.271820 626.112732,261.037659 624.472046,261.033844 
	C607.379944,260.994232 590.287598,261.019043 573.195740,260.936951 
	C571.462769,260.928619 569.732544,260.337646 568.001038,260.017059 
	C569.717712,259.680267 571.433167,259.058136 573.151245,259.050812 
	C590.191833,258.977905 607.233337,259.062958 624.272888,258.912720 
	C625.839844,258.898895 627.395203,257.567200 628.971924,256.436523 
	C630.611206,255.595734 632.234680,255.165894 633.929077,254.368011 
	C634.000000,254.000000 633.997620,254.001221 633.997620,254.001221 
	C635.051025,253.995682 636.104370,253.990158 637.550781,253.978577 
	C638.046448,255.421539 638.149109,256.870575 638.247986,258.266479 
	C641.826904,258.680115 645.147949,259.063904 648.734131,259.719482 
z"/>
<path fill="#022B9D" opacity="1.000000" stroke="none" 
	d="
M638.878906,245.919220 
	C638.878967,245.919205 638.930664,245.442841 639.354004,245.166199 
	C642.244507,244.261734 644.688477,243.229706 647.183472,243.086411 
	C653.227722,242.739288 659.295837,242.806198 665.354309,242.706238 
	C667.273926,244.147873 669.193542,245.589523 671.418945,247.157639 
	C672.189941,247.985580 672.655090,248.687057 673.302246,249.658051 
	C662.635925,249.619202 651.787720,249.310806 640.339600,248.701874 
	C639.452881,247.573944 639.165894,246.746582 638.878906,245.919220 
z"/>
<path fill="#003CB2" opacity="1.000000" stroke="none" 
	d="
M638.557983,245.990082 
	C639.165894,246.746582 639.452881,247.573944 639.871155,248.703369 
	C639.551941,249.322311 639.101379,249.639221 638.366394,249.984085 
	C638.133667,248.695023 638.185303,247.377975 638.557983,245.990082 
z"/>
<path fill="#003CB2" opacity="1.000000" stroke="none" 
	d="
M665.290894,242.439270 
	C659.295837,242.806198 653.227722,242.739288 647.183472,243.086411 
	C644.688477,243.229706 642.244507,244.261734 639.391113,244.929504 
	C639.022949,243.616882 639.041016,242.264267 639.105591,240.511810 
	C645.271851,239.901840 651.391602,239.691711 657.783813,239.140518 
	C660.090271,239.526794 662.124207,240.254135 664.521240,241.054535 
	C664.998718,241.475815 665.113098,241.824051 665.290894,242.439270 
z"/>
<path fill="#11A7F5" opacity="1.000000" stroke="none" 
	d="
M636.988281,239.581512 
	C635.842590,240.574188 634.593018,241.050079 633.697449,241.899338 
	C630.411987,245.014908 627.445251,244.750412 624.210510,240.938812 
	C623.607727,240.282227 623.350342,240.129257 623.037476,240.069748 
	C623.037476,240.069748 622.555847,239.988068 622.314148,239.957108 
	C621.520325,235.715897 624.019592,234.925095 627.697754,235.527832 
	C627.454956,236.859634 626.367554,237.954086 626.559509,238.733459 
	C626.839050,239.868576 627.859009,241.175751 628.906982,241.650101 
	C629.683716,242.001633 631.864197,241.273300 631.934570,240.803543 
	C632.198914,239.039017 631.854431,237.183258 631.738159,235.361679 
	C634.101440,235.839630 638.268005,233.846710 636.988281,239.581512 
z"/>
<path fill="#0FF1F7" opacity="1.000000" stroke="none" 
	d="
M631.391357,235.299896 
	C631.854431,237.183258 632.198914,239.039017 631.934570,240.803543 
	C631.864197,241.273300 629.683716,242.001633 628.906982,241.650101 
	C627.859009,241.175751 626.839050,239.868576 626.559509,238.733459 
	C626.367554,237.954086 627.454956,236.859634 628.101196,235.617676 
	C629.169434,235.298950 630.107056,235.268524 631.391357,235.299896 
z"/>
<path fill="#2159B0" opacity="1.000000" stroke="none" 
	d="
M652.231812,235.255249 
	C652.884216,235.153687 653.740295,235.316269 654.815063,235.729462 
	C654.167664,235.826523 653.301514,235.672958 652.231812,235.255249 
z"/>
<path fill="#0D89DF" opacity="1.000000" stroke="none" 
	d="
M567.563782,260.024689 
	C569.732544,260.337646 571.462769,260.928619 573.195740,260.936951 
	C590.287598,261.019043 607.379944,260.994232 624.472046,261.033844 
	C626.112732,261.037659 627.752869,261.271820 629.485779,261.967194 
	C627.072327,263.636261 624.566406,264.737213 621.732178,265.577759 
	C595.955811,265.256897 570.507690,265.196442 544.734863,265.122192 
	C543.637390,265.754211 542.864502,266.399994 541.791138,267.200714 
	C532.680725,276.272797 523.870789,285.189880 514.761902,294.211578 
	C513.959900,295.185944 513.456848,296.055695 512.964722,296.950623 
	C512.975525,296.975830 512.925293,296.953918 512.613159,296.993378 
	C511.570160,297.707428 510.839386,298.381989 509.817139,299.203552 
	C508.661346,300.550323 507.796997,301.750092 506.623413,303.003784 
	C505.862213,303.685944 505.410248,304.314209 504.973206,304.968140 
	C504.988159,304.993805 504.939911,304.958862 504.609985,305.019287 
	C501.610565,306.734070 500.774323,308.983887 501.656189,311.964539 
	C498.057739,311.672241 497.979553,313.980377 497.354492,316.127747 
	C495.310272,316.425293 493.653076,316.748199 491.709106,317.037018 
	C491.234375,316.961487 491.046387,316.920044 490.858398,316.878601 
	C491.025665,314.785919 491.192902,312.693237 491.559296,310.186829 
	C492.039032,309.811188 492.315063,309.870636 492.805420,310.358154 
	C493.024323,311.869019 493.024323,312.973175 493.024323,314.077362 
	C493.493744,314.301819 493.963135,314.526276 494.432556,314.750732 
	C496.291199,311.835968 498.149872,308.921204 500.144226,305.952545 
	C500.184418,305.929596 500.088959,305.960541 499.644714,306.030640 
	C497.621246,306.745056 495.946503,307.420227 494.271759,308.095428 
	C494.239685,307.705994 494.207611,307.316589 494.203613,306.638977 
	C495.551605,305.378754 497.003510,304.543121 498.170837,303.413452 
	C509.338226,292.606232 520.469543,281.761688 531.583191,270.899200 
	C534.875916,267.680817 538.080017,264.371735 542.062134,261.034088 
	C545.194763,260.666718 548.375305,262.075592 549.068176,258.079041 
	C549.068176,258.079041 548.999268,258.008759 549.408508,257.984375 
	C551.535645,257.657166 553.253479,257.354279 554.971375,257.051392 
	C555.170898,257.582916 555.370422,258.114471 555.948547,259.121643 
	C557.886780,259.736328 559.446472,259.875397 561.006104,260.014435 
	C563.046265,260.020386 565.086365,260.026367 567.563782,260.024689 
z"/>
<path fill="#0251BD" opacity="1.000000" stroke="none" 
	d="
M548.669800,258.054016 
	C548.375305,262.075592 545.194763,260.666718 542.386353,260.941528 
	C544.072693,259.955750 546.172058,258.992371 548.669800,258.054016 
z"/>
<path fill="#0251BD" opacity="1.000000" stroke="none" 
	d="
M554.948669,256.703217 
	C553.253479,257.354279 551.535645,257.657166 549.447021,258.014282 
	C550.962463,253.827209 552.299255,253.435638 554.948669,256.703217 
z"/>
<path fill="#0251BD" opacity="1.000000" stroke="none" 
	d="
M560.757141,259.737366 
	C559.446472,259.875397 557.886780,259.736328 556.189880,259.281555 
	C557.537781,259.130646 559.023010,259.295471 560.757141,259.737366 
z"/>
<path fill="#11A7F5" opacity="1.000000" stroke="none" 
	d="
M494.161499,308.291809 
	C495.946503,307.420227 497.621246,306.745056 499.648010,306.034912 
	C500.000000,306.000000 500.008545,306.006470 500.008545,306.006470 
	C498.149872,308.921204 496.291199,311.835968 494.432556,314.750732 
	C493.963135,314.526276 493.493744,314.301819 493.024323,314.077362 
	C493.024323,312.973175 493.024323,311.869019 493.011902,310.405151 
	C492.999481,310.045441 492.993286,309.993713 493.241364,309.885254 
	C493.763672,309.558929 493.916779,309.275635 493.948761,308.926819 
	C493.948761,308.926819 494.051208,308.488159 494.161499,308.291809 
z"/>
<path fill="#0D89DF" opacity="1.000000" stroke="none" 
	d="
M493.728851,309.037537 
	C493.916779,309.275635 493.763672,309.558929 493.266724,309.881287 
	C493.070129,309.635071 493.225098,309.355896 493.728851,309.037537 
z"/>
<path fill="#11A7F5" opacity="1.000000" stroke="none" 
	d="
M634.000183,253.999329 
	C633.500916,253.989136 632.856018,254.177521 632.527161,253.935852 
	C631.102661,252.888962 629.770447,251.716461 628.404663,250.589630 
	C629.720581,249.831345 631.141357,248.305695 632.331482,248.468338 
	C635.148071,248.853287 635.558777,250.915680 634.129517,253.661575 
	C633.997620,254.001221 634.000000,254.000000 634.000183,253.999329 
z"/>
<path fill="#086CD7" opacity="1.000000" stroke="none" 
	d="
M623.146240,240.272827 
	C623.350342,240.129257 623.607727,240.282227 623.927063,240.724274 
	C623.723450,240.874832 623.460205,240.726837 623.146240,240.272827 
z"/>
<path fill="#0235B0" opacity="1.000000" stroke="none" 
	d="
M787.982544,208.615570 
	C784.371826,205.213470 780.715820,201.466888 777.012939,197.381210 
	C780.623108,200.785095 784.280273,204.528091 787.982544,208.615570 
z"/>
<path fill="#0235B0" opacity="1.000000" stroke="none" 
	d="
M792.951965,212.675842 
	C791.672791,211.916183 790.364929,210.811218 789.012939,209.367569 
	C790.286987,210.129425 791.605103,211.229996 792.951965,212.675842 
z"/>
<path fill="#0235B0" opacity="1.000000" stroke="none" 
	d="
M803.971252,224.642395 
	C803.031921,224.208618 802.054138,223.438644 801.046692,222.327423 
	C801.988953,222.759491 802.960876,223.532837 803.971252,224.642395 
z"/>
<path fill="#0235B0" opacity="1.000000" stroke="none" 
	d="
M806.831848,226.766403 
	C806.541931,227.317154 806.086304,227.625244 805.344238,227.950058 
	C805.042908,227.525436 805.028015,227.084091 805.023071,226.310883 
	C805.577393,226.160599 806.121704,226.342163 806.831848,226.766403 
z"/>
<path fill="#011480" opacity="1.000000" stroke="none" 
	d="
M812.011047,238.984528 
	C812.124146,239.302933 812.316467,239.562729 812.807068,239.874817 
	C811.820190,242.143036 810.614197,244.300400 809.201660,246.729309 
	C806.879211,246.906906 804.702637,247.099060 802.658752,246.665573 
	C796.414307,245.341202 794.307678,241.358978 796.062073,233.224838 
	C799.150146,230.520874 801.601013,232.104874 804.054321,232.938400 
	C804.054321,232.938400 804.520874,232.985352 804.795532,233.358124 
	C807.383728,235.482117 809.697388,237.233322 812.011047,238.984528 
z"/>
<path fill="#032495" opacity="1.000000" stroke="none" 
	d="
M803.999146,232.606064 
	C801.601013,232.104874 799.150146,230.520874 796.342773,232.944504 
	C796.310059,231.878586 796.620667,230.782242 797.297852,229.340836 
	C798.432373,228.681900 799.200317,228.368027 799.968262,228.054169 
	C799.968262,228.054184 800.451843,228.041977 800.692993,228.049011 
	C801.937378,229.461945 802.940674,230.867828 803.999146,232.606064 
z"/>
<path fill="#003499" opacity="1.000000" stroke="none" 
	d="
M799.933228,227.697296 
	C799.200317,228.368027 798.432373,228.681900 797.332214,228.997589 
	C796.670288,227.888000 796.340576,226.776596 796.016663,225.309875 
	C797.314331,225.749847 798.606262,226.545135 799.933228,227.697296 
z"/>
<path fill="#003499" opacity="1.000000" stroke="none" 
	d="
M792.013062,221.355560 
	C792.924988,221.797577 793.841431,222.611984 794.889771,223.707611 
	C794.020325,223.235214 793.018921,222.481567 792.013062,221.355560 
z"/>
<path fill="#0240AA" opacity="1.000000" stroke="none" 
	d="
M648.983032,264.836609 
	C666.369873,264.894043 683.756775,264.943176 701.143494,265.017578 
	C703.826050,265.029053 706.841492,264.193146 707.614075,268.437256 
	C707.737244,269.113800 710.917236,269.233917 712.852539,269.326111 
	C714.338196,269.769012 715.655334,270.497040 716.619690,271.649963 
	C713.826294,272.387024 711.272583,272.359497 708.982178,273.122559 
	C707.257446,273.697113 704.448303,275.473358 704.569763,276.390381 
	C705.228638,281.362915 702.118042,280.994659 699.040100,280.994476 
	C685.030579,280.993713 671.021118,280.990753 657.011597,280.988556 
	C656.611023,280.697815 656.210388,280.407074 655.342896,279.852417 
	C654.799561,279.201294 654.723083,278.814117 654.691284,278.174255 
	C654.533813,277.666504 654.331848,277.411438 653.946777,276.835632 
	C651.399292,273.010254 649.034912,269.505615 646.903320,265.762207 
	C647.751770,265.294495 648.367371,265.065552 648.983032,264.836609 
z"/>
<path fill="#00359C" opacity="1.000000" stroke="none" 
	d="
M657.102173,281.384277 
	C671.021118,280.990753 685.030579,280.993713 699.040100,280.994476 
	C702.118042,280.994659 705.228638,281.362915 704.569763,276.390381 
	C704.448303,275.473358 707.257446,273.697113 708.982178,273.122559 
	C711.272583,272.359497 713.826294,272.387024 716.681396,271.998840 
	C717.622437,272.123016 718.148987,272.323212 718.880249,273.079163 
	C719.718018,274.092499 720.351135,274.550079 720.984192,275.007629 
	C722.226318,276.154724 723.468506,277.301849 724.855469,279.104553 
	C725.652771,283.043488 727.483215,284.981262 730.990051,285.004425 
	C731.310608,285.111633 731.571777,285.300812 731.920654,286.132782 
	C733.713562,288.127716 735.359253,289.561859 737.005005,290.996002 
	C737.514954,291.552704 738.024841,292.109406 738.808350,293.185608 
	C740.371643,294.480347 741.661255,295.255554 742.950928,296.030762 
	C742.950928,296.030762 742.993652,296.006622 742.993408,296.387665 
	C745.019592,301.250977 747.764648,304.958984 752.964111,306.022369 
	C752.964111,306.022369 753.000183,306.009003 753.074280,306.387360 
	C754.771667,307.186096 756.394958,307.606476 758.018250,308.026825 
	C758.994690,314.491791 754.287781,312.813232 750.441711,313.008545 
	C749.182617,312.899353 748.073853,313.089142 747.597107,312.627014 
	C741.273560,306.497681 735.185181,300.119324 728.738098,294.125854 
	C722.981079,288.773865 718.496765,282.079895 708.518372,282.725677 
	C693.132019,283.721527 677.633911,283.031830 662.183838,282.945282 
	C660.518005,282.935944 658.856384,282.186249 657.102173,281.384277 
z"/>
<path fill="#00359C" opacity="1.000000" stroke="none" 
	d="
M767.562256,307.007690 
	C770.717285,307.346619 773.426208,307.675537 776.575806,308.013855 
	C772.696899,310.587799 768.399597,313.666290 763.118469,312.738739 
	C761.835632,312.513458 761.026794,309.589569 759.981689,307.522522 
	C762.347656,307.091553 764.731995,307.044586 767.562256,307.007690 
z"/>
<path fill="#0240AA" opacity="1.000000" stroke="none" 
	d="
M752.830200,305.718201 
	C747.764648,304.958984 745.019592,301.250977 742.981140,296.408905 
	C746.211548,299.170746 749.453857,302.292389 752.830200,305.718201 
z"/>
<path fill="#0240AA" opacity="1.000000" stroke="none" 
	d="
M730.852295,284.703400 
	C727.483215,284.981262 725.652771,283.043488 724.993347,279.392792 
	C726.895752,280.817749 728.805115,282.610046 730.852295,284.703400 
z"/>
<path fill="#0240AA" opacity="1.000000" stroke="none" 
	d="
M736.972717,290.652161 
	C735.359253,289.561859 733.713562,288.127716 732.032715,286.350555 
	C733.645203,287.441132 735.292786,288.874725 736.972717,290.652161 
z"/>
<path fill="#0240AA" opacity="1.000000" stroke="none" 
	d="
M758.022583,307.794769 
	C756.394958,307.606476 754.771667,307.186096 753.064453,306.404053 
	C754.496155,306.199768 756.011841,306.357178 757.773804,306.806580 
	C758.020020,307.098602 758.026917,307.562714 758.022583,307.794769 
z"/>
<path fill="#0240AA" opacity="1.000000" stroke="none" 
	d="
M742.822754,295.727783 
	C741.661255,295.255554 740.371643,294.480347 739.042236,293.351044 
	C740.233154,293.806213 741.463806,294.615509 742.822754,295.727783 
z"/>
<path fill="#0240AA" opacity="1.000000" stroke="none" 
	d="
M720.949463,274.686218 
	C720.351135,274.550079 719.718018,274.092499 719.043701,273.316345 
	C719.639893,273.453430 720.277283,273.909119 720.949463,274.686218 
z"/>
<path fill="#032495" opacity="1.000000" stroke="none" 
	d="
M812.008057,238.644348 
	C809.697388,237.233322 807.383728,235.482117 805.027832,233.389740 
	C806.449951,233.938950 807.914307,234.829330 809.676514,235.857208 
	C810.418823,236.039307 810.863220,236.083893 811.633301,236.208282 
	C811.974365,236.960114 811.989746,237.632126 812.008057,238.644348 
z"/>
<path fill="#0442AB" opacity="1.000000" stroke="none" 
	d="
M482.122864,374.061249 
	C482.832306,368.851898 483.541748,363.642517 484.808319,358.281250 
	C489.400665,359.106384 490.974243,355.808533 493.183960,353.622131 
	C500.125610,346.753723 507.063232,339.881195 514.004272,333.402771 
	C514.688354,334.827362 515.370483,335.859436 516.052673,336.891510 
	C516.635010,336.462006 517.217407,336.032532 517.799744,335.603027 
	C516.536438,334.736542 515.273132,333.870087 514.154724,332.702057 
	C518.196167,328.397278 521.990479,324.287384 526.024048,320.427429 
	C528.829773,317.742523 531.964233,315.401062 534.968506,313.260162 
	C534.994934,314.084625 535.004639,314.555084 535.012573,315.269043 
	C535.010742,315.512573 534.995117,315.999359 534.612854,316.090332 
	C533.820374,317.679688 533.120300,319.164581 533.050232,320.678680 
	C532.751770,327.126831 530.353516,328.536682 524.134705,324.939301 
	C523.008606,326.989105 521.890991,329.023438 520.773438,331.057800 
	C521.124207,331.607635 521.474915,332.157471 521.825684,332.707306 
	C526.102661,328.074493 530.701782,329.589539 535.143982,331.132629 
	C536.579590,331.631287 537.645325,333.194641 538.880249,334.270996 
	C537.630981,335.167267 536.491516,336.577820 535.113647,336.872009 
	C528.663330,338.249329 527.239197,341.180969 530.306519,348.023254 
	C531.124023,347.503906 532.222168,347.194092 532.630005,346.486450 
	C536.243958,340.215485 541.824280,336.015869 547.363586,331.631500 
	C549.755432,329.738312 551.137512,326.569214 552.985229,323.984894 
	C552.992126,323.990021 552.977844,323.976776 553.378174,323.982788 
	C555.855530,323.660431 557.932678,323.332031 560.009766,323.003662 
	C557.514282,325.668823 555.097290,328.412964 552.509644,330.985413 
	C541.067749,342.360382 529.572266,353.681366 518.112488,365.038361 
	C517.210938,365.931824 516.434570,366.951599 515.253052,367.974060 
	C509.537994,368.429504 504.167328,368.790710 498.803101,369.231171 
	C496.010254,369.460510 493.137634,369.506989 490.477325,370.259369 
	C488.934937,370.695618 487.742401,372.368774 485.816223,373.774384 
	C484.200958,374.062714 483.161896,374.061981 482.122864,374.061249 
z"/>
<path fill="#043ED5" opacity="1.000000" stroke="none" 
	d="
M571.944824,319.001251 
	C569.225952,319.665924 566.507019,320.330597 563.398315,320.678711 
	C563.011597,319.926575 563.014709,319.491058 563.351562,319.003876 
	C565.932739,317.998810 567.673584,316.811340 565.978149,313.690765 
	C565.998962,312.903015 566.000732,312.452728 566.305115,311.852417 
	C569.570618,308.884705 572.379700,305.782166 576.963623,306.443878 
	C576.541077,307.342377 576.272766,308.234253 575.802795,308.357574 
	C570.177856,309.833893 571.523621,313.385590 573.082275,317.343567 
	C572.679443,318.096893 572.312134,318.549072 571.944824,319.001251 
z"/>
<path fill="#0BC4F6" opacity="1.000000" stroke="none" 
	d="
M571.989868,319.396515 
	C572.312134,318.549072 572.679443,318.096893 573.338501,317.105255 
	C574.774780,315.394043 575.919189,314.222260 577.063599,313.050476 
	C578.125854,314.104889 579.175049,315.172974 580.256531,316.207306 
	C580.718140,316.648834 581.247253,317.019836 581.745728,317.422913 
	C581.796631,317.777222 581.847595,318.131561 582.002075,318.735229 
	C582.083313,321.773468 580.731079,323.166534 577.891602,323.011902 
	C575.420898,322.877350 572.395874,323.760010 571.989868,319.396515 
z"/>
<path fill="#023EA7" opacity="1.000000" stroke="none" 
	d="
M563.017822,319.055511 
	C563.014709,319.491058 563.011597,319.926575 563.011902,320.689087 
	C562.112549,321.489868 561.209717,321.963715 560.158325,322.720612 
	C557.932678,323.332031 555.855530,323.660431 553.388794,323.587891 
	C554.132446,322.129303 555.097900,320.601562 556.437988,320.123169 
	C558.495972,319.388428 560.811584,319.375031 563.017822,319.055511 
z"/>
<path fill="#0240A9" opacity="1.000000" stroke="none" 
	d="
M518.046570,374.824097 
	C516.513733,376.456696 514.959717,377.728302 513.405640,378.999908 
	C513.057861,378.833282 512.710144,378.666687 512.362366,378.500092 
	C513.139343,376.188354 513.916321,373.876617 514.898193,371.258545 
	C515.391602,371.492706 515.680237,372.033234 516.095825,372.985901 
	C516.222778,373.398041 516.573486,373.479340 516.573486,373.479340 
	C516.573486,373.479340 516.848022,373.221558 516.848022,373.221558 
	C517.044922,373.183594 517.241821,373.145630 517.736450,373.062042 
	C518.031189,373.498627 518.028259,373.980835 518.046570,374.824097 
z"/>
<path fill="#0240A9" opacity="1.000000" stroke="none" 
	d="
M523.725464,369.078552 
	C523.761658,369.250977 523.569519,369.518311 523.136230,369.865692 
	C523.019470,369.628876 523.220215,369.371429 523.725464,369.078552 
z"/>
<path fill="#1E4796" opacity="1.000000" stroke="none" 
	d="
M516.649536,373.193420 
	C516.848022,373.221558 516.573486,373.479340 516.573486,373.479340 
	C516.573486,373.479340 516.222778,373.398041 516.137085,373.298767 
	C516.051453,373.199463 516.450989,373.165314 516.649536,373.193420 
z"/>
<path fill="#0122A1" opacity="1.000000" stroke="none" 
	d="
M717.998779,314.975830 
	C718.004822,316.036621 718.010864,317.097412 718.026978,318.554169 
	C715.875671,319.112732 713.714294,319.275360 711.277710,319.719299 
	C709.441772,320.088409 707.882019,320.200317 706.320190,320.259674 
	C700.468933,320.481995 694.616943,320.687195 688.060547,320.808105 
	C685.903687,320.807953 684.451599,320.898499 682.999512,320.989044 
	C682.826233,320.995789 682.652893,321.002502 682.219971,321.032288 
	C676.679321,320.049042 671.408508,318.241547 666.115356,318.173431 
	C641.568054,317.857483 617.014526,318.042999 592.463379,317.990601 
	C589.804443,317.984924 586.059814,318.753265 588.428589,313.379272 
	C591.281128,313.014923 593.639587,313.014252 596.301392,313.298828 
	C603.692078,313.722748 610.779663,313.963959 617.866699,313.948029 
	C620.581970,313.941925 623.295837,313.348236 626.475830,313.021545 
	C631.624756,313.020752 636.308167,313.020782 641.296387,313.305847 
	C644.070923,313.726868 646.539124,313.928314 649.010742,313.987579 
	C660.435486,314.261566 671.861145,314.496033 683.686951,314.806763 
	C685.995361,314.854614 687.903381,314.840393 690.210876,314.838074 
	C695.413879,314.228424 700.217346,313.606873 705.192139,313.222046 
	C705.908936,313.632111 706.454468,313.805450 707.219421,314.267914 
	C709.756226,315.751343 712.135681,317.675659 714.399902,313.997528 
	C715.840149,314.324402 716.919434,314.650116 717.998779,314.975830 
z"/>
<path fill="#0341AA" opacity="1.000000" stroke="none" 
	d="
M595.998108,313.013611 
	C593.639587,313.014252 591.281128,313.014923 588.483521,313.014618 
	C585.271423,311.008698 582.498352,309.003693 579.190186,306.763977 
	C578.111389,306.379578 577.567810,306.229889 577.024231,306.080200 
	C572.379700,305.782166 569.570618,308.884705 566.048767,311.635254 
	C566.444824,309.599945 567.399902,307.631775 568.295105,305.787018 
	C564.554565,304.620667 560.763000,303.438416 556.971375,302.256134 
	C556.983765,301.530518 556.996216,300.804901 557.008606,300.079254 
	C560.370239,299.719299 563.734680,299.051422 567.092773,299.082214 
	C570.768799,299.115845 572.416016,297.777344 572.407837,294.065155 
	C574.215515,294.025421 575.606506,294.009064 577.249756,294.011536 
	C577.669983,294.042999 577.838074,294.055634 577.948364,294.387451 
	C577.408997,295.503326 576.927490,296.300018 576.652954,296.754181 
	C579.857239,298.771545 582.862671,300.663757 587.041687,303.294800 
	C586.643982,299.795380 586.319580,296.940582 586.330383,294.066559 
	C587.113098,294.029510 587.560608,294.011719 588.424255,293.997620 
	C590.227783,294.023712 591.615112,294.046021 593.001831,294.509155 
	C593.387390,299.525940 593.720276,304.107910 594.234802,308.669403 
	C594.291565,309.172913 595.386536,309.559296 596.000000,310.376526 
	C595.999390,311.506592 595.998779,312.260101 595.998108,313.013611 
z"/>
<path fill="#0455DA" opacity="1.000000" stroke="none" 
	d="
M576.963623,306.443878 
	C577.567810,306.229889 578.111389,306.379578 578.826294,306.762695 
	C579.965088,310.172699 580.932617,313.349274 581.822876,316.974365 
	C581.247253,317.019836 580.718140,316.648834 580.256531,316.207306 
	C579.175049,315.172974 578.125854,314.104889 577.063599,313.050476 
	C575.919189,314.222260 574.774780,315.394043 573.374146,316.804108 
	C571.523621,313.385590 570.177856,309.833893 575.802795,308.357574 
	C576.272766,308.234253 576.541077,307.342377 576.963623,306.443878 
z"/>
<path fill="#0240A9" opacity="1.000000" stroke="none" 
	d="
M559.993652,371.005005 
	C560.107971,371.141510 560.228638,371.273132 560.349304,371.404724 
	C560.268127,371.356110 560.186951,371.307465 560.057617,371.123322 
	C560.009399,370.987793 560.000000,371.000061 559.993652,371.005005 
z"/>
<path fill="#0137AC" opacity="1.000000" stroke="none" 
	d="
M683.151001,321.293854 
	C684.451599,320.898499 685.903687,320.807953 687.675415,320.856934 
	C691.094360,324.928864 694.236816,328.828339 697.269165,332.811676 
	C698.317810,334.189240 699.092651,335.775238 699.998718,337.630859 
	C696.409546,334.673309 692.757263,331.409760 689.240356,328.006317 
	C687.150940,325.984253 685.274658,323.741943 683.151001,321.293854 
z"/>
<path fill="#0B76EC" opacity="1.000000" stroke="none" 
	d="
M600.115234,341.629395 
	C599.775208,338.318207 601.095154,336.543884 604.007324,337.104370 
	C605.702209,337.430573 607.817749,338.571289 608.530212,339.965668 
	C609.019165,340.922607 607.839905,343.662903 606.670044,344.347137 
	C604.038391,345.886475 601.572876,344.980194 600.115234,341.629395 
z"/>
<path fill="#1E4796" opacity="1.000000" stroke="none" 
	d="
M560.716431,380.856628 
	C560.628479,383.310608 560.302246,385.546204 559.623108,387.890106 
	C558.838196,384.937195 558.406189,381.875977 558.040283,378.406738 
	C558.896912,378.878571 559.687500,379.758362 560.716431,380.856628 
z"/>
<path fill="#0137AC" opacity="1.000000" stroke="none" 
	d="
M795.008240,325.985352 
	C796.745789,326.322021 798.483276,326.658661 800.606750,326.993378 
	C803.872253,327.233124 806.751831,327.474823 809.631409,327.716492 
	C809.630310,328.439606 809.629211,329.162720 809.628113,329.885834 
	C804.372437,330.585480 799.116760,331.285126 793.443481,332.005402 
	C793.025940,332.026062 792.965698,331.965881 792.959656,331.525940 
	C792.904602,329.443237 792.855591,327.800537 793.171997,326.112701 
	C794.027710,326.040161 794.517944,326.012756 795.008240,325.985352 
z"/>
<path fill="#0FF1F7" opacity="1.000000" stroke="none" 
	d="
M743.536804,341.098389 
	C744.991089,342.085571 746.069153,343.057831 747.432800,344.150146 
	C747.781311,345.838562 747.844299,347.406891 747.823608,349.358368 
	C744.523071,350.819214 741.464844,353.151306 737.455078,350.973816 
	C737.071228,350.954376 736.914368,350.556885 736.920166,349.946747 
	C737.998291,346.611542 739.070618,343.886475 740.142944,341.161438 
	C741.148865,341.135437 742.154724,341.109406 743.536804,341.098389 
z"/>
<path fill="#013295" opacity="1.000000" stroke="none" 
	d="
M794.960205,325.616394 
	C794.517944,326.012756 794.027710,326.040161 792.797485,326.089539 
	C791.040894,326.098450 790.024231,326.085388 789.007507,326.072327 
	C787.982910,325.733521 786.946777,325.072784 785.935730,325.109314 
	C780.265320,325.314117 774.601013,325.687042 768.388428,325.636902 
	C765.621582,323.317902 763.596252,322.933929 762.000000,325.998230 
	C759.300720,325.998962 756.601379,325.999725 753.446350,325.629944 
	C752.660400,323.840424 752.330200,322.421448 752.000000,321.002441 
	C763.882324,321.001648 775.766602,321.121674 787.645691,320.926514 
	C791.215332,320.867889 793.738220,321.697327 794.960205,325.616394 
z"/>
<path fill="#01359A" opacity="1.000000" stroke="none" 
	d="
M751.531372,321.001221 
	C752.330200,322.421448 752.660400,323.840424 752.996521,325.627625 
	C752.686646,326.107056 752.427673,326.296112 752.112427,326.778259 
	C749.961060,326.992828 747.922729,326.992157 745.345947,326.600525 
	C740.468201,325.806641 736.135803,325.238068 731.787292,325.059387 
	C728.922607,324.941681 726.755554,324.525879 727.004883,320.989014 
	C727.004883,320.989014 727.495178,320.975647 727.739990,320.965393 
	C735.677490,320.970093 743.370117,320.985046 751.531372,321.001221 
z"/>
<path fill="#0560DC" opacity="1.000000" stroke="none" 
	d="
M788.841492,326.377808 
	C790.024231,326.085388 791.040894,326.098450 792.432129,326.134644 
	C792.855591,327.800537 792.904602,329.443237 792.929504,331.495850 
	C791.087524,331.470856 789.269592,331.035980 787.451721,330.601105 
	C787.859619,329.295166 788.267578,327.989227 788.841492,326.377808 
z"/>
<path fill="#01359A" opacity="1.000000" stroke="none" 
	d="
M762.428467,326.000366 
	C763.596252,322.933929 765.621582,323.317902 767.921143,325.634979 
	C766.285645,326.000031 764.571289,326.001251 762.428467,326.000366 
z"/>
<path fill="#0137AC" opacity="1.000000" stroke="none" 
	d="
M726.577881,320.988007 
	C726.755554,324.525879 728.922607,324.941681 731.787292,325.059387 
	C736.135803,325.238068 740.468201,325.806641 744.909180,326.600647 
	C740.531555,326.993561 736.048584,327.107941 731.573853,326.964752 
	C727.312866,326.828369 723.467163,325.625854 721.067261,321.288513 
	C722.744934,320.946655 724.447876,320.966827 726.577881,320.988007 
z"/>
<path fill="#14A1D9" opacity="1.000000" stroke="none" 
	d="
M739.772827,341.128967 
	C739.070618,343.886475 737.998291,346.611542 736.852966,349.743896 
	C733.875916,346.620972 734.605408,344.102570 739.772827,341.128967 
z"/>
<path fill="#0455DA" opacity="1.000000" stroke="none" 
	d="
M843.024353,382.426544 
	C846.420288,380.090118 849.648926,377.109985 853.547119,380.632263 
	C846.473267,381.035492 845.917786,381.661469 847.102661,387.901184 
	C847.030579,387.971069 846.886536,388.110962 846.886536,388.110962 
	C843.150513,388.137848 842.849365,385.657623 843.024353,382.426544 
z"/>
<path fill="#0455DA" opacity="1.000000" stroke="none" 
	d="
M855.052490,381.931305 
	C855.779114,381.952515 856.505798,381.973755 857.594238,382.019684 
	C856.432129,384.919739 855.285034,388.139801 853.158813,390.464294 
	C852.505554,391.178406 849.174011,389.442688 846.977722,388.469849 
	C846.886536,388.110962 847.030579,387.971069 847.455811,387.994080 
	C853.568054,389.638519 854.726196,388.655701 855.052490,381.931305 
z"/>
<path fill="#11A7F5" opacity="1.000000" stroke="none" 
	d="
M854.847168,381.816895 
	C854.726196,388.655701 853.568054,389.638519 847.527893,387.924194 
	C845.917786,381.661469 846.473267,381.035492 853.627014,381.008423 
	C854.187500,381.271698 854.386597,381.510529 854.847168,381.816895 
z"/>
<path fill="#35BDFA" opacity="1.000000" stroke="none" 
	d="
M868.904419,319.448792 
	C867.776733,321.230347 866.807617,323.127808 865.287476,323.826294 
	C864.522217,324.177948 861.797546,322.473175 861.673767,321.483765 
	C861.498047,320.079529 862.773071,317.312988 863.662842,317.212830 
	C865.296570,317.028809 867.101624,318.366119 868.904419,319.448792 
z"/>
<path fill="#F5F9FB" opacity="1.000000" stroke="none" 
	d="
M646.670471,266.000977 
	C649.034912,269.505615 651.399292,273.010254 653.883911,277.087555 
	C654.218323,277.915771 654.432495,278.171356 654.646667,278.426941 
	C654.723083,278.814117 654.799561,279.201294 655.086670,279.803101 
	C655.119812,282.014374 654.942444,284.011017 654.748352,286.402557 
	C654.731628,286.797424 654.649841,286.847015 654.400330,287.080017 
	C653.632874,289.120972 653.114929,290.928955 652.364624,292.908264 
	C651.674683,293.914551 651.217224,294.749451 650.433350,295.705383 
	C646.678223,298.179810 643.249451,300.533203 639.372986,302.847473 
	C630.192383,304.278137 623.056396,301.342590 617.142273,294.638245 
	C616.827454,294.103760 616.552368,293.857605 616.318176,293.604675 
	C616.359009,293.597870 616.353638,293.515289 616.273376,293.204254 
	C615.594849,292.304565 614.996643,291.715881 614.402466,291.247498 
	C614.406616,291.367798 614.628235,291.273865 614.672607,290.865753 
	C614.283447,289.869171 613.849792,289.280670 613.517151,288.716705 
	C613.618103,288.741241 613.685181,288.544525 613.848450,288.177490 
	C613.718811,285.553192 613.425842,283.295929 613.396362,280.776245 
	C614.317383,274.395508 617.644653,269.805298 622.060425,265.838165 
	C624.566406,264.737213 627.072327,263.636261 629.780823,262.259888 
	C632.711609,262.070679 635.439941,262.156921 638.792358,262.368958 
	C639.416443,262.494751 639.844482,262.370270 639.844482,262.370270 
	C639.986328,262.462494 640.128174,262.554718 640.640381,262.961548 
	C642.897339,264.184418 644.783875,265.092712 646.670471,266.000977 
z"/>
<path fill="#01389F" opacity="1.000000" stroke="none" 
	d="
M718.371765,314.979431 
	C716.919434,314.650116 715.840149,314.324402 714.257690,313.701294 
	C712.837219,312.602570 711.919861,311.801270 710.843689,310.697388 
	C707.376526,307.021362 703.588562,303.994232 700.853333,300.206879 
	C693.815125,290.461456 684.612366,286.884247 672.594727,289.018494 
	C671.490967,289.010834 670.754761,289.016541 670.011353,288.756653 
	C670.004517,288.313965 670.004944,288.136841 670.005432,287.959717 
	C670.740540,287.642212 671.474426,287.051086 672.210938,287.047882 
	C684.845581,286.992737 697.512146,286.516083 710.095581,287.321808 
	C713.730347,287.554565 717.561401,291.059052 720.534546,293.874512 
	C725.622559,298.692688 730.166138,304.096863 734.792908,309.382324 
	C735.785522,310.516174 736.158875,312.192078 736.817505,313.618286 
	C735.353699,314.074677 733.912964,314.797394 732.420654,314.927399 
	C729.946167,315.143005 727.438416,314.977325 724.420532,314.642700 
	C722.924988,314.531677 721.953552,314.750244 720.982178,314.968872 
	C720.236389,314.973572 719.490540,314.978271 718.371765,314.979431 
z"/>
<path fill="#0348CC" opacity="1.000000" stroke="none" 
	d="
M669.540527,287.972839 
	C670.004944,288.136841 670.004517,288.313965 669.708252,289.041443 
	C664.921692,289.715393 660.431091,289.838959 655.957947,289.644348 
	C655.990540,288.902191 656.005554,288.478302 656.020569,288.054382 
	C660.372253,288.031586 664.723938,288.008789 669.540527,287.972839 
z"/>
<path fill="#0122A1" opacity="1.000000" stroke="none" 
	d="
M721.361694,314.977814 
	C721.953552,314.750244 722.924988,314.531677 723.957397,314.637878 
	C723.259399,314.970673 722.500244,314.978729 721.361694,314.977814 
z"/>
<path fill="#01389F" opacity="1.000000" stroke="none" 
	d="
M655.880127,287.796387 
	C656.005554,288.478302 655.990540,288.902191 655.697327,289.418610 
	C655.162720,288.623077 654.906311,287.735046 654.649841,286.847015 
	C654.649841,286.847015 654.731628,286.797424 654.763062,286.833435 
	C655.109619,287.092438 655.424683,287.315399 655.880127,287.796387 
z"/>
<path fill="#0741A6" opacity="1.000000" stroke="none" 
	d="
M543.405762,388.444031 
	C535.999023,393.280243 530.843262,400.168488 524.641846,405.906738 
	C520.611389,409.636200 517.775208,414.928528 511.397156,415.550629 
	C510.501526,415.638000 509.734558,417.044769 508.495789,417.694122 
	C507.996429,417.400116 507.909332,417.252808 507.822235,417.105469 
	C509.287842,407.286255 510.753235,397.466980 512.221008,387.648102 
	C512.245422,387.484680 512.290894,387.323029 512.340332,387.164917 
	C513.682678,382.870453 524.746399,377.704773 531.662842,378.141785 
	C538.502808,378.574005 542.048889,381.559265 543.405762,388.444031 
z"/>
<path fill="#0A40A1" opacity="1.000000" stroke="none" 
	d="
M474.904419,421.886322 
	C475.440247,415.886169 476.429443,409.879608 477.941193,403.576782 
	C483.774078,406.464142 488.866333,405.178955 492.014832,400.067047 
	C493.125641,398.263489 494.704926,396.748474 496.070374,395.101746 
	C495.957825,396.561218 495.845245,398.020721 495.499115,399.962036 
	C495.144501,400.604156 495.132935,400.772125 495.230835,400.947754 
	C494.257812,407.729218 493.284790,414.510712 492.123779,421.657867 
	C486.409821,421.975677 480.883820,421.927826 474.904419,421.886322 
z"/>
<path fill="#023EA7" opacity="1.000000" stroke="none" 
	d="
M497.127625,387.344818 
	C497.145416,387.728302 497.163177,388.111786 496.697968,388.793579 
	C494.091095,390.447540 491.987640,391.836609 489.838470,393.150970 
	C486.582245,395.142426 483.294250,397.081970 480.058136,398.624695 
	C480.667236,393.486145 481.115448,388.746216 481.889984,384.060211 
	C482.098175,382.800385 483.034210,381.110779 484.091248,380.639648 
	C487.992310,378.901123 495.686493,383.030212 497.127625,387.344818 
z"/>
<path fill="#1058CB" opacity="1.000000" stroke="none" 
	d="
M480.019745,399.043549 
	C483.294250,397.081970 486.582245,395.142426 489.838470,393.150970 
	C491.987640,391.836609 494.091095,390.447540 496.573303,389.059570 
	C496.767670,390.820221 496.603760,392.613220 496.255096,394.753967 
	C494.704926,396.748474 493.125641,398.263489 492.014832,400.067047 
	C488.866333,405.178955 483.774078,406.464142 478.149780,403.194214 
	C477.824890,402.531036 477.813904,401.954132 478.286346,401.093414 
	C479.186432,400.220917 479.603088,399.632233 480.019745,399.043549 
z"/>
<path fill="#163A84" opacity="1.000000" stroke="none" 
	d="
M480.058136,398.624695 
	C479.603088,399.632233 479.186432,400.220917 478.483765,400.864929 
	C479.391876,392.203735 480.585938,383.487274 481.951416,374.416016 
	C483.161896,374.061981 484.200958,374.062714 485.627075,374.107910 
	C487.016907,375.568298 487.768005,377.322296 489.091400,378.307587 
	C490.484131,379.344452 492.378876,379.874634 494.134247,380.222717 
	C498.049316,380.998962 498.750977,383.277191 497.344177,387.004150 
	C495.686493,383.030212 487.992310,378.901123 484.091248,380.639648 
	C483.034210,381.110779 482.098175,382.800385 481.889984,384.060211 
	C481.115448,388.746216 480.667236,393.486145 480.058136,398.624695 
z"/>
<path fill="#35BDFA" opacity="1.000000" stroke="none" 
	d="
M512.956787,424.862915 
	C511.416565,424.814880 509.876343,424.766846 508.160950,424.985229 
	C507.985809,425.251648 507.562805,425.190033 507.562805,425.190033 
	C507.562805,425.190033 507.152283,425.309204 506.939362,425.331482 
	C506.816833,424.091064 506.907227,422.828369 507.169434,421.218140 
	C509.855438,419.874664 512.369568,418.878754 515.507812,417.635620 
	C515.639282,419.636200 515.719910,420.862793 515.788452,422.480347 
	C515.477478,423.277618 515.178528,423.683929 514.529663,424.362366 
	C513.772156,424.710632 513.364441,424.786774 512.956787,424.862915 
z"/>
<path fill="#1E4796" opacity="1.000000" stroke="none" 
	d="
M542.591431,398.357788 
	C542.235229,398.096130 542.133423,397.508606 542.007263,396.475800 
	C542.183899,395.453003 542.384888,394.875488 542.844971,394.124207 
	C543.017944,395.310913 542.931885,396.671417 542.591431,398.357788 
z"/>
<path fill="#35BDFA" opacity="1.000000" stroke="none" 
	d="
M507.613373,417.273621 
	C507.909332,417.252808 507.996429,417.400116 508.166016,417.791687 
	C508.095459,418.248383 507.960724,418.471832 507.696960,418.831818 
	C507.501221,418.452087 507.452881,417.946930 507.613373,417.273621 
z"/>
<path fill="#1058CB" opacity="1.000000" stroke="none" 
	d="
M495.379150,400.911194 
	C495.132935,400.772125 495.144501,400.604156 495.385071,400.388123 
	C495.690491,400.505737 495.698090,400.686493 495.379150,400.911194 
z"/>
<path fill="#043BA2" opacity="1.000000" stroke="none" 
	d="
M512.875488,425.142975 
	C513.364441,424.786774 513.772156,424.710632 514.489868,424.615845 
	C514.127197,425.393219 513.454529,426.189270 512.410706,426.984619 
	C512.291077,426.463623 512.542603,425.943329 512.875488,425.142975 
z"/>
<path fill="#0455DA" opacity="1.000000" stroke="none" 
	d="
M599.703247,396.953491 
	C598.673706,395.594696 597.985413,394.183014 597.162476,392.468109 
	C599.739746,388.677856 602.549683,385.261871 605.140564,381.687164 
	C609.092468,376.234741 616.293396,375.228241 619.291016,380.217346 
	C620.580383,382.363342 621.219116,387.439941 620.440979,387.838043 
	C617.466492,389.359833 613.542236,390.630707 610.445557,389.923737 
	C606.054077,388.921173 603.384888,388.372803 602.868408,393.950104 
	C602.770081,395.011658 601.028137,395.921021 599.703247,396.953491 
M609.564636,383.975647 
	C610.059814,384.613861 610.575684,385.808533 611.046448,385.790985 
	C612.804016,385.725616 614.720032,385.582520 616.202698,384.778839 
	C616.734741,384.490448 616.624939,381.321533 616.084900,381.079285 
	C614.637573,380.430084 612.741150,380.352905 611.141541,380.661774 
	C610.396240,380.805634 609.925110,382.369385 609.564636,383.975647 
z"/>
<path fill="#0E80D9" opacity="1.000000" stroke="none" 
	d="
M448.130066,422.123230 
	C448.112457,423.046387 448.094849,423.969513 447.928101,425.122162 
	C439.312531,425.582916 430.846985,425.861603 422.379303,426.027771 
	C417.219025,426.129059 412.055328,426.056946 406.426941,425.668823 
	C405.621399,424.218140 405.282104,423.161346 405.276611,422.027222 
	C406.065552,421.930511 406.520630,421.911041 407.183594,422.165527 
	C408.598816,422.263794 409.806183,422.088165 411.481293,421.915527 
	C419.630829,421.924957 427.312622,421.931305 435.175140,422.195801 
	C436.238586,422.279785 437.121307,422.105621 438.461060,421.932434 
	C441.988739,421.996674 445.059387,422.059937 448.130066,422.123230 
z"/>
<path fill="#0241B4" opacity="1.000000" stroke="none" 
	d="
M427.390015,435.924255 
	C428.117249,436.430237 428.498138,436.876831 428.954285,437.604675 
	C428.459503,438.189667 427.845459,438.432770 427.327240,438.808136 
	C425.522644,440.115356 423.518005,442.795593 422.015228,442.531403 
	C418.754730,441.958313 417.562164,438.908508 418.037140,435.524323 
	C418.126434,434.888092 418.295288,434.263062 418.723785,432.219330 
	C421.789307,433.562561 424.416504,434.713715 427.390015,435.924255 
z"/>
<path fill="#0870B6" opacity="1.000000" stroke="none" 
	d="
M374.323395,426.375610 
	C380.802612,425.877960 387.599670,425.639832 394.695068,425.691101 
	C388.209351,426.198700 381.425323,426.416870 374.323395,426.375610 
z"/>
<path fill="#24B6F9" opacity="1.000000" stroke="none" 
	d="
M404.942780,422.104523 
	C405.282104,423.161346 405.621399,424.218140 405.958130,425.613831 
	C405.253662,426.075928 404.551849,426.199127 403.088867,426.269867 
	C401.167358,426.295044 400.007050,426.372681 398.846710,426.450317 
	C398.425964,426.334686 398.005249,426.219025 397.276672,425.988281 
	C396.985504,425.169403 397.002167,424.465607 397.039795,423.154236 
	C397.052368,422.377167 397.043884,422.207672 397.474274,422.017578 
	C400.256348,422.032837 402.599579,422.068665 404.942780,422.104523 
z"/>
<path fill="#0870B6" opacity="1.000000" stroke="none" 
	d="
M398.979431,426.728210 
	C400.007050,426.372681 401.167358,426.295044 402.744934,426.364136 
	C401.812195,426.675934 400.462189,426.841003 398.979431,426.728210 
z"/>
<path fill="#0870B6" opacity="1.000000" stroke="none" 
	d="
M397.035400,422.038147 
	C397.043884,422.207672 397.052368,422.377167 397.042725,422.801117 
	C389.222046,422.929077 381.419434,422.802612 373.322754,422.387329 
	C373.004730,421.914368 372.980865,421.730164 373.366333,421.291992 
	C374.844177,421.005829 375.912689,420.973633 377.450195,420.964355 
	C384.291290,421.337585 390.663361,421.687866 397.035400,422.038147 
z"/>
<path fill="#013598" opacity="1.000000" stroke="none" 
	d="
M397.474274,422.017578 
	C390.663361,421.687866 384.291290,421.337585 377.445984,420.606140 
	C374.307190,417.824158 371.641693,415.423309 368.976227,413.022430 
	C368.976227,413.022430 368.487183,413.007202 368.198120,412.679291 
	C367.269470,411.905975 366.629913,411.460571 365.990356,411.015198 
	C365.358032,410.553192 364.725677,410.091217 364.011902,408.968994 
	C363.617767,407.212433 363.305054,406.116058 363.461334,405.007111 
	C390.415009,404.996368 416.899780,405.023651 443.384308,404.957672 
	C445.837250,404.951569 448.288788,404.352325 450.740967,404.028442 
	C450.151855,408.501099 449.562744,412.973724 448.760406,417.871521 
	C448.547211,418.296692 448.477478,418.584930 448.477478,418.584930 
	C448.477478,418.584930 448.743683,418.683929 448.743683,418.683929 
	C448.655792,419.621979 448.567932,420.559998 448.305054,421.810638 
	C445.059387,422.059937 441.988739,421.996674 438.268402,421.702118 
	C436.743988,421.626465 435.869202,421.782074 434.994446,421.937683 
	C427.312622,421.931305 419.630829,421.924957 411.255249,421.681091 
	C409.366211,421.592957 408.170990,421.742279 406.975739,421.891602 
	C406.520630,421.911041 406.065552,421.930511 405.276611,422.027222 
	C402.599579,422.068665 400.256348,422.032837 397.474274,422.017578 
z"/>
<path fill="#0560DC" opacity="1.000000" stroke="none" 
	d="
M356.001587,399.412476 
	C376.063873,399.078857 396.060608,399.045074 416.975433,399.014465 
	C420.595245,399.034058 423.297028,399.050476 426.268982,399.326965 
	C428.693451,399.415222 430.847687,399.243439 433.451660,399.062012 
	C436.600403,399.059143 439.299469,399.065857 442.219727,399.314941 
	C443.627991,399.395447 444.815094,399.233643 446.326843,399.064026 
	C447.083893,399.056396 447.516266,399.056610 448.005127,399.425171 
	C449.797241,404.301178 446.192322,402.970947 444.338440,402.975677 
	C417.467072,403.044312 390.595428,403.002441 363.723999,403.059448 
	C361.821838,403.063507 359.920868,403.633820 358.019348,403.940826 
	C356.962982,403.923920 355.906616,403.907013 354.458984,403.831085 
	C354.690491,402.418793 355.313263,401.065552 356.001587,399.412476 
z"/>
<path fill="#054CBE" opacity="1.000000" stroke="none" 
	d="
M358.214355,404.209717 
	C359.920868,403.633820 361.821838,403.063507 363.723999,403.059448 
	C390.595428,403.002441 417.467072,403.044312 444.338440,402.975677 
	C446.192322,402.970947 449.797241,404.301178 448.340637,399.446442 
	C449.477997,399.772675 450.336395,400.446014 451.194763,401.119354 
	C451.160126,401.866425 451.125488,402.613525 450.915894,403.694519 
	C448.288788,404.352325 445.837250,404.951569 443.384308,404.957672 
	C416.899780,405.023651 390.415009,404.996368 363.123535,404.999634 
	C361.867035,405.034241 361.417297,405.063721 360.967560,405.093201 
	C360.114807,404.888336 359.262085,404.683441 358.214355,404.209717 
z"/>
<path fill="#012F8B" opacity="1.000000" stroke="none" 
	d="
M368.981506,413.399780 
	C371.641693,415.423309 374.307190,417.824158 376.976929,420.583221 
	C375.912689,420.973633 374.844177,421.005829 373.374268,421.013550 
	C371.644165,418.585052 370.315460,416.181122 368.981506,413.399780 
z"/>
<path fill="#012F8B" opacity="1.000000" stroke="none" 
	d="
M360.976196,405.471344 
	C361.417297,405.063721 361.867035,405.034241 362.654541,405.012207 
	C363.305054,406.116058 363.617767,407.212433 363.963104,408.651978 
	C362.992096,407.946564 361.988464,406.898041 360.976196,405.471344 
z"/>
<path fill="#012F8B" opacity="1.000000" stroke="none" 
	d="
M366.037720,411.331360 
	C366.629913,411.460571 367.269470,411.905975 367.953735,412.668457 
	C367.360657,412.539490 366.722870,412.093506 366.037720,411.331360 
z"/>
<path fill="#0964CE" opacity="1.000000" stroke="none" 
	d="
M344.066132,399.338379 
	C345.739014,399.285858 347.411926,399.233307 349.493774,399.269226 
	C350.223877,401.754547 349.533661,403.132751 346.756165,403.035583 
	C342.795868,402.897095 338.827393,402.990784 334.387024,402.947083 
	C333.915588,401.869171 333.919525,400.829468 333.951660,399.399078 
	C334.445984,399.199738 334.912140,399.391052 335.969238,399.895630 
	C339.062195,399.918732 341.564178,399.628571 344.066132,399.338379 
z"/>
<path fill="#01308D" opacity="1.000000" stroke="none" 
	d="
M350.999512,393.431549 
	C352.002533,394.722717 352.994751,396.437866 353.980408,398.527649 
	C353.224518,398.934418 352.475220,398.966553 351.351532,398.961426 
	C350.981018,397.234650 350.984894,395.545074 350.999512,393.431549 
z"/>
<path fill="#0B76EC" opacity="1.000000" stroke="none" 
	d="
M863.426636,276.599915 
	C859.209290,277.186188 855.982788,276.231720 855.088440,272.029144 
	C854.474609,269.145111 854.913269,265.789154 858.513611,265.513184 
	C861.127319,265.312836 865.124512,265.966431 866.268188,267.707977 
	C868.102173,270.500916 867.880066,274.446198 863.426636,276.599915 
M864.447021,269.064117 
	C862.136108,266.554047 859.681335,265.512238 858.194580,269.541626 
	C857.799133,270.613464 859.127991,273.416046 860.154724,273.709167 
	C863.026062,274.528900 864.793030,272.917511 864.447021,269.064117 
z"/>
<path fill="#0439BA" opacity="1.000000" stroke="none" 
	d="
M813.857788,244.356125 
	C817.027832,243.768250 819.305542,244.405914 818.955200,248.552887 
	C816.449341,248.448685 811.484131,251.010529 813.857788,244.356125 
z"/>
<path fill="#0B3189" opacity="1.000000" stroke="none" 
	d="
M414.343933,304.076904 
	C414.377594,306.182526 414.057831,308.364014 413.544250,310.851898 
	C411.293457,308.615692 411.046936,306.238495 414.343933,304.076904 
z"/>
<path fill="#02389F" opacity="1.000000" stroke="none" 
	d="
M405.965088,284.002960 
	C405.852142,283.707581 405.681763,283.450226 405.277985,283.079468 
	C407.743195,279.656311 411.668884,279.781891 415.647034,279.044037 
	C415.934052,285.665161 415.727081,285.842499 407.404297,286.035950 
	C406.973938,286.056549 406.966614,286.021667 406.954407,285.707642 
	C406.616486,284.930054 406.290802,284.466522 405.965088,284.002960 
z"/>
<path fill="#0B3189" opacity="1.000000" stroke="none" 
	d="
M405.989838,284.297974 
	C406.290802,284.466522 406.616486,284.930054 406.949219,285.691101 
	C406.642334,285.523407 406.328461,285.058197 405.989838,284.297974 
z"/>
<path fill="#054CBE" opacity="1.000000" stroke="none" 
	d="
M343.642456,399.222687 
	C341.564178,399.628571 339.062195,399.918732 336.195251,400.017761 
	C338.293121,399.586761 340.755981,399.346893 343.642456,399.222687 
z"/>
<path fill="#0341AA" opacity="1.000000" stroke="none" 
	d="
M621.732178,265.577759 
	C617.644653,269.805298 614.317383,274.395508 612.965210,280.784607 
	C608.650818,281.368988 605.036377,281.824707 601.410034,281.960022 
	C596.978821,282.125366 593.069824,281.974365 589.117432,278.404388 
	C583.450012,273.285309 575.872314,276.176666 574.537109,283.161560 
	C574.185730,284.999756 574.826294,287.027557 574.542297,288.987061 
	C569.094849,289.005005 564.054321,289.499634 559.152771,288.885376 
	C552.086853,287.999878 547.241821,291.169647 542.618469,295.833069 
	C525.677490,312.920715 508.617920,329.890808 491.596313,346.898468 
	C489.539520,348.953583 487.442139,350.968079 485.363586,353.001434 
	C487.043976,341.181580 488.724335,329.361725 490.631531,317.210236 
	C491.046387,316.920044 491.234375,316.961487 491.717804,317.395203 
	C494.639465,322.119965 500.096832,325.017761 503.183685,323.718872 
	C507.403137,321.943359 509.668182,315.416138 507.611206,311.193420 
	C506.622253,309.163208 505.824341,307.039978 504.939911,304.958862 
	C504.939911,304.958862 504.988159,304.993805 505.299377,304.943726 
	C506.051300,304.245728 506.491943,303.597809 506.932617,302.949860 
	C507.796997,301.750092 508.661346,300.550323 510.158203,299.184998 
	C511.502258,298.330933 512.213745,297.642426 512.925293,296.953918 
	C512.925293,296.953918 512.975525,296.975830 513.306763,296.921692 
	C514.112244,295.947388 514.586548,295.027191 515.060913,294.106995 
	C523.870789,285.189880 532.680725,276.272797 542.126892,267.186981 
	C543.528564,266.390839 544.294006,265.763397 545.059509,265.135925 
	C570.507690,265.196442 595.955811,265.256897 621.732178,265.577759 
z"/>
<path fill="#0A40A1" opacity="1.000000" stroke="none" 
	d="
M442.628448,297.248291 
	C446.448944,291.107819 452.184692,291.974396 457.756683,291.914124 
	C460.311340,291.886475 462.856384,290.971924 465.676636,290.405151 
	C465.894165,290.869202 466.351044,291.843872 467.099060,293.009705 
	C465.564331,306.345398 463.834625,319.504883 461.796753,332.616455 
	C461.527313,334.349884 460.010101,336.296173 458.512756,337.354279 
	C454.333588,340.307434 450.068390,343.290924 445.453827,345.438721 
	C439.271454,348.316162 435.300781,345.090881 436.269958,338.432312 
	C438.255127,324.793182 440.323059,311.166107 442.628448,297.248291 
M444.062164,316.814423 
	C448.896362,317.023254 452.379944,314.614166 455.413300,311.143219 
	C457.048889,309.271698 458.800476,307.447357 460.743561,305.909882 
	C464.957794,302.575348 466.064636,298.537170 463.511871,292.376678 
	C462.306366,294.224304 461.631378,295.852936 460.459167,296.950592 
	C455.852692,301.264343 451.581818,306.279388 446.215240,309.320190 
	C442.513916,311.417389 440.883575,312.314697 444.062164,316.814423 
z"/>
<path fill="#065DD2" opacity="1.000000" stroke="none" 
	d="
M485.182251,353.297791 
	C487.442139,350.968079 489.539520,348.953583 491.596313,346.898468 
	C508.617920,329.890808 525.677490,312.920715 542.618469,295.833069 
	C547.241821,291.169647 552.086853,287.999878 559.152771,288.885376 
	C564.054321,289.499634 569.094849,289.005005 574.864990,289.007690 
	C576.085144,289.052429 576.513611,289.095306 576.952881,289.542725 
	C576.974976,291.295715 576.986267,292.644196 576.997559,293.992676 
	C575.606506,294.009064 574.215515,294.025421 571.943176,294.038208 
	C566.375061,294.046478 561.688171,294.058380 556.608276,294.048401 
	C554.797546,294.671967 553.379822,295.317444 551.981079,295.981445 
	C552.000000,296.000000 551.964600,295.960358 551.616638,296.003601 
	C548.852661,298.367340 546.436646,300.687836 544.010315,303.004150 
	C544.000000,303.000000 544.008179,303.020721 543.711182,303.157837 
	C542.271057,304.855164 541.127869,306.415405 539.693420,308.117249 
	C538.592590,309.164215 537.783020,310.069550 536.708984,311.089508 
	C535.946899,311.771484 535.449341,312.338837 534.951843,312.906158 
	C531.964233,315.401062 528.829773,317.742523 526.024048,320.427429 
	C521.990479,324.287384 518.196167,328.397278 514.149841,332.700256 
	C514.000000,333.000000 514.002441,333.010284 514.002441,333.010284 
	C507.063232,339.881195 500.125610,346.753723 493.183960,353.622131 
	C490.974243,355.808533 489.400665,359.106384 485.053040,357.962311 
	C484.827362,356.394928 484.914124,354.994507 485.182251,353.297791 
z"/>
<path fill="#043BA2" opacity="1.000000" stroke="none" 
	d="
M451.470276,400.817017 
	C450.336395,400.446014 449.477997,399.772675 448.284119,399.078064 
	C447.516266,399.056610 447.083893,399.056396 446.119324,398.794617 
	C444.390930,398.712891 443.194733,398.892761 441.998505,399.072601 
	C439.299469,399.065857 436.600403,399.059143 433.197571,398.774841 
	C430.328766,398.687164 428.163757,398.877045 425.998779,399.066895 
	C423.297028,399.050476 420.595245,399.034058 417.443237,398.993042 
	C416.628754,396.629303 416.264496,394.290100 415.816467,391.412720 
	C419.068878,390.446228 425.630554,392.482422 424.005890,384.397400 
	C431.770660,383.968781 439.533478,384.027985 447.294495,383.935760 
	C449.234131,383.912689 451.167969,383.402130 453.513306,383.050171 
	C453.196655,388.828003 452.471222,394.671326 451.470276,400.817017 
z"/>
<path fill="#1C428F" opacity="1.000000" stroke="none" 
	d="
M467.989258,290.698975 
	C467.756958,290.415192 467.688232,289.818512 467.862427,289.103210 
	C468.121185,289.451782 468.136993,289.918915 467.989258,290.698975 
z"/>
<path fill="#0E80D9" opacity="1.000000" stroke="none" 
	d="
M448.834747,418.597382 
	C448.743683,418.683929 448.477478,418.584930 448.477478,418.584930 
	C448.477478,418.584930 448.547211,418.296692 448.658325,418.305420 
	C448.769409,418.314178 448.925812,418.510834 448.834747,418.597382 
z"/>
<path fill="#0D89DF" opacity="1.000000" stroke="none" 
	d="
M646.903320,265.762207 
	C644.783875,265.092712 642.897339,264.184418 640.892822,262.987396 
	C640.991150,262.280823 641.207397,261.862976 641.806763,261.395477 
	C642.458984,261.239105 642.728027,261.132416 642.997070,261.025696 
	C644.704773,261.010254 646.412415,260.994812 648.545410,260.980896 
	C648.977783,262.012939 648.984924,263.043457 648.987549,264.455322 
	C648.367371,265.065552 647.751770,265.294495 646.903320,265.762207 
z"/>
<path fill="#0974E0" opacity="1.000000" stroke="none" 
	d="
M642.749512,261.016266 
	C642.728027,261.132416 642.458984,261.239105 642.098633,261.190979 
	C642.007324,261.036133 642.501953,261.006805 642.749512,261.016266 
z"/>
<path fill="#0D89DF" opacity="1.000000" stroke="none" 
	d="
M639.838623,262.164520 
	C639.844482,262.370270 639.416443,262.494751 639.248413,262.349365 
	C639.331238,262.122223 639.582031,262.040497 639.838623,262.164520 
z"/>
<path fill="#0858C9" opacity="1.000000" stroke="none" 
	d="
M504.609985,305.019287 
	C505.824341,307.039978 506.622253,309.163208 507.611206,311.193420 
	C509.668182,315.416138 507.403137,321.943359 503.183685,323.718872 
	C500.096832,325.017761 494.639465,322.119965 492.004547,317.429260 
	C493.653076,316.748199 495.310272,316.425293 497.577484,316.413574 
	C500.091309,318.434845 501.862152,318.949310 503.564301,316.053162 
	C503.599335,314.950317 503.335541,313.954163 502.863953,312.826050 
	C502.390442,312.488129 502.190887,312.230804 502.057526,311.922119 
	C500.774323,308.983887 501.610565,306.734070 504.609985,305.019287 
z"/>
<path fill="#35BDFA" opacity="1.000000" stroke="none" 
	d="
M501.656189,311.964539 
	C502.190887,312.230804 502.390442,312.488129 502.837769,313.227173 
	C503.101410,314.560120 503.183441,315.359985 503.265472,316.159882 
	C501.862152,318.949310 500.091309,318.434845 497.964478,316.438904 
	C497.979553,313.980377 498.057739,311.672241 501.656189,311.964539 
z"/>
<path fill="#0858C9" opacity="1.000000" stroke="none" 
	d="
M544.734863,265.122192 
	C544.294006,265.763397 543.528564,266.390839 542.427368,267.032013 
	C542.864502,266.399994 543.637390,265.754211 544.734863,265.122192 
z"/>
<path fill="#0858C9" opacity="1.000000" stroke="none" 
	d="
M512.613159,296.993378 
	C512.213745,297.642426 511.502258,298.330933 510.449646,299.037964 
	C510.839386,298.381989 511.570160,297.707428 512.613159,296.993378 
z"/>
<path fill="#0858C9" opacity="1.000000" stroke="none" 
	d="
M514.761902,294.211609 
	C514.586548,295.027191 514.112244,295.947388 513.295898,296.896515 
	C513.456848,296.055695 513.959900,295.185944 514.761902,294.211609 
z"/>
<path fill="#0858C9" opacity="1.000000" stroke="none" 
	d="
M506.623413,303.003784 
	C506.491943,303.597809 506.051300,304.245728 505.284454,304.918091 
	C505.410248,304.314209 505.862213,303.685944 506.623413,303.003784 
z"/>
<path fill="#11A7F5" opacity="1.000000" stroke="none" 
	d="
M499.996735,305.995728 
	C500.088959,305.960541 500.184418,305.929596 500.144226,305.952576 
	C500.008545,306.006470 500.000000,306.000000 499.996735,305.995728 
z"/>
<path fill="#1058CB" opacity="1.000000" stroke="none" 
	d="
M654.691284,278.174255 
	C654.432495,278.171356 654.218323,277.915771 654.067017,277.408264 
	C654.331848,277.411438 654.533813,277.666504 654.691284,278.174255 
z"/>
<path fill="#011999" opacity="1.000000" stroke="none" 
	d="
M536.973511,310.974884 
	C537.783020,310.069550 538.592590,309.164215 540.070129,308.134277 
	C541.491516,307.999420 542.244812,307.989105 543.403809,307.985840 
	C545.704407,308.426941 548.155090,308.258026 549.395996,309.401703 
	C554.224426,313.851959 559.928833,314.335785 565.959106,314.028229 
	C567.673584,316.811340 565.932739,317.998810 563.351501,319.003876 
	C560.811584,319.375031 558.495972,319.388428 556.437988,320.123169 
	C555.097900,320.601562 554.132446,322.129303 552.988525,323.581848 
	C552.977844,323.976776 552.992126,323.990021 552.604248,324.032898 
	C542.312073,328.292175 537.909180,326.227295 534.995117,315.999359 
	C534.995117,315.999359 535.010742,315.512573 535.366821,315.191406 
	C536.139771,313.571777 536.556641,312.273346 536.973511,310.974884 
z"/>
<path fill="#023EA7" opacity="1.000000" stroke="none" 
	d="
M534.612854,316.090332 
	C537.909180,326.227295 542.312073,328.292175 552.597351,324.027771 
	C551.137512,326.569214 549.755432,329.738312 547.363586,331.631500 
	C541.824280,336.015869 536.243958,340.215485 532.630005,346.486450 
	C532.222168,347.194092 531.124023,347.503906 530.306519,348.023254 
	C527.239197,341.180969 528.663330,338.249329 535.113647,336.872009 
	C536.491516,336.577820 537.630981,335.167267 538.880249,334.270996 
	C537.645325,333.194641 536.579590,331.631287 535.143982,331.132629 
	C530.701782,329.589539 526.102661,328.074493 521.825684,332.707306 
	C521.474915,332.157471 521.124207,331.607635 520.773438,331.057800 
	C521.890991,329.023438 523.008606,326.989105 524.134705,324.939301 
	C530.353516,328.536682 532.751770,327.126831 533.050232,320.678680 
	C533.120300,319.164581 533.820374,317.679688 534.612854,316.090332 
z"/>
<path fill="#023EA7" opacity="1.000000" stroke="none" 
	d="
M514.004883,333.001831 
	C515.273132,333.870087 516.536438,334.736542 517.799744,335.603027 
	C517.217407,336.032532 516.635010,336.462006 516.052673,336.891510 
	C515.370483,335.859436 514.688354,334.827362 514.004272,333.402771 
	C514.002441,333.010284 514.000000,333.000000 514.004883,333.001831 
z"/>
<path fill="#023EA7" opacity="1.000000" stroke="none" 
	d="
M536.708984,311.089508 
	C536.556641,312.273346 536.139771,313.571777 535.368652,314.947876 
	C535.004639,314.555084 534.994934,314.084625 534.968506,313.260162 
	C535.449341,312.338837 535.946899,311.771484 536.708984,311.089508 
z"/>
<path fill="#023EA7" opacity="1.000000" stroke="none" 
	d="
M565.978149,313.690765 
	C559.928833,314.335785 554.224426,313.851959 549.395996,309.401703 
	C548.155090,308.258026 545.704407,308.426941 543.446045,307.624664 
	C543.391113,305.844574 543.699707,304.432648 544.008179,303.020721 
	C544.008179,303.020721 544.000000,303.000000 544.411377,303.001953 
	C550.180481,303.674805 550.870728,299.612976 551.964600,295.960358 
	C551.964600,295.960358 552.000000,296.000000 552.377319,295.994293 
	C554.170227,295.349152 555.585815,294.709686 557.001343,294.070251 
	C561.688171,294.058380 566.375061,294.046478 571.526489,294.061523 
	C572.416016,297.777344 570.768799,299.115845 567.092773,299.082214 
	C563.734680,299.051422 560.370239,299.719299 557.008606,300.079254 
	C556.996216,300.804901 556.983765,301.530518 556.971375,302.256134 
	C560.763000,303.438416 564.554565,304.620667 568.295105,305.787018 
	C567.399902,307.631775 566.444824,309.599945 565.746094,311.785278 
	C566.000732,312.452728 565.998962,312.903015 565.978149,313.690765 
z"/>
<path fill="#033FA9" opacity="1.000000" stroke="none" 
	d="
M650.759827,295.584381 
	C651.217224,294.749451 651.674683,293.914551 652.693115,293.067505 
	C654.155090,293.377655 655.056091,293.699982 656.268066,294.303833 
	C662.203186,294.718414 667.856018,295.269928 673.445129,294.888733 
	C682.345032,294.281738 690.557739,294.745697 695.906128,303.332825 
	C696.311462,303.983643 697.299438,304.242706 697.841797,304.842041 
	C700.269409,307.525146 702.633667,310.265594 705.020813,312.985352 
	C700.217346,313.606873 695.413879,314.228424 690.282104,314.513092 
	C689.504578,312.517365 689.350708,310.672852 688.476196,309.281219 
	C688.064697,308.626160 685.385254,308.426788 685.242798,308.729950 
	C684.353333,310.623352 683.890564,312.717224 683.286499,314.744659 
	C671.861145,314.496033 660.435486,314.261566 649.010742,313.987579 
	C646.539124,313.928314 644.070923,313.726868 641.260742,312.944153 
	C637.652832,305.188904 634.999146,304.228516 628.197205,307.617096 
	C627.959595,307.735474 627.406189,307.220062 627.130493,306.685730 
	C627.170166,305.669861 627.282471,304.478210 626.956726,304.343201 
	C624.014771,303.123932 620.996155,302.089691 617.976807,300.704651 
	C617.636536,299.939026 617.319519,299.469971 616.879211,298.736206 
	C616.170654,297.980621 615.585327,297.489716 615.008179,296.609131 
	C615.462158,295.318115 615.907898,294.416687 616.353638,293.515289 
	C616.353638,293.515289 616.359009,293.597870 616.337524,293.899475 
	C616.604736,294.442902 616.893433,294.684753 617.182068,294.926605 
	C623.056396,301.342590 630.192383,304.278137 639.670044,303.059570 
	C641.653870,304.048615 642.807739,305.069641 644.147461,305.473114 
	C648.857727,306.891541 650.630432,302.558441 653.287964,300.223083 
	C653.579102,299.967194 651.655945,297.191437 650.759827,295.584381 
z"/>
<path fill="#013FA8" opacity="1.000000" stroke="none" 
	d="
M615.000000,296.998779 
	C615.585327,297.489716 616.170654,297.980621 616.901489,299.032410 
	C617.364685,300.062164 617.682373,300.531067 618.009521,301.370422 
	C621.012634,303.493866 624.006348,305.246948 627.000000,307.000000 
	C627.406189,307.220062 627.959595,307.735474 628.197205,307.617096 
	C634.999146,304.228516 637.652832,305.188904 640.955994,312.659088 
	C636.308167,313.020782 631.624756,313.020752 626.295959,312.693817 
	C623.043884,311.578033 620.461609,310.222809 617.825684,310.108826 
	C610.562500,309.794647 603.276794,310.000092 596.000000,310.000000 
	C595.386536,309.559296 594.291565,309.172913 594.234802,308.669403 
	C593.720276,304.107910 593.387390,299.525940 593.468689,294.483765 
	C599.290710,294.021759 604.645386,294.025940 609.999756,294.440857 
	C609.411560,296.847870 608.513550,298.818665 608.347168,300.849457 
	C608.240173,302.155762 609.208740,303.550171 609.695190,304.905090 
	C610.989197,304.178436 612.806519,303.758057 613.453491,302.652679 
	C614.406616,301.024170 614.525696,298.907532 615.000000,296.998779 
z"/>
<path fill="#0348CC" opacity="1.000000" stroke="none" 
	d="
M705.192139,313.222046 
	C702.633667,310.265594 700.269409,307.525146 697.841797,304.842041 
	C697.299438,304.242706 696.311462,303.983643 695.906128,303.332825 
	C690.557739,294.745697 682.345032,294.281738 673.445129,294.888733 
	C667.856018,295.269928 662.203186,294.718414 656.571045,294.008911 
	C660.764893,293.283752 664.970886,292.950104 669.166809,293.047974 
	C672.216309,293.119141 673.350037,291.935486 672.962280,289.031921 
	C684.612366,286.884247 693.815125,290.461456 700.853333,300.206879 
	C703.588562,303.994232 707.376526,307.021362 710.781372,311.035553 
	C709.585205,312.443817 708.292603,313.211304 707.000000,313.978821 
	C706.454468,313.805450 705.908936,313.632111 705.192139,313.222046 
z"/>
<path fill="#033FA9" opacity="1.000000" stroke="none" 
	d="
M596.000000,310.376526 
	C603.276794,310.000092 610.562500,309.794647 617.825684,310.108826 
	C620.461609,310.222809 623.043884,311.578033 625.830505,312.694641 
	C623.295837,313.348236 620.581970,313.941925 617.866699,313.948029 
	C610.779663,313.963959 603.692078,313.722748 596.301392,313.298859 
	C595.998779,312.260101 595.999390,311.506592 596.000000,310.376526 
z"/>
<path fill="#0B76EC" opacity="1.000000" stroke="none" 
	d="
M683.686951,314.806763 
	C683.890564,312.717224 684.353333,310.623352 685.242798,308.729950 
	C685.385254,308.426788 688.064697,308.626160 688.476196,309.281219 
	C689.350708,310.672852 689.504578,312.517365 689.882568,314.501221 
	C687.903381,314.840393 685.995361,314.854614 683.686951,314.806763 
z"/>
<path fill="#0433B7" opacity="1.000000" stroke="none" 
	d="
M707.219421,314.267883 
	C708.292603,313.211304 709.585205,312.443817 710.940186,311.338165 
	C711.919861,311.801270 712.837219,312.602570 713.896851,313.700073 
	C712.135681,317.675659 709.756226,315.751343 707.219421,314.267883 
z"/>
<path fill="#0455DA" opacity="1.000000" stroke="none" 
	d="
M610.000000,294.030121 
	C604.645386,294.025940 599.290710,294.021759 593.469238,294.042969 
	C591.615112,294.046021 590.227783,294.023712 588.428955,293.645782 
	C588.691345,291.886719 589.365173,290.483215 590.402344,289.051331 
	C591.494385,288.986847 592.223083,288.950775 593.422729,288.949341 
	C599.260498,288.640778 604.627319,288.297638 610.342468,288.114990 
	C611.688904,288.365143 612.687012,288.454834 613.685181,288.544525 
	C613.685181,288.544525 613.618103,288.741241 613.418884,289.111145 
	C613.689209,290.078644 614.158752,290.676239 614.628235,291.273865 
	C614.628235,291.273865 614.406616,291.367798 614.278442,291.654388 
	C614.235474,295.554932 611.842896,294.246552 610.000000,294.030121 
z"/>
<path fill="#053ACF" opacity="1.000000" stroke="none" 
	d="
M590.039001,289.079742 
	C589.365173,290.483215 588.691345,291.886719 588.012817,293.642029 
	C587.560608,294.011719 587.113098,294.029510 585.887207,294.040527 
	C582.741272,294.045258 580.373718,294.056763 578.006165,294.068298 
	C577.838074,294.055634 577.669983,294.042999 577.249756,294.011505 
	C576.986267,292.644196 576.974976,291.295715 577.420654,289.497192 
	C581.931396,289.057983 585.985168,289.068878 590.039001,289.079742 
z"/>
<path fill="#023EA7" opacity="1.000000" stroke="none" 
	d="
M577.948364,294.387451 
	C580.373718,294.056763 582.741272,294.045258 585.552002,294.059753 
	C586.319580,296.940582 586.643982,299.795380 587.041687,303.294800 
	C582.862671,300.663757 579.857239,298.771545 576.652954,296.754181 
	C576.927490,296.300018 577.408997,295.503326 577.948364,294.387451 
z"/>
<path fill="#011999" opacity="1.000000" stroke="none" 
	d="
M590.402344,289.051331 
	C585.985168,289.068878 581.931396,289.057983 577.409790,289.092651 
	C576.513611,289.095306 576.085144,289.052429 575.333984,288.988922 
	C574.826294,287.027557 574.185730,284.999756 574.537109,283.161560 
	C575.872314,276.176666 583.450012,273.285309 589.117432,278.404388 
	C593.069824,281.974365 596.978821,282.125366 601.410034,281.960022 
	C605.036377,281.824707 608.650818,281.368988 612.701660,281.046967 
	C613.425842,283.295929 613.718811,285.553192 613.848450,288.177490 
	C612.687012,288.454834 611.688904,288.365143 610.038330,287.834747 
	C604.705933,287.268799 600.024719,287.051575 595.346802,287.106445 
	C594.541504,287.115906 593.749817,288.282623 592.951843,288.914734 
	C592.223083,288.950775 591.494385,288.986847 590.402344,289.051331 
z"/>
<path fill="#1058CB" opacity="1.000000" stroke="none" 
	d="
M650.433350,295.705383 
	C651.655945,297.191437 653.579102,299.967194 653.287964,300.223083 
	C650.630432,302.558441 648.857727,306.891541 644.147461,305.473114 
	C642.807739,305.069641 641.653870,304.048615 640.117798,303.098694 
	C643.249451,300.533203 646.678223,298.179810 650.433350,295.705383 
z"/>
<path fill="#1058CB" opacity="1.000000" stroke="none" 
	d="
M672.594727,289.018494 
	C673.350037,291.935486 672.216309,293.119141 669.166809,293.047974 
	C664.970886,292.950104 660.764893,293.283752 656.260132,293.727417 
	C655.056091,293.699982 654.155090,293.377655 652.925537,292.896118 
	C653.114929,290.928955 653.632874,289.120972 654.400330,287.080017 
	C654.906311,287.735046 655.162720,288.623077 655.679810,289.736816 
	C660.431091,289.838959 664.921692,289.715393 669.715454,289.307007 
	C670.754761,289.016541 671.490967,289.010834 672.594727,289.018494 
z"/>
<path fill="#0341AA" opacity="1.000000" stroke="none" 
	d="
M609.999756,294.440857 
	C611.842896,294.246552 614.235474,295.554932 614.274353,291.534119 
	C614.996643,291.715881 615.594849,292.304565 616.273376,293.204254 
	C615.907898,294.416687 615.462158,295.318115 615.008179,296.609131 
	C614.525696,298.907532 614.406616,301.024170 613.453491,302.652679 
	C612.806519,303.758057 610.989197,304.178436 609.695190,304.905090 
	C609.208740,303.550171 608.240173,302.155762 608.347168,300.849457 
	C608.513550,298.818665 609.411560,296.847870 609.999756,294.440857 
z"/>
<path fill="#0341AA" opacity="1.000000" stroke="none" 
	d="
M614.672607,290.865723 
	C614.158752,290.676239 613.689209,290.078644 613.317932,289.086609 
	C613.849792,289.280670 614.283447,289.869171 614.672607,290.865723 
z"/>
<path fill="#0341AA" opacity="1.000000" stroke="none" 
	d="
M617.142273,294.638245 
	C616.893433,294.684753 616.604736,294.442902 616.296753,293.906250 
	C616.552368,293.857605 616.827454,294.103760 617.142273,294.638245 
z"/>
<path fill="#24B6F9" opacity="1.000000" stroke="none" 
	d="
M609.447144,383.632568 
	C609.925110,382.369385 610.396240,380.805634 611.141541,380.661774 
	C612.741150,380.352905 614.637573,380.430084 616.084900,381.079285 
	C616.624939,381.321533 616.734741,384.490448 616.202698,384.778839 
	C614.720032,385.582520 612.804016,385.725616 611.046448,385.790985 
	C610.575684,385.808533 610.059814,384.613861 609.447144,383.632568 
z"/>
<path fill="#0870B6" opacity="1.000000" stroke="none" 
	d="
M407.183594,422.165527 
	C408.170990,421.742279 409.366211,421.592957 410.787537,421.678070 
	C409.806183,422.088165 408.598816,422.263794 407.183594,422.165527 
z"/>
<path fill="#0870B6" opacity="1.000000" stroke="none" 
	d="
M435.175140,422.195801 
	C435.869202,421.782074 436.743988,421.626465 437.811401,421.701172 
	C437.121307,422.105621 436.238586,422.279785 435.175140,422.195801 
z"/>
<path fill="#054CBE" opacity="1.000000" stroke="none" 
	d="
M426.268982,399.326965 
	C428.163757,398.877045 430.328766,398.687164 432.747833,398.784424 
	C430.847687,399.243439 428.693451,399.415222 426.268982,399.326965 
z"/>
<path fill="#054CBE" opacity="1.000000" stroke="none" 
	d="
M442.219727,399.314941 
	C443.194733,398.892761 444.390930,398.712891 445.794678,398.802429 
	C444.815094,399.233643 443.627991,399.395447 442.219727,399.314941 
z"/>
<path fill="#35BDFA" opacity="1.000000" stroke="none" 
	d="
M864.608765,269.410339 
	C864.793030,272.917511 863.026062,274.528900 860.154724,273.709167 
	C859.127991,273.416046 857.799133,270.613464 858.194580,269.541626 
	C859.681335,265.512238 862.136108,266.554047 864.608765,269.410339 
z"/>
<path fill="#0540BE" opacity="1.000000" stroke="none" 
	d="
M443.694458,316.813599 
	C440.883575,312.314697 442.513916,311.417389 446.215240,309.320190 
	C451.581818,306.279388 455.852692,301.264343 460.459167,296.950592 
	C461.631378,295.852936 462.306366,294.224304 463.511871,292.376678 
	C466.064636,298.537170 464.957794,302.575348 460.743561,305.909882 
	C458.800476,307.447357 457.048889,309.271698 455.413300,311.143219 
	C452.379944,314.614166 448.896362,317.023254 443.694458,316.813599 
z"/>
<path fill="#0341AA" opacity="1.000000" stroke="none" 
	d="
M551.616638,296.003601 
	C550.870728,299.612976 550.180481,303.674805 544.421631,303.006134 
	C546.436646,300.687836 548.852661,298.367340 551.616638,296.003601 
z"/>
<path fill="#0341AA" opacity="1.000000" stroke="none" 
	d="
M543.711182,303.157837 
	C543.699707,304.432648 543.391113,305.844574 543.040405,307.617676 
	C542.244812,307.989105 541.491516,307.999420 540.361450,307.992676 
	C541.127869,306.415405 542.271057,304.855164 543.711182,303.157837 
z"/>
<path fill="#0341AA" opacity="1.000000" stroke="none" 
	d="
M556.608276,294.048401 
	C555.585815,294.709686 554.170227,295.349152 552.358398,295.975769 
	C553.379822,295.317444 554.797546,294.671967 556.608276,294.048401 
z"/>
<path fill="#0D89DF" opacity="1.000000" stroke="none" 
	d="
M503.564301,316.053162 
	C503.183441,315.359985 503.101410,314.560120 503.045532,313.359131 
	C503.335541,313.954163 503.599335,314.950317 503.564301,316.053162 
z"/>
<path fill="#0341AA" opacity="1.000000" stroke="none" 
	d="
M627.130493,306.685730 
	C624.006348,305.246948 621.012634,303.493866 618.009521,301.371033 
	C620.996155,302.089691 624.014771,303.123932 626.956726,304.343201 
	C627.282471,304.478210 627.170166,305.669861 627.130493,306.685730 
z"/>
<path fill="#0341AA" opacity="1.000000" stroke="none" 
	d="
M617.976807,300.704041 
	C617.682373,300.531067 617.364685,300.062164 617.024780,299.297058 
	C617.319519,299.469971 617.636536,299.939026 617.976807,300.704041 
z"/>
<path fill="#0235B0" opacity="1.000000" stroke="none" 
	d="
M593.422729,288.949341 
	C593.749817,288.282623 594.541504,287.115906 595.346802,287.106445 
	C600.024719,287.051575 604.705933,287.268799 609.690063,287.674255 
	C604.627319,288.297638 599.260498,288.640778 593.422729,288.949341 
z"/>

</g>
</svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
